import { Injectable } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { StorageMap } from '@ngx-pwa/local-storage';
import { HttpClient, HttpRequest } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Location } from '@angular/common';

import { ItemEmployeeHonor } from '../models/global-classes';
import { DocsItem } from '../models/global-classes';

import { StrapiService } from './strapi.service';
import { AuthService } from './auth.service';

import { Subject, Subscription } from 'rxjs';


// TODO переделать на qs !!!
import qs from 'qs';

@Injectable({providedIn: 'root'})
export class DataService {
  private _mobile: boolean;
  private _hostname: string;
  private _context: any;
  /**
   * Блок хрени
   */
  private ListEmployeeHonor: ItemEmployeeHonor[] = [
    {
      ItemEmployeeHonorTitle: 'Почётная грамота Президента Российской Федерации',
      ItemEmployeeHonorImg: '../../assets/img/220px-Znak_pochetnoy_gramoty_Prezidenta_RF.png',
      ItemEmployeeHonorImgAlt: ''
    },
    {
      ItemEmployeeHonorTitle: 'Почётная грамота Президента Российской Федерации',
      ItemEmployeeHonorImg: '../../assets/img/220px-Znak_pochetnoy_gramoty_Prezidenta_RF.png',
      ItemEmployeeHonorImgAlt: ''
    },
    {
      ItemEmployeeHonorTitle: 'Почётная грамота Президента Российской Федерации',
      ItemEmployeeHonorImg: '../../assets/img/220px-Znak_pochetnoy_gramoty_Prezidenta_RF.png',
      ItemEmployeeHonorImgAlt: ''
    },
    {
      ItemEmployeeHonorTitle: 'Почётная грамота Президента Российской Федерации',
      ItemEmployeeHonorImg: '../../assets/img/220px-Znak_pochetnoy_gramoty_Prezidenta_RF.png',
      ItemEmployeeHonorImgAlt: ''
    },
    {
      ItemEmployeeHonorTitle: 'Почётная грамота Президента Российской Федерации',
      ItemEmployeeHonorImg: '../../assets/img/220px-Znak_pochetnoy_gramoty_Prezidenta_RF.png',
      ItemEmployeeHonorImgAlt: ''
    },
    {
      ItemEmployeeHonorTitle: 'Почётная грамота Президента Российской Федерации',
      ItemEmployeeHonorImg: '../../assets/img/220px-Znak_pochetnoy_gramoty_Prezidenta_RF.png',
      ItemEmployeeHonorImgAlt: ''
    }
  ];
  private ListDocs: DocsItem[] = [
    {
      idDocs: 1,
      iconDocs: '#doc',
      // tslint:disable-next-line:max-line-length
      titleDocs: 'Ультразвуковое исследование органов брюшной полости комплексное: печень, поджелудочная железа, желчный пузырь, селезенка при профилактическом осмотре',
      sizeDocs: '740 кб'
    },
    {
      idDocs: 2,
      iconDocs: '#doc',
      // tslint:disable-next-line:max-line-length
      titleDocs: 'Ультразвуковое исследование печени и желчного пузыря',
      sizeDocs: '620 кб'
    },
    {
      idDocs: 3,
      iconDocs: '#doc',
      // tslint:disable-next-line:max-line-length
      titleDocs: 'УЗИ почечного трансплантанта с дуплексным сканированием артерий и вен (в палате)',
      sizeDocs: '1600 кб'
    },
    {
      idDocs: 4,
      iconDocs: '#doc',
      // tslint:disable-next-line:max-line-length
      titleDocs: 'Ультразвуковое исследование органов брюшной полости комплексное: печень, поджелудочная железа, желчный пузырь, селезенка при профилактическом осмотре',
      sizeDocs: '740 кб'
    },
    {
      idDocs: 5,
      iconDocs: '#doc',
      // tslint:disable-next-line:max-line-length
      titleDocs: 'Ультразвуковое исследование органов брюшной полости комплексное: печень, поджелудочная железа, желчный пузырь, селезенка при профилактическом осмотре',
      sizeDocs: '200 кб'
    }
  ];
// ------------------------------------------------------
  private mockMarkdownContent;
  private fragmentSubscription: Subscription;

  // BEGIN попытки сделать чтение и запись в сессию


  private _mode: string;
  private mode$ = new Subject<string>();
  public get mode() { return this._mode; }
  public set mode(m: string) {
    this._mode = m;
    this.mode$.next(this._mode);
  }

  private _jivo: boolean;
  private jivo$ = new Subject<boolean>();
  public get jivo() { return this._jivo; }
  public set jivo(j: boolean) {
    this._jivo = j;
    this.jivo$.next(this._jivo);
  }

  /**
   * начало мета теги
   * @private
   */
  private _title: string;
  private title$ = new Subject();

  public get title() { return this._title; }
  public set title(t: string) {
    this._title = t;
    this.title$.next(this._title);
  }

  private _seo: any;
  private seo$ = new Subject();

  public get seo() { return this._seo; }
  public set seo(s: any) {
    this._seo = s;
    this.seo$.next(s);
  }

  private _metaKeywords: string;
  private metaKeywords$ = new Subject();
  public get metaKeywords() { return this._metaKeywords; }
  public set metaKeywords(t: string) {
    this._metaKeywords = t;
    this.metaKeywords$.next(this._metaKeywords);
  }
  private _metaDescription: string;
  private metaDescription$ = new Subject();
  public get metaDescription() { return this._metaDescription; }
  public set metaDescription(t: string) {
    this._metaDescription = t;
    this.metaDescription$.next(this._metaDescription);
  }
  /**
   * конец
   */

  private _hfVision: boolean;
  private hfVision$ = new Subject();
  public get hfVision() { return this._hfVision; }
  public set hfVision(t: boolean) {
    this._hfVision = t;
    this.hfVision$.next(this._hfVision);
  }

  private _struct: any;
  private struct$ = new Subject();

  public get struct() {
    return this._struct;
  }

  public set struct(url){
    console.log('Run set structure for', url);
    this.hostname = url;
    this.getListFromServer('structures', {url})
      .then(res => {
        console.log('get structures', res);
        this._struct = res[0] || {};
        this.title = this._struct.title;
        this.struct$.next(new Object(this._struct));
      });
  }

  // private _spinner: any;
  private spinner$ = new Subject();
  // public get spinner() { return this._spinner; }
  public set spinner(state: boolean){
    // this._spinner = state;
    this.spinner$.next(state);
  }

  constructor(
    private http: HttpClient,
    private auth: AuthService,
    private router: Router,
    private location: Location,
    private storage: StorageMap,
    public strapi: StrapiService
  ) {
    // TODO Загрузить нужный город правильно!!!!
    // this.language$ = {
    //   id: 1,
    //   locale: 'ru',
    //   shortName: 'Рус',
    //   name: 'Русский'
    // };
    // this.city$ = {
    //   id: 1,
    //   name: 'Красноярск',
    //   active: true
    // };
  }

  public get jwt(){
    return this.auth.currentUserValue.jwt;
  }

  public get srvurl(){
    if (environment.development || !this.struct || !this.struct.url){
      return environment.API_URL;
    }

    return this.siteurl + '/api';
  }

  public get siteurl() {
    // return environment.SITE_URL;
    return 'https://' + this.struct.url;
  }

  public get language() {
    // return {id: 1};
    return this.struct.language;
  }

  public get city() {
    // return {id: 1};
    return this.struct.city;
  }

  public get context() {
    return this._context;
  }

  public set context(c) {
    this._context = c;
  }

  public get mobile() { return this._mobile; }
  public set mobile(m){ this._mobile = m; }
  public get hostname() { return this._hostname; }
  public set hostname(hn){ this._hostname = hn; }

  public sub = () => (
    {
      mode: this.mode$.asObservable(),
      jivo: this.jivo$.asObservable(),
      seo: this.seo$.asObservable(),
      title: this.title$.asObservable(),
      metaKeywords: this.metaKeywords$.asObservable(), // мета тег ключевые запросы
      metaDescription: this.metaDescription$.asObservable(), // мета тег описание
      struct: this.struct$.asObservable(),
      hfVision: this.hfVision$.asObservable(),
      spinner: this.spinner$.asObservable(),
    })

  setRoute(route: string, params: any): void {
    // console.log('Route', route);
    // console.log('Route.includes \'/\'', route.includes('/'));
    const navigationExtras: NavigationExtras = {
      queryParams: params
    };
    this.router.navigate([route], navigationExtras);
  }

  /**
   * Неавторизованный GET-запрос. Нужен для обычного публичного интерфейса
   * @param subject - адрес роутинга, по которому будет определена необходимая для загрузки модель
   * @param id - идентификатор объекта модели (при наличии). Если число или строка, преобразуемая в число, то имеет значение
   * @param params - параметры GET-запроса
   */
  getData(subject: string, id: (boolean|number|string), params: any): Promise<any> {
    return new Promise((resolve, reject) => {
      // console.log('this.strapi.data', subject, this.strapi.data['' + subject])
      let url = this.srvurl + '/' + subject;
      /**
       * Если запрашивается конкретный объект данных
       */
      if (typeof id === 'number' || Number(id)) {
        url = url + '/' + id;
        if (this.mode === 'admin') {
          url = url + '?reload=1';
        }

        this.http.get(url, this.auth.currentUserValue ? {headers: {authorization: 'Bearer ' + this.auth.currentUserValue.jwt}} : {})
          .subscribe((data: any) => {
            if (data.order) {
              Object.keys(data.order).forEach(key => {
                console.log('key', key);
                data[key] = data[key]
                  ? this.ordered(data[key], data.order[key])
                  : [];
              });
            }
            resolve(data);
          }, error => reject(error));

      } else if (!this.strapi.data['' + subject]) {

        this.getListFromServer(subject, params)
          .then(ddata => {

            resolve(ddata);
          })
          .catch(err => reject(err));

      } else { // TODO на период разработки
        let data = this.strapi.data['' + subject];
        if ((typeof id === 'number') || Number(id)) {
          data = data.filter(item => (item.id === Number(id)))[0];
        }
        resolve(data);
      }
    });
  }

  /**
   * Неавторизованное получение списочного набора данных с сервера в соответствии с запросом
   * @param modelname - имя модели
   * @param params - параметры запроса
   */
  private getListFromServer = (modelname, params) => {
    return new Promise((resolve, reject) => {
      const _params = Object.assign({_limit: 999999}, params || {});

      const _query: any = {};

      for (const key of Object.keys(_params)){
        if (key === '_limit' || key === '_start' || key === '_end' || key === '_sort' || key === '_publicationState'){
          _query[key] = _params[key];
        }else if (key === '_or'){
          _query._where = {};
          _query._where._or = _params._or;
        }else{
          const t = {};
          t[key] = _params[key];
          if (!_query._where){
            _query._where = [];
          }
          _query._where.push(t);
        }
      }

      const query = qs.stringify(_query);

      console.log('query', query);

      const url = this.srvurl + '/' + modelname + `?${query}`;

      this.http.get(url, this.auth.currentUserValue ? {headers: {authorization: 'Bearer ' + this.auth.currentUserValue.jwt}} : {})
        .subscribe((data: any) => {
          console.log('getPublicListFromServer url', url);
          console.log('getPublicListFromServer Result', data);
          if (Array.isArray(data)){
            data.map(item => {
              if (item.order) {
                Object.keys(item.order).forEach(key => {
                  item[key] = item[key]
                    ? this.ordered(item[key], item.order[key])
                    : [];
                });
              }
              return item;
            });
          }
          resolve(data);
        }, err => {
          console.error('getPublicListFromServer error!');
          console.error(err);
          reject(err);
        });
    });
    }

  /**
   * Неавторизованный POST-запрос для отправки сообщения с сайта
   * @param params - параметры сообщения
   */
  sendMessage(params: any): Promise<unknown> {
    const url = this.srvurl + '/messages/send';
    params.production = environment.production; // !Важно
    params.structure = this.struct.id;

    return new Promise((resolve, reject) => this.http
      .post(url, params, {} )
      .subscribe((postdata: any) => resolve(postdata), error => reject(error)));
  }

  /**
   * Неавторизованный POST-запрос для отправки отзыва с сайта
   * TODO унифицировать. Возможно, просто перевести на sendMessage... или через кролика
   * @param params - параметры сообщения
   */
  sendFeedback(params: any): Promise<unknown> {
    const url = this.srvurl + '/feedbacks/send';
    params.production = environment.production; // !Важно
    params.structure = this.struct.id;
    return new Promise((resolve, reject) => this.http
      .post(url, params, {} )
      .subscribe((postdata: any) => resolve(postdata), error => reject(error)));
  }

  formReady(modelname: string): Promise<unknown> {
    const url = this.srvurl + '/' + modelname + '/ready';
    return new Promise((resolve, reject) => this.http
      .get(url)
      .subscribe(data => resolve(data), error => reject(error)));
  }

  loadSchema(subject: string): Promise<unknown> {
    console.log('getSchema subject', subject);
    return new Promise((resolve, reject) => {
      const url = this.srvurl + '/' + subject + '/schema';
      this.http.get(url)
        .subscribe((data: any) => {
          resolve(data);
        }, error => reject(error));
    });
  }

  /**
   * Авторизованный PUT-запрос
   * @param subject - адрес роутинга, по которому будет определена необходимая для загрузки модель
   * @param data - данные для изменения
   * @param id - идентификатор изменяемого объекта модели
   */
  putData(subject: string, data: any , id: number|string): Promise<unknown> {
    const url = this.srvurl + '/' + subject + '/' + id;
    console.log('putURL', url);
    console.log('putdata', data);
    return new Promise((resolve, reject) => this.http
      .put(url, data, {headers: {authorization: 'Bearer ' + this.auth.currentUserValue.jwt} } )
      .subscribe((putdata: any) => resolve(putdata), error => reject(error)));
  }

  /**
   * Авторизованный DELETE-запрос
   * @param subject - адрес роутинга, по которому будет определена необходимая для загрузки модель
   * @param id - идентификатор удаляемого объекта модели
   */
  delData(subject: string, id: number|string): Promise<unknown> {
    const url = this.srvurl + '/' + subject + '/' + id;
    return new Promise((resolve, reject) => this.http
      .delete(url, {headers: {authorization: 'Bearer ' + this.auth.currentUserValue.jwt} } )
      .subscribe((deldata: any) => resolve(deldata), error => reject(error)));
  }

  addData(subject: string, data: any): Promise<unknown> {
    const url = this.srvurl + '/' + subject;
    console.log('postdata', data);
    return new Promise((resolve, reject) => this.http
      .post(url, data, {headers: {authorization: 'Bearer ' + this.auth.currentUserValue.jwt} } )
      .subscribe((postdata: any) => resolve(postdata), error => reject(error)));
  }

  public ordered = (src: any[], order: number[] | string[]) => {
    let dest;
    // console.log('src', src);
    if (order && order.length) {
        dest = new Array(order.length);
        if (typeof order[0] === 'string'){
          const tmp = order[0].split(':');
          console.log('tmp', tmp);

          if (tmp[1] && tmp[1].toLowerCase() === 'asc'){
            dest = src.sort((a, b) => ('' + a[tmp[0]]).toLowerCase().localeCompare(('' + b[tmp[0]]).toLowerCase()));
          }else if (tmp[1] && tmp[1].toLowerCase() === 'desc'){
            dest = src.sort((b, a) => ('' + a[tmp[0]]).toLowerCase().localeCompare(('' + b[tmp[0]]).toLowerCase()));
          }
        }else{
          const index = {};
          // console.log('dest zero', dest);
          order.forEach((item, i) => {
            index['' + item] = i;
          });
          // console.log('index', index);
          src.forEach(item => {
            dest[index[item.id]] = item;
          });
          dest = dest.filter(item => item);
        }
      } else {
        dest = src;
      }
    return dest;
  }

  public uploadFile(file: File){
    console.log('uploadFile RUN');
    console.log('BLobToUpload');
    console.log(file);
    const formData = new FormData();
    formData.append('files', file);

    const url = this.srvurl + '/upload';
    let request = new HttpRequest('POST', url, formData, {reportProgress: true});
    request = request.clone({
      setHeaders: {
        Authorization: 'Bearer ' + this.auth.currentUserValue.jwt
      }
    });

    return new Promise((resolve, reject) => {
      this.http.request(request)
        .subscribe(
        (data: any) => {
          console.log('http request result of upload', data);
          if (data && data.body){
            resolve(data.body[0]);
          }
          // TODO Добавить таймаут выхода из всей этой лажи в случае ошибки загрузки. Или другое условие
        },
        err => {
          reject(err);
        }
      );
    });
  }

  getListDocs() { // TODO delete
    return this.ListDocs;
  }
  getListEmployeeHonor() { // TODO delete
    return this.ListEmployeeHonor;
  }
}
