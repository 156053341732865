<div class="modal-header justify-content-center">
  <h4 class="modal-title" id="modal-title">Укажите ваше местоположение.</h4>
</div>
<!-- <div class="modal-body">
  <p>Укажите ваше местоположение</p>
</div> -->
<div class="modal-footer justify-content-between">
  <button class="btn btn-secondary" (click)="modal.close(1)">Красноярск</button>
  <button class="btn btn-primary" (click)="modal.close(2)">Зеленогорск</button>
</div>
