<div ngbDropdown class="d-inline-block col-5">
  <button type="button" class="btn btn-outline-primary" id="dropdownBasic1" ngbDropdownToggle>
    <img *ngIf="selected && selected.url[0] == '/'"
         class="mr-2 block_directions_main_img" src="{{dataService.srvurl + selected.url}}" alt=""> {{selected && selected.name}} </button>
  <div ngbDropdownMenu aria-labelledby="dropdownBasic1" style="height:300px; overflow-y:auto">
    <button type="button" ngbDropdownItem *ngFor="let item of icons" (click)="setLinkModel(item)">
      <img *ngIf="item && item.url[0] == '/'"
           class="mr-2 block_directions_main_img" src="{{dataService.srvurl + item.url}}" alt="">
      {{item.name}} </button>
  </div>
</div>
