import { CommonModule } from '@angular/common';
import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DataService } from '../../../services/data.service';

@Component({
  standalone: true,
  templateUrl: './form-feedback.component.html',
  styleUrls: ['./form-feedback.component.css'],
  encapsulation: ViewEncapsulation.None,
  imports: [
    CommonModule,
    FormsModule
  ]
})
export class FormFeedbackComponent implements OnInit {
  data: any;
  id: any;
  product: any;
  price: any;

  types = [
    {
      code: 'good',
      title: 'Положительный'
    },
    {
      code: 'offer',
      title: 'Предложение'
    },
    {
      code: 'bad',
      title: 'Отрицательный'
    }
  ];

  constructor(
    public dataService: DataService,
    public modal: NgbActiveModal,
  ) {
    this.data = {
      type: 'good'
    };
    console.log('= this.data constructor =', this.data);
  }

  ngOnInit(): void {
    console.log('= form-feedback.comp data =', this.data);

  }
}

