/**
 * Меню элемента списка.
 * @Input() state - строка с состоянием для меню, соответствующая протоколу событий.
 * Части протокола разделены двоеточием:
 * 0  Имя модели основного/родительского элемента;
 * 1  ID основного/родительского элемента;
 * 2  Название поля целевого дочернего элемента;
 * 3  ID целевого дочернего элемента в случае составного (links, components);
 * 4  Маска набора доступных действий для целевого дочернего элемента (AdminService.actions);
 * 5  Режим работы (mode)
 */

import {Input, Component} from '@angular/core';
import { faUpload, faSortAlphaDown, faLink, faUnlink, faPlus, faTrash, faPen, faLongArrowAltUp, faLongArrowAltDown } from '@fortawesome/free-solid-svg-icons';
import { EventsService } from '../../../services/events.service';
import { AdminService } from '../../../services/admin.service';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';
import {NgbTooltipModule} from '@ng-bootstrap/ng-bootstrap';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';

@Component({
  standalone: true,
  selector: 'app-admitemslistmenu',
  templateUrl: './admitemslistmenu.component.html',
  imports: [
    CommonModule,
    RouterModule,
    NgbTooltipModule,
    FontAwesomeModule
  ]
})
export class AdmitemslistmenuComponent{
  @Input() set state(s){
    if(s && s.length > 0){
      console.log(s);
      const tmp = s.split(':');
      const schema = this.admin.schema[tmp[0]];
      if (!!+tmp[4]) {
        this.actions = +tmp[4];
      }
      else if (schema.imedia && schema.imedia[tmp[2]]) {
        this.actions = schema.imedia[tmp[2]].actions;
      }
      else {
        this.actions = schema.fields[schema.fields.findIndex((item, index, array) => item.name === tmp[2])].actions;
      }
    }
    this._state = s || false;
  };
  get state(){
    return this._state;
  }

  private _state: string | false;
  private actions: number;
  private modelname: string;

  public faSortAlphaDown = faSortAlphaDown;
  public faLink = faLink;
  public faUnlink = faUnlink;
  public faPlus = faPlus;
  public faPen = faPen;
  public faTrash = faTrash;
  public faLongArrowAltUp = faLongArrowAltUp;
  public faLongArrowAltDown = faLongArrowAltDown;
  public faUpload = faUpload;

  constructor(
    public admin: AdminService,
    private events: EventsService
  ) {
    this.actions = 0;
    this.modelname = '';
  }

  can = (a: string) => (this.actions & this.admin.actions[a]);
  send = (e: string) => this.events.send({name: e, data: this.state});
}

