import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {DataService} from '../../services/data.service';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';

@Component({
  standalone: true,
  selector: 'app-ocsfo',
  templateUrl: './ocsfo.component.html',
  styleUrls: ['./ocsfo.component.css'],
  encapsulation: ViewEncapsulation.None,
  imports: [
    NgbModule,
    CommonModule,
    RouterModule
  ]
})
export class OcsfoComponent implements OnInit {
  mobile;
  active;

  constructor(
    private dataService: DataService) { }

  ngOnInit(): void {
    this.mobile = this.dataService.mobile;
    this.dataService.hfVision = false;
    this.active = 'common';
  }

  setActive(e){
    this.active = e;
  }

  toURL(url){
    window.open(url, '_blank');
  }
}
