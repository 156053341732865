export class User {
  id: number;
  username: string;
  password: string;
  email: string;
  jwt: string;
}

export class Key {
  APIkey: string;
  secret: string;
}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';

@Injectable({ providedIn: 'root' })
export class AuthService {

  private currentUserSubject: BehaviorSubject<User>;
  private currentUser: Observable<User>;

  constructor(private http: HttpClient) {
    this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(sessionStorage.getItem('currentUser')));
    this.currentUser = this.currentUserSubject.asObservable();
  }

  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }

  login(username, password) {
    return this.http.post<any>(`${environment.API_URL}/auth/local`,
    // return this.http.post<any>(`${environment.API_URL}/auth/local`,
      {
        identifier  : username,
        password
      })
      .pipe(map(user => {
        const userCur: User = {
          id          : user.user.id,
          username    : user.user.username,
          password    : '',
          email       : user.user.email || '',
          jwt         : user.jwt
        };
        console.log('login result', userCur);
        // store user details and jwt in session storage to keep user logged in between page refreshes
        sessionStorage.setItem('currentUser', JSON.stringify(userCur));
        this.currentUserSubject.next(userCur);
        return userCur;
      }));
  }

  logout() {
    // remove user from session storage and set current user to null
    sessionStorage.removeItem('currentUser');
    this.currentUserSubject.next(null);
  }
}
