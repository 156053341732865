<div id="teasers" class="container">
    <div class="{{mobile ? '' : 'footer__slider'}}">
        <a href="https://мывместе.рф" class="teaser-block" rel="noopener"  target="_blank">
            <span class="teaser-inner">
                <img src="../../../assets/img/partners/together.webp" alt="мы вместе">
            </span>
      </a>
    <!--  <div class="teaser-viewport">-->
        <div class="teaser-block" id="js-show-iframe-wrapper" style="cursor: pointer">
            <span class="teaser-inner">
              <img src="../../../assets/img/gosuslugi.png">
          <span class="title">Сообщить о проблеме</span>
              <br>
            </span>
        </div>
      <a href="http://anketa.rosminzdrav.ru/staticmojustank/10610" class="teaser-block" rel="noopener"  target="_blank">
            <span class="teaser-inner">
                <img src="../../../assets/img/partners/nok.jpg" alt="">
            </span>
      </a>
      <a href="tel:88007757332" class="teaser-block" rel="noopener"  target="_blank">
            <span class="teaser-inner">
                <img src="../../../assets/img/partners/photo5458547771725428308.jpg" alt="">
            </span>
      </a>
        <div class="teaser-block">
          <div class="row my-4">
    <!--        <a class="col align-content-center" href="https://www.facebook.com/fmbaofrussia/" rel="noopener" target="_blank"><img style="width:35px;padding-top:5px;" src="https://fmba.gov.ru/img/fb.png"></a>-->
    <!--        <a class="col align-content-center" href="https://www.instagram.com/fmba_rossii" rel="noopener" target="_blank"><img style="width:35px;padding-top:5px;" src="https://fmba.gov.ru/img/inst.png"></a>-->
            <a class="col align-content-center" href="https://t.me/fmba_of_russia" rel="noopener" target="_blank"><img style="width:35px; border-radius: 50%; padding-top:5px;" src="../../../assets/img/partners/telegram.png"></a>
            <a class="col align-content-center" href="https://vk.com/krasfmba24" rel="noopener" target="_blank"><img style="width:35px; border-radius: 50%; padding-top:5px;" src="../../../assets/icons/vk.png"></a>
          </div>
            <span class="title">ФМБА России</span>
    <!--          <span>-->
    <!--          </span><br><br>-->
        </div>
        <a href="https://www.mchs.gov.ru/" class="teaser-block" rel="noopener" target="_blank">
            <span class="teaser-inner">
                    <img src="../../../assets/img/partners/mchs.jpg" alt="">
                <span class="title">
                    Сотрудничество с МЧС России
                </span>
            </span>
        </a>
        <a href="https://www.takzdorovo.ru/" class="teaser-block" rel="noopener" target="_blank">
            <span class="teaser-inner">
                <img src="../../../assets/img/partners/takzdorovo_ru.png" alt="">
            </span>
        </a>
        <a href="https://cloud.mail.ru/public/cNTX/K249RpC6f" class="teaser-block" rel="noopener" target="_blank">
            <span class="teaser-inner">
                <img src="../../../assets/img/partners/digitalOMS.jpg" alt="" class="for_img">
            </span>
        </a>
    
    <!--    <a [routerLink]="['/pages/302']" class="teaser-block">-->
    <!--        <span class="teaser-inner">-->
    <!--                <img src="../../../assets/img/partners/russiatravel.jpg" alt="">-->
    <!--            <span class="title">-->
    <!--                Медицинский туризм в России-->
    <!--            </span>-->
    <!--        </span>-->
    <!--    </a>-->
    <!--  </div>-->
    </div>
</div>
