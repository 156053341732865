import {Component, EventEmitter, NgModule, OnInit, Output} from '@angular/core';
import {DataService} from '../../services/data.service';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';
import {NgbTooltipModule} from '@ng-bootstrap/ng-bootstrap';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {AdmitemslistmenuComponent} from '../admin/admitemslistmenu/admitemslistmenu.component';

@Component({
  standalone: true,
  selector: 'app-svg-check',
  templateUrl: './svg-check.component.html',
  imports: [
    CommonModule,
    RouterModule,
    NgbTooltipModule,
    FontAwesomeModule
  ]
})
export class SvgCheckComponent implements OnInit {
  @Output() uploadedData = new EventEmitter<string>();

  icons: any[];
  selected: any;

  constructor(public dataService: DataService) { }

  ngOnInit(): void {
    this.dataService.getData('upload/files', false, {ext: '.svg'})
      .then((data: any[]) => {
        this.icons = data.sort((a, b) => a.name.localeCompare(b.name));
        console.log('icons', this.icons);
        this.selected = this.icons[0];
        console.log('url', this.dataService.srvurl + this.selected.url);
      });
  }

  setLinkModel = (icon) => {
    console.log('click!');
    this.selected = icon;
    this.uploadedData.emit(this.selected.url);
  }
}
// @NgModule({
//   imports: [
//     CommonModule,
//     RouterModule,
//     NgbTooltipModule,
//     FontAwesomeModule
//   ],
//   declarations: [
//     SvgCheckComponent
//   ],
//   exports: [
//     SvgCheckComponent
//   ]
// })
// export class SvgCheckComponentModule {}
