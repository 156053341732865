<div class="modal-header">
  <h3 class="title-2">Оставить отзыв</h3>
</div>
<div class="modal-body">
  <div class="mb-3">
    <label for="types"><span class="text-danger">*</span>Тип отзыва</label>
    <select class="form-control" id="types" [(ngModel)]="data.type">
      <option *ngFor="let item of types" [ngValue]="item.code">{{item.title}}</option>
    </select>
  </div>

  <div class="mb-3">
    <label for="name"> <span class="text-danger">*</span>Представьтесь, пожалуйста</label>
    <input [(ngModel)]="data.author" class="form-control" id="name" type="text" autofocus>
  </div>

  <div class="mb-3">
    <label for="email">
      Электронная почта</label>
    <input [(ngModel)]="data.email" class="form-control" id="email" type="email">
  </div>

  <div class="mb-3">
    <label for="phone">
      Номер телефона</label>
    <input [(ngModel)]="data.phone" class="form-control" id="phone" type="tel" placeholder="+7"/>
  </div>

  <div class="mb-3">
    <label for="message"> <span class="text-danger">*</span>Текст отзыва</label>
    <textarea class="form-control" id="message" type="text" [required]="" [(ngModel)]="data.text" placeholder="Напишите текст отзыва" name="comment"></textarea>
  </div>
</div>
<div class="modal-footer">
  <button class="btn btn-outline-secondary" (click)="modal.dismiss();">Отмена</button>
  <button class="btn btn-outline-primary" (click)="modal.close(data);" [disabled]="!(data.author && data.text)">Отправить</button>
</div>
