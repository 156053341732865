import {Input, Component, OnInit} from '@angular/core';
import { AdminService } from '../../../services/admin.service';
import {AdmitemslistmenuComponent} from '../../admin/admitemslistmenu/admitemslistmenu.component';
import {RouterModule} from '@angular/router';
import {NgbPopoverModule} from '@ng-bootstrap/ng-bootstrap';
import {CommonModule} from '@angular/common';

@Component({
  standalone: true,
  selector: 'app-nav-header-item',
  templateUrl: './nav-header-item.component.html',
  styleUrls: ['./nav-header-item.component.css'],
  imports: [
    AdmitemslistmenuComponent,
    CommonModule,
    NgbPopoverModule,
    RouterModule
  ]
})
export class NavHeaderItemComponent implements OnInit {
  @Input() model: any;
  @Input() state: string;

  modelname: string;
  mode: string;
  public borderStyle: any;

  constructor(public admin: AdminService) {
    this.modelname = ''; // Название текущей модели этого элемента
    this.mode = '';
    this.borderStyle = {};
  }

  ngOnInit(): void {
    if (this.state){
      const tmp = this.state.split(':');
      this.mode = tmp[5] || '';
      this.modelname = this.admin.schema[tmp[0]].links[tmp[2]].modelname || alert('Ошибка 1325! Обратитесь к разработчику.');
      this.model = (this.mode === 'admin') ? this.admin.fillAdminModel(this.model, this.modelname) : this.model;
    }
  }

  bordermark = (p, state: string | boolean) => {
    this.borderStyle = {};
    if (state) {
      this.borderStyle['' + state] = 'border: solid 1px #7d7e92;';
      this.admin.popoverOpen(p, '' + state);
    }
  }

  onHref = () => {
    window.open(this.model.url, '_blank');
  }
}

