import { ModalComponent } from './../templates-common/_modal/modal.component';
import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import { DataService } from '../../services/data.service';
import { JQ } from '../../models/jq';
import { EventsService } from '../../services/events.service';
import { AdminService } from '../../services/admin.service';
import {AdmitemslistmenuComponent} from '../admin/admitemslistmenu/admitemslistmenu.component';
import {CommonModule} from '@angular/common';
import {NgbPopoverModule, NgbRatingModule} from '@ng-bootstrap/ng-bootstrap';
import {RouterModule, UrlHandlingStrategy} from '@angular/router';
import {ModalService} from '../templates-common/_modal';
import {FormsModule} from '@angular/forms';
import {NewYearComponent} from '../templates-common/new-year/new-year.component';
import {March8thComponents} from '../templates-common/system-events/march-8th/march-8th.components';
import { NavHeaderComponent } from './nav-header/nav-header.component';
import { Router } from '@angular/router';
import { AnimateTimings } from '@angular/animations';
function getCookie(name) {
  const matches = document.cookie.match(new RegExp(
    '(?:^|; )' + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + '=([^;]*)'
  ));
  return matches ? decodeURIComponent(matches[1]) : undefined;
}

@Component({
  standalone: true,
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],
  // changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    AdmitemslistmenuComponent,
    RouterModule,
    CommonModule,
    NavHeaderComponent,
    NgbPopoverModule,
    ModalComponent,
    FormsModule,
    NgbRatingModule,
    NewYearComponent,
    March8thComponents,
    
  ],
})

export class HeaderComponent{
  @Input() set header(h) {
    console.log('HeaderComponent input header', h);
    if (h && h.id){
      this.model = new Object(h);
      if (!this.jqloaded && this.model) {
        this.jq.fixHeader();
        this.jq.openMainMenu();
        this.jqloaded++;
      }
      
      this.name = this.dataService.struct.city.name;
    }
  }

  message: string;

  currentDate: Date = new Date();
  ls_city: any;
  bodyText: string;
  model: any = {};
  city_id: any;
  borderStyle: any;
  jqloaded = 0;
  currentRate = 0;
  rated: any;
  newsite: any;
  filter: string;
  hidden: any;
  name: any;
  constructor(
    private events: EventsService,
    private jq: JQ,
    private dataService: DataService,
    public admin: AdminService,
    private modalService: ModalService,
    private router: Router,
  ) {
  }
  get mode(){
    return this.dataService.mode;
  }
  get mobile(){
    return this.dataService.mobile;
  }

  public switchSite() {
    document.cookie = 'newsite=false; path=/; expires=Fri, 31 Dec 9999 23:59:59 GMT';
    document.cookie = 'rated=true; path=/; expires=Fri, 31 Dec 9999 23:59:59 GMT';
    // location.href = '/';
    this.dataService.setRoute(((this.dataService.struct && this.dataService.struct.startpage) || ''), {});
    location.reload();
  }
  
  search(){
    if (this.filter && this.filter.trim().length){
      this.dataService.setRoute('search-results', {filter: this.filter});
    }
  }

  followTo(way){
    localStorage['city'] = 'false';
    window.open(`${way.url}`, '_self');
  }

  openModal(id: string) {
    // TODO сделать нормальную реализацию уведомления с разными режимами
    if (id === 'alert') {
      this.modalService.open(id);
    } else if (id === 'custom-modal-2') {
      this.modalService.open(id);
    } else {
      if (!this.rated) {
        this.modalService.open(id);
      }
      if (this.rated) {
        this.switchSite();
      }
    }
  }
  starsModal(id: string) {
    this.modalService.open(id);
  }
  closeModal(id: string) {
    // TODO добавить логику уведомлений из базы
    if (id === 'alert') {
      sessionStorage.alerted = true;
    }
    this.modalService.close(id);
    // else {
    //   if (id === 'custom-modal-2') {
    //     localStorage.setItem('firstStart', 'true');
    //     this.modalService.close(id);
    //   } else {
    //     this.dataService.sendMessage({
    //       type: 'newSiteRate',
    //       body: {rate, message}
    //     }).then((data: any) => {
    //       this.modalService.close(id);
    //     });
    //     if (!this.rated) {
    //       this.switchSite();
    //     }
    //   }
    // }
  }

  get newYearPublic() {
    return (
      (((this.currentDate.getMonth() + 1) === 12) && (this.currentDate.getDate() >= 1)) ||
      (((this.currentDate.getMonth() + 1) === 1) && (this.currentDate.getDate() <= 10))
    );
  }

  get march8th() {
    return (((this.currentDate.getMonth() + 1) === 3) && (this.currentDate.getDate() >= 1) && (this.currentDate.getDate() <= 9));
  }
}
