import {Component, NgModule, OnInit} from '@angular/core';
import { DataService } from '../../../services/data.service';
import {RouterModule} from '@angular/router';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { JQ } from '../../../models/jq';
import {Subscription} from 'rxjs';

@Component({
  standalone: true,
  selector: 'app-block-partners',
  templateUrl: './block-partners.component.html',
  styleUrls: ['./block-partners.component.css'],
  imports: [
    RouterModule,
    SlickCarouselModule
  ],
})

export class BlockPartnersComponent implements OnInit {
  subs: Subscription;
  mobile: boolean;
  constructor(
    public dataService: DataService,
    public jq: JQ,
  ) {
    this.subs = new Subscription();
  }
  private reload = (id) => {
    this.loadScripts(id);
    this.jq.footerSlider();
  }

  ngOnInit() {
    if (this.dataService.struct){
      this.reload(this.dataService.struct.id);
    }
    this.subs.add(this.dataService.sub().struct.subscribe((x: any) => this.reload(x.id)));
    this.mobile = this.dataService.mobile;
    // this.subs.add(this.dataService.sub().mode.subscribe(m => this.reload()));
  }

  loadScripts(id) {
    const dynamicScripts = [
      'https://pos.gosuslugi.ru/bin/script.min.js'
    ];
    dynamicScripts.push('../../../../assets/js/widget-' + (id || 1) + '.js');
    console.log('dynamicScripts', dynamicScripts);
    for (let i = 0; i < dynamicScripts.length; i++) {
      const node = document.createElement('script');
      node.src = dynamicScripts[i];
      node.type = 'text/javascript';
      node.async = false;
      node.charset = 'utf-8';
      document.getElementsByTagName('head')[0].appendChild(node);
    }
  }
}
