import {EventEmitter, Injectable} from '@angular/core';

export class Event {constructor(public name: string, public data: any) {}}

@Injectable({
  providedIn: 'root'
})

export class EventsService {
  public event$: EventEmitter<Event>;

  constructor() {
    this.event$ = new EventEmitter();
  }

  public send = (e: Event) => {
    console.log('%c = send feedback =', 'background: green; color: white');
    console.log('Event events.service ' + e.name + ' send.', e.data);
    console.log('= Event events.service e.data = ', e.data);
    console.log('Event events.service ', e);

    this.event$.emit(e);
  }
}
