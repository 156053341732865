import { SliderComponent } from './../slider/slider.component';
import {Component, OnDestroy, OnInit} from '@angular/core';
import {CommonModule} from "@angular/common";
import {AdmitemslistmenuComponent} from "../../admin/admitemslistmenu/admitemslistmenu.component";
import {Subscription} from "rxjs";
import {EventsService} from "../../../services/events.service";
import {DataService} from "../../../services/data.service";
import {AdminService} from "../../../services/admin.service";
import {NgbModule} from "@ng-bootstrap/ng-bootstrap";

class MainPage {
  id: number;
  order: any;
  slider: any = {};
}

@Component({
  standalone: true,
  selector: 'app-slider-full-width',
  templateUrl: './slider-full-width.component.html',
  styleUrls: ['./slider-full-width.component.css'],
  imports: [
    CommonModule,
    AdmitemslistmenuComponent,
    SliderComponent,
    NgbModule
  ],
})
export class SliderFullWidthComponent implements OnInit, OnDestroy {
  modelname: string;
  model: MainPage;
  borderStyle: any;
  subs: Subscription;
  mode: string;

  private async reload() {
    this.mode = this.dataService.mode;
    const data = await this.dataService.getData(this.modelname, this.dataService.struct.page_main.id, false);
    this.model = (this.mode === 'admin') ? this.admin.fillAdminModel(data, this.modelname) : data;
    console.log('= this.model SliderFullWidthComponent =', this.model);

  }

  constructor(
    private events: EventsService,
    private dataService: DataService,
    public admin: AdminService,
  ) {
    this.modelname = 'page-mains';
    this.model = {
      id: 0,
      order: {},
      slider: {}
    };
    this.borderStyle = {};
    this.subs = new Subscription();
  }

  ngOnInit() {
    console.log('MainComponent INIT', this.dataService.struct);

    if (this.dataService.struct) {
      this.reload();
    }
    this.subs.add(this.events.event$.subscribe(e => ((e.name === 'reload') && (e.data === (this.modelname + ':' + this.model.id)) && this.reload())));
    this.subs.add(this.dataService.sub().struct.subscribe((x: any) => this.reload()));
    this.subs.add(this.dataService.sub().mode.subscribe(m => this.reload()));
  }

  bordermark = (p, state: string | boolean) => {
    this.borderStyle = {};
    if (state) {
      this.borderStyle['' + state] = 'border: solid 1px #7d7e92;';
      this.admin.popoverOpen(p, '' + state);
    }
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }
}

