import { Component, OnInit } from '@angular/core';
import { DataService } from 'src/app/services/data.service';
import { ActivatedRoute, Router} from '@angular/router';
import { NgClass } from '@angular/common';

@Component({
    standalone: true,
    selector: 'app-admin-panel-n',
    templateUrl: './admin-panel-n.component.html',
    styleUrls: ['./admin-panel-n.component.css'],
    imports: [ NgClass ]
})
export class AdminPanelNComponent implements OnInit {
    public selectedAdmArea: string;

    constructor(
        private dataService: DataService,
        private route: ActivatedRoute,
        private router: Router        
    ) {}

    ngOnInit(): void {
        console.log('AdminPanelNComponent is loading.');
        this.selectedAdmArea = "";
    }

    toPublicMode = () => {
        this.dataService.mode = 'public';
        // @ts-ignore
        if (!~this.route.snapshot._routerState.url.indexOf('adm/')) {
          // @ts-ignore
          // this.dataService.setRoute(this.route.snapshot._routerState.url, {mode: 'public'});
          this.dataService.setRoute(this.route.snapshot._routerState.url, {});
        } else {
          // this.dataService.setRoute('/', {mode: 'public'});
          this.dataService.setRoute('/', {});
        }
      }

    imageUpload() {
        this.selectedAdmArea = 'upload';
        window.open(this.dataService.siteurl + '/imgupload');
    }
    
    integrity() {
        this.selectedAdmArea = 'integration';
        this.router.navigate(['/adm/integrity-n']);
    }

    content() {
        this.selectedAdmArea = 'content';
        this.router.navigate(['/adm/content-n']);
    }
}