import { Component, OnInit } from '@angular/core';
import { DataService } from '../../../services/data.service';
import { ActivatedRoute, Router} from '@angular/router';
import { environment } from '../../../../environments/environment';

@Component({
  standalone: true,
  selector: 'app-admin-panel',
  templateUrl: './admin-panel.component.html',
  imports: []
})
export class AdminPanelComponent implements OnInit {
  constructor(
    private dataService: DataService,
    private route: ActivatedRoute,
    private router: Router
  ) {
    console.log('AdminPanel constructor run');
  }

  ngOnInit(): void {
    console.log('AdminPanel init run');
  }

  toPublicMode = () => {
    console.log('this.route.snapshot', this.route.snapshot);
    this.dataService.mode = 'public';
    // @ts-ignore
    if (!~this.route.snapshot._routerState.url.indexOf('adm/')){
      // @ts-ignore
      // this.dataService.setRoute(this.route.snapshot._routerState.url, {mode: 'public'});
      this.dataService.setRoute(this.route.snapshot._routerState.url, {});
    } else {
      // this.dataService.setRoute('/', {mode: 'public'});
      this.dataService.setRoute('/', {});
    }
  }

  imageUpload = () => {
    window.open(this.dataService.siteurl + '/imgupload');
  }

  integrity = () => {
    this.router.navigate(['/adm/integrity']);
  }

  content = () => {
    this.router.navigate(['/adm/content']);
  }

  // uni = () => {
  //   this.router.navigate(['/adm/uni']);
  // }
}

