<ng-template #tipContent>Всего выделено <b>{{selectedItems.length}}</b><br>Отменить все.</ng-template>
<div class="modal-header">
  <form style="width: 98%;">
    <div class="form-group form-inline">
<!--      <input class="form-control ml-2 col-4" type="text" name="searchTerm"-->
<!--             [disabled]="selectedItems.length"-->
<!--             [(ngModel)]="searchTerm" placeholder="Поле поиска..."/>-->
      <input class="form-control ml-2 col-4" type="text" name="searchTerm"
             [(ngModel)]="searchTerm" placeholder="Поле поиска..."/>
      <div class="ml-3 col-2"><span *ngIf="loading$ | async">Поиск...</span></div>
      <div ngbDropdown class="d-inline-block col-5">
        <button class="btn btn-outline-primary" id="dropdownBasic1" ngbDropdownToggle>{{selectedLink.title}}</button>
        <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
          <button ngbDropdownItem *ngFor="let item of links" (click)="setLinkModel(item)">{{item.title}}</button>
        </div>
      </div>
    </div>
  </form>
</div>
<div class="modal-body">
  <div class="row">
    <table class="table table-striped">
      <thead>
      <tr>
        <th scope="col">
          <button
            class="btn btn-outline-warning"
            (click)="unselectAll()"
            placement="right"
            [ngbTooltip]="tipContent">&nbsp;{{selectedItems.length}}&nbsp;</button></th>
        <th scope="col">Служебное название</th>
        <th scope="col">Наименование</th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let item of items$ | async;">
        <td>
          <input
            [disabled]="schema.links[field] && schema.links[field].single && selectedItems && selectedItems.length &&(selectedItems[0] != item.id)"
            [(ngModel)]="selected[item.id]"
            (ngModelChange)="onItemChange()"
            type="checkbox" ngbButton>
        </td>
        <td>
          <ngb-highlight [result]="item.name" [term]="searchTerm"></ngb-highlight>
        </td>
        <td>
          <ngb-highlight [result]="item.title" [term]="searchTerm"></ngb-highlight>
        </td>
      </tr>
      </tbody>
    </table>
  </div>
</div>
<div class="modal-footer">
  <button class="btn btn-outline-secondary" (click)="modal.dismiss()">Отмена</button>
  <button class="btn btn-success" (click)="modal.close([selectedLink, selectedItems])">Привязать</button>
</div>
