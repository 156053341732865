import { NavHeaderItemComponent } from './../nav-header-item/nav-header-item.component';
import {Component, OnInit, Input} from '@angular/core';
import { AdminService } from '../../../services/admin.service';
import { AdmitemslistmenuComponent } from '../../admin/admitemslistmenu/admitemslistmenu.component';
import { NgbPopoverModule } from '@ng-bootstrap/ng-bootstrap';
import { CommonModule } from '@angular/common';
import { DataService } from '../../../services/data.service';
import {Subscription} from 'rxjs';
import {EventsService} from '../../../services/events.service';

@Component({
  standalone: true,
  selector: 'app-nav-header',
  templateUrl: './nav-header.component.html',
  styleUrls: ['./nav-header.component.css'],
  imports: [
    AdmitemslistmenuComponent,
    CommonModule,
    NgbPopoverModule,
    NavHeaderItemComponent,
  ]
})
export class NavHeaderComponent implements OnInit {
  @Input() set _model(s) {
    console.log('NavHeaderComponent struct input', s);
    this.model = s.navheader;
    this.model2 = s.navheader2;
    this.mode = this.dataService.mode;
  }
  private subs: Subscription;

  model: any = {};
  model2: any = {};
  modelname: string;
  mode: string;

  public borderStyle: any;

  constructor(
    private admin: AdminService,
    private dataService: DataService,
    private events: EventsService,
  ) {
    this.modelname = 'menus';

    this.subs = new Subscription();

    this.mode = '';
    this.borderStyle = {};
  }

  ngOnInit(): void {
    this.subs.add(this.dataService.sub().mode.subscribe(m => {
      this.reload(this.model.id)
      this.reload(this.model2.id, true)
    }));
    this.subs.add(
      this.events.event$.subscribe(e => {
        if ((e.name === 'reload')) {
          if (e.data === (this.modelname + ':' + this.model.id)) {
            this.reload(this.model.id);
          } else if (e.data === (this.modelname + ':' + this.model2.id)) {
            this.reload(this.model2.id, true);
          } else if (e.data.split(':')[0] === 'navigations') {
            this.reload(this.model.id);
            this.reload(this.model2.id, true);
          }
        }
      })
    );
  }

  private reload = async (id, second?) => {
    this.mode = this.dataService.mode;

    if (this.dataService.struct){
      const data = (!!id) && (await this.dataService.getData(this.modelname, id, {}));
      if(!second) {
        this.model = (data && this.mode === 'admin') ? this.admin.fillAdminModel(data, this.modelname) : data;
      }else if(second) {
        this.model2 = (data && this.mode === 'admin') ? this.admin.fillAdminModel(data, this.modelname) : data;
      }
      this.dataService.spinner = false;
    }
  }

  bordermark = (p, state: string | boolean) => {
    this.borderStyle = {};
    if (state) {
      this.borderStyle['' + state] = 'border: solid 1px #7d7e92;';
      this.admin.popoverOpen(p, '' + state);
    }
  }
}

