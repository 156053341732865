<div class="container-upload">
  <img class="{{cls}} my-2" src="{{imgSRC}}" (mouseover)="over()" (mouseleave)="leave()" />
  <div class="row mx-3 mb-2" *ngIf="!croppedImage">
    <input type="file" id="file" (change)="fileChangeEvent($event)" #inputFile />
  </div>
  <div class="row mx-1">
    <image-cropper
      [imageChangedEvent]="imageChangedEvent"
      [maintainAspectRatio]="conf[cls] && conf[cls].width && conf[cls].height"
      [aspectRatio]="conf[cls] && conf[cls].height && conf[cls].width/conf[cls].height"
      [resizeToWidth]="conf[cls] && conf[cls].width"
      [resizeToHeight]="conf[cls] && conf[cls].height"
      format="png"
      (imageCropped)="imageCropped($event)"
      (imageLoaded)="imageLoaded()"
      (cropperReady)="cropperReady()"
      (loadImageFailed)="loadImageFailed()"></image-cropper>
  </div>
</div>
