import {Component, Input, NgModule} from '@angular/core';
import { DataService } from '../../../services/data.service';
import { RouterModule } from '@angular/router';
import { CommonModule} from '@angular/common';
import { EventsService } from '../../../services/events.service';

@Component({
  standalone: true,
  selector: 'app-block-link-subscribe',
  templateUrl: './block-link-subscribe.component.html',
  styleUrls: ['./block-link-subscribe.component.css'],
  imports: [
    RouterModule,
    CommonModule
  ]
})

export class BlockLinkSubscribeComponent{
  @Input() set header(h) {
    if (h.id){
      this.model = new Object(h);
    }
    console.log('BlockLinkSubscribeComponent model after input', this.model);
  }
  model: any = {};

  constructor(
    private dataService: DataService,
    private events: EventsService
  ) {}

  get maincontact(){
    return (this.dataService.struct && this.dataService.struct.main_contact) || {phoneoms: {}};
  }

  fbopen = () => {
    const params = {
      name: 'feedback',
      data: '',
    };
    console.log('= this.dataService.context =', this.dataService.context);
    params.data = {
      doctors: 'doctor',
      units: 'unit',
      clinic: 'unit',
      centers: 'unit',
      pharmacies: 'pharmacy'
    }
    [this.dataService.context.modelname] + ':' + this.dataService.context.id;

    // if (this.dataService.context.modelname === 'doctors') {
    //   params.data = 'doctor' + ':' + this.dataService.context.id;
    // }
    // if (this.dataService.context.modelname === 'units') {
    //   params.data = 'unit' + ':' + this.dataService.context.id;
    // }
    // if (this.dataService.context.modelname === 'centers') {
    //   params.data = 'unit' + ':' + this.dataService.context.id;
    // }
    // if (this.dataService.context.modelname === 'services') {
    //   params.data = 'service' + ':' + this.dataService.context.id;
    // }
    // if (this.dataService.context.modelname === 'pharmacies') {
    //   params.data = 'pharmacy' + ':' + this.dataService.context.id;
    // }
    console.log('= block-link-sub - params fbopen =', params);
    this.events.send(params);
  }

  apointment = () => {
    const params = {
      apType: 'dms'
    };
    params[this.dataService.context.modelname] = this.dataService.context.id;
    this.dataService.setRoute('apointment', params);
    console.log('= params apointment =', params);
  }
}
