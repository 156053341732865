import { DataService } from '../../../services/data.service';
import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { JQ } from '../../../models/jq';
import { Globals } from '../../../models/globals';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { SlickCarouselModule } from 'ngx-slick-carousel';

@Component({
  standalone: true,
  selector: 'app-slider',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.css'],
  imports: [
    CommonModule,
    RouterModule,
    SlickCarouselModule
  ]
})
export class SliderComponent implements OnInit {
  @Input() id: number;
  @Input() gallery: boolean | null;

  modelname = 'sliders';
  model = {
    slideritems: []
  };
  jqloaded = 0;

  constructor(
    private jq: JQ,
    public dataService: DataService,
    public globals: Globals,
  ) {
  }

  ngOnInit() {
    console.log('= Load Component Slider =');
    console.log('Slider id', this.id);
    this.reload();
  }

  private reload = () => {
    this.dataService.getData(this.modelname, this.id, {})
      .then(async (data: any) => {
        this.model = data;
        if (this.gallery){
          let items = [];
          for (const item of this.model.slideritems){
            console.log('item', item);
            if (item.type && (item.type.id === 4)){
              items.push({
                url: item.data.mainimage,
                type: 'image',
                title: ''
              });
              const gal: any = (await this.dataService.getData('galeries', item.data.id, false));
              console.log('итемз оф слайдер', gal.items);
              for (const image of gal.items){
                items.push({
                  url: image.url,
                  type: 'image',
                  title: image.title || ''
                });
              }
            }
          }
          this.model.slideritems = items;
        }
        console.log('= SliderData =', this.model);

        if (!this.jqloaded) {
          this.jq.bigSlider();
          this.jqloaded++;
        }
      })
      .catch(err => console.error(err));
  }
}

