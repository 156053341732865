<div class="btn-toolbar" role="toolbar" aria-label="Toolbar with button groups">
  <div class="btn-group-sm mr-2" role="group">
    <button *ngIf="can('add')" type="button" class="btn btn-sm" ngbTooltip="Новый" placement="top" (click)="send('add')">
      <fa-icon [icon]="faPlus" class="text-success"></fa-icon>
    </button>
    <button *ngIf="can('edit')" type="button" class="btn btn-sm" ngbTooltip="Изменить" placement="top" (click)="send('edit')">
      <fa-icon [icon]="faPen" class="text-info"></fa-icon>
    </button>
    <button *ngIf="can('delete')" type="button" class="btn btn-sm" ngbTooltip="Удалить" placement="top" (click)="send('delete')">
      <fa-icon [icon]="faTrash" class="text-danger"></fa-icon>
    </button>
  </div>
  <div class="btn-group mr-2" role="group">
    <button *ngIf="can('upload')" type="button" class="btn btn-sm" ngbTooltip="Upload" placement="top" (click)="send('upload')">
      <fa-icon [icon]="faUpload" class="text-success"></fa-icon>
    </button>
    <button *ngIf="can('link')" type="button" class="btn btn-sm" ngbTooltip="Привязать" placement="top" (click)="send('link')">
      <fa-icon [icon]="faLink" class="text-success"></fa-icon>
    </button>
    <button *ngIf="can('linksub')" type="button" class="btn btn-sm" ngbTooltip="Привязать дочерний элемент" placement="top" (click)="send('linksub')">
      <fa-icon [icon]="faLink" class="text-success"></fa-icon>
    </button>
    <button *ngIf="can('unlink')" type="button" class="btn btn-sm" ngbTooltip="Отвязать" placement="top" (click)="send('unlink')">
      <fa-icon [icon]="faUnlink" class="text-warning"></fa-icon>
    </button>
    <button *ngIf="can('up')" type="button" class="btn btn-sm" ngbTooltip="Передвинуть" placement="top" (click)="send('up')">
      <fa-icon [icon]="faLongArrowAltUp"></fa-icon>
    </button>
    <button *ngIf="can('down')" type="button" class="btn btn-sm" ngbTooltip="Передвинуть" placement="top" (click)="send('down')">
      <fa-icon [icon]="faLongArrowAltDown"></fa-icon>
    </button>
    <button *ngIf="can('sort')" type="button" class="btn btn-sm" ngbTooltip="Сортировать" placement="top" (click)="send('sort')">
      <fa-icon [icon]="faSortAlphaDown"></fa-icon>
    </button>
  </div>
</div>
