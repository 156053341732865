<ng-template #admbuttons let-st="state">
  <app-admitemslistmenu [state]="st"></app-admitemslistmenu>
</ng-template>

<div class="header-nav__item">

<!--  <div class="header-nav__item-title">-->

<!--    <ng-container *ngIf="mode !== 'admin'">-->
<!--      <a *ngIf="model.url && (model.url.indexOf('://') > 0)" [href]="[model.url]" target="_blank" rel="noreferrer">-->
<!--        {{model.title}}-->
<!--      </a>-->
<!--      <a *ngIf="model.url && (model.url.indexOf('://') === -1)" [routerLink]="['/' + model.url]" [routerLinkActive]="['active']">-->
<!--        {{model.title}}-->
<!--      </a>-->
<!--      <div *ngIf="!model.url || model.url === ''">{{model.title}}</div>-->
<!--    </ng-container>-->

<!--    <ng-container *ngIf="mode === 'admin'">-->
<!--      <a *ngIf="model.url && model.url != ''" [routerLink]="[model.url]">{{model.title}}</a>-->
<!--      <div *ngIf="!model.url || model.url == ''">{{model.title}}</div>-->
<!--    </ng-container>-->

<!--    <svg *ngIf="mode === 'admin' || model.items.length > 0">-->
<!--      <use xlink:href="#menu-arrow"></use>-->
<!--    </svg>-->
<!--  </div>-->
<!--  <div class="header-nav__item-list" *ngIf="(model.items && model.items.length > 0) || mode === 'admin'">-->
<!--    <div *ngFor="let item of (((mode=='admin') && model.items) || [])"-->
<!--         [ngbPopover]="admbuttons" #p="ngbPopover" placement="left"-->
<!--         (click)="admin.actionDefault(item.state)"-->
<!--         (mouseenter)="bordermark(p, item.state)"-->
<!--         (mouseleave)="bordermark(p, false)"-->
<!--         triggers="manual" style="{{(item.style && item.style != '') ? (item.style): ('')}} {{borderStyle[item.state]||''}}"-->
<!--         [routerLink]="['/' + item.url]" [routerLinkActive]="['active']">-->
<!--      {{(model.items && model.items.length > 0 && model.items[0].id >= 0)? (item.title): ('* добавить пункт меню *')}}-->
<!--    </div>-->

<!--    <ng-container *ngFor="let item of (((mode!='admin') && model.items) || [])">-->
<!--      <a *ngIf="item.url && (item.url.indexOf('://') > 0)" [href]="[item.url]" target="_blank" rel="noreferrer">-->
<!--        {{item.title}}-->
<!--      </a>-->
<!--      <a *ngIf="item.url && (item.url.indexOf('://') === -1)" [routerLink]="['/' + item.url]" [routerLinkActive]="['active']" style="{{(item.style && item.style != '') ? (item.style): ('')}}">-->
<!--        {{item.title}}-->
<!--      </a>-->
<!--    </ng-container>-->

<!--  </div>-->

  <div id="MainMenu">
    <div class="list-group panel">
      <a *ngIf="((model.items && model.items.length > 0) || mode === 'admin')" [href]="'#d' + model.id" class="list-group-item collapsed" data-toggle="collapse" data-parent="#MainMenu">
        <div class="item-img">
          <img  src="{{(model.img && model.img !== '') ? (model.img) : ('../../../assets/img/ball.webp')}}" alt="" >
        </div>
        <ng-container *ngIf="mode !== 'admin'">
          <a *ngIf="model.url && (model.url.indexOf('://') > 0)" [href]="[model.url]" target="_blank" rel="noreferrer" class="item-link">
            {{model.title}}
          </a>
          <a *ngIf="model.url && (model.url.indexOf('://') === -1)" [routerLink]="['/' + model.url]" [routerLinkActive]="['active']" class="item-link">
            {{model.title}}
          </a>
          <div *ngIf="!model.url || model.url === ''" class="item-link">{{model.title}}</div>
        </ng-container>
                <ng-container *ngIf="mode === 'admin'">
          <a *ngIf="model.url && model.url != ''" [routerLink]="[model.url]" class="item-link">{{model.title}}</a>
          <div *ngIf="!model.url || model.url == ''" class="item-link">{{model.title}}</div>
        </ng-container>
        <svg *ngIf="mode === 'admin' || model.items.length > 0">
          <use xlink:href="#menu-arrow"></use>
        </svg>
      </a>


      <div *ngIf="((!model.items || model.items.length <= 0) && mode !== 'admin')" class="list-group-item" >
        <div class="item-img">
          <img  src="{{(model.img && model.img !== '') ? (model.img) : ('../../../assets/img/ball.webp')}}" alt="" >
        </div>
<!--        <ng-container *ngIf="mode !== 'admin'">-->
          <a *ngIf="model.url && (model.url.indexOf('://') > 0)" [href]="[model.url]" target="_blank" rel="noreferrer" class="item-link">
            {{model.title}}
          </a>
          <a *ngIf="model.url && (model.url.indexOf('://') === -1)" [routerLink]="['/' + model.url]" [routerLinkActive]="['active']" class="item-link">
            {{model.title}}
          </a>
          <div *ngIf="!model.url || model.url === ''" class="item-link">{{model.title}}</div>
<!--        </ng-container>-->
      </div>

      <div *ngIf="(model.items && model.items.length > 0) || mode === 'admin'" class="collapse" [id]="'d'+[model.id]">
          <div *ngFor="let item of (((mode=='admin') && model.items) || [])"
               [ngbPopover]="admbuttons" #p="ngbPopover" placement="left"
               (click)="admin.actionDefault(item.state)"
               (mouseenter)="bordermark(p, item.state)"
               (mouseleave)="bordermark(p, false)"
               triggers="manual"
               style="{{(item.style && item.style != '') ? (item.style): ('')}} {{borderStyle[item.state]||''}}"
               [routerLink]="['/' + item.url]" [routerLinkActive]="['active']"
                class="list-group-item">
            {{(model.items && model.items.length > 0 && model.items[0].id >= 0)? (item.title): ('* добавить пункт меню *')}}
          </div>

        <ng-container *ngFor="let item of (((mode!='admin') && model.items) || [])">
          <a
            *ngIf="item.url && (item.url.indexOf('://') > 0)" [href]="[item.url]"
            class="list-group-item" target="_blank" rel="noreferrer">
            {{item.title}}
          </a>
          <a
            *ngIf="item.url && (item.url.indexOf('://') === -1)" [routerLink]="['/' + item.url]" [routerLinkActive]="['active']" style="{{(item.style && item.style != '') ? (item.style): ('')}}"
            class="list-group-item">
            {{item.title}}
          </a>
          <a
            *ngIf="item.title && !item.url" [routerLink]="['/']" [routerLinkActive]="['active']" style="{{(item.style && item.style != '') ? (item.style): ('')}}"
            class="list-group-item">
            {{item.title}}
          </a>
        </ng-container>
      </div>
    </div>
  </div>
</div>
