<div class="modal-header">
  <h4 class="modal-title" id="modal-title">{{titleText}}</h4>
</div>
<div class="modal-body">
  <p *ngIf="baseText"><strong>{{baseText}}</strong></p>
  <p *ngIf="subText">{{subText}}</p>
</div>
<div class="modal-footer">
  <button class="btn btn-outline-secondary" (click)="modal.dismiss()">{{cancelButtonText}}</button>
  <button class="btn btn-danger" (click)="modal.close(true)">{{submitButtonText}}</button>
</div>
