import {Component, OnInit} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';
import { AuthService } from '../../services/auth.service';
import { DataService } from '../../services/data.service';
import { CommonModule } from '@angular/common';
// import { AlertService } from '../alert.service';

@Component({
  standalone: true,
  selector: 'app-login',
  templateUrl: 'login.component.html',
  imports: [
    CommonModule,
    ReactiveFormsModule,
  ]
})
export class LoginComponent implements OnInit {
  private modeStr: string;
  loginForm: FormGroup;
  loading = false;
  submitted = false;
  returnUrl: string;

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private authService: AuthService,
    private dataService: DataService
  ) {}

  ngOnInit() {
    console.log('SET HFVISIBLE false by Login');
    this.dataService.hfVision = false;
    // get return url from route parameters or default to '/'
    this.returnUrl = (this.route.snapshot.queryParams && this.route.snapshot.queryParams.returnUrl) || '/';
    const rurl = '' + this.returnUrl;
    console.log('rurl', rurl);
    // redirect to returnUrl if already logged in
    this.modeStr = (this.route.snapshot.queryParams && this.route.snapshot.queryParams.mode) || 'public';
    if (this.authService.currentUserValue || this.modeStr === 'public') {
      // TODO переключение в режим администрирования допускать только при наличии соответствующей привилегии у учетной записи
      this.dataService.mode = this.modeStr;
      this.router.navigate([this.returnUrl]);
    }

    this.loginForm = this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required]
    });
  }

  // convenience getter for easy access to form fields
  get f() { return this.loginForm.controls; }

  onSubmit() {
    this.submitted = true;

    console.log('!!!');

    // reset alerts on submit
    // this.alertService.clear();

    // stop here if form is invalid
    if (this.loginForm.invalid) {
      return;
    }

    this.loading = true;
    this.authService.login(this.f.username.value, this.f.password.value)
      .pipe(first())
      .subscribe(
        data => {
          // console.log('navigate to', this.returnUrl);
          this.dataService.mode = this.modeStr;
          this.router.navigate([this.returnUrl]);
          // location.href = this.returnUrl;
        },
        error => {
          // this.alertService.error(error);
          this.loading = false;
        });
  }
}

@Component({template: ``})
export class LogoutComponent {
  constructor(private router: Router) {
    sessionStorage.removeItem('currentUser');
    console.log('Logged out!');
    // this.router.navigate(['/']);
    location.href = '/';
  }
}

