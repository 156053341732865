<div class="container">
  <div class="row footer-address justify-content-between">
    <div class="col-auto footer-logo d-none d-xl-flex">
      <img class="footer_logo_img" src="../../../assets/img/logo-sm-{{model.id}}.png" alt=""/>
      <div class="footer-logo__text">
        <small [innerHTML]="model.orgform"></small>
        <div class="big" [innerHTML]="model.title"></div>
      </div>
    </div>

    <div class="flex-block">
      <div class="col-md-auto col-12" style="max-width: 240px; margin-left: auto; margin-right: auto;">
        <span>
          {{(model.address.street && model.address.street != '')? (model.address.street): ''}}<!-- 
       -->{{(model.address.house && model.address.house != '')? (', ' + model.address.house  + ', '): ''}}
          <br *ngIf="model.address.house && model.address.house != ''">
          {{(model.address.city && model.address.city != '')? (model.address.city) : ''}}<!-- 
       -->{{(model.address.region && model.address.region != '')? (', ' + model.address.region) : ''}}<!-- 
       -->{{(model.address.postcode && model.address.postcode != '')? (', ' + model.address.postcode) : ''}}<!-- 
       -->{{(model.address.mailbox && model.address.mailbox != '')? (', а/я ' + model.address.mailbox): ''}}
        </span>
      </div>

      <!--<div class="col-md-auto col-12">-->
      <!--  Телефон (ОМС):<br/>-->
      <!--  <span><a style="color: #55566a;" href="tel:{{model.phoneoms.number}}">{{model.phoneoms.number}}</a></span>-->
      <!--  <br/>-->
      <!--  Факс:-->
      <!--  <span>{{model.phonefax.number}}</span>-->
      <!--</div>-->

      <div class="col-md-auto col-12">
        <!--  Телефон (платно и по ДМС):<br/><a style="color: #55566a;" href="tel: {{model.phonedms.number}}">{{model.phonedms.number}}</a><br/>-->
        Почта: <a *ngFor="let item of model.email" style="color: #55566a;" href="mailto:{{item.email}}">{{item.email}}</a>
      </div>
    </div>

  </div>
</div>

<div class="d-md-none">
  <div class="footer-social">
    <ng-container *ngFor="let item of model.socialnets">
      <a *ngIf="item.socialnet" class="link-icon" rel="noopener" target="_blank" [href]="(item.socialnet && item.socialnet.urlprefix) ? (item.socialnet.urlprefix + ((item.socialnet.urlprefix[item.socialnet.urlprefix.length - 1] === '/')? (''): ('/')) + item.url) : ''">
        <svg>
          <use attr.xlink:href="#{{item.socialnet && item.socialnet.svgclass}}"></use>
        </svg>
      </a>
    </ng-container>
  </div>
</div>
