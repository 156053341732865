<ng-template #admbuttons let-st="state">
  <app-admitemslistmenu [state]="st"></app-admitemslistmenu>
</ng-template>

<div class="container-slider-full-width">
  <div class="mp-first-screen-sm-revers">
    <app-slider *ngIf="model.slider && model.slider.id && mode!=='admin'" [id]="model.slider.id"></app-slider>

    <div *ngIf="mode==='admin'" [ngbPopover]="admbuttons" triggers="manual" #p = "ngbPopover" placement="top"
         (mouseenter)="bordermark(p, model.slider.state)" (mouseleave) ="bordermark(p, false)"
         container="body" style="{{borderStyle[model.slider.state]}}">
      <app-slider *ngIf="model.slider && model.slider.id" [id]="model.slider.id"></app-slider>
      <h4 *ngIf="!model.slider || !model.slider.id ">Место для слайдера</h4>
    </div>
  </div>
</div>
