<div *ngIf="data" class="row">
  <button class="btn btn-success offset-1" (click)="upload()">Загрузить и скопировать ссылку</button>
</div>
<div *ngIf="url" class="row">
  <div class="offset-2">{{url}}</div>
</div>
<div class="row">
  <div class="col-10 offset-1">
    <app-upload class="ml-2"
              [src]="''"
              [cls]="'direction_img_img'"
              (uploadedData)="onSubDataUpdate($event)"></app-upload>
  </div>
</div>
<div *ngIf="url" class="row">
  <div class="offset-2">{{url}}</div>
</div>
<div *ngIf="data" class="row">
  <button class="btn btn-success offset-1" (click)="upload()">Загрузить и скопировать ссылку</button>
</div>
