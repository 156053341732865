import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class StrapiService {
  constructor() { }
  public data = {
    // faq: [
    //   {
    //     id: 1,
    //     faqspec: 'Специальность 1', // (список) Выберите специальность - это в форме Задать вопрос
    //     nameGuest: 'Анастасия', // (строка) имя гостя/пользователя
    //     question: 'Доброго времени суток! Получил направление на проведение радиойодтерапии натрием йодидом 131 рака щитовидной железы по квоте. Сколько это займёт время. Эта информация нужна для приобретения билетов на самолёт. И второй вопрос - кто будет выдавать листок нетрудоспособности? Наши местные или Вы', // вопрос
    //     email: 'user@user.ru',
    //     phone: '+7-923-456-9874',
    //     organization: 'МБОУ "Лицей №2"', // есть в форме Задать Вопрос
    //     created_at: '2020-03-13T06:53:42.061Z', // дата создания материала - уже ЕСТЬ в структуре
    //     answer: 'Здравствуйте, Михаил Викторович! Продолжительность данного курса составляет 2-7 (зависит от распространенности процесса, количества очагов гиперфиксации и их количества, как выводит организм излишки РФП и тд - все индивидуально) суток в круглосуточном стационаре. Выписка осуществляется после проведения дозиметрического контрольного измерения на расстоянии 1 м от тела (НРБ-99/2009). При превышении нормы, выписка невозможна. В аэропортах установлены рамки контроля гамма-излучения. При повышенном уровне Вас не пропустят в аэропорт и самолет. Покупка обратных билетов не желательна, придется возвращать в случаи задержки в отделение. Выдадим первичный б\\л со дня госпитализации, если б\\л будет выдан по месту жительства, лучше оформить по день госпитализации (дата указанная в ВК), чтобы мы смогли продлить следующим днем или получить дома б\\л на все время госпитализации + на дорогу.',
    //     doctor: // связь с доктором
    //       {
    //         id: 2,
    //         lastName: 'Морозова',
    //         firstName: 'Екатерина',
    //         middleName: 'Владимировна',
    //         specialization: 'ЛОР',
    //         main_photo: {
    //           id: 11,
    //           url: '../../assets/img/doctors/doc-5.png'
    //         }
    //       }
    //   },
    //   {
    //     id: 2,
    //     faqspec: 'Специальность 2', // Выберите специальность - это в форме Задать вопрос
    //     nameGuest: 'Анастасия Викторовна', // имя гостя/пользователя
    //     // tslint:disable-next-line:max-line-length
    //     question: 'Доброго времени суток! Получил направление на проведение радиойодтерапии натрием йодидом 131 рака щитовидной железы по квоте. Сколько это займёт время. Эта информация нужна для приобретения билетов на самолёт. И второй вопрос - кто будет выдавать листок нетрудоспособности? Наши местные или Вы', // вопрос
    //     email: 'user@user.ru',
    //     phone: '+7-923-456-9874',
    //     organization: 'МБОУ "Лицей №2"',
    //     created_at: '2020-05-13T06:53:42.061Z', // дата создания материала - уже ЕСТЬ в структуре
    //     // tslint:disable-next-line:max-line-length
    //     answer: 'Здравствуйте, Анастасия Викторовна! Продолжительность данного курса составляет 2-7 (зависит от распространенности процесса, количества очагов гиперфиксации и их количества, как выводит организм излишки РФП и тд - все индивидуально) суток в круглосуточном стационаре. Выписка осуществляется после проведения дозиметрического контрольного измерения на расстоянии 1 м от тела (НРБ-99/2009). При превышении нормы, выписка невозможна. В аэропортах установлены рамки контроля гамма-излучения. При повышенном уровне Вас не пропустят в аэропорт и самолет. Покупка обратных билетов не желательна, придется возвращать в случаи задержки в отделение. Выдадим первичный б\\л со дня госпитализации, если б\\л будет выдан по месту жительства, лучше оформить по день госпитализации (дата указанная в ВК), чтобы мы смогли продлить следующим днем или получить дома б\\л на все время госпитализации + на дорогу.',
    //     doctor: // связь с доктором
    //       {
    //         id: 2,
    //         lastName: 'Замза',
    //         firstName: 'Грегор',
    //         middleName: '',
    //         specialization: 'Уролог',
    //         main_photo: {
    //           id: 16,
    //           url: '../../assets/img/doctors/doc-2.png'
    //         }
    //       }
    //   },
    //   {
    //     id: 3,
    //     faqspec: 'Специальность 1', // Выберите специальность - это в форме Задать вопрос
    //     nameGuest: 'Анастасия', // имя гостя/пользователя
    //     // tslint:disable-next-line:max-line-length
    //     question: 'Доброго времени суток! Получил направление на проведение радиойодтерапии натрием йодидом 131 рака щитовидной железы по квоте. Сколько это займёт время. Эта информация нужна для приобретения билетов на самолёт. И второй вопрос - кто будет выдавать листок нетрудоспособности? Наши местные или Вы', // вопрос
    //     email: 'user@user.ru',
    //     phone: '+7-923-456-9874',
    //     organization: 'МБОУ "Лицей №2"',
    //     created_at: '2020-02-12T06:53:42.061Z', // дата создания материала - уже ЕСТЬ в структуре
    //     // tslint:disable-next-line:max-line-length
    //     answer: 'Здравствуйте, Михаил Викторович! Продолжительность данного курса составляет 2-7 (зависит от распространенности процесса, количества очагов гиперфиксации и их количества, как выводит организм излишки РФП и тд - все индивидуально) суток в круглосуточном стационаре. Выписка осуществляется после проведения дозиметрического контрольного измерения на расстоянии 1 м от тела (НРБ-99/2009). При превышении нормы, выписка невозможна. В аэропортах установлены рамки контроля гамма-излучения. При повышенном уровне Вас не пропустят в аэропорт и самолет. Покупка обратных билетов не желательна, придется возвращать в случаи задержки в отделение. Выдадим первичный б\\л со дня госпитализации, если б\\л будет выдан по месту жительства, лучше оформить по день госпитализации (дата указанная в ВК), чтобы мы смогли продлить следующим днем или получить дома б\\л на все время госпитализации + на дорогу.',
    //     doctor: // связь с доктором
    //       {
    //         id: 2,
    //         lastName: 'Морозова',
    //         firstName: 'Екатерина',
    //         middleName: 'Владимировна',
    //         specialization: 'ЛОР',
    //         main_photo: {
    //           id: 11,
    //           url: '../../assets/img/doctors/doc-5.png'
    //         }
    //       }
    //   },
    //   {
    //     id: 4,
    //     faqspec: 'Специальность 2', // Выберите специальность - это в форме Задать вопрос
    //     nameGuest: 'Анастасия Викторовна', // имя гостя/пользователя
    //     // tslint:disable-next-line:max-line-length
    //     question: '«Доброго времени суток! Получил направление на проведение радиойодтерапии натрием йодидом 131 рака щитовидной железы по квоте. Сколько это займёт время. Эта информация нужна для приобретения билетов на самолёт. И второй вопрос - кто будет выдавать листок нетрудоспособности? Наши местные или Вы»', // вопрос
    //     email: 'user@user.ru',
    //     phone: '+7-923-456-9874',
    //     organization: 'МБОУ "Лицей №2"',
    //     created_at: '2020-02-19T06:53:42.061Z', // дата создания материала - уже ЕСТЬ в структуре
    //     // tslint:disable-next-line:max-line-length
    //     answer: 'Здравствуйте, Анастасия Викторовна! Продолжительность данного курса составляет 2-7 (зависит от распространенности процесса, количества очагов гиперфиксации и их количества, как выводит организм излишки РФП и тд - все индивидуально) суток в круглосуточном стационаре. Выписка осуществляется после проведения дозиметрического контрольного измерения на расстоянии 1 м от тела (НРБ-99/2009). При превышении нормы, выписка невозможна. В аэропортах установлены рамки контроля гамма-излучения. При повышенном уровне Вас не пропустят в аэропорт и самолет. Покупка обратных билетов не желательна, придется возвращать в случаи задержки в отделение. Выдадим первичный б\\л со дня госпитализации, если б\\л будет выдан по месту жительства, лучше оформить по день госпитализации (дата указанная в ВК), чтобы мы смогли продлить следующим днем или получить дома б\\л на все время госпитализации + на дорогу.',
    //     doctor: // связь с доктором
    //       {
    //         id: 2,
    //         lastName: 'Замза',
    //         firstName: 'Грегор',
    //         middleName: '',
    //         specialization: 'Уролог',
    //         main_photo: {
    //           id: 16,
    //           url: '../../assets/img/doctors/doc-2.png'
    //         }
    //       }
    //   },
    //   {
    //     id: 5,
    //     faqspec: 'Специальность 1', // Выберите специальность - это в форме Задать вопрос
    //     nameGuest: 'Анастасия', // имя гостя/пользователя
    //     // tslint:disable-next-line:max-line-length
    //     question: '«Доброго времени суток! Получил направление на проведение радиойодтерапии натрием йодидом 131 рака щитовидной железы по квоте. Сколько это займёт время. Эта информация нужна для приобретения билетов на самолёт. И второй вопрос - кто будет выдавать листок нетрудоспособности? Наши местные или Вы»', // вопрос
    //     email: 'user@user.ru',
    //     phone: '+7-923-456-9874',
    //     organization: 'МБОУ "Лицей №2"',
    //     created_at: '2020-02-28T06:53:42.061Z', // дата создания материала - уже ЕСТЬ в структуре
    //     // tslint:disable-next-line:max-line-length
    //     answer: 'Здравствуйте, Михаил Викторович! Продолжительность данного курса составляет 2-7 (зависит от распространенности процесса, количества очагов гиперфиксации и их количества, как выводит организм излишки РФП и тд - все индивидуально) суток в круглосуточном стационаре. Выписка осуществляется после проведения дозиметрического контрольного измерения на расстоянии 1 м от тела (НРБ-99/2009). При превышении нормы, выписка невозможна. В аэропортах установлены рамки контроля гамма-излучения. При повышенном уровне Вас не пропустят в аэропорт и самолет. Покупка обратных билетов не желательна, придется возвращать в случаи задержки в отделение. Выдадим первичный б\\л со дня госпитализации, если б\\л будет выдан по месту жительства, лучше оформить по день госпитализации (дата указанная в ВК), чтобы мы смогли продлить следующим днем или получить дома б\\л на все время госпитализации + на дорогу.',
    //     doctor: // связь с доктором
    //       {
    //         id: 2,
    //         lastName: 'Морозова',
    //         firstName: 'Екатерина',
    //         middleName: 'Владимировна',
    //         specialization: 'ЛОР',
    //         main_photo: {
    //           id: 11,
    //           url: '../../assets/img/doctors/doc-5.png'
    //         }
    //       }
    //   },
    //   {
    //     id: 6,
    //     faqspec: 'Специальность 2', // Выберите специальность - это в форме Задать вопрос
    //     nameGuest: 'Анастасия Викторовна', // имя гостя/пользователя
    //     // tslint:disable-next-line:max-line-length
    //     question: '«Доброго времени суток! Получил направление на проведение радиойодтерапии натрием йодидом 131 рака щитовидной железы по квоте. Сколько это займёт время. Эта информация нужна для приобретения билетов на самолёт. И второй вопрос - кто будет выдавать листок нетрудоспособности? Наши местные или Вы»', // вопрос
    //     email: 'user@user.ru',
    //     phone: '+7-923-456-9874',
    //     organization: 'МБОУ "Лицей №2"',
    //     created_at: '2020-02-13T06:53:42.061Z', // дата создания материала - уже ЕСТЬ в структуре
    //     // tslint:disable-next-line:max-line-length
    //     answer: 'Здравствуйте, Анастасия Викторовна! Продолжительность данного курса составляет 2-7 (зависит от распространенности процесса, количества очагов гиперфиксации и их количества, как выводит организм излишки РФП и тд - все индивидуально) суток в круглосуточном стационаре. Выписка осуществляется после проведения дозиметрического контрольного измерения на расстоянии 1 м от тела (НРБ-99/2009). При превышении нормы, выписка невозможна. В аэропортах установлены рамки контроля гамма-излучения. При повышенном уровне Вас не пропустят в аэропорт и самолет. Покупка обратных билетов не желательна, придется возвращать в случаи задержки в отделение. Выдадим первичный б\\л со дня госпитализации, если б\\л будет выдан по месту жительства, лучше оформить по день госпитализации (дата указанная в ВК), чтобы мы смогли продлить следующим днем или получить дома б\\л на все время госпитализации + на дорогу.',
    //     doctor: // связь с доктором
    //       {
    //         id: 2,
    //         lastName: 'Замза',
    //         firstName: 'Грегор',
    //         middleName: '',
    //         specialization: 'Уролог',
    //         main_photo: {
    //           id: 16,
    //           url: '../../assets/img/doctors/doc-2.png'
    //         }
    //       }
    //   }
    // ],
    handbooks: [
      {
        id: 1,
        name: 'Адентия',
        description: 'Что такое геморрой? Такой вопрос возникает у людей, которые впервые испытали дискомфорт в заднем проходе. Геморрой — это увеличение кавернозных тел (сосудов), сопровождающееся их истончением, деформацией и формированием геморроидальных узлов. В народе часто говорят, что геморроидальный недуг — это варикозная болезнь сосудов анального прохода. Деструкция стенок кишечника может провоцировать анальные кровотечения и выпадение узлов из ануса.',
        anonce: 'УЗИ эластография (соноэластография) – дополнительная технология, используемая во время традиционного УЗИ, которая даёт возможность оценивать эластичность тканей исследуемого органа.',
        bodyText: '<div class="paragraph">Все ткани человека, в том числе и повреждённые тем или иным патологическим процессом, имеют определённую эластичность (жёсткость). Нераковые ткани (здоровые, доброкачественные опухоли, воспалительный процесс) имеют высокую эластичность. В отличии от них, ткани злокачественных новообразований значительно менее эластичны (более жёсткие, жёсткость некоторых из них в несколько раз выше нормы). Во время ультразвуковой эластографии, ультразвуковой луч, излучаемый и воспринимаемый специальным датчиком, словно врач, «ощупывает» ткани исследуемого органа и с помощью программы оценивает их эластичность (жёсткость), что невозможно при обычном УЗИ. После компьютерной обработки изображение передаётся на экран монитора.</div>\n' +
          '            <div class="paragraph">Соноэластографический метод помогает выполнить диагностику злокачественных образований молочной железы, органов мошонки, узловых образований щитовидной железы, поверхностно расположенных лимфатических узлов, мягких тканей и других органов.</div>\n' +
          '            <div class="my-4 my-md-5"><img src="../../assets/img/big-slide-3.png" alt=""/></div>\n' +
          '            <div class="paragraph">Соноэластографический метод помогает выполнить диагностику злокачественных образований молочной железы, органов мошонки, узловых образований щитовидной железы, поверхностно расположенных лимфатических узлов, мягких тканей и других органов.</div>\n' +
          '            <div class="paragraph">Ощущения пациента во время проведения ультразвуковой эластографии такие же, как и при обычном УЗИ. Ни боли, ни дискомфорта пациенты не испытывают.</div>',
        catalog: {
          id: 1,
          name: 'Клинический анализ крови'
        },
        directions: [
          {
            id: 20,
            name: 'Диагностика',
            short_description: 'Проснувшись однажды утром после беспокойного сна, Грегор Замза обнаружил, что он у себя в постели превратился в страшное насекомое. Лежа на панцирнотвердой спине, он видел, стоило ему приподнять голову, свой коричневый, выпуклый, разделенный дугообразными чешуйками живот, на верхушке которого еле держалось готовое вот-вот окончательно сползти одеяло.',
            description: 'Полное описание Диагностика. Его многочисленные, убого тонкие по сравнению с остальным телом ножки беспомощно копошились у него перед глазами. «Что со мной случилось? » – подумал он. Это не было сном. Его комната, настоящая, разве что слишком маленькая, но обычная комната, мирно покоилась в своих четырех хорошо знакомых стенах.',
            main_photo: { // TODO добавить
              id: 1,
              url: '../../assets/img/direction-2.png',
            },
            subdirections: [
              {
                id: 21,
                name: 'Рентген',
                main_photo: {
                  id: 1,
                  url: '../../assets/img/direction2-1.png',
                },
                short_description: 'Краткое описание Рентген',
                description: 'Полное описание Рентген',
                icon: null,
                units: [
                  {
                    id: 2,
                    name: 'Поликлиника №1',
                    address: {
                      id: 1,
                      city: 'Красноярск',
                      street: 'Вавилова',
                      house: '28',
                      building: '',
                      ymapcfg: '../../assets/img/contacts-card-map.png',
                      postcode: '660025',
                      region: 'Красноярский край',
                      mailbox: '6545'
                    },
                    phones: [
                      {
                        id: 7,
                        number: '+7 (391) 274-31-64',
                        description: 'круглосуточно'
                      },
                      {
                        id: 8,
                        number: '+7 (391) 257-64-60',
                        description: 'с 9:00 до 17:00'
                      }
                    ],
                  },
                  {
                    id: 3,
                    name: 'Поликлиника №3',
                    address: {
                      id: 1,
                      city: 'Красноярск',
                      street: 'Коломенская',
                      house: '28',
                      building: '',
                      ymapcfg: '../../assets/img/contacts-card-map.png',
                      postcode: '660025',
                      region: 'Красноярский край',
                      mailbox: '6545'
                    },
                    phones: [
                      {
                        id: 7,
                        number: '+7 (391) 274-31-64',
                        description: 'круглосуточно'
                      },
                      {
                        id: 8,
                        number: '+7 (391) 257-64-60',
                        description: 'с 9:00 до 17:00'
                      }
                    ],
                  },
                ],
                services: [
                  {
                    id: 1,
                    name: 'УЗИ печеночного трансплантанта с дуплексным сканированием артерий и вен',
                    display_name: 'УЗИ печеночного трансплантанта с дуплексным сканированием артерий и вен',
                    price: '1320',
                    // tslint:disable-next-line:max-line-length
                    anons: 'Ультразвуковую диагностику смело можно назвать уникальным методом исследования. Она обладает такими свойствами как неинвазивность, возможность многократного проведения исследования без вреда для здоровья пациента, быстрота получения информации, отсутствие противопоказаний, исключение лучевой нагрузки.',
                    // tslint:disable-next-line:max-line-length
                    body: 'Проснувшись однажды утром после беспокойного сна, Грегор Замза обнаружил, что он у себя в постели превратился в страшное насекомое.',
                    // tslint:disable-next-line:max-line-length
                    memo: 'Вы должны подготовить себя к исследованию следующим образом: воздержаться от физических нагрузок, приема алкоголя и лекарств, изменений в питании в течение 24 часов до взятия крови. Вам не следует принимать пищу после ужина, лечь спать нужно в обычное для Вас время и встать не позднее, чем за час до взятия крови. Утром до сдачи анализа воздержитесь от курения и приема пищи. Взятие крови осуществляется до проведения диагностических или лечебных процедур: инъекций, общего массажа тела, эндоскопий, ЭКГ, рентгеновского обследования и т.д. Если Вы испытываете трудности с отменой лекарств, то обязательно сообщите об этом лечащему врачу. Очень важно, чтобы Вы точно соблюдали указанные рекомендации, так как только в этом случае будут получены ценные результаты исследования крови.',
                    // tslint:disable-next-line:max-line-length
                    indication: 'Анализ на свертываемость является одним из самых простых и доступных методов исследования. Пациенту нужно отказываться от приема пищи в течение двух часов перед исследованием. Результаты готовы, как правило, через несколько часов. Такое тестирование проводится в рамках комплексного обследования организма, а также при наличии кровотечений невыясненной природы и при диагностике или контроле эффективности лечения патологий костного мозга. Референсные значения зависят от возраста, а в детстве — еще и от пола.',
                    contraindic: '<ul>При некоторых заболеваниях проведение забора тромбоцитарной массы категорически запрещено: \n' +
                      '          <li>туберкулез;</li>\n' +
                      '          <li>вирус иммунодефицита;</li>\n' +
                      '          <li>раковые патологии;</li>\n' +
                      '          <li>гепатиты;</li>\n' +
                      '          <li>нарушения в работе сердца и сосудов;</li>\n' +
                      '          <li>после проведения оперативных вмешательств;</li>\n' +
                      '          <li>беременность;</li>\n' +
                      '          <li>алкоголизм;</li>\n' +
                      '          <li>недавнее введение прививки;</li>\n' +
                      '          <li>язва желудка или двенадцатиперстной кишки;</li>\n' +
                      '          <li>татуировки;</li>\n' +
                      '          <li>обширные стоматологические поражения.</li>\n' +
                      '        </ul>',
                    sale: '',
                    doctor: [ // TODO могут ли одну услугу оказывать несколько врачей?
                      {
                        id: 2,
                        lastName: 'Морозова',
                        firstName: 'Екатерина',
                        middleName: 'Владимировна',
                        specialization: 'ЛОР',
                        main_photo: {
                          id: 11,
                          url: '../../assets/img/doctors/doc-5.png'
                        }
                      }
                    ],
                    documents: [
                      {
                        id: 1,
                        title: 'Паспорт',
                        // tslint:disable-next-line:max-line-length
                        desc: 'Проснувшись однажды утром после беспокойного сна, Грегор Замза обнаружил, что он у себя в постели превратился в страшное насекомое.',
                        main_photo:
                          {
                            id: 1,
                            url: '../../assets/img/doc-img-1.png',
                          }
                      },
                      {
                        id: 2,
                        title: 'Медицинский полис',
                        // tslint:disable-next-line:max-line-length
                        desc: 'Проснувшись однажды утром после беспокойного сна, Грегор Замза обнаружил, что он у себя в постели превратился в страшное насекомое.',
                        main_photo:
                          {
                            id: 1,
                            url: '../../assets/img/doc-img-2.png',
                          }
                      },
                      {
                        id: 3,
                        title: 'Направление доктора',
                        // tslint:disable-next-line:max-line-length
                        desc: 'Проснувшись однажды утром после беспокойного сна, Грегор Замза обнаружил, что он у себя в постели превратился в страшное насекомое.',
                        main_photo:
                          {
                            id: 1,
                            url: '../../assets/img/doc-img-3.png',
                          }
                      }
                    ],
                    tags: [
                      {
                        id: 1,
                        name: 'Кровь',
                        desc: 'Описание тега'
                      },
                      {
                        id: 2,
                        name: 'Глюкоза',
                        desc: 'Описание тега'
                      },
                      {
                        id: 3,
                        name: 'Витамины',
                        desc: 'Описание тега'
                      }
                    ],
                    slider: [
                      {
                        id: 1,
                        // tslint:disable-next-line:max-line-length
                        title: 'Ощущения пациента во время проведения ультразвуковой эластографии такие же, как и при обычном УЗИ. Ни боли, ни дискомфорта пациенты не испытывают.',
                        // tslint:disable-next-line:max-line-length
                        desc: 'Ощущения пациента во время проведения ультразвуковой эластографии такие же, как и при обычном УЗИ. Ни боли, ни дискомфорта пациенты не испытывают.',
                        main_photo: {
                          url: '../../assets/img/big-slide-3.png'
                        },
                      },
                      {
                        id: 2,
                        // tslint:disable-next-line:max-line-length
                        title: 'Ощущения пациента во время проведения ',
                        // tslint:disable-next-line:max-line-length
                        desc: 'Ощущения пациента во время проведения ультразвуковой эластографии такие же, как и при обычном УЗИ. Ни боли, ни дискомфорта пациенты не испытывают.',
                        main_photo: {
                          url: '../../assets/img/big-slide-4.png'
                        }
                      },
                      {
                        id: 3,
                        // tslint:disable-next-line:max-line-length
                        title: 'Ощущения пациента во время проведения ультразвуковой эластографии такие же, как и при обычном УЗИ. Ни боли, ни дискомфорта пациенты не испытывают.',
                        // tslint:disable-next-line:max-line-length
                        desc: 'Ощущения пациента во время проведения ультразвуковой эластографии такие же, как и при обычном УЗИ. Ни боли, ни дискомфорта пациенты не испытывают.',
                        main_photo: {
                          url: '../../assets/img/big-slide-3.png'
                        },
                      },
                      {
                        id: 4,
                        // tslint:disable-next-line:max-line-length
                        title: 'Ощущения пациента во время проведения ',
                        // tslint:disable-next-line:max-line-length
                        desc: 'Ощущения пациента во время проведения ультразвуковой эластографии такие же, как и при обычном УЗИ. Ни боли, ни дискомфорта пациенты не испытывают.',
                        main_photo: {
                          url: '../../assets/img/big-slide-4.png'
                        }
                      }
                    ],
                    catalog:
                      {
                        id: 1,
                        name: 'Клинический анализ крови',
                        desc: 'Описание Клинический анализ крови'
                      }
                  },
                  {
                    id: 2,
                    // tslint:disable-next-line:max-line-length
                    name: 'Ультразвуковое исследование органов брюшной полости комплексное: печень, поджелудочная железа, желчный пузырь, селезенка при профилактическом осмотре',
                    display_name: 'УЗИ брюшной полости',
                    price: '820',
                    // tslint:disable-next-line:max-line-length
                    anons: 'Ультразвуковую диагностику смело можно назвать уникальным методом исследования. Она обладает такими свойствами как неинвазивность, возможность многократного проведения исследования без вреда для здоровья пациента, быстрота получения информации, отсутствие противопоказаний, исключение лучевой нагрузки.',
                    // tslint:disable-next-line:max-line-length
                    body: 'Проснувшись однажды утром после беспокойного сна, Грегор Замза обнаружил, что он у себя в постели превратился в страшное насекомое.',
                    // tslint:disable-next-line:max-line-length
                    memo: 'Вы должны подготовить себя к исследованию следующим образом: воздержаться от физических нагрузок, приема алкоголя и лекарств, изменений в питании в течение 24 часов до взятия крови. Вам не следует принимать пищу после ужина, лечь спать нужно в обычное для Вас время и встать не позднее, чем за час до взятия крови. Утром до сдачи анализа воздержитесь от курения и приема пищи. Взятие крови осуществляется до проведения диагностических или лечебных процедур: инъекций, общего массажа тела, эндоскопий, ЭКГ, рентгеновского обследования и т.д. Если Вы испытываете трудности с отменой лекарств, то обязательно сообщите об этом лечащему врачу. Очень важно, чтобы Вы точно соблюдали указанные рекомендации, так как только в этом случае будут получены ценные результаты исследования крови.',
                    // tslint:disable-next-line:max-line-length
                    indication: 'Анализ на свертываемость является одним из самых простых и доступных методов исследования. Пациенту нужно отказываться от приема пищи в течение двух часов перед исследованием. Результаты готовы, как правило, через несколько часов. Такое тестирование проводится в рамках комплексного обследования организма, а также при наличии кровотечений невыясненной природы и при диагностике или контроле эффективности лечения патологий костного мозга. Референсные значения зависят от возраста, а в детстве — еще и от пола.',
                    contraindic: '<ul>При некоторых заболеваниях проведение забора тромбоцитарной массы категорически запрещено: \n' +
                      '          <li>туберкулез;</li>\n' +
                      '          <li>вирус иммунодефицита;</li>\n' +
                      '          <li>раковые патологии;</li>\n' +
                      '          <li>гепатиты;</li>\n' +
                      '          <li>нарушения в работе сердца и сосудов;</li>\n' +
                      '          <li>после проведения оперативных вмешательств;</li>\n' +
                      '          <li>беременность;</li>\n' +
                      '          <li>алкоголизм;</li>\n' +
                      '          <li>недавнее введение прививки;</li>\n' +
                      '          <li>язва желудка или двенадцатиперстной кишки;</li>\n' +
                      '          <li>татуировки;</li>\n' +
                      '          <li>обширные стоматологические поражения.</li>\n' +
                      '        </ul>',
                    sale: '10',
                    doctor: [ // TODO могут ли одну услугу оказывать несколько врачей?
                      {
                        id: 1,
                        lastName: 'Замза',
                        firstName: 'Грегор',
                        middleName: '',
                        specialization: 'Уролог',
                        main_photo: {
                          id: 16,
                          url: '../../assets/img/doctors/doc-2.png'
                        }
                      }
                    ],
                    documents: [
                      {
                        id: 1,
                        title: 'Паспорт',
                        // tslint:disable-next-line:max-line-length
                        desc: 'Проснувшись однажды утром после беспокойного сна, Грегор Замза обнаружил, что он у себя в постели превратился в страшное насекомое.',
                        main_photo:
                          {
                            id: 1,
                            url: '../../assets/img/doc-img-1.png',
                          }
                      }
                    ],
                    tags: [
                      {
                        id: 1,
                        name: 'Кровь',
                        desc: 'Описание тега'
                      },
                      {
                        id: 4,
                        name: 'Железо',
                        desc: 'Описание тега'
                      },
                      {
                        id: 5,
                        name: 'Белки',
                        desc: 'Описание тега'
                      }
                    ],
                    slider: [
                      {
                        id: 1,
                        // tslint:disable-next-line:max-line-length
                        title: 'Ощущения пациента во время проведения ультразвуковой эластографии такие же, как и при обычном УЗИ. Ни боли, ни дискомфорта пациенты не испытывают.',
                        // tslint:disable-next-line:max-line-length
                        desc: 'Ощущения пациента во время проведения ультразвуковой эластографии такие же, как и при обычном УЗИ. Ни боли, ни дискомфорта пациенты не испытывают.',
                        main_photo: {
                          url: '../../assets/img/big-slide-3.png'
                        },
                      },
                      {
                        id: 2,
                        // tslint:disable-next-line:max-line-length
                        title: 'Ощущения пациента во время проведения ',
                        // tslint:disable-next-line:max-line-length
                        desc: 'Ощущения пациента во время проведения ультразвуковой эластографии такие же, как и при обычном УЗИ. Ни боли, ни дискомфорта пациенты не испытывают.',
                        main_photo: {
                          url: '../../assets/img/big-slide-4.png'
                        }
                      }
                    ],
                    catalog:
                      {
                        id: 1,
                        name: 'Клинический анализ крови',
                        desc: 'Описание Клинический анализ крови'
                      }
                  },
                  {
                    id: 3,
                    name: 'УЗИ печеночного трансплантанта с дуплексным сканированием артерий и вен',
                    display_name: 'УЗИ печеночного трансплантанта с дуплексным сканированием артерий и вен',
                    price: '1320',
                    // tslint:disable-next-line:max-line-length
                    anons: 'Ультразвуковую диагностику смело можно назвать уникальным методом исследования. Она обладает такими свойствами как неинвазивность, возможность многократного проведения исследования без вреда для здоровья пациента, быстрота получения информации, отсутствие противопоказаний, исключение лучевой нагрузки.',
                    // tslint:disable-next-line:max-line-length
                    body: 'Проснувшись однажды утром после беспокойного сна, Грегор Замза обнаружил, что он у себя в постели превратился в страшное насекомое.',
                    // tslint:disable-next-line:max-line-length
                    memo: 'Вы должны подготовить себя к исследованию следующим образом: воздержаться от физических нагрузок, приема алкоголя и лекарств, изменений в питании в течение 24 часов до взятия крови. Вам не следует принимать пищу после ужина, лечь спать нужно в обычное для Вас время и встать не позднее, чем за час до взятия крови. Утром до сдачи анализа воздержитесь от курения и приема пищи. Взятие крови осуществляется до проведения диагностических или лечебных процедур: инъекций, общего массажа тела, эндоскопий, ЭКГ, рентгеновского обследования и т.д. Если Вы испытываете трудности с отменой лекарств, то обязательно сообщите об этом лечащему врачу. Очень важно, чтобы Вы точно соблюдали указанные рекомендации, так как только в этом случае будут получены ценные результаты исследования крови.',
                    // tslint:disable-next-line:max-line-length
                    indication: 'Анализ на свертываемость является одним из самых простых и доступных методов исследования. Пациенту нужно отказываться от приема пищи в течение двух часов перед исследованием. Результаты готовы, как правило, через несколько часов. Такое тестирование проводится в рамках комплексного обследования организма, а также при наличии кровотечений невыясненной природы и при диагностике или контроле эффективности лечения патологий костного мозга. Референсные значения зависят от возраста, а в детстве — еще и от пола.',
                    contraindic: '<ul>При некоторых заболеваниях проведение забора тромбоцитарной массы категорически запрещено: \n' +
                      '          <li>туберкулез;</li>\n' +
                      '          <li>вирус иммунодефицита;</li>\n' +
                      '          <li>раковые патологии;</li>\n' +
                      '          <li>гепатиты;</li>\n' +
                      '          <li>нарушения в работе сердца и сосудов;</li>\n' +
                      '          <li>после проведения оперативных вмешательств;</li>\n' +
                      '          <li>беременность;</li>\n' +
                      '          <li>алкоголизм;</li>\n' +
                      '          <li>недавнее введение прививки;</li>\n' +
                      '          <li>язва желудка или двенадцатиперстной кишки;</li>\n' +
                      '          <li>татуировки;</li>\n' +
                      '          <li>обширные стоматологические поражения.</li>\n' +
                      '        </ul>',
                    sale: '20',
                    doctor: [ // TODO могут ли одну услугу оказывать несколько врачей?
                      {
                        id: 1,
                        lastName: 'Коновалов',
                        firstName: 'Геннадий',
                        middleName: 'Александрович',
                        specialization: 'Главный ревматолог',
                        main_photo: {
                          id: 1,
                          url: '../../assets/img/doctors/doc-3.png'
                        }
                      },
                      {
                        id: 2,
                        lastName: 'Морозова',
                        firstName: 'Екатерина',
                        middleName: 'Владимировна',
                        specialization: 'ЛОР',
                        main_photo: {
                          id: 11,
                          url: '../../assets/img/doctors/doc-5.png'
                        }
                      }
                    ],
                    documents: [
                      {
                        id: 1,
                        title: 'Паспорт',
                        // tslint:disable-next-line:max-line-length
                        desc: 'Проснувшись однажды утром после беспокойного сна, Грегор Замза обнаружил, что он у себя в постели превратился в страшное насекомое.',
                        main_photo:
                          {
                            id: 1,
                            url: '../../assets/img/doc-img-1.png',
                          }
                      },
                      {
                        id: 2,
                        title: 'Медицинский полис',
                        // tslint:disable-next-line:max-line-length
                        desc: 'Проснувшись однажды утром после беспокойного сна, Грегор Замза обнаружил, что он у себя в постели превратился в страшное насекомое.',
                        main_photo:
                          {
                            id: 1,
                            url: '../../assets/img/doc-img-2.png',
                          }
                      },
                      {
                        id: 3,
                        title: 'Направление доктора',
                        // tslint:disable-next-line:max-line-length
                        desc: 'Проснувшись однажды утром после беспокойного сна, Грегор Замза обнаружил, что он у себя в постели превратился в страшное насекомое.',
                        main_photo:
                          {
                            id: 1,
                            url: '../../assets/img/doc-img-3.png',
                          }
                      }
                    ],
                    tags: [
                      {
                        id: 1,
                        name: 'Кровь',
                        desc: 'Описание тега'
                      },
                      {
                        id: 2,
                        name: 'Глюкоза',
                        desc: 'Описание тега'
                      },
                      {
                        id: 3,
                        name: 'Витамины',
                        desc: 'Описание тега'
                      }
                    ],
                    catalog:
                      {
                        id: 1,
                        name: 'Клинический анализ крови',
                        desc: 'Описание Клинический анализ крови'
                      }
                  },
                  {
                    id: 4,
                    name: 'УЗИ печеночного трансплантанта с дуплексным сканированием артерий и вен',
                    display_name: 'УЗИ печеночного трансплантанта с дуплексным сканированием артерий и вен',
                    price: '1320',
                    // tslint:disable-next-line:max-line-length
                    anons: 'Ультразвуковую диагностику смело можно назвать уникальным методом исследования. Она обладает такими свойствами как неинвазивность, возможность многократного проведения исследования без вреда для здоровья пациента, быстрота получения информации, отсутствие противопоказаний, исключение лучевой нагрузки.',
                    // tslint:disable-next-line:max-line-length
                    body: 'Проснувшись однажды утром после беспокойного сна, Грегор Замза обнаружил, что он у себя в постели превратился в страшное насекомое.',
                    // tslint:disable-next-line:max-line-length
                    memo: 'Вы должны подготовить себя к исследованию следующим образом: воздержаться от физических нагрузок, приема алкоголя и лекарств, изменений в питании в течение 24 часов до взятия крови. Вам не следует принимать пищу после ужина, лечь спать нужно в обычное для Вас время и встать не позднее, чем за час до взятия крови. Утром до сдачи анализа воздержитесь от курения и приема пищи. Взятие крови осуществляется до проведения диагностических или лечебных процедур: инъекций, общего массажа тела, эндоскопий, ЭКГ, рентгеновского обследования и т.д. Если Вы испытываете трудности с отменой лекарств, то обязательно сообщите об этом лечащему врачу. Очень важно, чтобы Вы точно соблюдали указанные рекомендации, так как только в этом случае будут получены ценные результаты исследования крови.',
                    // tslint:disable-next-line:max-line-length
                    indication: 'Анализ на свертываемость является одним из самых простых и доступных методов исследования. Пациенту нужно отказываться от приема пищи в течение двух часов перед исследованием. Результаты готовы, как правило, через несколько часов. Такое тестирование проводится в рамках комплексного обследования организма, а также при наличии кровотечений невыясненной природы и при диагностике или контроле эффективности лечения патологий костного мозга. Референсные значения зависят от возраста, а в детстве — еще и от пола.',
                    contraindic: '<ul>При некоторых заболеваниях проведение забора тромбоцитарной массы категорически запрещено: \n' +
                      '          <li>туберкулез;</li>\n' +
                      '          <li>вирус иммунодефицита;</li>\n' +
                      '          <li>раковые патологии;</li>\n' +
                      '          <li>гепатиты;</li>\n' +
                      '          <li>нарушения в работе сердца и сосудов;</li>\n' +
                      '          <li>после проведения оперативных вмешательств;</li>\n' +
                      '          <li>беременность;</li>\n' +
                      '          <li>алкоголизм;</li>\n' +
                      '          <li>недавнее введение прививки;</li>\n' +
                      '          <li>язва желудка или двенадцатиперстной кишки;</li>\n' +
                      '          <li>татуировки;</li>\n' +
                      '          <li>обширные стоматологические поражения.</li>\n' +
                      '        </ul>',
                    sale: '',
                    documents: [
                      {
                        id: 1,
                        title: 'Паспорт',
                        // tslint:disable-next-line:max-line-length
                        desc: 'Проснувшись однажды утром после беспокойного сна, Грегор Замза обнаружил, что он у себя в постели превратился в страшное насекомое.',
                        main_photo:
                          {
                            id: 1,
                            url: '../../assets/img/doc-img-1.png',
                          }
                      }
                    ],
                    tags: [
                      {
                        id: 1,
                        name: 'Кровь',
                        desc: 'Описание тега'
                      },
                      {
                        id: 2,
                        name: 'Глюкоза',
                        desc: 'Описание тега'
                      },
                      {
                        id: 3,
                        name: 'Витамины',
                        desc: 'Описание тега'
                      }
                    ],
                    catalog:
                      {
                        id: 1,
                        name: 'Клинический анализ крови',
                        desc: 'Описание Клинический анализ крови'
                      }
                  },
                  {
                    id: 5,
                    name: 'УЗИ печеночного трансплантанта с дуплексным сканированием артерий и вен',
                    display_name: 'УЗИ печеночного трансплантанта с дуплексным сканированием артерий и вен',
                    price: '1320',
                    // tslint:disable-next-line:max-line-length
                    anons: 'Ультразвуковую диагностику смело можно назвать уникальным методом исследования. Она обладает такими свойствами как неинвазивность, возможность многократного проведения исследования без вреда для здоровья пациента, быстрота получения информации, отсутствие противопоказаний, исключение лучевой нагрузки.',
                    // tslint:disable-next-line:max-line-length
                    body: 'Проснувшись однажды утром после беспокойного сна, Грегор Замза обнаружил, что он у себя в постели превратился в страшное насекомое.',
                    // tslint:disable-next-line:max-line-length
                    memo: 'Вы должны подготовить себя к исследованию следующим образом: воздержаться от физических нагрузок, приема алкоголя и лекарств, изменений в питании в течение 24 часов до взятия крови. Вам не следует принимать пищу после ужина, лечь спать нужно в обычное для Вас время и встать не позднее, чем за час до взятия крови. Утром до сдачи анализа воздержитесь от курения и приема пищи. Взятие крови осуществляется до проведения диагностических или лечебных процедур: инъекций, общего массажа тела, эндоскопий, ЭКГ, рентгеновского обследования и т.д. Если Вы испытываете трудности с отменой лекарств, то обязательно сообщите об этом лечащему врачу. Очень важно, чтобы Вы точно соблюдали указанные рекомендации, так как только в этом случае будут получены ценные результаты исследования крови.',
                    // tslint:disable-next-line:max-line-length
                    indication: 'Анализ на свертываемость является одним из самых простых и доступных методов исследования. Пациенту нужно отказываться от приема пищи в течение двух часов перед исследованием. Результаты готовы, как правило, через несколько часов. Такое тестирование проводится в рамках комплексного обследования организма, а также при наличии кровотечений невыясненной природы и при диагностике или контроле эффективности лечения патологий костного мозга. Референсные значения зависят от возраста, а в детстве — еще и от пола.',
                    contraindic: '<ul>При некоторых заболеваниях проведение забора тромбоцитарной массы категорически запрещено: \n' +
                      '          <li>туберкулез;</li>\n' +
                      '          <li>вирус иммунодефицита;</li>\n' +
                      '          <li>раковые патологии;</li>\n' +
                      '          <li>гепатиты;</li>\n' +
                      '          <li>нарушения в работе сердца и сосудов;</li>\n' +
                      '          <li>после проведения оперативных вмешательств;</li>\n' +
                      '          <li>беременность;</li>\n' +
                      '          <li>алкоголизм;</li>\n' +
                      '          <li>недавнее введение прививки;</li>\n' +
                      '          <li>язва желудка или двенадцатиперстной кишки;</li>\n' +
                      '          <li>татуировки;</li>\n' +
                      '          <li>обширные стоматологические поражения.</li>\n' +
                      '        </ul>',
                    sale: '',
                    documents: [
                      {
                        id: 1,
                        title: 'Паспорт',
                        // tslint:disable-next-line:max-line-length
                        desc: 'Проснувшись однажды утром после беспокойного сна, Грегор Замза обнаружил, что он у себя в постели превратился в страшное насекомое.',
                        main_photo:
                          {
                            id: 1,
                            url: '../../assets/img/doc-img-1.png',
                          }
                      }
                    ],
                    tags: [
                      {
                        id: 1,
                        name: 'Кровь',
                        desc: 'Описание тега'
                      },
                      {
                        id: 2,
                        name: 'Глюкоза',
                        desc: 'Описание тега'
                      },
                      {
                        id: 3,
                        name: 'Витамины',
                        desc: 'Описание тега'
                      }
                    ],
                    catalog:
                      {
                        id: 1,
                        name: 'УЗИ - диагностические исследования',
                        desc: 'Описание Клинический анализ крови'
                      }
                  },
                  {
                    id: 6,
                    name: 'УЗИ печеночного трансплантанта с дуплексным сканированием артерий и вен',
                    display_name: 'УЗИ печеночного трансплантанта с дуплексным сканированием артерий и вен',
                    price: '1320',
                    // tslint:disable-next-line:max-line-length
                    anons: 'Ультразвуковую диагностику смело можно назвать уникальным методом исследования. Она обладает такими свойствами как неинвазивность, возможность многократного проведения исследования без вреда для здоровья пациента, быстрота получения информации, отсутствие противопоказаний, исключение лучевой нагрузки.',
                    // tslint:disable-next-line:max-line-length
                    body: 'Проснувшись однажды утром после беспокойного сна, Грегор Замза обнаружил, что он у себя в постели превратился в страшное насекомое.',
                    // tslint:disable-next-line:max-line-length
                    memo: 'Вы должны подготовить себя к исследованию следующим образом: воздержаться от физических нагрузок, приема алкоголя и лекарств, изменений в питании в течение 24 часов до взятия крови. Вам не следует принимать пищу после ужина, лечь спать нужно в обычное для Вас время и встать не позднее, чем за час до взятия крови. Утром до сдачи анализа воздержитесь от курения и приема пищи. Взятие крови осуществляется до проведения диагностических или лечебных процедур: инъекций, общего массажа тела, эндоскопий, ЭКГ, рентгеновского обследования и т.д. Если Вы испытываете трудности с отменой лекарств, то обязательно сообщите об этом лечащему врачу. Очень важно, чтобы Вы точно соблюдали указанные рекомендации, так как только в этом случае будут получены ценные результаты исследования крови.',
                    // tslint:disable-next-line:max-line-length
                    indication: 'Анализ на свертываемость является одним из самых простых и доступных методов исследования. Пациенту нужно отказываться от приема пищи в течение двух часов перед исследованием. Результаты готовы, как правило, через несколько часов. Такое тестирование проводится в рамках комплексного обследования организма, а также при наличии кровотечений невыясненной природы и при диагностике или контроле эффективности лечения патологий костного мозга. Референсные значения зависят от возраста, а в детстве — еще и от пола.',
                    contraindic: '<ul>При некоторых заболеваниях проведение забора тромбоцитарной массы категорически запрещено: \n' +
                      '          <li>туберкулез;</li>\n' +
                      '          <li>вирус иммунодефицита;</li>\n' +
                      '          <li>раковые патологии;</li>\n' +
                      '          <li>гепатиты;</li>\n' +
                      '          <li>нарушения в работе сердца и сосудов;</li>\n' +
                      '          <li>после проведения оперативных вмешательств;</li>\n' +
                      '          <li>беременность;</li>\n' +
                      '          <li>алкоголизм;</li>\n' +
                      '          <li>недавнее введение прививки;</li>\n' +
                      '          <li>язва желудка или двенадцатиперстной кишки;</li>\n' +
                      '          <li>татуировки;</li>\n' +
                      '          <li>обширные стоматологические поражения.</li>\n' +
                      '        </ul>',
                    sale: '20',
                    tags: [
                      {
                        id: 1,
                        name: 'Кровь',
                        desc: 'Описание тега'
                      },
                      {
                        id: 2,
                        name: 'Глюкоза',
                        desc: 'Описание тега'
                      },
                      {
                        id: 3,
                        name: 'Витамины',
                        desc: 'Описание тега'
                      }
                    ],
                    catalog:
                      {
                        id: 1,
                        name: 'Анализы крови',
                        desc: 'Описание Клинический анализ крови'
                      }
                  },
                  {
                    id: 7,
                    name: 'Анализ крови артерий и вен',
                    display_name: 'Анализ крови артерий и вен',
                    price: '1320',
                    // tslint:disable-next-line:max-line-length
                    anons: 'Ультразвуковую диагностику смело можно назвать уникальным методом исследования. Она обладает такими свойствами как неинвазивность, возможность многократного проведения исследования без вреда для здоровья пациента, быстрота получения информации, отсутствие противопоказаний, исключение лучевой нагрузки.',
                    // tslint:disable-next-line:max-line-length
                    body: 'Проснувшись однажды утром после беспокойного сна, Грегор Замза обнаружил.',
                    // tslint:disable-next-line:max-line-length
                    memo: 'Вы должны подготовить себя к исследованию следующим образом: воздержаться от физических нагрузок, приема алкоголя и лекарств, изменений в питании в течение 24 часов до взятия крови. Вам не следует принимать пищу после ужина, лечь спать нужно в обычное для Вас время и встать не позднее, чем за час до взятия крови. Утром до сдачи анализа воздержитесь от курения и приема пищи. Взятие крови осуществляется до проведения диагностических или лечебных процедур: инъекций, общего массажа тела, эндоскопий, ЭКГ, рентгеновского обследования и т.д. Если Вы испытываете трудности с отменой лекарств, то обязательно сообщите об этом лечащему врачу. Очень важно, чтобы Вы точно соблюдали указанные рекомендации, так как только в этом случае будут получены ценные результаты исследования крови.',
                    // tslint:disable-next-line:max-line-length
                    indication: 'Анализ на свертываемость является одним из самых простых и доступных методов исследования. Пациенту нужно отказываться от приема пищи в течение двух часов перед исследованием. Результаты готовы, как правило, через несколько часов. Такое тестирование проводится в рамках комплексного обследования организма, а также при наличии кровотечений невыясненной природы и при диагностике или контроле эффективности лечения патологий костного мозга. Референсные значения зависят от возраста, а в детстве — еще и от пола.',
                    contraindic: '<ul>При некоторых заболеваниях проведение забора тромбоцитарной массы категорически запрещено: \n' +
                      '          <li>туберкулез;</li>\n' +
                      '          <li>вирус иммунодефицита;</li>\n' +
                      '          <li>раковые патологии;</li>\n' +
                      '          <li>гепатиты;</li>\n' +
                      '          <li>нарушения в работе сердца и сосудов;</li>\n' +
                      '          <li>после проведения оперативных вмешательств;</li>\n' +
                      '          <li>беременность;</li>\n' +
                      '          <li>алкоголизм;</li>\n' +
                      '          <li>недавнее введение прививки;</li>\n' +
                      '          <li>язва желудка или двенадцатиперстной кишки;</li>\n' +
                      '          <li>татуировки;</li>\n' +
                      '          <li>обширные стоматологические поражения.</li>\n' +
                      '        </ul>',
                    sale: '',
                    documents: [
                      {
                        id: 1,
                        title: 'Паспорт',
                        // tslint:disable-next-line:max-line-length
                        desc: 'Проснувшись однажды утром после беспокойного сна, Грегор Замза обнаружил, что он у себя в постели превратился в страшное насекомое.',
                        main_photo:
                          {
                            id: 1,
                            url: '../../assets/img/doc-img-1.png',
                          }
                      }
                    ],
                    tags: [
                      {
                        id: 1,
                        name: 'Кровь',
                        desc: 'Описание тега'
                      },
                      {
                        id: 2,
                        name: 'Глюкоза',
                        desc: 'Описание тега'
                      },
                      {
                        id: 3,
                        name: 'Гормоны',
                        desc: 'Описание тега'
                      }
                    ],
                    catalog:
                      {
                        id: 1,
                        name: 'Анализы крови',
                        desc: 'Описание Клинический анализ крови'
                      }
                  }
                ],
              },
              {
                id: 24,
                name: 'Функциональная диагностика',
                main_photo: {
                  id: 1,
                  url: '../../assets/img/direction2-3.png',
                },
                short_description: 'Краткое описание Функциональная диагностика',
                description: 'Полное описание Функциональная диагностика',
                icon: null,
                units: [
                  {
                    id: 2,
                    name: 'Поликлиника №1',
                    address: {
                      id: 1,
                      city: 'Красноярск',
                      street: 'Вавилова',
                      house: '28',
                      building: '',
                      ymapcfg: '../../assets/img/contacts-card-map.png',
                      postcode: '660025',
                      region: 'Красноярский край',
                      mailbox: '6545'
                    },
                    phones: [
                      {
                        id: 7,
                        number: '+7 (391) 274-31-64',
                        description: 'круглосуточно'
                      },
                      {
                        id: 8,
                        number: '+7 (391) 257-64-60',
                        description: 'с 9:00 до 17:00'
                      }
                    ],
                  },
                  {
                    id: 3,
                    name: 'Поликлиника №3',
                    address: {
                      id: 1,
                      city: 'Красноярск',
                      street: 'Коломенская',
                      house: '28',
                      building: '',
                      ymapcfg: '../../assets/img/contacts-card-map.png',
                      postcode: '660025',
                      region: 'Красноярский край',
                      mailbox: '6545'
                    },
                    phones: [
                      {
                        id: 7,
                        number: '+7 (391) 274-31-64',
                        description: 'круглосуточно'
                      },
                      {
                        id: 8,
                        number: '+7 (391) 257-64-60',
                        description: 'с 9:00 до 17:00'
                      }
                    ],
                  },
                ],
                services: [
                  {
                    id: 5,
                    name: 'УЗИ печеночного трансплантанта с дуплексным сканированием артерий и вен',
                    display_name: 'УЗИ печеночного трансплантанта с дуплексным сканированием артерий и вен',
                    price: '1320',
                    // tslint:disable-next-line:max-line-length
                    anons: 'Ультразвуковую диагностику смело можно назвать уникальным методом исследования. Она обладает такими свойствами как неинвазивность, возможность многократного проведения исследования без вреда для здоровья пациента, быстрота получения информации, отсутствие противопоказаний, исключение лучевой нагрузки.',
                    // tslint:disable-next-line:max-line-length
                    body: 'Проснувшись однажды утром после беспокойного сна, Грегор Замза обнаружил, что он у себя в постели превратился в страшное насекомое.',
                    // tslint:disable-next-line:max-line-length
                    memo: 'Вы должны подготовить себя к исследованию следующим образом: воздержаться от физических нагрузок, приема алкоголя и лекарств, изменений в питании в течение 24 часов до взятия крови. Вам не следует принимать пищу после ужина, лечь спать нужно в обычное для Вас время и встать не позднее, чем за час до взятия крови. Утром до сдачи анализа воздержитесь от курения и приема пищи. Взятие крови осуществляется до проведения диагностических или лечебных процедур: инъекций, общего массажа тела, эндоскопий, ЭКГ, рентгеновского обследования и т.д. Если Вы испытываете трудности с отменой лекарств, то обязательно сообщите об этом лечащему врачу. Очень важно, чтобы Вы точно соблюдали указанные рекомендации, так как только в этом случае будут получены ценные результаты исследования крови.',
                    // tslint:disable-next-line:max-line-length
                    indication: 'Анализ на свертываемость является одним из самых простых и доступных методов исследования. Пациенту нужно отказываться от приема пищи в течение двух часов перед исследованием. Результаты готовы, как правило, через несколько часов. Такое тестирование проводится в рамках комплексного обследования организма, а также при наличии кровотечений невыясненной природы и при диагностике или контроле эффективности лечения патологий костного мозга. Референсные значения зависят от возраста, а в детстве — еще и от пола.',
                    contraindic: '<ul>При некоторых заболеваниях проведение забора тромбоцитарной массы категорически запрещено: \n' +
                      '          <li>туберкулез;</li>\n' +
                      '          <li>вирус иммунодефицита;</li>\n' +
                      '          <li>раковые патологии;</li>\n' +
                      '          <li>гепатиты;</li>\n' +
                      '          <li>нарушения в работе сердца и сосудов;</li>\n' +
                      '          <li>после проведения оперативных вмешательств;</li>\n' +
                      '          <li>беременность;</li>\n' +
                      '          <li>алкоголизм;</li>\n' +
                      '          <li>недавнее введение прививки;</li>\n' +
                      '          <li>язва желудка или двенадцатиперстной кишки;</li>\n' +
                      '          <li>татуировки;</li>\n' +
                      '          <li>обширные стоматологические поражения.</li>\n' +
                      '        </ul>',
                    sale: '',
                    documents: [
                      {
                        id: 1,
                        title: 'Паспорт',
                        // tslint:disable-next-line:max-line-length
                        desc: 'Проснувшись однажды утром после беспокойного сна, Грегор Замза обнаружил, что он у себя в постели превратился в страшное насекомое.',
                        main_photo:
                          {
                            id: 1,
                            url: '../../assets/img/doc-img-1.png',
                          }
                      }
                    ],
                    tags: [
                      {
                        id: 1,
                        name: 'Кровь',
                        desc: 'Описание тега'
                      },
                      {
                        id: 2,
                        name: 'Глюкоза',
                        desc: 'Описание тега'
                      },
                      {
                        id: 3,
                        name: 'Витамины',
                        desc: 'Описание тега'
                      }
                    ],
                    catalog:
                      {
                        id: 1,
                        name: 'УЗИ - диагностические исследования',
                        desc: 'Описание Клинический анализ крови'
                      }
                  },
                  {
                    id: 6,
                    name: 'УЗИ печеночного трансплантанта с дуплексным сканированием артерий и вен',
                    display_name: 'УЗИ печеночного трансплантанта с дуплексным сканированием артерий и вен',
                    price: '1320',
                    // tslint:disable-next-line:max-line-length
                    anons: 'Ультразвуковую диагностику смело можно назвать уникальным методом исследования. Она обладает такими свойствами как неинвазивность, возможность многократного проведения исследования без вреда для здоровья пациента, быстрота получения информации, отсутствие противопоказаний, исключение лучевой нагрузки.',
                    // tslint:disable-next-line:max-line-length
                    body: 'Проснувшись однажды утром после беспокойного сна, Грегор Замза обнаружил, что он у себя в постели превратился в страшное насекомое.',
                    // tslint:disable-next-line:max-line-length
                    memo: 'Вы должны подготовить себя к исследованию следующим образом: воздержаться от физических нагрузок, приема алкоголя и лекарств, изменений в питании в течение 24 часов до взятия крови. Вам не следует принимать пищу после ужина, лечь спать нужно в обычное для Вас время и встать не позднее, чем за час до взятия крови. Утром до сдачи анализа воздержитесь от курения и приема пищи. Взятие крови осуществляется до проведения диагностических или лечебных процедур: инъекций, общего массажа тела, эндоскопий, ЭКГ, рентгеновского обследования и т.д. Если Вы испытываете трудности с отменой лекарств, то обязательно сообщите об этом лечащему врачу. Очень важно, чтобы Вы точно соблюдали указанные рекомендации, так как только в этом случае будут получены ценные результаты исследования крови.',
                    // tslint:disable-next-line:max-line-length
                    indication: 'Анализ на свертываемость является одним из самых простых и доступных методов исследования. Пациенту нужно отказываться от приема пищи в течение двух часов перед исследованием. Результаты готовы, как правило, через несколько часов. Такое тестирование проводится в рамках комплексного обследования организма, а также при наличии кровотечений невыясненной природы и при диагностике или контроле эффективности лечения патологий костного мозга. Референсные значения зависят от возраста, а в детстве — еще и от пола.',
                    contraindic: '<ul>При некоторых заболеваниях проведение забора тромбоцитарной массы категорически запрещено: \n' +
                      '          <li>туберкулез;</li>\n' +
                      '          <li>вирус иммунодефицита;</li>\n' +
                      '          <li>раковые патологии;</li>\n' +
                      '          <li>гепатиты;</li>\n' +
                      '          <li>нарушения в работе сердца и сосудов;</li>\n' +
                      '          <li>после проведения оперативных вмешательств;</li>\n' +
                      '          <li>беременность;</li>\n' +
                      '          <li>алкоголизм;</li>\n' +
                      '          <li>недавнее введение прививки;</li>\n' +
                      '          <li>язва желудка или двенадцатиперстной кишки;</li>\n' +
                      '          <li>татуировки;</li>\n' +
                      '          <li>обширные стоматологические поражения.</li>\n' +
                      '        </ul>',
                    sale: '20',
                    tags: [
                      {
                        id: 1,
                        name: 'Кровь',
                        desc: 'Описание тега'
                      },
                      {
                        id: 2,
                        name: 'Глюкоза',
                        desc: 'Описание тега'
                      },
                      {
                        id: 3,
                        name: 'Витамины',
                        desc: 'Описание тега'
                      }
                    ],
                    catalog:
                      {
                        id: 1,
                        name: 'Анализы крови',
                        desc: 'Описание Клинический анализ крови'
                      }
                  },
                  {
                    id: 7,
                    name: 'Анализ крови артерий и вен',
                    display_name: 'Анализ крови артерий и вен',
                    price: '1320',
                    // tslint:disable-next-line:max-line-length
                    anons: 'Ультразвуковую диагностику смело можно назвать уникальным методом исследования. Она обладает такими свойствами как неинвазивность, возможность многократного проведения исследования без вреда для здоровья пациента, быстрота получения информации, отсутствие противопоказаний, исключение лучевой нагрузки.',
                    // tslint:disable-next-line:max-line-length
                    body: 'Проснувшись однажды утром после беспокойного сна, Грегор Замза обнаружил.',
                    // tslint:disable-next-line:max-line-length
                    memo: 'Вы должны подготовить себя к исследованию следующим образом: воздержаться от физических нагрузок, приема алкоголя и лекарств, изменений в питании в течение 24 часов до взятия крови. Вам не следует принимать пищу после ужина, лечь спать нужно в обычное для Вас время и встать не позднее, чем за час до взятия крови. Утром до сдачи анализа воздержитесь от курения и приема пищи. Взятие крови осуществляется до проведения диагностических или лечебных процедур: инъекций, общего массажа тела, эндоскопий, ЭКГ, рентгеновского обследования и т.д. Если Вы испытываете трудности с отменой лекарств, то обязательно сообщите об этом лечащему врачу. Очень важно, чтобы Вы точно соблюдали указанные рекомендации, так как только в этом случае будут получены ценные результаты исследования крови.',
                    // tslint:disable-next-line:max-line-length
                    indication: 'Анализ на свертываемость является одним из самых простых и доступных методов исследования. Пациенту нужно отказываться от приема пищи в течение двух часов перед исследованием. Результаты готовы, как правило, через несколько часов. Такое тестирование проводится в рамках комплексного обследования организма, а также при наличии кровотечений невыясненной природы и при диагностике или контроле эффективности лечения патологий костного мозга. Референсные значения зависят от возраста, а в детстве — еще и от пола.',
                    contraindic: '<ul>При некоторых заболеваниях проведение забора тромбоцитарной массы категорически запрещено: \n' +
                      '          <li>туберкулез;</li>\n' +
                      '          <li>вирус иммунодефицита;</li>\n' +
                      '          <li>раковые патологии;</li>\n' +
                      '          <li>гепатиты;</li>\n' +
                      '          <li>нарушения в работе сердца и сосудов;</li>\n' +
                      '          <li>после проведения оперативных вмешательств;</li>\n' +
                      '          <li>беременность;</li>\n' +
                      '          <li>алкоголизм;</li>\n' +
                      '          <li>недавнее введение прививки;</li>\n' +
                      '          <li>язва желудка или двенадцатиперстной кишки;</li>\n' +
                      '          <li>татуировки;</li>\n' +
                      '          <li>обширные стоматологические поражения.</li>\n' +
                      '        </ul>',
                    sale: '',
                    documents: [
                      {
                        id: 1,
                        title: 'Паспорт',
                        // tslint:disable-next-line:max-line-length
                        desc: 'Проснувшись однажды утром после беспокойного сна, Грегор Замза обнаружил, что он у себя в постели превратился в страшное насекомое.',
                        main_photo:
                          {
                            id: 1,
                            url: '../../assets/img/doc-img-1.png',
                          }
                      }
                    ],
                    tags: [
                      {
                        id: 1,
                        name: 'Кровь',
                        desc: 'Описание тега'
                      },
                      {
                        id: 2,
                        name: 'Глюкоза',
                        desc: 'Описание тега'
                      },
                      {
                        id: 3,
                        name: 'Гормоны',
                        desc: 'Описание тега'
                      }
                    ],
                    catalog:
                      {
                        id: 1,
                        name: 'Анализы крови',
                        desc: 'Описание Клинический анализ крови'
                      }
                  }
                ],
              },
              {
                id: 22,
                name: 'УЗИ',
                main_photo: {
                  id: 1,
                  url: '../../assets/img/direction2-2.png',
                },
                short_description: 'Краткое описание УЗИ',
                description: 'Полное описание УЗИ',
                icon: null,
                units: [
                  {
                    id: 3,
                    name: 'Поликлиника №3',
                    address: {
                      id: 1,
                      city: 'Красноярск',
                      street: 'Коломенская',
                      house: '28',
                      building: '',
                      ymapcfg: '../../assets/img/contacts-card-map.png',
                      postcode: '660025',
                      region: 'Красноярский край',
                      mailbox: '6545'
                    },
                    phones: [
                      {
                        id: 7,
                        number: '+7 (391) 274-31-64',
                        description: 'круглосуточно'
                      },
                      {
                        id: 8,
                        number: '+7 (391) 257-64-60',
                        description: 'с 9:00 до 17:00'
                      }
                    ],
                  },
                ],
                services: [
                  {
                    id: 1,
                    name: 'УЗИ печеночного трансплантанта с дуплексным сканированием артерий и вен',
                    display_name: 'УЗИ печеночного трансплантанта с дуплексным сканированием артерий и вен',
                    price: '1320',
                    // tslint:disable-next-line:max-line-length
                    anons: 'Ультразвуковую диагностику смело можно назвать уникальным методом исследования. Она обладает такими свойствами как неинвазивность, возможность многократного проведения исследования без вреда для здоровья пациента, быстрота получения информации, отсутствие противопоказаний, исключение лучевой нагрузки.',
                    // tslint:disable-next-line:max-line-length
                    body: 'Проснувшись однажды утром после беспокойного сна, Грегор Замза обнаружил, что он у себя в постели превратился в страшное насекомое.',
                    // tslint:disable-next-line:max-line-length
                    memo: 'Вы должны подготовить себя к исследованию следующим образом: воздержаться от физических нагрузок, приема алкоголя и лекарств, изменений в питании в течение 24 часов до взятия крови. Вам не следует принимать пищу после ужина, лечь спать нужно в обычное для Вас время и встать не позднее, чем за час до взятия крови. Утром до сдачи анализа воздержитесь от курения и приема пищи. Взятие крови осуществляется до проведения диагностических или лечебных процедур: инъекций, общего массажа тела, эндоскопий, ЭКГ, рентгеновского обследования и т.д. Если Вы испытываете трудности с отменой лекарств, то обязательно сообщите об этом лечащему врачу. Очень важно, чтобы Вы точно соблюдали указанные рекомендации, так как только в этом случае будут получены ценные результаты исследования крови.',
                    // tslint:disable-next-line:max-line-length
                    indication: 'Анализ на свертываемость является одним из самых простых и доступных методов исследования. Пациенту нужно отказываться от приема пищи в течение двух часов перед исследованием. Результаты готовы, как правило, через несколько часов. Такое тестирование проводится в рамках комплексного обследования организма, а также при наличии кровотечений невыясненной природы и при диагностике или контроле эффективности лечения патологий костного мозга. Референсные значения зависят от возраста, а в детстве — еще и от пола.',
                    contraindic: '<ul>При некоторых заболеваниях проведение забора тромбоцитарной массы категорически запрещено: \n' +
                      '          <li>туберкулез;</li>\n' +
                      '          <li>вирус иммунодефицита;</li>\n' +
                      '          <li>раковые патологии;</li>\n' +
                      '          <li>гепатиты;</li>\n' +
                      '          <li>нарушения в работе сердца и сосудов;</li>\n' +
                      '          <li>после проведения оперативных вмешательств;</li>\n' +
                      '          <li>беременность;</li>\n' +
                      '          <li>алкоголизм;</li>\n' +
                      '          <li>недавнее введение прививки;</li>\n' +
                      '          <li>язва желудка или двенадцатиперстной кишки;</li>\n' +
                      '          <li>татуировки;</li>\n' +
                      '          <li>обширные стоматологические поражения.</li>\n' +
                      '        </ul>',
                    sale: '',
                    doctor: [ // TODO могут ли одну услугу оказывать несколько врачей?
                      {
                        id: 2,
                        lastName: 'Морозова',
                        firstName: 'Екатерина',
                        middleName: 'Владимировна',
                        specialization: 'ЛОР',
                        main_photo: {
                          id: 11,
                          url: '../../assets/img/doctors/doc-5.png'
                        }
                      }
                    ],
                    documents: [
                      {
                        id: 1,
                        title: 'Паспорт',
                        // tslint:disable-next-line:max-line-length
                        desc: 'Проснувшись однажды утром после беспокойного сна, Грегор Замза обнаружил, что он у себя в постели превратился в страшное насекомое.',
                        main_photo:
                          {
                            id: 1,
                            url: '../../assets/img/doc-img-1.png',
                          }
                      },
                      {
                        id: 2,
                        title: 'Медицинский полис',
                        // tslint:disable-next-line:max-line-length
                        desc: 'Проснувшись однажды утром после беспокойного сна, Грегор Замза обнаружил, что он у себя в постели превратился в страшное насекомое.',
                        main_photo:
                          {
                            id: 1,
                            url: '../../assets/img/doc-img-2.png',
                          }
                      },
                      {
                        id: 3,
                        title: 'Направление доктора',
                        // tslint:disable-next-line:max-line-length
                        desc: 'Проснувшись однажды утром после беспокойного сна, Грегор Замза обнаружил, что он у себя в постели превратился в страшное насекомое.',
                        main_photo:
                          {
                            id: 1,
                            url: '../../assets/img/doc-img-3.png',
                          }
                      }
                    ],
                    tags: [
                      {
                        id: 1,
                        name: 'Кровь',
                        desc: 'Описание тега'
                      },
                      {
                        id: 2,
                        name: 'Глюкоза',
                        desc: 'Описание тега'
                      },
                      {
                        id: 3,
                        name: 'Витамины',
                        desc: 'Описание тега'
                      }
                    ],
                    slider: [
                      {
                        id: 1,
                        // tslint:disable-next-line:max-line-length
                        title: 'Ощущения пациента во время проведения ультразвуковой эластографии такие же, как и при обычном УЗИ. Ни боли, ни дискомфорта пациенты не испытывают.',
                        // tslint:disable-next-line:max-line-length
                        desc: 'Ощущения пациента во время проведения ультразвуковой эластографии такие же, как и при обычном УЗИ. Ни боли, ни дискомфорта пациенты не испытывают.',
                        main_photo: {
                          url: '../../assets/img/big-slide-3.png'
                        },
                      },
                      {
                        id: 2,
                        // tslint:disable-next-line:max-line-length
                        title: 'Ощущения пациента во время проведения ',
                        // tslint:disable-next-line:max-line-length
                        desc: 'Ощущения пациента во время проведения ультразвуковой эластографии такие же, как и при обычном УЗИ. Ни боли, ни дискомфорта пациенты не испытывают.',
                        main_photo: {
                          url: '../../assets/img/big-slide-4.png'
                        }
                      },
                      {
                        id: 3,
                        // tslint:disable-next-line:max-line-length
                        title: 'Ощущения пациента во время проведения ультразвуковой эластографии такие же, как и при обычном УЗИ. Ни боли, ни дискомфорта пациенты не испытывают.',
                        // tslint:disable-next-line:max-line-length
                        desc: 'Ощущения пациента во время проведения ультразвуковой эластографии такие же, как и при обычном УЗИ. Ни боли, ни дискомфорта пациенты не испытывают.',
                        main_photo: {
                          url: '../../assets/img/big-slide-3.png'
                        },
                      },
                      {
                        id: 4,
                        // tslint:disable-next-line:max-line-length
                        title: 'Ощущения пациента во время проведения ',
                        // tslint:disable-next-line:max-line-length
                        desc: 'Ощущения пациента во время проведения ультразвуковой эластографии такие же, как и при обычном УЗИ. Ни боли, ни дискомфорта пациенты не испытывают.',
                        main_photo: {
                          url: '../../assets/img/big-slide-4.png'
                        }
                      }
                    ],
                    catalog:
                      {
                        id: 1,
                        name: 'Клинический анализ крови',
                        desc: 'Описание Клинический анализ крови'
                      }
                  },
                  {
                    id: 2,
                    // tslint:disable-next-line:max-line-length
                    name: 'Ультразвуковое исследование органов брюшной полости комплексное: печень, поджелудочная железа, желчный пузырь, селезенка при профилактическом осмотре',
                    display_name: 'УЗИ брюшной полости',
                    price: '820',
                    // tslint:disable-next-line:max-line-length
                    anons: 'Ультразвуковую диагностику смело можно назвать уникальным методом исследования. Она обладает такими свойствами как неинвазивность, возможность многократного проведения исследования без вреда для здоровья пациента, быстрота получения информации, отсутствие противопоказаний, исключение лучевой нагрузки.',
                    // tslint:disable-next-line:max-line-length
                    body: 'Проснувшись однажды утром после беспокойного сна, Грегор Замза обнаружил, что он у себя в постели превратился в страшное насекомое.',
                    // tslint:disable-next-line:max-line-length
                    memo: 'Вы должны подготовить себя к исследованию следующим образом: воздержаться от физических нагрузок, приема алкоголя и лекарств, изменений в питании в течение 24 часов до взятия крови. Вам не следует принимать пищу после ужина, лечь спать нужно в обычное для Вас время и встать не позднее, чем за час до взятия крови. Утром до сдачи анализа воздержитесь от курения и приема пищи. Взятие крови осуществляется до проведения диагностических или лечебных процедур: инъекций, общего массажа тела, эндоскопий, ЭКГ, рентгеновского обследования и т.д. Если Вы испытываете трудности с отменой лекарств, то обязательно сообщите об этом лечащему врачу. Очень важно, чтобы Вы точно соблюдали указанные рекомендации, так как только в этом случае будут получены ценные результаты исследования крови.',
                    // tslint:disable-next-line:max-line-length
                    indication: 'Анализ на свертываемость является одним из самых простых и доступных методов исследования. Пациенту нужно отказываться от приема пищи в течение двух часов перед исследованием. Результаты готовы, как правило, через несколько часов. Такое тестирование проводится в рамках комплексного обследования организма, а также при наличии кровотечений невыясненной природы и при диагностике или контроле эффективности лечения патологий костного мозга. Референсные значения зависят от возраста, а в детстве — еще и от пола.',
                    contraindic: '<ul>При некоторых заболеваниях проведение забора тромбоцитарной массы категорически запрещено: \n' +
                      '          <li>туберкулез;</li>\n' +
                      '          <li>вирус иммунодефицита;</li>\n' +
                      '          <li>раковые патологии;</li>\n' +
                      '          <li>гепатиты;</li>\n' +
                      '          <li>нарушения в работе сердца и сосудов;</li>\n' +
                      '          <li>после проведения оперативных вмешательств;</li>\n' +
                      '          <li>беременность;</li>\n' +
                      '          <li>алкоголизм;</li>\n' +
                      '          <li>недавнее введение прививки;</li>\n' +
                      '          <li>язва желудка или двенадцатиперстной кишки;</li>\n' +
                      '          <li>татуировки;</li>\n' +
                      '          <li>обширные стоматологические поражения.</li>\n' +
                      '        </ul>',
                    sale: '10',
                    doctor: [ // TODO могут ли одну услугу оказывать несколько врачей?
                      {
                        id: 1,
                        lastName: 'Замза',
                        firstName: 'Грегор',
                        middleName: '',
                        specialization: 'Уролог',
                        main_photo: {
                          id: 16,
                          url: '../../assets/img/doctors/doc-2.png'
                        }
                      }
                    ],
                    documents: [
                      {
                        id: 1,
                        title: 'Паспорт',
                        // tslint:disable-next-line:max-line-length
                        desc: 'Проснувшись однажды утром после беспокойного сна, Грегор Замза обнаружил, что он у себя в постели превратился в страшное насекомое.',
                        main_photo:
                          {
                            id: 1,
                            url: '../../assets/img/doc-img-1.png',
                          }
                      }
                    ],
                    tags: [
                      {
                        id: 1,
                        name: 'Кровь',
                        desc: 'Описание тега'
                      },
                      {
                        id: 4,
                        name: 'Железо',
                        desc: 'Описание тега'
                      },
                      {
                        id: 5,
                        name: 'Белки',
                        desc: 'Описание тега'
                      }
                    ],
                    slider: [
                      {
                        id: 1,
                        // tslint:disable-next-line:max-line-length
                        title: 'Ощущения пациента во время проведения ультразвуковой эластографии такие же, как и при обычном УЗИ. Ни боли, ни дискомфорта пациенты не испытывают.',
                        // tslint:disable-next-line:max-line-length
                        desc: 'Ощущения пациента во время проведения ультразвуковой эластографии такие же, как и при обычном УЗИ. Ни боли, ни дискомфорта пациенты не испытывают.',
                        main_photo: {
                          url: '../../assets/img/big-slide-3.png'
                        },
                      },
                      {
                        id: 2,
                        // tslint:disable-next-line:max-line-length
                        title: 'Ощущения пациента во время проведения ',
                        // tslint:disable-next-line:max-line-length
                        desc: 'Ощущения пациента во время проведения ультразвуковой эластографии такие же, как и при обычном УЗИ. Ни боли, ни дискомфорта пациенты не испытывают.',
                        main_photo: {
                          url: '../../assets/img/big-slide-4.png'
                        }
                      }
                    ],
                    catalog:
                      {
                        id: 1,
                        name: 'Клинический анализ крови',
                        desc: 'Описание Клинический анализ крови'
                      }
                  },
                  {
                    id: 3,
                    name: 'УЗИ печеночного трансплантанта с дуплексным сканированием артерий и вен',
                    display_name: 'УЗИ печеночного трансплантанта с дуплексным сканированием артерий и вен',
                    price: '1320',
                    // tslint:disable-next-line:max-line-length
                    anons: 'Ультразвуковую диагностику смело можно назвать уникальным методом исследования. Она обладает такими свойствами как неинвазивность, возможность многократного проведения исследования без вреда для здоровья пациента, быстрота получения информации, отсутствие противопоказаний, исключение лучевой нагрузки.',
                    // tslint:disable-next-line:max-line-length
                    body: 'Проснувшись однажды утром после беспокойного сна, Грегор Замза обнаружил, что он у себя в постели превратился в страшное насекомое.',
                    // tslint:disable-next-line:max-line-length
                    memo: 'Вы должны подготовить себя к исследованию следующим образом: воздержаться от физических нагрузок, приема алкоголя и лекарств, изменений в питании в течение 24 часов до взятия крови. Вам не следует принимать пищу после ужина, лечь спать нужно в обычное для Вас время и встать не позднее, чем за час до взятия крови. Утром до сдачи анализа воздержитесь от курения и приема пищи. Взятие крови осуществляется до проведения диагностических или лечебных процедур: инъекций, общего массажа тела, эндоскопий, ЭКГ, рентгеновского обследования и т.д. Если Вы испытываете трудности с отменой лекарств, то обязательно сообщите об этом лечащему врачу. Очень важно, чтобы Вы точно соблюдали указанные рекомендации, так как только в этом случае будут получены ценные результаты исследования крови.',
                    // tslint:disable-next-line:max-line-length
                    indication: 'Анализ на свертываемость является одним из самых простых и доступных методов исследования. Пациенту нужно отказываться от приема пищи в течение двух часов перед исследованием. Результаты готовы, как правило, через несколько часов. Такое тестирование проводится в рамках комплексного обследования организма, а также при наличии кровотечений невыясненной природы и при диагностике или контроле эффективности лечения патологий костного мозга. Референсные значения зависят от возраста, а в детстве — еще и от пола.',
                    contraindic: '<ul>При некоторых заболеваниях проведение забора тромбоцитарной массы категорически запрещено: \n' +
                      '          <li>туберкулез;</li>\n' +
                      '          <li>вирус иммунодефицита;</li>\n' +
                      '          <li>раковые патологии;</li>\n' +
                      '          <li>гепатиты;</li>\n' +
                      '          <li>нарушения в работе сердца и сосудов;</li>\n' +
                      '          <li>после проведения оперативных вмешательств;</li>\n' +
                      '          <li>беременность;</li>\n' +
                      '          <li>алкоголизм;</li>\n' +
                      '          <li>недавнее введение прививки;</li>\n' +
                      '          <li>язва желудка или двенадцатиперстной кишки;</li>\n' +
                      '          <li>татуировки;</li>\n' +
                      '          <li>обширные стоматологические поражения.</li>\n' +
                      '        </ul>',
                    sale: '20',
                    doctor: [ // TODO могут ли одну услугу оказывать несколько врачей?
                      {
                        id: 1,
                        lastName: 'Коновалов',
                        firstName: 'Геннадий',
                        middleName: 'Александрович',
                        specialization: 'Главный ревматолог',
                        main_photo: {
                          id: 1,
                          url: '../../assets/img/doctors/doc-3.png'
                        }
                      },
                      {
                        id: 2,
                        lastName: 'Морозова',
                        firstName: 'Екатерина',
                        middleName: 'Владимировна',
                        specialization: 'ЛОР',
                        main_photo: {
                          id: 11,
                          url: '../../assets/img/doctors/doc-5.png'
                        }
                      }
                    ],
                    documents: [
                      {
                        id: 1,
                        title: 'Паспорт',
                        // tslint:disable-next-line:max-line-length
                        desc: 'Проснувшись однажды утром после беспокойного сна, Грегор Замза обнаружил, что он у себя в постели превратился в страшное насекомое.',
                        main_photo:
                          {
                            id: 1,
                            url: '../../assets/img/doc-img-1.png',
                          }
                      },
                      {
                        id: 2,
                        title: 'Медицинский полис',
                        // tslint:disable-next-line:max-line-length
                        desc: 'Проснувшись однажды утром после беспокойного сна, Грегор Замза обнаружил, что он у себя в постели превратился в страшное насекомое.',
                        main_photo:
                          {
                            id: 1,
                            url: '../../assets/img/doc-img-2.png',
                          }
                      },
                      {
                        id: 3,
                        title: 'Направление доктора',
                        // tslint:disable-next-line:max-line-length
                        desc: 'Проснувшись однажды утром после беспокойного сна, Грегор Замза обнаружил, что он у себя в постели превратился в страшное насекомое.',
                        main_photo:
                          {
                            id: 1,
                            url: '../../assets/img/doc-img-3.png',
                          }
                      }
                    ],
                    tags: [
                      {
                        id: 1,
                        name: 'Кровь',
                        desc: 'Описание тега'
                      },
                      {
                        id: 2,
                        name: 'Глюкоза',
                        desc: 'Описание тега'
                      },
                      {
                        id: 3,
                        name: 'Витамины',
                        desc: 'Описание тега'
                      }
                    ],
                    catalog:
                      {
                        id: 1,
                        name: 'Клинический анализ крови',
                        desc: 'Описание Клинический анализ крови'
                      }
                  },
                  {
                    id: 6,
                    name: 'УЗИ печеночного трансплантанта с дуплексным сканированием артерий и вен',
                    display_name: 'УЗИ печеночного трансплантанта с дуплексным сканированием артерий и вен',
                    price: '1320',
                    // tslint:disable-next-line:max-line-length
                    anons: 'Ультразвуковую диагностику смело можно назвать уникальным методом исследования. Она обладает такими свойствами как неинвазивность, возможность многократного проведения исследования без вреда для здоровья пациента, быстрота получения информации, отсутствие противопоказаний, исключение лучевой нагрузки.',
                    // tslint:disable-next-line:max-line-length
                    body: 'Проснувшись однажды утром после беспокойного сна, Грегор Замза обнаружил, что он у себя в постели превратился в страшное насекомое.',
                    // tslint:disable-next-line:max-line-length
                    memo: 'Вы должны подготовить себя к исследованию следующим образом: воздержаться от физических нагрузок, приема алкоголя и лекарств, изменений в питании в течение 24 часов до взятия крови. Вам не следует принимать пищу после ужина, лечь спать нужно в обычное для Вас время и встать не позднее, чем за час до взятия крови. Утром до сдачи анализа воздержитесь от курения и приема пищи. Взятие крови осуществляется до проведения диагностических или лечебных процедур: инъекций, общего массажа тела, эндоскопий, ЭКГ, рентгеновского обследования и т.д. Если Вы испытываете трудности с отменой лекарств, то обязательно сообщите об этом лечащему врачу. Очень важно, чтобы Вы точно соблюдали указанные рекомендации, так как только в этом случае будут получены ценные результаты исследования крови.',
                    // tslint:disable-next-line:max-line-length
                    indication: 'Анализ на свертываемость является одним из самых простых и доступных методов исследования. Пациенту нужно отказываться от приема пищи в течение двух часов перед исследованием. Результаты готовы, как правило, через несколько часов. Такое тестирование проводится в рамках комплексного обследования организма, а также при наличии кровотечений невыясненной природы и при диагностике или контроле эффективности лечения патологий костного мозга. Референсные значения зависят от возраста, а в детстве — еще и от пола.',
                    contraindic: '<ul>При некоторых заболеваниях проведение забора тромбоцитарной массы категорически запрещено: \n' +
                      '          <li>туберкулез;</li>\n' +
                      '          <li>вирус иммунодефицита;</li>\n' +
                      '          <li>раковые патологии;</li>\n' +
                      '          <li>гепатиты;</li>\n' +
                      '          <li>нарушения в работе сердца и сосудов;</li>\n' +
                      '          <li>после проведения оперативных вмешательств;</li>\n' +
                      '          <li>беременность;</li>\n' +
                      '          <li>алкоголизм;</li>\n' +
                      '          <li>недавнее введение прививки;</li>\n' +
                      '          <li>язва желудка или двенадцатиперстной кишки;</li>\n' +
                      '          <li>татуировки;</li>\n' +
                      '          <li>обширные стоматологические поражения.</li>\n' +
                      '        </ul>',
                    sale: '20',
                    tags: [
                      {
                        id: 1,
                        name: 'Кровь',
                        desc: 'Описание тега'
                      },
                      {
                        id: 2,
                        name: 'Глюкоза',
                        desc: 'Описание тега'
                      },
                      {
                        id: 3,
                        name: 'Витамины',
                        desc: 'Описание тега'
                      }
                    ],
                    catalog:
                      {
                        id: 1,
                        name: 'Анализы крови',
                        desc: 'Описание Клинический анализ крови'
                      }
                  },
                  {
                    id: 7,
                    name: 'Анализ крови артерий и вен',
                    display_name: 'Анализ крови артерий и вен',
                    price: '1320',
                    // tslint:disable-next-line:max-line-length
                    anons: 'Ультразвуковую диагностику смело можно назвать уникальным методом исследования. Она обладает такими свойствами как неинвазивность, возможность многократного проведения исследования без вреда для здоровья пациента, быстрота получения информации, отсутствие противопоказаний, исключение лучевой нагрузки.',
                    // tslint:disable-next-line:max-line-length
                    body: 'Проснувшись однажды утром после беспокойного сна, Грегор Замза обнаружил.',
                    // tslint:disable-next-line:max-line-length
                    memo: 'Вы должны подготовить себя к исследованию следующим образом: воздержаться от физических нагрузок, приема алкоголя и лекарств, изменений в питании в течение 24 часов до взятия крови. Вам не следует принимать пищу после ужина, лечь спать нужно в обычное для Вас время и встать не позднее, чем за час до взятия крови. Утром до сдачи анализа воздержитесь от курения и приема пищи. Взятие крови осуществляется до проведения диагностических или лечебных процедур: инъекций, общего массажа тела, эндоскопий, ЭКГ, рентгеновского обследования и т.д. Если Вы испытываете трудности с отменой лекарств, то обязательно сообщите об этом лечащему врачу. Очень важно, чтобы Вы точно соблюдали указанные рекомендации, так как только в этом случае будут получены ценные результаты исследования крови.',
                    // tslint:disable-next-line:max-line-length
                    indication: 'Анализ на свертываемость является одним из самых простых и доступных методов исследования. Пациенту нужно отказываться от приема пищи в течение двух часов перед исследованием. Результаты готовы, как правило, через несколько часов. Такое тестирование проводится в рамках комплексного обследования организма, а также при наличии кровотечений невыясненной природы и при диагностике или контроле эффективности лечения патологий костного мозга. Референсные значения зависят от возраста, а в детстве — еще и от пола.',
                    contraindic: '<ul>При некоторых заболеваниях проведение забора тромбоцитарной массы категорически запрещено: \n' +
                      '          <li>туберкулез;</li>\n' +
                      '          <li>вирус иммунодефицита;</li>\n' +
                      '          <li>раковые патологии;</li>\n' +
                      '          <li>гепатиты;</li>\n' +
                      '          <li>нарушения в работе сердца и сосудов;</li>\n' +
                      '          <li>после проведения оперативных вмешательств;</li>\n' +
                      '          <li>беременность;</li>\n' +
                      '          <li>алкоголизм;</li>\n' +
                      '          <li>недавнее введение прививки;</li>\n' +
                      '          <li>язва желудка или двенадцатиперстной кишки;</li>\n' +
                      '          <li>татуировки;</li>\n' +
                      '          <li>обширные стоматологические поражения.</li>\n' +
                      '        </ul>',
                    sale: '',
                    documents: [
                      {
                        id: 1,
                        title: 'Паспорт',
                        // tslint:disable-next-line:max-line-length
                        desc: 'Проснувшись однажды утром после беспокойного сна, Грегор Замза обнаружил, что он у себя в постели превратился в страшное насекомое.',
                        main_photo:
                          {
                            id: 1,
                            url: '../../assets/img/doc-img-1.png',
                          }
                      }
                    ],
                    tags: [
                      {
                        id: 1,
                        name: 'Кровь',
                        desc: 'Описание тега'
                      },
                      {
                        id: 2,
                        name: 'Глюкоза',
                        desc: 'Описание тега'
                      },
                      {
                        id: 3,
                        name: 'Гормоны',
                        desc: 'Описание тега'
                      }
                    ],
                    catalog:
                      {
                        id: 1,
                        name: 'Анализы крови',
                        desc: 'Описание Клинический анализ крови'
                      }
                  }
                ],
              },
              {
                id: 23,
                name: 'Эндоскопия',
                main_photo: {
                  id: 1,
                  url: '../../assets/img/direction2-1.png',
                },
                short_description: 'Краткое описание Эндоскопия',
                description: 'Полное описание Эндоскопия',
                icon: null,
                units: [
                  {
                    id: 2,
                    name: 'Поликлиника №1',
                    address: {
                      id: 1,
                      city: 'Красноярск',
                      street: 'Вавилова',
                      house: '28',
                      building: '',
                      ymapcfg: '../../assets/img/contacts-card-map.png',
                      postcode: '660025',
                      region: 'Красноярский край',
                      mailbox: '6545'
                    },
                    phones: [
                      {
                        id: 7,
                        number: '+7 (391) 274-31-64',
                        description: 'круглосуточно'
                      },
                      {
                        id: 8,
                        number: '+7 (391) 257-64-60',
                        description: 'с 9:00 до 17:00'
                      }
                    ],
                  },
                ],
                services: [
                  {
                    id: 5,
                    name: 'УЗИ печеночного трансплантанта с дуплексным сканированием артерий и вен',
                    display_name: 'УЗИ печеночного трансплантанта с дуплексным сканированием артерий и вен',
                    price: '1320',
                    // tslint:disable-next-line:max-line-length
                    anons: 'Ультразвуковую диагностику смело можно назвать уникальным методом исследования. Она обладает такими свойствами как неинвазивность, возможность многократного проведения исследования без вреда для здоровья пациента, быстрота получения информации, отсутствие противопоказаний, исключение лучевой нагрузки.',
                    // tslint:disable-next-line:max-line-length
                    body: 'Проснувшись однажды утром после беспокойного сна, Грегор Замза обнаружил, что он у себя в постели превратился в страшное насекомое.',
                    // tslint:disable-next-line:max-line-length
                    memo: 'Вы должны подготовить себя к исследованию следующим образом: воздержаться от физических нагрузок, приема алкоголя и лекарств, изменений в питании в течение 24 часов до взятия крови. Вам не следует принимать пищу после ужина, лечь спать нужно в обычное для Вас время и встать не позднее, чем за час до взятия крови. Утром до сдачи анализа воздержитесь от курения и приема пищи. Взятие крови осуществляется до проведения диагностических или лечебных процедур: инъекций, общего массажа тела, эндоскопий, ЭКГ, рентгеновского обследования и т.д. Если Вы испытываете трудности с отменой лекарств, то обязательно сообщите об этом лечащему врачу. Очень важно, чтобы Вы точно соблюдали указанные рекомендации, так как только в этом случае будут получены ценные результаты исследования крови.',
                    // tslint:disable-next-line:max-line-length
                    indication: 'Анализ на свертываемость является одним из самых простых и доступных методов исследования. Пациенту нужно отказываться от приема пищи в течение двух часов перед исследованием. Результаты готовы, как правило, через несколько часов. Такое тестирование проводится в рамках комплексного обследования организма, а также при наличии кровотечений невыясненной природы и при диагностике или контроле эффективности лечения патологий костного мозга. Референсные значения зависят от возраста, а в детстве — еще и от пола.',
                    contraindic: '<ul>При некоторых заболеваниях проведение забора тромбоцитарной массы категорически запрещено: \n' +
                      '          <li>туберкулез;</li>\n' +
                      '          <li>вирус иммунодефицита;</li>\n' +
                      '          <li>раковые патологии;</li>\n' +
                      '          <li>гепатиты;</li>\n' +
                      '          <li>нарушения в работе сердца и сосудов;</li>\n' +
                      '          <li>после проведения оперативных вмешательств;</li>\n' +
                      '          <li>беременность;</li>\n' +
                      '          <li>алкоголизм;</li>\n' +
                      '          <li>недавнее введение прививки;</li>\n' +
                      '          <li>язва желудка или двенадцатиперстной кишки;</li>\n' +
                      '          <li>татуировки;</li>\n' +
                      '          <li>обширные стоматологические поражения.</li>\n' +
                      '        </ul>',
                    sale: '',
                    documents: [
                      {
                        id: 1,
                        title: 'Паспорт',
                        // tslint:disable-next-line:max-line-length
                        desc: 'Проснувшись однажды утром после беспокойного сна, Грегор Замза обнаружил, что он у себя в постели превратился в страшное насекомое.',
                        main_photo:
                          {
                            id: 1,
                            url: '../../assets/img/doc-img-1.png',
                          }
                      }
                    ],
                    tags: [
                      {
                        id: 1,
                        name: 'Кровь',
                        desc: 'Описание тега'
                      },
                      {
                        id: 2,
                        name: 'Глюкоза',
                        desc: 'Описание тега'
                      },
                      {
                        id: 3,
                        name: 'Витамины',
                        desc: 'Описание тега'
                      }
                    ],
                    catalog:
                      {
                        id: 1,
                        name: 'УЗИ - диагностические исследования',
                        desc: 'Описание Клинический анализ крови'
                      }
                  },
                  {
                    id: 6,
                    name: 'УЗИ печеночного трансплантанта с дуплексным сканированием артерий и вен',
                    display_name: 'УЗИ печеночного трансплантанта с дуплексным сканированием артерий и вен',
                    price: '1320',
                    // tslint:disable-next-line:max-line-length
                    anons: 'Ультразвуковую диагностику смело можно назвать уникальным методом исследования. Она обладает такими свойствами как неинвазивность, возможность многократного проведения исследования без вреда для здоровья пациента, быстрота получения информации, отсутствие противопоказаний, исключение лучевой нагрузки.',
                    // tslint:disable-next-line:max-line-length
                    body: 'Проснувшись однажды утром после беспокойного сна, Грегор Замза обнаружил, что он у себя в постели превратился в страшное насекомое.',
                    // tslint:disable-next-line:max-line-length
                    memo: 'Вы должны подготовить себя к исследованию следующим образом: воздержаться от физических нагрузок, приема алкоголя и лекарств, изменений в питании в течение 24 часов до взятия крови. Вам не следует принимать пищу после ужина, лечь спать нужно в обычное для Вас время и встать не позднее, чем за час до взятия крови. Утром до сдачи анализа воздержитесь от курения и приема пищи. Взятие крови осуществляется до проведения диагностических или лечебных процедур: инъекций, общего массажа тела, эндоскопий, ЭКГ, рентгеновского обследования и т.д. Если Вы испытываете трудности с отменой лекарств, то обязательно сообщите об этом лечащему врачу. Очень важно, чтобы Вы точно соблюдали указанные рекомендации, так как только в этом случае будут получены ценные результаты исследования крови.',
                    // tslint:disable-next-line:max-line-length
                    indication: 'Анализ на свертываемость является одним из самых простых и доступных методов исследования. Пациенту нужно отказываться от приема пищи в течение двух часов перед исследованием. Результаты готовы, как правило, через несколько часов. Такое тестирование проводится в рамках комплексного обследования организма, а также при наличии кровотечений невыясненной природы и при диагностике или контроле эффективности лечения патологий костного мозга. Референсные значения зависят от возраста, а в детстве — еще и от пола.',
                    contraindic: '<ul>При некоторых заболеваниях проведение забора тромбоцитарной массы категорически запрещено: \n' +
                      '          <li>туберкулез;</li>\n' +
                      '          <li>вирус иммунодефицита;</li>\n' +
                      '          <li>раковые патологии;</li>\n' +
                      '          <li>гепатиты;</li>\n' +
                      '          <li>нарушения в работе сердца и сосудов;</li>\n' +
                      '          <li>после проведения оперативных вмешательств;</li>\n' +
                      '          <li>беременность;</li>\n' +
                      '          <li>алкоголизм;</li>\n' +
                      '          <li>недавнее введение прививки;</li>\n' +
                      '          <li>язва желудка или двенадцатиперстной кишки;</li>\n' +
                      '          <li>татуировки;</li>\n' +
                      '          <li>обширные стоматологические поражения.</li>\n' +
                      '        </ul>',
                    sale: '20',
                    tags: [
                      {
                        id: 1,
                        name: 'Кровь',
                        desc: 'Описание тега'
                      },
                      {
                        id: 2,
                        name: 'Глюкоза',
                        desc: 'Описание тега'
                      },
                      {
                        id: 3,
                        name: 'Витамины',
                        desc: 'Описание тега'
                      }
                    ],
                    catalog:
                      {
                        id: 1,
                        name: 'Анализы крови',
                        desc: 'Описание Клинический анализ крови'
                      }
                  },
                  {
                    id: 7,
                    name: 'Анализ крови артерий и вен',
                    display_name: 'Анализ крови артерий и вен',
                    price: '1320',
                    // tslint:disable-next-line:max-line-length
                    anons: 'Ультразвуковую диагностику смело можно назвать уникальным методом исследования. Она обладает такими свойствами как неинвазивность, возможность многократного проведения исследования без вреда для здоровья пациента, быстрота получения информации, отсутствие противопоказаний, исключение лучевой нагрузки.',
                    // tslint:disable-next-line:max-line-length
                    body: 'Проснувшись однажды утром после беспокойного сна, Грегор Замза обнаружил.',
                    // tslint:disable-next-line:max-line-length
                    memo: 'Вы должны подготовить себя к исследованию следующим образом: воздержаться от физических нагрузок, приема алкоголя и лекарств, изменений в питании в течение 24 часов до взятия крови. Вам не следует принимать пищу после ужина, лечь спать нужно в обычное для Вас время и встать не позднее, чем за час до взятия крови. Утром до сдачи анализа воздержитесь от курения и приема пищи. Взятие крови осуществляется до проведения диагностических или лечебных процедур: инъекций, общего массажа тела, эндоскопий, ЭКГ, рентгеновского обследования и т.д. Если Вы испытываете трудности с отменой лекарств, то обязательно сообщите об этом лечащему врачу. Очень важно, чтобы Вы точно соблюдали указанные рекомендации, так как только в этом случае будут получены ценные результаты исследования крови.',
                    // tslint:disable-next-line:max-line-length
                    indication: 'Анализ на свертываемость является одним из самых простых и доступных методов исследования. Пациенту нужно отказываться от приема пищи в течение двух часов перед исследованием. Результаты готовы, как правило, через несколько часов. Такое тестирование проводится в рамках комплексного обследования организма, а также при наличии кровотечений невыясненной природы и при диагностике или контроле эффективности лечения патологий костного мозга. Референсные значения зависят от возраста, а в детстве — еще и от пола.',
                    contraindic: '<ul>При некоторых заболеваниях проведение забора тромбоцитарной массы категорически запрещено: \n' +
                      '          <li>туберкулез;</li>\n' +
                      '          <li>вирус иммунодефицита;</li>\n' +
                      '          <li>раковые патологии;</li>\n' +
                      '          <li>гепатиты;</li>\n' +
                      '          <li>нарушения в работе сердца и сосудов;</li>\n' +
                      '          <li>после проведения оперативных вмешательств;</li>\n' +
                      '          <li>беременность;</li>\n' +
                      '          <li>алкоголизм;</li>\n' +
                      '          <li>недавнее введение прививки;</li>\n' +
                      '          <li>язва желудка или двенадцатиперстной кишки;</li>\n' +
                      '          <li>татуировки;</li>\n' +
                      '          <li>обширные стоматологические поражения.</li>\n' +
                      '        </ul>',
                    sale: '',
                    documents: [
                      {
                        id: 1,
                        title: 'Паспорт',
                        // tslint:disable-next-line:max-line-length
                        desc: 'Проснувшись однажды утром после беспокойного сна, Грегор Замза обнаружил, что он у себя в постели превратился в страшное насекомое.',
                        main_photo:
                          {
                            id: 1,
                            url: '../../assets/img/doc-img-1.png',
                          }
                      }
                    ],
                    tags: [
                      {
                        id: 1,
                        name: 'Кровь',
                        desc: 'Описание тега'
                      },
                      {
                        id: 2,
                        name: 'Глюкоза',
                        desc: 'Описание тега'
                      },
                      {
                        id: 3,
                        name: 'Гормоны',
                        desc: 'Описание тега'
                      }
                    ],
                    catalog:
                      {
                        id: 1,
                        name: 'Анализы крови',
                        desc: 'Описание Клинический анализ крови'
                      }
                  }
                ],
              }
            ],
            directions: [
              {
                id: 60,
                name: 'root',
                short_description: null,
                description: null,
                icon: null
              }
            ],
          },
          {
            id: 19,
            name: 'Амбулаторный прием',
            short_description: 'Проснувшись однажды утром после беспокойного сна, Грегор Замза обнаружил, что он у себя в постели превратился в страшное насекомое.',
            description: 'Полное описание Диагностика. Его многочисленные, убого тонкие по сравнению с остальным телом ножки беспомощно копошились у него перед глазами. «Что со мной случилось? » – подумал он. Это не было сном.',
            main_photo: { // TODO добавить
              id: 1,
              url: '../../assets/img/direction-2.png',
            },
            subdirections: [
              {
                id: 21,
                name: 'Рентген',
                main_photo: {
                  id: 1,
                  url: '../../assets/img/direction2-1.png',
                },
                short_description: 'Краткое описание Рентген',
                description: 'Полное описание Рентген',
                icon: null,
                units: [
                  {
                    id: 2,
                    name: 'Поликлиника №1',
                    address: {
                      id: 1,
                      city: 'Красноярск',
                      street: 'Вавилова',
                      house: '28',
                      building: '',
                      ymapcfg: '../../assets/img/contacts-card-map.png',
                      postcode: '660025',
                      region: 'Красноярский край',
                      mailbox: '6545'
                    },
                    phones: [
                      {
                        id: 7,
                        number: '+7 (391) 274-31-64',
                        description: 'круглосуточно'
                      },
                      {
                        id: 8,
                        number: '+7 (391) 257-64-60',
                        description: 'с 9:00 до 17:00'
                      }
                    ],
                  },
                  {
                    id: 3,
                    name: 'Поликлиника №3',
                    address: {
                      id: 1,
                      city: 'Красноярск',
                      street: 'Коломенская',
                      house: '28',
                      building: '',
                      ymapcfg: '../../assets/img/contacts-card-map.png',
                      postcode: '660025',
                      region: 'Красноярский край',
                      mailbox: '6545'
                    },
                    phones: [
                      {
                        id: 7,
                        number: '+7 (391) 274-31-64',
                        description: 'круглосуточно'
                      },
                      {
                        id: 8,
                        number: '+7 (391) 257-64-60',
                        description: 'с 9:00 до 17:00'
                      }
                    ],
                  },
                ],
                services: [
                  {
                    id: 4,
                    name: 'УЗИ печеночного трансплантанта с дуплексным сканированием артерий и вен',
                    display_name: 'УЗИ печеночного трансплантанта с дуплексным сканированием артерий и вен',
                    price: '1320',
                    // tslint:disable-next-line:max-line-length
                    anons: 'Ультразвуковую диагностику смело можно назвать уникальным методом исследования. Она обладает такими свойствами как неинвазивность, возможность многократного проведения исследования без вреда для здоровья пациента, быстрота получения информации, отсутствие противопоказаний, исключение лучевой нагрузки.',
                    // tslint:disable-next-line:max-line-length
                    body: 'Проснувшись однажды утром после беспокойного сна, Грегор Замза обнаружил, что он у себя в постели превратился в страшное насекомое.',
                    // tslint:disable-next-line:max-line-length
                    memo: 'Вы должны подготовить себя к исследованию следующим образом: воздержаться от физических нагрузок, приема алкоголя и лекарств, изменений в питании в течение 24 часов до взятия крови. Вам не следует принимать пищу после ужина, лечь спать нужно в обычное для Вас время и встать не позднее, чем за час до взятия крови. Утром до сдачи анализа воздержитесь от курения и приема пищи. Взятие крови осуществляется до проведения диагностических или лечебных процедур: инъекций, общего массажа тела, эндоскопий, ЭКГ, рентгеновского обследования и т.д. Если Вы испытываете трудности с отменой лекарств, то обязательно сообщите об этом лечащему врачу. Очень важно, чтобы Вы точно соблюдали указанные рекомендации, так как только в этом случае будут получены ценные результаты исследования крови.',
                    // tslint:disable-next-line:max-line-length
                    indication: 'Анализ на свертываемость является одним из самых простых и доступных методов исследования. Пациенту нужно отказываться от приема пищи в течение двух часов перед исследованием. Результаты готовы, как правило, через несколько часов. Такое тестирование проводится в рамках комплексного обследования организма, а также при наличии кровотечений невыясненной природы и при диагностике или контроле эффективности лечения патологий костного мозга. Референсные значения зависят от возраста, а в детстве — еще и от пола.',
                    contraindic: '<ul>При некоторых заболеваниях проведение забора тромбоцитарной массы категорически запрещено: \n' +
                      '          <li>туберкулез;</li>\n' +
                      '          <li>вирус иммунодефицита;</li>\n' +
                      '          <li>раковые патологии;</li>\n' +
                      '          <li>гепатиты;</li>\n' +
                      '          <li>нарушения в работе сердца и сосудов;</li>\n' +
                      '          <li>после проведения оперативных вмешательств;</li>\n' +
                      '          <li>беременность;</li>\n' +
                      '          <li>алкоголизм;</li>\n' +
                      '          <li>недавнее введение прививки;</li>\n' +
                      '          <li>язва желудка или двенадцатиперстной кишки;</li>\n' +
                      '          <li>татуировки;</li>\n' +
                      '          <li>обширные стоматологические поражения.</li>\n' +
                      '        </ul>',
                    sale: '',
                    documents: [
                      {
                        id: 1,
                        title: 'Паспорт',
                        // tslint:disable-next-line:max-line-length
                        desc: 'Проснувшись однажды утром после беспокойного сна, Грегор Замза обнаружил, что он у себя в постели превратился в страшное насекомое.',
                        main_photo:
                          {
                            id: 1,
                            url: '../../assets/img/doc-img-1.png',
                          }
                      }
                    ],
                    tags: [
                      {
                        id: 1,
                        name: 'Кровь',
                        desc: 'Описание тега'
                      },
                      {
                        id: 2,
                        name: 'Глюкоза',
                        desc: 'Описание тега'
                      },
                      {
                        id: 3,
                        name: 'Витамины',
                        desc: 'Описание тега'
                      }
                    ],
                    catalog:
                      {
                        id: 1,
                        name: 'Клинический анализ крови',
                        desc: 'Описание Клинический анализ крови'
                      }
                  },
                  {
                    id: 5,
                    name: 'УЗИ печеночного трансплантанта с дуплексным сканированием артерий и вен',
                    display_name: 'УЗИ печеночного трансплантанта с дуплексным сканированием артерий и вен',
                    price: '1320',
                    // tslint:disable-next-line:max-line-length
                    anons: 'Ультразвуковую диагностику смело можно назвать уникальным методом исследования. Она обладает такими свойствами как неинвазивность, возможность многократного проведения исследования без вреда для здоровья пациента, быстрота получения информации, отсутствие противопоказаний, исключение лучевой нагрузки.',
                    // tslint:disable-next-line:max-line-length
                    body: 'Проснувшись однажды утром после беспокойного сна, Грегор Замза обнаружил, что он у себя в постели превратился в страшное насекомое.',
                    // tslint:disable-next-line:max-line-length
                    memo: 'Вы должны подготовить себя к исследованию следующим образом: воздержаться от физических нагрузок, приема алкоголя и лекарств, изменений в питании в течение 24 часов до взятия крови. Вам не следует принимать пищу после ужина, лечь спать нужно в обычное для Вас время и встать не позднее, чем за час до взятия крови. Утром до сдачи анализа воздержитесь от курения и приема пищи. Взятие крови осуществляется до проведения диагностических или лечебных процедур: инъекций, общего массажа тела, эндоскопий, ЭКГ, рентгеновского обследования и т.д. Если Вы испытываете трудности с отменой лекарств, то обязательно сообщите об этом лечащему врачу. Очень важно, чтобы Вы точно соблюдали указанные рекомендации, так как только в этом случае будут получены ценные результаты исследования крови.',
                    // tslint:disable-next-line:max-line-length
                    indication: 'Анализ на свертываемость является одним из самых простых и доступных методов исследования. Пациенту нужно отказываться от приема пищи в течение двух часов перед исследованием. Результаты готовы, как правило, через несколько часов. Такое тестирование проводится в рамках комплексного обследования организма, а также при наличии кровотечений невыясненной природы и при диагностике или контроле эффективности лечения патологий костного мозга. Референсные значения зависят от возраста, а в детстве — еще и от пола.',
                    contraindic: '<ul>При некоторых заболеваниях проведение забора тромбоцитарной массы категорически запрещено: \n' +
                      '          <li>туберкулез;</li>\n' +
                      '          <li>вирус иммунодефицита;</li>\n' +
                      '          <li>раковые патологии;</li>\n' +
                      '          <li>гепатиты;</li>\n' +
                      '          <li>нарушения в работе сердца и сосудов;</li>\n' +
                      '          <li>после проведения оперативных вмешательств;</li>\n' +
                      '          <li>беременность;</li>\n' +
                      '          <li>алкоголизм;</li>\n' +
                      '          <li>недавнее введение прививки;</li>\n' +
                      '          <li>язва желудка или двенадцатиперстной кишки;</li>\n' +
                      '          <li>татуировки;</li>\n' +
                      '          <li>обширные стоматологические поражения.</li>\n' +
                      '        </ul>',
                    sale: '',
                    documents: [
                      {
                        id: 1,
                        title: 'Паспорт',
                        // tslint:disable-next-line:max-line-length
                        desc: 'Проснувшись однажды утром после беспокойного сна, Грегор Замза обнаружил, что он у себя в постели превратился в страшное насекомое.',
                        main_photo:
                          {
                            id: 1,
                            url: '../../assets/img/doc-img-1.png',
                          }
                      }
                    ],
                    tags: [
                      {
                        id: 1,
                        name: 'Кровь',
                        desc: 'Описание тега'
                      },
                      {
                        id: 2,
                        name: 'Глюкоза',
                        desc: 'Описание тега'
                      },
                      {
                        id: 3,
                        name: 'Витамины',
                        desc: 'Описание тега'
                      }
                    ],
                    catalog:
                      {
                        id: 1,
                        name: 'УЗИ - диагностические исследования',
                        desc: 'Описание Клинический анализ крови'
                      }
                  },
                  {
                    id: 6,
                    name: 'УЗИ печеночного трансплантанта с дуплексным сканированием артерий и вен',
                    display_name: 'УЗИ печеночного трансплантанта с дуплексным сканированием артерий и вен',
                    price: '1320',
                    // tslint:disable-next-line:max-line-length
                    anons: 'Ультразвуковую диагностику смело можно назвать уникальным методом исследования. Она обладает такими свойствами как неинвазивность, возможность многократного проведения исследования без вреда для здоровья пациента, быстрота получения информации, отсутствие противопоказаний, исключение лучевой нагрузки.',
                    // tslint:disable-next-line:max-line-length
                    body: 'Проснувшись однажды утром после беспокойного сна, Грегор Замза обнаружил, что он у себя в постели превратился в страшное насекомое.',
                    // tslint:disable-next-line:max-line-length
                    memo: 'Вы должны подготовить себя к исследованию следующим образом: воздержаться от физических нагрузок, приема алкоголя и лекарств, изменений в питании в течение 24 часов до взятия крови. Вам не следует принимать пищу после ужина, лечь спать нужно в обычное для Вас время и встать не позднее, чем за час до взятия крови. Утром до сдачи анализа воздержитесь от курения и приема пищи. Взятие крови осуществляется до проведения диагностических или лечебных процедур: инъекций, общего массажа тела, эндоскопий, ЭКГ, рентгеновского обследования и т.д. Если Вы испытываете трудности с отменой лекарств, то обязательно сообщите об этом лечащему врачу. Очень важно, чтобы Вы точно соблюдали указанные рекомендации, так как только в этом случае будут получены ценные результаты исследования крови.',
                    // tslint:disable-next-line:max-line-length
                    indication: 'Анализ на свертываемость является одним из самых простых и доступных методов исследования. Пациенту нужно отказываться от приема пищи в течение двух часов перед исследованием. Результаты готовы, как правило, через несколько часов. Такое тестирование проводится в рамках комплексного обследования организма, а также при наличии кровотечений невыясненной природы и при диагностике или контроле эффективности лечения патологий костного мозга. Референсные значения зависят от возраста, а в детстве — еще и от пола.',
                    contraindic: '<ul>При некоторых заболеваниях проведение забора тромбоцитарной массы категорически запрещено: \n' +
                      '          <li>туберкулез;</li>\n' +
                      '          <li>вирус иммунодефицита;</li>\n' +
                      '          <li>раковые патологии;</li>\n' +
                      '          <li>гепатиты;</li>\n' +
                      '          <li>нарушения в работе сердца и сосудов;</li>\n' +
                      '          <li>после проведения оперативных вмешательств;</li>\n' +
                      '          <li>беременность;</li>\n' +
                      '          <li>алкоголизм;</li>\n' +
                      '          <li>недавнее введение прививки;</li>\n' +
                      '          <li>язва желудка или двенадцатиперстной кишки;</li>\n' +
                      '          <li>татуировки;</li>\n' +
                      '          <li>обширные стоматологические поражения.</li>\n' +
                      '        </ul>',
                    sale: '20',
                    tags: [
                      {
                        id: 1,
                        name: 'Кровь',
                        desc: 'Описание тега'
                      },
                      {
                        id: 2,
                        name: 'Глюкоза',
                        desc: 'Описание тега'
                      },
                      {
                        id: 3,
                        name: 'Витамины',
                        desc: 'Описание тега'
                      }
                    ],
                    catalog:
                      {
                        id: 1,
                        name: 'Анализы крови',
                        desc: 'Описание Клинический анализ крови'
                      }
                  },
                  {
                    id: 7,
                    name: 'Анализ крови артерий и вен',
                    display_name: 'Анализ крови артерий и вен',
                    price: '1320',
                    // tslint:disable-next-line:max-line-length
                    anons: 'Ультразвуковую диагностику смело можно назвать уникальным методом исследования. Она обладает такими свойствами как неинвазивность, возможность многократного проведения исследования без вреда для здоровья пациента, быстрота получения информации, отсутствие противопоказаний, исключение лучевой нагрузки.',
                    // tslint:disable-next-line:max-line-length
                    body: 'Проснувшись однажды утром после беспокойного сна, Грегор Замза обнаружил.',
                    // tslint:disable-next-line:max-line-length
                    memo: 'Вы должны подготовить себя к исследованию следующим образом: воздержаться от физических нагрузок, приема алкоголя и лекарств, изменений в питании в течение 24 часов до взятия крови. Вам не следует принимать пищу после ужина, лечь спать нужно в обычное для Вас время и встать не позднее, чем за час до взятия крови. Утром до сдачи анализа воздержитесь от курения и приема пищи. Взятие крови осуществляется до проведения диагностических или лечебных процедур: инъекций, общего массажа тела, эндоскопий, ЭКГ, рентгеновского обследования и т.д. Если Вы испытываете трудности с отменой лекарств, то обязательно сообщите об этом лечащему врачу. Очень важно, чтобы Вы точно соблюдали указанные рекомендации, так как только в этом случае будут получены ценные результаты исследования крови.',
                    // tslint:disable-next-line:max-line-length
                    indication: 'Анализ на свертываемость является одним из самых простых и доступных методов исследования. Пациенту нужно отказываться от приема пищи в течение двух часов перед исследованием. Результаты готовы, как правило, через несколько часов. Такое тестирование проводится в рамках комплексного обследования организма, а также при наличии кровотечений невыясненной природы и при диагностике или контроле эффективности лечения патологий костного мозга. Референсные значения зависят от возраста, а в детстве — еще и от пола.',
                    contraindic: '<ul>При некоторых заболеваниях проведение забора тромбоцитарной массы категорически запрещено: \n' +
                      '          <li>туберкулез;</li>\n' +
                      '          <li>вирус иммунодефицита;</li>\n' +
                      '          <li>раковые патологии;</li>\n' +
                      '          <li>гепатиты;</li>\n' +
                      '          <li>нарушения в работе сердца и сосудов;</li>\n' +
                      '          <li>после проведения оперативных вмешательств;</li>\n' +
                      '          <li>беременность;</li>\n' +
                      '          <li>алкоголизм;</li>\n' +
                      '          <li>недавнее введение прививки;</li>\n' +
                      '          <li>язва желудка или двенадцатиперстной кишки;</li>\n' +
                      '          <li>татуировки;</li>\n' +
                      '          <li>обширные стоматологические поражения.</li>\n' +
                      '        </ul>',
                    sale: '',
                    documents: [
                      {
                        id: 1,
                        title: 'Паспорт',
                        // tslint:disable-next-line:max-line-length
                        desc: 'Проснувшись однажды утром после беспокойного сна, Грегор Замза обнаружил, что он у себя в постели превратился в страшное насекомое.',
                        main_photo:
                          {
                            id: 1,
                            url: '../../assets/img/doc-img-1.png',
                          }
                      }
                    ],
                    tags: [
                      {
                        id: 1,
                        name: 'Кровь',
                        desc: 'Описание тега'
                      },
                      {
                        id: 2,
                        name: 'Глюкоза',
                        desc: 'Описание тега'
                      },
                      {
                        id: 3,
                        name: 'Гормоны',
                        desc: 'Описание тега'
                      }
                    ],
                    catalog:
                      {
                        id: 1,
                        name: 'Анализы крови',
                        desc: 'Описание Клинический анализ крови'
                      }
                  }
                ],
              },
              {
                id: 24,
                name: 'Функциональная диагностика',
                main_photo: {
                  id: 1,
                  url: '../../assets/img/direction2-3.png',
                },
                short_description: 'Краткое описание Функциональная диагностика',
                description: 'Полное описание Функциональная диагностика',
                icon: null,
                units: [
                  {
                    id: 2,
                    name: 'Поликлиника №1',
                    address: {
                      id: 1,
                      city: 'Красноярск',
                      street: 'Вавилова',
                      house: '28',
                      building: '',
                      ymapcfg: '../../assets/img/contacts-card-map.png',
                      postcode: '660025',
                      region: 'Красноярский край',
                      mailbox: '6545'
                    },
                    phones: [
                      {
                        id: 7,
                        number: '+7 (391) 274-31-64',
                        description: 'круглосуточно'
                      },
                      {
                        id: 8,
                        number: '+7 (391) 257-64-60',
                        description: 'с 9:00 до 17:00'
                      }
                    ],
                  },
                  {
                    id: 3,
                    name: 'Поликлиника №3',
                    address: {
                      id: 1,
                      city: 'Красноярск',
                      street: 'Коломенская',
                      house: '28',
                      building: '',
                      ymapcfg: '../../assets/img/contacts-card-map.png',
                      postcode: '660025',
                      region: 'Красноярский край',
                      mailbox: '6545'
                    },
                    phones: [
                      {
                        id: 7,
                        number: '+7 (391) 274-31-64',
                        description: 'круглосуточно'
                      },
                      {
                        id: 8,
                        number: '+7 (391) 257-64-60',
                        description: 'с 9:00 до 17:00'
                      }
                    ],
                  },
                ],
                services: [
                  {
                    id: 5,
                    name: 'УЗИ печеночного трансплантанта с дуплексным сканированием артерий и вен',
                    display_name: 'УЗИ печеночного трансплантанта с дуплексным сканированием артерий и вен',
                    price: '1320',
                    // tslint:disable-next-line:max-line-length
                    anons: 'Ультразвуковую диагностику смело можно назвать уникальным методом исследования. Она обладает такими свойствами как неинвазивность, возможность многократного проведения исследования без вреда для здоровья пациента, быстрота получения информации, отсутствие противопоказаний, исключение лучевой нагрузки.',
                    // tslint:disable-next-line:max-line-length
                    body: 'Проснувшись однажды утром после беспокойного сна, Грегор Замза обнаружил, что он у себя в постели превратился в страшное насекомое.',
                    // tslint:disable-next-line:max-line-length
                    memo: 'Вы должны подготовить себя к исследованию следующим образом: воздержаться от физических нагрузок, приема алкоголя и лекарств, изменений в питании в течение 24 часов до взятия крови. Вам не следует принимать пищу после ужина, лечь спать нужно в обычное для Вас время и встать не позднее, чем за час до взятия крови. Утром до сдачи анализа воздержитесь от курения и приема пищи. Взятие крови осуществляется до проведения диагностических или лечебных процедур: инъекций, общего массажа тела, эндоскопий, ЭКГ, рентгеновского обследования и т.д. Если Вы испытываете трудности с отменой лекарств, то обязательно сообщите об этом лечащему врачу. Очень важно, чтобы Вы точно соблюдали указанные рекомендации, так как только в этом случае будут получены ценные результаты исследования крови.',
                    // tslint:disable-next-line:max-line-length
                    indication: 'Анализ на свертываемость является одним из самых простых и доступных методов исследования. Пациенту нужно отказываться от приема пищи в течение двух часов перед исследованием. Результаты готовы, как правило, через несколько часов. Такое тестирование проводится в рамках комплексного обследования организма, а также при наличии кровотечений невыясненной природы и при диагностике или контроле эффективности лечения патологий костного мозга. Референсные значения зависят от возраста, а в детстве — еще и от пола.',
                    contraindic: '<ul>При некоторых заболеваниях проведение забора тромбоцитарной массы категорически запрещено: \n' +
                      '          <li>туберкулез;</li>\n' +
                      '          <li>вирус иммунодефицита;</li>\n' +
                      '          <li>раковые патологии;</li>\n' +
                      '          <li>гепатиты;</li>\n' +
                      '          <li>нарушения в работе сердца и сосудов;</li>\n' +
                      '          <li>после проведения оперативных вмешательств;</li>\n' +
                      '          <li>беременность;</li>\n' +
                      '          <li>алкоголизм;</li>\n' +
                      '          <li>недавнее введение прививки;</li>\n' +
                      '          <li>язва желудка или двенадцатиперстной кишки;</li>\n' +
                      '          <li>татуировки;</li>\n' +
                      '          <li>обширные стоматологические поражения.</li>\n' +
                      '        </ul>',
                    sale: '',
                    documents: [
                      {
                        id: 1,
                        title: 'Паспорт',
                        // tslint:disable-next-line:max-line-length
                        desc: 'Проснувшись однажды утром после беспокойного сна, Грегор Замза обнаружил, что он у себя в постели превратился в страшное насекомое.',
                        main_photo:
                          {
                            id: 1,
                            url: '../../assets/img/doc-img-1.png',
                          }
                      }
                    ],
                    tags: [
                      {
                        id: 1,
                        name: 'Кровь',
                        desc: 'Описание тега'
                      },
                      {
                        id: 2,
                        name: 'Глюкоза',
                        desc: 'Описание тега'
                      },
                      {
                        id: 3,
                        name: 'Витамины',
                        desc: 'Описание тега'
                      }
                    ],
                    catalog:
                      {
                        id: 1,
                        name: 'УЗИ - диагностические исследования',
                        desc: 'Описание Клинический анализ крови'
                      }
                  },
                  {
                    id: 6,
                    name: 'УЗИ печеночного трансплантанта с дуплексным сканированием артерий и вен',
                    display_name: 'УЗИ печеночного трансплантанта с дуплексным сканированием артерий и вен',
                    price: '1320',
                    // tslint:disable-next-line:max-line-length
                    anons: 'Ультразвуковую диагностику смело можно назвать уникальным методом исследования. Она обладает такими свойствами как неинвазивность, возможность многократного проведения исследования без вреда для здоровья пациента, быстрота получения информации, отсутствие противопоказаний, исключение лучевой нагрузки.',
                    // tslint:disable-next-line:max-line-length
                    body: 'Проснувшись однажды утром после беспокойного сна, Грегор Замза обнаружил, что он у себя в постели превратился в страшное насекомое.',
                    // tslint:disable-next-line:max-line-length
                    memo: 'Вы должны подготовить себя к исследованию следующим образом: воздержаться от физических нагрузок, приема алкоголя и лекарств, изменений в питании в течение 24 часов до взятия крови. Вам не следует принимать пищу после ужина, лечь спать нужно в обычное для Вас время и встать не позднее, чем за час до взятия крови. Утром до сдачи анализа воздержитесь от курения и приема пищи. Взятие крови осуществляется до проведения диагностических или лечебных процедур: инъекций, общего массажа тела, эндоскопий, ЭКГ, рентгеновского обследования и т.д. Если Вы испытываете трудности с отменой лекарств, то обязательно сообщите об этом лечащему врачу. Очень важно, чтобы Вы точно соблюдали указанные рекомендации, так как только в этом случае будут получены ценные результаты исследования крови.',
                    // tslint:disable-next-line:max-line-length
                    indication: 'Анализ на свертываемость является одним из самых простых и доступных методов исследования. Пациенту нужно отказываться от приема пищи в течение двух часов перед исследованием. Результаты готовы, как правило, через несколько часов. Такое тестирование проводится в рамках комплексного обследования организма, а также при наличии кровотечений невыясненной природы и при диагностике или контроле эффективности лечения патологий костного мозга. Референсные значения зависят от возраста, а в детстве — еще и от пола.',
                    contraindic: '<ul>При некоторых заболеваниях проведение забора тромбоцитарной массы категорически запрещено: \n' +
                      '          <li>туберкулез;</li>\n' +
                      '          <li>вирус иммунодефицита;</li>\n' +
                      '          <li>раковые патологии;</li>\n' +
                      '          <li>гепатиты;</li>\n' +
                      '          <li>нарушения в работе сердца и сосудов;</li>\n' +
                      '          <li>после проведения оперативных вмешательств;</li>\n' +
                      '          <li>беременность;</li>\n' +
                      '          <li>алкоголизм;</li>\n' +
                      '          <li>недавнее введение прививки;</li>\n' +
                      '          <li>язва желудка или двенадцатиперстной кишки;</li>\n' +
                      '          <li>татуировки;</li>\n' +
                      '          <li>обширные стоматологические поражения.</li>\n' +
                      '        </ul>',
                    sale: '20',
                    tags: [
                      {
                        id: 1,
                        name: 'Кровь',
                        desc: 'Описание тега'
                      },
                      {
                        id: 2,
                        name: 'Глюкоза',
                        desc: 'Описание тега'
                      },
                      {
                        id: 3,
                        name: 'Витамины',
                        desc: 'Описание тега'
                      }
                    ],
                    catalog:
                      {
                        id: 1,
                        name: 'Анализы крови',
                        desc: 'Описание Клинический анализ крови'
                      }
                  },
                  {
                    id: 7,
                    name: 'Анализ крови артерий и вен',
                    display_name: 'Анализ крови артерий и вен',
                    price: '1320',
                    // tslint:disable-next-line:max-line-length
                    anons: 'Ультразвуковую диагностику смело можно назвать уникальным методом исследования. Она обладает такими свойствами как неинвазивность, возможность многократного проведения исследования без вреда для здоровья пациента, быстрота получения информации, отсутствие противопоказаний, исключение лучевой нагрузки.',
                    // tslint:disable-next-line:max-line-length
                    body: 'Проснувшись однажды утром после беспокойного сна, Грегор Замза обнаружил.',
                    // tslint:disable-next-line:max-line-length
                    memo: 'Вы должны подготовить себя к исследованию следующим образом: воздержаться от физических нагрузок, приема алкоголя и лекарств, изменений в питании в течение 24 часов до взятия крови. Вам не следует принимать пищу после ужина, лечь спать нужно в обычное для Вас время и встать не позднее, чем за час до взятия крови. Утром до сдачи анализа воздержитесь от курения и приема пищи. Взятие крови осуществляется до проведения диагностических или лечебных процедур: инъекций, общего массажа тела, эндоскопий, ЭКГ, рентгеновского обследования и т.д. Если Вы испытываете трудности с отменой лекарств, то обязательно сообщите об этом лечащему врачу. Очень важно, чтобы Вы точно соблюдали указанные рекомендации, так как только в этом случае будут получены ценные результаты исследования крови.',
                    // tslint:disable-next-line:max-line-length
                    indication: 'Анализ на свертываемость является одним из самых простых и доступных методов исследования. Пациенту нужно отказываться от приема пищи в течение двух часов перед исследованием. Результаты готовы, как правило, через несколько часов. Такое тестирование проводится в рамках комплексного обследования организма, а также при наличии кровотечений невыясненной природы и при диагностике или контроле эффективности лечения патологий костного мозга. Референсные значения зависят от возраста, а в детстве — еще и от пола.',
                    contraindic: '<ul>При некоторых заболеваниях проведение забора тромбоцитарной массы категорически запрещено: \n' +
                      '          <li>туберкулез;</li>\n' +
                      '          <li>вирус иммунодефицита;</li>\n' +
                      '          <li>раковые патологии;</li>\n' +
                      '          <li>гепатиты;</li>\n' +
                      '          <li>нарушения в работе сердца и сосудов;</li>\n' +
                      '          <li>после проведения оперативных вмешательств;</li>\n' +
                      '          <li>беременность;</li>\n' +
                      '          <li>алкоголизм;</li>\n' +
                      '          <li>недавнее введение прививки;</li>\n' +
                      '          <li>язва желудка или двенадцатиперстной кишки;</li>\n' +
                      '          <li>татуировки;</li>\n' +
                      '          <li>обширные стоматологические поражения.</li>\n' +
                      '        </ul>',
                    sale: '',
                    documents: [
                      {
                        id: 1,
                        title: 'Паспорт',
                        // tslint:disable-next-line:max-line-length
                        desc: 'Проснувшись однажды утром после беспокойного сна, Грегор Замза обнаружил, что он у себя в постели превратился в страшное насекомое.',
                        main_photo:
                          {
                            id: 1,
                            url: '../../assets/img/doc-img-1.png',
                          }
                      }
                    ],
                    tags: [
                      {
                        id: 1,
                        name: 'Кровь',
                        desc: 'Описание тега'
                      },
                      {
                        id: 2,
                        name: 'Глюкоза',
                        desc: 'Описание тега'
                      },
                      {
                        id: 3,
                        name: 'Гормоны',
                        desc: 'Описание тега'
                      }
                    ],
                    catalog:
                      {
                        id: 1,
                        name: 'Анализы крови',
                        desc: 'Описание Клинический анализ крови'
                      }
                  }
                ],
              },
              {
                id: 22,
                name: 'УЗИ',
                main_photo: {
                  id: 1,
                  url: '../../assets/img/direction2-2.png',
                },
                short_description: 'Краткое описание УЗИ',
                description: 'Полное описание УЗИ',
                icon: null,
                units: [
                  {
                    id: 3,
                    name: 'Поликлиника №3',
                    address: {
                      id: 1,
                      city: 'Красноярск',
                      street: 'Коломенская',
                      house: '28',
                      building: '',
                      ymapcfg: '../../assets/img/contacts-card-map.png',
                      postcode: '660025',
                      region: 'Красноярский край',
                      mailbox: '6545'
                    },
                    phones: [
                      {
                        id: 7,
                        number: '+7 (391) 274-31-64',
                        description: 'круглосуточно'
                      },
                      {
                        id: 8,
                        number: '+7 (391) 257-64-60',
                        description: 'с 9:00 до 17:00'
                      }
                    ],
                  },
                ],
                services: [
                  {
                    id: 2,
                    // tslint:disable-next-line:max-line-length
                    name: 'Ультразвуковое исследование органов брюшной полости комплексное: печень, поджелудочная железа, желчный пузырь, селезенка при профилактическом осмотре',
                    display_name: 'УЗИ брюшной полости',
                    price: '820',
                    // tslint:disable-next-line:max-line-length
                    anons: 'Ультразвуковую диагностику смело можно назвать уникальным методом исследования. Она обладает такими свойствами как неинвазивность, возможность многократного проведения исследования без вреда для здоровья пациента, быстрота получения информации, отсутствие противопоказаний, исключение лучевой нагрузки.',
                    // tslint:disable-next-line:max-line-length
                    body: 'Проснувшись однажды утром после беспокойного сна, Грегор Замза обнаружил, что он у себя в постели превратился в страшное насекомое.',
                    // tslint:disable-next-line:max-line-length
                    memo: 'Вы должны подготовить себя к исследованию следующим образом: воздержаться от физических нагрузок, приема алкоголя и лекарств, изменений в питании в течение 24 часов до взятия крови. Вам не следует принимать пищу после ужина, лечь спать нужно в обычное для Вас время и встать не позднее, чем за час до взятия крови. Утром до сдачи анализа воздержитесь от курения и приема пищи. Взятие крови осуществляется до проведения диагностических или лечебных процедур: инъекций, общего массажа тела, эндоскопий, ЭКГ, рентгеновского обследования и т.д. Если Вы испытываете трудности с отменой лекарств, то обязательно сообщите об этом лечащему врачу. Очень важно, чтобы Вы точно соблюдали указанные рекомендации, так как только в этом случае будут получены ценные результаты исследования крови.',
                    // tslint:disable-next-line:max-line-length
                    indication: 'Анализ на свертываемость является одним из самых простых и доступных методов исследования. Пациенту нужно отказываться от приема пищи в течение двух часов перед исследованием. Результаты готовы, как правило, через несколько часов. Такое тестирование проводится в рамках комплексного обследования организма, а также при наличии кровотечений невыясненной природы и при диагностике или контроле эффективности лечения патологий костного мозга. Референсные значения зависят от возраста, а в детстве — еще и от пола.',
                    contraindic: '<ul>При некоторых заболеваниях проведение забора тромбоцитарной массы категорически запрещено: \n' +
                      '          <li>туберкулез;</li>\n' +
                      '          <li>вирус иммунодефицита;</li>\n' +
                      '          <li>раковые патологии;</li>\n' +
                      '          <li>гепатиты;</li>\n' +
                      '          <li>нарушения в работе сердца и сосудов;</li>\n' +
                      '          <li>после проведения оперативных вмешательств;</li>\n' +
                      '          <li>беременность;</li>\n' +
                      '          <li>алкоголизм;</li>\n' +
                      '          <li>недавнее введение прививки;</li>\n' +
                      '          <li>язва желудка или двенадцатиперстной кишки;</li>\n' +
                      '          <li>татуировки;</li>\n' +
                      '          <li>обширные стоматологические поражения.</li>\n' +
                      '        </ul>',
                    sale: '10',
                    doctor: [ // TODO могут ли одну услугу оказывать несколько врачей?
                      {
                        id: 1,
                        lastName: 'Замза',
                        firstName: 'Грегор',
                        middleName: '',
                        specialization: 'Уролог',
                        main_photo: {
                          id: 16,
                          url: '../../assets/img/doctors/doc-2.png'
                        }
                      }
                    ],
                    documents: [
                      {
                        id: 1,
                        title: 'Паспорт',
                        // tslint:disable-next-line:max-line-length
                        desc: 'Проснувшись однажды утром после беспокойного сна, Грегор Замза обнаружил, что он у себя в постели превратился в страшное насекомое.',
                        main_photo:
                          {
                            id: 1,
                            url: '../../assets/img/doc-img-1.png',
                          }
                      }
                    ],
                    tags: [
                      {
                        id: 1,
                        name: 'Кровь',
                        desc: 'Описание тега'
                      },
                      {
                        id: 4,
                        name: 'Железо',
                        desc: 'Описание тега'
                      },
                      {
                        id: 5,
                        name: 'Белки',
                        desc: 'Описание тега'
                      }
                    ],
                    slider: [
                      {
                        id: 1,
                        // tslint:disable-next-line:max-line-length
                        title: 'Ощущения пациента во время проведения ультразвуковой эластографии такие же, как и при обычном УЗИ. Ни боли, ни дискомфорта пациенты не испытывают.',
                        // tslint:disable-next-line:max-line-length
                        desc: 'Ощущения пациента во время проведения ультразвуковой эластографии такие же, как и при обычном УЗИ. Ни боли, ни дискомфорта пациенты не испытывают.',
                        main_photo: {
                          url: '../../assets/img/big-slide-3.png'
                        },
                      },
                      {
                        id: 2,
                        // tslint:disable-next-line:max-line-length
                        title: 'Ощущения пациента во время проведения ',
                        // tslint:disable-next-line:max-line-length
                        desc: 'Ощущения пациента во время проведения ультразвуковой эластографии такие же, как и при обычном УЗИ. Ни боли, ни дискомфорта пациенты не испытывают.',
                        main_photo: {
                          url: '../../assets/img/big-slide-4.png'
                        }
                      }
                    ],
                    catalog:
                      {
                        id: 1,
                        name: 'Клинический анализ крови',
                        desc: 'Описание Клинический анализ крови'
                      }
                  },
                  {
                    id: 3,
                    name: 'УЗИ печеночного трансплантанта с дуплексным сканированием артерий и вен',
                    display_name: 'УЗИ печеночного трансплантанта с дуплексным сканированием артерий и вен',
                    price: '1320',
                    // tslint:disable-next-line:max-line-length
                    anons: 'Ультразвуковую диагностику смело можно назвать уникальным методом исследования. Она обладает такими свойствами как неинвазивность, возможность многократного проведения исследования без вреда для здоровья пациента, быстрота получения информации, отсутствие противопоказаний, исключение лучевой нагрузки.',
                    // tslint:disable-next-line:max-line-length
                    body: 'Проснувшись однажды утром после беспокойного сна, Грегор Замза обнаружил, что он у себя в постели превратился в страшное насекомое.',
                    // tslint:disable-next-line:max-line-length
                    memo: 'Вы должны подготовить себя к исследованию следующим образом: воздержаться от физических нагрузок, приема алкоголя и лекарств, изменений в питании в течение 24 часов до взятия крови. Вам не следует принимать пищу после ужина, лечь спать нужно в обычное для Вас время и встать не позднее, чем за час до взятия крови. Утром до сдачи анализа воздержитесь от курения и приема пищи. Взятие крови осуществляется до проведения диагностических или лечебных процедур: инъекций, общего массажа тела, эндоскопий, ЭКГ, рентгеновского обследования и т.д. Если Вы испытываете трудности с отменой лекарств, то обязательно сообщите об этом лечащему врачу. Очень важно, чтобы Вы точно соблюдали указанные рекомендации, так как только в этом случае будут получены ценные результаты исследования крови.',
                    // tslint:disable-next-line:max-line-length
                    indication: 'Анализ на свертываемость является одним из самых простых и доступных методов исследования. Пациенту нужно отказываться от приема пищи в течение двух часов перед исследованием. Результаты готовы, как правило, через несколько часов. Такое тестирование проводится в рамках комплексного обследования организма, а также при наличии кровотечений невыясненной природы и при диагностике или контроле эффективности лечения патологий костного мозга. Референсные значения зависят от возраста, а в детстве — еще и от пола.',
                    contraindic: '<ul>При некоторых заболеваниях проведение забора тромбоцитарной массы категорически запрещено: \n' +
                      '          <li>туберкулез;</li>\n' +
                      '          <li>вирус иммунодефицита;</li>\n' +
                      '          <li>раковые патологии;</li>\n' +
                      '          <li>гепатиты;</li>\n' +
                      '          <li>нарушения в работе сердца и сосудов;</li>\n' +
                      '          <li>после проведения оперативных вмешательств;</li>\n' +
                      '          <li>беременность;</li>\n' +
                      '          <li>алкоголизм;</li>\n' +
                      '          <li>недавнее введение прививки;</li>\n' +
                      '          <li>язва желудка или двенадцатиперстной кишки;</li>\n' +
                      '          <li>татуировки;</li>\n' +
                      '          <li>обширные стоматологические поражения.</li>\n' +
                      '        </ul>',
                    sale: '20',
                    doctor: [ // TODO могут ли одну услугу оказывать несколько врачей?
                      {
                        id: 1,
                        lastName: 'Коновалов',
                        firstName: 'Геннадий',
                        middleName: 'Александрович',
                        specialization: 'Главный ревматолог',
                        main_photo: {
                          id: 1,
                          url: '../../assets/img/doctors/doc-3.png'
                        }
                      },
                      {
                        id: 2,
                        lastName: 'Морозова',
                        firstName: 'Екатерина',
                        middleName: 'Владимировна',
                        specialization: 'ЛОР',
                        main_photo: {
                          id: 11,
                          url: '../../assets/img/doctors/doc-5.png'
                        }
                      }
                    ],
                    documents: [
                      {
                        id: 1,
                        title: 'Паспорт',
                        // tslint:disable-next-line:max-line-length
                        desc: 'Проснувшись однажды утром после беспокойного сна, Грегор Замза обнаружил, что он у себя в постели превратился в страшное насекомое.',
                        main_photo:
                          {
                            id: 1,
                            url: '../../assets/img/doc-img-1.png',
                          }
                      },
                      {
                        id: 2,
                        title: 'Медицинский полис',
                        // tslint:disable-next-line:max-line-length
                        desc: 'Проснувшись однажды утром после беспокойного сна, Грегор Замза обнаружил, что он у себя в постели превратился в страшное насекомое.',
                        main_photo:
                          {
                            id: 1,
                            url: '../../assets/img/doc-img-2.png',
                          }
                      },
                      {
                        id: 3,
                        title: 'Направление доктора',
                        // tslint:disable-next-line:max-line-length
                        desc: 'Проснувшись однажды утром после беспокойного сна, Грегор Замза обнаружил, что он у себя в постели превратился в страшное насекомое.',
                        main_photo:
                          {
                            id: 1,
                            url: '../../assets/img/doc-img-3.png',
                          }
                      }
                    ],
                    tags: [
                      {
                        id: 1,
                        name: 'Кровь',
                        desc: 'Описание тега'
                      },
                      {
                        id: 2,
                        name: 'Глюкоза',
                        desc: 'Описание тега'
                      },
                      {
                        id: 3,
                        name: 'Витамины',
                        desc: 'Описание тега'
                      }
                    ],
                    catalog:
                      {
                        id: 1,
                        name: 'Клинический анализ крови',
                        desc: 'Описание Клинический анализ крови'
                      }
                  },
                  {
                    id: 6,
                    name: 'УЗИ печеночного трансплантанта с дуплексным сканированием артерий и вен',
                    display_name: 'УЗИ печеночного трансплантанта с дуплексным сканированием артерий и вен',
                    price: '1320',
                    // tslint:disable-next-line:max-line-length
                    anons: 'Ультразвуковую диагностику смело можно назвать уникальным методом исследования. Она обладает такими свойствами как неинвазивность, возможность многократного проведения исследования без вреда для здоровья пациента, быстрота получения информации, отсутствие противопоказаний, исключение лучевой нагрузки.',
                    // tslint:disable-next-line:max-line-length
                    body: 'Проснувшись однажды утром после беспокойного сна, Грегор Замза обнаружил, что он у себя в постели превратился в страшное насекомое.',
                    // tslint:disable-next-line:max-line-length
                    memo: 'Вы должны подготовить себя к исследованию следующим образом: воздержаться от физических нагрузок, приема алкоголя и лекарств, изменений в питании в течение 24 часов до взятия крови. Вам не следует принимать пищу после ужина, лечь спать нужно в обычное для Вас время и встать не позднее, чем за час до взятия крови. Утром до сдачи анализа воздержитесь от курения и приема пищи. Взятие крови осуществляется до проведения диагностических или лечебных процедур: инъекций, общего массажа тела, эндоскопий, ЭКГ, рентгеновского обследования и т.д. Если Вы испытываете трудности с отменой лекарств, то обязательно сообщите об этом лечащему врачу. Очень важно, чтобы Вы точно соблюдали указанные рекомендации, так как только в этом случае будут получены ценные результаты исследования крови.',
                    // tslint:disable-next-line:max-line-length
                    indication: 'Анализ на свертываемость является одним из самых простых и доступных методов исследования. Пациенту нужно отказываться от приема пищи в течение двух часов перед исследованием. Результаты готовы, как правило, через несколько часов. Такое тестирование проводится в рамках комплексного обследования организма, а также при наличии кровотечений невыясненной природы и при диагностике или контроле эффективности лечения патологий костного мозга. Референсные значения зависят от возраста, а в детстве — еще и от пола.',
                    contraindic: '<ul>При некоторых заболеваниях проведение забора тромбоцитарной массы категорически запрещено: \n' +
                      '          <li>туберкулез;</li>\n' +
                      '          <li>вирус иммунодефицита;</li>\n' +
                      '          <li>раковые патологии;</li>\n' +
                      '          <li>гепатиты;</li>\n' +
                      '          <li>нарушения в работе сердца и сосудов;</li>\n' +
                      '          <li>после проведения оперативных вмешательств;</li>\n' +
                      '          <li>беременность;</li>\n' +
                      '          <li>алкоголизм;</li>\n' +
                      '          <li>недавнее введение прививки;</li>\n' +
                      '          <li>язва желудка или двенадцатиперстной кишки;</li>\n' +
                      '          <li>татуировки;</li>\n' +
                      '          <li>обширные стоматологические поражения.</li>\n' +
                      '        </ul>',
                    sale: '20',
                    tags: [
                      {
                        id: 1,
                        name: 'Кровь',
                        desc: 'Описание тега'
                      },
                      {
                        id: 2,
                        name: 'Глюкоза',
                        desc: 'Описание тега'
                      },
                      {
                        id: 3,
                        name: 'Витамины',
                        desc: 'Описание тега'
                      }
                    ],
                    catalog:
                      {
                        id: 1,
                        name: 'Анализы крови',
                        desc: 'Описание Клинический анализ крови'
                      }
                  },
                  {
                    id: 7,
                    name: 'Анализ крови артерий и вен',
                    display_name: 'Анализ крови артерий и вен',
                    price: '1320',
                    // tslint:disable-next-line:max-line-length
                    anons: 'Ультразвуковую диагностику смело можно назвать уникальным методом исследования. Она обладает такими свойствами как неинвазивность, возможность многократного проведения исследования без вреда для здоровья пациента, быстрота получения информации, отсутствие противопоказаний, исключение лучевой нагрузки.',
                    // tslint:disable-next-line:max-line-length
                    body: 'Проснувшись однажды утром после беспокойного сна, Грегор Замза обнаружил.',
                    // tslint:disable-next-line:max-line-length
                    memo: 'Вы должны подготовить себя к исследованию следующим образом: воздержаться от физических нагрузок, приема алкоголя и лекарств, изменений в питании в течение 24 часов до взятия крови. Вам не следует принимать пищу после ужина, лечь спать нужно в обычное для Вас время и встать не позднее, чем за час до взятия крови. Утром до сдачи анализа воздержитесь от курения и приема пищи. Взятие крови осуществляется до проведения диагностических или лечебных процедур: инъекций, общего массажа тела, эндоскопий, ЭКГ, рентгеновского обследования и т.д. Если Вы испытываете трудности с отменой лекарств, то обязательно сообщите об этом лечащему врачу. Очень важно, чтобы Вы точно соблюдали указанные рекомендации, так как только в этом случае будут получены ценные результаты исследования крови.',
                    // tslint:disable-next-line:max-line-length
                    indication: 'Анализ на свертываемость является одним из самых простых и доступных методов исследования. Пациенту нужно отказываться от приема пищи в течение двух часов перед исследованием. Результаты готовы, как правило, через несколько часов. Такое тестирование проводится в рамках комплексного обследования организма, а также при наличии кровотечений невыясненной природы и при диагностике или контроле эффективности лечения патологий костного мозга. Референсные значения зависят от возраста, а в детстве — еще и от пола.',
                    contraindic: '<ul>При некоторых заболеваниях проведение забора тромбоцитарной массы категорически запрещено: \n' +
                      '          <li>туберкулез;</li>\n' +
                      '          <li>вирус иммунодефицита;</li>\n' +
                      '          <li>раковые патологии;</li>\n' +
                      '          <li>гепатиты;</li>\n' +
                      '          <li>нарушения в работе сердца и сосудов;</li>\n' +
                      '          <li>после проведения оперативных вмешательств;</li>\n' +
                      '          <li>беременность;</li>\n' +
                      '          <li>алкоголизм;</li>\n' +
                      '          <li>недавнее введение прививки;</li>\n' +
                      '          <li>язва желудка или двенадцатиперстной кишки;</li>\n' +
                      '          <li>татуировки;</li>\n' +
                      '          <li>обширные стоматологические поражения.</li>\n' +
                      '        </ul>',
                    sale: '',
                    documents: [
                      {
                        id: 1,
                        title: 'Паспорт',
                        // tslint:disable-next-line:max-line-length
                        desc: 'Проснувшись однажды утром после беспокойного сна, Грегор Замза обнаружил, что он у себя в постели превратился в страшное насекомое.',
                        main_photo:
                          {
                            id: 1,
                            url: '../../assets/img/doc-img-1.png',
                          }
                      }
                    ],
                    tags: [
                      {
                        id: 1,
                        name: 'Кровь',
                        desc: 'Описание тега'
                      },
                      {
                        id: 2,
                        name: 'Глюкоза',
                        desc: 'Описание тега'
                      },
                      {
                        id: 3,
                        name: 'Гормоны',
                        desc: 'Описание тега'
                      }
                    ],
                    catalog:
                      {
                        id: 1,
                        name: 'Анализы крови',
                        desc: 'Описание Клинический анализ крови'
                      }
                  }
                ],
              },
              {
                id: 23,
                name: 'Эндоскопия',
                main_photo: {
                  id: 1,
                  url: '../../assets/img/direction2-1.png',
                },
                short_description: 'Краткое описание Эндоскопия',
                description: 'Полное описание Эндоскопия',
                icon: null,
                units: [
                  {
                    id: 2,
                    name: 'Поликлиника №1',
                    address: {
                      id: 1,
                      city: 'Красноярск',
                      street: 'Вавилова',
                      house: '28',
                      building: '',
                      ymapcfg: '../../assets/img/contacts-card-map.png',
                      postcode: '660025',
                      region: 'Красноярский край',
                      mailbox: '6545'
                    },
                    phones: [
                      {
                        id: 7,
                        number: '+7 (391) 274-31-64',
                        description: 'круглосуточно'
                      },
                      {
                        id: 8,
                        number: '+7 (391) 257-64-60',
                        description: 'с 9:00 до 17:00'
                      }
                    ],
                  },
                ],
                services: [
                  {
                    id: 5,
                    name: 'УЗИ печеночного трансплантанта с дуплексным сканированием артерий и вен',
                    display_name: 'УЗИ печеночного трансплантанта с дуплексным сканированием артерий и вен',
                    price: '1320',
                    // tslint:disable-next-line:max-line-length
                    anons: 'Ультразвуковую диагностику смело можно назвать уникальным методом исследования. Она обладает такими свойствами как неинвазивность, возможность многократного проведения исследования без вреда для здоровья пациента, быстрота получения информации, отсутствие противопоказаний, исключение лучевой нагрузки.',
                    // tslint:disable-next-line:max-line-length
                    body: 'Проснувшись однажды утром после беспокойного сна, Грегор Замза обнаружил, что он у себя в постели превратился в страшное насекомое.',
                    // tslint:disable-next-line:max-line-length
                    memo: 'Вы должны подготовить себя к исследованию следующим образом: воздержаться от физических нагрузок, приема алкоголя и лекарств, изменений в питании в течение 24 часов до взятия крови. Вам не следует принимать пищу после ужина, лечь спать нужно в обычное для Вас время и встать не позднее, чем за час до взятия крови. Утром до сдачи анализа воздержитесь от курения и приема пищи. Взятие крови осуществляется до проведения диагностических или лечебных процедур: инъекций, общего массажа тела, эндоскопий, ЭКГ, рентгеновского обследования и т.д. Если Вы испытываете трудности с отменой лекарств, то обязательно сообщите об этом лечащему врачу. Очень важно, чтобы Вы точно соблюдали указанные рекомендации, так как только в этом случае будут получены ценные результаты исследования крови.',
                    // tslint:disable-next-line:max-line-length
                    indication: 'Анализ на свертываемость является одним из самых простых и доступных методов исследования. Пациенту нужно отказываться от приема пищи в течение двух часов перед исследованием. Результаты готовы, как правило, через несколько часов. Такое тестирование проводится в рамках комплексного обследования организма, а также при наличии кровотечений невыясненной природы и при диагностике или контроле эффективности лечения патологий костного мозга. Референсные значения зависят от возраста, а в детстве — еще и от пола.',
                    contraindic: '<ul>При некоторых заболеваниях проведение забора тромбоцитарной массы категорически запрещено: \n' +
                      '          <li>туберкулез;</li>\n' +
                      '          <li>вирус иммунодефицита;</li>\n' +
                      '          <li>раковые патологии;</li>\n' +
                      '          <li>гепатиты;</li>\n' +
                      '          <li>нарушения в работе сердца и сосудов;</li>\n' +
                      '          <li>после проведения оперативных вмешательств;</li>\n' +
                      '          <li>беременность;</li>\n' +
                      '          <li>алкоголизм;</li>\n' +
                      '          <li>недавнее введение прививки;</li>\n' +
                      '          <li>язва желудка или двенадцатиперстной кишки;</li>\n' +
                      '          <li>татуировки;</li>\n' +
                      '          <li>обширные стоматологические поражения.</li>\n' +
                      '        </ul>',
                    sale: '',
                    documents: [
                      {
                        id: 1,
                        title: 'Паспорт',
                        // tslint:disable-next-line:max-line-length
                        desc: 'Проснувшись однажды утром после беспокойного сна, Грегор Замза обнаружил, что он у себя в постели превратился в страшное насекомое.',
                        main_photo:
                          {
                            id: 1,
                            url: '../../assets/img/doc-img-1.png',
                          }
                      }
                    ],
                    tags: [
                      {
                        id: 1,
                        name: 'Кровь',
                        desc: 'Описание тега'
                      },
                      {
                        id: 2,
                        name: 'Глюкоза',
                        desc: 'Описание тега'
                      },
                      {
                        id: 3,
                        name: 'Витамины',
                        desc: 'Описание тега'
                      }
                    ],
                    catalog:
                      {
                        id: 1,
                        name: 'УЗИ - диагностические исследования',
                        desc: 'Описание Клинический анализ крови'
                      }
                  },
                  {
                    id: 6,
                    name: 'УЗИ печеночного трансплантанта с дуплексным сканированием артерий и вен',
                    display_name: 'УЗИ печеночного трансплантанта с дуплексным сканированием артерий и вен',
                    price: '1320',
                    // tslint:disable-next-line:max-line-length
                    anons: 'Ультразвуковую диагностику смело можно назвать уникальным методом исследования. Она обладает такими свойствами как неинвазивность, возможность многократного проведения исследования без вреда для здоровья пациента, быстрота получения информации, отсутствие противопоказаний, исключение лучевой нагрузки.',
                    // tslint:disable-next-line:max-line-length
                    body: 'Проснувшись однажды утром после беспокойного сна, Грегор Замза обнаружил, что он у себя в постели превратился в страшное насекомое.',
                    // tslint:disable-next-line:max-line-length
                    memo: 'Вы должны подготовить себя к исследованию следующим образом: воздержаться от физических нагрузок, приема алкоголя и лекарств, изменений в питании в течение 24 часов до взятия крови. Вам не следует принимать пищу после ужина, лечь спать нужно в обычное для Вас время и встать не позднее, чем за час до взятия крови. Утром до сдачи анализа воздержитесь от курения и приема пищи. Взятие крови осуществляется до проведения диагностических или лечебных процедур: инъекций, общего массажа тела, эндоскопий, ЭКГ, рентгеновского обследования и т.д. Если Вы испытываете трудности с отменой лекарств, то обязательно сообщите об этом лечащему врачу. Очень важно, чтобы Вы точно соблюдали указанные рекомендации, так как только в этом случае будут получены ценные результаты исследования крови.',
                    // tslint:disable-next-line:max-line-length
                    indication: 'Анализ на свертываемость является одним из самых простых и доступных методов исследования. Пациенту нужно отказываться от приема пищи в течение двух часов перед исследованием. Результаты готовы, как правило, через несколько часов. Такое тестирование проводится в рамках комплексного обследования организма, а также при наличии кровотечений невыясненной природы и при диагностике или контроле эффективности лечения патологий костного мозга. Референсные значения зависят от возраста, а в детстве — еще и от пола.',
                    contraindic: '<ul>При некоторых заболеваниях проведение забора тромбоцитарной массы категорически запрещено: \n' +
                      '          <li>туберкулез;</li>\n' +
                      '          <li>вирус иммунодефицита;</li>\n' +
                      '          <li>раковые патологии;</li>\n' +
                      '          <li>гепатиты;</li>\n' +
                      '          <li>нарушения в работе сердца и сосудов;</li>\n' +
                      '          <li>после проведения оперативных вмешательств;</li>\n' +
                      '          <li>беременность;</li>\n' +
                      '          <li>алкоголизм;</li>\n' +
                      '          <li>недавнее введение прививки;</li>\n' +
                      '          <li>язва желудка или двенадцатиперстной кишки;</li>\n' +
                      '          <li>татуировки;</li>\n' +
                      '          <li>обширные стоматологические поражения.</li>\n' +
                      '        </ul>',
                    sale: '20',
                    tags: [
                      {
                        id: 1,
                        name: 'Кровь',
                        desc: 'Описание тега'
                      },
                      {
                        id: 2,
                        name: 'Глюкоза',
                        desc: 'Описание тега'
                      },
                      {
                        id: 3,
                        name: 'Витамины',
                        desc: 'Описание тега'
                      }
                    ],
                    catalog:
                      {
                        id: 1,
                        name: 'Анализы крови',
                        desc: 'Описание Клинический анализ крови'
                      }
                  },
                  {
                    id: 7,
                    name: 'Анализ крови артерий и вен',
                    display_name: 'Анализ крови артерий и вен',
                    price: '1320',
                    // tslint:disable-next-line:max-line-length
                    anons: 'Ультразвуковую диагностику смело можно назвать уникальным методом исследования. Она обладает такими свойствами как неинвазивность, возможность многократного проведения исследования без вреда для здоровья пациента, быстрота получения информации, отсутствие противопоказаний, исключение лучевой нагрузки.',
                    // tslint:disable-next-line:max-line-length
                    body: 'Проснувшись однажды утром после беспокойного сна, Грегор Замза обнаружил.',
                    // tslint:disable-next-line:max-line-length
                    memo: 'Вы должны подготовить себя к исследованию следующим образом: воздержаться от физических нагрузок, приема алкоголя и лекарств, изменений в питании в течение 24 часов до взятия крови. Вам не следует принимать пищу после ужина, лечь спать нужно в обычное для Вас время и встать не позднее, чем за час до взятия крови. Утром до сдачи анализа воздержитесь от курения и приема пищи. Взятие крови осуществляется до проведения диагностических или лечебных процедур: инъекций, общего массажа тела, эндоскопий, ЭКГ, рентгеновского обследования и т.д. Если Вы испытываете трудности с отменой лекарств, то обязательно сообщите об этом лечащему врачу. Очень важно, чтобы Вы точно соблюдали указанные рекомендации, так как только в этом случае будут получены ценные результаты исследования крови.',
                    // tslint:disable-next-line:max-line-length
                    indication: 'Анализ на свертываемость является одним из самых простых и доступных методов исследования. Пациенту нужно отказываться от приема пищи в течение двух часов перед исследованием. Результаты готовы, как правило, через несколько часов. Такое тестирование проводится в рамках комплексного обследования организма, а также при наличии кровотечений невыясненной природы и при диагностике или контроле эффективности лечения патологий костного мозга. Референсные значения зависят от возраста, а в детстве — еще и от пола.',
                    contraindic: '<ul>При некоторых заболеваниях проведение забора тромбоцитарной массы категорически запрещено: \n' +
                      '          <li>туберкулез;</li>\n' +
                      '          <li>вирус иммунодефицита;</li>\n' +
                      '          <li>раковые патологии;</li>\n' +
                      '          <li>гепатиты;</li>\n' +
                      '          <li>нарушения в работе сердца и сосудов;</li>\n' +
                      '          <li>после проведения оперативных вмешательств;</li>\n' +
                      '          <li>беременность;</li>\n' +
                      '          <li>алкоголизм;</li>\n' +
                      '          <li>недавнее введение прививки;</li>\n' +
                      '          <li>язва желудка или двенадцатиперстной кишки;</li>\n' +
                      '          <li>татуировки;</li>\n' +
                      '          <li>обширные стоматологические поражения.</li>\n' +
                      '        </ul>',
                    sale: '',
                    documents: [
                      {
                        id: 1,
                        title: 'Паспорт',
                        // tslint:disable-next-line:max-line-length
                        desc: 'Проснувшись однажды утром после беспокойного сна, Грегор Замза обнаружил, что он у себя в постели превратился в страшное насекомое.',
                        main_photo:
                          {
                            id: 1,
                            url: '../../assets/img/doc-img-1.png',
                          }
                      }
                    ],
                    tags: [
                      {
                        id: 1,
                        name: 'Кровь',
                        desc: 'Описание тега'
                      },
                      {
                        id: 2,
                        name: 'Глюкоза',
                        desc: 'Описание тега'
                      },
                      {
                        id: 3,
                        name: 'Гормоны',
                        desc: 'Описание тега'
                      }
                    ],
                    catalog:
                      {
                        id: 1,
                        name: 'Анализы крови',
                        desc: 'Описание Клинический анализ крови'
                      }
                  }
                ],
              }
            ],
            directions: [
              {
                id: 60,
                name: 'root',
                short_description: null,
                description: null,
                icon: null
              }
            ],
          },
        ],
        services: [
          {
            id: 1,
            name: 'УЗИ печеночного трансплантанта с дуплексным сканированием артерий и вен',
            display_name: 'УЗИ печеночного трансплантанта с дуплексным сканированием артерий и вен',
            price: '1320',
            // tslint:disable-next-line:max-line-length
            anons: 'Ультразвуковую диагностику смело можно назвать уникальным методом исследования. Она обладает такими свойствами как неинвазивность, возможность многократного проведения исследования без вреда для здоровья пациента, быстрота получения информации, отсутствие противопоказаний, исключение лучевой нагрузки.',
            // tslint:disable-next-line:max-line-length
            body: 'Проснувшись однажды утром после беспокойного сна, Грегор Замза обнаружил, что он у себя в постели превратился в страшное насекомое.',
            // tslint:disable-next-line:max-line-length
            memo: 'Вы должны подготовить себя к исследованию следующим образом: воздержаться от физических нагрузок, приема алкоголя и лекарств, изменений в питании в течение 24 часов до взятия крови. Вам не следует принимать пищу после ужина, лечь спать нужно в обычное для Вас время и встать не позднее, чем за час до взятия крови. Утром до сдачи анализа воздержитесь от курения и приема пищи. Взятие крови осуществляется до проведения диагностических или лечебных процедур: инъекций, общего массажа тела, эндоскопий, ЭКГ, рентгеновского обследования и т.д. Если Вы испытываете трудности с отменой лекарств, то обязательно сообщите об этом лечащему врачу. Очень важно, чтобы Вы точно соблюдали указанные рекомендации, так как только в этом случае будут получены ценные результаты исследования крови.',
            // tslint:disable-next-line:max-line-length
            indication: 'Анализ на свертываемость является одним из самых простых и доступных методов исследования. Пациенту нужно отказываться от приема пищи в течение двух часов перед исследованием. Результаты готовы, как правило, через несколько часов. Такое тестирование проводится в рамках комплексного обследования организма, а также при наличии кровотечений невыясненной природы и при диагностике или контроле эффективности лечения патологий костного мозга. Референсные значения зависят от возраста, а в детстве — еще и от пола.',
            contraindic: '<ul>При некоторых заболеваниях проведение забора тромбоцитарной массы категорически запрещено: \n' +
              '          <li>туберкулез;</li>\n' +
              '          <li>вирус иммунодефицита;</li>\n' +
              '          <li>раковые патологии;</li>\n' +
              '          <li>гепатиты;</li>\n' +
              '          <li>нарушения в работе сердца и сосудов;</li>\n' +
              '          <li>после проведения оперативных вмешательств;</li>\n' +
              '          <li>беременность;</li>\n' +
              '          <li>алкоголизм;</li>\n' +
              '          <li>недавнее введение прививки;</li>\n' +
              '          <li>язва желудка или двенадцатиперстной кишки;</li>\n' +
              '          <li>татуировки;</li>\n' +
              '          <li>обширные стоматологические поражения.</li>\n' +
              '        </ul>',
            sale: '',
            doctor: [ // TODO могут ли одну услугу оказывать несколько врачей?
              {
                id: 2,
                lastName: 'Морозова',
                firstName: 'Екатерина',
                middleName: 'Владимировна',
                specialization: 'ЛОР',
                main_photo: {
                  id: 11,
                  url: '../../assets/img/doctors/doc-5.png'
                }
              }
            ],
            documents: [
              {
                id: 1,
                title: 'Паспорт',
                // tslint:disable-next-line:max-line-length
                desc: 'Проснувшись однажды утром после беспокойного сна, Грегор Замза обнаружил, что он у себя в постели превратился в страшное насекомое.',
                main_photo:
                  {
                    id: 1,
                    url: '../../assets/img/doc-img-1.png',
                  }
              },
              {
                id: 2,
                title: 'Медицинский полис',
                // tslint:disable-next-line:max-line-length
                desc: 'Проснувшись однажды утром после беспокойного сна, Грегор Замза обнаружил, что он у себя в постели превратился в страшное насекомое.',
                main_photo:
                  {
                    id: 1,
                    url: '../../assets/img/doc-img-2.png',
                  }
              },
              {
                id: 3,
                title: 'Направление доктора',
                // tslint:disable-next-line:max-line-length
                desc: 'Проснувшись однажды утром после беспокойного сна, Грегор Замза обнаружил, что он у себя в постели превратился в страшное насекомое.',
                main_photo:
                  {
                    id: 1,
                    url: '../../assets/img/doc-img-3.png',
                  }
              }
            ],
            tags: [
              {
                id: 1,
                name: 'Кровь',
                desc: 'Описание тега'
              },
              {
                id: 2,
                name: 'Глюкоза',
                desc: 'Описание тега'
              },
              {
                id: 3,
                name: 'Витамины',
                desc: 'Описание тега'
              }
            ],
            slider: [
              {
                id: 1,
                // tslint:disable-next-line:max-line-length
                title: 'Ощущения пациента во время проведения ультразвуковой эластографии такие же, как и при обычном УЗИ. Ни боли, ни дискомфорта пациенты не испытывают.',
                // tslint:disable-next-line:max-line-length
                desc: 'Ощущения пациента во время проведения ультразвуковой эластографии такие же, как и при обычном УЗИ. Ни боли, ни дискомфорта пациенты не испытывают.',
                main_photo: {
                  url: '../../assets/img/big-slide-3.png'
                },
              },
              {
                id: 2,
                // tslint:disable-next-line:max-line-length
                title: 'Ощущения пациента во время проведения ',
                // tslint:disable-next-line:max-line-length
                desc: 'Ощущения пациента во время проведения ультразвуковой эластографии такие же, как и при обычном УЗИ. Ни боли, ни дискомфорта пациенты не испытывают.',
                main_photo: {
                  url: '../../assets/img/big-slide-4.png'
                }
              },
              {
                id: 3,
                // tslint:disable-next-line:max-line-length
                title: 'Ощущения пациента во время проведения ультразвуковой эластографии такие же, как и при обычном УЗИ. Ни боли, ни дискомфорта пациенты не испытывают.',
                // tslint:disable-next-line:max-line-length
                desc: 'Ощущения пациента во время проведения ультразвуковой эластографии такие же, как и при обычном УЗИ. Ни боли, ни дискомфорта пациенты не испытывают.',
                main_photo: {
                  url: '../../assets/img/big-slide-3.png'
                },
              },
              {
                id: 4,
                // tslint:disable-next-line:max-line-length
                title: 'Ощущения пациента во время проведения ',
                // tslint:disable-next-line:max-line-length
                desc: 'Ощущения пациента во время проведения ультразвуковой эластографии такие же, как и при обычном УЗИ. Ни боли, ни дискомфорта пациенты не испытывают.',
                main_photo: {
                  url: '../../assets/img/big-slide-4.png'
                }
              }
            ],
            catalog:
              {
                id: 1,
                name: 'Клинический анализ крови',
                desc: 'Описание Клинический анализ крови'
              }
          },
          {
            id: 2,
            name: 'Вторая услуга и она со скидкой',
            display_name: 'Вторая услуга и она со скидкой. УЗИ печеночного трансплантанта с дуплексным сканированием артерий и вен',
            price: '2502',
            // tslint:disable-next-line:max-line-length
            anons: 'Ультразвуковую диагностику смело можно назвать уникальным методом исследования. Она обладает такими свойствами как неинвазивность, возможность многократного проведения исследования без вреда для здоровья пациента, быстрота получения информации, отсутствие противопоказаний, исключение лучевой нагрузки.',
            // tslint:disable-next-line:max-line-length
            body: 'Проснувшись однажды утром после беспокойного сна, Грегор Замза обнаружил, что он у себя в постели превратился в страшное насекомое.',
            // tslint:disable-next-line:max-line-length
            memo: 'Вы должны подготовить себя к исследованию следующим образом: воздержаться от физических нагрузок, приема алкоголя и лекарств, изменений в питании в течение 24 часов до взятия крови. Вам не следует принимать пищу после ужина, лечь спать нужно в обычное для Вас время и встать не позднее, чем за час до взятия крови. Утром до сдачи анализа воздержитесь от курения и приема пищи. Взятие крови осуществляется до проведения диагностических или лечебных процедур: инъекций, общего массажа тела, эндоскопий, ЭКГ, рентгеновского обследования и т.д. Если Вы испытываете трудности с отменой лекарств, то обязательно сообщите об этом лечащему врачу. Очень важно, чтобы Вы точно соблюдали указанные рекомендации, так как только в этом случае будут получены ценные результаты исследования крови.',
            // tslint:disable-next-line:max-line-length
            indication: 'Анализ на свертываемость является одним из самых простых и доступных методов исследования. Пациенту нужно отказываться от приема пищи в течение двух часов перед исследованием. Результаты готовы, как правило, через несколько часов. Такое тестирование проводится в рамках комплексного обследования организма, а также при наличии кровотечений невыясненной природы и при диагностике или контроле эффективности лечения патологий костного мозга. Референсные значения зависят от возраста, а в детстве — еще и от пола.',
            contraindic: '<ul>При некоторых заболеваниях проведение забора тромбоцитарной массы категорически запрещено: \n' +
              '          <li>туберкулез;</li>\n' +
              '          <li>вирус иммунодефицита;</li>\n' +
              '          <li>раковые патологии;</li>\n' +
              '          <li>гепатиты;</li>\n' +
              '          <li>нарушения в работе сердца и сосудов;</li>\n' +
              '          <li>после проведения оперативных вмешательств;</li>\n' +
              '          <li>беременность;</li>\n' +
              '          <li>алкоголизм;</li>\n' +
              '          <li>недавнее введение прививки;</li>\n' +
              '          <li>язва желудка или двенадцатиперстной кишки;</li>\n' +
              '          <li>татуировки;</li>\n' +
              '          <li>обширные стоматологические поражения.</li>\n' +
              '        </ul>',
            sale: '13',
            doctor: [ // TODO могут ли одну услугу оказывать несколько врачей?
              {
                id: 2,
                lastName: 'Морозова',
                firstName: 'Екатерина',
                middleName: 'Владимировна',
                specialization: 'ЛОР',
                main_photo: {
                  id: 11,
                  url: '../../assets/img/doctors/doc-5.png'
                }
              }
            ],
            documents: [
              {
                id: 1,
                title: 'Паспорт',
                // tslint:disable-next-line:max-line-length
                desc: 'Проснувшись однажды утром после беспокойного сна, Грегор Замза обнаружил, что он у себя в постели превратился в страшное насекомое.',
                main_photo:
                  {
                    id: 1,
                    url: '../../assets/img/doc-img-1.png',
                  }
              },
              {
                id: 2,
                title: 'Медицинский полис',
                // tslint:disable-next-line:max-line-length
                desc: 'Проснувшись однажды утром после беспокойного сна, Грегор Замза обнаружил, что он у себя в постели превратился в страшное насекомое.',
                main_photo:
                  {
                    id: 1,
                    url: '../../assets/img/doc-img-2.png',
                  }
              },
              {
                id: 3,
                title: 'Направление доктора',
                // tslint:disable-next-line:max-line-length
                desc: 'Проснувшись однажды утром после беспокойного сна, Грегор Замза обнаружил, что он у себя в постели превратился в страшное насекомое.',
                main_photo:
                  {
                    id: 1,
                    url: '../../assets/img/doc-img-3.png',
                  }
              }
            ],
            tags: [
              {
                id: 1,
                name: 'Кровь',
                desc: 'Описание тега'
              },
              {
                id: 2,
                name: 'Глюкоза',
                desc: 'Описание тега'
              },
              {
                id: 3,
                name: 'Витамины',
                desc: 'Описание тега'
              }
            ],
            slider: [
              {
                id: 1,
                // tslint:disable-next-line:max-line-length
                title: 'Ощущения пациента во время проведения ультразвуковой эластографии такие же, как и при обычном УЗИ. Ни боли, ни дискомфорта пациенты не испытывают.',
                // tslint:disable-next-line:max-line-length
                desc: 'Ощущения пациента во время проведения ультразвуковой эластографии такие же, как и при обычном УЗИ. Ни боли, ни дискомфорта пациенты не испытывают.',
                main_photo: {
                  url: '../../assets/img/big-slide-3.png'
                },
              },
              {
                id: 2,
                // tslint:disable-next-line:max-line-length
                title: 'Ощущения пациента во время проведения ',
                // tslint:disable-next-line:max-line-length
                desc: 'Ощущения пациента во время проведения ультразвуковой эластографии такие же, как и при обычном УЗИ. Ни боли, ни дискомфорта пациенты не испытывают.',
                main_photo: {
                  url: '../../assets/img/big-slide-4.png'
                }
              },
              {
                id: 3,
                // tslint:disable-next-line:max-line-length
                title: 'Ощущения пациента во время проведения ультразвуковой эластографии такие же, как и при обычном УЗИ. Ни боли, ни дискомфорта пациенты не испытывают.',
                // tslint:disable-next-line:max-line-length
                desc: 'Ощущения пациента во время проведения ультразвуковой эластографии такие же, как и при обычном УЗИ. Ни боли, ни дискомфорта пациенты не испытывают.',
                main_photo: {
                  url: '../../assets/img/big-slide-3.png'
                },
              },
              {
                id: 4,
                // tslint:disable-next-line:max-line-length
                title: 'Ощущения пациента во время проведения ',
                // tslint:disable-next-line:max-line-length
                desc: 'Ощущения пациента во время проведения ультразвуковой эластографии такие же, как и при обычном УЗИ. Ни боли, ни дискомфорта пациенты не испытывают.',
                main_photo: {
                  url: '../../assets/img/big-slide-4.png'
                }
              }
            ],
            catalog:
              {
                id: 1,
                name: 'Клинический анализ крови',
                desc: 'Описание Клинический анализ крови'
              }
          },

        ],
        tags: {
          id: 1,
          name: 'Печень',
        },
      },
      {
        id: 2,
        name: 'Аллергические риниты',
        description: 'Аллергические риниты. Что такое геморрой? Такой вопрос возникает у людей, которые впервые испытали дискомфорт в заднем проходе. Геморрой — это увеличение кавернозных тел (сосудов), сопровождающееся их истончением, деформацией и формированием геморроидальных узлов. В народе часто говорят, что геморроидальный недуг — это варикозная болезнь сосудов анального прохода. Деструкция стенок кишечника может провоцировать анальные кровотечения и выпадение узлов из ануса.',
        anonce: 'Аллергические риниты. УЗИ эластография (соноэластография) – дополнительная технология, используемая во время традиционного УЗИ, которая даёт возможность оценивать эластичность тканей исследуемого органа.',
        bodyText: '<div class="paragraph">Аллергические риниты. Все ткани человека, в том числе и повреждённые тем или иным патологическим процессом, имеют определённую эластичность (жёсткость). Нераковые ткани (здоровые, доброкачественные опухоли, воспалительный процесс) имеют высокую эластичность. В отличии от них, ткани злокачественных новообразований значительно менее эластичны (более жёсткие, жёсткость некоторых из них в несколько раз выше нормы). Во время ультразвуковой эластографии, ультразвуковой луч, излучаемый и воспринимаемый специальным датчиком, словно врач, «ощупывает» ткани исследуемого органа и с помощью программы оценивает их эластичность (жёсткость), что невозможно при обычном УЗИ. После компьютерной обработки изображение передаётся на экран монитора.</div>\n' +
          '            <div class="paragraph">Соноэластографический метод помогает выполнить диагностику злокачественных образований молочной железы, органов мошонки, узловых образований щитовидной железы, поверхностно расположенных лимфатических узлов, мягких тканей и других органов.</div>\n' +
          '            <div class="my-4 my-md-5"><img src="../../assets/img/big-slide-3.png" alt=""/></div>\n' +
          '            <div class="paragraph">Соноэластографический метод помогает выполнить диагностику злокачественных образований молочной железы, органов мошонки, узловых образований щитовидной железы, поверхностно расположенных лимфатических узлов, мягких тканей и других органов.</div>\n' +
          '            <div class="paragraph">Ощущения пациента во время проведения ультразвуковой эластографии такие же, как и при обычном УЗИ. Ни боли, ни дискомфорта пациенты не испытывают.</div>',
        catalog: {
          id: 1,
          name: 'Клинический анализ крови'
        },
        directions: [
          {
            id: 20,
            name: 'Аллергические риниты. Диагностика',
            short_description: 'Проснувшись однажды утром после беспокойного сна, Грегор Замза обнаружил, что он у себя в постели превратился в страшное насекомое. Лежа на панцирнотвердой спине, он видел, стоило ему приподнять голову, свой коричневый, выпуклый, разделенный дугообразными чешуйками живот, на верхушке которого еле держалось готовое вот-вот окончательно сползти одеяло.',
            description: 'Полное описание Диагностика. Его многочисленные, убого тонкие по сравнению с остальным телом ножки беспомощно копошились у него перед глазами. «Что со мной случилось? » – подумал он. Это не было сном. Его комната, настоящая, разве что слишком маленькая, но обычная комната, мирно покоилась в своих четырех хорошо знакомых стенах.',
            main_photo: { // TODO добавить
              id: 1,
              url: '../../assets/img/direction-2.png',
            },
            subdirections: [
              {
                id: 21,
                name: 'Аллергические риниты. Рентген',
                main_photo: {
                  id: 1,
                  url: '../../assets/img/direction2-1.png',
                },
                short_description: 'Краткое описание Рентген',
                description: 'Полное описание Рентген',
                icon: null,
                units: [
                  {
                    id: 2,
                    name: 'Аллергические риниты. Поликлиника №1',
                    address: {
                      id: 1,
                      city: 'Красноярск',
                      street: 'Вавилова',
                      house: '28',
                      building: '',
                      ymapcfg: '../../assets/img/contacts-card-map.png',
                      postcode: '660025',
                      region: 'Красноярский край',
                      mailbox: '6545'
                    },
                    phones: [
                      {
                        id: 7,
                        number: '+7 (391) 274-31-64',
                        description: 'круглосуточно'
                      },
                      {
                        id: 8,
                        number: '+7 (391) 257-64-60',
                        description: 'с 9:00 до 17:00'
                      }
                    ],
                  },
                  {
                    id: 3,
                    name: 'Поликлиника №3',
                    address: {
                      id: 1,
                      city: 'Красноярск',
                      street: 'Коломенская',
                      house: '28',
                      building: '',
                      ymapcfg: '../../assets/img/contacts-card-map.png',
                      postcode: '660025',
                      region: 'Красноярский край',
                      mailbox: '6545'
                    },
                    phones: [
                      {
                        id: 7,
                        number: '+7 (391) 274-31-64',
                        description: 'круглосуточно'
                      },
                      {
                        id: 8,
                        number: '+7 (391) 257-64-60',
                        description: 'с 9:00 до 17:00'
                      }
                    ],
                  },
                ],
                services: [
                  {
                    id: 1,
                    name: 'УЗИ печеночного трансплантанта с дуплексным сканированием артерий и вен',
                    display_name: 'УЗИ печеночного трансплантанта с дуплексным сканированием артерий и вен',
                    price: '1320',
                    // tslint:disable-next-line:max-line-length
                    anons: 'Ультразвуковую диагностику смело можно назвать уникальным методом исследования. Она обладает такими свойствами как неинвазивность, возможность многократного проведения исследования без вреда для здоровья пациента, быстрота получения информации, отсутствие противопоказаний, исключение лучевой нагрузки.',
                    // tslint:disable-next-line:max-line-length
                    body: 'Проснувшись однажды утром после беспокойного сна, Грегор Замза обнаружил, что он у себя в постели превратился в страшное насекомое.',
                    // tslint:disable-next-line:max-line-length
                    memo: 'Вы должны подготовить себя к исследованию следующим образом: воздержаться от физических нагрузок, приема алкоголя и лекарств, изменений в питании в течение 24 часов до взятия крови. Вам не следует принимать пищу после ужина, лечь спать нужно в обычное для Вас время и встать не позднее, чем за час до взятия крови. Утром до сдачи анализа воздержитесь от курения и приема пищи. Взятие крови осуществляется до проведения диагностических или лечебных процедур: инъекций, общего массажа тела, эндоскопий, ЭКГ, рентгеновского обследования и т.д. Если Вы испытываете трудности с отменой лекарств, то обязательно сообщите об этом лечащему врачу. Очень важно, чтобы Вы точно соблюдали указанные рекомендации, так как только в этом случае будут получены ценные результаты исследования крови.',
                    // tslint:disable-next-line:max-line-length
                    indication: 'Анализ на свертываемость является одним из самых простых и доступных методов исследования. Пациенту нужно отказываться от приема пищи в течение двух часов перед исследованием. Результаты готовы, как правило, через несколько часов. Такое тестирование проводится в рамках комплексного обследования организма, а также при наличии кровотечений невыясненной природы и при диагностике или контроле эффективности лечения патологий костного мозга. Референсные значения зависят от возраста, а в детстве — еще и от пола.',
                    contraindic: '<ul>При некоторых заболеваниях проведение забора тромбоцитарной массы категорически запрещено: \n' +
                      '          <li>туберкулез;</li>\n' +
                      '          <li>вирус иммунодефицита;</li>\n' +
                      '          <li>раковые патологии;</li>\n' +
                      '          <li>гепатиты;</li>\n' +
                      '          <li>нарушения в работе сердца и сосудов;</li>\n' +
                      '          <li>после проведения оперативных вмешательств;</li>\n' +
                      '          <li>беременность;</li>\n' +
                      '          <li>алкоголизм;</li>\n' +
                      '          <li>недавнее введение прививки;</li>\n' +
                      '          <li>язва желудка или двенадцатиперстной кишки;</li>\n' +
                      '          <li>татуировки;</li>\n' +
                      '          <li>обширные стоматологические поражения.</li>\n' +
                      '        </ul>',
                    sale: '',
                    doctor: [ // TODO могут ли одну услугу оказывать несколько врачей?
                      {
                        id: 2,
                        lastName: 'Морозова',
                        firstName: 'Екатерина',
                        middleName: 'Владимировна',
                        specialization: 'ЛОР',
                        main_photo: {
                          id: 11,
                          url: '../../assets/img/doctors/doc-5.png'
                        }
                      }
                    ],
                    documents: [
                      {
                        id: 1,
                        title: 'Паспорт',
                        // tslint:disable-next-line:max-line-length
                        desc: 'Проснувшись однажды утром после беспокойного сна, Грегор Замза обнаружил, что он у себя в постели превратился в страшное насекомое.',
                        main_photo:
                          {
                            id: 1,
                            url: '../../assets/img/doc-img-1.png',
                          }
                      },
                      {
                        id: 2,
                        title: 'Медицинский полис',
                        // tslint:disable-next-line:max-line-length
                        desc: 'Проснувшись однажды утром после беспокойного сна, Грегор Замза обнаружил, что он у себя в постели превратился в страшное насекомое.',
                        main_photo:
                          {
                            id: 1,
                            url: '../../assets/img/doc-img-2.png',
                          }
                      },
                      {
                        id: 3,
                        title: 'Направление доктора',
                        // tslint:disable-next-line:max-line-length
                        desc: 'Проснувшись однажды утром после беспокойного сна, Грегор Замза обнаружил, что он у себя в постели превратился в страшное насекомое.',
                        main_photo:
                          {
                            id: 1,
                            url: '../../assets/img/doc-img-3.png',
                          }
                      }
                    ],
                    tags: [
                      {
                        id: 1,
                        name: 'Кровь',
                        desc: 'Описание тега'
                      },
                      {
                        id: 2,
                        name: 'Глюкоза',
                        desc: 'Описание тега'
                      },
                      {
                        id: 3,
                        name: 'Витамины',
                        desc: 'Описание тега'
                      }
                    ],
                    slider: [
                      {
                        id: 1,
                        // tslint:disable-next-line:max-line-length
                        title: 'Ощущения пациента во время проведения ультразвуковой эластографии такие же, как и при обычном УЗИ. Ни боли, ни дискомфорта пациенты не испытывают.',
                        // tslint:disable-next-line:max-line-length
                        desc: 'Ощущения пациента во время проведения ультразвуковой эластографии такие же, как и при обычном УЗИ. Ни боли, ни дискомфорта пациенты не испытывают.',
                        main_photo: {
                          url: '../../assets/img/big-slide-3.png'
                        },
                      },
                      {
                        id: 2,
                        // tslint:disable-next-line:max-line-length
                        title: 'Ощущения пациента во время проведения ',
                        // tslint:disable-next-line:max-line-length
                        desc: 'Ощущения пациента во время проведения ультразвуковой эластографии такие же, как и при обычном УЗИ. Ни боли, ни дискомфорта пациенты не испытывают.',
                        main_photo: {
                          url: '../../assets/img/big-slide-4.png'
                        }
                      },
                      {
                        id: 3,
                        // tslint:disable-next-line:max-line-length
                        title: 'Ощущения пациента во время проведения ультразвуковой эластографии такие же, как и при обычном УЗИ. Ни боли, ни дискомфорта пациенты не испытывают.',
                        // tslint:disable-next-line:max-line-length
                        desc: 'Ощущения пациента во время проведения ультразвуковой эластографии такие же, как и при обычном УЗИ. Ни боли, ни дискомфорта пациенты не испытывают.',
                        main_photo: {
                          url: '../../assets/img/big-slide-3.png'
                        },
                      },
                      {
                        id: 4,
                        // tslint:disable-next-line:max-line-length
                        title: 'Ощущения пациента во время проведения ',
                        // tslint:disable-next-line:max-line-length
                        desc: 'Ощущения пациента во время проведения ультразвуковой эластографии такие же, как и при обычном УЗИ. Ни боли, ни дискомфорта пациенты не испытывают.',
                        main_photo: {
                          url: '../../assets/img/big-slide-4.png'
                        }
                      }
                    ],
                    catalog:
                      {
                        id: 1,
                        name: 'Клинический анализ крови',
                        desc: 'Описание Клинический анализ крови'
                      }
                  },
                  {
                    id: 2,
                    // tslint:disable-next-line:max-line-length
                    name: 'Ультразвуковое исследование органов брюшной полости комплексное: печень, поджелудочная железа, желчный пузырь, селезенка при профилактическом осмотре',
                    display_name: 'УЗИ брюшной полости',
                    price: '820',
                    // tslint:disable-next-line:max-line-length
                    anons: 'Ультразвуковую диагностику смело можно назвать уникальным методом исследования. Она обладает такими свойствами как неинвазивность, возможность многократного проведения исследования без вреда для здоровья пациента, быстрота получения информации, отсутствие противопоказаний, исключение лучевой нагрузки.',
                    // tslint:disable-next-line:max-line-length
                    body: 'Проснувшись однажды утром после беспокойного сна, Грегор Замза обнаружил, что он у себя в постели превратился в страшное насекомое.',
                    // tslint:disable-next-line:max-line-length
                    memo: 'Вы должны подготовить себя к исследованию следующим образом: воздержаться от физических нагрузок, приема алкоголя и лекарств, изменений в питании в течение 24 часов до взятия крови. Вам не следует принимать пищу после ужина, лечь спать нужно в обычное для Вас время и встать не позднее, чем за час до взятия крови. Утром до сдачи анализа воздержитесь от курения и приема пищи. Взятие крови осуществляется до проведения диагностических или лечебных процедур: инъекций, общего массажа тела, эндоскопий, ЭКГ, рентгеновского обследования и т.д. Если Вы испытываете трудности с отменой лекарств, то обязательно сообщите об этом лечащему врачу. Очень важно, чтобы Вы точно соблюдали указанные рекомендации, так как только в этом случае будут получены ценные результаты исследования крови.',
                    // tslint:disable-next-line:max-line-length
                    indication: 'Анализ на свертываемость является одним из самых простых и доступных методов исследования. Пациенту нужно отказываться от приема пищи в течение двух часов перед исследованием. Результаты готовы, как правило, через несколько часов. Такое тестирование проводится в рамках комплексного обследования организма, а также при наличии кровотечений невыясненной природы и при диагностике или контроле эффективности лечения патологий костного мозга. Референсные значения зависят от возраста, а в детстве — еще и от пола.',
                    contraindic: '<ul>При некоторых заболеваниях проведение забора тромбоцитарной массы категорически запрещено: \n' +
                      '          <li>туберкулез;</li>\n' +
                      '          <li>вирус иммунодефицита;</li>\n' +
                      '          <li>раковые патологии;</li>\n' +
                      '          <li>гепатиты;</li>\n' +
                      '          <li>нарушения в работе сердца и сосудов;</li>\n' +
                      '          <li>после проведения оперативных вмешательств;</li>\n' +
                      '          <li>беременность;</li>\n' +
                      '          <li>алкоголизм;</li>\n' +
                      '          <li>недавнее введение прививки;</li>\n' +
                      '          <li>язва желудка или двенадцатиперстной кишки;</li>\n' +
                      '          <li>татуировки;</li>\n' +
                      '          <li>обширные стоматологические поражения.</li>\n' +
                      '        </ul>',
                    sale: '10',
                    doctor: [ // TODO могут ли одну услугу оказывать несколько врачей?
                      {
                        id: 1,
                        lastName: 'Замза',
                        firstName: 'Грегор',
                        middleName: '',
                        specialization: 'Уролог',
                        main_photo: {
                          id: 16,
                          url: '../../assets/img/doctors/doc-2.png'
                        }
                      }
                    ],
                    documents: [
                      {
                        id: 1,
                        title: 'Паспорт',
                        // tslint:disable-next-line:max-line-length
                        desc: 'Проснувшись однажды утром после беспокойного сна, Грегор Замза обнаружил, что он у себя в постели превратился в страшное насекомое.',
                        main_photo:
                          {
                            id: 1,
                            url: '../../assets/img/doc-img-1.png',
                          }
                      }
                    ],
                    tags: [
                      {
                        id: 1,
                        name: 'Кровь',
                        desc: 'Описание тега'
                      },
                      {
                        id: 4,
                        name: 'Железо',
                        desc: 'Описание тега'
                      },
                      {
                        id: 5,
                        name: 'Белки',
                        desc: 'Описание тега'
                      }
                    ],
                    slider: [
                      {
                        id: 1,
                        // tslint:disable-next-line:max-line-length
                        title: 'Ощущения пациента во время проведения ультразвуковой эластографии такие же, как и при обычном УЗИ. Ни боли, ни дискомфорта пациенты не испытывают.',
                        // tslint:disable-next-line:max-line-length
                        desc: 'Ощущения пациента во время проведения ультразвуковой эластографии такие же, как и при обычном УЗИ. Ни боли, ни дискомфорта пациенты не испытывают.',
                        main_photo: {
                          url: '../../assets/img/big-slide-3.png'
                        },
                      },
                      {
                        id: 2,
                        // tslint:disable-next-line:max-line-length
                        title: 'Ощущения пациента во время проведения ',
                        // tslint:disable-next-line:max-line-length
                        desc: 'Ощущения пациента во время проведения ультразвуковой эластографии такие же, как и при обычном УЗИ. Ни боли, ни дискомфорта пациенты не испытывают.',
                        main_photo: {
                          url: '../../assets/img/big-slide-4.png'
                        }
                      }
                    ],
                    catalog:
                      {
                        id: 1,
                        name: 'Клинический анализ крови',
                        desc: 'Описание Клинический анализ крови'
                      }
                  },
                  {
                    id: 3,
                    name: 'УЗИ печеночного трансплантанта с дуплексным сканированием артерий и вен',
                    display_name: 'УЗИ печеночного трансплантанта с дуплексным сканированием артерий и вен',
                    price: '1320',
                    // tslint:disable-next-line:max-line-length
                    anons: 'Ультразвуковую диагностику смело можно назвать уникальным методом исследования. Она обладает такими свойствами как неинвазивность, возможность многократного проведения исследования без вреда для здоровья пациента, быстрота получения информации, отсутствие противопоказаний, исключение лучевой нагрузки.',
                    // tslint:disable-next-line:max-line-length
                    body: 'Проснувшись однажды утром после беспокойного сна, Грегор Замза обнаружил, что он у себя в постели превратился в страшное насекомое.',
                    // tslint:disable-next-line:max-line-length
                    memo: 'Вы должны подготовить себя к исследованию следующим образом: воздержаться от физических нагрузок, приема алкоголя и лекарств, изменений в питании в течение 24 часов до взятия крови. Вам не следует принимать пищу после ужина, лечь спать нужно в обычное для Вас время и встать не позднее, чем за час до взятия крови. Утром до сдачи анализа воздержитесь от курения и приема пищи. Взятие крови осуществляется до проведения диагностических или лечебных процедур: инъекций, общего массажа тела, эндоскопий, ЭКГ, рентгеновского обследования и т.д. Если Вы испытываете трудности с отменой лекарств, то обязательно сообщите об этом лечащему врачу. Очень важно, чтобы Вы точно соблюдали указанные рекомендации, так как только в этом случае будут получены ценные результаты исследования крови.',
                    // tslint:disable-next-line:max-line-length
                    indication: 'Анализ на свертываемость является одним из самых простых и доступных методов исследования. Пациенту нужно отказываться от приема пищи в течение двух часов перед исследованием. Результаты готовы, как правило, через несколько часов. Такое тестирование проводится в рамках комплексного обследования организма, а также при наличии кровотечений невыясненной природы и при диагностике или контроле эффективности лечения патологий костного мозга. Референсные значения зависят от возраста, а в детстве — еще и от пола.',
                    contraindic: '<ul>При некоторых заболеваниях проведение забора тромбоцитарной массы категорически запрещено: \n' +
                      '          <li>туберкулез;</li>\n' +
                      '          <li>вирус иммунодефицита;</li>\n' +
                      '          <li>раковые патологии;</li>\n' +
                      '          <li>гепатиты;</li>\n' +
                      '          <li>нарушения в работе сердца и сосудов;</li>\n' +
                      '          <li>после проведения оперативных вмешательств;</li>\n' +
                      '          <li>беременность;</li>\n' +
                      '          <li>алкоголизм;</li>\n' +
                      '          <li>недавнее введение прививки;</li>\n' +
                      '          <li>язва желудка или двенадцатиперстной кишки;</li>\n' +
                      '          <li>татуировки;</li>\n' +
                      '          <li>обширные стоматологические поражения.</li>\n' +
                      '        </ul>',
                    sale: '20',
                    doctor: [ // TODO могут ли одну услугу оказывать несколько врачей?
                      {
                        id: 1,
                        lastName: 'Коновалов',
                        firstName: 'Геннадий',
                        middleName: 'Александрович',
                        specialization: 'Главный ревматолог',
                        main_photo: {
                          id: 1,
                          url: '../../assets/img/doctors/doc-3.png'
                        }
                      },
                      {
                        id: 2,
                        lastName: 'Морозова',
                        firstName: 'Екатерина',
                        middleName: 'Владимировна',
                        specialization: 'ЛОР',
                        main_photo: {
                          id: 11,
                          url: '../../assets/img/doctors/doc-5.png'
                        }
                      }
                    ],
                    documents: [
                      {
                        id: 1,
                        title: 'Паспорт',
                        // tslint:disable-next-line:max-line-length
                        desc: 'Проснувшись однажды утром после беспокойного сна, Грегор Замза обнаружил, что он у себя в постели превратился в страшное насекомое.',
                        main_photo:
                          {
                            id: 1,
                            url: '../../assets/img/doc-img-1.png',
                          }
                      },
                      {
                        id: 2,
                        title: 'Медицинский полис',
                        // tslint:disable-next-line:max-line-length
                        desc: 'Проснувшись однажды утром после беспокойного сна, Грегор Замза обнаружил, что он у себя в постели превратился в страшное насекомое.',
                        main_photo:
                          {
                            id: 1,
                            url: '../../assets/img/doc-img-2.png',
                          }
                      },
                      {
                        id: 3,
                        title: 'Направление доктора',
                        // tslint:disable-next-line:max-line-length
                        desc: 'Проснувшись однажды утром после беспокойного сна, Грегор Замза обнаружил, что он у себя в постели превратился в страшное насекомое.',
                        main_photo:
                          {
                            id: 1,
                            url: '../../assets/img/doc-img-3.png',
                          }
                      }
                    ],
                    tags: [
                      {
                        id: 1,
                        name: 'Кровь',
                        desc: 'Описание тега'
                      },
                      {
                        id: 2,
                        name: 'Глюкоза',
                        desc: 'Описание тега'
                      },
                      {
                        id: 3,
                        name: 'Витамины',
                        desc: 'Описание тега'
                      }
                    ],
                    catalog:
                      {
                        id: 1,
                        name: 'Клинический анализ крови',
                        desc: 'Описание Клинический анализ крови'
                      }
                  },
                  {
                    id: 4,
                    name: 'УЗИ печеночного трансплантанта с дуплексным сканированием артерий и вен',
                    display_name: 'УЗИ печеночного трансплантанта с дуплексным сканированием артерий и вен',
                    price: '1320',
                    // tslint:disable-next-line:max-line-length
                    anons: 'Ультразвуковую диагностику смело можно назвать уникальным методом исследования. Она обладает такими свойствами как неинвазивность, возможность многократного проведения исследования без вреда для здоровья пациента, быстрота получения информации, отсутствие противопоказаний, исключение лучевой нагрузки.',
                    // tslint:disable-next-line:max-line-length
                    body: 'Проснувшись однажды утром после беспокойного сна, Грегор Замза обнаружил, что он у себя в постели превратился в страшное насекомое.',
                    // tslint:disable-next-line:max-line-length
                    memo: 'Вы должны подготовить себя к исследованию следующим образом: воздержаться от физических нагрузок, приема алкоголя и лекарств, изменений в питании в течение 24 часов до взятия крови. Вам не следует принимать пищу после ужина, лечь спать нужно в обычное для Вас время и встать не позднее, чем за час до взятия крови. Утром до сдачи анализа воздержитесь от курения и приема пищи. Взятие крови осуществляется до проведения диагностических или лечебных процедур: инъекций, общего массажа тела, эндоскопий, ЭКГ, рентгеновского обследования и т.д. Если Вы испытываете трудности с отменой лекарств, то обязательно сообщите об этом лечащему врачу. Очень важно, чтобы Вы точно соблюдали указанные рекомендации, так как только в этом случае будут получены ценные результаты исследования крови.',
                    // tslint:disable-next-line:max-line-length
                    indication: 'Анализ на свертываемость является одним из самых простых и доступных методов исследования. Пациенту нужно отказываться от приема пищи в течение двух часов перед исследованием. Результаты готовы, как правило, через несколько часов. Такое тестирование проводится в рамках комплексного обследования организма, а также при наличии кровотечений невыясненной природы и при диагностике или контроле эффективности лечения патологий костного мозга. Референсные значения зависят от возраста, а в детстве — еще и от пола.',
                    contraindic: '<ul>При некоторых заболеваниях проведение забора тромбоцитарной массы категорически запрещено: \n' +
                      '          <li>туберкулез;</li>\n' +
                      '          <li>вирус иммунодефицита;</li>\n' +
                      '          <li>раковые патологии;</li>\n' +
                      '          <li>гепатиты;</li>\n' +
                      '          <li>нарушения в работе сердца и сосудов;</li>\n' +
                      '          <li>после проведения оперативных вмешательств;</li>\n' +
                      '          <li>беременность;</li>\n' +
                      '          <li>алкоголизм;</li>\n' +
                      '          <li>недавнее введение прививки;</li>\n' +
                      '          <li>язва желудка или двенадцатиперстной кишки;</li>\n' +
                      '          <li>татуировки;</li>\n' +
                      '          <li>обширные стоматологические поражения.</li>\n' +
                      '        </ul>',
                    sale: '',
                    documents: [
                      {
                        id: 1,
                        title: 'Паспорт',
                        // tslint:disable-next-line:max-line-length
                        desc: 'Проснувшись однажды утром после беспокойного сна, Грегор Замза обнаружил, что он у себя в постели превратился в страшное насекомое.',
                        main_photo:
                          {
                            id: 1,
                            url: '../../assets/img/doc-img-1.png',
                          }
                      }
                    ],
                    tags: [
                      {
                        id: 1,
                        name: 'Кровь',
                        desc: 'Описание тега'
                      },
                      {
                        id: 2,
                        name: 'Глюкоза',
                        desc: 'Описание тега'
                      },
                      {
                        id: 3,
                        name: 'Витамины',
                        desc: 'Описание тега'
                      }
                    ],
                    catalog:
                      {
                        id: 1,
                        name: 'Клинический анализ крови',
                        desc: 'Описание Клинический анализ крови'
                      }
                  },
                  {
                    id: 5,
                    name: 'УЗИ печеночного трансплантанта с дуплексным сканированием артерий и вен',
                    display_name: 'УЗИ печеночного трансплантанта с дуплексным сканированием артерий и вен',
                    price: '1320',
                    // tslint:disable-next-line:max-line-length
                    anons: 'Ультразвуковую диагностику смело можно назвать уникальным методом исследования. Она обладает такими свойствами как неинвазивность, возможность многократного проведения исследования без вреда для здоровья пациента, быстрота получения информации, отсутствие противопоказаний, исключение лучевой нагрузки.',
                    // tslint:disable-next-line:max-line-length
                    body: 'Проснувшись однажды утром после беспокойного сна, Грегор Замза обнаружил, что он у себя в постели превратился в страшное насекомое.',
                    // tslint:disable-next-line:max-line-length
                    memo: 'Вы должны подготовить себя к исследованию следующим образом: воздержаться от физических нагрузок, приема алкоголя и лекарств, изменений в питании в течение 24 часов до взятия крови. Вам не следует принимать пищу после ужина, лечь спать нужно в обычное для Вас время и встать не позднее, чем за час до взятия крови. Утром до сдачи анализа воздержитесь от курения и приема пищи. Взятие крови осуществляется до проведения диагностических или лечебных процедур: инъекций, общего массажа тела, эндоскопий, ЭКГ, рентгеновского обследования и т.д. Если Вы испытываете трудности с отменой лекарств, то обязательно сообщите об этом лечащему врачу. Очень важно, чтобы Вы точно соблюдали указанные рекомендации, так как только в этом случае будут получены ценные результаты исследования крови.',
                    // tslint:disable-next-line:max-line-length
                    indication: 'Анализ на свертываемость является одним из самых простых и доступных методов исследования. Пациенту нужно отказываться от приема пищи в течение двух часов перед исследованием. Результаты готовы, как правило, через несколько часов. Такое тестирование проводится в рамках комплексного обследования организма, а также при наличии кровотечений невыясненной природы и при диагностике или контроле эффективности лечения патологий костного мозга. Референсные значения зависят от возраста, а в детстве — еще и от пола.',
                    contraindic: '<ul>При некоторых заболеваниях проведение забора тромбоцитарной массы категорически запрещено: \n' +
                      '          <li>туберкулез;</li>\n' +
                      '          <li>вирус иммунодефицита;</li>\n' +
                      '          <li>раковые патологии;</li>\n' +
                      '          <li>гепатиты;</li>\n' +
                      '          <li>нарушения в работе сердца и сосудов;</li>\n' +
                      '          <li>после проведения оперативных вмешательств;</li>\n' +
                      '          <li>беременность;</li>\n' +
                      '          <li>алкоголизм;</li>\n' +
                      '          <li>недавнее введение прививки;</li>\n' +
                      '          <li>язва желудка или двенадцатиперстной кишки;</li>\n' +
                      '          <li>татуировки;</li>\n' +
                      '          <li>обширные стоматологические поражения.</li>\n' +
                      '        </ul>',
                    sale: '',
                    documents: [
                      {
                        id: 1,
                        title: 'Паспорт',
                        // tslint:disable-next-line:max-line-length
                        desc: 'Проснувшись однажды утром после беспокойного сна, Грегор Замза обнаружил, что он у себя в постели превратился в страшное насекомое.',
                        main_photo:
                          {
                            id: 1,
                            url: '../../assets/img/doc-img-1.png',
                          }
                      }
                    ],
                    tags: [
                      {
                        id: 1,
                        name: 'Кровь',
                        desc: 'Описание тега'
                      },
                      {
                        id: 2,
                        name: 'Глюкоза',
                        desc: 'Описание тега'
                      },
                      {
                        id: 3,
                        name: 'Витамины',
                        desc: 'Описание тега'
                      }
                    ],
                    catalog:
                      {
                        id: 1,
                        name: 'УЗИ - диагностические исследования',
                        desc: 'Описание Клинический анализ крови'
                      }
                  },
                  {
                    id: 6,
                    name: 'УЗИ печеночного трансплантанта с дуплексным сканированием артерий и вен',
                    display_name: 'УЗИ печеночного трансплантанта с дуплексным сканированием артерий и вен',
                    price: '1320',
                    // tslint:disable-next-line:max-line-length
                    anons: 'Ультразвуковую диагностику смело можно назвать уникальным методом исследования. Она обладает такими свойствами как неинвазивность, возможность многократного проведения исследования без вреда для здоровья пациента, быстрота получения информации, отсутствие противопоказаний, исключение лучевой нагрузки.',
                    // tslint:disable-next-line:max-line-length
                    body: 'Проснувшись однажды утром после беспокойного сна, Грегор Замза обнаружил, что он у себя в постели превратился в страшное насекомое.',
                    // tslint:disable-next-line:max-line-length
                    memo: 'Вы должны подготовить себя к исследованию следующим образом: воздержаться от физических нагрузок, приема алкоголя и лекарств, изменений в питании в течение 24 часов до взятия крови. Вам не следует принимать пищу после ужина, лечь спать нужно в обычное для Вас время и встать не позднее, чем за час до взятия крови. Утром до сдачи анализа воздержитесь от курения и приема пищи. Взятие крови осуществляется до проведения диагностических или лечебных процедур: инъекций, общего массажа тела, эндоскопий, ЭКГ, рентгеновского обследования и т.д. Если Вы испытываете трудности с отменой лекарств, то обязательно сообщите об этом лечащему врачу. Очень важно, чтобы Вы точно соблюдали указанные рекомендации, так как только в этом случае будут получены ценные результаты исследования крови.',
                    // tslint:disable-next-line:max-line-length
                    indication: 'Анализ на свертываемость является одним из самых простых и доступных методов исследования. Пациенту нужно отказываться от приема пищи в течение двух часов перед исследованием. Результаты готовы, как правило, через несколько часов. Такое тестирование проводится в рамках комплексного обследования организма, а также при наличии кровотечений невыясненной природы и при диагностике или контроле эффективности лечения патологий костного мозга. Референсные значения зависят от возраста, а в детстве — еще и от пола.',
                    contraindic: '<ul>При некоторых заболеваниях проведение забора тромбоцитарной массы категорически запрещено: \n' +
                      '          <li>туберкулез;</li>\n' +
                      '          <li>вирус иммунодефицита;</li>\n' +
                      '          <li>раковые патологии;</li>\n' +
                      '          <li>гепатиты;</li>\n' +
                      '          <li>нарушения в работе сердца и сосудов;</li>\n' +
                      '          <li>после проведения оперативных вмешательств;</li>\n' +
                      '          <li>беременность;</li>\n' +
                      '          <li>алкоголизм;</li>\n' +
                      '          <li>недавнее введение прививки;</li>\n' +
                      '          <li>язва желудка или двенадцатиперстной кишки;</li>\n' +
                      '          <li>татуировки;</li>\n' +
                      '          <li>обширные стоматологические поражения.</li>\n' +
                      '        </ul>',
                    sale: '20',
                    tags: [
                      {
                        id: 1,
                        name: 'Кровь',
                        desc: 'Описание тега'
                      },
                      {
                        id: 2,
                        name: 'Глюкоза',
                        desc: 'Описание тега'
                      },
                      {
                        id: 3,
                        name: 'Витамины',
                        desc: 'Описание тега'
                      }
                    ],
                    catalog:
                      {
                        id: 1,
                        name: 'Анализы крови',
                        desc: 'Описание Клинический анализ крови'
                      }
                  },
                  {
                    id: 7,
                    name: 'Анализ крови артерий и вен',
                    display_name: 'Анализ крови артерий и вен',
                    price: '1320',
                    // tslint:disable-next-line:max-line-length
                    anons: 'Ультразвуковую диагностику смело можно назвать уникальным методом исследования. Она обладает такими свойствами как неинвазивность, возможность многократного проведения исследования без вреда для здоровья пациента, быстрота получения информации, отсутствие противопоказаний, исключение лучевой нагрузки.',
                    // tslint:disable-next-line:max-line-length
                    body: 'Проснувшись однажды утром после беспокойного сна, Грегор Замза обнаружил.',
                    // tslint:disable-next-line:max-line-length
                    memo: 'Вы должны подготовить себя к исследованию следующим образом: воздержаться от физических нагрузок, приема алкоголя и лекарств, изменений в питании в течение 24 часов до взятия крови. Вам не следует принимать пищу после ужина, лечь спать нужно в обычное для Вас время и встать не позднее, чем за час до взятия крови. Утром до сдачи анализа воздержитесь от курения и приема пищи. Взятие крови осуществляется до проведения диагностических или лечебных процедур: инъекций, общего массажа тела, эндоскопий, ЭКГ, рентгеновского обследования и т.д. Если Вы испытываете трудности с отменой лекарств, то обязательно сообщите об этом лечащему врачу. Очень важно, чтобы Вы точно соблюдали указанные рекомендации, так как только в этом случае будут получены ценные результаты исследования крови.',
                    // tslint:disable-next-line:max-line-length
                    indication: 'Анализ на свертываемость является одним из самых простых и доступных методов исследования. Пациенту нужно отказываться от приема пищи в течение двух часов перед исследованием. Результаты готовы, как правило, через несколько часов. Такое тестирование проводится в рамках комплексного обследования организма, а также при наличии кровотечений невыясненной природы и при диагностике или контроле эффективности лечения патологий костного мозга. Референсные значения зависят от возраста, а в детстве — еще и от пола.',
                    contraindic: '<ul>При некоторых заболеваниях проведение забора тромбоцитарной массы категорически запрещено: \n' +
                      '          <li>туберкулез;</li>\n' +
                      '          <li>вирус иммунодефицита;</li>\n' +
                      '          <li>раковые патологии;</li>\n' +
                      '          <li>гепатиты;</li>\n' +
                      '          <li>нарушения в работе сердца и сосудов;</li>\n' +
                      '          <li>после проведения оперативных вмешательств;</li>\n' +
                      '          <li>беременность;</li>\n' +
                      '          <li>алкоголизм;</li>\n' +
                      '          <li>недавнее введение прививки;</li>\n' +
                      '          <li>язва желудка или двенадцатиперстной кишки;</li>\n' +
                      '          <li>татуировки;</li>\n' +
                      '          <li>обширные стоматологические поражения.</li>\n' +
                      '        </ul>',
                    sale: '',
                    documents: [
                      {
                        id: 1,
                        title: 'Паспорт',
                        // tslint:disable-next-line:max-line-length
                        desc: 'Проснувшись однажды утром после беспокойного сна, Грегор Замза обнаружил, что он у себя в постели превратился в страшное насекомое.',
                        main_photo:
                          {
                            id: 1,
                            url: '../../assets/img/doc-img-1.png',
                          }
                      }
                    ],
                    tags: [
                      {
                        id: 1,
                        name: 'Кровь',
                        desc: 'Описание тега'
                      },
                      {
                        id: 2,
                        name: 'Глюкоза',
                        desc: 'Описание тега'
                      },
                      {
                        id: 3,
                        name: 'Гормоны',
                        desc: 'Описание тега'
                      }
                    ],
                    catalog:
                      {
                        id: 1,
                        name: 'Анализы крови',
                        desc: 'Описание Клинический анализ крови'
                      }
                  }
                ],
              },
              {
                id: 24,
                name: 'Функциональная диагностика',
                main_photo: {
                  id: 1,
                  url: '../../assets/img/direction2-3.png',
                },
                short_description: 'Краткое описание Функциональная диагностика',
                description: 'Полное описание Функциональная диагностика',
                icon: null,
                units: [
                  {
                    id: 2,
                    name: 'Поликлиника №1',
                    address: {
                      id: 1,
                      city: 'Красноярск',
                      street: 'Вавилова',
                      house: '28',
                      building: '',
                      ymapcfg: '../../assets/img/contacts-card-map.png',
                      postcode: '660025',
                      region: 'Красноярский край',
                      mailbox: '6545'
                    },
                    phones: [
                      {
                        id: 7,
                        number: '+7 (391) 274-31-64',
                        description: 'круглосуточно'
                      },
                      {
                        id: 8,
                        number: '+7 (391) 257-64-60',
                        description: 'с 9:00 до 17:00'
                      }
                    ],
                  },
                  {
                    id: 3,
                    name: 'Поликлиника №3',
                    address: {
                      id: 1,
                      city: 'Красноярск',
                      street: 'Коломенская',
                      house: '28',
                      building: '',
                      ymapcfg: '../../assets/img/contacts-card-map.png',
                      postcode: '660025',
                      region: 'Красноярский край',
                      mailbox: '6545'
                    },
                    phones: [
                      {
                        id: 7,
                        number: '+7 (391) 274-31-64',
                        description: 'круглосуточно'
                      },
                      {
                        id: 8,
                        number: '+7 (391) 257-64-60',
                        description: 'с 9:00 до 17:00'
                      }
                    ],
                  },
                ],
                services: [
                  {
                    id: 5,
                    name: 'УЗИ печеночного трансплантанта с дуплексным сканированием артерий и вен',
                    display_name: 'УЗИ печеночного трансплантанта с дуплексным сканированием артерий и вен',
                    price: '1320',
                    // tslint:disable-next-line:max-line-length
                    anons: 'Ультразвуковую диагностику смело можно назвать уникальным методом исследования. Она обладает такими свойствами как неинвазивность, возможность многократного проведения исследования без вреда для здоровья пациента, быстрота получения информации, отсутствие противопоказаний, исключение лучевой нагрузки.',
                    // tslint:disable-next-line:max-line-length
                    body: 'Проснувшись однажды утром после беспокойного сна, Грегор Замза обнаружил, что он у себя в постели превратился в страшное насекомое.',
                    // tslint:disable-next-line:max-line-length
                    memo: 'Вы должны подготовить себя к исследованию следующим образом: воздержаться от физических нагрузок, приема алкоголя и лекарств, изменений в питании в течение 24 часов до взятия крови. Вам не следует принимать пищу после ужина, лечь спать нужно в обычное для Вас время и встать не позднее, чем за час до взятия крови. Утром до сдачи анализа воздержитесь от курения и приема пищи. Взятие крови осуществляется до проведения диагностических или лечебных процедур: инъекций, общего массажа тела, эндоскопий, ЭКГ, рентгеновского обследования и т.д. Если Вы испытываете трудности с отменой лекарств, то обязательно сообщите об этом лечащему врачу. Очень важно, чтобы Вы точно соблюдали указанные рекомендации, так как только в этом случае будут получены ценные результаты исследования крови.',
                    // tslint:disable-next-line:max-line-length
                    indication: 'Анализ на свертываемость является одним из самых простых и доступных методов исследования. Пациенту нужно отказываться от приема пищи в течение двух часов перед исследованием. Результаты готовы, как правило, через несколько часов. Такое тестирование проводится в рамках комплексного обследования организма, а также при наличии кровотечений невыясненной природы и при диагностике или контроле эффективности лечения патологий костного мозга. Референсные значения зависят от возраста, а в детстве — еще и от пола.',
                    contraindic: '<ul>При некоторых заболеваниях проведение забора тромбоцитарной массы категорически запрещено: \n' +
                      '          <li>туберкулез;</li>\n' +
                      '          <li>вирус иммунодефицита;</li>\n' +
                      '          <li>раковые патологии;</li>\n' +
                      '          <li>гепатиты;</li>\n' +
                      '          <li>нарушения в работе сердца и сосудов;</li>\n' +
                      '          <li>после проведения оперативных вмешательств;</li>\n' +
                      '          <li>беременность;</li>\n' +
                      '          <li>алкоголизм;</li>\n' +
                      '          <li>недавнее введение прививки;</li>\n' +
                      '          <li>язва желудка или двенадцатиперстной кишки;</li>\n' +
                      '          <li>татуировки;</li>\n' +
                      '          <li>обширные стоматологические поражения.</li>\n' +
                      '        </ul>',
                    sale: '',
                    documents: [
                      {
                        id: 1,
                        title: 'Паспорт',
                        // tslint:disable-next-line:max-line-length
                        desc: 'Проснувшись однажды утром после беспокойного сна, Грегор Замза обнаружил, что он у себя в постели превратился в страшное насекомое.',
                        main_photo:
                          {
                            id: 1,
                            url: '../../assets/img/doc-img-1.png',
                          }
                      }
                    ],
                    tags: [
                      {
                        id: 1,
                        name: 'Кровь',
                        desc: 'Описание тега'
                      },
                      {
                        id: 2,
                        name: 'Глюкоза',
                        desc: 'Описание тега'
                      },
                      {
                        id: 3,
                        name: 'Витамины',
                        desc: 'Описание тега'
                      }
                    ],
                    catalog:
                      {
                        id: 1,
                        name: 'УЗИ - диагностические исследования',
                        desc: 'Описание Клинический анализ крови'
                      }
                  },
                  {
                    id: 6,
                    name: 'УЗИ печеночного трансплантанта с дуплексным сканированием артерий и вен',
                    display_name: 'УЗИ печеночного трансплантанта с дуплексным сканированием артерий и вен',
                    price: '1320',
                    // tslint:disable-next-line:max-line-length
                    anons: 'Ультразвуковую диагностику смело можно назвать уникальным методом исследования. Она обладает такими свойствами как неинвазивность, возможность многократного проведения исследования без вреда для здоровья пациента, быстрота получения информации, отсутствие противопоказаний, исключение лучевой нагрузки.',
                    // tslint:disable-next-line:max-line-length
                    body: 'Проснувшись однажды утром после беспокойного сна, Грегор Замза обнаружил, что он у себя в постели превратился в страшное насекомое.',
                    // tslint:disable-next-line:max-line-length
                    memo: 'Вы должны подготовить себя к исследованию следующим образом: воздержаться от физических нагрузок, приема алкоголя и лекарств, изменений в питании в течение 24 часов до взятия крови. Вам не следует принимать пищу после ужина, лечь спать нужно в обычное для Вас время и встать не позднее, чем за час до взятия крови. Утром до сдачи анализа воздержитесь от курения и приема пищи. Взятие крови осуществляется до проведения диагностических или лечебных процедур: инъекций, общего массажа тела, эндоскопий, ЭКГ, рентгеновского обследования и т.д. Если Вы испытываете трудности с отменой лекарств, то обязательно сообщите об этом лечащему врачу. Очень важно, чтобы Вы точно соблюдали указанные рекомендации, так как только в этом случае будут получены ценные результаты исследования крови.',
                    // tslint:disable-next-line:max-line-length
                    indication: 'Анализ на свертываемость является одним из самых простых и доступных методов исследования. Пациенту нужно отказываться от приема пищи в течение двух часов перед исследованием. Результаты готовы, как правило, через несколько часов. Такое тестирование проводится в рамках комплексного обследования организма, а также при наличии кровотечений невыясненной природы и при диагностике или контроле эффективности лечения патологий костного мозга. Референсные значения зависят от возраста, а в детстве — еще и от пола.',
                    contraindic: '<ul>При некоторых заболеваниях проведение забора тромбоцитарной массы категорически запрещено: \n' +
                      '          <li>туберкулез;</li>\n' +
                      '          <li>вирус иммунодефицита;</li>\n' +
                      '          <li>раковые патологии;</li>\n' +
                      '          <li>гепатиты;</li>\n' +
                      '          <li>нарушения в работе сердца и сосудов;</li>\n' +
                      '          <li>после проведения оперативных вмешательств;</li>\n' +
                      '          <li>беременность;</li>\n' +
                      '          <li>алкоголизм;</li>\n' +
                      '          <li>недавнее введение прививки;</li>\n' +
                      '          <li>язва желудка или двенадцатиперстной кишки;</li>\n' +
                      '          <li>татуировки;</li>\n' +
                      '          <li>обширные стоматологические поражения.</li>\n' +
                      '        </ul>',
                    sale: '20',
                    tags: [
                      {
                        id: 1,
                        name: 'Кровь',
                        desc: 'Описание тега'
                      },
                      {
                        id: 2,
                        name: 'Глюкоза',
                        desc: 'Описание тега'
                      },
                      {
                        id: 3,
                        name: 'Витамины',
                        desc: 'Описание тега'
                      }
                    ],
                    catalog:
                      {
                        id: 1,
                        name: 'Анализы крови',
                        desc: 'Описание Клинический анализ крови'
                      }
                  },
                  {
                    id: 7,
                    name: 'Анализ крови артерий и вен',
                    display_name: 'Анализ крови артерий и вен',
                    price: '1320',
                    // tslint:disable-next-line:max-line-length
                    anons: 'Ультразвуковую диагностику смело можно назвать уникальным методом исследования. Она обладает такими свойствами как неинвазивность, возможность многократного проведения исследования без вреда для здоровья пациента, быстрота получения информации, отсутствие противопоказаний, исключение лучевой нагрузки.',
                    // tslint:disable-next-line:max-line-length
                    body: 'Проснувшись однажды утром после беспокойного сна, Грегор Замза обнаружил.',
                    // tslint:disable-next-line:max-line-length
                    memo: 'Вы должны подготовить себя к исследованию следующим образом: воздержаться от физических нагрузок, приема алкоголя и лекарств, изменений в питании в течение 24 часов до взятия крови. Вам не следует принимать пищу после ужина, лечь спать нужно в обычное для Вас время и встать не позднее, чем за час до взятия крови. Утром до сдачи анализа воздержитесь от курения и приема пищи. Взятие крови осуществляется до проведения диагностических или лечебных процедур: инъекций, общего массажа тела, эндоскопий, ЭКГ, рентгеновского обследования и т.д. Если Вы испытываете трудности с отменой лекарств, то обязательно сообщите об этом лечащему врачу. Очень важно, чтобы Вы точно соблюдали указанные рекомендации, так как только в этом случае будут получены ценные результаты исследования крови.',
                    // tslint:disable-next-line:max-line-length
                    indication: 'Анализ на свертываемость является одним из самых простых и доступных методов исследования. Пациенту нужно отказываться от приема пищи в течение двух часов перед исследованием. Результаты готовы, как правило, через несколько часов. Такое тестирование проводится в рамках комплексного обследования организма, а также при наличии кровотечений невыясненной природы и при диагностике или контроле эффективности лечения патологий костного мозга. Референсные значения зависят от возраста, а в детстве — еще и от пола.',
                    contraindic: '<ul>При некоторых заболеваниях проведение забора тромбоцитарной массы категорически запрещено: \n' +
                      '          <li>туберкулез;</li>\n' +
                      '          <li>вирус иммунодефицита;</li>\n' +
                      '          <li>раковые патологии;</li>\n' +
                      '          <li>гепатиты;</li>\n' +
                      '          <li>нарушения в работе сердца и сосудов;</li>\n' +
                      '          <li>после проведения оперативных вмешательств;</li>\n' +
                      '          <li>беременность;</li>\n' +
                      '          <li>алкоголизм;</li>\n' +
                      '          <li>недавнее введение прививки;</li>\n' +
                      '          <li>язва желудка или двенадцатиперстной кишки;</li>\n' +
                      '          <li>татуировки;</li>\n' +
                      '          <li>обширные стоматологические поражения.</li>\n' +
                      '        </ul>',
                    sale: '',
                    documents: [
                      {
                        id: 1,
                        title: 'Паспорт',
                        // tslint:disable-next-line:max-line-length
                        desc: 'Проснувшись однажды утром после беспокойного сна, Грегор Замза обнаружил, что он у себя в постели превратился в страшное насекомое.',
                        main_photo:
                          {
                            id: 1,
                            url: '../../assets/img/doc-img-1.png',
                          }
                      }
                    ],
                    tags: [
                      {
                        id: 1,
                        name: 'Кровь',
                        desc: 'Описание тега'
                      },
                      {
                        id: 2,
                        name: 'Глюкоза',
                        desc: 'Описание тега'
                      },
                      {
                        id: 3,
                        name: 'Гормоны',
                        desc: 'Описание тега'
                      }
                    ],
                    catalog:
                      {
                        id: 1,
                        name: 'Анализы крови',
                        desc: 'Описание Клинический анализ крови'
                      }
                  }
                ],
              },
              {
                id: 22,
                name: 'УЗИ',
                main_photo: {
                  id: 1,
                  url: '../../assets/img/direction2-2.png',
                },
                short_description: 'Краткое описание УЗИ',
                description: 'Полное описание УЗИ',
                icon: null,
                units: [
                  {
                    id: 3,
                    name: 'Поликлиника №3',
                    address: {
                      id: 1,
                      city: 'Красноярск',
                      street: 'Коломенская',
                      house: '28',
                      building: '',
                      ymapcfg: '../../assets/img/contacts-card-map.png',
                      postcode: '660025',
                      region: 'Красноярский край',
                      mailbox: '6545'
                    },
                    phones: [
                      {
                        id: 7,
                        number: '+7 (391) 274-31-64',
                        description: 'круглосуточно'
                      },
                      {
                        id: 8,
                        number: '+7 (391) 257-64-60',
                        description: 'с 9:00 до 17:00'
                      }
                    ],
                  },
                ],
                services: [
                  {
                    id: 1,
                    name: 'УЗИ печеночного трансплантанта с дуплексным сканированием артерий и вен',
                    display_name: 'УЗИ печеночного трансплантанта с дуплексным сканированием артерий и вен',
                    price: '1320',
                    // tslint:disable-next-line:max-line-length
                    anons: 'Ультразвуковую диагностику смело можно назвать уникальным методом исследования. Она обладает такими свойствами как неинвазивность, возможность многократного проведения исследования без вреда для здоровья пациента, быстрота получения информации, отсутствие противопоказаний, исключение лучевой нагрузки.',
                    // tslint:disable-next-line:max-line-length
                    body: 'Проснувшись однажды утром после беспокойного сна, Грегор Замза обнаружил, что он у себя в постели превратился в страшное насекомое.',
                    // tslint:disable-next-line:max-line-length
                    memo: 'Вы должны подготовить себя к исследованию следующим образом: воздержаться от физических нагрузок, приема алкоголя и лекарств, изменений в питании в течение 24 часов до взятия крови. Вам не следует принимать пищу после ужина, лечь спать нужно в обычное для Вас время и встать не позднее, чем за час до взятия крови. Утром до сдачи анализа воздержитесь от курения и приема пищи. Взятие крови осуществляется до проведения диагностических или лечебных процедур: инъекций, общего массажа тела, эндоскопий, ЭКГ, рентгеновского обследования и т.д. Если Вы испытываете трудности с отменой лекарств, то обязательно сообщите об этом лечащему врачу. Очень важно, чтобы Вы точно соблюдали указанные рекомендации, так как только в этом случае будут получены ценные результаты исследования крови.',
                    // tslint:disable-next-line:max-line-length
                    indication: 'Анализ на свертываемость является одним из самых простых и доступных методов исследования. Пациенту нужно отказываться от приема пищи в течение двух часов перед исследованием. Результаты готовы, как правило, через несколько часов. Такое тестирование проводится в рамках комплексного обследования организма, а также при наличии кровотечений невыясненной природы и при диагностике или контроле эффективности лечения патологий костного мозга. Референсные значения зависят от возраста, а в детстве — еще и от пола.',
                    contraindic: '<ul>При некоторых заболеваниях проведение забора тромбоцитарной массы категорически запрещено: \n' +
                      '          <li>туберкулез;</li>\n' +
                      '          <li>вирус иммунодефицита;</li>\n' +
                      '          <li>раковые патологии;</li>\n' +
                      '          <li>гепатиты;</li>\n' +
                      '          <li>нарушения в работе сердца и сосудов;</li>\n' +
                      '          <li>после проведения оперативных вмешательств;</li>\n' +
                      '          <li>беременность;</li>\n' +
                      '          <li>алкоголизм;</li>\n' +
                      '          <li>недавнее введение прививки;</li>\n' +
                      '          <li>язва желудка или двенадцатиперстной кишки;</li>\n' +
                      '          <li>татуировки;</li>\n' +
                      '          <li>обширные стоматологические поражения.</li>\n' +
                      '        </ul>',
                    sale: '',
                    doctor: [ // TODO могут ли одну услугу оказывать несколько врачей?
                      {
                        id: 2,
                        lastName: 'Морозова',
                        firstName: 'Екатерина',
                        middleName: 'Владимировна',
                        specialization: 'ЛОР',
                        main_photo: {
                          id: 11,
                          url: '../../assets/img/doctors/doc-5.png'
                        }
                      }
                    ],
                    documents: [
                      {
                        id: 1,
                        title: 'Паспорт',
                        // tslint:disable-next-line:max-line-length
                        desc: 'Проснувшись однажды утром после беспокойного сна, Грегор Замза обнаружил, что он у себя в постели превратился в страшное насекомое.',
                        main_photo:
                          {
                            id: 1,
                            url: '../../assets/img/doc-img-1.png',
                          }
                      },
                      {
                        id: 2,
                        title: 'Медицинский полис',
                        // tslint:disable-next-line:max-line-length
                        desc: 'Проснувшись однажды утром после беспокойного сна, Грегор Замза обнаружил, что он у себя в постели превратился в страшное насекомое.',
                        main_photo:
                          {
                            id: 1,
                            url: '../../assets/img/doc-img-2.png',
                          }
                      },
                      {
                        id: 3,
                        title: 'Направление доктора',
                        // tslint:disable-next-line:max-line-length
                        desc: 'Проснувшись однажды утром после беспокойного сна, Грегор Замза обнаружил, что он у себя в постели превратился в страшное насекомое.',
                        main_photo:
                          {
                            id: 1,
                            url: '../../assets/img/doc-img-3.png',
                          }
                      }
                    ],
                    tags: [
                      {
                        id: 1,
                        name: 'Кровь',
                        desc: 'Описание тега'
                      },
                      {
                        id: 2,
                        name: 'Глюкоза',
                        desc: 'Описание тега'
                      },
                      {
                        id: 3,
                        name: 'Витамины',
                        desc: 'Описание тега'
                      }
                    ],
                    slider: [
                      {
                        id: 1,
                        // tslint:disable-next-line:max-line-length
                        title: 'Ощущения пациента во время проведения ультразвуковой эластографии такие же, как и при обычном УЗИ. Ни боли, ни дискомфорта пациенты не испытывают.',
                        // tslint:disable-next-line:max-line-length
                        desc: 'Ощущения пациента во время проведения ультразвуковой эластографии такие же, как и при обычном УЗИ. Ни боли, ни дискомфорта пациенты не испытывают.',
                        main_photo: {
                          url: '../../assets/img/big-slide-3.png'
                        },
                      },
                      {
                        id: 2,
                        // tslint:disable-next-line:max-line-length
                        title: 'Ощущения пациента во время проведения ',
                        // tslint:disable-next-line:max-line-length
                        desc: 'Ощущения пациента во время проведения ультразвуковой эластографии такие же, как и при обычном УЗИ. Ни боли, ни дискомфорта пациенты не испытывают.',
                        main_photo: {
                          url: '../../assets/img/big-slide-4.png'
                        }
                      },
                      {
                        id: 3,
                        // tslint:disable-next-line:max-line-length
                        title: 'Ощущения пациента во время проведения ультразвуковой эластографии такие же, как и при обычном УЗИ. Ни боли, ни дискомфорта пациенты не испытывают.',
                        // tslint:disable-next-line:max-line-length
                        desc: 'Ощущения пациента во время проведения ультразвуковой эластографии такие же, как и при обычном УЗИ. Ни боли, ни дискомфорта пациенты не испытывают.',
                        main_photo: {
                          url: '../../assets/img/big-slide-3.png'
                        },
                      },
                      {
                        id: 4,
                        // tslint:disable-next-line:max-line-length
                        title: 'Ощущения пациента во время проведения ',
                        // tslint:disable-next-line:max-line-length
                        desc: 'Ощущения пациента во время проведения ультразвуковой эластографии такие же, как и при обычном УЗИ. Ни боли, ни дискомфорта пациенты не испытывают.',
                        main_photo: {
                          url: '../../assets/img/big-slide-4.png'
                        }
                      }
                    ],
                    catalog:
                      {
                        id: 1,
                        name: 'Клинический анализ крови',
                        desc: 'Описание Клинический анализ крови'
                      }
                  },
                  {
                    id: 2,
                    // tslint:disable-next-line:max-line-length
                    name: 'Ультразвуковое исследование органов брюшной полости комплексное: печень, поджелудочная железа, желчный пузырь, селезенка при профилактическом осмотре',
                    display_name: 'УЗИ брюшной полости',
                    price: '820',
                    // tslint:disable-next-line:max-line-length
                    anons: 'Ультразвуковую диагностику смело можно назвать уникальным методом исследования. Она обладает такими свойствами как неинвазивность, возможность многократного проведения исследования без вреда для здоровья пациента, быстрота получения информации, отсутствие противопоказаний, исключение лучевой нагрузки.',
                    // tslint:disable-next-line:max-line-length
                    body: 'Проснувшись однажды утром после беспокойного сна, Грегор Замза обнаружил, что он у себя в постели превратился в страшное насекомое.',
                    // tslint:disable-next-line:max-line-length
                    memo: 'Вы должны подготовить себя к исследованию следующим образом: воздержаться от физических нагрузок, приема алкоголя и лекарств, изменений в питании в течение 24 часов до взятия крови. Вам не следует принимать пищу после ужина, лечь спать нужно в обычное для Вас время и встать не позднее, чем за час до взятия крови. Утром до сдачи анализа воздержитесь от курения и приема пищи. Взятие крови осуществляется до проведения диагностических или лечебных процедур: инъекций, общего массажа тела, эндоскопий, ЭКГ, рентгеновского обследования и т.д. Если Вы испытываете трудности с отменой лекарств, то обязательно сообщите об этом лечащему врачу. Очень важно, чтобы Вы точно соблюдали указанные рекомендации, так как только в этом случае будут получены ценные результаты исследования крови.',
                    // tslint:disable-next-line:max-line-length
                    indication: 'Анализ на свертываемость является одним из самых простых и доступных методов исследования. Пациенту нужно отказываться от приема пищи в течение двух часов перед исследованием. Результаты готовы, как правило, через несколько часов. Такое тестирование проводится в рамках комплексного обследования организма, а также при наличии кровотечений невыясненной природы и при диагностике или контроле эффективности лечения патологий костного мозга. Референсные значения зависят от возраста, а в детстве — еще и от пола.',
                    contraindic: '<ul>При некоторых заболеваниях проведение забора тромбоцитарной массы категорически запрещено: \n' +
                      '          <li>туберкулез;</li>\n' +
                      '          <li>вирус иммунодефицита;</li>\n' +
                      '          <li>раковые патологии;</li>\n' +
                      '          <li>гепатиты;</li>\n' +
                      '          <li>нарушения в работе сердца и сосудов;</li>\n' +
                      '          <li>после проведения оперативных вмешательств;</li>\n' +
                      '          <li>беременность;</li>\n' +
                      '          <li>алкоголизм;</li>\n' +
                      '          <li>недавнее введение прививки;</li>\n' +
                      '          <li>язва желудка или двенадцатиперстной кишки;</li>\n' +
                      '          <li>татуировки;</li>\n' +
                      '          <li>обширные стоматологические поражения.</li>\n' +
                      '        </ul>',
                    sale: '10',
                    doctor: [ // TODO могут ли одну услугу оказывать несколько врачей?
                      {
                        id: 1,
                        lastName: 'Замза',
                        firstName: 'Грегор',
                        middleName: '',
                        specialization: 'Уролог',
                        main_photo: {
                          id: 16,
                          url: '../../assets/img/doctors/doc-2.png'
                        }
                      }
                    ],
                    documents: [
                      {
                        id: 1,
                        title: 'Паспорт',
                        // tslint:disable-next-line:max-line-length
                        desc: 'Проснувшись однажды утром после беспокойного сна, Грегор Замза обнаружил, что он у себя в постели превратился в страшное насекомое.',
                        main_photo:
                          {
                            id: 1,
                            url: '../../assets/img/doc-img-1.png',
                          }
                      }
                    ],
                    tags: [
                      {
                        id: 1,
                        name: 'Кровь',
                        desc: 'Описание тега'
                      },
                      {
                        id: 4,
                        name: 'Железо',
                        desc: 'Описание тега'
                      },
                      {
                        id: 5,
                        name: 'Белки',
                        desc: 'Описание тега'
                      }
                    ],
                    slider: [
                      {
                        id: 1,
                        // tslint:disable-next-line:max-line-length
                        title: 'Ощущения пациента во время проведения ультразвуковой эластографии такие же, как и при обычном УЗИ. Ни боли, ни дискомфорта пациенты не испытывают.',
                        // tslint:disable-next-line:max-line-length
                        desc: 'Ощущения пациента во время проведения ультразвуковой эластографии такие же, как и при обычном УЗИ. Ни боли, ни дискомфорта пациенты не испытывают.',
                        main_photo: {
                          url: '../../assets/img/big-slide-3.png'
                        },
                      },
                      {
                        id: 2,
                        // tslint:disable-next-line:max-line-length
                        title: 'Ощущения пациента во время проведения ',
                        // tslint:disable-next-line:max-line-length
                        desc: 'Ощущения пациента во время проведения ультразвуковой эластографии такие же, как и при обычном УЗИ. Ни боли, ни дискомфорта пациенты не испытывают.',
                        main_photo: {
                          url: '../../assets/img/big-slide-4.png'
                        }
                      }
                    ],
                    catalog:
                      {
                        id: 1,
                        name: 'Клинический анализ крови',
                        desc: 'Описание Клинический анализ крови'
                      }
                  },
                  {
                    id: 3,
                    name: 'УЗИ печеночного трансплантанта с дуплексным сканированием артерий и вен',
                    display_name: 'УЗИ печеночного трансплантанта с дуплексным сканированием артерий и вен',
                    price: '1320',
                    // tslint:disable-next-line:max-line-length
                    anons: 'Ультразвуковую диагностику смело можно назвать уникальным методом исследования. Она обладает такими свойствами как неинвазивность, возможность многократного проведения исследования без вреда для здоровья пациента, быстрота получения информации, отсутствие противопоказаний, исключение лучевой нагрузки.',
                    // tslint:disable-next-line:max-line-length
                    body: 'Проснувшись однажды утром после беспокойного сна, Грегор Замза обнаружил, что он у себя в постели превратился в страшное насекомое.',
                    // tslint:disable-next-line:max-line-length
                    memo: 'Вы должны подготовить себя к исследованию следующим образом: воздержаться от физических нагрузок, приема алкоголя и лекарств, изменений в питании в течение 24 часов до взятия крови. Вам не следует принимать пищу после ужина, лечь спать нужно в обычное для Вас время и встать не позднее, чем за час до взятия крови. Утром до сдачи анализа воздержитесь от курения и приема пищи. Взятие крови осуществляется до проведения диагностических или лечебных процедур: инъекций, общего массажа тела, эндоскопий, ЭКГ, рентгеновского обследования и т.д. Если Вы испытываете трудности с отменой лекарств, то обязательно сообщите об этом лечащему врачу. Очень важно, чтобы Вы точно соблюдали указанные рекомендации, так как только в этом случае будут получены ценные результаты исследования крови.',
                    // tslint:disable-next-line:max-line-length
                    indication: 'Анализ на свертываемость является одним из самых простых и доступных методов исследования. Пациенту нужно отказываться от приема пищи в течение двух часов перед исследованием. Результаты готовы, как правило, через несколько часов. Такое тестирование проводится в рамках комплексного обследования организма, а также при наличии кровотечений невыясненной природы и при диагностике или контроле эффективности лечения патологий костного мозга. Референсные значения зависят от возраста, а в детстве — еще и от пола.',
                    contraindic: '<ul>При некоторых заболеваниях проведение забора тромбоцитарной массы категорически запрещено: \n' +
                      '          <li>туберкулез;</li>\n' +
                      '          <li>вирус иммунодефицита;</li>\n' +
                      '          <li>раковые патологии;</li>\n' +
                      '          <li>гепатиты;</li>\n' +
                      '          <li>нарушения в работе сердца и сосудов;</li>\n' +
                      '          <li>после проведения оперативных вмешательств;</li>\n' +
                      '          <li>беременность;</li>\n' +
                      '          <li>алкоголизм;</li>\n' +
                      '          <li>недавнее введение прививки;</li>\n' +
                      '          <li>язва желудка или двенадцатиперстной кишки;</li>\n' +
                      '          <li>татуировки;</li>\n' +
                      '          <li>обширные стоматологические поражения.</li>\n' +
                      '        </ul>',
                    sale: '20',
                    doctor: [ // TODO могут ли одну услугу оказывать несколько врачей?
                      {
                        id: 1,
                        lastName: 'Коновалов',
                        firstName: 'Геннадий',
                        middleName: 'Александрович',
                        specialization: 'Главный ревматолог',
                        main_photo: {
                          id: 1,
                          url: '../../assets/img/doctors/doc-3.png'
                        }
                      },
                      {
                        id: 2,
                        lastName: 'Морозова',
                        firstName: 'Екатерина',
                        middleName: 'Владимировна',
                        specialization: 'ЛОР',
                        main_photo: {
                          id: 11,
                          url: '../../assets/img/doctors/doc-5.png'
                        }
                      }
                    ],
                    documents: [
                      {
                        id: 1,
                        title: 'Паспорт',
                        // tslint:disable-next-line:max-line-length
                        desc: 'Проснувшись однажды утром после беспокойного сна, Грегор Замза обнаружил, что он у себя в постели превратился в страшное насекомое.',
                        main_photo:
                          {
                            id: 1,
                            url: '../../assets/img/doc-img-1.png',
                          }
                      },
                      {
                        id: 2,
                        title: 'Медицинский полис',
                        // tslint:disable-next-line:max-line-length
                        desc: 'Проснувшись однажды утром после беспокойного сна, Грегор Замза обнаружил, что он у себя в постели превратился в страшное насекомое.',
                        main_photo:
                          {
                            id: 1,
                            url: '../../assets/img/doc-img-2.png',
                          }
                      },
                      {
                        id: 3,
                        title: 'Направление доктора',
                        // tslint:disable-next-line:max-line-length
                        desc: 'Проснувшись однажды утром после беспокойного сна, Грегор Замза обнаружил, что он у себя в постели превратился в страшное насекомое.',
                        main_photo:
                          {
                            id: 1,
                            url: '../../assets/img/doc-img-3.png',
                          }
                      }
                    ],
                    tags: [
                      {
                        id: 1,
                        name: 'Кровь',
                        desc: 'Описание тега'
                      },
                      {
                        id: 2,
                        name: 'Глюкоза',
                        desc: 'Описание тега'
                      },
                      {
                        id: 3,
                        name: 'Витамины',
                        desc: 'Описание тега'
                      }
                    ],
                    catalog:
                      {
                        id: 1,
                        name: 'Клинический анализ крови',
                        desc: 'Описание Клинический анализ крови'
                      }
                  },
                  {
                    id: 6,
                    name: 'УЗИ печеночного трансплантанта с дуплексным сканированием артерий и вен',
                    display_name: 'УЗИ печеночного трансплантанта с дуплексным сканированием артерий и вен',
                    price: '1320',
                    // tslint:disable-next-line:max-line-length
                    anons: 'Ультразвуковую диагностику смело можно назвать уникальным методом исследования. Она обладает такими свойствами как неинвазивность, возможность многократного проведения исследования без вреда для здоровья пациента, быстрота получения информации, отсутствие противопоказаний, исключение лучевой нагрузки.',
                    // tslint:disable-next-line:max-line-length
                    body: 'Проснувшись однажды утром после беспокойного сна, Грегор Замза обнаружил, что он у себя в постели превратился в страшное насекомое.',
                    // tslint:disable-next-line:max-line-length
                    memo: 'Вы должны подготовить себя к исследованию следующим образом: воздержаться от физических нагрузок, приема алкоголя и лекарств, изменений в питании в течение 24 часов до взятия крови. Вам не следует принимать пищу после ужина, лечь спать нужно в обычное для Вас время и встать не позднее, чем за час до взятия крови. Утром до сдачи анализа воздержитесь от курения и приема пищи. Взятие крови осуществляется до проведения диагностических или лечебных процедур: инъекций, общего массажа тела, эндоскопий, ЭКГ, рентгеновского обследования и т.д. Если Вы испытываете трудности с отменой лекарств, то обязательно сообщите об этом лечащему врачу. Очень важно, чтобы Вы точно соблюдали указанные рекомендации, так как только в этом случае будут получены ценные результаты исследования крови.',
                    // tslint:disable-next-line:max-line-length
                    indication: 'Анализ на свертываемость является одним из самых простых и доступных методов исследования. Пациенту нужно отказываться от приема пищи в течение двух часов перед исследованием. Результаты готовы, как правило, через несколько часов. Такое тестирование проводится в рамках комплексного обследования организма, а также при наличии кровотечений невыясненной природы и при диагностике или контроле эффективности лечения патологий костного мозга. Референсные значения зависят от возраста, а в детстве — еще и от пола.',
                    contraindic: '<ul>При некоторых заболеваниях проведение забора тромбоцитарной массы категорически запрещено: \n' +
                      '          <li>туберкулез;</li>\n' +
                      '          <li>вирус иммунодефицита;</li>\n' +
                      '          <li>раковые патологии;</li>\n' +
                      '          <li>гепатиты;</li>\n' +
                      '          <li>нарушения в работе сердца и сосудов;</li>\n' +
                      '          <li>после проведения оперативных вмешательств;</li>\n' +
                      '          <li>беременность;</li>\n' +
                      '          <li>алкоголизм;</li>\n' +
                      '          <li>недавнее введение прививки;</li>\n' +
                      '          <li>язва желудка или двенадцатиперстной кишки;</li>\n' +
                      '          <li>татуировки;</li>\n' +
                      '          <li>обширные стоматологические поражения.</li>\n' +
                      '        </ul>',
                    sale: '20',
                    tags: [
                      {
                        id: 1,
                        name: 'Кровь',
                        desc: 'Описание тега'
                      },
                      {
                        id: 2,
                        name: 'Глюкоза',
                        desc: 'Описание тега'
                      },
                      {
                        id: 3,
                        name: 'Витамины',
                        desc: 'Описание тега'
                      }
                    ],
                    catalog:
                      {
                        id: 1,
                        name: 'Анализы крови',
                        desc: 'Описание Клинический анализ крови'
                      }
                  },
                  {
                    id: 7,
                    name: 'Анализ крови артерий и вен',
                    display_name: 'Анализ крови артерий и вен',
                    price: '1320',
                    // tslint:disable-next-line:max-line-length
                    anons: 'Ультразвуковую диагностику смело можно назвать уникальным методом исследования. Она обладает такими свойствами как неинвазивность, возможность многократного проведения исследования без вреда для здоровья пациента, быстрота получения информации, отсутствие противопоказаний, исключение лучевой нагрузки.',
                    // tslint:disable-next-line:max-line-length
                    body: 'Проснувшись однажды утром после беспокойного сна, Грегор Замза обнаружил.',
                    // tslint:disable-next-line:max-line-length
                    memo: 'Вы должны подготовить себя к исследованию следующим образом: воздержаться от физических нагрузок, приема алкоголя и лекарств, изменений в питании в течение 24 часов до взятия крови. Вам не следует принимать пищу после ужина, лечь спать нужно в обычное для Вас время и встать не позднее, чем за час до взятия крови. Утром до сдачи анализа воздержитесь от курения и приема пищи. Взятие крови осуществляется до проведения диагностических или лечебных процедур: инъекций, общего массажа тела, эндоскопий, ЭКГ, рентгеновского обследования и т.д. Если Вы испытываете трудности с отменой лекарств, то обязательно сообщите об этом лечащему врачу. Очень важно, чтобы Вы точно соблюдали указанные рекомендации, так как только в этом случае будут получены ценные результаты исследования крови.',
                    // tslint:disable-next-line:max-line-length
                    indication: 'Анализ на свертываемость является одним из самых простых и доступных методов исследования. Пациенту нужно отказываться от приема пищи в течение двух часов перед исследованием. Результаты готовы, как правило, через несколько часов. Такое тестирование проводится в рамках комплексного обследования организма, а также при наличии кровотечений невыясненной природы и при диагностике или контроле эффективности лечения патологий костного мозга. Референсные значения зависят от возраста, а в детстве — еще и от пола.',
                    contraindic: '<ul>При некоторых заболеваниях проведение забора тромбоцитарной массы категорически запрещено: \n' +
                      '          <li>туберкулез;</li>\n' +
                      '          <li>вирус иммунодефицита;</li>\n' +
                      '          <li>раковые патологии;</li>\n' +
                      '          <li>гепатиты;</li>\n' +
                      '          <li>нарушения в работе сердца и сосудов;</li>\n' +
                      '          <li>после проведения оперативных вмешательств;</li>\n' +
                      '          <li>беременность;</li>\n' +
                      '          <li>алкоголизм;</li>\n' +
                      '          <li>недавнее введение прививки;</li>\n' +
                      '          <li>язва желудка или двенадцатиперстной кишки;</li>\n' +
                      '          <li>татуировки;</li>\n' +
                      '          <li>обширные стоматологические поражения.</li>\n' +
                      '        </ul>',
                    sale: '',
                    documents: [
                      {
                        id: 1,
                        title: 'Паспорт',
                        // tslint:disable-next-line:max-line-length
                        desc: 'Проснувшись однажды утром после беспокойного сна, Грегор Замза обнаружил, что он у себя в постели превратился в страшное насекомое.',
                        main_photo:
                          {
                            id: 1,
                            url: '../../assets/img/doc-img-1.png',
                          }
                      }
                    ],
                    tags: [
                      {
                        id: 1,
                        name: 'Кровь',
                        desc: 'Описание тега'
                      },
                      {
                        id: 2,
                        name: 'Глюкоза',
                        desc: 'Описание тега'
                      },
                      {
                        id: 3,
                        name: 'Гормоны',
                        desc: 'Описание тега'
                      }
                    ],
                    catalog:
                      {
                        id: 1,
                        name: 'Анализы крови',
                        desc: 'Описание Клинический анализ крови'
                      }
                  }
                ],
              },
              {
                id: 23,
                name: 'Эндоскопия',
                main_photo: {
                  id: 1,
                  url: '../../assets/img/direction2-1.png',
                },
                short_description: 'Краткое описание Эндоскопия',
                description: 'Полное описание Эндоскопия',
                icon: null,
                units: [
                  {
                    id: 2,
                    name: 'Поликлиника №1',
                    address: {
                      id: 1,
                      city: 'Красноярск',
                      street: 'Вавилова',
                      house: '28',
                      building: '',
                      ymapcfg: '../../assets/img/contacts-card-map.png',
                      postcode: '660025',
                      region: 'Красноярский край',
                      mailbox: '6545'
                    },
                    phones: [
                      {
                        id: 7,
                        number: '+7 (391) 274-31-64',
                        description: 'круглосуточно'
                      },
                      {
                        id: 8,
                        number: '+7 (391) 257-64-60',
                        description: 'с 9:00 до 17:00'
                      }
                    ],
                  },
                ],
                services: [
                  {
                    id: 5,
                    name: 'УЗИ печеночного трансплантанта с дуплексным сканированием артерий и вен',
                    display_name: 'УЗИ печеночного трансплантанта с дуплексным сканированием артерий и вен',
                    price: '1320',
                    // tslint:disable-next-line:max-line-length
                    anons: 'Ультразвуковую диагностику смело можно назвать уникальным методом исследования. Она обладает такими свойствами как неинвазивность, возможность многократного проведения исследования без вреда для здоровья пациента, быстрота получения информации, отсутствие противопоказаний, исключение лучевой нагрузки.',
                    // tslint:disable-next-line:max-line-length
                    body: 'Проснувшись однажды утром после беспокойного сна, Грегор Замза обнаружил, что он у себя в постели превратился в страшное насекомое.',
                    // tslint:disable-next-line:max-line-length
                    memo: 'Вы должны подготовить себя к исследованию следующим образом: воздержаться от физических нагрузок, приема алкоголя и лекарств, изменений в питании в течение 24 часов до взятия крови. Вам не следует принимать пищу после ужина, лечь спать нужно в обычное для Вас время и встать не позднее, чем за час до взятия крови. Утром до сдачи анализа воздержитесь от курения и приема пищи. Взятие крови осуществляется до проведения диагностических или лечебных процедур: инъекций, общего массажа тела, эндоскопий, ЭКГ, рентгеновского обследования и т.д. Если Вы испытываете трудности с отменой лекарств, то обязательно сообщите об этом лечащему врачу. Очень важно, чтобы Вы точно соблюдали указанные рекомендации, так как только в этом случае будут получены ценные результаты исследования крови.',
                    // tslint:disable-next-line:max-line-length
                    indication: 'Анализ на свертываемость является одним из самых простых и доступных методов исследования. Пациенту нужно отказываться от приема пищи в течение двух часов перед исследованием. Результаты готовы, как правило, через несколько часов. Такое тестирование проводится в рамках комплексного обследования организма, а также при наличии кровотечений невыясненной природы и при диагностике или контроле эффективности лечения патологий костного мозга. Референсные значения зависят от возраста, а в детстве — еще и от пола.',
                    contraindic: '<ul>При некоторых заболеваниях проведение забора тромбоцитарной массы категорически запрещено: \n' +
                      '          <li>туберкулез;</li>\n' +
                      '          <li>вирус иммунодефицита;</li>\n' +
                      '          <li>раковые патологии;</li>\n' +
                      '          <li>гепатиты;</li>\n' +
                      '          <li>нарушения в работе сердца и сосудов;</li>\n' +
                      '          <li>после проведения оперативных вмешательств;</li>\n' +
                      '          <li>беременность;</li>\n' +
                      '          <li>алкоголизм;</li>\n' +
                      '          <li>недавнее введение прививки;</li>\n' +
                      '          <li>язва желудка или двенадцатиперстной кишки;</li>\n' +
                      '          <li>татуировки;</li>\n' +
                      '          <li>обширные стоматологические поражения.</li>\n' +
                      '        </ul>',
                    sale: '',
                    documents: [
                      {
                        id: 1,
                        title: 'Паспорт',
                        // tslint:disable-next-line:max-line-length
                        desc: 'Проснувшись однажды утром после беспокойного сна, Грегор Замза обнаружил, что он у себя в постели превратился в страшное насекомое.',
                        main_photo:
                          {
                            id: 1,
                            url: '../../assets/img/doc-img-1.png',
                          }
                      }
                    ],
                    tags: [
                      {
                        id: 1,
                        name: 'Кровь',
                        desc: 'Описание тега'
                      },
                      {
                        id: 2,
                        name: 'Глюкоза',
                        desc: 'Описание тега'
                      },
                      {
                        id: 3,
                        name: 'Витамины',
                        desc: 'Описание тега'
                      }
                    ],
                    catalog:
                      {
                        id: 1,
                        name: 'УЗИ - диагностические исследования',
                        desc: 'Описание Клинический анализ крови'
                      }
                  },
                  {
                    id: 6,
                    name: 'УЗИ печеночного трансплантанта с дуплексным сканированием артерий и вен',
                    display_name: 'УЗИ печеночного трансплантанта с дуплексным сканированием артерий и вен',
                    price: '1320',
                    // tslint:disable-next-line:max-line-length
                    anons: 'Ультразвуковую диагностику смело можно назвать уникальным методом исследования. Она обладает такими свойствами как неинвазивность, возможность многократного проведения исследования без вреда для здоровья пациента, быстрота получения информации, отсутствие противопоказаний, исключение лучевой нагрузки.',
                    // tslint:disable-next-line:max-line-length
                    body: 'Проснувшись однажды утром после беспокойного сна, Грегор Замза обнаружил, что он у себя в постели превратился в страшное насекомое.',
                    // tslint:disable-next-line:max-line-length
                    memo: 'Вы должны подготовить себя к исследованию следующим образом: воздержаться от физических нагрузок, приема алкоголя и лекарств, изменений в питании в течение 24 часов до взятия крови. Вам не следует принимать пищу после ужина, лечь спать нужно в обычное для Вас время и встать не позднее, чем за час до взятия крови. Утром до сдачи анализа воздержитесь от курения и приема пищи. Взятие крови осуществляется до проведения диагностических или лечебных процедур: инъекций, общего массажа тела, эндоскопий, ЭКГ, рентгеновского обследования и т.д. Если Вы испытываете трудности с отменой лекарств, то обязательно сообщите об этом лечащему врачу. Очень важно, чтобы Вы точно соблюдали указанные рекомендации, так как только в этом случае будут получены ценные результаты исследования крови.',
                    // tslint:disable-next-line:max-line-length
                    indication: 'Анализ на свертываемость является одним из самых простых и доступных методов исследования. Пациенту нужно отказываться от приема пищи в течение двух часов перед исследованием. Результаты готовы, как правило, через несколько часов. Такое тестирование проводится в рамках комплексного обследования организма, а также при наличии кровотечений невыясненной природы и при диагностике или контроле эффективности лечения патологий костного мозга. Референсные значения зависят от возраста, а в детстве — еще и от пола.',
                    contraindic: '<ul>При некоторых заболеваниях проведение забора тромбоцитарной массы категорически запрещено: \n' +
                      '          <li>туберкулез;</li>\n' +
                      '          <li>вирус иммунодефицита;</li>\n' +
                      '          <li>раковые патологии;</li>\n' +
                      '          <li>гепатиты;</li>\n' +
                      '          <li>нарушения в работе сердца и сосудов;</li>\n' +
                      '          <li>после проведения оперативных вмешательств;</li>\n' +
                      '          <li>беременность;</li>\n' +
                      '          <li>алкоголизм;</li>\n' +
                      '          <li>недавнее введение прививки;</li>\n' +
                      '          <li>язва желудка или двенадцатиперстной кишки;</li>\n' +
                      '          <li>татуировки;</li>\n' +
                      '          <li>обширные стоматологические поражения.</li>\n' +
                      '        </ul>',
                    sale: '20',
                    tags: [
                      {
                        id: 1,
                        name: 'Кровь',
                        desc: 'Описание тега'
                      },
                      {
                        id: 2,
                        name: 'Глюкоза',
                        desc: 'Описание тега'
                      },
                      {
                        id: 3,
                        name: 'Витамины',
                        desc: 'Описание тега'
                      }
                    ],
                    catalog:
                      {
                        id: 1,
                        name: 'Анализы крови',
                        desc: 'Описание Клинический анализ крови'
                      }
                  },
                  {
                    id: 7,
                    name: 'Анализ крови артерий и вен',
                    display_name: 'Анализ крови артерий и вен',
                    price: '1320',
                    // tslint:disable-next-line:max-line-length
                    anons: 'Ультразвуковую диагностику смело можно назвать уникальным методом исследования. Она обладает такими свойствами как неинвазивность, возможность многократного проведения исследования без вреда для здоровья пациента, быстрота получения информации, отсутствие противопоказаний, исключение лучевой нагрузки.',
                    // tslint:disable-next-line:max-line-length
                    body: 'Проснувшись однажды утром после беспокойного сна, Грегор Замза обнаружил.',
                    // tslint:disable-next-line:max-line-length
                    memo: 'Вы должны подготовить себя к исследованию следующим образом: воздержаться от физических нагрузок, приема алкоголя и лекарств, изменений в питании в течение 24 часов до взятия крови. Вам не следует принимать пищу после ужина, лечь спать нужно в обычное для Вас время и встать не позднее, чем за час до взятия крови. Утром до сдачи анализа воздержитесь от курения и приема пищи. Взятие крови осуществляется до проведения диагностических или лечебных процедур: инъекций, общего массажа тела, эндоскопий, ЭКГ, рентгеновского обследования и т.д. Если Вы испытываете трудности с отменой лекарств, то обязательно сообщите об этом лечащему врачу. Очень важно, чтобы Вы точно соблюдали указанные рекомендации, так как только в этом случае будут получены ценные результаты исследования крови.',
                    // tslint:disable-next-line:max-line-length
                    indication: 'Анализ на свертываемость является одним из самых простых и доступных методов исследования. Пациенту нужно отказываться от приема пищи в течение двух часов перед исследованием. Результаты готовы, как правило, через несколько часов. Такое тестирование проводится в рамках комплексного обследования организма, а также при наличии кровотечений невыясненной природы и при диагностике или контроле эффективности лечения патологий костного мозга. Референсные значения зависят от возраста, а в детстве — еще и от пола.',
                    contraindic: '<ul>При некоторых заболеваниях проведение забора тромбоцитарной массы категорически запрещено: \n' +
                      '          <li>туберкулез;</li>\n' +
                      '          <li>вирус иммунодефицита;</li>\n' +
                      '          <li>раковые патологии;</li>\n' +
                      '          <li>гепатиты;</li>\n' +
                      '          <li>нарушения в работе сердца и сосудов;</li>\n' +
                      '          <li>после проведения оперативных вмешательств;</li>\n' +
                      '          <li>беременность;</li>\n' +
                      '          <li>алкоголизм;</li>\n' +
                      '          <li>недавнее введение прививки;</li>\n' +
                      '          <li>язва желудка или двенадцатиперстной кишки;</li>\n' +
                      '          <li>татуировки;</li>\n' +
                      '          <li>обширные стоматологические поражения.</li>\n' +
                      '        </ul>',
                    sale: '',
                    documents: [
                      {
                        id: 1,
                        title: 'Паспорт',
                        // tslint:disable-next-line:max-line-length
                        desc: 'Проснувшись однажды утром после беспокойного сна, Грегор Замза обнаружил, что он у себя в постели превратился в страшное насекомое.',
                        main_photo:
                          {
                            id: 1,
                            url: '../../assets/img/doc-img-1.png',
                          }
                      }
                    ],
                    tags: [
                      {
                        id: 1,
                        name: 'Кровь',
                        desc: 'Описание тега'
                      },
                      {
                        id: 2,
                        name: 'Глюкоза',
                        desc: 'Описание тега'
                      },
                      {
                        id: 3,
                        name: 'Гормоны',
                        desc: 'Описание тега'
                      }
                    ],
                    catalog:
                      {
                        id: 1,
                        name: 'Анализы крови',
                        desc: 'Описание Клинический анализ крови'
                      }
                  }
                ],
              }
            ],
            directions: [
              {
                id: 60,
                name: 'root',
                short_description: null,
                description: null,
                icon: null
              }
            ],
          },
          {
            id: 19,
            name: 'Амбулаторный прием',
            short_description: 'Проснувшись однажды утром после беспокойного сна, Грегор Замза обнаружил, что он у себя в постели превратился в страшное насекомое.',
            description: 'Полное описание Диагностика. Его многочисленные, убого тонкие по сравнению с остальным телом ножки беспомощно копошились у него перед глазами. «Что со мной случилось? » – подумал он. Это не было сном.',
            main_photo: { // TODO добавить
              id: 1,
              url: '../../assets/img/direction-2.png',
            },
            subdirections: [
              {
                id: 21,
                name: 'Рентген',
                main_photo: {
                  id: 1,
                  url: '../../assets/img/direction2-1.png',
                },
                short_description: 'Краткое описание Рентген',
                description: 'Полное описание Рентген',
                icon: null,
                units: [
                  {
                    id: 2,
                    name: 'Поликлиника №1',
                    address: {
                      id: 1,
                      city: 'Красноярск',
                      street: 'Вавилова',
                      house: '28',
                      building: '',
                      ymapcfg: '../../assets/img/contacts-card-map.png',
                      postcode: '660025',
                      region: 'Красноярский край',
                      mailbox: '6545'
                    },
                    phones: [
                      {
                        id: 7,
                        number: '+7 (391) 274-31-64',
                        description: 'круглосуточно'
                      },
                      {
                        id: 8,
                        number: '+7 (391) 257-64-60',
                        description: 'с 9:00 до 17:00'
                      }
                    ],
                  },
                  {
                    id: 3,
                    name: 'Поликлиника №3',
                    address: {
                      id: 1,
                      city: 'Красноярск',
                      street: 'Коломенская',
                      house: '28',
                      building: '',
                      ymapcfg: '../../assets/img/contacts-card-map.png',
                      postcode: '660025',
                      region: 'Красноярский край',
                      mailbox: '6545'
                    },
                    phones: [
                      {
                        id: 7,
                        number: '+7 (391) 274-31-64',
                        description: 'круглосуточно'
                      },
                      {
                        id: 8,
                        number: '+7 (391) 257-64-60',
                        description: 'с 9:00 до 17:00'
                      }
                    ],
                  },
                ],
                services: [
                  {
                    id: 4,
                    name: 'УЗИ печеночного трансплантанта с дуплексным сканированием артерий и вен',
                    display_name: 'УЗИ печеночного трансплантанта с дуплексным сканированием артерий и вен',
                    price: '1320',
                    // tslint:disable-next-line:max-line-length
                    anons: 'Ультразвуковую диагностику смело можно назвать уникальным методом исследования. Она обладает такими свойствами как неинвазивность, возможность многократного проведения исследования без вреда для здоровья пациента, быстрота получения информации, отсутствие противопоказаний, исключение лучевой нагрузки.',
                    // tslint:disable-next-line:max-line-length
                    body: 'Проснувшись однажды утром после беспокойного сна, Грегор Замза обнаружил, что он у себя в постели превратился в страшное насекомое.',
                    // tslint:disable-next-line:max-line-length
                    memo: 'Вы должны подготовить себя к исследованию следующим образом: воздержаться от физических нагрузок, приема алкоголя и лекарств, изменений в питании в течение 24 часов до взятия крови. Вам не следует принимать пищу после ужина, лечь спать нужно в обычное для Вас время и встать не позднее, чем за час до взятия крови. Утром до сдачи анализа воздержитесь от курения и приема пищи. Взятие крови осуществляется до проведения диагностических или лечебных процедур: инъекций, общего массажа тела, эндоскопий, ЭКГ, рентгеновского обследования и т.д. Если Вы испытываете трудности с отменой лекарств, то обязательно сообщите об этом лечащему врачу. Очень важно, чтобы Вы точно соблюдали указанные рекомендации, так как только в этом случае будут получены ценные результаты исследования крови.',
                    // tslint:disable-next-line:max-line-length
                    indication: 'Анализ на свертываемость является одним из самых простых и доступных методов исследования. Пациенту нужно отказываться от приема пищи в течение двух часов перед исследованием. Результаты готовы, как правило, через несколько часов. Такое тестирование проводится в рамках комплексного обследования организма, а также при наличии кровотечений невыясненной природы и при диагностике или контроле эффективности лечения патологий костного мозга. Референсные значения зависят от возраста, а в детстве — еще и от пола.',
                    contraindic: '<ul>При некоторых заболеваниях проведение забора тромбоцитарной массы категорически запрещено: \n' +
                      '          <li>туберкулез;</li>\n' +
                      '          <li>вирус иммунодефицита;</li>\n' +
                      '          <li>раковые патологии;</li>\n' +
                      '          <li>гепатиты;</li>\n' +
                      '          <li>нарушения в работе сердца и сосудов;</li>\n' +
                      '          <li>после проведения оперативных вмешательств;</li>\n' +
                      '          <li>беременность;</li>\n' +
                      '          <li>алкоголизм;</li>\n' +
                      '          <li>недавнее введение прививки;</li>\n' +
                      '          <li>язва желудка или двенадцатиперстной кишки;</li>\n' +
                      '          <li>татуировки;</li>\n' +
                      '          <li>обширные стоматологические поражения.</li>\n' +
                      '        </ul>',
                    sale: '',
                    documents: [
                      {
                        id: 1,
                        title: 'Паспорт',
                        // tslint:disable-next-line:max-line-length
                        desc: 'Проснувшись однажды утром после беспокойного сна, Грегор Замза обнаружил, что он у себя в постели превратился в страшное насекомое.',
                        main_photo:
                          {
                            id: 1,
                            url: '../../assets/img/doc-img-1.png',
                          }
                      }
                    ],
                    tags: [
                      {
                        id: 1,
                        name: 'Кровь',
                        desc: 'Описание тега'
                      },
                      {
                        id: 2,
                        name: 'Глюкоза',
                        desc: 'Описание тега'
                      },
                      {
                        id: 3,
                        name: 'Витамины',
                        desc: 'Описание тега'
                      }
                    ],
                    catalog:
                      {
                        id: 1,
                        name: 'Клинический анализ крови',
                        desc: 'Описание Клинический анализ крови'
                      }
                  },
                  {
                    id: 5,
                    name: 'УЗИ печеночного трансплантанта с дуплексным сканированием артерий и вен',
                    display_name: 'УЗИ печеночного трансплантанта с дуплексным сканированием артерий и вен',
                    price: '1320',
                    // tslint:disable-next-line:max-line-length
                    anons: 'Ультразвуковую диагностику смело можно назвать уникальным методом исследования. Она обладает такими свойствами как неинвазивность, возможность многократного проведения исследования без вреда для здоровья пациента, быстрота получения информации, отсутствие противопоказаний, исключение лучевой нагрузки.',
                    // tslint:disable-next-line:max-line-length
                    body: 'Проснувшись однажды утром после беспокойного сна, Грегор Замза обнаружил, что он у себя в постели превратился в страшное насекомое.',
                    // tslint:disable-next-line:max-line-length
                    memo: 'Вы должны подготовить себя к исследованию следующим образом: воздержаться от физических нагрузок, приема алкоголя и лекарств, изменений в питании в течение 24 часов до взятия крови. Вам не следует принимать пищу после ужина, лечь спать нужно в обычное для Вас время и встать не позднее, чем за час до взятия крови. Утром до сдачи анализа воздержитесь от курения и приема пищи. Взятие крови осуществляется до проведения диагностических или лечебных процедур: инъекций, общего массажа тела, эндоскопий, ЭКГ, рентгеновского обследования и т.д. Если Вы испытываете трудности с отменой лекарств, то обязательно сообщите об этом лечащему врачу. Очень важно, чтобы Вы точно соблюдали указанные рекомендации, так как только в этом случае будут получены ценные результаты исследования крови.',
                    // tslint:disable-next-line:max-line-length
                    indication: 'Анализ на свертываемость является одним из самых простых и доступных методов исследования. Пациенту нужно отказываться от приема пищи в течение двух часов перед исследованием. Результаты готовы, как правило, через несколько часов. Такое тестирование проводится в рамках комплексного обследования организма, а также при наличии кровотечений невыясненной природы и при диагностике или контроле эффективности лечения патологий костного мозга. Референсные значения зависят от возраста, а в детстве — еще и от пола.',
                    contraindic: '<ul>При некоторых заболеваниях проведение забора тромбоцитарной массы категорически запрещено: \n' +
                      '          <li>туберкулез;</li>\n' +
                      '          <li>вирус иммунодефицита;</li>\n' +
                      '          <li>раковые патологии;</li>\n' +
                      '          <li>гепатиты;</li>\n' +
                      '          <li>нарушения в работе сердца и сосудов;</li>\n' +
                      '          <li>после проведения оперативных вмешательств;</li>\n' +
                      '          <li>беременность;</li>\n' +
                      '          <li>алкоголизм;</li>\n' +
                      '          <li>недавнее введение прививки;</li>\n' +
                      '          <li>язва желудка или двенадцатиперстной кишки;</li>\n' +
                      '          <li>татуировки;</li>\n' +
                      '          <li>обширные стоматологические поражения.</li>\n' +
                      '        </ul>',
                    sale: '',
                    documents: [
                      {
                        id: 1,
                        title: 'Паспорт',
                        // tslint:disable-next-line:max-line-length
                        desc: 'Проснувшись однажды утром после беспокойного сна, Грегор Замза обнаружил, что он у себя в постели превратился в страшное насекомое.',
                        main_photo:
                          {
                            id: 1,
                            url: '../../assets/img/doc-img-1.png',
                          }
                      }
                    ],
                    tags: [
                      {
                        id: 1,
                        name: 'Кровь',
                        desc: 'Описание тега'
                      },
                      {
                        id: 2,
                        name: 'Глюкоза',
                        desc: 'Описание тега'
                      },
                      {
                        id: 3,
                        name: 'Витамины',
                        desc: 'Описание тега'
                      }
                    ],
                    catalog:
                      {
                        id: 1,
                        name: 'УЗИ - диагностические исследования',
                        desc: 'Описание Клинический анализ крови'
                      }
                  },
                  {
                    id: 6,
                    name: 'УЗИ печеночного трансплантанта с дуплексным сканированием артерий и вен',
                    display_name: 'УЗИ печеночного трансплантанта с дуплексным сканированием артерий и вен',
                    price: '1320',
                    // tslint:disable-next-line:max-line-length
                    anons: 'Ультразвуковую диагностику смело можно назвать уникальным методом исследования. Она обладает такими свойствами как неинвазивность, возможность многократного проведения исследования без вреда для здоровья пациента, быстрота получения информации, отсутствие противопоказаний, исключение лучевой нагрузки.',
                    // tslint:disable-next-line:max-line-length
                    body: 'Проснувшись однажды утром после беспокойного сна, Грегор Замза обнаружил, что он у себя в постели превратился в страшное насекомое.',
                    // tslint:disable-next-line:max-line-length
                    memo: 'Вы должны подготовить себя к исследованию следующим образом: воздержаться от физических нагрузок, приема алкоголя и лекарств, изменений в питании в течение 24 часов до взятия крови. Вам не следует принимать пищу после ужина, лечь спать нужно в обычное для Вас время и встать не позднее, чем за час до взятия крови. Утром до сдачи анализа воздержитесь от курения и приема пищи. Взятие крови осуществляется до проведения диагностических или лечебных процедур: инъекций, общего массажа тела, эндоскопий, ЭКГ, рентгеновского обследования и т.д. Если Вы испытываете трудности с отменой лекарств, то обязательно сообщите об этом лечащему врачу. Очень важно, чтобы Вы точно соблюдали указанные рекомендации, так как только в этом случае будут получены ценные результаты исследования крови.',
                    // tslint:disable-next-line:max-line-length
                    indication: 'Анализ на свертываемость является одним из самых простых и доступных методов исследования. Пациенту нужно отказываться от приема пищи в течение двух часов перед исследованием. Результаты готовы, как правило, через несколько часов. Такое тестирование проводится в рамках комплексного обследования организма, а также при наличии кровотечений невыясненной природы и при диагностике или контроле эффективности лечения патологий костного мозга. Референсные значения зависят от возраста, а в детстве — еще и от пола.',
                    contraindic: '<ul>При некоторых заболеваниях проведение забора тромбоцитарной массы категорически запрещено: \n' +
                      '          <li>туберкулез;</li>\n' +
                      '          <li>вирус иммунодефицита;</li>\n' +
                      '          <li>раковые патологии;</li>\n' +
                      '          <li>гепатиты;</li>\n' +
                      '          <li>нарушения в работе сердца и сосудов;</li>\n' +
                      '          <li>после проведения оперативных вмешательств;</li>\n' +
                      '          <li>беременность;</li>\n' +
                      '          <li>алкоголизм;</li>\n' +
                      '          <li>недавнее введение прививки;</li>\n' +
                      '          <li>язва желудка или двенадцатиперстной кишки;</li>\n' +
                      '          <li>татуировки;</li>\n' +
                      '          <li>обширные стоматологические поражения.</li>\n' +
                      '        </ul>',
                    sale: '20',
                    tags: [
                      {
                        id: 1,
                        name: 'Кровь',
                        desc: 'Описание тега'
                      },
                      {
                        id: 2,
                        name: 'Глюкоза',
                        desc: 'Описание тега'
                      },
                      {
                        id: 3,
                        name: 'Витамины',
                        desc: 'Описание тега'
                      }
                    ],
                    catalog:
                      {
                        id: 1,
                        name: 'Анализы крови',
                        desc: 'Описание Клинический анализ крови'
                      }
                  },
                  {
                    id: 7,
                    name: 'Анализ крови артерий и вен',
                    display_name: 'Анализ крови артерий и вен',
                    price: '1320',
                    // tslint:disable-next-line:max-line-length
                    anons: 'Ультразвуковую диагностику смело можно назвать уникальным методом исследования. Она обладает такими свойствами как неинвазивность, возможность многократного проведения исследования без вреда для здоровья пациента, быстрота получения информации, отсутствие противопоказаний, исключение лучевой нагрузки.',
                    // tslint:disable-next-line:max-line-length
                    body: 'Проснувшись однажды утром после беспокойного сна, Грегор Замза обнаружил.',
                    // tslint:disable-next-line:max-line-length
                    memo: 'Вы должны подготовить себя к исследованию следующим образом: воздержаться от физических нагрузок, приема алкоголя и лекарств, изменений в питании в течение 24 часов до взятия крови. Вам не следует принимать пищу после ужина, лечь спать нужно в обычное для Вас время и встать не позднее, чем за час до взятия крови. Утром до сдачи анализа воздержитесь от курения и приема пищи. Взятие крови осуществляется до проведения диагностических или лечебных процедур: инъекций, общего массажа тела, эндоскопий, ЭКГ, рентгеновского обследования и т.д. Если Вы испытываете трудности с отменой лекарств, то обязательно сообщите об этом лечащему врачу. Очень важно, чтобы Вы точно соблюдали указанные рекомендации, так как только в этом случае будут получены ценные результаты исследования крови.',
                    // tslint:disable-next-line:max-line-length
                    indication: 'Анализ на свертываемость является одним из самых простых и доступных методов исследования. Пациенту нужно отказываться от приема пищи в течение двух часов перед исследованием. Результаты готовы, как правило, через несколько часов. Такое тестирование проводится в рамках комплексного обследования организма, а также при наличии кровотечений невыясненной природы и при диагностике или контроле эффективности лечения патологий костного мозга. Референсные значения зависят от возраста, а в детстве — еще и от пола.',
                    contraindic: '<ul>При некоторых заболеваниях проведение забора тромбоцитарной массы категорически запрещено: \n' +
                      '          <li>туберкулез;</li>\n' +
                      '          <li>вирус иммунодефицита;</li>\n' +
                      '          <li>раковые патологии;</li>\n' +
                      '          <li>гепатиты;</li>\n' +
                      '          <li>нарушения в работе сердца и сосудов;</li>\n' +
                      '          <li>после проведения оперативных вмешательств;</li>\n' +
                      '          <li>беременность;</li>\n' +
                      '          <li>алкоголизм;</li>\n' +
                      '          <li>недавнее введение прививки;</li>\n' +
                      '          <li>язва желудка или двенадцатиперстной кишки;</li>\n' +
                      '          <li>татуировки;</li>\n' +
                      '          <li>обширные стоматологические поражения.</li>\n' +
                      '        </ul>',
                    sale: '',
                    documents: [
                      {
                        id: 1,
                        title: 'Паспорт',
                        // tslint:disable-next-line:max-line-length
                        desc: 'Проснувшись однажды утром после беспокойного сна, Грегор Замза обнаружил, что он у себя в постели превратился в страшное насекомое.',
                        main_photo:
                          {
                            id: 1,
                            url: '../../assets/img/doc-img-1.png',
                          }
                      }
                    ],
                    tags: [
                      {
                        id: 1,
                        name: 'Кровь',
                        desc: 'Описание тега'
                      },
                      {
                        id: 2,
                        name: 'Глюкоза',
                        desc: 'Описание тега'
                      },
                      {
                        id: 3,
                        name: 'Гормоны',
                        desc: 'Описание тега'
                      }
                    ],
                    catalog:
                      {
                        id: 1,
                        name: 'Анализы крови',
                        desc: 'Описание Клинический анализ крови'
                      }
                  }
                ],
              },
              {
                id: 24,
                name: 'Функциональная диагностика',
                main_photo: {
                  id: 1,
                  url: '../../assets/img/direction2-3.png',
                },
                short_description: 'Краткое описание Функциональная диагностика',
                description: 'Полное описание Функциональная диагностика',
                icon: null,
                units: [
                  {
                    id: 2,
                    name: 'Поликлиника №1',
                    address: {
                      id: 1,
                      city: 'Красноярск',
                      street: 'Вавилова',
                      house: '28',
                      building: '',
                      ymapcfg: '../../assets/img/contacts-card-map.png',
                      postcode: '660025',
                      region: 'Красноярский край',
                      mailbox: '6545'
                    },
                    phones: [
                      {
                        id: 7,
                        number: '+7 (391) 274-31-64',
                        description: 'круглосуточно'
                      },
                      {
                        id: 8,
                        number: '+7 (391) 257-64-60',
                        description: 'с 9:00 до 17:00'
                      }
                    ],
                  },
                  {
                    id: 3,
                    name: 'Поликлиника №3',
                    address: {
                      id: 1,
                      city: 'Красноярск',
                      street: 'Коломенская',
                      house: '28',
                      building: '',
                      ymapcfg: '../../assets/img/contacts-card-map.png',
                      postcode: '660025',
                      region: 'Красноярский край',
                      mailbox: '6545'
                    },
                    phones: [
                      {
                        id: 7,
                        number: '+7 (391) 274-31-64',
                        description: 'круглосуточно'
                      },
                      {
                        id: 8,
                        number: '+7 (391) 257-64-60',
                        description: 'с 9:00 до 17:00'
                      }
                    ],
                  },
                ],
                services: [
                  {
                    id: 5,
                    name: 'УЗИ печеночного трансплантанта с дуплексным сканированием артерий и вен',
                    display_name: 'УЗИ печеночного трансплантанта с дуплексным сканированием артерий и вен',
                    price: '1320',
                    // tslint:disable-next-line:max-line-length
                    anons: 'Ультразвуковую диагностику смело можно назвать уникальным методом исследования. Она обладает такими свойствами как неинвазивность, возможность многократного проведения исследования без вреда для здоровья пациента, быстрота получения информации, отсутствие противопоказаний, исключение лучевой нагрузки.',
                    // tslint:disable-next-line:max-line-length
                    body: 'Проснувшись однажды утром после беспокойного сна, Грегор Замза обнаружил, что он у себя в постели превратился в страшное насекомое.',
                    // tslint:disable-next-line:max-line-length
                    memo: 'Вы должны подготовить себя к исследованию следующим образом: воздержаться от физических нагрузок, приема алкоголя и лекарств, изменений в питании в течение 24 часов до взятия крови. Вам не следует принимать пищу после ужина, лечь спать нужно в обычное для Вас время и встать не позднее, чем за час до взятия крови. Утром до сдачи анализа воздержитесь от курения и приема пищи. Взятие крови осуществляется до проведения диагностических или лечебных процедур: инъекций, общего массажа тела, эндоскопий, ЭКГ, рентгеновского обследования и т.д. Если Вы испытываете трудности с отменой лекарств, то обязательно сообщите об этом лечащему врачу. Очень важно, чтобы Вы точно соблюдали указанные рекомендации, так как только в этом случае будут получены ценные результаты исследования крови.',
                    // tslint:disable-next-line:max-line-length
                    indication: 'Анализ на свертываемость является одним из самых простых и доступных методов исследования. Пациенту нужно отказываться от приема пищи в течение двух часов перед исследованием. Результаты готовы, как правило, через несколько часов. Такое тестирование проводится в рамках комплексного обследования организма, а также при наличии кровотечений невыясненной природы и при диагностике или контроле эффективности лечения патологий костного мозга. Референсные значения зависят от возраста, а в детстве — еще и от пола.',
                    contraindic: '<ul>При некоторых заболеваниях проведение забора тромбоцитарной массы категорически запрещено: \n' +
                      '          <li>туберкулез;</li>\n' +
                      '          <li>вирус иммунодефицита;</li>\n' +
                      '          <li>раковые патологии;</li>\n' +
                      '          <li>гепатиты;</li>\n' +
                      '          <li>нарушения в работе сердца и сосудов;</li>\n' +
                      '          <li>после проведения оперативных вмешательств;</li>\n' +
                      '          <li>беременность;</li>\n' +
                      '          <li>алкоголизм;</li>\n' +
                      '          <li>недавнее введение прививки;</li>\n' +
                      '          <li>язва желудка или двенадцатиперстной кишки;</li>\n' +
                      '          <li>татуировки;</li>\n' +
                      '          <li>обширные стоматологические поражения.</li>\n' +
                      '        </ul>',
                    sale: '',
                    documents: [
                      {
                        id: 1,
                        title: 'Паспорт',
                        // tslint:disable-next-line:max-line-length
                        desc: 'Проснувшись однажды утром после беспокойного сна, Грегор Замза обнаружил, что он у себя в постели превратился в страшное насекомое.',
                        main_photo:
                          {
                            id: 1,
                            url: '../../assets/img/doc-img-1.png',
                          }
                      }
                    ],
                    tags: [
                      {
                        id: 1,
                        name: 'Кровь',
                        desc: 'Описание тега'
                      },
                      {
                        id: 2,
                        name: 'Глюкоза',
                        desc: 'Описание тега'
                      },
                      {
                        id: 3,
                        name: 'Витамины',
                        desc: 'Описание тега'
                      }
                    ],
                    catalog:
                      {
                        id: 1,
                        name: 'УЗИ - диагностические исследования',
                        desc: 'Описание Клинический анализ крови'
                      }
                  },
                  {
                    id: 6,
                    name: 'УЗИ печеночного трансплантанта с дуплексным сканированием артерий и вен',
                    display_name: 'УЗИ печеночного трансплантанта с дуплексным сканированием артерий и вен',
                    price: '1320',
                    // tslint:disable-next-line:max-line-length
                    anons: 'Ультразвуковую диагностику смело можно назвать уникальным методом исследования. Она обладает такими свойствами как неинвазивность, возможность многократного проведения исследования без вреда для здоровья пациента, быстрота получения информации, отсутствие противопоказаний, исключение лучевой нагрузки.',
                    // tslint:disable-next-line:max-line-length
                    body: 'Проснувшись однажды утром после беспокойного сна, Грегор Замза обнаружил, что он у себя в постели превратился в страшное насекомое.',
                    // tslint:disable-next-line:max-line-length
                    memo: 'Вы должны подготовить себя к исследованию следующим образом: воздержаться от физических нагрузок, приема алкоголя и лекарств, изменений в питании в течение 24 часов до взятия крови. Вам не следует принимать пищу после ужина, лечь спать нужно в обычное для Вас время и встать не позднее, чем за час до взятия крови. Утром до сдачи анализа воздержитесь от курения и приема пищи. Взятие крови осуществляется до проведения диагностических или лечебных процедур: инъекций, общего массажа тела, эндоскопий, ЭКГ, рентгеновского обследования и т.д. Если Вы испытываете трудности с отменой лекарств, то обязательно сообщите об этом лечащему врачу. Очень важно, чтобы Вы точно соблюдали указанные рекомендации, так как только в этом случае будут получены ценные результаты исследования крови.',
                    // tslint:disable-next-line:max-line-length
                    indication: 'Анализ на свертываемость является одним из самых простых и доступных методов исследования. Пациенту нужно отказываться от приема пищи в течение двух часов перед исследованием. Результаты готовы, как правило, через несколько часов. Такое тестирование проводится в рамках комплексного обследования организма, а также при наличии кровотечений невыясненной природы и при диагностике или контроле эффективности лечения патологий костного мозга. Референсные значения зависят от возраста, а в детстве — еще и от пола.',
                    contraindic: '<ul>При некоторых заболеваниях проведение забора тромбоцитарной массы категорически запрещено: \n' +
                      '          <li>туберкулез;</li>\n' +
                      '          <li>вирус иммунодефицита;</li>\n' +
                      '          <li>раковые патологии;</li>\n' +
                      '          <li>гепатиты;</li>\n' +
                      '          <li>нарушения в работе сердца и сосудов;</li>\n' +
                      '          <li>после проведения оперативных вмешательств;</li>\n' +
                      '          <li>беременность;</li>\n' +
                      '          <li>алкоголизм;</li>\n' +
                      '          <li>недавнее введение прививки;</li>\n' +
                      '          <li>язва желудка или двенадцатиперстной кишки;</li>\n' +
                      '          <li>татуировки;</li>\n' +
                      '          <li>обширные стоматологические поражения.</li>\n' +
                      '        </ul>',
                    sale: '20',
                    tags: [
                      {
                        id: 1,
                        name: 'Кровь',
                        desc: 'Описание тега'
                      },
                      {
                        id: 2,
                        name: 'Глюкоза',
                        desc: 'Описание тега'
                      },
                      {
                        id: 3,
                        name: 'Витамины',
                        desc: 'Описание тега'
                      }
                    ],
                    catalog:
                      {
                        id: 1,
                        name: 'Анализы крови',
                        desc: 'Описание Клинический анализ крови'
                      }
                  },
                  {
                    id: 7,
                    name: 'Анализ крови артерий и вен',
                    display_name: 'Анализ крови артерий и вен',
                    price: '1320',
                    // tslint:disable-next-line:max-line-length
                    anons: 'Ультразвуковую диагностику смело можно назвать уникальным методом исследования. Она обладает такими свойствами как неинвазивность, возможность многократного проведения исследования без вреда для здоровья пациента, быстрота получения информации, отсутствие противопоказаний, исключение лучевой нагрузки.',
                    // tslint:disable-next-line:max-line-length
                    body: 'Проснувшись однажды утром после беспокойного сна, Грегор Замза обнаружил.',
                    // tslint:disable-next-line:max-line-length
                    memo: 'Вы должны подготовить себя к исследованию следующим образом: воздержаться от физических нагрузок, приема алкоголя и лекарств, изменений в питании в течение 24 часов до взятия крови. Вам не следует принимать пищу после ужина, лечь спать нужно в обычное для Вас время и встать не позднее, чем за час до взятия крови. Утром до сдачи анализа воздержитесь от курения и приема пищи. Взятие крови осуществляется до проведения диагностических или лечебных процедур: инъекций, общего массажа тела, эндоскопий, ЭКГ, рентгеновского обследования и т.д. Если Вы испытываете трудности с отменой лекарств, то обязательно сообщите об этом лечащему врачу. Очень важно, чтобы Вы точно соблюдали указанные рекомендации, так как только в этом случае будут получены ценные результаты исследования крови.',
                    // tslint:disable-next-line:max-line-length
                    indication: 'Анализ на свертываемость является одним из самых простых и доступных методов исследования. Пациенту нужно отказываться от приема пищи в течение двух часов перед исследованием. Результаты готовы, как правило, через несколько часов. Такое тестирование проводится в рамках комплексного обследования организма, а также при наличии кровотечений невыясненной природы и при диагностике или контроле эффективности лечения патологий костного мозга. Референсные значения зависят от возраста, а в детстве — еще и от пола.',
                    contraindic: '<ul>При некоторых заболеваниях проведение забора тромбоцитарной массы категорически запрещено: \n' +
                      '          <li>туберкулез;</li>\n' +
                      '          <li>вирус иммунодефицита;</li>\n' +
                      '          <li>раковые патологии;</li>\n' +
                      '          <li>гепатиты;</li>\n' +
                      '          <li>нарушения в работе сердца и сосудов;</li>\n' +
                      '          <li>после проведения оперативных вмешательств;</li>\n' +
                      '          <li>беременность;</li>\n' +
                      '          <li>алкоголизм;</li>\n' +
                      '          <li>недавнее введение прививки;</li>\n' +
                      '          <li>язва желудка или двенадцатиперстной кишки;</li>\n' +
                      '          <li>татуировки;</li>\n' +
                      '          <li>обширные стоматологические поражения.</li>\n' +
                      '        </ul>',
                    sale: '',
                    documents: [
                      {
                        id: 1,
                        title: 'Паспорт',
                        // tslint:disable-next-line:max-line-length
                        desc: 'Проснувшись однажды утром после беспокойного сна, Грегор Замза обнаружил, что он у себя в постели превратился в страшное насекомое.',
                        main_photo:
                          {
                            id: 1,
                            url: '../../assets/img/doc-img-1.png',
                          }
                      }
                    ],
                    tags: [
                      {
                        id: 1,
                        name: 'Кровь',
                        desc: 'Описание тега'
                      },
                      {
                        id: 2,
                        name: 'Глюкоза',
                        desc: 'Описание тега'
                      },
                      {
                        id: 3,
                        name: 'Гормоны',
                        desc: 'Описание тега'
                      }
                    ],
                    catalog:
                      {
                        id: 1,
                        name: 'Анализы крови',
                        desc: 'Описание Клинический анализ крови'
                      }
                  }
                ],
              },
              {
                id: 22,
                name: 'УЗИ',
                main_photo: {
                  id: 1,
                  url: '../../assets/img/direction2-2.png',
                },
                short_description: 'Краткое описание УЗИ',
                description: 'Полное описание УЗИ',
                icon: null,
                units: [
                  {
                    id: 3,
                    name: 'Поликлиника №3',
                    address: {
                      id: 1,
                      city: 'Красноярск',
                      street: 'Коломенская',
                      house: '28',
                      building: '',
                      ymapcfg: '../../assets/img/contacts-card-map.png',
                      postcode: '660025',
                      region: 'Красноярский край',
                      mailbox: '6545'
                    },
                    phones: [
                      {
                        id: 7,
                        number: '+7 (391) 274-31-64',
                        description: 'круглосуточно'
                      },
                      {
                        id: 8,
                        number: '+7 (391) 257-64-60',
                        description: 'с 9:00 до 17:00'
                      }
                    ],
                  },
                ],
                services: [
                  {
                    id: 2,
                    // tslint:disable-next-line:max-line-length
                    name: 'Ультразвуковое исследование органов брюшной полости комплексное: печень, поджелудочная железа, желчный пузырь, селезенка при профилактическом осмотре',
                    display_name: 'УЗИ брюшной полости',
                    price: '820',
                    // tslint:disable-next-line:max-line-length
                    anons: 'Ультразвуковую диагностику смело можно назвать уникальным методом исследования. Она обладает такими свойствами как неинвазивность, возможность многократного проведения исследования без вреда для здоровья пациента, быстрота получения информации, отсутствие противопоказаний, исключение лучевой нагрузки.',
                    // tslint:disable-next-line:max-line-length
                    body: 'Проснувшись однажды утром после беспокойного сна, Грегор Замза обнаружил, что он у себя в постели превратился в страшное насекомое.',
                    // tslint:disable-next-line:max-line-length
                    memo: 'Вы должны подготовить себя к исследованию следующим образом: воздержаться от физических нагрузок, приема алкоголя и лекарств, изменений в питании в течение 24 часов до взятия крови. Вам не следует принимать пищу после ужина, лечь спать нужно в обычное для Вас время и встать не позднее, чем за час до взятия крови. Утром до сдачи анализа воздержитесь от курения и приема пищи. Взятие крови осуществляется до проведения диагностических или лечебных процедур: инъекций, общего массажа тела, эндоскопий, ЭКГ, рентгеновского обследования и т.д. Если Вы испытываете трудности с отменой лекарств, то обязательно сообщите об этом лечащему врачу. Очень важно, чтобы Вы точно соблюдали указанные рекомендации, так как только в этом случае будут получены ценные результаты исследования крови.',
                    // tslint:disable-next-line:max-line-length
                    indication: 'Анализ на свертываемость является одним из самых простых и доступных методов исследования. Пациенту нужно отказываться от приема пищи в течение двух часов перед исследованием. Результаты готовы, как правило, через несколько часов. Такое тестирование проводится в рамках комплексного обследования организма, а также при наличии кровотечений невыясненной природы и при диагностике или контроле эффективности лечения патологий костного мозга. Референсные значения зависят от возраста, а в детстве — еще и от пола.',
                    contraindic: '<ul>При некоторых заболеваниях проведение забора тромбоцитарной массы категорически запрещено: \n' +
                      '          <li>туберкулез;</li>\n' +
                      '          <li>вирус иммунодефицита;</li>\n' +
                      '          <li>раковые патологии;</li>\n' +
                      '          <li>гепатиты;</li>\n' +
                      '          <li>нарушения в работе сердца и сосудов;</li>\n' +
                      '          <li>после проведения оперативных вмешательств;</li>\n' +
                      '          <li>беременность;</li>\n' +
                      '          <li>алкоголизм;</li>\n' +
                      '          <li>недавнее введение прививки;</li>\n' +
                      '          <li>язва желудка или двенадцатиперстной кишки;</li>\n' +
                      '          <li>татуировки;</li>\n' +
                      '          <li>обширные стоматологические поражения.</li>\n' +
                      '        </ul>',
                    sale: '10',
                    doctor: [ // TODO могут ли одну услугу оказывать несколько врачей?
                      {
                        id: 1,
                        lastName: 'Замза',
                        firstName: 'Грегор',
                        middleName: '',
                        specialization: 'Уролог',
                        main_photo: {
                          id: 16,
                          url: '../../assets/img/doctors/doc-2.png'
                        }
                      }
                    ],
                    documents: [
                      {
                        id: 1,
                        title: 'Паспорт',
                        // tslint:disable-next-line:max-line-length
                        desc: 'Проснувшись однажды утром после беспокойного сна, Грегор Замза обнаружил, что он у себя в постели превратился в страшное насекомое.',
                        main_photo:
                          {
                            id: 1,
                            url: '../../assets/img/doc-img-1.png',
                          }
                      }
                    ],
                    tags: [
                      {
                        id: 1,
                        name: 'Кровь',
                        desc: 'Описание тега'
                      },
                      {
                        id: 4,
                        name: 'Железо',
                        desc: 'Описание тега'
                      },
                      {
                        id: 5,
                        name: 'Белки',
                        desc: 'Описание тега'
                      }
                    ],
                    slider: [
                      {
                        id: 1,
                        // tslint:disable-next-line:max-line-length
                        title: 'Ощущения пациента во время проведения ультразвуковой эластографии такие же, как и при обычном УЗИ. Ни боли, ни дискомфорта пациенты не испытывают.',
                        // tslint:disable-next-line:max-line-length
                        desc: 'Ощущения пациента во время проведения ультразвуковой эластографии такие же, как и при обычном УЗИ. Ни боли, ни дискомфорта пациенты не испытывают.',
                        main_photo: {
                          url: '../../assets/img/big-slide-3.png'
                        },
                      },
                      {
                        id: 2,
                        // tslint:disable-next-line:max-line-length
                        title: 'Ощущения пациента во время проведения ',
                        // tslint:disable-next-line:max-line-length
                        desc: 'Ощущения пациента во время проведения ультразвуковой эластографии такие же, как и при обычном УЗИ. Ни боли, ни дискомфорта пациенты не испытывают.',
                        main_photo: {
                          url: '../../assets/img/big-slide-4.png'
                        }
                      }
                    ],
                    catalog:
                      {
                        id: 1,
                        name: 'Клинический анализ крови',
                        desc: 'Описание Клинический анализ крови'
                      }
                  },
                  {
                    id: 3,
                    name: 'УЗИ печеночного трансплантанта с дуплексным сканированием артерий и вен',
                    display_name: 'УЗИ печеночного трансплантанта с дуплексным сканированием артерий и вен',
                    price: '1320',
                    // tslint:disable-next-line:max-line-length
                    anons: 'Ультразвуковую диагностику смело можно назвать уникальным методом исследования. Она обладает такими свойствами как неинвазивность, возможность многократного проведения исследования без вреда для здоровья пациента, быстрота получения информации, отсутствие противопоказаний, исключение лучевой нагрузки.',
                    // tslint:disable-next-line:max-line-length
                    body: 'Проснувшись однажды утром после беспокойного сна, Грегор Замза обнаружил, что он у себя в постели превратился в страшное насекомое.',
                    // tslint:disable-next-line:max-line-length
                    memo: 'Вы должны подготовить себя к исследованию следующим образом: воздержаться от физических нагрузок, приема алкоголя и лекарств, изменений в питании в течение 24 часов до взятия крови. Вам не следует принимать пищу после ужина, лечь спать нужно в обычное для Вас время и встать не позднее, чем за час до взятия крови. Утром до сдачи анализа воздержитесь от курения и приема пищи. Взятие крови осуществляется до проведения диагностических или лечебных процедур: инъекций, общего массажа тела, эндоскопий, ЭКГ, рентгеновского обследования и т.д. Если Вы испытываете трудности с отменой лекарств, то обязательно сообщите об этом лечащему врачу. Очень важно, чтобы Вы точно соблюдали указанные рекомендации, так как только в этом случае будут получены ценные результаты исследования крови.',
                    // tslint:disable-next-line:max-line-length
                    indication: 'Анализ на свертываемость является одним из самых простых и доступных методов исследования. Пациенту нужно отказываться от приема пищи в течение двух часов перед исследованием. Результаты готовы, как правило, через несколько часов. Такое тестирование проводится в рамках комплексного обследования организма, а также при наличии кровотечений невыясненной природы и при диагностике или контроле эффективности лечения патологий костного мозга. Референсные значения зависят от возраста, а в детстве — еще и от пола.',
                    contraindic: '<ul>При некоторых заболеваниях проведение забора тромбоцитарной массы категорически запрещено: \n' +
                      '          <li>туберкулез;</li>\n' +
                      '          <li>вирус иммунодефицита;</li>\n' +
                      '          <li>раковые патологии;</li>\n' +
                      '          <li>гепатиты;</li>\n' +
                      '          <li>нарушения в работе сердца и сосудов;</li>\n' +
                      '          <li>после проведения оперативных вмешательств;</li>\n' +
                      '          <li>беременность;</li>\n' +
                      '          <li>алкоголизм;</li>\n' +
                      '          <li>недавнее введение прививки;</li>\n' +
                      '          <li>язва желудка или двенадцатиперстной кишки;</li>\n' +
                      '          <li>татуировки;</li>\n' +
                      '          <li>обширные стоматологические поражения.</li>\n' +
                      '        </ul>',
                    sale: '20',
                    doctor: [ // TODO могут ли одну услугу оказывать несколько врачей?
                      {
                        id: 1,
                        lastName: 'Коновалов',
                        firstName: 'Геннадий',
                        middleName: 'Александрович',
                        specialization: 'Главный ревматолог',
                        main_photo: {
                          id: 1,
                          url: '../../assets/img/doctors/doc-3.png'
                        }
                      },
                      {
                        id: 2,
                        lastName: 'Морозова',
                        firstName: 'Екатерина',
                        middleName: 'Владимировна',
                        specialization: 'ЛОР',
                        main_photo: {
                          id: 11,
                          url: '../../assets/img/doctors/doc-5.png'
                        }
                      }
                    ],
                    documents: [
                      {
                        id: 1,
                        title: 'Паспорт',
                        // tslint:disable-next-line:max-line-length
                        desc: 'Проснувшись однажды утром после беспокойного сна, Грегор Замза обнаружил, что он у себя в постели превратился в страшное насекомое.',
                        main_photo:
                          {
                            id: 1,
                            url: '../../assets/img/doc-img-1.png',
                          }
                      },
                      {
                        id: 2,
                        title: 'Медицинский полис',
                        // tslint:disable-next-line:max-line-length
                        desc: 'Проснувшись однажды утром после беспокойного сна, Грегор Замза обнаружил, что он у себя в постели превратился в страшное насекомое.',
                        main_photo:
                          {
                            id: 1,
                            url: '../../assets/img/doc-img-2.png',
                          }
                      },
                      {
                        id: 3,
                        title: 'Направление доктора',
                        // tslint:disable-next-line:max-line-length
                        desc: 'Проснувшись однажды утром после беспокойного сна, Грегор Замза обнаружил, что он у себя в постели превратился в страшное насекомое.',
                        main_photo:
                          {
                            id: 1,
                            url: '../../assets/img/doc-img-3.png',
                          }
                      }
                    ],
                    tags: [
                      {
                        id: 1,
                        name: 'Кровь',
                        desc: 'Описание тега'
                      },
                      {
                        id: 2,
                        name: 'Глюкоза',
                        desc: 'Описание тега'
                      },
                      {
                        id: 3,
                        name: 'Витамины',
                        desc: 'Описание тега'
                      }
                    ],
                    catalog:
                      {
                        id: 1,
                        name: 'Клинический анализ крови',
                        desc: 'Описание Клинический анализ крови'
                      }
                  },
                  {
                    id: 6,
                    name: 'УЗИ печеночного трансплантанта с дуплексным сканированием артерий и вен',
                    display_name: 'УЗИ печеночного трансплантанта с дуплексным сканированием артерий и вен',
                    price: '1320',
                    // tslint:disable-next-line:max-line-length
                    anons: 'Ультразвуковую диагностику смело можно назвать уникальным методом исследования. Она обладает такими свойствами как неинвазивность, возможность многократного проведения исследования без вреда для здоровья пациента, быстрота получения информации, отсутствие противопоказаний, исключение лучевой нагрузки.',
                    // tslint:disable-next-line:max-line-length
                    body: 'Проснувшись однажды утром после беспокойного сна, Грегор Замза обнаружил, что он у себя в постели превратился в страшное насекомое.',
                    // tslint:disable-next-line:max-line-length
                    memo: 'Вы должны подготовить себя к исследованию следующим образом: воздержаться от физических нагрузок, приема алкоголя и лекарств, изменений в питании в течение 24 часов до взятия крови. Вам не следует принимать пищу после ужина, лечь спать нужно в обычное для Вас время и встать не позднее, чем за час до взятия крови. Утром до сдачи анализа воздержитесь от курения и приема пищи. Взятие крови осуществляется до проведения диагностических или лечебных процедур: инъекций, общего массажа тела, эндоскопий, ЭКГ, рентгеновского обследования и т.д. Если Вы испытываете трудности с отменой лекарств, то обязательно сообщите об этом лечащему врачу. Очень важно, чтобы Вы точно соблюдали указанные рекомендации, так как только в этом случае будут получены ценные результаты исследования крови.',
                    // tslint:disable-next-line:max-line-length
                    indication: 'Анализ на свертываемость является одним из самых простых и доступных методов исследования. Пациенту нужно отказываться от приема пищи в течение двух часов перед исследованием. Результаты готовы, как правило, через несколько часов. Такое тестирование проводится в рамках комплексного обследования организма, а также при наличии кровотечений невыясненной природы и при диагностике или контроле эффективности лечения патологий костного мозга. Референсные значения зависят от возраста, а в детстве — еще и от пола.',
                    contraindic: '<ul>При некоторых заболеваниях проведение забора тромбоцитарной массы категорически запрещено: \n' +
                      '          <li>туберкулез;</li>\n' +
                      '          <li>вирус иммунодефицита;</li>\n' +
                      '          <li>раковые патологии;</li>\n' +
                      '          <li>гепатиты;</li>\n' +
                      '          <li>нарушения в работе сердца и сосудов;</li>\n' +
                      '          <li>после проведения оперативных вмешательств;</li>\n' +
                      '          <li>беременность;</li>\n' +
                      '          <li>алкоголизм;</li>\n' +
                      '          <li>недавнее введение прививки;</li>\n' +
                      '          <li>язва желудка или двенадцатиперстной кишки;</li>\n' +
                      '          <li>татуировки;</li>\n' +
                      '          <li>обширные стоматологические поражения.</li>\n' +
                      '        </ul>',
                    sale: '20',
                    tags: [
                      {
                        id: 1,
                        name: 'Кровь',
                        desc: 'Описание тега'
                      },
                      {
                        id: 2,
                        name: 'Глюкоза',
                        desc: 'Описание тега'
                      },
                      {
                        id: 3,
                        name: 'Витамины',
                        desc: 'Описание тега'
                      }
                    ],
                    catalog:
                      {
                        id: 1,
                        name: 'Анализы крови',
                        desc: 'Описание Клинический анализ крови'
                      }
                  },
                  {
                    id: 7,
                    name: 'Анализ крови артерий и вен',
                    display_name: 'Анализ крови артерий и вен',
                    price: '1320',
                    // tslint:disable-next-line:max-line-length
                    anons: 'Ультразвуковую диагностику смело можно назвать уникальным методом исследования. Она обладает такими свойствами как неинвазивность, возможность многократного проведения исследования без вреда для здоровья пациента, быстрота получения информации, отсутствие противопоказаний, исключение лучевой нагрузки.',
                    // tslint:disable-next-line:max-line-length
                    body: 'Проснувшись однажды утром после беспокойного сна, Грегор Замза обнаружил.',
                    // tslint:disable-next-line:max-line-length
                    memo: 'Вы должны подготовить себя к исследованию следующим образом: воздержаться от физических нагрузок, приема алкоголя и лекарств, изменений в питании в течение 24 часов до взятия крови. Вам не следует принимать пищу после ужина, лечь спать нужно в обычное для Вас время и встать не позднее, чем за час до взятия крови. Утром до сдачи анализа воздержитесь от курения и приема пищи. Взятие крови осуществляется до проведения диагностических или лечебных процедур: инъекций, общего массажа тела, эндоскопий, ЭКГ, рентгеновского обследования и т.д. Если Вы испытываете трудности с отменой лекарств, то обязательно сообщите об этом лечащему врачу. Очень важно, чтобы Вы точно соблюдали указанные рекомендации, так как только в этом случае будут получены ценные результаты исследования крови.',
                    // tslint:disable-next-line:max-line-length
                    indication: 'Анализ на свертываемость является одним из самых простых и доступных методов исследования. Пациенту нужно отказываться от приема пищи в течение двух часов перед исследованием. Результаты готовы, как правило, через несколько часов. Такое тестирование проводится в рамках комплексного обследования организма, а также при наличии кровотечений невыясненной природы и при диагностике или контроле эффективности лечения патологий костного мозга. Референсные значения зависят от возраста, а в детстве — еще и от пола.',
                    contraindic: '<ul>При некоторых заболеваниях проведение забора тромбоцитарной массы категорически запрещено: \n' +
                      '          <li>туберкулез;</li>\n' +
                      '          <li>вирус иммунодефицита;</li>\n' +
                      '          <li>раковые патологии;</li>\n' +
                      '          <li>гепатиты;</li>\n' +
                      '          <li>нарушения в работе сердца и сосудов;</li>\n' +
                      '          <li>после проведения оперативных вмешательств;</li>\n' +
                      '          <li>беременность;</li>\n' +
                      '          <li>алкоголизм;</li>\n' +
                      '          <li>недавнее введение прививки;</li>\n' +
                      '          <li>язва желудка или двенадцатиперстной кишки;</li>\n' +
                      '          <li>татуировки;</li>\n' +
                      '          <li>обширные стоматологические поражения.</li>\n' +
                      '        </ul>',
                    sale: '',
                    documents: [
                      {
                        id: 1,
                        title: 'Паспорт',
                        // tslint:disable-next-line:max-line-length
                        desc: 'Проснувшись однажды утром после беспокойного сна, Грегор Замза обнаружил, что он у себя в постели превратился в страшное насекомое.',
                        main_photo:
                          {
                            id: 1,
                            url: '../../assets/img/doc-img-1.png',
                          }
                      }
                    ],
                    tags: [
                      {
                        id: 1,
                        name: 'Кровь',
                        desc: 'Описание тега'
                      },
                      {
                        id: 2,
                        name: 'Глюкоза',
                        desc: 'Описание тега'
                      },
                      {
                        id: 3,
                        name: 'Гормоны',
                        desc: 'Описание тега'
                      }
                    ],
                    catalog:
                      {
                        id: 1,
                        name: 'Анализы крови',
                        desc: 'Описание Клинический анализ крови'
                      }
                  }
                ],
              },
              {
                id: 23,
                name: 'Эндоскопия',
                main_photo: {
                  id: 1,
                  url: '../../assets/img/direction2-1.png',
                },
                short_description: 'Краткое описание Эндоскопия',
                description: 'Полное описание Эндоскопия',
                icon: null,
                units: [
                  {
                    id: 2,
                    name: 'Поликлиника №1',
                    address: {
                      id: 1,
                      city: 'Красноярск',
                      street: 'Вавилова',
                      house: '28',
                      building: '',
                      ymapcfg: '../../assets/img/contacts-card-map.png',
                      postcode: '660025',
                      region: 'Красноярский край',
                      mailbox: '6545'
                    },
                    phones: [
                      {
                        id: 7,
                        number: '+7 (391) 274-31-64',
                        description: 'круглосуточно'
                      },
                      {
                        id: 8,
                        number: '+7 (391) 257-64-60',
                        description: 'с 9:00 до 17:00'
                      }
                    ],
                  },
                ],
                services: [
                  {
                    id: 5,
                    name: 'УЗИ печеночного трансплантанта с дуплексным сканированием артерий и вен',
                    display_name: 'УЗИ печеночного трансплантанта с дуплексным сканированием артерий и вен',
                    price: '1320',
                    // tslint:disable-next-line:max-line-length
                    anons: 'Ультразвуковую диагностику смело можно назвать уникальным методом исследования. Она обладает такими свойствами как неинвазивность, возможность многократного проведения исследования без вреда для здоровья пациента, быстрота получения информации, отсутствие противопоказаний, исключение лучевой нагрузки.',
                    // tslint:disable-next-line:max-line-length
                    body: 'Проснувшись однажды утром после беспокойного сна, Грегор Замза обнаружил, что он у себя в постели превратился в страшное насекомое.',
                    // tslint:disable-next-line:max-line-length
                    memo: 'Вы должны подготовить себя к исследованию следующим образом: воздержаться от физических нагрузок, приема алкоголя и лекарств, изменений в питании в течение 24 часов до взятия крови. Вам не следует принимать пищу после ужина, лечь спать нужно в обычное для Вас время и встать не позднее, чем за час до взятия крови. Утром до сдачи анализа воздержитесь от курения и приема пищи. Взятие крови осуществляется до проведения диагностических или лечебных процедур: инъекций, общего массажа тела, эндоскопий, ЭКГ, рентгеновского обследования и т.д. Если Вы испытываете трудности с отменой лекарств, то обязательно сообщите об этом лечащему врачу. Очень важно, чтобы Вы точно соблюдали указанные рекомендации, так как только в этом случае будут получены ценные результаты исследования крови.',
                    // tslint:disable-next-line:max-line-length
                    indication: 'Анализ на свертываемость является одним из самых простых и доступных методов исследования. Пациенту нужно отказываться от приема пищи в течение двух часов перед исследованием. Результаты готовы, как правило, через несколько часов. Такое тестирование проводится в рамках комплексного обследования организма, а также при наличии кровотечений невыясненной природы и при диагностике или контроле эффективности лечения патологий костного мозга. Референсные значения зависят от возраста, а в детстве — еще и от пола.',
                    contraindic: '<ul>При некоторых заболеваниях проведение забора тромбоцитарной массы категорически запрещено: \n' +
                      '          <li>туберкулез;</li>\n' +
                      '          <li>вирус иммунодефицита;</li>\n' +
                      '          <li>раковые патологии;</li>\n' +
                      '          <li>гепатиты;</li>\n' +
                      '          <li>нарушения в работе сердца и сосудов;</li>\n' +
                      '          <li>после проведения оперативных вмешательств;</li>\n' +
                      '          <li>беременность;</li>\n' +
                      '          <li>алкоголизм;</li>\n' +
                      '          <li>недавнее введение прививки;</li>\n' +
                      '          <li>язва желудка или двенадцатиперстной кишки;</li>\n' +
                      '          <li>татуировки;</li>\n' +
                      '          <li>обширные стоматологические поражения.</li>\n' +
                      '        </ul>',
                    sale: '',
                    documents: [
                      {
                        id: 1,
                        title: 'Паспорт',
                        // tslint:disable-next-line:max-line-length
                        desc: 'Проснувшись однажды утром после беспокойного сна, Грегор Замза обнаружил, что он у себя в постели превратился в страшное насекомое.',
                        main_photo:
                          {
                            id: 1,
                            url: '../../assets/img/doc-img-1.png',
                          }
                      }
                    ],
                    tags: [
                      {
                        id: 1,
                        name: 'Кровь',
                        desc: 'Описание тега'
                      },
                      {
                        id: 2,
                        name: 'Глюкоза',
                        desc: 'Описание тега'
                      },
                      {
                        id: 3,
                        name: 'Витамины',
                        desc: 'Описание тега'
                      }
                    ],
                    catalog:
                      {
                        id: 1,
                        name: 'УЗИ - диагностические исследования',
                        desc: 'Описание Клинический анализ крови'
                      }
                  },
                  {
                    id: 6,
                    name: 'УЗИ печеночного трансплантанта с дуплексным сканированием артерий и вен',
                    display_name: 'УЗИ печеночного трансплантанта с дуплексным сканированием артерий и вен',
                    price: '1320',
                    // tslint:disable-next-line:max-line-length
                    anons: 'Ультразвуковую диагностику смело можно назвать уникальным методом исследования. Она обладает такими свойствами как неинвазивность, возможность многократного проведения исследования без вреда для здоровья пациента, быстрота получения информации, отсутствие противопоказаний, исключение лучевой нагрузки.',
                    // tslint:disable-next-line:max-line-length
                    body: 'Проснувшись однажды утром после беспокойного сна, Грегор Замза обнаружил, что он у себя в постели превратился в страшное насекомое.',
                    // tslint:disable-next-line:max-line-length
                    memo: 'Вы должны подготовить себя к исследованию следующим образом: воздержаться от физических нагрузок, приема алкоголя и лекарств, изменений в питании в течение 24 часов до взятия крови. Вам не следует принимать пищу после ужина, лечь спать нужно в обычное для Вас время и встать не позднее, чем за час до взятия крови. Утром до сдачи анализа воздержитесь от курения и приема пищи. Взятие крови осуществляется до проведения диагностических или лечебных процедур: инъекций, общего массажа тела, эндоскопий, ЭКГ, рентгеновского обследования и т.д. Если Вы испытываете трудности с отменой лекарств, то обязательно сообщите об этом лечащему врачу. Очень важно, чтобы Вы точно соблюдали указанные рекомендации, так как только в этом случае будут получены ценные результаты исследования крови.',
                    // tslint:disable-next-line:max-line-length
                    indication: 'Анализ на свертываемость является одним из самых простых и доступных методов исследования. Пациенту нужно отказываться от приема пищи в течение двух часов перед исследованием. Результаты готовы, как правило, через несколько часов. Такое тестирование проводится в рамках комплексного обследования организма, а также при наличии кровотечений невыясненной природы и при диагностике или контроле эффективности лечения патологий костного мозга. Референсные значения зависят от возраста, а в детстве — еще и от пола.',
                    contraindic: '<ul>При некоторых заболеваниях проведение забора тромбоцитарной массы категорически запрещено: \n' +
                      '          <li>туберкулез;</li>\n' +
                      '          <li>вирус иммунодефицита;</li>\n' +
                      '          <li>раковые патологии;</li>\n' +
                      '          <li>гепатиты;</li>\n' +
                      '          <li>нарушения в работе сердца и сосудов;</li>\n' +
                      '          <li>после проведения оперативных вмешательств;</li>\n' +
                      '          <li>беременность;</li>\n' +
                      '          <li>алкоголизм;</li>\n' +
                      '          <li>недавнее введение прививки;</li>\n' +
                      '          <li>язва желудка или двенадцатиперстной кишки;</li>\n' +
                      '          <li>татуировки;</li>\n' +
                      '          <li>обширные стоматологические поражения.</li>\n' +
                      '        </ul>',
                    sale: '20',
                    tags: [
                      {
                        id: 1,
                        name: 'Кровь',
                        desc: 'Описание тега'
                      },
                      {
                        id: 2,
                        name: 'Глюкоза',
                        desc: 'Описание тега'
                      },
                      {
                        id: 3,
                        name: 'Витамины',
                        desc: 'Описание тега'
                      }
                    ],
                    catalog:
                      {
                        id: 1,
                        name: 'Анализы крови',
                        desc: 'Описание Клинический анализ крови'
                      }
                  },
                  {
                    id: 7,
                    name: 'Анализ крови артерий и вен',
                    display_name: 'Анализ крови артерий и вен',
                    price: '1320',
                    // tslint:disable-next-line:max-line-length
                    anons: 'Ультразвуковую диагностику смело можно назвать уникальным методом исследования. Она обладает такими свойствами как неинвазивность, возможность многократного проведения исследования без вреда для здоровья пациента, быстрота получения информации, отсутствие противопоказаний, исключение лучевой нагрузки.',
                    // tslint:disable-next-line:max-line-length
                    body: 'Проснувшись однажды утром после беспокойного сна, Грегор Замза обнаружил.',
                    // tslint:disable-next-line:max-line-length
                    memo: 'Вы должны подготовить себя к исследованию следующим образом: воздержаться от физических нагрузок, приема алкоголя и лекарств, изменений в питании в течение 24 часов до взятия крови. Вам не следует принимать пищу после ужина, лечь спать нужно в обычное для Вас время и встать не позднее, чем за час до взятия крови. Утром до сдачи анализа воздержитесь от курения и приема пищи. Взятие крови осуществляется до проведения диагностических или лечебных процедур: инъекций, общего массажа тела, эндоскопий, ЭКГ, рентгеновского обследования и т.д. Если Вы испытываете трудности с отменой лекарств, то обязательно сообщите об этом лечащему врачу. Очень важно, чтобы Вы точно соблюдали указанные рекомендации, так как только в этом случае будут получены ценные результаты исследования крови.',
                    // tslint:disable-next-line:max-line-length
                    indication: 'Анализ на свертываемость является одним из самых простых и доступных методов исследования. Пациенту нужно отказываться от приема пищи в течение двух часов перед исследованием. Результаты готовы, как правило, через несколько часов. Такое тестирование проводится в рамках комплексного обследования организма, а также при наличии кровотечений невыясненной природы и при диагностике или контроле эффективности лечения патологий костного мозга. Референсные значения зависят от возраста, а в детстве — еще и от пола.',
                    contraindic: '<ul>При некоторых заболеваниях проведение забора тромбоцитарной массы категорически запрещено: \n' +
                      '          <li>туберкулез;</li>\n' +
                      '          <li>вирус иммунодефицита;</li>\n' +
                      '          <li>раковые патологии;</li>\n' +
                      '          <li>гепатиты;</li>\n' +
                      '          <li>нарушения в работе сердца и сосудов;</li>\n' +
                      '          <li>после проведения оперативных вмешательств;</li>\n' +
                      '          <li>беременность;</li>\n' +
                      '          <li>алкоголизм;</li>\n' +
                      '          <li>недавнее введение прививки;</li>\n' +
                      '          <li>язва желудка или двенадцатиперстной кишки;</li>\n' +
                      '          <li>татуировки;</li>\n' +
                      '          <li>обширные стоматологические поражения.</li>\n' +
                      '        </ul>',
                    sale: '',
                    documents: [
                      {
                        id: 1,
                        title: 'Паспорт',
                        // tslint:disable-next-line:max-line-length
                        desc: 'Проснувшись однажды утром после беспокойного сна, Грегор Замза обнаружил, что он у себя в постели превратился в страшное насекомое.',
                        main_photo:
                          {
                            id: 1,
                            url: '../../assets/img/doc-img-1.png',
                          }
                      }
                    ],
                    tags: [
                      {
                        id: 1,
                        name: 'Кровь',
                        desc: 'Описание тега'
                      },
                      {
                        id: 2,
                        name: 'Глюкоза',
                        desc: 'Описание тега'
                      },
                      {
                        id: 3,
                        name: 'Гормоны',
                        desc: 'Описание тега'
                      }
                    ],
                    catalog:
                      {
                        id: 1,
                        name: 'Анализы крови',
                        desc: 'Описание Клинический анализ крови'
                      }
                  }
                ],
              }
            ],
            directions: [
              {
                id: 60,
                name: 'root',
                short_description: null,
                description: null,
                icon: null
              }
            ],
          },
        ],
        services: [
          {
            id: 1,
            name: 'УЗИ печеночного трансплантанта с дуплексным сканированием артерий и вен',
            display_name: 'УЗИ печеночного трансплантанта с дуплексным сканированием артерий и вен',
            price: '1320',
            // tslint:disable-next-line:max-line-length
            anons: 'Ультразвуковую диагностику смело можно назвать уникальным методом исследования. Она обладает такими свойствами как неинвазивность, возможность многократного проведения исследования без вреда для здоровья пациента, быстрота получения информации, отсутствие противопоказаний, исключение лучевой нагрузки.',
            // tslint:disable-next-line:max-line-length
            body: 'Проснувшись однажды утром после беспокойного сна, Грегор Замза обнаружил, что он у себя в постели превратился в страшное насекомое.',
            // tslint:disable-next-line:max-line-length
            memo: 'Вы должны подготовить себя к исследованию следующим образом: воздержаться от физических нагрузок, приема алкоголя и лекарств, изменений в питании в течение 24 часов до взятия крови. Вам не следует принимать пищу после ужина, лечь спать нужно в обычное для Вас время и встать не позднее, чем за час до взятия крови. Утром до сдачи анализа воздержитесь от курения и приема пищи. Взятие крови осуществляется до проведения диагностических или лечебных процедур: инъекций, общего массажа тела, эндоскопий, ЭКГ, рентгеновского обследования и т.д. Если Вы испытываете трудности с отменой лекарств, то обязательно сообщите об этом лечащему врачу. Очень важно, чтобы Вы точно соблюдали указанные рекомендации, так как только в этом случае будут получены ценные результаты исследования крови.',
            // tslint:disable-next-line:max-line-length
            indication: 'Анализ на свертываемость является одним из самых простых и доступных методов исследования. Пациенту нужно отказываться от приема пищи в течение двух часов перед исследованием. Результаты готовы, как правило, через несколько часов. Такое тестирование проводится в рамках комплексного обследования организма, а также при наличии кровотечений невыясненной природы и при диагностике или контроле эффективности лечения патологий костного мозга. Референсные значения зависят от возраста, а в детстве — еще и от пола.',
            contraindic: '<ul>При некоторых заболеваниях проведение забора тромбоцитарной массы категорически запрещено: \n' +
              '          <li>туберкулез;</li>\n' +
              '          <li>вирус иммунодефицита;</li>\n' +
              '          <li>раковые патологии;</li>\n' +
              '          <li>гепатиты;</li>\n' +
              '          <li>нарушения в работе сердца и сосудов;</li>\n' +
              '          <li>после проведения оперативных вмешательств;</li>\n' +
              '          <li>беременность;</li>\n' +
              '          <li>алкоголизм;</li>\n' +
              '          <li>недавнее введение прививки;</li>\n' +
              '          <li>язва желудка или двенадцатиперстной кишки;</li>\n' +
              '          <li>татуировки;</li>\n' +
              '          <li>обширные стоматологические поражения.</li>\n' +
              '        </ul>',
            sale: '',
            doctor: [ // TODO могут ли одну услугу оказывать несколько врачей?
              {
                id: 2,
                lastName: 'Морозова',
                firstName: 'Екатерина',
                middleName: 'Владимировна',
                specialization: 'ЛОР',
                main_photo: {
                  id: 11,
                  url: '../../assets/img/doctors/doc-5.png'
                }
              }
            ],
            documents: [
              {
                id: 1,
                title: 'Паспорт',
                // tslint:disable-next-line:max-line-length
                desc: 'Проснувшись однажды утром после беспокойного сна, Грегор Замза обнаружил, что он у себя в постели превратился в страшное насекомое.',
                main_photo:
                  {
                    id: 1,
                    url: '../../assets/img/doc-img-1.png',
                  }
              },
              {
                id: 2,
                title: 'Медицинский полис',
                // tslint:disable-next-line:max-line-length
                desc: 'Проснувшись однажды утром после беспокойного сна, Грегор Замза обнаружил, что он у себя в постели превратился в страшное насекомое.',
                main_photo:
                  {
                    id: 1,
                    url: '../../assets/img/doc-img-2.png',
                  }
              },
              {
                id: 3,
                title: 'Направление доктора',
                // tslint:disable-next-line:max-line-length
                desc: 'Проснувшись однажды утром после беспокойного сна, Грегор Замза обнаружил, что он у себя в постели превратился в страшное насекомое.',
                main_photo:
                  {
                    id: 1,
                    url: '../../assets/img/doc-img-3.png',
                  }
              }
            ],
            tags: [
              {
                id: 1,
                name: 'Кровь',
                desc: 'Описание тега'
              },
              {
                id: 2,
                name: 'Глюкоза',
                desc: 'Описание тега'
              },
              {
                id: 3,
                name: 'Витамины',
                desc: 'Описание тега'
              }
            ],
            slider: [
              {
                id: 1,
                // tslint:disable-next-line:max-line-length
                title: 'Ощущения пациента во время проведения ультразвуковой эластографии такие же, как и при обычном УЗИ. Ни боли, ни дискомфорта пациенты не испытывают.',
                // tslint:disable-next-line:max-line-length
                desc: 'Ощущения пациента во время проведения ультразвуковой эластографии такие же, как и при обычном УЗИ. Ни боли, ни дискомфорта пациенты не испытывают.',
                main_photo: {
                  url: '../../assets/img/big-slide-3.png'
                },
              },
              {
                id: 2,
                // tslint:disable-next-line:max-line-length
                title: 'Ощущения пациента во время проведения ',
                // tslint:disable-next-line:max-line-length
                desc: 'Ощущения пациента во время проведения ультразвуковой эластографии такие же, как и при обычном УЗИ. Ни боли, ни дискомфорта пациенты не испытывают.',
                main_photo: {
                  url: '../../assets/img/big-slide-4.png'
                }
              },
              {
                id: 3,
                // tslint:disable-next-line:max-line-length
                title: 'Ощущения пациента во время проведения ультразвуковой эластографии такие же, как и при обычном УЗИ. Ни боли, ни дискомфорта пациенты не испытывают.',
                // tslint:disable-next-line:max-line-length
                desc: 'Ощущения пациента во время проведения ультразвуковой эластографии такие же, как и при обычном УЗИ. Ни боли, ни дискомфорта пациенты не испытывают.',
                main_photo: {
                  url: '../../assets/img/big-slide-3.png'
                },
              },
              {
                id: 4,
                // tslint:disable-next-line:max-line-length
                title: 'Ощущения пациента во время проведения ',
                // tslint:disable-next-line:max-line-length
                desc: 'Ощущения пациента во время проведения ультразвуковой эластографии такие же, как и при обычном УЗИ. Ни боли, ни дискомфорта пациенты не испытывают.',
                main_photo: {
                  url: '../../assets/img/big-slide-4.png'
                }
              }
            ],
            catalog:
              {
                id: 1,
                name: 'Клинический анализ крови',
                desc: 'Описание Клинический анализ крови'
              }
          },
          {
            id: 2,
            name: 'Вторая услуга и она со скидкой',
            display_name: 'Вторая услуга и она со скидкой. УЗИ печеночного трансплантанта с дуплексным сканированием артерий и вен',
            price: '2502',
            // tslint:disable-next-line:max-line-length
            anons: 'Ультразвуковую диагностику смело можно назвать уникальным методом исследования. Она обладает такими свойствами как неинвазивность, возможность многократного проведения исследования без вреда для здоровья пациента, быстрота получения информации, отсутствие противопоказаний, исключение лучевой нагрузки.',
            // tslint:disable-next-line:max-line-length
            body: 'Проснувшись однажды утром после беспокойного сна, Грегор Замза обнаружил, что он у себя в постели превратился в страшное насекомое.',
            // tslint:disable-next-line:max-line-length
            memo: 'Вы должны подготовить себя к исследованию следующим образом: воздержаться от физических нагрузок, приема алкоголя и лекарств, изменений в питании в течение 24 часов до взятия крови. Вам не следует принимать пищу после ужина, лечь спать нужно в обычное для Вас время и встать не позднее, чем за час до взятия крови. Утром до сдачи анализа воздержитесь от курения и приема пищи. Взятие крови осуществляется до проведения диагностических или лечебных процедур: инъекций, общего массажа тела, эндоскопий, ЭКГ, рентгеновского обследования и т.д. Если Вы испытываете трудности с отменой лекарств, то обязательно сообщите об этом лечащему врачу. Очень важно, чтобы Вы точно соблюдали указанные рекомендации, так как только в этом случае будут получены ценные результаты исследования крови.',
            // tslint:disable-next-line:max-line-length
            indication: 'Анализ на свертываемость является одним из самых простых и доступных методов исследования. Пациенту нужно отказываться от приема пищи в течение двух часов перед исследованием. Результаты готовы, как правило, через несколько часов. Такое тестирование проводится в рамках комплексного обследования организма, а также при наличии кровотечений невыясненной природы и при диагностике или контроле эффективности лечения патологий костного мозга. Референсные значения зависят от возраста, а в детстве — еще и от пола.',
            contraindic: '<ul>При некоторых заболеваниях проведение забора тромбоцитарной массы категорически запрещено: \n' +
              '          <li>туберкулез;</li>\n' +
              '          <li>вирус иммунодефицита;</li>\n' +
              '          <li>раковые патологии;</li>\n' +
              '          <li>гепатиты;</li>\n' +
              '          <li>нарушения в работе сердца и сосудов;</li>\n' +
              '          <li>после проведения оперативных вмешательств;</li>\n' +
              '          <li>беременность;</li>\n' +
              '          <li>алкоголизм;</li>\n' +
              '          <li>недавнее введение прививки;</li>\n' +
              '          <li>язва желудка или двенадцатиперстной кишки;</li>\n' +
              '          <li>татуировки;</li>\n' +
              '          <li>обширные стоматологические поражения.</li>\n' +
              '        </ul>',
            sale: '13',
            doctor: [ // TODO могут ли одну услугу оказывать несколько врачей?
              {
                id: 2,
                lastName: 'Морозова',
                firstName: 'Екатерина',
                middleName: 'Владимировна',
                specialization: 'ЛОР',
                main_photo: {
                  id: 11,
                  url: '../../assets/img/doctors/doc-5.png'
                }
              }
            ],
            documents: [
              {
                id: 1,
                title: 'Паспорт',
                // tslint:disable-next-line:max-line-length
                desc: 'Проснувшись однажды утром после беспокойного сна, Грегор Замза обнаружил, что он у себя в постели превратился в страшное насекомое.',
                main_photo:
                  {
                    id: 1,
                    url: '../../assets/img/doc-img-1.png',
                  }
              },
              {
                id: 2,
                title: 'Медицинский полис',
                // tslint:disable-next-line:max-line-length
                desc: 'Проснувшись однажды утром после беспокойного сна, Грегор Замза обнаружил, что он у себя в постели превратился в страшное насекомое.',
                main_photo:
                  {
                    id: 1,
                    url: '../../assets/img/doc-img-2.png',
                  }
              },
              {
                id: 3,
                title: 'Направление доктора',
                // tslint:disable-next-line:max-line-length
                desc: 'Проснувшись однажды утром после беспокойного сна, Грегор Замза обнаружил, что он у себя в постели превратился в страшное насекомое.',
                main_photo:
                  {
                    id: 1,
                    url: '../../assets/img/doc-img-3.png',
                  }
              }
            ],
            tags: [
              {
                id: 1,
                name: 'Кровь',
                desc: 'Описание тега'
              },
              {
                id: 2,
                name: 'Глюкоза',
                desc: 'Описание тега'
              },
              {
                id: 3,
                name: 'Витамины',
                desc: 'Описание тега'
              }
            ],
            slider: [
              {
                id: 1,
                // tslint:disable-next-line:max-line-length
                title: 'Ощущения пациента во время проведения ультразвуковой эластографии такие же, как и при обычном УЗИ. Ни боли, ни дискомфорта пациенты не испытывают.',
                // tslint:disable-next-line:max-line-length
                desc: 'Ощущения пациента во время проведения ультразвуковой эластографии такие же, как и при обычном УЗИ. Ни боли, ни дискомфорта пациенты не испытывают.',
                main_photo: {
                  url: '../../assets/img/big-slide-3.png'
                },
              },
              {
                id: 2,
                // tslint:disable-next-line:max-line-length
                title: 'Ощущения пациента во время проведения ',
                // tslint:disable-next-line:max-line-length
                desc: 'Ощущения пациента во время проведения ультразвуковой эластографии такие же, как и при обычном УЗИ. Ни боли, ни дискомфорта пациенты не испытывают.',
                main_photo: {
                  url: '../../assets/img/big-slide-4.png'
                }
              },
              {
                id: 3,
                // tslint:disable-next-line:max-line-length
                title: 'Ощущения пациента во время проведения ультразвуковой эластографии такие же, как и при обычном УЗИ. Ни боли, ни дискомфорта пациенты не испытывают.',
                // tslint:disable-next-line:max-line-length
                desc: 'Ощущения пациента во время проведения ультразвуковой эластографии такие же, как и при обычном УЗИ. Ни боли, ни дискомфорта пациенты не испытывают.',
                main_photo: {
                  url: '../../assets/img/big-slide-3.png'
                },
              },
              {
                id: 4,
                // tslint:disable-next-line:max-line-length
                title: 'Ощущения пациента во время проведения ',
                // tslint:disable-next-line:max-line-length
                desc: 'Ощущения пациента во время проведения ультразвуковой эластографии такие же, как и при обычном УЗИ. Ни боли, ни дискомфорта пациенты не испытывают.',
                main_photo: {
                  url: '../../assets/img/big-slide-4.png'
                }
              }
            ],
            catalog:
              {
                id: 1,
                name: 'Клинический анализ крови',
                desc: 'Описание Клинический анализ крови'
              }
          },

        ],
        tags: {
          id: 1,
          name: 'Печень',
        },
      },
      {
        id: 3,
        name: 'Болезни периодонта',
        description: 'Болезни периодонта. Что такое геморрой? Такой вопрос возникает у людей, которые впервые испытали дискомфорт в заднем проходе. Геморрой — это увеличение кавернозных тел (сосудов), сопровождающееся их истончением, деформацией и формированием геморроидальных узлов. В народе часто говорят, что геморроидальный недуг — это варикозная болезнь сосудов анального прохода. Деструкция стенок кишечника может провоцировать анальные кровотечения и выпадение узлов из ануса.',
        anonce: 'Болезни периодонта. УЗИ эластография (соноэластография) – дополнительная технология, используемая во время традиционного УЗИ, которая даёт возможность оценивать эластичность тканей исследуемого органа.',
        bodyText: '<div class="paragraph">Болезни периодонта. Все ткани человека, в том числе и повреждённые тем или иным патологическим процессом, имеют определённую эластичность (жёсткость). Нераковые ткани (здоровые, доброкачественные опухоли, воспалительный процесс) имеют высокую эластичность. В отличии от них, ткани злокачественных новообразований значительно менее эластичны (более жёсткие, жёсткость некоторых из них в несколько раз выше нормы). Во время ультразвуковой эластографии, ультразвуковой луч, излучаемый и воспринимаемый специальным датчиком, словно врач, «ощупывает» ткани исследуемого органа и с помощью программы оценивает их эластичность (жёсткость), что невозможно при обычном УЗИ. После компьютерной обработки изображение передаётся на экран монитора.</div>\n' +
          '            <div class="paragraph">Соноэластографический метод помогает выполнить диагностику злокачественных образований молочной железы, органов мошонки, узловых образований щитовидной железы, поверхностно расположенных лимфатических узлов, мягких тканей и других органов.</div>\n' +
          '            <div class="my-4 my-md-5"><img src="../../assets/img/big-slide-3.png" alt=""/></div>\n' +
          '            <div class="paragraph">Соноэластографический метод помогает выполнить диагностику злокачественных образований молочной железы, органов мошонки, узловых образований щитовидной железы, поверхностно расположенных лимфатических узлов, мягких тканей и других органов.</div>\n' +
          '            <div class="paragraph">Ощущения пациента во время проведения ультразвуковой эластографии такие же, как и при обычном УЗИ. Ни боли, ни дискомфорта пациенты не испытывают.</div>',
        catalog: {
          id: 1,
          name: 'Какая-то диагностика'
        },
        directions: [
          {
            id: 20,
            name: 'Болезни периодонта. Диагностика',
            short_description: 'Проснувшись однажды утром после беспокойного сна, Грегор Замза обнаружил, что он у себя в постели превратился в страшное насекомое. Лежа на панцирнотвердой спине, он видел, стоило ему приподнять голову, свой коричневый, выпуклый, разделенный дугообразными чешуйками живот, на верхушке которого еле держалось готовое вот-вот окончательно сползти одеяло.',
            description: 'Полное описание Диагностика. Его многочисленные, убого тонкие по сравнению с остальным телом ножки беспомощно копошились у него перед глазами. «Что со мной случилось? » – подумал он. Это не было сном. Его комната, настоящая, разве что слишком маленькая, но обычная комната, мирно покоилась в своих четырех хорошо знакомых стенах.',
            main_photo: { // TODO добавить
              id: 1,
              url: '../../assets/img/direction-2.png',
            },
            subdirections: [
              {
                id: 21,
                name: 'Аллергические риниты. Рентген',
                main_photo: {
                  id: 1,
                  url: '../../assets/img/direction2-1.png',
                },
                short_description: 'Краткое описание Рентген',
                description: 'Полное описание Рентген',
                icon: null,
                units: [
                  {
                    id: 2,
                    name: 'Аллергические риниты. Поликлиника №1',
                    address: {
                      id: 1,
                      city: 'Красноярск',
                      street: 'Вавилова',
                      house: '28',
                      building: '',
                      ymapcfg: '../../assets/img/contacts-card-map.png',
                      postcode: '660025',
                      region: 'Красноярский край',
                      mailbox: '6545'
                    },
                    phones: [
                      {
                        id: 7,
                        number: '+7 (391) 274-31-64',
                        description: 'круглосуточно'
                      },
                      {
                        id: 8,
                        number: '+7 (391) 257-64-60',
                        description: 'с 9:00 до 17:00'
                      }
                    ],
                  },
                  {
                    id: 3,
                    name: 'Поликлиника №3',
                    address: {
                      id: 1,
                      city: 'Красноярск',
                      street: 'Коломенская',
                      house: '28',
                      building: '',
                      ymapcfg: '../../assets/img/contacts-card-map.png',
                      postcode: '660025',
                      region: 'Красноярский край',
                      mailbox: '6545'
                    },
                    phones: [
                      {
                        id: 7,
                        number: '+7 (391) 274-31-64',
                        description: 'круглосуточно'
                      },
                      {
                        id: 8,
                        number: '+7 (391) 257-64-60',
                        description: 'с 9:00 до 17:00'
                      }
                    ],
                  },
                ],
                services: [
                  {
                    id: 1,
                    name: 'УЗИ печеночного трансплантанта с дуплексным сканированием артерий и вен',
                    display_name: 'УЗИ печеночного трансплантанта с дуплексным сканированием артерий и вен',
                    price: '1320',
                    // tslint:disable-next-line:max-line-length
                    anons: 'Ультразвуковую диагностику смело можно назвать уникальным методом исследования. Она обладает такими свойствами как неинвазивность, возможность многократного проведения исследования без вреда для здоровья пациента, быстрота получения информации, отсутствие противопоказаний, исключение лучевой нагрузки.',
                    // tslint:disable-next-line:max-line-length
                    body: 'Проснувшись однажды утром после беспокойного сна, Грегор Замза обнаружил, что он у себя в постели превратился в страшное насекомое.',
                    // tslint:disable-next-line:max-line-length
                    memo: 'Вы должны подготовить себя к исследованию следующим образом: воздержаться от физических нагрузок, приема алкоголя и лекарств, изменений в питании в течение 24 часов до взятия крови. Вам не следует принимать пищу после ужина, лечь спать нужно в обычное для Вас время и встать не позднее, чем за час до взятия крови. Утром до сдачи анализа воздержитесь от курения и приема пищи. Взятие крови осуществляется до проведения диагностических или лечебных процедур: инъекций, общего массажа тела, эндоскопий, ЭКГ, рентгеновского обследования и т.д. Если Вы испытываете трудности с отменой лекарств, то обязательно сообщите об этом лечащему врачу. Очень важно, чтобы Вы точно соблюдали указанные рекомендации, так как только в этом случае будут получены ценные результаты исследования крови.',
                    // tslint:disable-next-line:max-line-length
                    indication: 'Анализ на свертываемость является одним из самых простых и доступных методов исследования. Пациенту нужно отказываться от приема пищи в течение двух часов перед исследованием. Результаты готовы, как правило, через несколько часов. Такое тестирование проводится в рамках комплексного обследования организма, а также при наличии кровотечений невыясненной природы и при диагностике или контроле эффективности лечения патологий костного мозга. Референсные значения зависят от возраста, а в детстве — еще и от пола.',
                    contraindic: '<ul>При некоторых заболеваниях проведение забора тромбоцитарной массы категорически запрещено: \n' +
                      '          <li>туберкулез;</li>\n' +
                      '          <li>вирус иммунодефицита;</li>\n' +
                      '          <li>раковые патологии;</li>\n' +
                      '          <li>гепатиты;</li>\n' +
                      '          <li>нарушения в работе сердца и сосудов;</li>\n' +
                      '          <li>после проведения оперативных вмешательств;</li>\n' +
                      '          <li>беременность;</li>\n' +
                      '          <li>алкоголизм;</li>\n' +
                      '          <li>недавнее введение прививки;</li>\n' +
                      '          <li>язва желудка или двенадцатиперстной кишки;</li>\n' +
                      '          <li>татуировки;</li>\n' +
                      '          <li>обширные стоматологические поражения.</li>\n' +
                      '        </ul>',
                    sale: '',
                    doctor: [ // TODO могут ли одну услугу оказывать несколько врачей?
                      {
                        id: 2,
                        lastName: 'Морозова',
                        firstName: 'Екатерина',
                        middleName: 'Владимировна',
                        specialization: 'ЛОР',
                        main_photo: {
                          id: 11,
                          url: '../../assets/img/doctors/doc-5.png'
                        }
                      }
                    ],
                    documents: [
                      {
                        id: 1,
                        title: 'Паспорт',
                        // tslint:disable-next-line:max-line-length
                        desc: 'Проснувшись однажды утром после беспокойного сна, Грегор Замза обнаружил, что он у себя в постели превратился в страшное насекомое.',
                        main_photo:
                          {
                            id: 1,
                            url: '../../assets/img/doc-img-1.png',
                          }
                      },
                      {
                        id: 2,
                        title: 'Медицинский полис',
                        // tslint:disable-next-line:max-line-length
                        desc: 'Проснувшись однажды утром после беспокойного сна, Грегор Замза обнаружил, что он у себя в постели превратился в страшное насекомое.',
                        main_photo:
                          {
                            id: 1,
                            url: '../../assets/img/doc-img-2.png',
                          }
                      },
                      {
                        id: 3,
                        title: 'Направление доктора',
                        // tslint:disable-next-line:max-line-length
                        desc: 'Проснувшись однажды утром после беспокойного сна, Грегор Замза обнаружил, что он у себя в постели превратился в страшное насекомое.',
                        main_photo:
                          {
                            id: 1,
                            url: '../../assets/img/doc-img-3.png',
                          }
                      }
                    ],
                    tags: [
                      {
                        id: 1,
                        name: 'Кровь',
                        desc: 'Описание тега'
                      },
                      {
                        id: 2,
                        name: 'Глюкоза',
                        desc: 'Описание тега'
                      },
                      {
                        id: 3,
                        name: 'Витамины',
                        desc: 'Описание тега'
                      }
                    ],
                    slider: [
                      {
                        id: 1,
                        // tslint:disable-next-line:max-line-length
                        title: 'Ощущения пациента во время проведения ультразвуковой эластографии такие же, как и при обычном УЗИ. Ни боли, ни дискомфорта пациенты не испытывают.',
                        // tslint:disable-next-line:max-line-length
                        desc: 'Ощущения пациента во время проведения ультразвуковой эластографии такие же, как и при обычном УЗИ. Ни боли, ни дискомфорта пациенты не испытывают.',
                        main_photo: {
                          url: '../../assets/img/big-slide-3.png'
                        },
                      },
                      {
                        id: 2,
                        // tslint:disable-next-line:max-line-length
                        title: 'Ощущения пациента во время проведения ',
                        // tslint:disable-next-line:max-line-length
                        desc: 'Ощущения пациента во время проведения ультразвуковой эластографии такие же, как и при обычном УЗИ. Ни боли, ни дискомфорта пациенты не испытывают.',
                        main_photo: {
                          url: '../../assets/img/big-slide-4.png'
                        }
                      },
                      {
                        id: 3,
                        // tslint:disable-next-line:max-line-length
                        title: 'Ощущения пациента во время проведения ультразвуковой эластографии такие же, как и при обычном УЗИ. Ни боли, ни дискомфорта пациенты не испытывают.',
                        // tslint:disable-next-line:max-line-length
                        desc: 'Ощущения пациента во время проведения ультразвуковой эластографии такие же, как и при обычном УЗИ. Ни боли, ни дискомфорта пациенты не испытывают.',
                        main_photo: {
                          url: '../../assets/img/big-slide-3.png'
                        },
                      },
                      {
                        id: 4,
                        // tslint:disable-next-line:max-line-length
                        title: 'Ощущения пациента во время проведения ',
                        // tslint:disable-next-line:max-line-length
                        desc: 'Ощущения пациента во время проведения ультразвуковой эластографии такие же, как и при обычном УЗИ. Ни боли, ни дискомфорта пациенты не испытывают.',
                        main_photo: {
                          url: '../../assets/img/big-slide-4.png'
                        }
                      }
                    ],
                    catalog:
                      {
                        id: 1,
                        name: 'Клинический анализ крови',
                        desc: 'Описание Клинический анализ крови'
                      }
                  },
                  {
                    id: 2,
                    // tslint:disable-next-line:max-line-length
                    name: 'Ультразвуковое исследование органов брюшной полости комплексное: печень, поджелудочная железа, желчный пузырь, селезенка при профилактическом осмотре',
                    display_name: 'УЗИ брюшной полости',
                    price: '820',
                    // tslint:disable-next-line:max-line-length
                    anons: 'Ультразвуковую диагностику смело можно назвать уникальным методом исследования. Она обладает такими свойствами как неинвазивность, возможность многократного проведения исследования без вреда для здоровья пациента, быстрота получения информации, отсутствие противопоказаний, исключение лучевой нагрузки.',
                    // tslint:disable-next-line:max-line-length
                    body: 'Проснувшись однажды утром после беспокойного сна, Грегор Замза обнаружил, что он у себя в постели превратился в страшное насекомое.',
                    // tslint:disable-next-line:max-line-length
                    memo: 'Вы должны подготовить себя к исследованию следующим образом: воздержаться от физических нагрузок, приема алкоголя и лекарств, изменений в питании в течение 24 часов до взятия крови. Вам не следует принимать пищу после ужина, лечь спать нужно в обычное для Вас время и встать не позднее, чем за час до взятия крови. Утром до сдачи анализа воздержитесь от курения и приема пищи. Взятие крови осуществляется до проведения диагностических или лечебных процедур: инъекций, общего массажа тела, эндоскопий, ЭКГ, рентгеновского обследования и т.д. Если Вы испытываете трудности с отменой лекарств, то обязательно сообщите об этом лечащему врачу. Очень важно, чтобы Вы точно соблюдали указанные рекомендации, так как только в этом случае будут получены ценные результаты исследования крови.',
                    // tslint:disable-next-line:max-line-length
                    indication: 'Анализ на свертываемость является одним из самых простых и доступных методов исследования. Пациенту нужно отказываться от приема пищи в течение двух часов перед исследованием. Результаты готовы, как правило, через несколько часов. Такое тестирование проводится в рамках комплексного обследования организма, а также при наличии кровотечений невыясненной природы и при диагностике или контроле эффективности лечения патологий костного мозга. Референсные значения зависят от возраста, а в детстве — еще и от пола.',
                    contraindic: '<ul>При некоторых заболеваниях проведение забора тромбоцитарной массы категорически запрещено: \n' +
                      '          <li>туберкулез;</li>\n' +
                      '          <li>вирус иммунодефицита;</li>\n' +
                      '          <li>раковые патологии;</li>\n' +
                      '          <li>гепатиты;</li>\n' +
                      '          <li>нарушения в работе сердца и сосудов;</li>\n' +
                      '          <li>после проведения оперативных вмешательств;</li>\n' +
                      '          <li>беременность;</li>\n' +
                      '          <li>алкоголизм;</li>\n' +
                      '          <li>недавнее введение прививки;</li>\n' +
                      '          <li>язва желудка или двенадцатиперстной кишки;</li>\n' +
                      '          <li>татуировки;</li>\n' +
                      '          <li>обширные стоматологические поражения.</li>\n' +
                      '        </ul>',
                    sale: '10',
                    doctor: [ // TODO могут ли одну услугу оказывать несколько врачей?
                      {
                        id: 1,
                        lastName: 'Замза',
                        firstName: 'Грегор',
                        middleName: '',
                        specialization: 'Уролог',
                        main_photo: {
                          id: 16,
                          url: '../../assets/img/doctors/doc-2.png'
                        }
                      }
                    ],
                    documents: [
                      {
                        id: 1,
                        title: 'Паспорт',
                        // tslint:disable-next-line:max-line-length
                        desc: 'Проснувшись однажды утром после беспокойного сна, Грегор Замза обнаружил, что он у себя в постели превратился в страшное насекомое.',
                        main_photo:
                          {
                            id: 1,
                            url: '../../assets/img/doc-img-1.png',
                          }
                      }
                    ],
                    tags: [
                      {
                        id: 1,
                        name: 'Кровь',
                        desc: 'Описание тега'
                      },
                      {
                        id: 4,
                        name: 'Железо',
                        desc: 'Описание тега'
                      },
                      {
                        id: 5,
                        name: 'Белки',
                        desc: 'Описание тега'
                      }
                    ],
                    slider: [
                      {
                        id: 1,
                        // tslint:disable-next-line:max-line-length
                        title: 'Ощущения пациента во время проведения ультразвуковой эластографии такие же, как и при обычном УЗИ. Ни боли, ни дискомфорта пациенты не испытывают.',
                        // tslint:disable-next-line:max-line-length
                        desc: 'Ощущения пациента во время проведения ультразвуковой эластографии такие же, как и при обычном УЗИ. Ни боли, ни дискомфорта пациенты не испытывают.',
                        main_photo: {
                          url: '../../assets/img/big-slide-3.png'
                        },
                      },
                      {
                        id: 2,
                        // tslint:disable-next-line:max-line-length
                        title: 'Ощущения пациента во время проведения ',
                        // tslint:disable-next-line:max-line-length
                        desc: 'Ощущения пациента во время проведения ультразвуковой эластографии такие же, как и при обычном УЗИ. Ни боли, ни дискомфорта пациенты не испытывают.',
                        main_photo: {
                          url: '../../assets/img/big-slide-4.png'
                        }
                      }
                    ],
                    catalog:
                      {
                        id: 1,
                        name: 'Клинический анализ крови',
                        desc: 'Описание Клинический анализ крови'
                      }
                  },
                  {
                    id: 3,
                    name: 'УЗИ печеночного трансплантанта с дуплексным сканированием артерий и вен',
                    display_name: 'УЗИ печеночного трансплантанта с дуплексным сканированием артерий и вен',
                    price: '1320',
                    // tslint:disable-next-line:max-line-length
                    anons: 'Ультразвуковую диагностику смело можно назвать уникальным методом исследования. Она обладает такими свойствами как неинвазивность, возможность многократного проведения исследования без вреда для здоровья пациента, быстрота получения информации, отсутствие противопоказаний, исключение лучевой нагрузки.',
                    // tslint:disable-next-line:max-line-length
                    body: 'Проснувшись однажды утром после беспокойного сна, Грегор Замза обнаружил, что он у себя в постели превратился в страшное насекомое.',
                    // tslint:disable-next-line:max-line-length
                    memo: 'Вы должны подготовить себя к исследованию следующим образом: воздержаться от физических нагрузок, приема алкоголя и лекарств, изменений в питании в течение 24 часов до взятия крови. Вам не следует принимать пищу после ужина, лечь спать нужно в обычное для Вас время и встать не позднее, чем за час до взятия крови. Утром до сдачи анализа воздержитесь от курения и приема пищи. Взятие крови осуществляется до проведения диагностических или лечебных процедур: инъекций, общего массажа тела, эндоскопий, ЭКГ, рентгеновского обследования и т.д. Если Вы испытываете трудности с отменой лекарств, то обязательно сообщите об этом лечащему врачу. Очень важно, чтобы Вы точно соблюдали указанные рекомендации, так как только в этом случае будут получены ценные результаты исследования крови.',
                    // tslint:disable-next-line:max-line-length
                    indication: 'Анализ на свертываемость является одним из самых простых и доступных методов исследования. Пациенту нужно отказываться от приема пищи в течение двух часов перед исследованием. Результаты готовы, как правило, через несколько часов. Такое тестирование проводится в рамках комплексного обследования организма, а также при наличии кровотечений невыясненной природы и при диагностике или контроле эффективности лечения патологий костного мозга. Референсные значения зависят от возраста, а в детстве — еще и от пола.',
                    contraindic: '<ul>При некоторых заболеваниях проведение забора тромбоцитарной массы категорически запрещено: \n' +
                      '          <li>туберкулез;</li>\n' +
                      '          <li>вирус иммунодефицита;</li>\n' +
                      '          <li>раковые патологии;</li>\n' +
                      '          <li>гепатиты;</li>\n' +
                      '          <li>нарушения в работе сердца и сосудов;</li>\n' +
                      '          <li>после проведения оперативных вмешательств;</li>\n' +
                      '          <li>беременность;</li>\n' +
                      '          <li>алкоголизм;</li>\n' +
                      '          <li>недавнее введение прививки;</li>\n' +
                      '          <li>язва желудка или двенадцатиперстной кишки;</li>\n' +
                      '          <li>татуировки;</li>\n' +
                      '          <li>обширные стоматологические поражения.</li>\n' +
                      '        </ul>',
                    sale: '20',
                    doctor: [ // TODO могут ли одну услугу оказывать несколько врачей?
                      {
                        id: 1,
                        lastName: 'Коновалов',
                        firstName: 'Геннадий',
                        middleName: 'Александрович',
                        specialization: 'Главный ревматолог',
                        main_photo: {
                          id: 1,
                          url: '../../assets/img/doctors/doc-3.png'
                        }
                      },
                      {
                        id: 2,
                        lastName: 'Морозова',
                        firstName: 'Екатерина',
                        middleName: 'Владимировна',
                        specialization: 'ЛОР',
                        main_photo: {
                          id: 11,
                          url: '../../assets/img/doctors/doc-5.png'
                        }
                      }
                    ],
                    documents: [
                      {
                        id: 1,
                        title: 'Паспорт',
                        // tslint:disable-next-line:max-line-length
                        desc: 'Проснувшись однажды утром после беспокойного сна, Грегор Замза обнаружил, что он у себя в постели превратился в страшное насекомое.',
                        main_photo:
                          {
                            id: 1,
                            url: '../../assets/img/doc-img-1.png',
                          }
                      },
                      {
                        id: 2,
                        title: 'Медицинский полис',
                        // tslint:disable-next-line:max-line-length
                        desc: 'Проснувшись однажды утром после беспокойного сна, Грегор Замза обнаружил, что он у себя в постели превратился в страшное насекомое.',
                        main_photo:
                          {
                            id: 1,
                            url: '../../assets/img/doc-img-2.png',
                          }
                      },
                      {
                        id: 3,
                        title: 'Направление доктора',
                        // tslint:disable-next-line:max-line-length
                        desc: 'Проснувшись однажды утром после беспокойного сна, Грегор Замза обнаружил, что он у себя в постели превратился в страшное насекомое.',
                        main_photo:
                          {
                            id: 1,
                            url: '../../assets/img/doc-img-3.png',
                          }
                      }
                    ],
                    tags: [
                      {
                        id: 1,
                        name: 'Кровь',
                        desc: 'Описание тега'
                      },
                      {
                        id: 2,
                        name: 'Глюкоза',
                        desc: 'Описание тега'
                      },
                      {
                        id: 3,
                        name: 'Витамины',
                        desc: 'Описание тега'
                      }
                    ],
                    catalog:
                      {
                        id: 1,
                        name: 'Клинический анализ крови',
                        desc: 'Описание Клинический анализ крови'
                      }
                  },
                  {
                    id: 4,
                    name: 'УЗИ печеночного трансплантанта с дуплексным сканированием артерий и вен',
                    display_name: 'УЗИ печеночного трансплантанта с дуплексным сканированием артерий и вен',
                    price: '1320',
                    // tslint:disable-next-line:max-line-length
                    anons: 'Ультразвуковую диагностику смело можно назвать уникальным методом исследования. Она обладает такими свойствами как неинвазивность, возможность многократного проведения исследования без вреда для здоровья пациента, быстрота получения информации, отсутствие противопоказаний, исключение лучевой нагрузки.',
                    // tslint:disable-next-line:max-line-length
                    body: 'Проснувшись однажды утром после беспокойного сна, Грегор Замза обнаружил, что он у себя в постели превратился в страшное насекомое.',
                    // tslint:disable-next-line:max-line-length
                    memo: 'Вы должны подготовить себя к исследованию следующим образом: воздержаться от физических нагрузок, приема алкоголя и лекарств, изменений в питании в течение 24 часов до взятия крови. Вам не следует принимать пищу после ужина, лечь спать нужно в обычное для Вас время и встать не позднее, чем за час до взятия крови. Утром до сдачи анализа воздержитесь от курения и приема пищи. Взятие крови осуществляется до проведения диагностических или лечебных процедур: инъекций, общего массажа тела, эндоскопий, ЭКГ, рентгеновского обследования и т.д. Если Вы испытываете трудности с отменой лекарств, то обязательно сообщите об этом лечащему врачу. Очень важно, чтобы Вы точно соблюдали указанные рекомендации, так как только в этом случае будут получены ценные результаты исследования крови.',
                    // tslint:disable-next-line:max-line-length
                    indication: 'Анализ на свертываемость является одним из самых простых и доступных методов исследования. Пациенту нужно отказываться от приема пищи в течение двух часов перед исследованием. Результаты готовы, как правило, через несколько часов. Такое тестирование проводится в рамках комплексного обследования организма, а также при наличии кровотечений невыясненной природы и при диагностике или контроле эффективности лечения патологий костного мозга. Референсные значения зависят от возраста, а в детстве — еще и от пола.',
                    contraindic: '<ul>При некоторых заболеваниях проведение забора тромбоцитарной массы категорически запрещено: \n' +
                      '          <li>туберкулез;</li>\n' +
                      '          <li>вирус иммунодефицита;</li>\n' +
                      '          <li>раковые патологии;</li>\n' +
                      '          <li>гепатиты;</li>\n' +
                      '          <li>нарушения в работе сердца и сосудов;</li>\n' +
                      '          <li>после проведения оперативных вмешательств;</li>\n' +
                      '          <li>беременность;</li>\n' +
                      '          <li>алкоголизм;</li>\n' +
                      '          <li>недавнее введение прививки;</li>\n' +
                      '          <li>язва желудка или двенадцатиперстной кишки;</li>\n' +
                      '          <li>татуировки;</li>\n' +
                      '          <li>обширные стоматологические поражения.</li>\n' +
                      '        </ul>',
                    sale: '',
                    documents: [
                      {
                        id: 1,
                        title: 'Паспорт',
                        // tslint:disable-next-line:max-line-length
                        desc: 'Проснувшись однажды утром после беспокойного сна, Грегор Замза обнаружил, что он у себя в постели превратился в страшное насекомое.',
                        main_photo:
                          {
                            id: 1,
                            url: '../../assets/img/doc-img-1.png',
                          }
                      }
                    ],
                    tags: [
                      {
                        id: 1,
                        name: 'Кровь',
                        desc: 'Описание тега'
                      },
                      {
                        id: 2,
                        name: 'Глюкоза',
                        desc: 'Описание тега'
                      },
                      {
                        id: 3,
                        name: 'Витамины',
                        desc: 'Описание тега'
                      }
                    ],
                    catalog:
                      {
                        id: 1,
                        name: 'Клинический анализ крови',
                        desc: 'Описание Клинический анализ крови'
                      }
                  },
                  {
                    id: 5,
                    name: 'УЗИ печеночного трансплантанта с дуплексным сканированием артерий и вен',
                    display_name: 'УЗИ печеночного трансплантанта с дуплексным сканированием артерий и вен',
                    price: '1320',
                    // tslint:disable-next-line:max-line-length
                    anons: 'Ультразвуковую диагностику смело можно назвать уникальным методом исследования. Она обладает такими свойствами как неинвазивность, возможность многократного проведения исследования без вреда для здоровья пациента, быстрота получения информации, отсутствие противопоказаний, исключение лучевой нагрузки.',
                    // tslint:disable-next-line:max-line-length
                    body: 'Проснувшись однажды утром после беспокойного сна, Грегор Замза обнаружил, что он у себя в постели превратился в страшное насекомое.',
                    // tslint:disable-next-line:max-line-length
                    memo: 'Вы должны подготовить себя к исследованию следующим образом: воздержаться от физических нагрузок, приема алкоголя и лекарств, изменений в питании в течение 24 часов до взятия крови. Вам не следует принимать пищу после ужина, лечь спать нужно в обычное для Вас время и встать не позднее, чем за час до взятия крови. Утром до сдачи анализа воздержитесь от курения и приема пищи. Взятие крови осуществляется до проведения диагностических или лечебных процедур: инъекций, общего массажа тела, эндоскопий, ЭКГ, рентгеновского обследования и т.д. Если Вы испытываете трудности с отменой лекарств, то обязательно сообщите об этом лечащему врачу. Очень важно, чтобы Вы точно соблюдали указанные рекомендации, так как только в этом случае будут получены ценные результаты исследования крови.',
                    // tslint:disable-next-line:max-line-length
                    indication: 'Анализ на свертываемость является одним из самых простых и доступных методов исследования. Пациенту нужно отказываться от приема пищи в течение двух часов перед исследованием. Результаты готовы, как правило, через несколько часов. Такое тестирование проводится в рамках комплексного обследования организма, а также при наличии кровотечений невыясненной природы и при диагностике или контроле эффективности лечения патологий костного мозга. Референсные значения зависят от возраста, а в детстве — еще и от пола.',
                    contraindic: '<ul>При некоторых заболеваниях проведение забора тромбоцитарной массы категорически запрещено: \n' +
                      '          <li>туберкулез;</li>\n' +
                      '          <li>вирус иммунодефицита;</li>\n' +
                      '          <li>раковые патологии;</li>\n' +
                      '          <li>гепатиты;</li>\n' +
                      '          <li>нарушения в работе сердца и сосудов;</li>\n' +
                      '          <li>после проведения оперативных вмешательств;</li>\n' +
                      '          <li>беременность;</li>\n' +
                      '          <li>алкоголизм;</li>\n' +
                      '          <li>недавнее введение прививки;</li>\n' +
                      '          <li>язва желудка или двенадцатиперстной кишки;</li>\n' +
                      '          <li>татуировки;</li>\n' +
                      '          <li>обширные стоматологические поражения.</li>\n' +
                      '        </ul>',
                    sale: '',
                    documents: [
                      {
                        id: 1,
                        title: 'Паспорт',
                        // tslint:disable-next-line:max-line-length
                        desc: 'Проснувшись однажды утром после беспокойного сна, Грегор Замза обнаружил, что он у себя в постели превратился в страшное насекомое.',
                        main_photo:
                          {
                            id: 1,
                            url: '../../assets/img/doc-img-1.png',
                          }
                      }
                    ],
                    tags: [
                      {
                        id: 1,
                        name: 'Кровь',
                        desc: 'Описание тега'
                      },
                      {
                        id: 2,
                        name: 'Глюкоза',
                        desc: 'Описание тега'
                      },
                      {
                        id: 3,
                        name: 'Витамины',
                        desc: 'Описание тега'
                      }
                    ],
                    catalog:
                      {
                        id: 1,
                        name: 'УЗИ - диагностические исследования',
                        desc: 'Описание Клинический анализ крови'
                      }
                  },
                  {
                    id: 6,
                    name: 'УЗИ печеночного трансплантанта с дуплексным сканированием артерий и вен',
                    display_name: 'УЗИ печеночного трансплантанта с дуплексным сканированием артерий и вен',
                    price: '1320',
                    // tslint:disable-next-line:max-line-length
                    anons: 'Ультразвуковую диагностику смело можно назвать уникальным методом исследования. Она обладает такими свойствами как неинвазивность, возможность многократного проведения исследования без вреда для здоровья пациента, быстрота получения информации, отсутствие противопоказаний, исключение лучевой нагрузки.',
                    // tslint:disable-next-line:max-line-length
                    body: 'Проснувшись однажды утром после беспокойного сна, Грегор Замза обнаружил, что он у себя в постели превратился в страшное насекомое.',
                    // tslint:disable-next-line:max-line-length
                    memo: 'Вы должны подготовить себя к исследованию следующим образом: воздержаться от физических нагрузок, приема алкоголя и лекарств, изменений в питании в течение 24 часов до взятия крови. Вам не следует принимать пищу после ужина, лечь спать нужно в обычное для Вас время и встать не позднее, чем за час до взятия крови. Утром до сдачи анализа воздержитесь от курения и приема пищи. Взятие крови осуществляется до проведения диагностических или лечебных процедур: инъекций, общего массажа тела, эндоскопий, ЭКГ, рентгеновского обследования и т.д. Если Вы испытываете трудности с отменой лекарств, то обязательно сообщите об этом лечащему врачу. Очень важно, чтобы Вы точно соблюдали указанные рекомендации, так как только в этом случае будут получены ценные результаты исследования крови.',
                    // tslint:disable-next-line:max-line-length
                    indication: 'Анализ на свертываемость является одним из самых простых и доступных методов исследования. Пациенту нужно отказываться от приема пищи в течение двух часов перед исследованием. Результаты готовы, как правило, через несколько часов. Такое тестирование проводится в рамках комплексного обследования организма, а также при наличии кровотечений невыясненной природы и при диагностике или контроле эффективности лечения патологий костного мозга. Референсные значения зависят от возраста, а в детстве — еще и от пола.',
                    contraindic: '<ul>При некоторых заболеваниях проведение забора тромбоцитарной массы категорически запрещено: \n' +
                      '          <li>туберкулез;</li>\n' +
                      '          <li>вирус иммунодефицита;</li>\n' +
                      '          <li>раковые патологии;</li>\n' +
                      '          <li>гепатиты;</li>\n' +
                      '          <li>нарушения в работе сердца и сосудов;</li>\n' +
                      '          <li>после проведения оперативных вмешательств;</li>\n' +
                      '          <li>беременность;</li>\n' +
                      '          <li>алкоголизм;</li>\n' +
                      '          <li>недавнее введение прививки;</li>\n' +
                      '          <li>язва желудка или двенадцатиперстной кишки;</li>\n' +
                      '          <li>татуировки;</li>\n' +
                      '          <li>обширные стоматологические поражения.</li>\n' +
                      '        </ul>',
                    sale: '20',
                    tags: [
                      {
                        id: 1,
                        name: 'Кровь',
                        desc: 'Описание тега'
                      },
                      {
                        id: 2,
                        name: 'Глюкоза',
                        desc: 'Описание тега'
                      },
                      {
                        id: 3,
                        name: 'Витамины',
                        desc: 'Описание тега'
                      }
                    ],
                    catalog:
                      {
                        id: 1,
                        name: 'Анализы крови',
                        desc: 'Описание Клинический анализ крови'
                      }
                  },
                  {
                    id: 7,
                    name: 'Анализ крови артерий и вен',
                    display_name: 'Анализ крови артерий и вен',
                    price: '1320',
                    // tslint:disable-next-line:max-line-length
                    anons: 'Ультразвуковую диагностику смело можно назвать уникальным методом исследования. Она обладает такими свойствами как неинвазивность, возможность многократного проведения исследования без вреда для здоровья пациента, быстрота получения информации, отсутствие противопоказаний, исключение лучевой нагрузки.',
                    // tslint:disable-next-line:max-line-length
                    body: 'Проснувшись однажды утром после беспокойного сна, Грегор Замза обнаружил.',
                    // tslint:disable-next-line:max-line-length
                    memo: 'Вы должны подготовить себя к исследованию следующим образом: воздержаться от физических нагрузок, приема алкоголя и лекарств, изменений в питании в течение 24 часов до взятия крови. Вам не следует принимать пищу после ужина, лечь спать нужно в обычное для Вас время и встать не позднее, чем за час до взятия крови. Утром до сдачи анализа воздержитесь от курения и приема пищи. Взятие крови осуществляется до проведения диагностических или лечебных процедур: инъекций, общего массажа тела, эндоскопий, ЭКГ, рентгеновского обследования и т.д. Если Вы испытываете трудности с отменой лекарств, то обязательно сообщите об этом лечащему врачу. Очень важно, чтобы Вы точно соблюдали указанные рекомендации, так как только в этом случае будут получены ценные результаты исследования крови.',
                    // tslint:disable-next-line:max-line-length
                    indication: 'Анализ на свертываемость является одним из самых простых и доступных методов исследования. Пациенту нужно отказываться от приема пищи в течение двух часов перед исследованием. Результаты готовы, как правило, через несколько часов. Такое тестирование проводится в рамках комплексного обследования организма, а также при наличии кровотечений невыясненной природы и при диагностике или контроле эффективности лечения патологий костного мозга. Референсные значения зависят от возраста, а в детстве — еще и от пола.',
                    contraindic: '<ul>При некоторых заболеваниях проведение забора тромбоцитарной массы категорически запрещено: \n' +
                      '          <li>туберкулез;</li>\n' +
                      '          <li>вирус иммунодефицита;</li>\n' +
                      '          <li>раковые патологии;</li>\n' +
                      '          <li>гепатиты;</li>\n' +
                      '          <li>нарушения в работе сердца и сосудов;</li>\n' +
                      '          <li>после проведения оперативных вмешательств;</li>\n' +
                      '          <li>беременность;</li>\n' +
                      '          <li>алкоголизм;</li>\n' +
                      '          <li>недавнее введение прививки;</li>\n' +
                      '          <li>язва желудка или двенадцатиперстной кишки;</li>\n' +
                      '          <li>татуировки;</li>\n' +
                      '          <li>обширные стоматологические поражения.</li>\n' +
                      '        </ul>',
                    sale: '',
                    documents: [
                      {
                        id: 1,
                        title: 'Паспорт',
                        // tslint:disable-next-line:max-line-length
                        desc: 'Проснувшись однажды утром после беспокойного сна, Грегор Замза обнаружил, что он у себя в постели превратился в страшное насекомое.',
                        main_photo:
                          {
                            id: 1,
                            url: '../../assets/img/doc-img-1.png',
                          }
                      }
                    ],
                    tags: [
                      {
                        id: 1,
                        name: 'Кровь',
                        desc: 'Описание тега'
                      },
                      {
                        id: 2,
                        name: 'Глюкоза',
                        desc: 'Описание тега'
                      },
                      {
                        id: 3,
                        name: 'Гормоны',
                        desc: 'Описание тега'
                      }
                    ],
                    catalog:
                      {
                        id: 1,
                        name: 'Анализы крови',
                        desc: 'Описание Клинический анализ крови'
                      }
                  }
                ],
              },
              {
                id: 24,
                name: 'Функциональная диагностика',
                main_photo: {
                  id: 1,
                  url: '../../assets/img/direction2-3.png',
                },
                short_description: 'Краткое описание Функциональная диагностика',
                description: 'Полное описание Функциональная диагностика',
                icon: null,
                units: [
                  {
                    id: 2,
                    name: 'Поликлиника №1',
                    address: {
                      id: 1,
                      city: 'Красноярск',
                      street: 'Вавилова',
                      house: '28',
                      building: '',
                      ymapcfg: '../../assets/img/contacts-card-map.png',
                      postcode: '660025',
                      region: 'Красноярский край',
                      mailbox: '6545'
                    },
                    phones: [
                      {
                        id: 7,
                        number: '+7 (391) 274-31-64',
                        description: 'круглосуточно'
                      },
                      {
                        id: 8,
                        number: '+7 (391) 257-64-60',
                        description: 'с 9:00 до 17:00'
                      }
                    ],
                  },
                  {
                    id: 3,
                    name: 'Поликлиника №3',
                    address: {
                      id: 1,
                      city: 'Красноярск',
                      street: 'Коломенская',
                      house: '28',
                      building: '',
                      ymapcfg: '../../assets/img/contacts-card-map.png',
                      postcode: '660025',
                      region: 'Красноярский край',
                      mailbox: '6545'
                    },
                    phones: [
                      {
                        id: 7,
                        number: '+7 (391) 274-31-64',
                        description: 'круглосуточно'
                      },
                      {
                        id: 8,
                        number: '+7 (391) 257-64-60',
                        description: 'с 9:00 до 17:00'
                      }
                    ],
                  },
                ],
                services: [
                  {
                    id: 5,
                    name: 'УЗИ печеночного трансплантанта с дуплексным сканированием артерий и вен',
                    display_name: 'УЗИ печеночного трансплантанта с дуплексным сканированием артерий и вен',
                    price: '1320',
                    // tslint:disable-next-line:max-line-length
                    anons: 'Ультразвуковую диагностику смело можно назвать уникальным методом исследования. Она обладает такими свойствами как неинвазивность, возможность многократного проведения исследования без вреда для здоровья пациента, быстрота получения информации, отсутствие противопоказаний, исключение лучевой нагрузки.',
                    // tslint:disable-next-line:max-line-length
                    body: 'Проснувшись однажды утром после беспокойного сна, Грегор Замза обнаружил, что он у себя в постели превратился в страшное насекомое.',
                    // tslint:disable-next-line:max-line-length
                    memo: 'Вы должны подготовить себя к исследованию следующим образом: воздержаться от физических нагрузок, приема алкоголя и лекарств, изменений в питании в течение 24 часов до взятия крови. Вам не следует принимать пищу после ужина, лечь спать нужно в обычное для Вас время и встать не позднее, чем за час до взятия крови. Утром до сдачи анализа воздержитесь от курения и приема пищи. Взятие крови осуществляется до проведения диагностических или лечебных процедур: инъекций, общего массажа тела, эндоскопий, ЭКГ, рентгеновского обследования и т.д. Если Вы испытываете трудности с отменой лекарств, то обязательно сообщите об этом лечащему врачу. Очень важно, чтобы Вы точно соблюдали указанные рекомендации, так как только в этом случае будут получены ценные результаты исследования крови.',
                    // tslint:disable-next-line:max-line-length
                    indication: 'Анализ на свертываемость является одним из самых простых и доступных методов исследования. Пациенту нужно отказываться от приема пищи в течение двух часов перед исследованием. Результаты готовы, как правило, через несколько часов. Такое тестирование проводится в рамках комплексного обследования организма, а также при наличии кровотечений невыясненной природы и при диагностике или контроле эффективности лечения патологий костного мозга. Референсные значения зависят от возраста, а в детстве — еще и от пола.',
                    contraindic: '<ul>При некоторых заболеваниях проведение забора тромбоцитарной массы категорически запрещено: \n' +
                      '          <li>туберкулез;</li>\n' +
                      '          <li>вирус иммунодефицита;</li>\n' +
                      '          <li>раковые патологии;</li>\n' +
                      '          <li>гепатиты;</li>\n' +
                      '          <li>нарушения в работе сердца и сосудов;</li>\n' +
                      '          <li>после проведения оперативных вмешательств;</li>\n' +
                      '          <li>беременность;</li>\n' +
                      '          <li>алкоголизм;</li>\n' +
                      '          <li>недавнее введение прививки;</li>\n' +
                      '          <li>язва желудка или двенадцатиперстной кишки;</li>\n' +
                      '          <li>татуировки;</li>\n' +
                      '          <li>обширные стоматологические поражения.</li>\n' +
                      '        </ul>',
                    sale: '',
                    documents: [
                      {
                        id: 1,
                        title: 'Паспорт',
                        // tslint:disable-next-line:max-line-length
                        desc: 'Проснувшись однажды утром после беспокойного сна, Грегор Замза обнаружил, что он у себя в постели превратился в страшное насекомое.',
                        main_photo:
                          {
                            id: 1,
                            url: '../../assets/img/doc-img-1.png',
                          }
                      }
                    ],
                    tags: [
                      {
                        id: 1,
                        name: 'Кровь',
                        desc: 'Описание тега'
                      },
                      {
                        id: 2,
                        name: 'Глюкоза',
                        desc: 'Описание тега'
                      },
                      {
                        id: 3,
                        name: 'Витамины',
                        desc: 'Описание тега'
                      }
                    ],
                    catalog:
                      {
                        id: 1,
                        name: 'УЗИ - диагностические исследования',
                        desc: 'Описание Клинический анализ крови'
                      }
                  },
                  {
                    id: 6,
                    name: 'УЗИ печеночного трансплантанта с дуплексным сканированием артерий и вен',
                    display_name: 'УЗИ печеночного трансплантанта с дуплексным сканированием артерий и вен',
                    price: '1320',
                    // tslint:disable-next-line:max-line-length
                    anons: 'Ультразвуковую диагностику смело можно назвать уникальным методом исследования. Она обладает такими свойствами как неинвазивность, возможность многократного проведения исследования без вреда для здоровья пациента, быстрота получения информации, отсутствие противопоказаний, исключение лучевой нагрузки.',
                    // tslint:disable-next-line:max-line-length
                    body: 'Проснувшись однажды утром после беспокойного сна, Грегор Замза обнаружил, что он у себя в постели превратился в страшное насекомое.',
                    // tslint:disable-next-line:max-line-length
                    memo: 'Вы должны подготовить себя к исследованию следующим образом: воздержаться от физических нагрузок, приема алкоголя и лекарств, изменений в питании в течение 24 часов до взятия крови. Вам не следует принимать пищу после ужина, лечь спать нужно в обычное для Вас время и встать не позднее, чем за час до взятия крови. Утром до сдачи анализа воздержитесь от курения и приема пищи. Взятие крови осуществляется до проведения диагностических или лечебных процедур: инъекций, общего массажа тела, эндоскопий, ЭКГ, рентгеновского обследования и т.д. Если Вы испытываете трудности с отменой лекарств, то обязательно сообщите об этом лечащему врачу. Очень важно, чтобы Вы точно соблюдали указанные рекомендации, так как только в этом случае будут получены ценные результаты исследования крови.',
                    // tslint:disable-next-line:max-line-length
                    indication: 'Анализ на свертываемость является одним из самых простых и доступных методов исследования. Пациенту нужно отказываться от приема пищи в течение двух часов перед исследованием. Результаты готовы, как правило, через несколько часов. Такое тестирование проводится в рамках комплексного обследования организма, а также при наличии кровотечений невыясненной природы и при диагностике или контроле эффективности лечения патологий костного мозга. Референсные значения зависят от возраста, а в детстве — еще и от пола.',
                    contraindic: '<ul>При некоторых заболеваниях проведение забора тромбоцитарной массы категорически запрещено: \n' +
                      '          <li>туберкулез;</li>\n' +
                      '          <li>вирус иммунодефицита;</li>\n' +
                      '          <li>раковые патологии;</li>\n' +
                      '          <li>гепатиты;</li>\n' +
                      '          <li>нарушения в работе сердца и сосудов;</li>\n' +
                      '          <li>после проведения оперативных вмешательств;</li>\n' +
                      '          <li>беременность;</li>\n' +
                      '          <li>алкоголизм;</li>\n' +
                      '          <li>недавнее введение прививки;</li>\n' +
                      '          <li>язва желудка или двенадцатиперстной кишки;</li>\n' +
                      '          <li>татуировки;</li>\n' +
                      '          <li>обширные стоматологические поражения.</li>\n' +
                      '        </ul>',
                    sale: '20',
                    tags: [
                      {
                        id: 1,
                        name: 'Кровь',
                        desc: 'Описание тега'
                      },
                      {
                        id: 2,
                        name: 'Глюкоза',
                        desc: 'Описание тега'
                      },
                      {
                        id: 3,
                        name: 'Витамины',
                        desc: 'Описание тега'
                      }
                    ],
                    catalog:
                      {
                        id: 1,
                        name: 'Анализы крови',
                        desc: 'Описание Клинический анализ крови'
                      }
                  },
                  {
                    id: 7,
                    name: 'Анализ крови артерий и вен',
                    display_name: 'Анализ крови артерий и вен',
                    price: '1320',
                    // tslint:disable-next-line:max-line-length
                    anons: 'Ультразвуковую диагностику смело можно назвать уникальным методом исследования. Она обладает такими свойствами как неинвазивность, возможность многократного проведения исследования без вреда для здоровья пациента, быстрота получения информации, отсутствие противопоказаний, исключение лучевой нагрузки.',
                    // tslint:disable-next-line:max-line-length
                    body: 'Проснувшись однажды утром после беспокойного сна, Грегор Замза обнаружил.',
                    // tslint:disable-next-line:max-line-length
                    memo: 'Вы должны подготовить себя к исследованию следующим образом: воздержаться от физических нагрузок, приема алкоголя и лекарств, изменений в питании в течение 24 часов до взятия крови. Вам не следует принимать пищу после ужина, лечь спать нужно в обычное для Вас время и встать не позднее, чем за час до взятия крови. Утром до сдачи анализа воздержитесь от курения и приема пищи. Взятие крови осуществляется до проведения диагностических или лечебных процедур: инъекций, общего массажа тела, эндоскопий, ЭКГ, рентгеновского обследования и т.д. Если Вы испытываете трудности с отменой лекарств, то обязательно сообщите об этом лечащему врачу. Очень важно, чтобы Вы точно соблюдали указанные рекомендации, так как только в этом случае будут получены ценные результаты исследования крови.',
                    // tslint:disable-next-line:max-line-length
                    indication: 'Анализ на свертываемость является одним из самых простых и доступных методов исследования. Пациенту нужно отказываться от приема пищи в течение двух часов перед исследованием. Результаты готовы, как правило, через несколько часов. Такое тестирование проводится в рамках комплексного обследования организма, а также при наличии кровотечений невыясненной природы и при диагностике или контроле эффективности лечения патологий костного мозга. Референсные значения зависят от возраста, а в детстве — еще и от пола.',
                    contraindic: '<ul>При некоторых заболеваниях проведение забора тромбоцитарной массы категорически запрещено: \n' +
                      '          <li>туберкулез;</li>\n' +
                      '          <li>вирус иммунодефицита;</li>\n' +
                      '          <li>раковые патологии;</li>\n' +
                      '          <li>гепатиты;</li>\n' +
                      '          <li>нарушения в работе сердца и сосудов;</li>\n' +
                      '          <li>после проведения оперативных вмешательств;</li>\n' +
                      '          <li>беременность;</li>\n' +
                      '          <li>алкоголизм;</li>\n' +
                      '          <li>недавнее введение прививки;</li>\n' +
                      '          <li>язва желудка или двенадцатиперстной кишки;</li>\n' +
                      '          <li>татуировки;</li>\n' +
                      '          <li>обширные стоматологические поражения.</li>\n' +
                      '        </ul>',
                    sale: '',
                    documents: [
                      {
                        id: 1,
                        title: 'Паспорт',
                        // tslint:disable-next-line:max-line-length
                        desc: 'Проснувшись однажды утром после беспокойного сна, Грегор Замза обнаружил, что он у себя в постели превратился в страшное насекомое.',
                        main_photo:
                          {
                            id: 1,
                            url: '../../assets/img/doc-img-1.png',
                          }
                      }
                    ],
                    tags: [
                      {
                        id: 1,
                        name: 'Кровь',
                        desc: 'Описание тега'
                      },
                      {
                        id: 2,
                        name: 'Глюкоза',
                        desc: 'Описание тега'
                      },
                      {
                        id: 3,
                        name: 'Гормоны',
                        desc: 'Описание тега'
                      }
                    ],
                    catalog:
                      {
                        id: 1,
                        name: 'Анализы крови',
                        desc: 'Описание Клинический анализ крови'
                      }
                  }
                ],
              },
              {
                id: 22,
                name: 'УЗИ',
                main_photo: {
                  id: 1,
                  url: '../../assets/img/direction2-2.png',
                },
                short_description: 'Краткое описание УЗИ',
                description: 'Полное описание УЗИ',
                icon: null,
                units: [
                  {
                    id: 3,
                    name: 'Поликлиника №3',
                    address: {
                      id: 1,
                      city: 'Красноярск',
                      street: 'Коломенская',
                      house: '28',
                      building: '',
                      ymapcfg: '../../assets/img/contacts-card-map.png',
                      postcode: '660025',
                      region: 'Красноярский край',
                      mailbox: '6545'
                    },
                    phones: [
                      {
                        id: 7,
                        number: '+7 (391) 274-31-64',
                        description: 'круглосуточно'
                      },
                      {
                        id: 8,
                        number: '+7 (391) 257-64-60',
                        description: 'с 9:00 до 17:00'
                      }
                    ],
                  },
                ],
                services: [
                  {
                    id: 1,
                    name: 'УЗИ печеночного трансплантанта с дуплексным сканированием артерий и вен',
                    display_name: 'УЗИ печеночного трансплантанта с дуплексным сканированием артерий и вен',
                    price: '1320',
                    // tslint:disable-next-line:max-line-length
                    anons: 'Ультразвуковую диагностику смело можно назвать уникальным методом исследования. Она обладает такими свойствами как неинвазивность, возможность многократного проведения исследования без вреда для здоровья пациента, быстрота получения информации, отсутствие противопоказаний, исключение лучевой нагрузки.',
                    // tslint:disable-next-line:max-line-length
                    body: 'Проснувшись однажды утром после беспокойного сна, Грегор Замза обнаружил, что он у себя в постели превратился в страшное насекомое.',
                    // tslint:disable-next-line:max-line-length
                    memo: 'Вы должны подготовить себя к исследованию следующим образом: воздержаться от физических нагрузок, приема алкоголя и лекарств, изменений в питании в течение 24 часов до взятия крови. Вам не следует принимать пищу после ужина, лечь спать нужно в обычное для Вас время и встать не позднее, чем за час до взятия крови. Утром до сдачи анализа воздержитесь от курения и приема пищи. Взятие крови осуществляется до проведения диагностических или лечебных процедур: инъекций, общего массажа тела, эндоскопий, ЭКГ, рентгеновского обследования и т.д. Если Вы испытываете трудности с отменой лекарств, то обязательно сообщите об этом лечащему врачу. Очень важно, чтобы Вы точно соблюдали указанные рекомендации, так как только в этом случае будут получены ценные результаты исследования крови.',
                    // tslint:disable-next-line:max-line-length
                    indication: 'Анализ на свертываемость является одним из самых простых и доступных методов исследования. Пациенту нужно отказываться от приема пищи в течение двух часов перед исследованием. Результаты готовы, как правило, через несколько часов. Такое тестирование проводится в рамках комплексного обследования организма, а также при наличии кровотечений невыясненной природы и при диагностике или контроле эффективности лечения патологий костного мозга. Референсные значения зависят от возраста, а в детстве — еще и от пола.',
                    contraindic: '<ul>При некоторых заболеваниях проведение забора тромбоцитарной массы категорически запрещено: \n' +
                      '          <li>туберкулез;</li>\n' +
                      '          <li>вирус иммунодефицита;</li>\n' +
                      '          <li>раковые патологии;</li>\n' +
                      '          <li>гепатиты;</li>\n' +
                      '          <li>нарушения в работе сердца и сосудов;</li>\n' +
                      '          <li>после проведения оперативных вмешательств;</li>\n' +
                      '          <li>беременность;</li>\n' +
                      '          <li>алкоголизм;</li>\n' +
                      '          <li>недавнее введение прививки;</li>\n' +
                      '          <li>язва желудка или двенадцатиперстной кишки;</li>\n' +
                      '          <li>татуировки;</li>\n' +
                      '          <li>обширные стоматологические поражения.</li>\n' +
                      '        </ul>',
                    sale: '',
                    doctor: [ // TODO могут ли одну услугу оказывать несколько врачей?
                      {
                        id: 2,
                        lastName: 'Морозова',
                        firstName: 'Екатерина',
                        middleName: 'Владимировна',
                        specialization: 'ЛОР',
                        main_photo: {
                          id: 11,
                          url: '../../assets/img/doctors/doc-5.png'
                        }
                      }
                    ],
                    documents: [
                      {
                        id: 1,
                        title: 'Паспорт',
                        // tslint:disable-next-line:max-line-length
                        desc: 'Проснувшись однажды утром после беспокойного сна, Грегор Замза обнаружил, что он у себя в постели превратился в страшное насекомое.',
                        main_photo:
                          {
                            id: 1,
                            url: '../../assets/img/doc-img-1.png',
                          }
                      },
                      {
                        id: 2,
                        title: 'Медицинский полис',
                        // tslint:disable-next-line:max-line-length
                        desc: 'Проснувшись однажды утром после беспокойного сна, Грегор Замза обнаружил, что он у себя в постели превратился в страшное насекомое.',
                        main_photo:
                          {
                            id: 1,
                            url: '../../assets/img/doc-img-2.png',
                          }
                      },
                      {
                        id: 3,
                        title: 'Направление доктора',
                        // tslint:disable-next-line:max-line-length
                        desc: 'Проснувшись однажды утром после беспокойного сна, Грегор Замза обнаружил, что он у себя в постели превратился в страшное насекомое.',
                        main_photo:
                          {
                            id: 1,
                            url: '../../assets/img/doc-img-3.png',
                          }
                      }
                    ],
                    tags: [
                      {
                        id: 1,
                        name: 'Кровь',
                        desc: 'Описание тега'
                      },
                      {
                        id: 2,
                        name: 'Глюкоза',
                        desc: 'Описание тега'
                      },
                      {
                        id: 3,
                        name: 'Витамины',
                        desc: 'Описание тега'
                      }
                    ],
                    slider: [
                      {
                        id: 1,
                        // tslint:disable-next-line:max-line-length
                        title: 'Ощущения пациента во время проведения ультразвуковой эластографии такие же, как и при обычном УЗИ. Ни боли, ни дискомфорта пациенты не испытывают.',
                        // tslint:disable-next-line:max-line-length
                        desc: 'Ощущения пациента во время проведения ультразвуковой эластографии такие же, как и при обычном УЗИ. Ни боли, ни дискомфорта пациенты не испытывают.',
                        main_photo: {
                          url: '../../assets/img/big-slide-3.png'
                        },
                      },
                      {
                        id: 2,
                        // tslint:disable-next-line:max-line-length
                        title: 'Ощущения пациента во время проведения ',
                        // tslint:disable-next-line:max-line-length
                        desc: 'Ощущения пациента во время проведения ультразвуковой эластографии такие же, как и при обычном УЗИ. Ни боли, ни дискомфорта пациенты не испытывают.',
                        main_photo: {
                          url: '../../assets/img/big-slide-4.png'
                        }
                      },
                      {
                        id: 3,
                        // tslint:disable-next-line:max-line-length
                        title: 'Ощущения пациента во время проведения ультразвуковой эластографии такие же, как и при обычном УЗИ. Ни боли, ни дискомфорта пациенты не испытывают.',
                        // tslint:disable-next-line:max-line-length
                        desc: 'Ощущения пациента во время проведения ультразвуковой эластографии такие же, как и при обычном УЗИ. Ни боли, ни дискомфорта пациенты не испытывают.',
                        main_photo: {
                          url: '../../assets/img/big-slide-3.png'
                        },
                      },
                      {
                        id: 4,
                        // tslint:disable-next-line:max-line-length
                        title: 'Ощущения пациента во время проведения ',
                        // tslint:disable-next-line:max-line-length
                        desc: 'Ощущения пациента во время проведения ультразвуковой эластографии такие же, как и при обычном УЗИ. Ни боли, ни дискомфорта пациенты не испытывают.',
                        main_photo: {
                          url: '../../assets/img/big-slide-4.png'
                        }
                      }
                    ],
                    catalog:
                      {
                        id: 1,
                        name: 'Клинический анализ крови',
                        desc: 'Описание Клинический анализ крови'
                      }
                  },
                  {
                    id: 2,
                    // tslint:disable-next-line:max-line-length
                    name: 'Ультразвуковое исследование органов брюшной полости комплексное: печень, поджелудочная железа, желчный пузырь, селезенка при профилактическом осмотре',
                    display_name: 'УЗИ брюшной полости',
                    price: '820',
                    // tslint:disable-next-line:max-line-length
                    anons: 'Ультразвуковую диагностику смело можно назвать уникальным методом исследования. Она обладает такими свойствами как неинвазивность, возможность многократного проведения исследования без вреда для здоровья пациента, быстрота получения информации, отсутствие противопоказаний, исключение лучевой нагрузки.',
                    // tslint:disable-next-line:max-line-length
                    body: 'Проснувшись однажды утром после беспокойного сна, Грегор Замза обнаружил, что он у себя в постели превратился в страшное насекомое.',
                    // tslint:disable-next-line:max-line-length
                    memo: 'Вы должны подготовить себя к исследованию следующим образом: воздержаться от физических нагрузок, приема алкоголя и лекарств, изменений в питании в течение 24 часов до взятия крови. Вам не следует принимать пищу после ужина, лечь спать нужно в обычное для Вас время и встать не позднее, чем за час до взятия крови. Утром до сдачи анализа воздержитесь от курения и приема пищи. Взятие крови осуществляется до проведения диагностических или лечебных процедур: инъекций, общего массажа тела, эндоскопий, ЭКГ, рентгеновского обследования и т.д. Если Вы испытываете трудности с отменой лекарств, то обязательно сообщите об этом лечащему врачу. Очень важно, чтобы Вы точно соблюдали указанные рекомендации, так как только в этом случае будут получены ценные результаты исследования крови.',
                    // tslint:disable-next-line:max-line-length
                    indication: 'Анализ на свертываемость является одним из самых простых и доступных методов исследования. Пациенту нужно отказываться от приема пищи в течение двух часов перед исследованием. Результаты готовы, как правило, через несколько часов. Такое тестирование проводится в рамках комплексного обследования организма, а также при наличии кровотечений невыясненной природы и при диагностике или контроле эффективности лечения патологий костного мозга. Референсные значения зависят от возраста, а в детстве — еще и от пола.',
                    contraindic: '<ul>При некоторых заболеваниях проведение забора тромбоцитарной массы категорически запрещено: \n' +
                      '          <li>туберкулез;</li>\n' +
                      '          <li>вирус иммунодефицита;</li>\n' +
                      '          <li>раковые патологии;</li>\n' +
                      '          <li>гепатиты;</li>\n' +
                      '          <li>нарушения в работе сердца и сосудов;</li>\n' +
                      '          <li>после проведения оперативных вмешательств;</li>\n' +
                      '          <li>беременность;</li>\n' +
                      '          <li>алкоголизм;</li>\n' +
                      '          <li>недавнее введение прививки;</li>\n' +
                      '          <li>язва желудка или двенадцатиперстной кишки;</li>\n' +
                      '          <li>татуировки;</li>\n' +
                      '          <li>обширные стоматологические поражения.</li>\n' +
                      '        </ul>',
                    sale: '10',
                    doctor: [ // TODO могут ли одну услугу оказывать несколько врачей?
                      {
                        id: 1,
                        lastName: 'Замза',
                        firstName: 'Грегор',
                        middleName: '',
                        specialization: 'Уролог',
                        main_photo: {
                          id: 16,
                          url: '../../assets/img/doctors/doc-2.png'
                        }
                      }
                    ],
                    documents: [
                      {
                        id: 1,
                        title: 'Паспорт',
                        // tslint:disable-next-line:max-line-length
                        desc: 'Проснувшись однажды утром после беспокойного сна, Грегор Замза обнаружил, что он у себя в постели превратился в страшное насекомое.',
                        main_photo:
                          {
                            id: 1,
                            url: '../../assets/img/doc-img-1.png',
                          }
                      }
                    ],
                    tags: [
                      {
                        id: 1,
                        name: 'Кровь',
                        desc: 'Описание тега'
                      },
                      {
                        id: 4,
                        name: 'Железо',
                        desc: 'Описание тега'
                      },
                      {
                        id: 5,
                        name: 'Белки',
                        desc: 'Описание тега'
                      }
                    ],
                    slider: [
                      {
                        id: 1,
                        // tslint:disable-next-line:max-line-length
                        title: 'Ощущения пациента во время проведения ультразвуковой эластографии такие же, как и при обычном УЗИ. Ни боли, ни дискомфорта пациенты не испытывают.',
                        // tslint:disable-next-line:max-line-length
                        desc: 'Ощущения пациента во время проведения ультразвуковой эластографии такие же, как и при обычном УЗИ. Ни боли, ни дискомфорта пациенты не испытывают.',
                        main_photo: {
                          url: '../../assets/img/big-slide-3.png'
                        },
                      },
                      {
                        id: 2,
                        // tslint:disable-next-line:max-line-length
                        title: 'Ощущения пациента во время проведения ',
                        // tslint:disable-next-line:max-line-length
                        desc: 'Ощущения пациента во время проведения ультразвуковой эластографии такие же, как и при обычном УЗИ. Ни боли, ни дискомфорта пациенты не испытывают.',
                        main_photo: {
                          url: '../../assets/img/big-slide-4.png'
                        }
                      }
                    ],
                    catalog:
                      {
                        id: 1,
                        name: 'Клинический анализ крови',
                        desc: 'Описание Клинический анализ крови'
                      }
                  },
                  {
                    id: 3,
                    name: 'УЗИ печеночного трансплантанта с дуплексным сканированием артерий и вен',
                    display_name: 'УЗИ печеночного трансплантанта с дуплексным сканированием артерий и вен',
                    price: '1320',
                    // tslint:disable-next-line:max-line-length
                    anons: 'Ультразвуковую диагностику смело можно назвать уникальным методом исследования. Она обладает такими свойствами как неинвазивность, возможность многократного проведения исследования без вреда для здоровья пациента, быстрота получения информации, отсутствие противопоказаний, исключение лучевой нагрузки.',
                    // tslint:disable-next-line:max-line-length
                    body: 'Проснувшись однажды утром после беспокойного сна, Грегор Замза обнаружил, что он у себя в постели превратился в страшное насекомое.',
                    // tslint:disable-next-line:max-line-length
                    memo: 'Вы должны подготовить себя к исследованию следующим образом: воздержаться от физических нагрузок, приема алкоголя и лекарств, изменений в питании в течение 24 часов до взятия крови. Вам не следует принимать пищу после ужина, лечь спать нужно в обычное для Вас время и встать не позднее, чем за час до взятия крови. Утром до сдачи анализа воздержитесь от курения и приема пищи. Взятие крови осуществляется до проведения диагностических или лечебных процедур: инъекций, общего массажа тела, эндоскопий, ЭКГ, рентгеновского обследования и т.д. Если Вы испытываете трудности с отменой лекарств, то обязательно сообщите об этом лечащему врачу. Очень важно, чтобы Вы точно соблюдали указанные рекомендации, так как только в этом случае будут получены ценные результаты исследования крови.',
                    // tslint:disable-next-line:max-line-length
                    indication: 'Анализ на свертываемость является одним из самых простых и доступных методов исследования. Пациенту нужно отказываться от приема пищи в течение двух часов перед исследованием. Результаты готовы, как правило, через несколько часов. Такое тестирование проводится в рамках комплексного обследования организма, а также при наличии кровотечений невыясненной природы и при диагностике или контроле эффективности лечения патологий костного мозга. Референсные значения зависят от возраста, а в детстве — еще и от пола.',
                    contraindic: '<ul>При некоторых заболеваниях проведение забора тромбоцитарной массы категорически запрещено: \n' +
                      '          <li>туберкулез;</li>\n' +
                      '          <li>вирус иммунодефицита;</li>\n' +
                      '          <li>раковые патологии;</li>\n' +
                      '          <li>гепатиты;</li>\n' +
                      '          <li>нарушения в работе сердца и сосудов;</li>\n' +
                      '          <li>после проведения оперативных вмешательств;</li>\n' +
                      '          <li>беременность;</li>\n' +
                      '          <li>алкоголизм;</li>\n' +
                      '          <li>недавнее введение прививки;</li>\n' +
                      '          <li>язва желудка или двенадцатиперстной кишки;</li>\n' +
                      '          <li>татуировки;</li>\n' +
                      '          <li>обширные стоматологические поражения.</li>\n' +
                      '        </ul>',
                    sale: '20',
                    doctor: [ // TODO могут ли одну услугу оказывать несколько врачей?
                      {
                        id: 1,
                        lastName: 'Коновалов',
                        firstName: 'Геннадий',
                        middleName: 'Александрович',
                        specialization: 'Главный ревматолог',
                        main_photo: {
                          id: 1,
                          url: '../../assets/img/doctors/doc-3.png'
                        }
                      },
                      {
                        id: 2,
                        lastName: 'Морозова',
                        firstName: 'Екатерина',
                        middleName: 'Владимировна',
                        specialization: 'ЛОР',
                        main_photo: {
                          id: 11,
                          url: '../../assets/img/doctors/doc-5.png'
                        }
                      }
                    ],
                    documents: [
                      {
                        id: 1,
                        title: 'Паспорт',
                        // tslint:disable-next-line:max-line-length
                        desc: 'Проснувшись однажды утром после беспокойного сна, Грегор Замза обнаружил, что он у себя в постели превратился в страшное насекомое.',
                        main_photo:
                          {
                            id: 1,
                            url: '../../assets/img/doc-img-1.png',
                          }
                      },
                      {
                        id: 2,
                        title: 'Медицинский полис',
                        // tslint:disable-next-line:max-line-length
                        desc: 'Проснувшись однажды утром после беспокойного сна, Грегор Замза обнаружил, что он у себя в постели превратился в страшное насекомое.',
                        main_photo:
                          {
                            id: 1,
                            url: '../../assets/img/doc-img-2.png',
                          }
                      },
                      {
                        id: 3,
                        title: 'Направление доктора',
                        // tslint:disable-next-line:max-line-length
                        desc: 'Проснувшись однажды утром после беспокойного сна, Грегор Замза обнаружил, что он у себя в постели превратился в страшное насекомое.',
                        main_photo:
                          {
                            id: 1,
                            url: '../../assets/img/doc-img-3.png',
                          }
                      }
                    ],
                    tags: [
                      {
                        id: 1,
                        name: 'Кровь',
                        desc: 'Описание тега'
                      },
                      {
                        id: 2,
                        name: 'Глюкоза',
                        desc: 'Описание тега'
                      },
                      {
                        id: 3,
                        name: 'Витамины',
                        desc: 'Описание тега'
                      }
                    ],
                    catalog:
                      {
                        id: 1,
                        name: 'Клинический анализ крови',
                        desc: 'Описание Клинический анализ крови'
                      }
                  },
                  {
                    id: 6,
                    name: 'УЗИ печеночного трансплантанта с дуплексным сканированием артерий и вен',
                    display_name: 'УЗИ печеночного трансплантанта с дуплексным сканированием артерий и вен',
                    price: '1320',
                    // tslint:disable-next-line:max-line-length
                    anons: 'Ультразвуковую диагностику смело можно назвать уникальным методом исследования. Она обладает такими свойствами как неинвазивность, возможность многократного проведения исследования без вреда для здоровья пациента, быстрота получения информации, отсутствие противопоказаний, исключение лучевой нагрузки.',
                    // tslint:disable-next-line:max-line-length
                    body: 'Проснувшись однажды утром после беспокойного сна, Грегор Замза обнаружил, что он у себя в постели превратился в страшное насекомое.',
                    // tslint:disable-next-line:max-line-length
                    memo: 'Вы должны подготовить себя к исследованию следующим образом: воздержаться от физических нагрузок, приема алкоголя и лекарств, изменений в питании в течение 24 часов до взятия крови. Вам не следует принимать пищу после ужина, лечь спать нужно в обычное для Вас время и встать не позднее, чем за час до взятия крови. Утром до сдачи анализа воздержитесь от курения и приема пищи. Взятие крови осуществляется до проведения диагностических или лечебных процедур: инъекций, общего массажа тела, эндоскопий, ЭКГ, рентгеновского обследования и т.д. Если Вы испытываете трудности с отменой лекарств, то обязательно сообщите об этом лечащему врачу. Очень важно, чтобы Вы точно соблюдали указанные рекомендации, так как только в этом случае будут получены ценные результаты исследования крови.',
                    // tslint:disable-next-line:max-line-length
                    indication: 'Анализ на свертываемость является одним из самых простых и доступных методов исследования. Пациенту нужно отказываться от приема пищи в течение двух часов перед исследованием. Результаты готовы, как правило, через несколько часов. Такое тестирование проводится в рамках комплексного обследования организма, а также при наличии кровотечений невыясненной природы и при диагностике или контроле эффективности лечения патологий костного мозга. Референсные значения зависят от возраста, а в детстве — еще и от пола.',
                    contraindic: '<ul>При некоторых заболеваниях проведение забора тромбоцитарной массы категорически запрещено: \n' +
                      '          <li>туберкулез;</li>\n' +
                      '          <li>вирус иммунодефицита;</li>\n' +
                      '          <li>раковые патологии;</li>\n' +
                      '          <li>гепатиты;</li>\n' +
                      '          <li>нарушения в работе сердца и сосудов;</li>\n' +
                      '          <li>после проведения оперативных вмешательств;</li>\n' +
                      '          <li>беременность;</li>\n' +
                      '          <li>алкоголизм;</li>\n' +
                      '          <li>недавнее введение прививки;</li>\n' +
                      '          <li>язва желудка или двенадцатиперстной кишки;</li>\n' +
                      '          <li>татуировки;</li>\n' +
                      '          <li>обширные стоматологические поражения.</li>\n' +
                      '        </ul>',
                    sale: '20',
                    tags: [
                      {
                        id: 1,
                        name: 'Кровь',
                        desc: 'Описание тега'
                      },
                      {
                        id: 2,
                        name: 'Глюкоза',
                        desc: 'Описание тега'
                      },
                      {
                        id: 3,
                        name: 'Витамины',
                        desc: 'Описание тега'
                      }
                    ],
                    catalog:
                      {
                        id: 1,
                        name: 'Анализы крови',
                        desc: 'Описание Клинический анализ крови'
                      }
                  },
                  {
                    id: 7,
                    name: 'Анализ крови артерий и вен',
                    display_name: 'Анализ крови артерий и вен',
                    price: '1320',
                    // tslint:disable-next-line:max-line-length
                    anons: 'Ультразвуковую диагностику смело можно назвать уникальным методом исследования. Она обладает такими свойствами как неинвазивность, возможность многократного проведения исследования без вреда для здоровья пациента, быстрота получения информации, отсутствие противопоказаний, исключение лучевой нагрузки.',
                    // tslint:disable-next-line:max-line-length
                    body: 'Проснувшись однажды утром после беспокойного сна, Грегор Замза обнаружил.',
                    // tslint:disable-next-line:max-line-length
                    memo: 'Вы должны подготовить себя к исследованию следующим образом: воздержаться от физических нагрузок, приема алкоголя и лекарств, изменений в питании в течение 24 часов до взятия крови. Вам не следует принимать пищу после ужина, лечь спать нужно в обычное для Вас время и встать не позднее, чем за час до взятия крови. Утром до сдачи анализа воздержитесь от курения и приема пищи. Взятие крови осуществляется до проведения диагностических или лечебных процедур: инъекций, общего массажа тела, эндоскопий, ЭКГ, рентгеновского обследования и т.д. Если Вы испытываете трудности с отменой лекарств, то обязательно сообщите об этом лечащему врачу. Очень важно, чтобы Вы точно соблюдали указанные рекомендации, так как только в этом случае будут получены ценные результаты исследования крови.',
                    // tslint:disable-next-line:max-line-length
                    indication: 'Анализ на свертываемость является одним из самых простых и доступных методов исследования. Пациенту нужно отказываться от приема пищи в течение двух часов перед исследованием. Результаты готовы, как правило, через несколько часов. Такое тестирование проводится в рамках комплексного обследования организма, а также при наличии кровотечений невыясненной природы и при диагностике или контроле эффективности лечения патологий костного мозга. Референсные значения зависят от возраста, а в детстве — еще и от пола.',
                    contraindic: '<ul>При некоторых заболеваниях проведение забора тромбоцитарной массы категорически запрещено: \n' +
                      '          <li>туберкулез;</li>\n' +
                      '          <li>вирус иммунодефицита;</li>\n' +
                      '          <li>раковые патологии;</li>\n' +
                      '          <li>гепатиты;</li>\n' +
                      '          <li>нарушения в работе сердца и сосудов;</li>\n' +
                      '          <li>после проведения оперативных вмешательств;</li>\n' +
                      '          <li>беременность;</li>\n' +
                      '          <li>алкоголизм;</li>\n' +
                      '          <li>недавнее введение прививки;</li>\n' +
                      '          <li>язва желудка или двенадцатиперстной кишки;</li>\n' +
                      '          <li>татуировки;</li>\n' +
                      '          <li>обширные стоматологические поражения.</li>\n' +
                      '        </ul>',
                    sale: '',
                    documents: [
                      {
                        id: 1,
                        title: 'Паспорт',
                        // tslint:disable-next-line:max-line-length
                        desc: 'Проснувшись однажды утром после беспокойного сна, Грегор Замза обнаружил, что он у себя в постели превратился в страшное насекомое.',
                        main_photo:
                          {
                            id: 1,
                            url: '../../assets/img/doc-img-1.png',
                          }
                      }
                    ],
                    tags: [
                      {
                        id: 1,
                        name: 'Кровь',
                        desc: 'Описание тега'
                      },
                      {
                        id: 2,
                        name: 'Глюкоза',
                        desc: 'Описание тега'
                      },
                      {
                        id: 3,
                        name: 'Гормоны',
                        desc: 'Описание тега'
                      }
                    ],
                    catalog:
                      {
                        id: 1,
                        name: 'Анализы крови',
                        desc: 'Описание Клинический анализ крови'
                      }
                  }
                ],
              },
              {
                id: 23,
                name: 'Эндоскопия',
                main_photo: {
                  id: 1,
                  url: '../../assets/img/direction2-1.png',
                },
                short_description: 'Краткое описание Эндоскопия',
                description: 'Полное описание Эндоскопия',
                icon: null,
                units: [
                  {
                    id: 2,
                    name: 'Поликлиника №1',
                    address: {
                      id: 1,
                      city: 'Красноярск',
                      street: 'Вавилова',
                      house: '28',
                      building: '',
                      ymapcfg: '../../assets/img/contacts-card-map.png',
                      postcode: '660025',
                      region: 'Красноярский край',
                      mailbox: '6545'
                    },
                    phones: [
                      {
                        id: 7,
                        number: '+7 (391) 274-31-64',
                        description: 'круглосуточно'
                      },
                      {
                        id: 8,
                        number: '+7 (391) 257-64-60',
                        description: 'с 9:00 до 17:00'
                      }
                    ],
                  },
                ],
                services: [
                  {
                    id: 5,
                    name: 'УЗИ печеночного трансплантанта с дуплексным сканированием артерий и вен',
                    display_name: 'УЗИ печеночного трансплантанта с дуплексным сканированием артерий и вен',
                    price: '1320',
                    // tslint:disable-next-line:max-line-length
                    anons: 'Ультразвуковую диагностику смело можно назвать уникальным методом исследования. Она обладает такими свойствами как неинвазивность, возможность многократного проведения исследования без вреда для здоровья пациента, быстрота получения информации, отсутствие противопоказаний, исключение лучевой нагрузки.',
                    // tslint:disable-next-line:max-line-length
                    body: 'Проснувшись однажды утром после беспокойного сна, Грегор Замза обнаружил, что он у себя в постели превратился в страшное насекомое.',
                    // tslint:disable-next-line:max-line-length
                    memo: 'Вы должны подготовить себя к исследованию следующим образом: воздержаться от физических нагрузок, приема алкоголя и лекарств, изменений в питании в течение 24 часов до взятия крови. Вам не следует принимать пищу после ужина, лечь спать нужно в обычное для Вас время и встать не позднее, чем за час до взятия крови. Утром до сдачи анализа воздержитесь от курения и приема пищи. Взятие крови осуществляется до проведения диагностических или лечебных процедур: инъекций, общего массажа тела, эндоскопий, ЭКГ, рентгеновского обследования и т.д. Если Вы испытываете трудности с отменой лекарств, то обязательно сообщите об этом лечащему врачу. Очень важно, чтобы Вы точно соблюдали указанные рекомендации, так как только в этом случае будут получены ценные результаты исследования крови.',
                    // tslint:disable-next-line:max-line-length
                    indication: 'Анализ на свертываемость является одним из самых простых и доступных методов исследования. Пациенту нужно отказываться от приема пищи в течение двух часов перед исследованием. Результаты готовы, как правило, через несколько часов. Такое тестирование проводится в рамках комплексного обследования организма, а также при наличии кровотечений невыясненной природы и при диагностике или контроле эффективности лечения патологий костного мозга. Референсные значения зависят от возраста, а в детстве — еще и от пола.',
                    contraindic: '<ul>При некоторых заболеваниях проведение забора тромбоцитарной массы категорически запрещено: \n' +
                      '          <li>туберкулез;</li>\n' +
                      '          <li>вирус иммунодефицита;</li>\n' +
                      '          <li>раковые патологии;</li>\n' +
                      '          <li>гепатиты;</li>\n' +
                      '          <li>нарушения в работе сердца и сосудов;</li>\n' +
                      '          <li>после проведения оперативных вмешательств;</li>\n' +
                      '          <li>беременность;</li>\n' +
                      '          <li>алкоголизм;</li>\n' +
                      '          <li>недавнее введение прививки;</li>\n' +
                      '          <li>язва желудка или двенадцатиперстной кишки;</li>\n' +
                      '          <li>татуировки;</li>\n' +
                      '          <li>обширные стоматологические поражения.</li>\n' +
                      '        </ul>',
                    sale: '',
                    documents: [
                      {
                        id: 1,
                        title: 'Паспорт',
                        // tslint:disable-next-line:max-line-length
                        desc: 'Проснувшись однажды утром после беспокойного сна, Грегор Замза обнаружил, что он у себя в постели превратился в страшное насекомое.',
                        main_photo:
                          {
                            id: 1,
                            url: '../../assets/img/doc-img-1.png',
                          }
                      }
                    ],
                    tags: [
                      {
                        id: 1,
                        name: 'Кровь',
                        desc: 'Описание тега'
                      },
                      {
                        id: 2,
                        name: 'Глюкоза',
                        desc: 'Описание тега'
                      },
                      {
                        id: 3,
                        name: 'Витамины',
                        desc: 'Описание тега'
                      }
                    ],
                    catalog:
                      {
                        id: 1,
                        name: 'УЗИ - диагностические исследования',
                        desc: 'Описание Клинический анализ крови'
                      }
                  },
                  {
                    id: 6,
                    name: 'УЗИ печеночного трансплантанта с дуплексным сканированием артерий и вен',
                    display_name: 'УЗИ печеночного трансплантанта с дуплексным сканированием артерий и вен',
                    price: '1320',
                    // tslint:disable-next-line:max-line-length
                    anons: 'Ультразвуковую диагностику смело можно назвать уникальным методом исследования. Она обладает такими свойствами как неинвазивность, возможность многократного проведения исследования без вреда для здоровья пациента, быстрота получения информации, отсутствие противопоказаний, исключение лучевой нагрузки.',
                    // tslint:disable-next-line:max-line-length
                    body: 'Проснувшись однажды утром после беспокойного сна, Грегор Замза обнаружил, что он у себя в постели превратился в страшное насекомое.',
                    // tslint:disable-next-line:max-line-length
                    memo: 'Вы должны подготовить себя к исследованию следующим образом: воздержаться от физических нагрузок, приема алкоголя и лекарств, изменений в питании в течение 24 часов до взятия крови. Вам не следует принимать пищу после ужина, лечь спать нужно в обычное для Вас время и встать не позднее, чем за час до взятия крови. Утром до сдачи анализа воздержитесь от курения и приема пищи. Взятие крови осуществляется до проведения диагностических или лечебных процедур: инъекций, общего массажа тела, эндоскопий, ЭКГ, рентгеновского обследования и т.д. Если Вы испытываете трудности с отменой лекарств, то обязательно сообщите об этом лечащему врачу. Очень важно, чтобы Вы точно соблюдали указанные рекомендации, так как только в этом случае будут получены ценные результаты исследования крови.',
                    // tslint:disable-next-line:max-line-length
                    indication: 'Анализ на свертываемость является одним из самых простых и доступных методов исследования. Пациенту нужно отказываться от приема пищи в течение двух часов перед исследованием. Результаты готовы, как правило, через несколько часов. Такое тестирование проводится в рамках комплексного обследования организма, а также при наличии кровотечений невыясненной природы и при диагностике или контроле эффективности лечения патологий костного мозга. Референсные значения зависят от возраста, а в детстве — еще и от пола.',
                    contraindic: '<ul>При некоторых заболеваниях проведение забора тромбоцитарной массы категорически запрещено: \n' +
                      '          <li>туберкулез;</li>\n' +
                      '          <li>вирус иммунодефицита;</li>\n' +
                      '          <li>раковые патологии;</li>\n' +
                      '          <li>гепатиты;</li>\n' +
                      '          <li>нарушения в работе сердца и сосудов;</li>\n' +
                      '          <li>после проведения оперативных вмешательств;</li>\n' +
                      '          <li>беременность;</li>\n' +
                      '          <li>алкоголизм;</li>\n' +
                      '          <li>недавнее введение прививки;</li>\n' +
                      '          <li>язва желудка или двенадцатиперстной кишки;</li>\n' +
                      '          <li>татуировки;</li>\n' +
                      '          <li>обширные стоматологические поражения.</li>\n' +
                      '        </ul>',
                    sale: '20',
                    tags: [
                      {
                        id: 1,
                        name: 'Кровь',
                        desc: 'Описание тега'
                      },
                      {
                        id: 2,
                        name: 'Глюкоза',
                        desc: 'Описание тега'
                      },
                      {
                        id: 3,
                        name: 'Витамины',
                        desc: 'Описание тега'
                      }
                    ],
                    catalog:
                      {
                        id: 1,
                        name: 'Анализы крови',
                        desc: 'Описание Клинический анализ крови'
                      }
                  },
                  {
                    id: 7,
                    name: 'Анализ крови артерий и вен',
                    display_name: 'Анализ крови артерий и вен',
                    price: '1320',
                    // tslint:disable-next-line:max-line-length
                    anons: 'Ультразвуковую диагностику смело можно назвать уникальным методом исследования. Она обладает такими свойствами как неинвазивность, возможность многократного проведения исследования без вреда для здоровья пациента, быстрота получения информации, отсутствие противопоказаний, исключение лучевой нагрузки.',
                    // tslint:disable-next-line:max-line-length
                    body: 'Проснувшись однажды утром после беспокойного сна, Грегор Замза обнаружил.',
                    // tslint:disable-next-line:max-line-length
                    memo: 'Вы должны подготовить себя к исследованию следующим образом: воздержаться от физических нагрузок, приема алкоголя и лекарств, изменений в питании в течение 24 часов до взятия крови. Вам не следует принимать пищу после ужина, лечь спать нужно в обычное для Вас время и встать не позднее, чем за час до взятия крови. Утром до сдачи анализа воздержитесь от курения и приема пищи. Взятие крови осуществляется до проведения диагностических или лечебных процедур: инъекций, общего массажа тела, эндоскопий, ЭКГ, рентгеновского обследования и т.д. Если Вы испытываете трудности с отменой лекарств, то обязательно сообщите об этом лечащему врачу. Очень важно, чтобы Вы точно соблюдали указанные рекомендации, так как только в этом случае будут получены ценные результаты исследования крови.',
                    // tslint:disable-next-line:max-line-length
                    indication: 'Анализ на свертываемость является одним из самых простых и доступных методов исследования. Пациенту нужно отказываться от приема пищи в течение двух часов перед исследованием. Результаты готовы, как правило, через несколько часов. Такое тестирование проводится в рамках комплексного обследования организма, а также при наличии кровотечений невыясненной природы и при диагностике или контроле эффективности лечения патологий костного мозга. Референсные значения зависят от возраста, а в детстве — еще и от пола.',
                    contraindic: '<ul>При некоторых заболеваниях проведение забора тромбоцитарной массы категорически запрещено: \n' +
                      '          <li>туберкулез;</li>\n' +
                      '          <li>вирус иммунодефицита;</li>\n' +
                      '          <li>раковые патологии;</li>\n' +
                      '          <li>гепатиты;</li>\n' +
                      '          <li>нарушения в работе сердца и сосудов;</li>\n' +
                      '          <li>после проведения оперативных вмешательств;</li>\n' +
                      '          <li>беременность;</li>\n' +
                      '          <li>алкоголизм;</li>\n' +
                      '          <li>недавнее введение прививки;</li>\n' +
                      '          <li>язва желудка или двенадцатиперстной кишки;</li>\n' +
                      '          <li>татуировки;</li>\n' +
                      '          <li>обширные стоматологические поражения.</li>\n' +
                      '        </ul>',
                    sale: '',
                    documents: [
                      {
                        id: 1,
                        title: 'Паспорт',
                        // tslint:disable-next-line:max-line-length
                        desc: 'Проснувшись однажды утром после беспокойного сна, Грегор Замза обнаружил, что он у себя в постели превратился в страшное насекомое.',
                        main_photo:
                          {
                            id: 1,
                            url: '../../assets/img/doc-img-1.png',
                          }
                      }
                    ],
                    tags: [
                      {
                        id: 1,
                        name: 'Кровь',
                        desc: 'Описание тега'
                      },
                      {
                        id: 2,
                        name: 'Глюкоза',
                        desc: 'Описание тега'
                      },
                      {
                        id: 3,
                        name: 'Гормоны',
                        desc: 'Описание тега'
                      }
                    ],
                    catalog:
                      {
                        id: 1,
                        name: 'Анализы крови',
                        desc: 'Описание Клинический анализ крови'
                      }
                  }
                ],
              }
            ],
            directions: [
              {
                id: 60,
                name: 'root',
                short_description: null,
                description: null,
                icon: null
              }
            ],
          },
          {
            id: 19,
            name: 'Амбулаторный прием',
            short_description: 'Проснувшись однажды утром после беспокойного сна, Грегор Замза обнаружил, что он у себя в постели превратился в страшное насекомое.',
            description: 'Полное описание Диагностика. Его многочисленные, убого тонкие по сравнению с остальным телом ножки беспомощно копошились у него перед глазами. «Что со мной случилось? » – подумал он. Это не было сном.',
            main_photo: { // TODO добавить
              id: 1,
              url: '../../assets/img/direction-2.png',
            },
            subdirections: [
              {
                id: 21,
                name: 'Рентген',
                main_photo: {
                  id: 1,
                  url: '../../assets/img/direction2-1.png',
                },
                short_description: 'Краткое описание Рентген',
                description: 'Полное описание Рентген',
                icon: null,
                units: [
                  {
                    id: 2,
                    name: 'Поликлиника №1',
                    address: {
                      id: 1,
                      city: 'Красноярск',
                      street: 'Вавилова',
                      house: '28',
                      building: '',
                      ymapcfg: '../../assets/img/contacts-card-map.png',
                      postcode: '660025',
                      region: 'Красноярский край',
                      mailbox: '6545'
                    },
                    phones: [
                      {
                        id: 7,
                        number: '+7 (391) 274-31-64',
                        description: 'круглосуточно'
                      },
                      {
                        id: 8,
                        number: '+7 (391) 257-64-60',
                        description: 'с 9:00 до 17:00'
                      }
                    ],
                  },
                  {
                    id: 3,
                    name: 'Поликлиника №3',
                    address: {
                      id: 1,
                      city: 'Красноярск',
                      street: 'Коломенская',
                      house: '28',
                      building: '',
                      ymapcfg: '../../assets/img/contacts-card-map.png',
                      postcode: '660025',
                      region: 'Красноярский край',
                      mailbox: '6545'
                    },
                    phones: [
                      {
                        id: 7,
                        number: '+7 (391) 274-31-64',
                        description: 'круглосуточно'
                      },
                      {
                        id: 8,
                        number: '+7 (391) 257-64-60',
                        description: 'с 9:00 до 17:00'
                      }
                    ],
                  },
                ],
                services: [
                  {
                    id: 4,
                    name: 'УЗИ печеночного трансплантанта с дуплексным сканированием артерий и вен',
                    display_name: 'УЗИ печеночного трансплантанта с дуплексным сканированием артерий и вен',
                    price: '1320',
                    // tslint:disable-next-line:max-line-length
                    anons: 'Ультразвуковую диагностику смело можно назвать уникальным методом исследования. Она обладает такими свойствами как неинвазивность, возможность многократного проведения исследования без вреда для здоровья пациента, быстрота получения информации, отсутствие противопоказаний, исключение лучевой нагрузки.',
                    // tslint:disable-next-line:max-line-length
                    body: 'Проснувшись однажды утром после беспокойного сна, Грегор Замза обнаружил, что он у себя в постели превратился в страшное насекомое.',
                    // tslint:disable-next-line:max-line-length
                    memo: 'Вы должны подготовить себя к исследованию следующим образом: воздержаться от физических нагрузок, приема алкоголя и лекарств, изменений в питании в течение 24 часов до взятия крови. Вам не следует принимать пищу после ужина, лечь спать нужно в обычное для Вас время и встать не позднее, чем за час до взятия крови. Утром до сдачи анализа воздержитесь от курения и приема пищи. Взятие крови осуществляется до проведения диагностических или лечебных процедур: инъекций, общего массажа тела, эндоскопий, ЭКГ, рентгеновского обследования и т.д. Если Вы испытываете трудности с отменой лекарств, то обязательно сообщите об этом лечащему врачу. Очень важно, чтобы Вы точно соблюдали указанные рекомендации, так как только в этом случае будут получены ценные результаты исследования крови.',
                    // tslint:disable-next-line:max-line-length
                    indication: 'Анализ на свертываемость является одним из самых простых и доступных методов исследования. Пациенту нужно отказываться от приема пищи в течение двух часов перед исследованием. Результаты готовы, как правило, через несколько часов. Такое тестирование проводится в рамках комплексного обследования организма, а также при наличии кровотечений невыясненной природы и при диагностике или контроле эффективности лечения патологий костного мозга. Референсные значения зависят от возраста, а в детстве — еще и от пола.',
                    contraindic: '<ul>При некоторых заболеваниях проведение забора тромбоцитарной массы категорически запрещено: \n' +
                      '          <li>туберкулез;</li>\n' +
                      '          <li>вирус иммунодефицита;</li>\n' +
                      '          <li>раковые патологии;</li>\n' +
                      '          <li>гепатиты;</li>\n' +
                      '          <li>нарушения в работе сердца и сосудов;</li>\n' +
                      '          <li>после проведения оперативных вмешательств;</li>\n' +
                      '          <li>беременность;</li>\n' +
                      '          <li>алкоголизм;</li>\n' +
                      '          <li>недавнее введение прививки;</li>\n' +
                      '          <li>язва желудка или двенадцатиперстной кишки;</li>\n' +
                      '          <li>татуировки;</li>\n' +
                      '          <li>обширные стоматологические поражения.</li>\n' +
                      '        </ul>',
                    sale: '',
                    documents: [
                      {
                        id: 1,
                        title: 'Паспорт',
                        // tslint:disable-next-line:max-line-length
                        desc: 'Проснувшись однажды утром после беспокойного сна, Грегор Замза обнаружил, что он у себя в постели превратился в страшное насекомое.',
                        main_photo:
                          {
                            id: 1,
                            url: '../../assets/img/doc-img-1.png',
                          }
                      }
                    ],
                    tags: [
                      {
                        id: 1,
                        name: 'Кровь',
                        desc: 'Описание тега'
                      },
                      {
                        id: 2,
                        name: 'Глюкоза',
                        desc: 'Описание тега'
                      },
                      {
                        id: 3,
                        name: 'Витамины',
                        desc: 'Описание тега'
                      }
                    ],
                    catalog:
                      {
                        id: 1,
                        name: 'Клинический анализ крови',
                        desc: 'Описание Клинический анализ крови'
                      }
                  },
                  {
                    id: 5,
                    name: 'УЗИ печеночного трансплантанта с дуплексным сканированием артерий и вен',
                    display_name: 'УЗИ печеночного трансплантанта с дуплексным сканированием артерий и вен',
                    price: '1320',
                    // tslint:disable-next-line:max-line-length
                    anons: 'Ультразвуковую диагностику смело можно назвать уникальным методом исследования. Она обладает такими свойствами как неинвазивность, возможность многократного проведения исследования без вреда для здоровья пациента, быстрота получения информации, отсутствие противопоказаний, исключение лучевой нагрузки.',
                    // tslint:disable-next-line:max-line-length
                    body: 'Проснувшись однажды утром после беспокойного сна, Грегор Замза обнаружил, что он у себя в постели превратился в страшное насекомое.',
                    // tslint:disable-next-line:max-line-length
                    memo: 'Вы должны подготовить себя к исследованию следующим образом: воздержаться от физических нагрузок, приема алкоголя и лекарств, изменений в питании в течение 24 часов до взятия крови. Вам не следует принимать пищу после ужина, лечь спать нужно в обычное для Вас время и встать не позднее, чем за час до взятия крови. Утром до сдачи анализа воздержитесь от курения и приема пищи. Взятие крови осуществляется до проведения диагностических или лечебных процедур: инъекций, общего массажа тела, эндоскопий, ЭКГ, рентгеновского обследования и т.д. Если Вы испытываете трудности с отменой лекарств, то обязательно сообщите об этом лечащему врачу. Очень важно, чтобы Вы точно соблюдали указанные рекомендации, так как только в этом случае будут получены ценные результаты исследования крови.',
                    // tslint:disable-next-line:max-line-length
                    indication: 'Анализ на свертываемость является одним из самых простых и доступных методов исследования. Пациенту нужно отказываться от приема пищи в течение двух часов перед исследованием. Результаты готовы, как правило, через несколько часов. Такое тестирование проводится в рамках комплексного обследования организма, а также при наличии кровотечений невыясненной природы и при диагностике или контроле эффективности лечения патологий костного мозга. Референсные значения зависят от возраста, а в детстве — еще и от пола.',
                    contraindic: '<ul>При некоторых заболеваниях проведение забора тромбоцитарной массы категорически запрещено: \n' +
                      '          <li>туберкулез;</li>\n' +
                      '          <li>вирус иммунодефицита;</li>\n' +
                      '          <li>раковые патологии;</li>\n' +
                      '          <li>гепатиты;</li>\n' +
                      '          <li>нарушения в работе сердца и сосудов;</li>\n' +
                      '          <li>после проведения оперативных вмешательств;</li>\n' +
                      '          <li>беременность;</li>\n' +
                      '          <li>алкоголизм;</li>\n' +
                      '          <li>недавнее введение прививки;</li>\n' +
                      '          <li>язва желудка или двенадцатиперстной кишки;</li>\n' +
                      '          <li>татуировки;</li>\n' +
                      '          <li>обширные стоматологические поражения.</li>\n' +
                      '        </ul>',
                    sale: '',
                    documents: [
                      {
                        id: 1,
                        title: 'Паспорт',
                        // tslint:disable-next-line:max-line-length
                        desc: 'Проснувшись однажды утром после беспокойного сна, Грегор Замза обнаружил, что он у себя в постели превратился в страшное насекомое.',
                        main_photo:
                          {
                            id: 1,
                            url: '../../assets/img/doc-img-1.png',
                          }
                      }
                    ],
                    tags: [
                      {
                        id: 1,
                        name: 'Кровь',
                        desc: 'Описание тега'
                      },
                      {
                        id: 2,
                        name: 'Глюкоза',
                        desc: 'Описание тега'
                      },
                      {
                        id: 3,
                        name: 'Витамины',
                        desc: 'Описание тега'
                      }
                    ],
                    catalog:
                      {
                        id: 1,
                        name: 'УЗИ - диагностические исследования',
                        desc: 'Описание Клинический анализ крови'
                      }
                  },
                  {
                    id: 6,
                    name: 'УЗИ печеночного трансплантанта с дуплексным сканированием артерий и вен',
                    display_name: 'УЗИ печеночного трансплантанта с дуплексным сканированием артерий и вен',
                    price: '1320',
                    // tslint:disable-next-line:max-line-length
                    anons: 'Ультразвуковую диагностику смело можно назвать уникальным методом исследования. Она обладает такими свойствами как неинвазивность, возможность многократного проведения исследования без вреда для здоровья пациента, быстрота получения информации, отсутствие противопоказаний, исключение лучевой нагрузки.',
                    // tslint:disable-next-line:max-line-length
                    body: 'Проснувшись однажды утром после беспокойного сна, Грегор Замза обнаружил, что он у себя в постели превратился в страшное насекомое.',
                    // tslint:disable-next-line:max-line-length
                    memo: 'Вы должны подготовить себя к исследованию следующим образом: воздержаться от физических нагрузок, приема алкоголя и лекарств, изменений в питании в течение 24 часов до взятия крови. Вам не следует принимать пищу после ужина, лечь спать нужно в обычное для Вас время и встать не позднее, чем за час до взятия крови. Утром до сдачи анализа воздержитесь от курения и приема пищи. Взятие крови осуществляется до проведения диагностических или лечебных процедур: инъекций, общего массажа тела, эндоскопий, ЭКГ, рентгеновского обследования и т.д. Если Вы испытываете трудности с отменой лекарств, то обязательно сообщите об этом лечащему врачу. Очень важно, чтобы Вы точно соблюдали указанные рекомендации, так как только в этом случае будут получены ценные результаты исследования крови.',
                    // tslint:disable-next-line:max-line-length
                    indication: 'Анализ на свертываемость является одним из самых простых и доступных методов исследования. Пациенту нужно отказываться от приема пищи в течение двух часов перед исследованием. Результаты готовы, как правило, через несколько часов. Такое тестирование проводится в рамках комплексного обследования организма, а также при наличии кровотечений невыясненной природы и при диагностике или контроле эффективности лечения патологий костного мозга. Референсные значения зависят от возраста, а в детстве — еще и от пола.',
                    contraindic: '<ul>При некоторых заболеваниях проведение забора тромбоцитарной массы категорически запрещено: \n' +
                      '          <li>туберкулез;</li>\n' +
                      '          <li>вирус иммунодефицита;</li>\n' +
                      '          <li>раковые патологии;</li>\n' +
                      '          <li>гепатиты;</li>\n' +
                      '          <li>нарушения в работе сердца и сосудов;</li>\n' +
                      '          <li>после проведения оперативных вмешательств;</li>\n' +
                      '          <li>беременность;</li>\n' +
                      '          <li>алкоголизм;</li>\n' +
                      '          <li>недавнее введение прививки;</li>\n' +
                      '          <li>язва желудка или двенадцатиперстной кишки;</li>\n' +
                      '          <li>татуировки;</li>\n' +
                      '          <li>обширные стоматологические поражения.</li>\n' +
                      '        </ul>',
                    sale: '20',
                    tags: [
                      {
                        id: 1,
                        name: 'Кровь',
                        desc: 'Описание тега'
                      },
                      {
                        id: 2,
                        name: 'Глюкоза',
                        desc: 'Описание тега'
                      },
                      {
                        id: 3,
                        name: 'Витамины',
                        desc: 'Описание тега'
                      }
                    ],
                    catalog:
                      {
                        id: 1,
                        name: 'Анализы крови',
                        desc: 'Описание Клинический анализ крови'
                      }
                  },
                  {
                    id: 7,
                    name: 'Анализ крови артерий и вен',
                    display_name: 'Анализ крови артерий и вен',
                    price: '1320',
                    // tslint:disable-next-line:max-line-length
                    anons: 'Ультразвуковую диагностику смело можно назвать уникальным методом исследования. Она обладает такими свойствами как неинвазивность, возможность многократного проведения исследования без вреда для здоровья пациента, быстрота получения информации, отсутствие противопоказаний, исключение лучевой нагрузки.',
                    // tslint:disable-next-line:max-line-length
                    body: 'Проснувшись однажды утром после беспокойного сна, Грегор Замза обнаружил.',
                    // tslint:disable-next-line:max-line-length
                    memo: 'Вы должны подготовить себя к исследованию следующим образом: воздержаться от физических нагрузок, приема алкоголя и лекарств, изменений в питании в течение 24 часов до взятия крови. Вам не следует принимать пищу после ужина, лечь спать нужно в обычное для Вас время и встать не позднее, чем за час до взятия крови. Утром до сдачи анализа воздержитесь от курения и приема пищи. Взятие крови осуществляется до проведения диагностических или лечебных процедур: инъекций, общего массажа тела, эндоскопий, ЭКГ, рентгеновского обследования и т.д. Если Вы испытываете трудности с отменой лекарств, то обязательно сообщите об этом лечащему врачу. Очень важно, чтобы Вы точно соблюдали указанные рекомендации, так как только в этом случае будут получены ценные результаты исследования крови.',
                    // tslint:disable-next-line:max-line-length
                    indication: 'Анализ на свертываемость является одним из самых простых и доступных методов исследования. Пациенту нужно отказываться от приема пищи в течение двух часов перед исследованием. Результаты готовы, как правило, через несколько часов. Такое тестирование проводится в рамках комплексного обследования организма, а также при наличии кровотечений невыясненной природы и при диагностике или контроле эффективности лечения патологий костного мозга. Референсные значения зависят от возраста, а в детстве — еще и от пола.',
                    contraindic: '<ul>При некоторых заболеваниях проведение забора тромбоцитарной массы категорически запрещено: \n' +
                      '          <li>туберкулез;</li>\n' +
                      '          <li>вирус иммунодефицита;</li>\n' +
                      '          <li>раковые патологии;</li>\n' +
                      '          <li>гепатиты;</li>\n' +
                      '          <li>нарушения в работе сердца и сосудов;</li>\n' +
                      '          <li>после проведения оперативных вмешательств;</li>\n' +
                      '          <li>беременность;</li>\n' +
                      '          <li>алкоголизм;</li>\n' +
                      '          <li>недавнее введение прививки;</li>\n' +
                      '          <li>язва желудка или двенадцатиперстной кишки;</li>\n' +
                      '          <li>татуировки;</li>\n' +
                      '          <li>обширные стоматологические поражения.</li>\n' +
                      '        </ul>',
                    sale: '',
                    documents: [
                      {
                        id: 1,
                        title: 'Паспорт',
                        // tslint:disable-next-line:max-line-length
                        desc: 'Проснувшись однажды утром после беспокойного сна, Грегор Замза обнаружил, что он у себя в постели превратился в страшное насекомое.',
                        main_photo:
                          {
                            id: 1,
                            url: '../../assets/img/doc-img-1.png',
                          }
                      }
                    ],
                    tags: [
                      {
                        id: 1,
                        name: 'Кровь',
                        desc: 'Описание тега'
                      },
                      {
                        id: 2,
                        name: 'Глюкоза',
                        desc: 'Описание тега'
                      },
                      {
                        id: 3,
                        name: 'Гормоны',
                        desc: 'Описание тега'
                      }
                    ],
                    catalog:
                      {
                        id: 1,
                        name: 'Анализы крови',
                        desc: 'Описание Клинический анализ крови'
                      }
                  }
                ],
              },
              {
                id: 24,
                name: 'Функциональная диагностика',
                main_photo: {
                  id: 1,
                  url: '../../assets/img/direction2-3.png',
                },
                short_description: 'Краткое описание Функциональная диагностика',
                description: 'Полное описание Функциональная диагностика',
                icon: null,
                units: [
                  {
                    id: 2,
                    name: 'Поликлиника №1',
                    address: {
                      id: 1,
                      city: 'Красноярск',
                      street: 'Вавилова',
                      house: '28',
                      building: '',
                      ymapcfg: '../../assets/img/contacts-card-map.png',
                      postcode: '660025',
                      region: 'Красноярский край',
                      mailbox: '6545'
                    },
                    phones: [
                      {
                        id: 7,
                        number: '+7 (391) 274-31-64',
                        description: 'круглосуточно'
                      },
                      {
                        id: 8,
                        number: '+7 (391) 257-64-60',
                        description: 'с 9:00 до 17:00'
                      }
                    ],
                  },
                  {
                    id: 3,
                    name: 'Поликлиника №3',
                    address: {
                      id: 1,
                      city: 'Красноярск',
                      street: 'Коломенская',
                      house: '28',
                      building: '',
                      ymapcfg: '../../assets/img/contacts-card-map.png',
                      postcode: '660025',
                      region: 'Красноярский край',
                      mailbox: '6545'
                    },
                    phones: [
                      {
                        id: 7,
                        number: '+7 (391) 274-31-64',
                        description: 'круглосуточно'
                      },
                      {
                        id: 8,
                        number: '+7 (391) 257-64-60',
                        description: 'с 9:00 до 17:00'
                      }
                    ],
                  },
                ],
                services: [
                  {
                    id: 5,
                    name: 'УЗИ печеночного трансплантанта с дуплексным сканированием артерий и вен',
                    display_name: 'УЗИ печеночного трансплантанта с дуплексным сканированием артерий и вен',
                    price: '1320',
                    // tslint:disable-next-line:max-line-length
                    anons: 'Ультразвуковую диагностику смело можно назвать уникальным методом исследования. Она обладает такими свойствами как неинвазивность, возможность многократного проведения исследования без вреда для здоровья пациента, быстрота получения информации, отсутствие противопоказаний, исключение лучевой нагрузки.',
                    // tslint:disable-next-line:max-line-length
                    body: 'Проснувшись однажды утром после беспокойного сна, Грегор Замза обнаружил, что он у себя в постели превратился в страшное насекомое.',
                    // tslint:disable-next-line:max-line-length
                    memo: 'Вы должны подготовить себя к исследованию следующим образом: воздержаться от физических нагрузок, приема алкоголя и лекарств, изменений в питании в течение 24 часов до взятия крови. Вам не следует принимать пищу после ужина, лечь спать нужно в обычное для Вас время и встать не позднее, чем за час до взятия крови. Утром до сдачи анализа воздержитесь от курения и приема пищи. Взятие крови осуществляется до проведения диагностических или лечебных процедур: инъекций, общего массажа тела, эндоскопий, ЭКГ, рентгеновского обследования и т.д. Если Вы испытываете трудности с отменой лекарств, то обязательно сообщите об этом лечащему врачу. Очень важно, чтобы Вы точно соблюдали указанные рекомендации, так как только в этом случае будут получены ценные результаты исследования крови.',
                    // tslint:disable-next-line:max-line-length
                    indication: 'Анализ на свертываемость является одним из самых простых и доступных методов исследования. Пациенту нужно отказываться от приема пищи в течение двух часов перед исследованием. Результаты готовы, как правило, через несколько часов. Такое тестирование проводится в рамках комплексного обследования организма, а также при наличии кровотечений невыясненной природы и при диагностике или контроле эффективности лечения патологий костного мозга. Референсные значения зависят от возраста, а в детстве — еще и от пола.',
                    contraindic: '<ul>При некоторых заболеваниях проведение забора тромбоцитарной массы категорически запрещено: \n' +
                      '          <li>туберкулез;</li>\n' +
                      '          <li>вирус иммунодефицита;</li>\n' +
                      '          <li>раковые патологии;</li>\n' +
                      '          <li>гепатиты;</li>\n' +
                      '          <li>нарушения в работе сердца и сосудов;</li>\n' +
                      '          <li>после проведения оперативных вмешательств;</li>\n' +
                      '          <li>беременность;</li>\n' +
                      '          <li>алкоголизм;</li>\n' +
                      '          <li>недавнее введение прививки;</li>\n' +
                      '          <li>язва желудка или двенадцатиперстной кишки;</li>\n' +
                      '          <li>татуировки;</li>\n' +
                      '          <li>обширные стоматологические поражения.</li>\n' +
                      '        </ul>',
                    sale: '',
                    documents: [
                      {
                        id: 1,
                        title: 'Паспорт',
                        // tslint:disable-next-line:max-line-length
                        desc: 'Проснувшись однажды утром после беспокойного сна, Грегор Замза обнаружил, что он у себя в постели превратился в страшное насекомое.',
                        main_photo:
                          {
                            id: 1,
                            url: '../../assets/img/doc-img-1.png',
                          }
                      }
                    ],
                    tags: [
                      {
                        id: 1,
                        name: 'Кровь',
                        desc: 'Описание тега'
                      },
                      {
                        id: 2,
                        name: 'Глюкоза',
                        desc: 'Описание тега'
                      },
                      {
                        id: 3,
                        name: 'Витамины',
                        desc: 'Описание тега'
                      }
                    ],
                    catalog:
                      {
                        id: 1,
                        name: 'УЗИ - диагностические исследования',
                        desc: 'Описание Клинический анализ крови'
                      }
                  },
                  {
                    id: 6,
                    name: 'УЗИ печеночного трансплантанта с дуплексным сканированием артерий и вен',
                    display_name: 'УЗИ печеночного трансплантанта с дуплексным сканированием артерий и вен',
                    price: '1320',
                    // tslint:disable-next-line:max-line-length
                    anons: 'Ультразвуковую диагностику смело можно назвать уникальным методом исследования. Она обладает такими свойствами как неинвазивность, возможность многократного проведения исследования без вреда для здоровья пациента, быстрота получения информации, отсутствие противопоказаний, исключение лучевой нагрузки.',
                    // tslint:disable-next-line:max-line-length
                    body: 'Проснувшись однажды утром после беспокойного сна, Грегор Замза обнаружил, что он у себя в постели превратился в страшное насекомое.',
                    // tslint:disable-next-line:max-line-length
                    memo: 'Вы должны подготовить себя к исследованию следующим образом: воздержаться от физических нагрузок, приема алкоголя и лекарств, изменений в питании в течение 24 часов до взятия крови. Вам не следует принимать пищу после ужина, лечь спать нужно в обычное для Вас время и встать не позднее, чем за час до взятия крови. Утром до сдачи анализа воздержитесь от курения и приема пищи. Взятие крови осуществляется до проведения диагностических или лечебных процедур: инъекций, общего массажа тела, эндоскопий, ЭКГ, рентгеновского обследования и т.д. Если Вы испытываете трудности с отменой лекарств, то обязательно сообщите об этом лечащему врачу. Очень важно, чтобы Вы точно соблюдали указанные рекомендации, так как только в этом случае будут получены ценные результаты исследования крови.',
                    // tslint:disable-next-line:max-line-length
                    indication: 'Анализ на свертываемость является одним из самых простых и доступных методов исследования. Пациенту нужно отказываться от приема пищи в течение двух часов перед исследованием. Результаты готовы, как правило, через несколько часов. Такое тестирование проводится в рамках комплексного обследования организма, а также при наличии кровотечений невыясненной природы и при диагностике или контроле эффективности лечения патологий костного мозга. Референсные значения зависят от возраста, а в детстве — еще и от пола.',
                    contraindic: '<ul>При некоторых заболеваниях проведение забора тромбоцитарной массы категорически запрещено: \n' +
                      '          <li>туберкулез;</li>\n' +
                      '          <li>вирус иммунодефицита;</li>\n' +
                      '          <li>раковые патологии;</li>\n' +
                      '          <li>гепатиты;</li>\n' +
                      '          <li>нарушения в работе сердца и сосудов;</li>\n' +
                      '          <li>после проведения оперативных вмешательств;</li>\n' +
                      '          <li>беременность;</li>\n' +
                      '          <li>алкоголизм;</li>\n' +
                      '          <li>недавнее введение прививки;</li>\n' +
                      '          <li>язва желудка или двенадцатиперстной кишки;</li>\n' +
                      '          <li>татуировки;</li>\n' +
                      '          <li>обширные стоматологические поражения.</li>\n' +
                      '        </ul>',
                    sale: '20',
                    tags: [
                      {
                        id: 1,
                        name: 'Кровь',
                        desc: 'Описание тега'
                      },
                      {
                        id: 2,
                        name: 'Глюкоза',
                        desc: 'Описание тега'
                      },
                      {
                        id: 3,
                        name: 'Витамины',
                        desc: 'Описание тега'
                      }
                    ],
                    catalog:
                      {
                        id: 1,
                        name: 'Анализы крови',
                        desc: 'Описание Клинический анализ крови'
                      }
                  },
                  {
                    id: 7,
                    name: 'Анализ крови артерий и вен',
                    display_name: 'Анализ крови артерий и вен',
                    price: '1320',
                    // tslint:disable-next-line:max-line-length
                    anons: 'Ультразвуковую диагностику смело можно назвать уникальным методом исследования. Она обладает такими свойствами как неинвазивность, возможность многократного проведения исследования без вреда для здоровья пациента, быстрота получения информации, отсутствие противопоказаний, исключение лучевой нагрузки.',
                    // tslint:disable-next-line:max-line-length
                    body: 'Проснувшись однажды утром после беспокойного сна, Грегор Замза обнаружил.',
                    // tslint:disable-next-line:max-line-length
                    memo: 'Вы должны подготовить себя к исследованию следующим образом: воздержаться от физических нагрузок, приема алкоголя и лекарств, изменений в питании в течение 24 часов до взятия крови. Вам не следует принимать пищу после ужина, лечь спать нужно в обычное для Вас время и встать не позднее, чем за час до взятия крови. Утром до сдачи анализа воздержитесь от курения и приема пищи. Взятие крови осуществляется до проведения диагностических или лечебных процедур: инъекций, общего массажа тела, эндоскопий, ЭКГ, рентгеновского обследования и т.д. Если Вы испытываете трудности с отменой лекарств, то обязательно сообщите об этом лечащему врачу. Очень важно, чтобы Вы точно соблюдали указанные рекомендации, так как только в этом случае будут получены ценные результаты исследования крови.',
                    // tslint:disable-next-line:max-line-length
                    indication: 'Анализ на свертываемость является одним из самых простых и доступных методов исследования. Пациенту нужно отказываться от приема пищи в течение двух часов перед исследованием. Результаты готовы, как правило, через несколько часов. Такое тестирование проводится в рамках комплексного обследования организма, а также при наличии кровотечений невыясненной природы и при диагностике или контроле эффективности лечения патологий костного мозга. Референсные значения зависят от возраста, а в детстве — еще и от пола.',
                    contraindic: '<ul>При некоторых заболеваниях проведение забора тромбоцитарной массы категорически запрещено: \n' +
                      '          <li>туберкулез;</li>\n' +
                      '          <li>вирус иммунодефицита;</li>\n' +
                      '          <li>раковые патологии;</li>\n' +
                      '          <li>гепатиты;</li>\n' +
                      '          <li>нарушения в работе сердца и сосудов;</li>\n' +
                      '          <li>после проведения оперативных вмешательств;</li>\n' +
                      '          <li>беременность;</li>\n' +
                      '          <li>алкоголизм;</li>\n' +
                      '          <li>недавнее введение прививки;</li>\n' +
                      '          <li>язва желудка или двенадцатиперстной кишки;</li>\n' +
                      '          <li>татуировки;</li>\n' +
                      '          <li>обширные стоматологические поражения.</li>\n' +
                      '        </ul>',
                    sale: '',
                    documents: [
                      {
                        id: 1,
                        title: 'Паспорт',
                        // tslint:disable-next-line:max-line-length
                        desc: 'Проснувшись однажды утром после беспокойного сна, Грегор Замза обнаружил, что он у себя в постели превратился в страшное насекомое.',
                        main_photo:
                          {
                            id: 1,
                            url: '../../assets/img/doc-img-1.png',
                          }
                      }
                    ],
                    tags: [
                      {
                        id: 1,
                        name: 'Кровь',
                        desc: 'Описание тега'
                      },
                      {
                        id: 2,
                        name: 'Глюкоза',
                        desc: 'Описание тега'
                      },
                      {
                        id: 3,
                        name: 'Гормоны',
                        desc: 'Описание тега'
                      }
                    ],
                    catalog:
                      {
                        id: 1,
                        name: 'Анализы крови',
                        desc: 'Описание Клинический анализ крови'
                      }
                  }
                ],
              },
              {
                id: 22,
                name: 'УЗИ',
                main_photo: {
                  id: 1,
                  url: '../../assets/img/direction2-2.png',
                },
                short_description: 'Краткое описание УЗИ',
                description: 'Полное описание УЗИ',
                icon: null,
                units: [
                  {
                    id: 3,
                    name: 'Поликлиника №3',
                    address: {
                      id: 1,
                      city: 'Красноярск',
                      street: 'Коломенская',
                      house: '28',
                      building: '',
                      ymapcfg: '../../assets/img/contacts-card-map.png',
                      postcode: '660025',
                      region: 'Красноярский край',
                      mailbox: '6545'
                    },
                    phones: [
                      {
                        id: 7,
                        number: '+7 (391) 274-31-64',
                        description: 'круглосуточно'
                      },
                      {
                        id: 8,
                        number: '+7 (391) 257-64-60',
                        description: 'с 9:00 до 17:00'
                      }
                    ],
                  },
                ],
                services: [
                  {
                    id: 2,
                    // tslint:disable-next-line:max-line-length
                    name: 'Ультразвуковое исследование органов брюшной полости комплексное: печень, поджелудочная железа, желчный пузырь, селезенка при профилактическом осмотре',
                    display_name: 'УЗИ брюшной полости',
                    price: '820',
                    // tslint:disable-next-line:max-line-length
                    anons: 'Ультразвуковую диагностику смело можно назвать уникальным методом исследования. Она обладает такими свойствами как неинвазивность, возможность многократного проведения исследования без вреда для здоровья пациента, быстрота получения информации, отсутствие противопоказаний, исключение лучевой нагрузки.',
                    // tslint:disable-next-line:max-line-length
                    body: 'Проснувшись однажды утром после беспокойного сна, Грегор Замза обнаружил, что он у себя в постели превратился в страшное насекомое.',
                    // tslint:disable-next-line:max-line-length
                    memo: 'Вы должны подготовить себя к исследованию следующим образом: воздержаться от физических нагрузок, приема алкоголя и лекарств, изменений в питании в течение 24 часов до взятия крови. Вам не следует принимать пищу после ужина, лечь спать нужно в обычное для Вас время и встать не позднее, чем за час до взятия крови. Утром до сдачи анализа воздержитесь от курения и приема пищи. Взятие крови осуществляется до проведения диагностических или лечебных процедур: инъекций, общего массажа тела, эндоскопий, ЭКГ, рентгеновского обследования и т.д. Если Вы испытываете трудности с отменой лекарств, то обязательно сообщите об этом лечащему врачу. Очень важно, чтобы Вы точно соблюдали указанные рекомендации, так как только в этом случае будут получены ценные результаты исследования крови.',
                    // tslint:disable-next-line:max-line-length
                    indication: 'Анализ на свертываемость является одним из самых простых и доступных методов исследования. Пациенту нужно отказываться от приема пищи в течение двух часов перед исследованием. Результаты готовы, как правило, через несколько часов. Такое тестирование проводится в рамках комплексного обследования организма, а также при наличии кровотечений невыясненной природы и при диагностике или контроле эффективности лечения патологий костного мозга. Референсные значения зависят от возраста, а в детстве — еще и от пола.',
                    contraindic: '<ul>При некоторых заболеваниях проведение забора тромбоцитарной массы категорически запрещено: \n' +
                      '          <li>туберкулез;</li>\n' +
                      '          <li>вирус иммунодефицита;</li>\n' +
                      '          <li>раковые патологии;</li>\n' +
                      '          <li>гепатиты;</li>\n' +
                      '          <li>нарушения в работе сердца и сосудов;</li>\n' +
                      '          <li>после проведения оперативных вмешательств;</li>\n' +
                      '          <li>беременность;</li>\n' +
                      '          <li>алкоголизм;</li>\n' +
                      '          <li>недавнее введение прививки;</li>\n' +
                      '          <li>язва желудка или двенадцатиперстной кишки;</li>\n' +
                      '          <li>татуировки;</li>\n' +
                      '          <li>обширные стоматологические поражения.</li>\n' +
                      '        </ul>',
                    sale: '10',
                    doctor: [ // TODO могут ли одну услугу оказывать несколько врачей?
                      {
                        id: 1,
                        lastName: 'Замза',
                        firstName: 'Грегор',
                        middleName: '',
                        specialization: 'Уролог',
                        main_photo: {
                          id: 16,
                          url: '../../assets/img/doctors/doc-2.png'
                        }
                      }
                    ],
                    documents: [
                      {
                        id: 1,
                        title: 'Паспорт',
                        // tslint:disable-next-line:max-line-length
                        desc: 'Проснувшись однажды утром после беспокойного сна, Грегор Замза обнаружил, что он у себя в постели превратился в страшное насекомое.',
                        main_photo:
                          {
                            id: 1,
                            url: '../../assets/img/doc-img-1.png',
                          }
                      }
                    ],
                    tags: [
                      {
                        id: 1,
                        name: 'Кровь',
                        desc: 'Описание тега'
                      },
                      {
                        id: 4,
                        name: 'Железо',
                        desc: 'Описание тега'
                      },
                      {
                        id: 5,
                        name: 'Белки',
                        desc: 'Описание тега'
                      }
                    ],
                    slider: [
                      {
                        id: 1,
                        // tslint:disable-next-line:max-line-length
                        title: 'Ощущения пациента во время проведения ультразвуковой эластографии такие же, как и при обычном УЗИ. Ни боли, ни дискомфорта пациенты не испытывают.',
                        // tslint:disable-next-line:max-line-length
                        desc: 'Ощущения пациента во время проведения ультразвуковой эластографии такие же, как и при обычном УЗИ. Ни боли, ни дискомфорта пациенты не испытывают.',
                        main_photo: {
                          url: '../../assets/img/big-slide-3.png'
                        },
                      },
                      {
                        id: 2,
                        // tslint:disable-next-line:max-line-length
                        title: 'Ощущения пациента во время проведения ',
                        // tslint:disable-next-line:max-line-length
                        desc: 'Ощущения пациента во время проведения ультразвуковой эластографии такие же, как и при обычном УЗИ. Ни боли, ни дискомфорта пациенты не испытывают.',
                        main_photo: {
                          url: '../../assets/img/big-slide-4.png'
                        }
                      }
                    ],
                    catalog:
                      {
                        id: 1,
                        name: 'Клинический анализ крови',
                        desc: 'Описание Клинический анализ крови'
                      }
                  },
                  {
                    id: 3,
                    name: 'УЗИ печеночного трансплантанта с дуплексным сканированием артерий и вен',
                    display_name: 'УЗИ печеночного трансплантанта с дуплексным сканированием артерий и вен',
                    price: '1320',
                    // tslint:disable-next-line:max-line-length
                    anons: 'Ультразвуковую диагностику смело можно назвать уникальным методом исследования. Она обладает такими свойствами как неинвазивность, возможность многократного проведения исследования без вреда для здоровья пациента, быстрота получения информации, отсутствие противопоказаний, исключение лучевой нагрузки.',
                    // tslint:disable-next-line:max-line-length
                    body: 'Проснувшись однажды утром после беспокойного сна, Грегор Замза обнаружил, что он у себя в постели превратился в страшное насекомое.',
                    // tslint:disable-next-line:max-line-length
                    memo: 'Вы должны подготовить себя к исследованию следующим образом: воздержаться от физических нагрузок, приема алкоголя и лекарств, изменений в питании в течение 24 часов до взятия крови. Вам не следует принимать пищу после ужина, лечь спать нужно в обычное для Вас время и встать не позднее, чем за час до взятия крови. Утром до сдачи анализа воздержитесь от курения и приема пищи. Взятие крови осуществляется до проведения диагностических или лечебных процедур: инъекций, общего массажа тела, эндоскопий, ЭКГ, рентгеновского обследования и т.д. Если Вы испытываете трудности с отменой лекарств, то обязательно сообщите об этом лечащему врачу. Очень важно, чтобы Вы точно соблюдали указанные рекомендации, так как только в этом случае будут получены ценные результаты исследования крови.',
                    // tslint:disable-next-line:max-line-length
                    indication: 'Анализ на свертываемость является одним из самых простых и доступных методов исследования. Пациенту нужно отказываться от приема пищи в течение двух часов перед исследованием. Результаты готовы, как правило, через несколько часов. Такое тестирование проводится в рамках комплексного обследования организма, а также при наличии кровотечений невыясненной природы и при диагностике или контроле эффективности лечения патологий костного мозга. Референсные значения зависят от возраста, а в детстве — еще и от пола.',
                    contraindic: '<ul>При некоторых заболеваниях проведение забора тромбоцитарной массы категорически запрещено: \n' +
                      '          <li>туберкулез;</li>\n' +
                      '          <li>вирус иммунодефицита;</li>\n' +
                      '          <li>раковые патологии;</li>\n' +
                      '          <li>гепатиты;</li>\n' +
                      '          <li>нарушения в работе сердца и сосудов;</li>\n' +
                      '          <li>после проведения оперативных вмешательств;</li>\n' +
                      '          <li>беременность;</li>\n' +
                      '          <li>алкоголизм;</li>\n' +
                      '          <li>недавнее введение прививки;</li>\n' +
                      '          <li>язва желудка или двенадцатиперстной кишки;</li>\n' +
                      '          <li>татуировки;</li>\n' +
                      '          <li>обширные стоматологические поражения.</li>\n' +
                      '        </ul>',
                    sale: '20',
                    doctor: [ // TODO могут ли одну услугу оказывать несколько врачей?
                      {
                        id: 1,
                        lastName: 'Коновалов',
                        firstName: 'Геннадий',
                        middleName: 'Александрович',
                        specialization: 'Главный ревматолог',
                        main_photo: {
                          id: 1,
                          url: '../../assets/img/doctors/doc-3.png'
                        }
                      },
                      {
                        id: 2,
                        lastName: 'Морозова',
                        firstName: 'Екатерина',
                        middleName: 'Владимировна',
                        specialization: 'ЛОР',
                        main_photo: {
                          id: 11,
                          url: '../../assets/img/doctors/doc-5.png'
                        }
                      }
                    ],
                    documents: [
                      {
                        id: 1,
                        title: 'Паспорт',
                        // tslint:disable-next-line:max-line-length
                        desc: 'Проснувшись однажды утром после беспокойного сна, Грегор Замза обнаружил, что он у себя в постели превратился в страшное насекомое.',
                        main_photo:
                          {
                            id: 1,
                            url: '../../assets/img/doc-img-1.png',
                          }
                      },
                      {
                        id: 2,
                        title: 'Медицинский полис',
                        // tslint:disable-next-line:max-line-length
                        desc: 'Проснувшись однажды утром после беспокойного сна, Грегор Замза обнаружил, что он у себя в постели превратился в страшное насекомое.',
                        main_photo:
                          {
                            id: 1,
                            url: '../../assets/img/doc-img-2.png',
                          }
                      },
                      {
                        id: 3,
                        title: 'Направление доктора',
                        // tslint:disable-next-line:max-line-length
                        desc: 'Проснувшись однажды утром после беспокойного сна, Грегор Замза обнаружил, что он у себя в постели превратился в страшное насекомое.',
                        main_photo:
                          {
                            id: 1,
                            url: '../../assets/img/doc-img-3.png',
                          }
                      }
                    ],
                    tags: [
                      {
                        id: 1,
                        name: 'Кровь',
                        desc: 'Описание тега'
                      },
                      {
                        id: 2,
                        name: 'Глюкоза',
                        desc: 'Описание тега'
                      },
                      {
                        id: 3,
                        name: 'Витамины',
                        desc: 'Описание тега'
                      }
                    ],
                    catalog:
                      {
                        id: 1,
                        name: 'Клинический анализ крови',
                        desc: 'Описание Клинический анализ крови'
                      }
                  },
                  {
                    id: 6,
                    name: 'УЗИ печеночного трансплантанта с дуплексным сканированием артерий и вен',
                    display_name: 'УЗИ печеночного трансплантанта с дуплексным сканированием артерий и вен',
                    price: '1320',
                    // tslint:disable-next-line:max-line-length
                    anons: 'Ультразвуковую диагностику смело можно назвать уникальным методом исследования. Она обладает такими свойствами как неинвазивность, возможность многократного проведения исследования без вреда для здоровья пациента, быстрота получения информации, отсутствие противопоказаний, исключение лучевой нагрузки.',
                    // tslint:disable-next-line:max-line-length
                    body: 'Проснувшись однажды утром после беспокойного сна, Грегор Замза обнаружил, что он у себя в постели превратился в страшное насекомое.',
                    // tslint:disable-next-line:max-line-length
                    memo: 'Вы должны подготовить себя к исследованию следующим образом: воздержаться от физических нагрузок, приема алкоголя и лекарств, изменений в питании в течение 24 часов до взятия крови. Вам не следует принимать пищу после ужина, лечь спать нужно в обычное для Вас время и встать не позднее, чем за час до взятия крови. Утром до сдачи анализа воздержитесь от курения и приема пищи. Взятие крови осуществляется до проведения диагностических или лечебных процедур: инъекций, общего массажа тела, эндоскопий, ЭКГ, рентгеновского обследования и т.д. Если Вы испытываете трудности с отменой лекарств, то обязательно сообщите об этом лечащему врачу. Очень важно, чтобы Вы точно соблюдали указанные рекомендации, так как только в этом случае будут получены ценные результаты исследования крови.',
                    // tslint:disable-next-line:max-line-length
                    indication: 'Анализ на свертываемость является одним из самых простых и доступных методов исследования. Пациенту нужно отказываться от приема пищи в течение двух часов перед исследованием. Результаты готовы, как правило, через несколько часов. Такое тестирование проводится в рамках комплексного обследования организма, а также при наличии кровотечений невыясненной природы и при диагностике или контроле эффективности лечения патологий костного мозга. Референсные значения зависят от возраста, а в детстве — еще и от пола.',
                    contraindic: '<ul>При некоторых заболеваниях проведение забора тромбоцитарной массы категорически запрещено: \n' +
                      '          <li>туберкулез;</li>\n' +
                      '          <li>вирус иммунодефицита;</li>\n' +
                      '          <li>раковые патологии;</li>\n' +
                      '          <li>гепатиты;</li>\n' +
                      '          <li>нарушения в работе сердца и сосудов;</li>\n' +
                      '          <li>после проведения оперативных вмешательств;</li>\n' +
                      '          <li>беременность;</li>\n' +
                      '          <li>алкоголизм;</li>\n' +
                      '          <li>недавнее введение прививки;</li>\n' +
                      '          <li>язва желудка или двенадцатиперстной кишки;</li>\n' +
                      '          <li>татуировки;</li>\n' +
                      '          <li>обширные стоматологические поражения.</li>\n' +
                      '        </ul>',
                    sale: '20',
                    tags: [
                      {
                        id: 1,
                        name: 'Кровь',
                        desc: 'Описание тега'
                      },
                      {
                        id: 2,
                        name: 'Глюкоза',
                        desc: 'Описание тега'
                      },
                      {
                        id: 3,
                        name: 'Витамины',
                        desc: 'Описание тега'
                      }
                    ],
                    catalog:
                      {
                        id: 1,
                        name: 'Анализы крови',
                        desc: 'Описание Клинический анализ крови'
                      }
                  },
                  {
                    id: 7,
                    name: 'Анализ крови артерий и вен',
                    display_name: 'Анализ крови артерий и вен',
                    price: '1320',
                    // tslint:disable-next-line:max-line-length
                    anons: 'Ультразвуковую диагностику смело можно назвать уникальным методом исследования. Она обладает такими свойствами как неинвазивность, возможность многократного проведения исследования без вреда для здоровья пациента, быстрота получения информации, отсутствие противопоказаний, исключение лучевой нагрузки.',
                    // tslint:disable-next-line:max-line-length
                    body: 'Проснувшись однажды утром после беспокойного сна, Грегор Замза обнаружил.',
                    // tslint:disable-next-line:max-line-length
                    memo: 'Вы должны подготовить себя к исследованию следующим образом: воздержаться от физических нагрузок, приема алкоголя и лекарств, изменений в питании в течение 24 часов до взятия крови. Вам не следует принимать пищу после ужина, лечь спать нужно в обычное для Вас время и встать не позднее, чем за час до взятия крови. Утром до сдачи анализа воздержитесь от курения и приема пищи. Взятие крови осуществляется до проведения диагностических или лечебных процедур: инъекций, общего массажа тела, эндоскопий, ЭКГ, рентгеновского обследования и т.д. Если Вы испытываете трудности с отменой лекарств, то обязательно сообщите об этом лечащему врачу. Очень важно, чтобы Вы точно соблюдали указанные рекомендации, так как только в этом случае будут получены ценные результаты исследования крови.',
                    // tslint:disable-next-line:max-line-length
                    indication: 'Анализ на свертываемость является одним из самых простых и доступных методов исследования. Пациенту нужно отказываться от приема пищи в течение двух часов перед исследованием. Результаты готовы, как правило, через несколько часов. Такое тестирование проводится в рамках комплексного обследования организма, а также при наличии кровотечений невыясненной природы и при диагностике или контроле эффективности лечения патологий костного мозга. Референсные значения зависят от возраста, а в детстве — еще и от пола.',
                    contraindic: '<ul>При некоторых заболеваниях проведение забора тромбоцитарной массы категорически запрещено: \n' +
                      '          <li>туберкулез;</li>\n' +
                      '          <li>вирус иммунодефицита;</li>\n' +
                      '          <li>раковые патологии;</li>\n' +
                      '          <li>гепатиты;</li>\n' +
                      '          <li>нарушения в работе сердца и сосудов;</li>\n' +
                      '          <li>после проведения оперативных вмешательств;</li>\n' +
                      '          <li>беременность;</li>\n' +
                      '          <li>алкоголизм;</li>\n' +
                      '          <li>недавнее введение прививки;</li>\n' +
                      '          <li>язва желудка или двенадцатиперстной кишки;</li>\n' +
                      '          <li>татуировки;</li>\n' +
                      '          <li>обширные стоматологические поражения.</li>\n' +
                      '        </ul>',
                    sale: '',
                    documents: [
                      {
                        id: 1,
                        title: 'Паспорт',
                        // tslint:disable-next-line:max-line-length
                        desc: 'Проснувшись однажды утром после беспокойного сна, Грегор Замза обнаружил, что он у себя в постели превратился в страшное насекомое.',
                        main_photo:
                          {
                            id: 1,
                            url: '../../assets/img/doc-img-1.png',
                          }
                      }
                    ],
                    tags: [
                      {
                        id: 1,
                        name: 'Кровь',
                        desc: 'Описание тега'
                      },
                      {
                        id: 2,
                        name: 'Глюкоза',
                        desc: 'Описание тега'
                      },
                      {
                        id: 3,
                        name: 'Гормоны',
                        desc: 'Описание тега'
                      }
                    ],
                    catalog:
                      {
                        id: 1,
                        name: 'Анализы крови',
                        desc: 'Описание Клинический анализ крови'
                      }
                  }
                ],
              },
              {
                id: 23,
                name: 'Эндоскопия',
                main_photo: {
                  id: 1,
                  url: '../../assets/img/direction2-1.png',
                },
                short_description: 'Краткое описание Эндоскопия',
                description: 'Полное описание Эндоскопия',
                icon: null,
                units: [
                  {
                    id: 2,
                    name: 'Поликлиника №1',
                    address: {
                      id: 1,
                      city: 'Красноярск',
                      street: 'Вавилова',
                      house: '28',
                      building: '',
                      ymapcfg: '../../assets/img/contacts-card-map.png',
                      postcode: '660025',
                      region: 'Красноярский край',
                      mailbox: '6545'
                    },
                    phones: [
                      {
                        id: 7,
                        number: '+7 (391) 274-31-64',
                        description: 'круглосуточно'
                      },
                      {
                        id: 8,
                        number: '+7 (391) 257-64-60',
                        description: 'с 9:00 до 17:00'
                      }
                    ],
                  },
                ],
                services: [
                  {
                    id: 5,
                    name: 'УЗИ печеночного трансплантанта с дуплексным сканированием артерий и вен',
                    display_name: 'УЗИ печеночного трансплантанта с дуплексным сканированием артерий и вен',
                    price: '1320',
                    // tslint:disable-next-line:max-line-length
                    anons: 'Ультразвуковую диагностику смело можно назвать уникальным методом исследования. Она обладает такими свойствами как неинвазивность, возможность многократного проведения исследования без вреда для здоровья пациента, быстрота получения информации, отсутствие противопоказаний, исключение лучевой нагрузки.',
                    // tslint:disable-next-line:max-line-length
                    body: 'Проснувшись однажды утром после беспокойного сна, Грегор Замза обнаружил, что он у себя в постели превратился в страшное насекомое.',
                    // tslint:disable-next-line:max-line-length
                    memo: 'Вы должны подготовить себя к исследованию следующим образом: воздержаться от физических нагрузок, приема алкоголя и лекарств, изменений в питании в течение 24 часов до взятия крови. Вам не следует принимать пищу после ужина, лечь спать нужно в обычное для Вас время и встать не позднее, чем за час до взятия крови. Утром до сдачи анализа воздержитесь от курения и приема пищи. Взятие крови осуществляется до проведения диагностических или лечебных процедур: инъекций, общего массажа тела, эндоскопий, ЭКГ, рентгеновского обследования и т.д. Если Вы испытываете трудности с отменой лекарств, то обязательно сообщите об этом лечащему врачу. Очень важно, чтобы Вы точно соблюдали указанные рекомендации, так как только в этом случае будут получены ценные результаты исследования крови.',
                    // tslint:disable-next-line:max-line-length
                    indication: 'Анализ на свертываемость является одним из самых простых и доступных методов исследования. Пациенту нужно отказываться от приема пищи в течение двух часов перед исследованием. Результаты готовы, как правило, через несколько часов. Такое тестирование проводится в рамках комплексного обследования организма, а также при наличии кровотечений невыясненной природы и при диагностике или контроле эффективности лечения патологий костного мозга. Референсные значения зависят от возраста, а в детстве — еще и от пола.',
                    contraindic: '<ul>При некоторых заболеваниях проведение забора тромбоцитарной массы категорически запрещено: \n' +
                      '          <li>туберкулез;</li>\n' +
                      '          <li>вирус иммунодефицита;</li>\n' +
                      '          <li>раковые патологии;</li>\n' +
                      '          <li>гепатиты;</li>\n' +
                      '          <li>нарушения в работе сердца и сосудов;</li>\n' +
                      '          <li>после проведения оперативных вмешательств;</li>\n' +
                      '          <li>беременность;</li>\n' +
                      '          <li>алкоголизм;</li>\n' +
                      '          <li>недавнее введение прививки;</li>\n' +
                      '          <li>язва желудка или двенадцатиперстной кишки;</li>\n' +
                      '          <li>татуировки;</li>\n' +
                      '          <li>обширные стоматологические поражения.</li>\n' +
                      '        </ul>',
                    sale: '',
                    documents: [
                      {
                        id: 1,
                        title: 'Паспорт',
                        // tslint:disable-next-line:max-line-length
                        desc: 'Проснувшись однажды утром после беспокойного сна, Грегор Замза обнаружил, что он у себя в постели превратился в страшное насекомое.',
                        main_photo:
                          {
                            id: 1,
                            url: '../../assets/img/doc-img-1.png',
                          }
                      }
                    ],
                    tags: [
                      {
                        id: 1,
                        name: 'Кровь',
                        desc: 'Описание тега'
                      },
                      {
                        id: 2,
                        name: 'Глюкоза',
                        desc: 'Описание тега'
                      },
                      {
                        id: 3,
                        name: 'Витамины',
                        desc: 'Описание тега'
                      }
                    ],
                    catalog:
                      {
                        id: 1,
                        name: 'УЗИ - диагностические исследования',
                        desc: 'Описание Клинический анализ крови'
                      }
                  },
                  {
                    id: 6,
                    name: 'УЗИ печеночного трансплантанта с дуплексным сканированием артерий и вен',
                    display_name: 'УЗИ печеночного трансплантанта с дуплексным сканированием артерий и вен',
                    price: '1320',
                    // tslint:disable-next-line:max-line-length
                    anons: 'Ультразвуковую диагностику смело можно назвать уникальным методом исследования. Она обладает такими свойствами как неинвазивность, возможность многократного проведения исследования без вреда для здоровья пациента, быстрота получения информации, отсутствие противопоказаний, исключение лучевой нагрузки.',
                    // tslint:disable-next-line:max-line-length
                    body: 'Проснувшись однажды утром после беспокойного сна, Грегор Замза обнаружил, что он у себя в постели превратился в страшное насекомое.',
                    // tslint:disable-next-line:max-line-length
                    memo: 'Вы должны подготовить себя к исследованию следующим образом: воздержаться от физических нагрузок, приема алкоголя и лекарств, изменений в питании в течение 24 часов до взятия крови. Вам не следует принимать пищу после ужина, лечь спать нужно в обычное для Вас время и встать не позднее, чем за час до взятия крови. Утром до сдачи анализа воздержитесь от курения и приема пищи. Взятие крови осуществляется до проведения диагностических или лечебных процедур: инъекций, общего массажа тела, эндоскопий, ЭКГ, рентгеновского обследования и т.д. Если Вы испытываете трудности с отменой лекарств, то обязательно сообщите об этом лечащему врачу. Очень важно, чтобы Вы точно соблюдали указанные рекомендации, так как только в этом случае будут получены ценные результаты исследования крови.',
                    // tslint:disable-next-line:max-line-length
                    indication: 'Анализ на свертываемость является одним из самых простых и доступных методов исследования. Пациенту нужно отказываться от приема пищи в течение двух часов перед исследованием. Результаты готовы, как правило, через несколько часов. Такое тестирование проводится в рамках комплексного обследования организма, а также при наличии кровотечений невыясненной природы и при диагностике или контроле эффективности лечения патологий костного мозга. Референсные значения зависят от возраста, а в детстве — еще и от пола.',
                    contraindic: '<ul>При некоторых заболеваниях проведение забора тромбоцитарной массы категорически запрещено: \n' +
                      '          <li>туберкулез;</li>\n' +
                      '          <li>вирус иммунодефицита;</li>\n' +
                      '          <li>раковые патологии;</li>\n' +
                      '          <li>гепатиты;</li>\n' +
                      '          <li>нарушения в работе сердца и сосудов;</li>\n' +
                      '          <li>после проведения оперативных вмешательств;</li>\n' +
                      '          <li>беременность;</li>\n' +
                      '          <li>алкоголизм;</li>\n' +
                      '          <li>недавнее введение прививки;</li>\n' +
                      '          <li>язва желудка или двенадцатиперстной кишки;</li>\n' +
                      '          <li>татуировки;</li>\n' +
                      '          <li>обширные стоматологические поражения.</li>\n' +
                      '        </ul>',
                    sale: '20',
                    tags: [
                      {
                        id: 1,
                        name: 'Кровь',
                        desc: 'Описание тега'
                      },
                      {
                        id: 2,
                        name: 'Глюкоза',
                        desc: 'Описание тега'
                      },
                      {
                        id: 3,
                        name: 'Витамины',
                        desc: 'Описание тега'
                      }
                    ],
                    catalog:
                      {
                        id: 1,
                        name: 'Анализы крови',
                        desc: 'Описание Клинический анализ крови'
                      }
                  },
                  {
                    id: 7,
                    name: 'Анализ крови артерий и вен',
                    display_name: 'Анализ крови артерий и вен',
                    price: '1320',
                    // tslint:disable-next-line:max-line-length
                    anons: 'Ультразвуковую диагностику смело можно назвать уникальным методом исследования. Она обладает такими свойствами как неинвазивность, возможность многократного проведения исследования без вреда для здоровья пациента, быстрота получения информации, отсутствие противопоказаний, исключение лучевой нагрузки.',
                    // tslint:disable-next-line:max-line-length
                    body: 'Проснувшись однажды утром после беспокойного сна, Грегор Замза обнаружил.',
                    // tslint:disable-next-line:max-line-length
                    memo: 'Вы должны подготовить себя к исследованию следующим образом: воздержаться от физических нагрузок, приема алкоголя и лекарств, изменений в питании в течение 24 часов до взятия крови. Вам не следует принимать пищу после ужина, лечь спать нужно в обычное для Вас время и встать не позднее, чем за час до взятия крови. Утром до сдачи анализа воздержитесь от курения и приема пищи. Взятие крови осуществляется до проведения диагностических или лечебных процедур: инъекций, общего массажа тела, эндоскопий, ЭКГ, рентгеновского обследования и т.д. Если Вы испытываете трудности с отменой лекарств, то обязательно сообщите об этом лечащему врачу. Очень важно, чтобы Вы точно соблюдали указанные рекомендации, так как только в этом случае будут получены ценные результаты исследования крови.',
                    // tslint:disable-next-line:max-line-length
                    indication: 'Анализ на свертываемость является одним из самых простых и доступных методов исследования. Пациенту нужно отказываться от приема пищи в течение двух часов перед исследованием. Результаты готовы, как правило, через несколько часов. Такое тестирование проводится в рамках комплексного обследования организма, а также при наличии кровотечений невыясненной природы и при диагностике или контроле эффективности лечения патологий костного мозга. Референсные значения зависят от возраста, а в детстве — еще и от пола.',
                    contraindic: '<ul>При некоторых заболеваниях проведение забора тромбоцитарной массы категорически запрещено: \n' +
                      '          <li>туберкулез;</li>\n' +
                      '          <li>вирус иммунодефицита;</li>\n' +
                      '          <li>раковые патологии;</li>\n' +
                      '          <li>гепатиты;</li>\n' +
                      '          <li>нарушения в работе сердца и сосудов;</li>\n' +
                      '          <li>после проведения оперативных вмешательств;</li>\n' +
                      '          <li>беременность;</li>\n' +
                      '          <li>алкоголизм;</li>\n' +
                      '          <li>недавнее введение прививки;</li>\n' +
                      '          <li>язва желудка или двенадцатиперстной кишки;</li>\n' +
                      '          <li>татуировки;</li>\n' +
                      '          <li>обширные стоматологические поражения.</li>\n' +
                      '        </ul>',
                    sale: '',
                    documents: [
                      {
                        id: 1,
                        title: 'Паспорт',
                        // tslint:disable-next-line:max-line-length
                        desc: 'Проснувшись однажды утром после беспокойного сна, Грегор Замза обнаружил, что он у себя в постели превратился в страшное насекомое.',
                        main_photo:
                          {
                            id: 1,
                            url: '../../assets/img/doc-img-1.png',
                          }
                      }
                    ],
                    tags: [
                      {
                        id: 1,
                        name: 'Кровь',
                        desc: 'Описание тега'
                      },
                      {
                        id: 2,
                        name: 'Глюкоза',
                        desc: 'Описание тега'
                      },
                      {
                        id: 3,
                        name: 'Гормоны',
                        desc: 'Описание тега'
                      }
                    ],
                    catalog:
                      {
                        id: 1,
                        name: 'Анализы крови',
                        desc: 'Описание Клинический анализ крови'
                      }
                  }
                ],
              }
            ],
            directions: [
              {
                id: 60,
                name: 'root',
                short_description: null,
                description: null,
                icon: null
              }
            ],
          },
        ],
        services: [
          {
            id: 1,
            name: 'УЗИ печеночного трансплантанта с дуплексным сканированием артерий и вен',
            display_name: 'УЗИ печеночного трансплантанта с дуплексным сканированием артерий и вен',
            price: '1320',
            // tslint:disable-next-line:max-line-length
            anons: 'Ультразвуковую диагностику смело можно назвать уникальным методом исследования. Она обладает такими свойствами как неинвазивность, возможность многократного проведения исследования без вреда для здоровья пациента, быстрота получения информации, отсутствие противопоказаний, исключение лучевой нагрузки.',
            // tslint:disable-next-line:max-line-length
            body: 'Проснувшись однажды утром после беспокойного сна, Грегор Замза обнаружил, что он у себя в постели превратился в страшное насекомое.',
            // tslint:disable-next-line:max-line-length
            memo: 'Вы должны подготовить себя к исследованию следующим образом: воздержаться от физических нагрузок, приема алкоголя и лекарств, изменений в питании в течение 24 часов до взятия крови. Вам не следует принимать пищу после ужина, лечь спать нужно в обычное для Вас время и встать не позднее, чем за час до взятия крови. Утром до сдачи анализа воздержитесь от курения и приема пищи. Взятие крови осуществляется до проведения диагностических или лечебных процедур: инъекций, общего массажа тела, эндоскопий, ЭКГ, рентгеновского обследования и т.д. Если Вы испытываете трудности с отменой лекарств, то обязательно сообщите об этом лечащему врачу. Очень важно, чтобы Вы точно соблюдали указанные рекомендации, так как только в этом случае будут получены ценные результаты исследования крови.',
            // tslint:disable-next-line:max-line-length
            indication: 'Анализ на свертываемость является одним из самых простых и доступных методов исследования. Пациенту нужно отказываться от приема пищи в течение двух часов перед исследованием. Результаты готовы, как правило, через несколько часов. Такое тестирование проводится в рамках комплексного обследования организма, а также при наличии кровотечений невыясненной природы и при диагностике или контроле эффективности лечения патологий костного мозга. Референсные значения зависят от возраста, а в детстве — еще и от пола.',
            contraindic: '<ul>При некоторых заболеваниях проведение забора тромбоцитарной массы категорически запрещено: \n' +
              '          <li>туберкулез;</li>\n' +
              '          <li>вирус иммунодефицита;</li>\n' +
              '          <li>раковые патологии;</li>\n' +
              '          <li>гепатиты;</li>\n' +
              '          <li>нарушения в работе сердца и сосудов;</li>\n' +
              '          <li>после проведения оперативных вмешательств;</li>\n' +
              '          <li>беременность;</li>\n' +
              '          <li>алкоголизм;</li>\n' +
              '          <li>недавнее введение прививки;</li>\n' +
              '          <li>язва желудка или двенадцатиперстной кишки;</li>\n' +
              '          <li>татуировки;</li>\n' +
              '          <li>обширные стоматологические поражения.</li>\n' +
              '        </ul>',
            sale: '',
            doctor: [ // TODO могут ли одну услугу оказывать несколько врачей?
              {
                id: 2,
                lastName: 'Морозова',
                firstName: 'Екатерина',
                middleName: 'Владимировна',
                specialization: 'ЛОР',
                main_photo: {
                  id: 11,
                  url: '../../assets/img/doctors/doc-5.png'
                }
              }
            ],
            documents: [
              {
                id: 1,
                title: 'Паспорт',
                // tslint:disable-next-line:max-line-length
                desc: 'Проснувшись однажды утром после беспокойного сна, Грегор Замза обнаружил, что он у себя в постели превратился в страшное насекомое.',
                main_photo:
                  {
                    id: 1,
                    url: '../../assets/img/doc-img-1.png',
                  }
              },
              {
                id: 2,
                title: 'Медицинский полис',
                // tslint:disable-next-line:max-line-length
                desc: 'Проснувшись однажды утром после беспокойного сна, Грегор Замза обнаружил, что он у себя в постели превратился в страшное насекомое.',
                main_photo:
                  {
                    id: 1,
                    url: '../../assets/img/doc-img-2.png',
                  }
              },
              {
                id: 3,
                title: 'Направление доктора',
                // tslint:disable-next-line:max-line-length
                desc: 'Проснувшись однажды утром после беспокойного сна, Грегор Замза обнаружил, что он у себя в постели превратился в страшное насекомое.',
                main_photo:
                  {
                    id: 1,
                    url: '../../assets/img/doc-img-3.png',
                  }
              }
            ],
            tags: [
              {
                id: 1,
                name: 'Кровь',
                desc: 'Описание тега'
              },
              {
                id: 2,
                name: 'Глюкоза',
                desc: 'Описание тега'
              },
              {
                id: 3,
                name: 'Витамины',
                desc: 'Описание тега'
              }
            ],
            slider: [
              {
                id: 1,
                // tslint:disable-next-line:max-line-length
                title: 'Ощущения пациента во время проведения ультразвуковой эластографии такие же, как и при обычном УЗИ. Ни боли, ни дискомфорта пациенты не испытывают.',
                // tslint:disable-next-line:max-line-length
                desc: 'Ощущения пациента во время проведения ультразвуковой эластографии такие же, как и при обычном УЗИ. Ни боли, ни дискомфорта пациенты не испытывают.',
                main_photo: {
                  url: '../../assets/img/big-slide-3.png'
                },
              },
              {
                id: 2,
                // tslint:disable-next-line:max-line-length
                title: 'Ощущения пациента во время проведения ',
                // tslint:disable-next-line:max-line-length
                desc: 'Ощущения пациента во время проведения ультразвуковой эластографии такие же, как и при обычном УЗИ. Ни боли, ни дискомфорта пациенты не испытывают.',
                main_photo: {
                  url: '../../assets/img/big-slide-4.png'
                }
              },
              {
                id: 3,
                // tslint:disable-next-line:max-line-length
                title: 'Ощущения пациента во время проведения ультразвуковой эластографии такие же, как и при обычном УЗИ. Ни боли, ни дискомфорта пациенты не испытывают.',
                // tslint:disable-next-line:max-line-length
                desc: 'Ощущения пациента во время проведения ультразвуковой эластографии такие же, как и при обычном УЗИ. Ни боли, ни дискомфорта пациенты не испытывают.',
                main_photo: {
                  url: '../../assets/img/big-slide-3.png'
                },
              },
              {
                id: 4,
                // tslint:disable-next-line:max-line-length
                title: 'Ощущения пациента во время проведения ',
                // tslint:disable-next-line:max-line-length
                desc: 'Ощущения пациента во время проведения ультразвуковой эластографии такие же, как и при обычном УЗИ. Ни боли, ни дискомфорта пациенты не испытывают.',
                main_photo: {
                  url: '../../assets/img/big-slide-4.png'
                }
              }
            ],
            catalog:
              {
                id: 1,
                name: 'Клинический анализ крови',
                desc: 'Описание Клинический анализ крови'
              }
          },
          {
            id: 2,
            name: 'Вторая услуга и она со скидкой',
            display_name: 'Вторая услуга и она со скидкой. УЗИ печеночного трансплантанта с дуплексным сканированием артерий и вен',
            price: '2502',
            // tslint:disable-next-line:max-line-length
            anons: 'Ультразвуковую диагностику смело можно назвать уникальным методом исследования. Она обладает такими свойствами как неинвазивность, возможность многократного проведения исследования без вреда для здоровья пациента, быстрота получения информации, отсутствие противопоказаний, исключение лучевой нагрузки.',
            // tslint:disable-next-line:max-line-length
            body: 'Проснувшись однажды утром после беспокойного сна, Грегор Замза обнаружил, что он у себя в постели превратился в страшное насекомое.',
            // tslint:disable-next-line:max-line-length
            memo: 'Вы должны подготовить себя к исследованию следующим образом: воздержаться от физических нагрузок, приема алкоголя и лекарств, изменений в питании в течение 24 часов до взятия крови. Вам не следует принимать пищу после ужина, лечь спать нужно в обычное для Вас время и встать не позднее, чем за час до взятия крови. Утром до сдачи анализа воздержитесь от курения и приема пищи. Взятие крови осуществляется до проведения диагностических или лечебных процедур: инъекций, общего массажа тела, эндоскопий, ЭКГ, рентгеновского обследования и т.д. Если Вы испытываете трудности с отменой лекарств, то обязательно сообщите об этом лечащему врачу. Очень важно, чтобы Вы точно соблюдали указанные рекомендации, так как только в этом случае будут получены ценные результаты исследования крови.',
            // tslint:disable-next-line:max-line-length
            indication: 'Анализ на свертываемость является одним из самых простых и доступных методов исследования. Пациенту нужно отказываться от приема пищи в течение двух часов перед исследованием. Результаты готовы, как правило, через несколько часов. Такое тестирование проводится в рамках комплексного обследования организма, а также при наличии кровотечений невыясненной природы и при диагностике или контроле эффективности лечения патологий костного мозга. Референсные значения зависят от возраста, а в детстве — еще и от пола.',
            contraindic: '<ul>При некоторых заболеваниях проведение забора тромбоцитарной массы категорически запрещено: \n' +
              '          <li>туберкулез;</li>\n' +
              '          <li>вирус иммунодефицита;</li>\n' +
              '          <li>раковые патологии;</li>\n' +
              '          <li>гепатиты;</li>\n' +
              '          <li>нарушения в работе сердца и сосудов;</li>\n' +
              '          <li>после проведения оперативных вмешательств;</li>\n' +
              '          <li>беременность;</li>\n' +
              '          <li>алкоголизм;</li>\n' +
              '          <li>недавнее введение прививки;</li>\n' +
              '          <li>язва желудка или двенадцатиперстной кишки;</li>\n' +
              '          <li>татуировки;</li>\n' +
              '          <li>обширные стоматологические поражения.</li>\n' +
              '        </ul>',
            sale: '13',
            doctor: [ // TODO могут ли одну услугу оказывать несколько врачей?
              {
                id: 2,
                lastName: 'Морозова',
                firstName: 'Екатерина',
                middleName: 'Владимировна',
                specialization: 'ЛОР',
                main_photo: {
                  id: 11,
                  url: '../../assets/img/doctors/doc-5.png'
                }
              }
            ],
            documents: [
              {
                id: 1,
                title: 'Паспорт',
                // tslint:disable-next-line:max-line-length
                desc: 'Проснувшись однажды утром после беспокойного сна, Грегор Замза обнаружил, что он у себя в постели превратился в страшное насекомое.',
                main_photo:
                  {
                    id: 1,
                    url: '../../assets/img/doc-img-1.png',
                  }
              },
              {
                id: 2,
                title: 'Медицинский полис',
                // tslint:disable-next-line:max-line-length
                desc: 'Проснувшись однажды утром после беспокойного сна, Грегор Замза обнаружил, что он у себя в постели превратился в страшное насекомое.',
                main_photo:
                  {
                    id: 1,
                    url: '../../assets/img/doc-img-2.png',
                  }
              },
              {
                id: 3,
                title: 'Направление доктора',
                // tslint:disable-next-line:max-line-length
                desc: 'Проснувшись однажды утром после беспокойного сна, Грегор Замза обнаружил, что он у себя в постели превратился в страшное насекомое.',
                main_photo:
                  {
                    id: 1,
                    url: '../../assets/img/doc-img-3.png',
                  }
              }
            ],
            tags: [
              {
                id: 1,
                name: 'Кровь',
                desc: 'Описание тега'
              },
              {
                id: 2,
                name: 'Глюкоза',
                desc: 'Описание тега'
              },
              {
                id: 3,
                name: 'Витамины',
                desc: 'Описание тега'
              }
            ],
            slider: [
              {
                id: 1,
                // tslint:disable-next-line:max-line-length
                title: 'Ощущения пациента во время проведения ультразвуковой эластографии такие же, как и при обычном УЗИ. Ни боли, ни дискомфорта пациенты не испытывают.',
                // tslint:disable-next-line:max-line-length
                desc: 'Ощущения пациента во время проведения ультразвуковой эластографии такие же, как и при обычном УЗИ. Ни боли, ни дискомфорта пациенты не испытывают.',
                main_photo: {
                  url: '../../assets/img/big-slide-3.png'
                },
              },
              {
                id: 2,
                // tslint:disable-next-line:max-line-length
                title: 'Ощущения пациента во время проведения ',
                // tslint:disable-next-line:max-line-length
                desc: 'Ощущения пациента во время проведения ультразвуковой эластографии такие же, как и при обычном УЗИ. Ни боли, ни дискомфорта пациенты не испытывают.',
                main_photo: {
                  url: '../../assets/img/big-slide-4.png'
                }
              },
              {
                id: 3,
                // tslint:disable-next-line:max-line-length
                title: 'Ощущения пациента во время проведения ультразвуковой эластографии такие же, как и при обычном УЗИ. Ни боли, ни дискомфорта пациенты не испытывают.',
                // tslint:disable-next-line:max-line-length
                desc: 'Ощущения пациента во время проведения ультразвуковой эластографии такие же, как и при обычном УЗИ. Ни боли, ни дискомфорта пациенты не испытывают.',
                main_photo: {
                  url: '../../assets/img/big-slide-3.png'
                },
              },
              {
                id: 4,
                // tslint:disable-next-line:max-line-length
                title: 'Ощущения пациента во время проведения ',
                // tslint:disable-next-line:max-line-length
                desc: 'Ощущения пациента во время проведения ультразвуковой эластографии такие же, как и при обычном УЗИ. Ни боли, ни дискомфорта пациенты не испытывают.',
                main_photo: {
                  url: '../../assets/img/big-slide-4.png'
                }
              }
            ],
            catalog:
              {
                id: 1,
                name: 'Клинический анализ крови',
                desc: 'Описание Клинический анализ крови'
              }
          },

        ],
        tags: {
          id: 2,
          name: 'Желудок',
        },

      },
      {
        id: 4,
        name: 'Болезни периодонта',
        description: 'Болезни периодонта. Что такое геморрой? Такой вопрос возникает у людей, которые впервые испытали дискомфорт в заднем проходе. Геморрой — это увеличение кавернозных тел (сосудов), сопровождающееся их истончением, деформацией и формированием геморроидальных узлов. В народе часто говорят, что геморроидальный недуг — это варикозная болезнь сосудов анального прохода. Деструкция стенок кишечника может провоцировать анальные кровотечения и выпадение узлов из ануса.',
        anonce: 'Болезни периодонта. УЗИ эластография (соноэластография) – дополнительная технология, используемая во время традиционного УЗИ, которая даёт возможность оценивать эластичность тканей исследуемого органа.',
        bodyText: '<div class="paragraph">Болезни периодонта. Все ткани человека, в том числе и повреждённые тем или иным патологическим процессом, имеют определённую эластичность (жёсткость). Нераковые ткани (здоровые, доброкачественные опухоли, воспалительный процесс) имеют высокую эластичность. В отличии от них, ткани злокачественных новообразований значительно менее эластичны (более жёсткие, жёсткость некоторых из них в несколько раз выше нормы). Во время ультразвуковой эластографии, ультразвуковой луч, излучаемый и воспринимаемый специальным датчиком, словно врач, «ощупывает» ткани исследуемого органа и с помощью программы оценивает их эластичность (жёсткость), что невозможно при обычном УЗИ. После компьютерной обработки изображение передаётся на экран монитора.</div>\n' +
          '            <div class="paragraph">Соноэластографический метод помогает выполнить диагностику злокачественных образований молочной железы, органов мошонки, узловых образований щитовидной железы, поверхностно расположенных лимфатических узлов, мягких тканей и других органов.</div>\n' +
          '            <div class="my-4 my-md-5"><img src="../../assets/img/big-slide-3.png" alt=""/></div>\n' +
          '            <div class="paragraph">Соноэластографический метод помогает выполнить диагностику злокачественных образований молочной железы, органов мошонки, узловых образований щитовидной железы, поверхностно расположенных лимфатических узлов, мягких тканей и других органов.</div>\n' +
          '            <div class="paragraph">Ощущения пациента во время проведения ультразвуковой эластографии такие же, как и при обычном УЗИ. Ни боли, ни дискомфорта пациенты не испытывают.</div>',
        catalog: {
          id: 1,
          name: 'В. Какая-то диагностика'
        },
        directions: [
          {
            id: 20,
            name: 'Болезни периодонта. Диагностика',
            short_description: 'Проснувшись однажды утром после беспокойного сна, Грегор Замза обнаружил, что он у себя в постели превратился в страшное насекомое. Лежа на панцирнотвердой спине, он видел, стоило ему приподнять голову, свой коричневый, выпуклый, разделенный дугообразными чешуйками живот, на верхушке которого еле держалось готовое вот-вот окончательно сползти одеяло.',
            description: 'Полное описание Диагностика. Его многочисленные, убого тонкие по сравнению с остальным телом ножки беспомощно копошились у него перед глазами. «Что со мной случилось? » – подумал он. Это не было сном. Его комната, настоящая, разве что слишком маленькая, но обычная комната, мирно покоилась в своих четырех хорошо знакомых стенах.',
            main_photo: { // TODO добавить
              id: 1,
              url: '../../assets/img/direction-2.png',
            },
            subdirections: [
              {
                id: 21,
                name: 'Аллергические риниты. Рентген',
                main_photo: {
                  id: 1,
                  url: '../../assets/img/direction2-1.png',
                },
                short_description: 'Краткое описание Рентген',
                description: 'Полное описание Рентген',
                icon: null,
                units: [
                  {
                    id: 2,
                    name: 'Аллергические риниты. Поликлиника №1',
                    address: {
                      id: 1,
                      city: 'Красноярск',
                      street: 'Вавилова',
                      house: '28',
                      building: '',
                      ymapcfg: '../../assets/img/contacts-card-map.png',
                      postcode: '660025',
                      region: 'Красноярский край',
                      mailbox: '6545'
                    },
                    phones: [
                      {
                        id: 7,
                        number: '+7 (391) 274-31-64',
                        description: 'круглосуточно'
                      },
                      {
                        id: 8,
                        number: '+7 (391) 257-64-60',
                        description: 'с 9:00 до 17:00'
                      }
                    ],
                  },
                  {
                    id: 3,
                    name: 'Поликлиника №3',
                    address: {
                      id: 1,
                      city: 'Красноярск',
                      street: 'Коломенская',
                      house: '28',
                      building: '',
                      ymapcfg: '../../assets/img/contacts-card-map.png',
                      postcode: '660025',
                      region: 'Красноярский край',
                      mailbox: '6545'
                    },
                    phones: [
                      {
                        id: 7,
                        number: '+7 (391) 274-31-64',
                        description: 'круглосуточно'
                      },
                      {
                        id: 8,
                        number: '+7 (391) 257-64-60',
                        description: 'с 9:00 до 17:00'
                      }
                    ],
                  },
                ],
                services: [
                  {
                    id: 1,
                    name: 'УЗИ печеночного трансплантанта с дуплексным сканированием артерий и вен',
                    display_name: 'УЗИ печеночного трансплантанта с дуплексным сканированием артерий и вен',
                    price: '1320',
                    // tslint:disable-next-line:max-line-length
                    anons: 'Ультразвуковую диагностику смело можно назвать уникальным методом исследования. Она обладает такими свойствами как неинвазивность, возможность многократного проведения исследования без вреда для здоровья пациента, быстрота получения информации, отсутствие противопоказаний, исключение лучевой нагрузки.',
                    // tslint:disable-next-line:max-line-length
                    body: 'Проснувшись однажды утром после беспокойного сна, Грегор Замза обнаружил, что он у себя в постели превратился в страшное насекомое.',
                    // tslint:disable-next-line:max-line-length
                    memo: 'Вы должны подготовить себя к исследованию следующим образом: воздержаться от физических нагрузок, приема алкоголя и лекарств, изменений в питании в течение 24 часов до взятия крови. Вам не следует принимать пищу после ужина, лечь спать нужно в обычное для Вас время и встать не позднее, чем за час до взятия крови. Утром до сдачи анализа воздержитесь от курения и приема пищи. Взятие крови осуществляется до проведения диагностических или лечебных процедур: инъекций, общего массажа тела, эндоскопий, ЭКГ, рентгеновского обследования и т.д. Если Вы испытываете трудности с отменой лекарств, то обязательно сообщите об этом лечащему врачу. Очень важно, чтобы Вы точно соблюдали указанные рекомендации, так как только в этом случае будут получены ценные результаты исследования крови.',
                    // tslint:disable-next-line:max-line-length
                    indication: 'Анализ на свертываемость является одним из самых простых и доступных методов исследования. Пациенту нужно отказываться от приема пищи в течение двух часов перед исследованием. Результаты готовы, как правило, через несколько часов. Такое тестирование проводится в рамках комплексного обследования организма, а также при наличии кровотечений невыясненной природы и при диагностике или контроле эффективности лечения патологий костного мозга. Референсные значения зависят от возраста, а в детстве — еще и от пола.',
                    contraindic: '<ul>При некоторых заболеваниях проведение забора тромбоцитарной массы категорически запрещено: \n' +
                      '          <li>туберкулез;</li>\n' +
                      '          <li>вирус иммунодефицита;</li>\n' +
                      '          <li>раковые патологии;</li>\n' +
                      '          <li>гепатиты;</li>\n' +
                      '          <li>нарушения в работе сердца и сосудов;</li>\n' +
                      '          <li>после проведения оперативных вмешательств;</li>\n' +
                      '          <li>беременность;</li>\n' +
                      '          <li>алкоголизм;</li>\n' +
                      '          <li>недавнее введение прививки;</li>\n' +
                      '          <li>язва желудка или двенадцатиперстной кишки;</li>\n' +
                      '          <li>татуировки;</li>\n' +
                      '          <li>обширные стоматологические поражения.</li>\n' +
                      '        </ul>',
                    sale: '',
                    doctor: [ // TODO могут ли одну услугу оказывать несколько врачей?
                      {
                        id: 2,
                        lastName: 'Морозова',
                        firstName: 'Екатерина',
                        middleName: 'Владимировна',
                        specialization: 'ЛОР',
                        main_photo: {
                          id: 11,
                          url: '../../assets/img/doctors/doc-5.png'
                        }
                      }
                    ],
                    documents: [
                      {
                        id: 1,
                        title: 'Паспорт',
                        // tslint:disable-next-line:max-line-length
                        desc: 'Проснувшись однажды утром после беспокойного сна, Грегор Замза обнаружил, что он у себя в постели превратился в страшное насекомое.',
                        main_photo:
                          {
                            id: 1,
                            url: '../../assets/img/doc-img-1.png',
                          }
                      },
                      {
                        id: 2,
                        title: 'Медицинский полис',
                        // tslint:disable-next-line:max-line-length
                        desc: 'Проснувшись однажды утром после беспокойного сна, Грегор Замза обнаружил, что он у себя в постели превратился в страшное насекомое.',
                        main_photo:
                          {
                            id: 1,
                            url: '../../assets/img/doc-img-2.png',
                          }
                      },
                      {
                        id: 3,
                        title: 'Направление доктора',
                        // tslint:disable-next-line:max-line-length
                        desc: 'Проснувшись однажды утром после беспокойного сна, Грегор Замза обнаружил, что он у себя в постели превратился в страшное насекомое.',
                        main_photo:
                          {
                            id: 1,
                            url: '../../assets/img/doc-img-3.png',
                          }
                      }
                    ],
                    tags: [
                      {
                        id: 1,
                        name: 'Кровь',
                        desc: 'Описание тега'
                      },
                      {
                        id: 2,
                        name: 'Глюкоза',
                        desc: 'Описание тега'
                      },
                      {
                        id: 3,
                        name: 'Витамины',
                        desc: 'Описание тега'
                      }
                    ],
                    slider: [
                      {
                        id: 1,
                        // tslint:disable-next-line:max-line-length
                        title: 'Ощущения пациента во время проведения ультразвуковой эластографии такие же, как и при обычном УЗИ. Ни боли, ни дискомфорта пациенты не испытывают.',
                        // tslint:disable-next-line:max-line-length
                        desc: 'Ощущения пациента во время проведения ультразвуковой эластографии такие же, как и при обычном УЗИ. Ни боли, ни дискомфорта пациенты не испытывают.',
                        main_photo: {
                          url: '../../assets/img/big-slide-3.png'
                        },
                      },
                      {
                        id: 2,
                        // tslint:disable-next-line:max-line-length
                        title: 'Ощущения пациента во время проведения ',
                        // tslint:disable-next-line:max-line-length
                        desc: 'Ощущения пациента во время проведения ультразвуковой эластографии такие же, как и при обычном УЗИ. Ни боли, ни дискомфорта пациенты не испытывают.',
                        main_photo: {
                          url: '../../assets/img/big-slide-4.png'
                        }
                      },
                      {
                        id: 3,
                        // tslint:disable-next-line:max-line-length
                        title: 'Ощущения пациента во время проведения ультразвуковой эластографии такие же, как и при обычном УЗИ. Ни боли, ни дискомфорта пациенты не испытывают.',
                        // tslint:disable-next-line:max-line-length
                        desc: 'Ощущения пациента во время проведения ультразвуковой эластографии такие же, как и при обычном УЗИ. Ни боли, ни дискомфорта пациенты не испытывают.',
                        main_photo: {
                          url: '../../assets/img/big-slide-3.png'
                        },
                      },
                      {
                        id: 4,
                        // tslint:disable-next-line:max-line-length
                        title: 'Ощущения пациента во время проведения ',
                        // tslint:disable-next-line:max-line-length
                        desc: 'Ощущения пациента во время проведения ультразвуковой эластографии такие же, как и при обычном УЗИ. Ни боли, ни дискомфорта пациенты не испытывают.',
                        main_photo: {
                          url: '../../assets/img/big-slide-4.png'
                        }
                      }
                    ],
                    catalog:
                      {
                        id: 1,
                        name: 'Клинический анализ крови',
                        desc: 'Описание Клинический анализ крови'
                      }
                  },
                  {
                    id: 2,
                    // tslint:disable-next-line:max-line-length
                    name: 'Ультразвуковое исследование органов брюшной полости комплексное: печень, поджелудочная железа, желчный пузырь, селезенка при профилактическом осмотре',
                    display_name: 'УЗИ брюшной полости',
                    price: '820',
                    // tslint:disable-next-line:max-line-length
                    anons: 'Ультразвуковую диагностику смело можно назвать уникальным методом исследования. Она обладает такими свойствами как неинвазивность, возможность многократного проведения исследования без вреда для здоровья пациента, быстрота получения информации, отсутствие противопоказаний, исключение лучевой нагрузки.',
                    // tslint:disable-next-line:max-line-length
                    body: 'Проснувшись однажды утром после беспокойного сна, Грегор Замза обнаружил, что он у себя в постели превратился в страшное насекомое.',
                    // tslint:disable-next-line:max-line-length
                    memo: 'Вы должны подготовить себя к исследованию следующим образом: воздержаться от физических нагрузок, приема алкоголя и лекарств, изменений в питании в течение 24 часов до взятия крови. Вам не следует принимать пищу после ужина, лечь спать нужно в обычное для Вас время и встать не позднее, чем за час до взятия крови. Утром до сдачи анализа воздержитесь от курения и приема пищи. Взятие крови осуществляется до проведения диагностических или лечебных процедур: инъекций, общего массажа тела, эндоскопий, ЭКГ, рентгеновского обследования и т.д. Если Вы испытываете трудности с отменой лекарств, то обязательно сообщите об этом лечащему врачу. Очень важно, чтобы Вы точно соблюдали указанные рекомендации, так как только в этом случае будут получены ценные результаты исследования крови.',
                    // tslint:disable-next-line:max-line-length
                    indication: 'Анализ на свертываемость является одним из самых простых и доступных методов исследования. Пациенту нужно отказываться от приема пищи в течение двух часов перед исследованием. Результаты готовы, как правило, через несколько часов. Такое тестирование проводится в рамках комплексного обследования организма, а также при наличии кровотечений невыясненной природы и при диагностике или контроле эффективности лечения патологий костного мозга. Референсные значения зависят от возраста, а в детстве — еще и от пола.',
                    contraindic: '<ul>При некоторых заболеваниях проведение забора тромбоцитарной массы категорически запрещено: \n' +
                      '          <li>туберкулез;</li>\n' +
                      '          <li>вирус иммунодефицита;</li>\n' +
                      '          <li>раковые патологии;</li>\n' +
                      '          <li>гепатиты;</li>\n' +
                      '          <li>нарушения в работе сердца и сосудов;</li>\n' +
                      '          <li>после проведения оперативных вмешательств;</li>\n' +
                      '          <li>беременность;</li>\n' +
                      '          <li>алкоголизм;</li>\n' +
                      '          <li>недавнее введение прививки;</li>\n' +
                      '          <li>язва желудка или двенадцатиперстной кишки;</li>\n' +
                      '          <li>татуировки;</li>\n' +
                      '          <li>обширные стоматологические поражения.</li>\n' +
                      '        </ul>',
                    sale: '10',
                    doctor: [ // TODO могут ли одну услугу оказывать несколько врачей?
                      {
                        id: 1,
                        lastName: 'Замза',
                        firstName: 'Грегор',
                        middleName: '',
                        specialization: 'Уролог',
                        main_photo: {
                          id: 16,
                          url: '../../assets/img/doctors/doc-2.png'
                        }
                      }
                    ],
                    documents: [
                      {
                        id: 1,
                        title: 'Паспорт',
                        // tslint:disable-next-line:max-line-length
                        desc: 'Проснувшись однажды утром после беспокойного сна, Грегор Замза обнаружил, что он у себя в постели превратился в страшное насекомое.',
                        main_photo:
                          {
                            id: 1,
                            url: '../../assets/img/doc-img-1.png',
                          }
                      }
                    ],
                    tags: [
                      {
                        id: 1,
                        name: 'Кровь',
                        desc: 'Описание тега'
                      },
                      {
                        id: 4,
                        name: 'Железо',
                        desc: 'Описание тега'
                      },
                      {
                        id: 5,
                        name: 'Белки',
                        desc: 'Описание тега'
                      }
                    ],
                    slider: [
                      {
                        id: 1,
                        // tslint:disable-next-line:max-line-length
                        title: 'Ощущения пациента во время проведения ультразвуковой эластографии такие же, как и при обычном УЗИ. Ни боли, ни дискомфорта пациенты не испытывают.',
                        // tslint:disable-next-line:max-line-length
                        desc: 'Ощущения пациента во время проведения ультразвуковой эластографии такие же, как и при обычном УЗИ. Ни боли, ни дискомфорта пациенты не испытывают.',
                        main_photo: {
                          url: '../../assets/img/big-slide-3.png'
                        },
                      },
                      {
                        id: 2,
                        // tslint:disable-next-line:max-line-length
                        title: 'Ощущения пациента во время проведения ',
                        // tslint:disable-next-line:max-line-length
                        desc: 'Ощущения пациента во время проведения ультразвуковой эластографии такие же, как и при обычном УЗИ. Ни боли, ни дискомфорта пациенты не испытывают.',
                        main_photo: {
                          url: '../../assets/img/big-slide-4.png'
                        }
                      }
                    ],
                    catalog:
                      {
                        id: 1,
                        name: 'Клинический анализ крови',
                        desc: 'Описание Клинический анализ крови'
                      }
                  },
                  {
                    id: 3,
                    name: 'УЗИ печеночного трансплантанта с дуплексным сканированием артерий и вен',
                    display_name: 'УЗИ печеночного трансплантанта с дуплексным сканированием артерий и вен',
                    price: '1320',
                    // tslint:disable-next-line:max-line-length
                    anons: 'Ультразвуковую диагностику смело можно назвать уникальным методом исследования. Она обладает такими свойствами как неинвазивность, возможность многократного проведения исследования без вреда для здоровья пациента, быстрота получения информации, отсутствие противопоказаний, исключение лучевой нагрузки.',
                    // tslint:disable-next-line:max-line-length
                    body: 'Проснувшись однажды утром после беспокойного сна, Грегор Замза обнаружил, что он у себя в постели превратился в страшное насекомое.',
                    // tslint:disable-next-line:max-line-length
                    memo: 'Вы должны подготовить себя к исследованию следующим образом: воздержаться от физических нагрузок, приема алкоголя и лекарств, изменений в питании в течение 24 часов до взятия крови. Вам не следует принимать пищу после ужина, лечь спать нужно в обычное для Вас время и встать не позднее, чем за час до взятия крови. Утром до сдачи анализа воздержитесь от курения и приема пищи. Взятие крови осуществляется до проведения диагностических или лечебных процедур: инъекций, общего массажа тела, эндоскопий, ЭКГ, рентгеновского обследования и т.д. Если Вы испытываете трудности с отменой лекарств, то обязательно сообщите об этом лечащему врачу. Очень важно, чтобы Вы точно соблюдали указанные рекомендации, так как только в этом случае будут получены ценные результаты исследования крови.',
                    // tslint:disable-next-line:max-line-length
                    indication: 'Анализ на свертываемость является одним из самых простых и доступных методов исследования. Пациенту нужно отказываться от приема пищи в течение двух часов перед исследованием. Результаты готовы, как правило, через несколько часов. Такое тестирование проводится в рамках комплексного обследования организма, а также при наличии кровотечений невыясненной природы и при диагностике или контроле эффективности лечения патологий костного мозга. Референсные значения зависят от возраста, а в детстве — еще и от пола.',
                    contraindic: '<ul>При некоторых заболеваниях проведение забора тромбоцитарной массы категорически запрещено: \n' +
                      '          <li>туберкулез;</li>\n' +
                      '          <li>вирус иммунодефицита;</li>\n' +
                      '          <li>раковые патологии;</li>\n' +
                      '          <li>гепатиты;</li>\n' +
                      '          <li>нарушения в работе сердца и сосудов;</li>\n' +
                      '          <li>после проведения оперативных вмешательств;</li>\n' +
                      '          <li>беременность;</li>\n' +
                      '          <li>алкоголизм;</li>\n' +
                      '          <li>недавнее введение прививки;</li>\n' +
                      '          <li>язва желудка или двенадцатиперстной кишки;</li>\n' +
                      '          <li>татуировки;</li>\n' +
                      '          <li>обширные стоматологические поражения.</li>\n' +
                      '        </ul>',
                    sale: '20',
                    doctor: [ // TODO могут ли одну услугу оказывать несколько врачей?
                      {
                        id: 1,
                        lastName: 'Коновалов',
                        firstName: 'Геннадий',
                        middleName: 'Александрович',
                        specialization: 'Главный ревматолог',
                        main_photo: {
                          id: 1,
                          url: '../../assets/img/doctors/doc-3.png'
                        }
                      },
                      {
                        id: 2,
                        lastName: 'Морозова',
                        firstName: 'Екатерина',
                        middleName: 'Владимировна',
                        specialization: 'ЛОР',
                        main_photo: {
                          id: 11,
                          url: '../../assets/img/doctors/doc-5.png'
                        }
                      }
                    ],
                    documents: [
                      {
                        id: 1,
                        title: 'Паспорт',
                        // tslint:disable-next-line:max-line-length
                        desc: 'Проснувшись однажды утром после беспокойного сна, Грегор Замза обнаружил, что он у себя в постели превратился в страшное насекомое.',
                        main_photo:
                          {
                            id: 1,
                            url: '../../assets/img/doc-img-1.png',
                          }
                      },
                      {
                        id: 2,
                        title: 'Медицинский полис',
                        // tslint:disable-next-line:max-line-length
                        desc: 'Проснувшись однажды утром после беспокойного сна, Грегор Замза обнаружил, что он у себя в постели превратился в страшное насекомое.',
                        main_photo:
                          {
                            id: 1,
                            url: '../../assets/img/doc-img-2.png',
                          }
                      },
                      {
                        id: 3,
                        title: 'Направление доктора',
                        // tslint:disable-next-line:max-line-length
                        desc: 'Проснувшись однажды утром после беспокойного сна, Грегор Замза обнаружил, что он у себя в постели превратился в страшное насекомое.',
                        main_photo:
                          {
                            id: 1,
                            url: '../../assets/img/doc-img-3.png',
                          }
                      }
                    ],
                    tags: [
                      {
                        id: 1,
                        name: 'Кровь',
                        desc: 'Описание тега'
                      },
                      {
                        id: 2,
                        name: 'Глюкоза',
                        desc: 'Описание тега'
                      },
                      {
                        id: 3,
                        name: 'Витамины',
                        desc: 'Описание тега'
                      }
                    ],
                    catalog:
                      {
                        id: 1,
                        name: 'Клинический анализ крови',
                        desc: 'Описание Клинический анализ крови'
                      }
                  },
                  {
                    id: 4,
                    name: 'УЗИ печеночного трансплантанта с дуплексным сканированием артерий и вен',
                    display_name: 'УЗИ печеночного трансплантанта с дуплексным сканированием артерий и вен',
                    price: '1320',
                    // tslint:disable-next-line:max-line-length
                    anons: 'Ультразвуковую диагностику смело можно назвать уникальным методом исследования. Она обладает такими свойствами как неинвазивность, возможность многократного проведения исследования без вреда для здоровья пациента, быстрота получения информации, отсутствие противопоказаний, исключение лучевой нагрузки.',
                    // tslint:disable-next-line:max-line-length
                    body: 'Проснувшись однажды утром после беспокойного сна, Грегор Замза обнаружил, что он у себя в постели превратился в страшное насекомое.',
                    // tslint:disable-next-line:max-line-length
                    memo: 'Вы должны подготовить себя к исследованию следующим образом: воздержаться от физических нагрузок, приема алкоголя и лекарств, изменений в питании в течение 24 часов до взятия крови. Вам не следует принимать пищу после ужина, лечь спать нужно в обычное для Вас время и встать не позднее, чем за час до взятия крови. Утром до сдачи анализа воздержитесь от курения и приема пищи. Взятие крови осуществляется до проведения диагностических или лечебных процедур: инъекций, общего массажа тела, эндоскопий, ЭКГ, рентгеновского обследования и т.д. Если Вы испытываете трудности с отменой лекарств, то обязательно сообщите об этом лечащему врачу. Очень важно, чтобы Вы точно соблюдали указанные рекомендации, так как только в этом случае будут получены ценные результаты исследования крови.',
                    // tslint:disable-next-line:max-line-length
                    indication: 'Анализ на свертываемость является одним из самых простых и доступных методов исследования. Пациенту нужно отказываться от приема пищи в течение двух часов перед исследованием. Результаты готовы, как правило, через несколько часов. Такое тестирование проводится в рамках комплексного обследования организма, а также при наличии кровотечений невыясненной природы и при диагностике или контроле эффективности лечения патологий костного мозга. Референсные значения зависят от возраста, а в детстве — еще и от пола.',
                    contraindic: '<ul>При некоторых заболеваниях проведение забора тромбоцитарной массы категорически запрещено: \n' +
                      '          <li>туберкулез;</li>\n' +
                      '          <li>вирус иммунодефицита;</li>\n' +
                      '          <li>раковые патологии;</li>\n' +
                      '          <li>гепатиты;</li>\n' +
                      '          <li>нарушения в работе сердца и сосудов;</li>\n' +
                      '          <li>после проведения оперативных вмешательств;</li>\n' +
                      '          <li>беременность;</li>\n' +
                      '          <li>алкоголизм;</li>\n' +
                      '          <li>недавнее введение прививки;</li>\n' +
                      '          <li>язва желудка или двенадцатиперстной кишки;</li>\n' +
                      '          <li>татуировки;</li>\n' +
                      '          <li>обширные стоматологические поражения.</li>\n' +
                      '        </ul>',
                    sale: '',
                    documents: [
                      {
                        id: 1,
                        title: 'Паспорт',
                        // tslint:disable-next-line:max-line-length
                        desc: 'Проснувшись однажды утром после беспокойного сна, Грегор Замза обнаружил, что он у себя в постели превратился в страшное насекомое.',
                        main_photo:
                          {
                            id: 1,
                            url: '../../assets/img/doc-img-1.png',
                          }
                      }
                    ],
                    tags: [
                      {
                        id: 1,
                        name: 'Кровь',
                        desc: 'Описание тега'
                      },
                      {
                        id: 2,
                        name: 'Глюкоза',
                        desc: 'Описание тега'
                      },
                      {
                        id: 3,
                        name: 'Витамины',
                        desc: 'Описание тега'
                      }
                    ],
                    catalog:
                      {
                        id: 1,
                        name: 'Клинический анализ крови',
                        desc: 'Описание Клинический анализ крови'
                      }
                  },
                  {
                    id: 5,
                    name: 'УЗИ печеночного трансплантанта с дуплексным сканированием артерий и вен',
                    display_name: 'УЗИ печеночного трансплантанта с дуплексным сканированием артерий и вен',
                    price: '1320',
                    // tslint:disable-next-line:max-line-length
                    anons: 'Ультразвуковую диагностику смело можно назвать уникальным методом исследования. Она обладает такими свойствами как неинвазивность, возможность многократного проведения исследования без вреда для здоровья пациента, быстрота получения информации, отсутствие противопоказаний, исключение лучевой нагрузки.',
                    // tslint:disable-next-line:max-line-length
                    body: 'Проснувшись однажды утром после беспокойного сна, Грегор Замза обнаружил, что он у себя в постели превратился в страшное насекомое.',
                    // tslint:disable-next-line:max-line-length
                    memo: 'Вы должны подготовить себя к исследованию следующим образом: воздержаться от физических нагрузок, приема алкоголя и лекарств, изменений в питании в течение 24 часов до взятия крови. Вам не следует принимать пищу после ужина, лечь спать нужно в обычное для Вас время и встать не позднее, чем за час до взятия крови. Утром до сдачи анализа воздержитесь от курения и приема пищи. Взятие крови осуществляется до проведения диагностических или лечебных процедур: инъекций, общего массажа тела, эндоскопий, ЭКГ, рентгеновского обследования и т.д. Если Вы испытываете трудности с отменой лекарств, то обязательно сообщите об этом лечащему врачу. Очень важно, чтобы Вы точно соблюдали указанные рекомендации, так как только в этом случае будут получены ценные результаты исследования крови.',
                    // tslint:disable-next-line:max-line-length
                    indication: 'Анализ на свертываемость является одним из самых простых и доступных методов исследования. Пациенту нужно отказываться от приема пищи в течение двух часов перед исследованием. Результаты готовы, как правило, через несколько часов. Такое тестирование проводится в рамках комплексного обследования организма, а также при наличии кровотечений невыясненной природы и при диагностике или контроле эффективности лечения патологий костного мозга. Референсные значения зависят от возраста, а в детстве — еще и от пола.',
                    contraindic: '<ul>При некоторых заболеваниях проведение забора тромбоцитарной массы категорически запрещено: \n' +
                      '          <li>туберкулез;</li>\n' +
                      '          <li>вирус иммунодефицита;</li>\n' +
                      '          <li>раковые патологии;</li>\n' +
                      '          <li>гепатиты;</li>\n' +
                      '          <li>нарушения в работе сердца и сосудов;</li>\n' +
                      '          <li>после проведения оперативных вмешательств;</li>\n' +
                      '          <li>беременность;</li>\n' +
                      '          <li>алкоголизм;</li>\n' +
                      '          <li>недавнее введение прививки;</li>\n' +
                      '          <li>язва желудка или двенадцатиперстной кишки;</li>\n' +
                      '          <li>татуировки;</li>\n' +
                      '          <li>обширные стоматологические поражения.</li>\n' +
                      '        </ul>',
                    sale: '',
                    documents: [
                      {
                        id: 1,
                        title: 'Паспорт',
                        // tslint:disable-next-line:max-line-length
                        desc: 'Проснувшись однажды утром после беспокойного сна, Грегор Замза обнаружил, что он у себя в постели превратился в страшное насекомое.',
                        main_photo:
                          {
                            id: 1,
                            url: '../../assets/img/doc-img-1.png',
                          }
                      }
                    ],
                    tags: [
                      {
                        id: 1,
                        name: 'Кровь',
                        desc: 'Описание тега'
                      },
                      {
                        id: 2,
                        name: 'Глюкоза',
                        desc: 'Описание тега'
                      },
                      {
                        id: 3,
                        name: 'Витамины',
                        desc: 'Описание тега'
                      }
                    ],
                    catalog:
                      {
                        id: 1,
                        name: 'УЗИ - диагностические исследования',
                        desc: 'Описание Клинический анализ крови'
                      }
                  },
                  {
                    id: 6,
                    name: 'УЗИ печеночного трансплантанта с дуплексным сканированием артерий и вен',
                    display_name: 'УЗИ печеночного трансплантанта с дуплексным сканированием артерий и вен',
                    price: '1320',
                    // tslint:disable-next-line:max-line-length
                    anons: 'Ультразвуковую диагностику смело можно назвать уникальным методом исследования. Она обладает такими свойствами как неинвазивность, возможность многократного проведения исследования без вреда для здоровья пациента, быстрота получения информации, отсутствие противопоказаний, исключение лучевой нагрузки.',
                    // tslint:disable-next-line:max-line-length
                    body: 'Проснувшись однажды утром после беспокойного сна, Грегор Замза обнаружил, что он у себя в постели превратился в страшное насекомое.',
                    // tslint:disable-next-line:max-line-length
                    memo: 'Вы должны подготовить себя к исследованию следующим образом: воздержаться от физических нагрузок, приема алкоголя и лекарств, изменений в питании в течение 24 часов до взятия крови. Вам не следует принимать пищу после ужина, лечь спать нужно в обычное для Вас время и встать не позднее, чем за час до взятия крови. Утром до сдачи анализа воздержитесь от курения и приема пищи. Взятие крови осуществляется до проведения диагностических или лечебных процедур: инъекций, общего массажа тела, эндоскопий, ЭКГ, рентгеновского обследования и т.д. Если Вы испытываете трудности с отменой лекарств, то обязательно сообщите об этом лечащему врачу. Очень важно, чтобы Вы точно соблюдали указанные рекомендации, так как только в этом случае будут получены ценные результаты исследования крови.',
                    // tslint:disable-next-line:max-line-length
                    indication: 'Анализ на свертываемость является одним из самых простых и доступных методов исследования. Пациенту нужно отказываться от приема пищи в течение двух часов перед исследованием. Результаты готовы, как правило, через несколько часов. Такое тестирование проводится в рамках комплексного обследования организма, а также при наличии кровотечений невыясненной природы и при диагностике или контроле эффективности лечения патологий костного мозга. Референсные значения зависят от возраста, а в детстве — еще и от пола.',
                    contraindic: '<ul>При некоторых заболеваниях проведение забора тромбоцитарной массы категорически запрещено: \n' +
                      '          <li>туберкулез;</li>\n' +
                      '          <li>вирус иммунодефицита;</li>\n' +
                      '          <li>раковые патологии;</li>\n' +
                      '          <li>гепатиты;</li>\n' +
                      '          <li>нарушения в работе сердца и сосудов;</li>\n' +
                      '          <li>после проведения оперативных вмешательств;</li>\n' +
                      '          <li>беременность;</li>\n' +
                      '          <li>алкоголизм;</li>\n' +
                      '          <li>недавнее введение прививки;</li>\n' +
                      '          <li>язва желудка или двенадцатиперстной кишки;</li>\n' +
                      '          <li>татуировки;</li>\n' +
                      '          <li>обширные стоматологические поражения.</li>\n' +
                      '        </ul>',
                    sale: '20',
                    tags: [
                      {
                        id: 1,
                        name: 'Кровь',
                        desc: 'Описание тега'
                      },
                      {
                        id: 2,
                        name: 'Глюкоза',
                        desc: 'Описание тега'
                      },
                      {
                        id: 3,
                        name: 'Витамины',
                        desc: 'Описание тега'
                      }
                    ],
                    catalog:
                      {
                        id: 1,
                        name: 'Анализы крови',
                        desc: 'Описание Клинический анализ крови'
                      }
                  },
                  {
                    id: 7,
                    name: 'Анализ крови артерий и вен',
                    display_name: 'Анализ крови артерий и вен',
                    price: '1320',
                    // tslint:disable-next-line:max-line-length
                    anons: 'Ультразвуковую диагностику смело можно назвать уникальным методом исследования. Она обладает такими свойствами как неинвазивность, возможность многократного проведения исследования без вреда для здоровья пациента, быстрота получения информации, отсутствие противопоказаний, исключение лучевой нагрузки.',
                    // tslint:disable-next-line:max-line-length
                    body: 'Проснувшись однажды утром после беспокойного сна, Грегор Замза обнаружил.',
                    // tslint:disable-next-line:max-line-length
                    memo: 'Вы должны подготовить себя к исследованию следующим образом: воздержаться от физических нагрузок, приема алкоголя и лекарств, изменений в питании в течение 24 часов до взятия крови. Вам не следует принимать пищу после ужина, лечь спать нужно в обычное для Вас время и встать не позднее, чем за час до взятия крови. Утром до сдачи анализа воздержитесь от курения и приема пищи. Взятие крови осуществляется до проведения диагностических или лечебных процедур: инъекций, общего массажа тела, эндоскопий, ЭКГ, рентгеновского обследования и т.д. Если Вы испытываете трудности с отменой лекарств, то обязательно сообщите об этом лечащему врачу. Очень важно, чтобы Вы точно соблюдали указанные рекомендации, так как только в этом случае будут получены ценные результаты исследования крови.',
                    // tslint:disable-next-line:max-line-length
                    indication: 'Анализ на свертываемость является одним из самых простых и доступных методов исследования. Пациенту нужно отказываться от приема пищи в течение двух часов перед исследованием. Результаты готовы, как правило, через несколько часов. Такое тестирование проводится в рамках комплексного обследования организма, а также при наличии кровотечений невыясненной природы и при диагностике или контроле эффективности лечения патологий костного мозга. Референсные значения зависят от возраста, а в детстве — еще и от пола.',
                    contraindic: '<ul>При некоторых заболеваниях проведение забора тромбоцитарной массы категорически запрещено: \n' +
                      '          <li>туберкулез;</li>\n' +
                      '          <li>вирус иммунодефицита;</li>\n' +
                      '          <li>раковые патологии;</li>\n' +
                      '          <li>гепатиты;</li>\n' +
                      '          <li>нарушения в работе сердца и сосудов;</li>\n' +
                      '          <li>после проведения оперативных вмешательств;</li>\n' +
                      '          <li>беременность;</li>\n' +
                      '          <li>алкоголизм;</li>\n' +
                      '          <li>недавнее введение прививки;</li>\n' +
                      '          <li>язва желудка или двенадцатиперстной кишки;</li>\n' +
                      '          <li>татуировки;</li>\n' +
                      '          <li>обширные стоматологические поражения.</li>\n' +
                      '        </ul>',
                    sale: '',
                    documents: [
                      {
                        id: 1,
                        title: 'Паспорт',
                        // tslint:disable-next-line:max-line-length
                        desc: 'Проснувшись однажды утром после беспокойного сна, Грегор Замза обнаружил, что он у себя в постели превратился в страшное насекомое.',
                        main_photo:
                          {
                            id: 1,
                            url: '../../assets/img/doc-img-1.png',
                          }
                      }
                    ],
                    tags: [
                      {
                        id: 1,
                        name: 'Кровь',
                        desc: 'Описание тега'
                      },
                      {
                        id: 2,
                        name: 'Глюкоза',
                        desc: 'Описание тега'
                      },
                      {
                        id: 3,
                        name: 'Гормоны',
                        desc: 'Описание тега'
                      }
                    ],
                    catalog:
                      {
                        id: 1,
                        name: 'Анализы крови',
                        desc: 'Описание Клинический анализ крови'
                      }
                  }
                ],
              },
              {
                id: 24,
                name: 'Функциональная диагностика',
                main_photo: {
                  id: 1,
                  url: '../../assets/img/direction2-3.png',
                },
                short_description: 'Краткое описание Функциональная диагностика',
                description: 'Полное описание Функциональная диагностика',
                icon: null,
                units: [
                  {
                    id: 2,
                    name: 'Поликлиника №1',
                    address: {
                      id: 1,
                      city: 'Красноярск',
                      street: 'Вавилова',
                      house: '28',
                      building: '',
                      ymapcfg: '../../assets/img/contacts-card-map.png',
                      postcode: '660025',
                      region: 'Красноярский край',
                      mailbox: '6545'
                    },
                    phones: [
                      {
                        id: 7,
                        number: '+7 (391) 274-31-64',
                        description: 'круглосуточно'
                      },
                      {
                        id: 8,
                        number: '+7 (391) 257-64-60',
                        description: 'с 9:00 до 17:00'
                      }
                    ],
                  },
                  {
                    id: 3,
                    name: 'Поликлиника №3',
                    address: {
                      id: 1,
                      city: 'Красноярск',
                      street: 'Коломенская',
                      house: '28',
                      building: '',
                      ymapcfg: '../../assets/img/contacts-card-map.png',
                      postcode: '660025',
                      region: 'Красноярский край',
                      mailbox: '6545'
                    },
                    phones: [
                      {
                        id: 7,
                        number: '+7 (391) 274-31-64',
                        description: 'круглосуточно'
                      },
                      {
                        id: 8,
                        number: '+7 (391) 257-64-60',
                        description: 'с 9:00 до 17:00'
                      }
                    ],
                  },
                ],
                services: [
                  {
                    id: 5,
                    name: 'УЗИ печеночного трансплантанта с дуплексным сканированием артерий и вен',
                    display_name: 'УЗИ печеночного трансплантанта с дуплексным сканированием артерий и вен',
                    price: '1320',
                    // tslint:disable-next-line:max-line-length
                    anons: 'Ультразвуковую диагностику смело можно назвать уникальным методом исследования. Она обладает такими свойствами как неинвазивность, возможность многократного проведения исследования без вреда для здоровья пациента, быстрота получения информации, отсутствие противопоказаний, исключение лучевой нагрузки.',
                    // tslint:disable-next-line:max-line-length
                    body: 'Проснувшись однажды утром после беспокойного сна, Грегор Замза обнаружил, что он у себя в постели превратился в страшное насекомое.',
                    // tslint:disable-next-line:max-line-length
                    memo: 'Вы должны подготовить себя к исследованию следующим образом: воздержаться от физических нагрузок, приема алкоголя и лекарств, изменений в питании в течение 24 часов до взятия крови. Вам не следует принимать пищу после ужина, лечь спать нужно в обычное для Вас время и встать не позднее, чем за час до взятия крови. Утром до сдачи анализа воздержитесь от курения и приема пищи. Взятие крови осуществляется до проведения диагностических или лечебных процедур: инъекций, общего массажа тела, эндоскопий, ЭКГ, рентгеновского обследования и т.д. Если Вы испытываете трудности с отменой лекарств, то обязательно сообщите об этом лечащему врачу. Очень важно, чтобы Вы точно соблюдали указанные рекомендации, так как только в этом случае будут получены ценные результаты исследования крови.',
                    // tslint:disable-next-line:max-line-length
                    indication: 'Анализ на свертываемость является одним из самых простых и доступных методов исследования. Пациенту нужно отказываться от приема пищи в течение двух часов перед исследованием. Результаты готовы, как правило, через несколько часов. Такое тестирование проводится в рамках комплексного обследования организма, а также при наличии кровотечений невыясненной природы и при диагностике или контроле эффективности лечения патологий костного мозга. Референсные значения зависят от возраста, а в детстве — еще и от пола.',
                    contraindic: '<ul>При некоторых заболеваниях проведение забора тромбоцитарной массы категорически запрещено: \n' +
                      '          <li>туберкулез;</li>\n' +
                      '          <li>вирус иммунодефицита;</li>\n' +
                      '          <li>раковые патологии;</li>\n' +
                      '          <li>гепатиты;</li>\n' +
                      '          <li>нарушения в работе сердца и сосудов;</li>\n' +
                      '          <li>после проведения оперативных вмешательств;</li>\n' +
                      '          <li>беременность;</li>\n' +
                      '          <li>алкоголизм;</li>\n' +
                      '          <li>недавнее введение прививки;</li>\n' +
                      '          <li>язва желудка или двенадцатиперстной кишки;</li>\n' +
                      '          <li>татуировки;</li>\n' +
                      '          <li>обширные стоматологические поражения.</li>\n' +
                      '        </ul>',
                    sale: '',
                    documents: [
                      {
                        id: 1,
                        title: 'Паспорт',
                        // tslint:disable-next-line:max-line-length
                        desc: 'Проснувшись однажды утром после беспокойного сна, Грегор Замза обнаружил, что он у себя в постели превратился в страшное насекомое.',
                        main_photo:
                          {
                            id: 1,
                            url: '../../assets/img/doc-img-1.png',
                          }
                      }
                    ],
                    tags: [
                      {
                        id: 1,
                        name: 'Кровь',
                        desc: 'Описание тега'
                      },
                      {
                        id: 2,
                        name: 'Глюкоза',
                        desc: 'Описание тега'
                      },
                      {
                        id: 3,
                        name: 'Витамины',
                        desc: 'Описание тега'
                      }
                    ],
                    catalog:
                      {
                        id: 1,
                        name: 'УЗИ - диагностические исследования',
                        desc: 'Описание Клинический анализ крови'
                      }
                  },
                  {
                    id: 6,
                    name: 'УЗИ печеночного трансплантанта с дуплексным сканированием артерий и вен',
                    display_name: 'УЗИ печеночного трансплантанта с дуплексным сканированием артерий и вен',
                    price: '1320',
                    // tslint:disable-next-line:max-line-length
                    anons: 'Ультразвуковую диагностику смело можно назвать уникальным методом исследования. Она обладает такими свойствами как неинвазивность, возможность многократного проведения исследования без вреда для здоровья пациента, быстрота получения информации, отсутствие противопоказаний, исключение лучевой нагрузки.',
                    // tslint:disable-next-line:max-line-length
                    body: 'Проснувшись однажды утром после беспокойного сна, Грегор Замза обнаружил, что он у себя в постели превратился в страшное насекомое.',
                    // tslint:disable-next-line:max-line-length
                    memo: 'Вы должны подготовить себя к исследованию следующим образом: воздержаться от физических нагрузок, приема алкоголя и лекарств, изменений в питании в течение 24 часов до взятия крови. Вам не следует принимать пищу после ужина, лечь спать нужно в обычное для Вас время и встать не позднее, чем за час до взятия крови. Утром до сдачи анализа воздержитесь от курения и приема пищи. Взятие крови осуществляется до проведения диагностических или лечебных процедур: инъекций, общего массажа тела, эндоскопий, ЭКГ, рентгеновского обследования и т.д. Если Вы испытываете трудности с отменой лекарств, то обязательно сообщите об этом лечащему врачу. Очень важно, чтобы Вы точно соблюдали указанные рекомендации, так как только в этом случае будут получены ценные результаты исследования крови.',
                    // tslint:disable-next-line:max-line-length
                    indication: 'Анализ на свертываемость является одним из самых простых и доступных методов исследования. Пациенту нужно отказываться от приема пищи в течение двух часов перед исследованием. Результаты готовы, как правило, через несколько часов. Такое тестирование проводится в рамках комплексного обследования организма, а также при наличии кровотечений невыясненной природы и при диагностике или контроле эффективности лечения патологий костного мозга. Референсные значения зависят от возраста, а в детстве — еще и от пола.',
                    contraindic: '<ul>При некоторых заболеваниях проведение забора тромбоцитарной массы категорически запрещено: \n' +
                      '          <li>туберкулез;</li>\n' +
                      '          <li>вирус иммунодефицита;</li>\n' +
                      '          <li>раковые патологии;</li>\n' +
                      '          <li>гепатиты;</li>\n' +
                      '          <li>нарушения в работе сердца и сосудов;</li>\n' +
                      '          <li>после проведения оперативных вмешательств;</li>\n' +
                      '          <li>беременность;</li>\n' +
                      '          <li>алкоголизм;</li>\n' +
                      '          <li>недавнее введение прививки;</li>\n' +
                      '          <li>язва желудка или двенадцатиперстной кишки;</li>\n' +
                      '          <li>татуировки;</li>\n' +
                      '          <li>обширные стоматологические поражения.</li>\n' +
                      '        </ul>',
                    sale: '20',
                    tags: [
                      {
                        id: 1,
                        name: 'Кровь',
                        desc: 'Описание тега'
                      },
                      {
                        id: 2,
                        name: 'Глюкоза',
                        desc: 'Описание тега'
                      },
                      {
                        id: 3,
                        name: 'Витамины',
                        desc: 'Описание тега'
                      }
                    ],
                    catalog:
                      {
                        id: 1,
                        name: 'Анализы крови',
                        desc: 'Описание Клинический анализ крови'
                      }
                  },
                  {
                    id: 7,
                    name: 'Анализ крови артерий и вен',
                    display_name: 'Анализ крови артерий и вен',
                    price: '1320',
                    // tslint:disable-next-line:max-line-length
                    anons: 'Ультразвуковую диагностику смело можно назвать уникальным методом исследования. Она обладает такими свойствами как неинвазивность, возможность многократного проведения исследования без вреда для здоровья пациента, быстрота получения информации, отсутствие противопоказаний, исключение лучевой нагрузки.',
                    // tslint:disable-next-line:max-line-length
                    body: 'Проснувшись однажды утром после беспокойного сна, Грегор Замза обнаружил.',
                    // tslint:disable-next-line:max-line-length
                    memo: 'Вы должны подготовить себя к исследованию следующим образом: воздержаться от физических нагрузок, приема алкоголя и лекарств, изменений в питании в течение 24 часов до взятия крови. Вам не следует принимать пищу после ужина, лечь спать нужно в обычное для Вас время и встать не позднее, чем за час до взятия крови. Утром до сдачи анализа воздержитесь от курения и приема пищи. Взятие крови осуществляется до проведения диагностических или лечебных процедур: инъекций, общего массажа тела, эндоскопий, ЭКГ, рентгеновского обследования и т.д. Если Вы испытываете трудности с отменой лекарств, то обязательно сообщите об этом лечащему врачу. Очень важно, чтобы Вы точно соблюдали указанные рекомендации, так как только в этом случае будут получены ценные результаты исследования крови.',
                    // tslint:disable-next-line:max-line-length
                    indication: 'Анализ на свертываемость является одним из самых простых и доступных методов исследования. Пациенту нужно отказываться от приема пищи в течение двух часов перед исследованием. Результаты готовы, как правило, через несколько часов. Такое тестирование проводится в рамках комплексного обследования организма, а также при наличии кровотечений невыясненной природы и при диагностике или контроле эффективности лечения патологий костного мозга. Референсные значения зависят от возраста, а в детстве — еще и от пола.',
                    contraindic: '<ul>При некоторых заболеваниях проведение забора тромбоцитарной массы категорически запрещено: \n' +
                      '          <li>туберкулез;</li>\n' +
                      '          <li>вирус иммунодефицита;</li>\n' +
                      '          <li>раковые патологии;</li>\n' +
                      '          <li>гепатиты;</li>\n' +
                      '          <li>нарушения в работе сердца и сосудов;</li>\n' +
                      '          <li>после проведения оперативных вмешательств;</li>\n' +
                      '          <li>беременность;</li>\n' +
                      '          <li>алкоголизм;</li>\n' +
                      '          <li>недавнее введение прививки;</li>\n' +
                      '          <li>язва желудка или двенадцатиперстной кишки;</li>\n' +
                      '          <li>татуировки;</li>\n' +
                      '          <li>обширные стоматологические поражения.</li>\n' +
                      '        </ul>',
                    sale: '',
                    documents: [
                      {
                        id: 1,
                        title: 'Паспорт',
                        // tslint:disable-next-line:max-line-length
                        desc: 'Проснувшись однажды утром после беспокойного сна, Грегор Замза обнаружил, что он у себя в постели превратился в страшное насекомое.',
                        main_photo:
                          {
                            id: 1,
                            url: '../../assets/img/doc-img-1.png',
                          }
                      }
                    ],
                    tags: [
                      {
                        id: 1,
                        name: 'Кровь',
                        desc: 'Описание тега'
                      },
                      {
                        id: 2,
                        name: 'Глюкоза',
                        desc: 'Описание тега'
                      },
                      {
                        id: 3,
                        name: 'Гормоны',
                        desc: 'Описание тега'
                      }
                    ],
                    catalog:
                      {
                        id: 1,
                        name: 'Анализы крови',
                        desc: 'Описание Клинический анализ крови'
                      }
                  }
                ],
              },
              {
                id: 22,
                name: 'УЗИ',
                main_photo: {
                  id: 1,
                  url: '../../assets/img/direction2-2.png',
                },
                short_description: 'Краткое описание УЗИ',
                description: 'Полное описание УЗИ',
                icon: null,
                units: [
                  {
                    id: 3,
                    name: 'Поликлиника №3',
                    address: {
                      id: 1,
                      city: 'Красноярск',
                      street: 'Коломенская',
                      house: '28',
                      building: '',
                      ymapcfg: '../../assets/img/contacts-card-map.png',
                      postcode: '660025',
                      region: 'Красноярский край',
                      mailbox: '6545'
                    },
                    phones: [
                      {
                        id: 7,
                        number: '+7 (391) 274-31-64',
                        description: 'круглосуточно'
                      },
                      {
                        id: 8,
                        number: '+7 (391) 257-64-60',
                        description: 'с 9:00 до 17:00'
                      }
                    ],
                  },
                ],
                services: [
                  {
                    id: 1,
                    name: 'УЗИ печеночного трансплантанта с дуплексным сканированием артерий и вен',
                    display_name: 'УЗИ печеночного трансплантанта с дуплексным сканированием артерий и вен',
                    price: '1320',
                    // tslint:disable-next-line:max-line-length
                    anons: 'Ультразвуковую диагностику смело можно назвать уникальным методом исследования. Она обладает такими свойствами как неинвазивность, возможность многократного проведения исследования без вреда для здоровья пациента, быстрота получения информации, отсутствие противопоказаний, исключение лучевой нагрузки.',
                    // tslint:disable-next-line:max-line-length
                    body: 'Проснувшись однажды утром после беспокойного сна, Грегор Замза обнаружил, что он у себя в постели превратился в страшное насекомое.',
                    // tslint:disable-next-line:max-line-length
                    memo: 'Вы должны подготовить себя к исследованию следующим образом: воздержаться от физических нагрузок, приема алкоголя и лекарств, изменений в питании в течение 24 часов до взятия крови. Вам не следует принимать пищу после ужина, лечь спать нужно в обычное для Вас время и встать не позднее, чем за час до взятия крови. Утром до сдачи анализа воздержитесь от курения и приема пищи. Взятие крови осуществляется до проведения диагностических или лечебных процедур: инъекций, общего массажа тела, эндоскопий, ЭКГ, рентгеновского обследования и т.д. Если Вы испытываете трудности с отменой лекарств, то обязательно сообщите об этом лечащему врачу. Очень важно, чтобы Вы точно соблюдали указанные рекомендации, так как только в этом случае будут получены ценные результаты исследования крови.',
                    // tslint:disable-next-line:max-line-length
                    indication: 'Анализ на свертываемость является одним из самых простых и доступных методов исследования. Пациенту нужно отказываться от приема пищи в течение двух часов перед исследованием. Результаты готовы, как правило, через несколько часов. Такое тестирование проводится в рамках комплексного обследования организма, а также при наличии кровотечений невыясненной природы и при диагностике или контроле эффективности лечения патологий костного мозга. Референсные значения зависят от возраста, а в детстве — еще и от пола.',
                    contraindic: '<ul>При некоторых заболеваниях проведение забора тромбоцитарной массы категорически запрещено: \n' +
                      '          <li>туберкулез;</li>\n' +
                      '          <li>вирус иммунодефицита;</li>\n' +
                      '          <li>раковые патологии;</li>\n' +
                      '          <li>гепатиты;</li>\n' +
                      '          <li>нарушения в работе сердца и сосудов;</li>\n' +
                      '          <li>после проведения оперативных вмешательств;</li>\n' +
                      '          <li>беременность;</li>\n' +
                      '          <li>алкоголизм;</li>\n' +
                      '          <li>недавнее введение прививки;</li>\n' +
                      '          <li>язва желудка или двенадцатиперстной кишки;</li>\n' +
                      '          <li>татуировки;</li>\n' +
                      '          <li>обширные стоматологические поражения.</li>\n' +
                      '        </ul>',
                    sale: '',
                    doctor: [ // TODO могут ли одну услугу оказывать несколько врачей?
                      {
                        id: 2,
                        lastName: 'Морозова',
                        firstName: 'Екатерина',
                        middleName: 'Владимировна',
                        specialization: 'ЛОР',
                        main_photo: {
                          id: 11,
                          url: '../../assets/img/doctors/doc-5.png'
                        }
                      }
                    ],
                    documents: [
                      {
                        id: 1,
                        title: 'Паспорт',
                        // tslint:disable-next-line:max-line-length
                        desc: 'Проснувшись однажды утром после беспокойного сна, Грегор Замза обнаружил, что он у себя в постели превратился в страшное насекомое.',
                        main_photo:
                          {
                            id: 1,
                            url: '../../assets/img/doc-img-1.png',
                          }
                      },
                      {
                        id: 2,
                        title: 'Медицинский полис',
                        // tslint:disable-next-line:max-line-length
                        desc: 'Проснувшись однажды утром после беспокойного сна, Грегор Замза обнаружил, что он у себя в постели превратился в страшное насекомое.',
                        main_photo:
                          {
                            id: 1,
                            url: '../../assets/img/doc-img-2.png',
                          }
                      },
                      {
                        id: 3,
                        title: 'Направление доктора',
                        // tslint:disable-next-line:max-line-length
                        desc: 'Проснувшись однажды утром после беспокойного сна, Грегор Замза обнаружил, что он у себя в постели превратился в страшное насекомое.',
                        main_photo:
                          {
                            id: 1,
                            url: '../../assets/img/doc-img-3.png',
                          }
                      }
                    ],
                    tags: [
                      {
                        id: 1,
                        name: 'Кровь',
                        desc: 'Описание тега'
                      },
                      {
                        id: 2,
                        name: 'Глюкоза',
                        desc: 'Описание тега'
                      },
                      {
                        id: 3,
                        name: 'Витамины',
                        desc: 'Описание тега'
                      }
                    ],
                    slider: [
                      {
                        id: 1,
                        // tslint:disable-next-line:max-line-length
                        title: 'Ощущения пациента во время проведения ультразвуковой эластографии такие же, как и при обычном УЗИ. Ни боли, ни дискомфорта пациенты не испытывают.',
                        // tslint:disable-next-line:max-line-length
                        desc: 'Ощущения пациента во время проведения ультразвуковой эластографии такие же, как и при обычном УЗИ. Ни боли, ни дискомфорта пациенты не испытывают.',
                        main_photo: {
                          url: '../../assets/img/big-slide-3.png'
                        },
                      },
                      {
                        id: 2,
                        // tslint:disable-next-line:max-line-length
                        title: 'Ощущения пациента во время проведения ',
                        // tslint:disable-next-line:max-line-length
                        desc: 'Ощущения пациента во время проведения ультразвуковой эластографии такие же, как и при обычном УЗИ. Ни боли, ни дискомфорта пациенты не испытывают.',
                        main_photo: {
                          url: '../../assets/img/big-slide-4.png'
                        }
                      },
                      {
                        id: 3,
                        // tslint:disable-next-line:max-line-length
                        title: 'Ощущения пациента во время проведения ультразвуковой эластографии такие же, как и при обычном УЗИ. Ни боли, ни дискомфорта пациенты не испытывают.',
                        // tslint:disable-next-line:max-line-length
                        desc: 'Ощущения пациента во время проведения ультразвуковой эластографии такие же, как и при обычном УЗИ. Ни боли, ни дискомфорта пациенты не испытывают.',
                        main_photo: {
                          url: '../../assets/img/big-slide-3.png'
                        },
                      },
                      {
                        id: 4,
                        // tslint:disable-next-line:max-line-length
                        title: 'Ощущения пациента во время проведения ',
                        // tslint:disable-next-line:max-line-length
                        desc: 'Ощущения пациента во время проведения ультразвуковой эластографии такие же, как и при обычном УЗИ. Ни боли, ни дискомфорта пациенты не испытывают.',
                        main_photo: {
                          url: '../../assets/img/big-slide-4.png'
                        }
                      }
                    ],
                    catalog:
                      {
                        id: 1,
                        name: 'Клинический анализ крови',
                        desc: 'Описание Клинический анализ крови'
                      }
                  },
                  {
                    id: 2,
                    // tslint:disable-next-line:max-line-length
                    name: 'Ультразвуковое исследование органов брюшной полости комплексное: печень, поджелудочная железа, желчный пузырь, селезенка при профилактическом осмотре',
                    display_name: 'УЗИ брюшной полости',
                    price: '820',
                    // tslint:disable-next-line:max-line-length
                    anons: 'Ультразвуковую диагностику смело можно назвать уникальным методом исследования. Она обладает такими свойствами как неинвазивность, возможность многократного проведения исследования без вреда для здоровья пациента, быстрота получения информации, отсутствие противопоказаний, исключение лучевой нагрузки.',
                    // tslint:disable-next-line:max-line-length
                    body: 'Проснувшись однажды утром после беспокойного сна, Грегор Замза обнаружил, что он у себя в постели превратился в страшное насекомое.',
                    // tslint:disable-next-line:max-line-length
                    memo: 'Вы должны подготовить себя к исследованию следующим образом: воздержаться от физических нагрузок, приема алкоголя и лекарств, изменений в питании в течение 24 часов до взятия крови. Вам не следует принимать пищу после ужина, лечь спать нужно в обычное для Вас время и встать не позднее, чем за час до взятия крови. Утром до сдачи анализа воздержитесь от курения и приема пищи. Взятие крови осуществляется до проведения диагностических или лечебных процедур: инъекций, общего массажа тела, эндоскопий, ЭКГ, рентгеновского обследования и т.д. Если Вы испытываете трудности с отменой лекарств, то обязательно сообщите об этом лечащему врачу. Очень важно, чтобы Вы точно соблюдали указанные рекомендации, так как только в этом случае будут получены ценные результаты исследования крови.',
                    // tslint:disable-next-line:max-line-length
                    indication: 'Анализ на свертываемость является одним из самых простых и доступных методов исследования. Пациенту нужно отказываться от приема пищи в течение двух часов перед исследованием. Результаты готовы, как правило, через несколько часов. Такое тестирование проводится в рамках комплексного обследования организма, а также при наличии кровотечений невыясненной природы и при диагностике или контроле эффективности лечения патологий костного мозга. Референсные значения зависят от возраста, а в детстве — еще и от пола.',
                    contraindic: '<ul>При некоторых заболеваниях проведение забора тромбоцитарной массы категорически запрещено: \n' +
                      '          <li>туберкулез;</li>\n' +
                      '          <li>вирус иммунодефицита;</li>\n' +
                      '          <li>раковые патологии;</li>\n' +
                      '          <li>гепатиты;</li>\n' +
                      '          <li>нарушения в работе сердца и сосудов;</li>\n' +
                      '          <li>после проведения оперативных вмешательств;</li>\n' +
                      '          <li>беременность;</li>\n' +
                      '          <li>алкоголизм;</li>\n' +
                      '          <li>недавнее введение прививки;</li>\n' +
                      '          <li>язва желудка или двенадцатиперстной кишки;</li>\n' +
                      '          <li>татуировки;</li>\n' +
                      '          <li>обширные стоматологические поражения.</li>\n' +
                      '        </ul>',
                    sale: '10',
                    doctor: [ // TODO могут ли одну услугу оказывать несколько врачей?
                      {
                        id: 1,
                        lastName: 'Замза',
                        firstName: 'Грегор',
                        middleName: '',
                        specialization: 'Уролог',
                        main_photo: {
                          id: 16,
                          url: '../../assets/img/doctors/doc-2.png'
                        }
                      }
                    ],
                    documents: [
                      {
                        id: 1,
                        title: 'Паспорт',
                        // tslint:disable-next-line:max-line-length
                        desc: 'Проснувшись однажды утром после беспокойного сна, Грегор Замза обнаружил, что он у себя в постели превратился в страшное насекомое.',
                        main_photo:
                          {
                            id: 1,
                            url: '../../assets/img/doc-img-1.png',
                          }
                      }
                    ],
                    tags: [
                      {
                        id: 1,
                        name: 'Кровь',
                        desc: 'Описание тега'
                      },
                      {
                        id: 4,
                        name: 'Железо',
                        desc: 'Описание тега'
                      },
                      {
                        id: 5,
                        name: 'Белки',
                        desc: 'Описание тега'
                      }
                    ],
                    slider: [
                      {
                        id: 1,
                        // tslint:disable-next-line:max-line-length
                        title: 'Ощущения пациента во время проведения ультразвуковой эластографии такие же, как и при обычном УЗИ. Ни боли, ни дискомфорта пациенты не испытывают.',
                        // tslint:disable-next-line:max-line-length
                        desc: 'Ощущения пациента во время проведения ультразвуковой эластографии такие же, как и при обычном УЗИ. Ни боли, ни дискомфорта пациенты не испытывают.',
                        main_photo: {
                          url: '../../assets/img/big-slide-3.png'
                        },
                      },
                      {
                        id: 2,
                        // tslint:disable-next-line:max-line-length
                        title: 'Ощущения пациента во время проведения ',
                        // tslint:disable-next-line:max-line-length
                        desc: 'Ощущения пациента во время проведения ультразвуковой эластографии такие же, как и при обычном УЗИ. Ни боли, ни дискомфорта пациенты не испытывают.',
                        main_photo: {
                          url: '../../assets/img/big-slide-4.png'
                        }
                      }
                    ],
                    catalog:
                      {
                        id: 1,
                        name: 'Клинический анализ крови',
                        desc: 'Описание Клинический анализ крови'
                      }
                  },
                  {
                    id: 3,
                    name: 'УЗИ печеночного трансплантанта с дуплексным сканированием артерий и вен',
                    display_name: 'УЗИ печеночного трансплантанта с дуплексным сканированием артерий и вен',
                    price: '1320',
                    // tslint:disable-next-line:max-line-length
                    anons: 'Ультразвуковую диагностику смело можно назвать уникальным методом исследования. Она обладает такими свойствами как неинвазивность, возможность многократного проведения исследования без вреда для здоровья пациента, быстрота получения информации, отсутствие противопоказаний, исключение лучевой нагрузки.',
                    // tslint:disable-next-line:max-line-length
                    body: 'Проснувшись однажды утром после беспокойного сна, Грегор Замза обнаружил, что он у себя в постели превратился в страшное насекомое.',
                    // tslint:disable-next-line:max-line-length
                    memo: 'Вы должны подготовить себя к исследованию следующим образом: воздержаться от физических нагрузок, приема алкоголя и лекарств, изменений в питании в течение 24 часов до взятия крови. Вам не следует принимать пищу после ужина, лечь спать нужно в обычное для Вас время и встать не позднее, чем за час до взятия крови. Утром до сдачи анализа воздержитесь от курения и приема пищи. Взятие крови осуществляется до проведения диагностических или лечебных процедур: инъекций, общего массажа тела, эндоскопий, ЭКГ, рентгеновского обследования и т.д. Если Вы испытываете трудности с отменой лекарств, то обязательно сообщите об этом лечащему врачу. Очень важно, чтобы Вы точно соблюдали указанные рекомендации, так как только в этом случае будут получены ценные результаты исследования крови.',
                    // tslint:disable-next-line:max-line-length
                    indication: 'Анализ на свертываемость является одним из самых простых и доступных методов исследования. Пациенту нужно отказываться от приема пищи в течение двух часов перед исследованием. Результаты готовы, как правило, через несколько часов. Такое тестирование проводится в рамках комплексного обследования организма, а также при наличии кровотечений невыясненной природы и при диагностике или контроле эффективности лечения патологий костного мозга. Референсные значения зависят от возраста, а в детстве — еще и от пола.',
                    contraindic: '<ul>При некоторых заболеваниях проведение забора тромбоцитарной массы категорически запрещено: \n' +
                      '          <li>туберкулез;</li>\n' +
                      '          <li>вирус иммунодефицита;</li>\n' +
                      '          <li>раковые патологии;</li>\n' +
                      '          <li>гепатиты;</li>\n' +
                      '          <li>нарушения в работе сердца и сосудов;</li>\n' +
                      '          <li>после проведения оперативных вмешательств;</li>\n' +
                      '          <li>беременность;</li>\n' +
                      '          <li>алкоголизм;</li>\n' +
                      '          <li>недавнее введение прививки;</li>\n' +
                      '          <li>язва желудка или двенадцатиперстной кишки;</li>\n' +
                      '          <li>татуировки;</li>\n' +
                      '          <li>обширные стоматологические поражения.</li>\n' +
                      '        </ul>',
                    sale: '20',
                    doctor: [ // TODO могут ли одну услугу оказывать несколько врачей?
                      {
                        id: 1,
                        lastName: 'Коновалов',
                        firstName: 'Геннадий',
                        middleName: 'Александрович',
                        specialization: 'Главный ревматолог',
                        main_photo: {
                          id: 1,
                          url: '../../assets/img/doctors/doc-3.png'
                        }
                      },
                      {
                        id: 2,
                        lastName: 'Морозова',
                        firstName: 'Екатерина',
                        middleName: 'Владимировна',
                        specialization: 'ЛОР',
                        main_photo: {
                          id: 11,
                          url: '../../assets/img/doctors/doc-5.png'
                        }
                      }
                    ],
                    documents: [
                      {
                        id: 1,
                        title: 'Паспорт',
                        // tslint:disable-next-line:max-line-length
                        desc: 'Проснувшись однажды утром после беспокойного сна, Грегор Замза обнаружил, что он у себя в постели превратился в страшное насекомое.',
                        main_photo:
                          {
                            id: 1,
                            url: '../../assets/img/doc-img-1.png',
                          }
                      },
                      {
                        id: 2,
                        title: 'Медицинский полис',
                        // tslint:disable-next-line:max-line-length
                        desc: 'Проснувшись однажды утром после беспокойного сна, Грегор Замза обнаружил, что он у себя в постели превратился в страшное насекомое.',
                        main_photo:
                          {
                            id: 1,
                            url: '../../assets/img/doc-img-2.png',
                          }
                      },
                      {
                        id: 3,
                        title: 'Направление доктора',
                        // tslint:disable-next-line:max-line-length
                        desc: 'Проснувшись однажды утром после беспокойного сна, Грегор Замза обнаружил, что он у себя в постели превратился в страшное насекомое.',
                        main_photo:
                          {
                            id: 1,
                            url: '../../assets/img/doc-img-3.png',
                          }
                      }
                    ],
                    tags: [
                      {
                        id: 1,
                        name: 'Кровь',
                        desc: 'Описание тега'
                      },
                      {
                        id: 2,
                        name: 'Глюкоза',
                        desc: 'Описание тега'
                      },
                      {
                        id: 3,
                        name: 'Витамины',
                        desc: 'Описание тега'
                      }
                    ],
                    catalog:
                      {
                        id: 1,
                        name: 'Клинический анализ крови',
                        desc: 'Описание Клинический анализ крови'
                      }
                  },
                  {
                    id: 6,
                    name: 'УЗИ печеночного трансплантанта с дуплексным сканированием артерий и вен',
                    display_name: 'УЗИ печеночного трансплантанта с дуплексным сканированием артерий и вен',
                    price: '1320',
                    // tslint:disable-next-line:max-line-length
                    anons: 'Ультразвуковую диагностику смело можно назвать уникальным методом исследования. Она обладает такими свойствами как неинвазивность, возможность многократного проведения исследования без вреда для здоровья пациента, быстрота получения информации, отсутствие противопоказаний, исключение лучевой нагрузки.',
                    // tslint:disable-next-line:max-line-length
                    body: 'Проснувшись однажды утром после беспокойного сна, Грегор Замза обнаружил, что он у себя в постели превратился в страшное насекомое.',
                    // tslint:disable-next-line:max-line-length
                    memo: 'Вы должны подготовить себя к исследованию следующим образом: воздержаться от физических нагрузок, приема алкоголя и лекарств, изменений в питании в течение 24 часов до взятия крови. Вам не следует принимать пищу после ужина, лечь спать нужно в обычное для Вас время и встать не позднее, чем за час до взятия крови. Утром до сдачи анализа воздержитесь от курения и приема пищи. Взятие крови осуществляется до проведения диагностических или лечебных процедур: инъекций, общего массажа тела, эндоскопий, ЭКГ, рентгеновского обследования и т.д. Если Вы испытываете трудности с отменой лекарств, то обязательно сообщите об этом лечащему врачу. Очень важно, чтобы Вы точно соблюдали указанные рекомендации, так как только в этом случае будут получены ценные результаты исследования крови.',
                    // tslint:disable-next-line:max-line-length
                    indication: 'Анализ на свертываемость является одним из самых простых и доступных методов исследования. Пациенту нужно отказываться от приема пищи в течение двух часов перед исследованием. Результаты готовы, как правило, через несколько часов. Такое тестирование проводится в рамках комплексного обследования организма, а также при наличии кровотечений невыясненной природы и при диагностике или контроле эффективности лечения патологий костного мозга. Референсные значения зависят от возраста, а в детстве — еще и от пола.',
                    contraindic: '<ul>При некоторых заболеваниях проведение забора тромбоцитарной массы категорически запрещено: \n' +
                      '          <li>туберкулез;</li>\n' +
                      '          <li>вирус иммунодефицита;</li>\n' +
                      '          <li>раковые патологии;</li>\n' +
                      '          <li>гепатиты;</li>\n' +
                      '          <li>нарушения в работе сердца и сосудов;</li>\n' +
                      '          <li>после проведения оперативных вмешательств;</li>\n' +
                      '          <li>беременность;</li>\n' +
                      '          <li>алкоголизм;</li>\n' +
                      '          <li>недавнее введение прививки;</li>\n' +
                      '          <li>язва желудка или двенадцатиперстной кишки;</li>\n' +
                      '          <li>татуировки;</li>\n' +
                      '          <li>обширные стоматологические поражения.</li>\n' +
                      '        </ul>',
                    sale: '20',
                    tags: [
                      {
                        id: 1,
                        name: 'Кровь',
                        desc: 'Описание тега'
                      },
                      {
                        id: 2,
                        name: 'Глюкоза',
                        desc: 'Описание тега'
                      },
                      {
                        id: 3,
                        name: 'Витамины',
                        desc: 'Описание тега'
                      }
                    ],
                    catalog:
                      {
                        id: 1,
                        name: 'Анализы крови',
                        desc: 'Описание Клинический анализ крови'
                      }
                  },
                  {
                    id: 7,
                    name: 'Анализ крови артерий и вен',
                    display_name: 'Анализ крови артерий и вен',
                    price: '1320',
                    // tslint:disable-next-line:max-line-length
                    anons: 'Ультразвуковую диагностику смело можно назвать уникальным методом исследования. Она обладает такими свойствами как неинвазивность, возможность многократного проведения исследования без вреда для здоровья пациента, быстрота получения информации, отсутствие противопоказаний, исключение лучевой нагрузки.',
                    // tslint:disable-next-line:max-line-length
                    body: 'Проснувшись однажды утром после беспокойного сна, Грегор Замза обнаружил.',
                    // tslint:disable-next-line:max-line-length
                    memo: 'Вы должны подготовить себя к исследованию следующим образом: воздержаться от физических нагрузок, приема алкоголя и лекарств, изменений в питании в течение 24 часов до взятия крови. Вам не следует принимать пищу после ужина, лечь спать нужно в обычное для Вас время и встать не позднее, чем за час до взятия крови. Утром до сдачи анализа воздержитесь от курения и приема пищи. Взятие крови осуществляется до проведения диагностических или лечебных процедур: инъекций, общего массажа тела, эндоскопий, ЭКГ, рентгеновского обследования и т.д. Если Вы испытываете трудности с отменой лекарств, то обязательно сообщите об этом лечащему врачу. Очень важно, чтобы Вы точно соблюдали указанные рекомендации, так как только в этом случае будут получены ценные результаты исследования крови.',
                    // tslint:disable-next-line:max-line-length
                    indication: 'Анализ на свертываемость является одним из самых простых и доступных методов исследования. Пациенту нужно отказываться от приема пищи в течение двух часов перед исследованием. Результаты готовы, как правило, через несколько часов. Такое тестирование проводится в рамках комплексного обследования организма, а также при наличии кровотечений невыясненной природы и при диагностике или контроле эффективности лечения патологий костного мозга. Референсные значения зависят от возраста, а в детстве — еще и от пола.',
                    contraindic: '<ul>При некоторых заболеваниях проведение забора тромбоцитарной массы категорически запрещено: \n' +
                      '          <li>туберкулез;</li>\n' +
                      '          <li>вирус иммунодефицита;</li>\n' +
                      '          <li>раковые патологии;</li>\n' +
                      '          <li>гепатиты;</li>\n' +
                      '          <li>нарушения в работе сердца и сосудов;</li>\n' +
                      '          <li>после проведения оперативных вмешательств;</li>\n' +
                      '          <li>беременность;</li>\n' +
                      '          <li>алкоголизм;</li>\n' +
                      '          <li>недавнее введение прививки;</li>\n' +
                      '          <li>язва желудка или двенадцатиперстной кишки;</li>\n' +
                      '          <li>татуировки;</li>\n' +
                      '          <li>обширные стоматологические поражения.</li>\n' +
                      '        </ul>',
                    sale: '',
                    documents: [
                      {
                        id: 1,
                        title: 'Паспорт',
                        // tslint:disable-next-line:max-line-length
                        desc: 'Проснувшись однажды утром после беспокойного сна, Грегор Замза обнаружил, что он у себя в постели превратился в страшное насекомое.',
                        main_photo:
                          {
                            id: 1,
                            url: '../../assets/img/doc-img-1.png',
                          }
                      }
                    ],
                    tags: [
                      {
                        id: 1,
                        name: 'Кровь',
                        desc: 'Описание тега'
                      },
                      {
                        id: 2,
                        name: 'Глюкоза',
                        desc: 'Описание тега'
                      },
                      {
                        id: 3,
                        name: 'Гормоны',
                        desc: 'Описание тега'
                      }
                    ],
                    catalog:
                      {
                        id: 1,
                        name: 'Анализы крови',
                        desc: 'Описание Клинический анализ крови'
                      }
                  }
                ],
              },
              {
                id: 23,
                name: 'Эндоскопия',
                main_photo: {
                  id: 1,
                  url: '../../assets/img/direction2-1.png',
                },
                short_description: 'Краткое описание Эндоскопия',
                description: 'Полное описание Эндоскопия',
                icon: null,
                units: [
                  {
                    id: 2,
                    name: 'Поликлиника №1',
                    address: {
                      id: 1,
                      city: 'Красноярск',
                      street: 'Вавилова',
                      house: '28',
                      building: '',
                      ymapcfg: '../../assets/img/contacts-card-map.png',
                      postcode: '660025',
                      region: 'Красноярский край',
                      mailbox: '6545'
                    },
                    phones: [
                      {
                        id: 7,
                        number: '+7 (391) 274-31-64',
                        description: 'круглосуточно'
                      },
                      {
                        id: 8,
                        number: '+7 (391) 257-64-60',
                        description: 'с 9:00 до 17:00'
                      }
                    ],
                  },
                ],
                services: [
                  {
                    id: 5,
                    name: 'УЗИ печеночного трансплантанта с дуплексным сканированием артерий и вен',
                    display_name: 'УЗИ печеночного трансплантанта с дуплексным сканированием артерий и вен',
                    price: '1320',
                    // tslint:disable-next-line:max-line-length
                    anons: 'Ультразвуковую диагностику смело можно назвать уникальным методом исследования. Она обладает такими свойствами как неинвазивность, возможность многократного проведения исследования без вреда для здоровья пациента, быстрота получения информации, отсутствие противопоказаний, исключение лучевой нагрузки.',
                    // tslint:disable-next-line:max-line-length
                    body: 'Проснувшись однажды утром после беспокойного сна, Грегор Замза обнаружил, что он у себя в постели превратился в страшное насекомое.',
                    // tslint:disable-next-line:max-line-length
                    memo: 'Вы должны подготовить себя к исследованию следующим образом: воздержаться от физических нагрузок, приема алкоголя и лекарств, изменений в питании в течение 24 часов до взятия крови. Вам не следует принимать пищу после ужина, лечь спать нужно в обычное для Вас время и встать не позднее, чем за час до взятия крови. Утром до сдачи анализа воздержитесь от курения и приема пищи. Взятие крови осуществляется до проведения диагностических или лечебных процедур: инъекций, общего массажа тела, эндоскопий, ЭКГ, рентгеновского обследования и т.д. Если Вы испытываете трудности с отменой лекарств, то обязательно сообщите об этом лечащему врачу. Очень важно, чтобы Вы точно соблюдали указанные рекомендации, так как только в этом случае будут получены ценные результаты исследования крови.',
                    // tslint:disable-next-line:max-line-length
                    indication: 'Анализ на свертываемость является одним из самых простых и доступных методов исследования. Пациенту нужно отказываться от приема пищи в течение двух часов перед исследованием. Результаты готовы, как правило, через несколько часов. Такое тестирование проводится в рамках комплексного обследования организма, а также при наличии кровотечений невыясненной природы и при диагностике или контроле эффективности лечения патологий костного мозга. Референсные значения зависят от возраста, а в детстве — еще и от пола.',
                    contraindic: '<ul>При некоторых заболеваниях проведение забора тромбоцитарной массы категорически запрещено: \n' +
                      '          <li>туберкулез;</li>\n' +
                      '          <li>вирус иммунодефицита;</li>\n' +
                      '          <li>раковые патологии;</li>\n' +
                      '          <li>гепатиты;</li>\n' +
                      '          <li>нарушения в работе сердца и сосудов;</li>\n' +
                      '          <li>после проведения оперативных вмешательств;</li>\n' +
                      '          <li>беременность;</li>\n' +
                      '          <li>алкоголизм;</li>\n' +
                      '          <li>недавнее введение прививки;</li>\n' +
                      '          <li>язва желудка или двенадцатиперстной кишки;</li>\n' +
                      '          <li>татуировки;</li>\n' +
                      '          <li>обширные стоматологические поражения.</li>\n' +
                      '        </ul>',
                    sale: '',
                    documents: [
                      {
                        id: 1,
                        title: 'Паспорт',
                        // tslint:disable-next-line:max-line-length
                        desc: 'Проснувшись однажды утром после беспокойного сна, Грегор Замза обнаружил, что он у себя в постели превратился в страшное насекомое.',
                        main_photo:
                          {
                            id: 1,
                            url: '../../assets/img/doc-img-1.png',
                          }
                      }
                    ],
                    tags: [
                      {
                        id: 1,
                        name: 'Кровь',
                        desc: 'Описание тега'
                      },
                      {
                        id: 2,
                        name: 'Глюкоза',
                        desc: 'Описание тега'
                      },
                      {
                        id: 3,
                        name: 'Витамины',
                        desc: 'Описание тега'
                      }
                    ],
                    catalog:
                      {
                        id: 1,
                        name: 'УЗИ - диагностические исследования',
                        desc: 'Описание Клинический анализ крови'
                      }
                  },
                  {
                    id: 6,
                    name: 'УЗИ печеночного трансплантанта с дуплексным сканированием артерий и вен',
                    display_name: 'УЗИ печеночного трансплантанта с дуплексным сканированием артерий и вен',
                    price: '1320',
                    // tslint:disable-next-line:max-line-length
                    anons: 'Ультразвуковую диагностику смело можно назвать уникальным методом исследования. Она обладает такими свойствами как неинвазивность, возможность многократного проведения исследования без вреда для здоровья пациента, быстрота получения информации, отсутствие противопоказаний, исключение лучевой нагрузки.',
                    // tslint:disable-next-line:max-line-length
                    body: 'Проснувшись однажды утром после беспокойного сна, Грегор Замза обнаружил, что он у себя в постели превратился в страшное насекомое.',
                    // tslint:disable-next-line:max-line-length
                    memo: 'Вы должны подготовить себя к исследованию следующим образом: воздержаться от физических нагрузок, приема алкоголя и лекарств, изменений в питании в течение 24 часов до взятия крови. Вам не следует принимать пищу после ужина, лечь спать нужно в обычное для Вас время и встать не позднее, чем за час до взятия крови. Утром до сдачи анализа воздержитесь от курения и приема пищи. Взятие крови осуществляется до проведения диагностических или лечебных процедур: инъекций, общего массажа тела, эндоскопий, ЭКГ, рентгеновского обследования и т.д. Если Вы испытываете трудности с отменой лекарств, то обязательно сообщите об этом лечащему врачу. Очень важно, чтобы Вы точно соблюдали указанные рекомендации, так как только в этом случае будут получены ценные результаты исследования крови.',
                    // tslint:disable-next-line:max-line-length
                    indication: 'Анализ на свертываемость является одним из самых простых и доступных методов исследования. Пациенту нужно отказываться от приема пищи в течение двух часов перед исследованием. Результаты готовы, как правило, через несколько часов. Такое тестирование проводится в рамках комплексного обследования организма, а также при наличии кровотечений невыясненной природы и при диагностике или контроле эффективности лечения патологий костного мозга. Референсные значения зависят от возраста, а в детстве — еще и от пола.',
                    contraindic: '<ul>При некоторых заболеваниях проведение забора тромбоцитарной массы категорически запрещено: \n' +
                      '          <li>туберкулез;</li>\n' +
                      '          <li>вирус иммунодефицита;</li>\n' +
                      '          <li>раковые патологии;</li>\n' +
                      '          <li>гепатиты;</li>\n' +
                      '          <li>нарушения в работе сердца и сосудов;</li>\n' +
                      '          <li>после проведения оперативных вмешательств;</li>\n' +
                      '          <li>беременность;</li>\n' +
                      '          <li>алкоголизм;</li>\n' +
                      '          <li>недавнее введение прививки;</li>\n' +
                      '          <li>язва желудка или двенадцатиперстной кишки;</li>\n' +
                      '          <li>татуировки;</li>\n' +
                      '          <li>обширные стоматологические поражения.</li>\n' +
                      '        </ul>',
                    sale: '20',
                    tags: [
                      {
                        id: 1,
                        name: 'Кровь',
                        desc: 'Описание тега'
                      },
                      {
                        id: 2,
                        name: 'Глюкоза',
                        desc: 'Описание тега'
                      },
                      {
                        id: 3,
                        name: 'Витамины',
                        desc: 'Описание тега'
                      }
                    ],
                    catalog:
                      {
                        id: 1,
                        name: 'Анализы крови',
                        desc: 'Описание Клинический анализ крови'
                      }
                  },
                  {
                    id: 7,
                    name: 'Анализ крови артерий и вен',
                    display_name: 'Анализ крови артерий и вен',
                    price: '1320',
                    // tslint:disable-next-line:max-line-length
                    anons: 'Ультразвуковую диагностику смело можно назвать уникальным методом исследования. Она обладает такими свойствами как неинвазивность, возможность многократного проведения исследования без вреда для здоровья пациента, быстрота получения информации, отсутствие противопоказаний, исключение лучевой нагрузки.',
                    // tslint:disable-next-line:max-line-length
                    body: 'Проснувшись однажды утром после беспокойного сна, Грегор Замза обнаружил.',
                    // tslint:disable-next-line:max-line-length
                    memo: 'Вы должны подготовить себя к исследованию следующим образом: воздержаться от физических нагрузок, приема алкоголя и лекарств, изменений в питании в течение 24 часов до взятия крови. Вам не следует принимать пищу после ужина, лечь спать нужно в обычное для Вас время и встать не позднее, чем за час до взятия крови. Утром до сдачи анализа воздержитесь от курения и приема пищи. Взятие крови осуществляется до проведения диагностических или лечебных процедур: инъекций, общего массажа тела, эндоскопий, ЭКГ, рентгеновского обследования и т.д. Если Вы испытываете трудности с отменой лекарств, то обязательно сообщите об этом лечащему врачу. Очень важно, чтобы Вы точно соблюдали указанные рекомендации, так как только в этом случае будут получены ценные результаты исследования крови.',
                    // tslint:disable-next-line:max-line-length
                    indication: 'Анализ на свертываемость является одним из самых простых и доступных методов исследования. Пациенту нужно отказываться от приема пищи в течение двух часов перед исследованием. Результаты готовы, как правило, через несколько часов. Такое тестирование проводится в рамках комплексного обследования организма, а также при наличии кровотечений невыясненной природы и при диагностике или контроле эффективности лечения патологий костного мозга. Референсные значения зависят от возраста, а в детстве — еще и от пола.',
                    contraindic: '<ul>При некоторых заболеваниях проведение забора тромбоцитарной массы категорически запрещено: \n' +
                      '          <li>туберкулез;</li>\n' +
                      '          <li>вирус иммунодефицита;</li>\n' +
                      '          <li>раковые патологии;</li>\n' +
                      '          <li>гепатиты;</li>\n' +
                      '          <li>нарушения в работе сердца и сосудов;</li>\n' +
                      '          <li>после проведения оперативных вмешательств;</li>\n' +
                      '          <li>беременность;</li>\n' +
                      '          <li>алкоголизм;</li>\n' +
                      '          <li>недавнее введение прививки;</li>\n' +
                      '          <li>язва желудка или двенадцатиперстной кишки;</li>\n' +
                      '          <li>татуировки;</li>\n' +
                      '          <li>обширные стоматологические поражения.</li>\n' +
                      '        </ul>',
                    sale: '',
                    documents: [
                      {
                        id: 1,
                        title: 'Паспорт',
                        // tslint:disable-next-line:max-line-length
                        desc: 'Проснувшись однажды утром после беспокойного сна, Грегор Замза обнаружил, что он у себя в постели превратился в страшное насекомое.',
                        main_photo:
                          {
                            id: 1,
                            url: '../../assets/img/doc-img-1.png',
                          }
                      }
                    ],
                    tags: [
                      {
                        id: 1,
                        name: 'Кровь',
                        desc: 'Описание тега'
                      },
                      {
                        id: 2,
                        name: 'Глюкоза',
                        desc: 'Описание тега'
                      },
                      {
                        id: 3,
                        name: 'Гормоны',
                        desc: 'Описание тега'
                      }
                    ],
                    catalog:
                      {
                        id: 1,
                        name: 'Анализы крови',
                        desc: 'Описание Клинический анализ крови'
                      }
                  }
                ],
              }
            ],
            directions: [
              {
                id: 60,
                name: 'root',
                short_description: null,
                description: null,
                icon: null
              }
            ],
          },
          {
            id: 19,
            name: 'Амбулаторный прием',
            short_description: 'Проснувшись однажды утром после беспокойного сна, Грегор Замза обнаружил, что он у себя в постели превратился в страшное насекомое.',
            description: 'Полное описание Диагностика. Его многочисленные, убого тонкие по сравнению с остальным телом ножки беспомощно копошились у него перед глазами. «Что со мной случилось? » – подумал он. Это не было сном.',
            main_photo: { // TODO добавить
              id: 1,
              url: '../../assets/img/direction-2.png',
            },
            subdirections: [
              {
                id: 21,
                name: 'Рентген',
                main_photo: {
                  id: 1,
                  url: '../../assets/img/direction2-1.png',
                },
                short_description: 'Краткое описание Рентген',
                description: 'Полное описание Рентген',
                icon: null,
                units: [
                  {
                    id: 2,
                    name: 'Поликлиника №1',
                    address: {
                      id: 1,
                      city: 'Красноярск',
                      street: 'Вавилова',
                      house: '28',
                      building: '',
                      ymapcfg: '../../assets/img/contacts-card-map.png',
                      postcode: '660025',
                      region: 'Красноярский край',
                      mailbox: '6545'
                    },
                    phones: [
                      {
                        id: 7,
                        number: '+7 (391) 274-31-64',
                        description: 'круглосуточно'
                      },
                      {
                        id: 8,
                        number: '+7 (391) 257-64-60',
                        description: 'с 9:00 до 17:00'
                      }
                    ],
                  },
                  {
                    id: 3,
                    name: 'Поликлиника №3',
                    address: {
                      id: 1,
                      city: 'Красноярск',
                      street: 'Коломенская',
                      house: '28',
                      building: '',
                      ymapcfg: '../../assets/img/contacts-card-map.png',
                      postcode: '660025',
                      region: 'Красноярский край',
                      mailbox: '6545'
                    },
                    phones: [
                      {
                        id: 7,
                        number: '+7 (391) 274-31-64',
                        description: 'круглосуточно'
                      },
                      {
                        id: 8,
                        number: '+7 (391) 257-64-60',
                        description: 'с 9:00 до 17:00'
                      }
                    ],
                  },
                ],
                services: [
                  {
                    id: 4,
                    name: 'УЗИ печеночного трансплантанта с дуплексным сканированием артерий и вен',
                    display_name: 'УЗИ печеночного трансплантанта с дуплексным сканированием артерий и вен',
                    price: '1320',
                    // tslint:disable-next-line:max-line-length
                    anons: 'Ультразвуковую диагностику смело можно назвать уникальным методом исследования. Она обладает такими свойствами как неинвазивность, возможность многократного проведения исследования без вреда для здоровья пациента, быстрота получения информации, отсутствие противопоказаний, исключение лучевой нагрузки.',
                    // tslint:disable-next-line:max-line-length
                    body: 'Проснувшись однажды утром после беспокойного сна, Грегор Замза обнаружил, что он у себя в постели превратился в страшное насекомое.',
                    // tslint:disable-next-line:max-line-length
                    memo: 'Вы должны подготовить себя к исследованию следующим образом: воздержаться от физических нагрузок, приема алкоголя и лекарств, изменений в питании в течение 24 часов до взятия крови. Вам не следует принимать пищу после ужина, лечь спать нужно в обычное для Вас время и встать не позднее, чем за час до взятия крови. Утром до сдачи анализа воздержитесь от курения и приема пищи. Взятие крови осуществляется до проведения диагностических или лечебных процедур: инъекций, общего массажа тела, эндоскопий, ЭКГ, рентгеновского обследования и т.д. Если Вы испытываете трудности с отменой лекарств, то обязательно сообщите об этом лечащему врачу. Очень важно, чтобы Вы точно соблюдали указанные рекомендации, так как только в этом случае будут получены ценные результаты исследования крови.',
                    // tslint:disable-next-line:max-line-length
                    indication: 'Анализ на свертываемость является одним из самых простых и доступных методов исследования. Пациенту нужно отказываться от приема пищи в течение двух часов перед исследованием. Результаты готовы, как правило, через несколько часов. Такое тестирование проводится в рамках комплексного обследования организма, а также при наличии кровотечений невыясненной природы и при диагностике или контроле эффективности лечения патологий костного мозга. Референсные значения зависят от возраста, а в детстве — еще и от пола.',
                    contraindic: '<ul>При некоторых заболеваниях проведение забора тромбоцитарной массы категорически запрещено: \n' +
                      '          <li>туберкулез;</li>\n' +
                      '          <li>вирус иммунодефицита;</li>\n' +
                      '          <li>раковые патологии;</li>\n' +
                      '          <li>гепатиты;</li>\n' +
                      '          <li>нарушения в работе сердца и сосудов;</li>\n' +
                      '          <li>после проведения оперативных вмешательств;</li>\n' +
                      '          <li>беременность;</li>\n' +
                      '          <li>алкоголизм;</li>\n' +
                      '          <li>недавнее введение прививки;</li>\n' +
                      '          <li>язва желудка или двенадцатиперстной кишки;</li>\n' +
                      '          <li>татуировки;</li>\n' +
                      '          <li>обширные стоматологические поражения.</li>\n' +
                      '        </ul>',
                    sale: '',
                    documents: [
                      {
                        id: 1,
                        title: 'Паспорт',
                        // tslint:disable-next-line:max-line-length
                        desc: 'Проснувшись однажды утром после беспокойного сна, Грегор Замза обнаружил, что он у себя в постели превратился в страшное насекомое.',
                        main_photo:
                          {
                            id: 1,
                            url: '../../assets/img/doc-img-1.png',
                          }
                      }
                    ],
                    tags: [
                      {
                        id: 1,
                        name: 'Кровь',
                        desc: 'Описание тега'
                      },
                      {
                        id: 2,
                        name: 'Глюкоза',
                        desc: 'Описание тега'
                      },
                      {
                        id: 3,
                        name: 'Витамины',
                        desc: 'Описание тега'
                      }
                    ],
                    catalog:
                      {
                        id: 1,
                        name: 'Клинический анализ крови',
                        desc: 'Описание Клинический анализ крови'
                      }
                  },
                  {
                    id: 5,
                    name: 'УЗИ печеночного трансплантанта с дуплексным сканированием артерий и вен',
                    display_name: 'УЗИ печеночного трансплантанта с дуплексным сканированием артерий и вен',
                    price: '1320',
                    // tslint:disable-next-line:max-line-length
                    anons: 'Ультразвуковую диагностику смело можно назвать уникальным методом исследования. Она обладает такими свойствами как неинвазивность, возможность многократного проведения исследования без вреда для здоровья пациента, быстрота получения информации, отсутствие противопоказаний, исключение лучевой нагрузки.',
                    // tslint:disable-next-line:max-line-length
                    body: 'Проснувшись однажды утром после беспокойного сна, Грегор Замза обнаружил, что он у себя в постели превратился в страшное насекомое.',
                    // tslint:disable-next-line:max-line-length
                    memo: 'Вы должны подготовить себя к исследованию следующим образом: воздержаться от физических нагрузок, приема алкоголя и лекарств, изменений в питании в течение 24 часов до взятия крови. Вам не следует принимать пищу после ужина, лечь спать нужно в обычное для Вас время и встать не позднее, чем за час до взятия крови. Утром до сдачи анализа воздержитесь от курения и приема пищи. Взятие крови осуществляется до проведения диагностических или лечебных процедур: инъекций, общего массажа тела, эндоскопий, ЭКГ, рентгеновского обследования и т.д. Если Вы испытываете трудности с отменой лекарств, то обязательно сообщите об этом лечащему врачу. Очень важно, чтобы Вы точно соблюдали указанные рекомендации, так как только в этом случае будут получены ценные результаты исследования крови.',
                    // tslint:disable-next-line:max-line-length
                    indication: 'Анализ на свертываемость является одним из самых простых и доступных методов исследования. Пациенту нужно отказываться от приема пищи в течение двух часов перед исследованием. Результаты готовы, как правило, через несколько часов. Такое тестирование проводится в рамках комплексного обследования организма, а также при наличии кровотечений невыясненной природы и при диагностике или контроле эффективности лечения патологий костного мозга. Референсные значения зависят от возраста, а в детстве — еще и от пола.',
                    contraindic: '<ul>При некоторых заболеваниях проведение забора тромбоцитарной массы категорически запрещено: \n' +
                      '          <li>туберкулез;</li>\n' +
                      '          <li>вирус иммунодефицита;</li>\n' +
                      '          <li>раковые патологии;</li>\n' +
                      '          <li>гепатиты;</li>\n' +
                      '          <li>нарушения в работе сердца и сосудов;</li>\n' +
                      '          <li>после проведения оперативных вмешательств;</li>\n' +
                      '          <li>беременность;</li>\n' +
                      '          <li>алкоголизм;</li>\n' +
                      '          <li>недавнее введение прививки;</li>\n' +
                      '          <li>язва желудка или двенадцатиперстной кишки;</li>\n' +
                      '          <li>татуировки;</li>\n' +
                      '          <li>обширные стоматологические поражения.</li>\n' +
                      '        </ul>',
                    sale: '',
                    documents: [
                      {
                        id: 1,
                        title: 'Паспорт',
                        // tslint:disable-next-line:max-line-length
                        desc: 'Проснувшись однажды утром после беспокойного сна, Грегор Замза обнаружил, что он у себя в постели превратился в страшное насекомое.',
                        main_photo:
                          {
                            id: 1,
                            url: '../../assets/img/doc-img-1.png',
                          }
                      }
                    ],
                    tags: [
                      {
                        id: 1,
                        name: 'Кровь',
                        desc: 'Описание тега'
                      },
                      {
                        id: 2,
                        name: 'Глюкоза',
                        desc: 'Описание тега'
                      },
                      {
                        id: 3,
                        name: 'Витамины',
                        desc: 'Описание тега'
                      }
                    ],
                    catalog:
                      {
                        id: 1,
                        name: 'УЗИ - диагностические исследования',
                        desc: 'Описание Клинический анализ крови'
                      }
                  },
                  {
                    id: 6,
                    name: 'УЗИ печеночного трансплантанта с дуплексным сканированием артерий и вен',
                    display_name: 'УЗИ печеночного трансплантанта с дуплексным сканированием артерий и вен',
                    price: '1320',
                    // tslint:disable-next-line:max-line-length
                    anons: 'Ультразвуковую диагностику смело можно назвать уникальным методом исследования. Она обладает такими свойствами как неинвазивность, возможность многократного проведения исследования без вреда для здоровья пациента, быстрота получения информации, отсутствие противопоказаний, исключение лучевой нагрузки.',
                    // tslint:disable-next-line:max-line-length
                    body: 'Проснувшись однажды утром после беспокойного сна, Грегор Замза обнаружил, что он у себя в постели превратился в страшное насекомое.',
                    // tslint:disable-next-line:max-line-length
                    memo: 'Вы должны подготовить себя к исследованию следующим образом: воздержаться от физических нагрузок, приема алкоголя и лекарств, изменений в питании в течение 24 часов до взятия крови. Вам не следует принимать пищу после ужина, лечь спать нужно в обычное для Вас время и встать не позднее, чем за час до взятия крови. Утром до сдачи анализа воздержитесь от курения и приема пищи. Взятие крови осуществляется до проведения диагностических или лечебных процедур: инъекций, общего массажа тела, эндоскопий, ЭКГ, рентгеновского обследования и т.д. Если Вы испытываете трудности с отменой лекарств, то обязательно сообщите об этом лечащему врачу. Очень важно, чтобы Вы точно соблюдали указанные рекомендации, так как только в этом случае будут получены ценные результаты исследования крови.',
                    // tslint:disable-next-line:max-line-length
                    indication: 'Анализ на свертываемость является одним из самых простых и доступных методов исследования. Пациенту нужно отказываться от приема пищи в течение двух часов перед исследованием. Результаты готовы, как правило, через несколько часов. Такое тестирование проводится в рамках комплексного обследования организма, а также при наличии кровотечений невыясненной природы и при диагностике или контроле эффективности лечения патологий костного мозга. Референсные значения зависят от возраста, а в детстве — еще и от пола.',
                    contraindic: '<ul>При некоторых заболеваниях проведение забора тромбоцитарной массы категорически запрещено: \n' +
                      '          <li>туберкулез;</li>\n' +
                      '          <li>вирус иммунодефицита;</li>\n' +
                      '          <li>раковые патологии;</li>\n' +
                      '          <li>гепатиты;</li>\n' +
                      '          <li>нарушения в работе сердца и сосудов;</li>\n' +
                      '          <li>после проведения оперативных вмешательств;</li>\n' +
                      '          <li>беременность;</li>\n' +
                      '          <li>алкоголизм;</li>\n' +
                      '          <li>недавнее введение прививки;</li>\n' +
                      '          <li>язва желудка или двенадцатиперстной кишки;</li>\n' +
                      '          <li>татуировки;</li>\n' +
                      '          <li>обширные стоматологические поражения.</li>\n' +
                      '        </ul>',
                    sale: '20',
                    tags: [
                      {
                        id: 1,
                        name: 'Кровь',
                        desc: 'Описание тега'
                      },
                      {
                        id: 2,
                        name: 'Глюкоза',
                        desc: 'Описание тега'
                      },
                      {
                        id: 3,
                        name: 'Витамины',
                        desc: 'Описание тега'
                      }
                    ],
                    catalog:
                      {
                        id: 1,
                        name: 'Анализы крови',
                        desc: 'Описание Клинический анализ крови'
                      }
                  },
                  {
                    id: 7,
                    name: 'Анализ крови артерий и вен',
                    display_name: 'Анализ крови артерий и вен',
                    price: '1320',
                    // tslint:disable-next-line:max-line-length
                    anons: 'Ультразвуковую диагностику смело можно назвать уникальным методом исследования. Она обладает такими свойствами как неинвазивность, возможность многократного проведения исследования без вреда для здоровья пациента, быстрота получения информации, отсутствие противопоказаний, исключение лучевой нагрузки.',
                    // tslint:disable-next-line:max-line-length
                    body: 'Проснувшись однажды утром после беспокойного сна, Грегор Замза обнаружил.',
                    // tslint:disable-next-line:max-line-length
                    memo: 'Вы должны подготовить себя к исследованию следующим образом: воздержаться от физических нагрузок, приема алкоголя и лекарств, изменений в питании в течение 24 часов до взятия крови. Вам не следует принимать пищу после ужина, лечь спать нужно в обычное для Вас время и встать не позднее, чем за час до взятия крови. Утром до сдачи анализа воздержитесь от курения и приема пищи. Взятие крови осуществляется до проведения диагностических или лечебных процедур: инъекций, общего массажа тела, эндоскопий, ЭКГ, рентгеновского обследования и т.д. Если Вы испытываете трудности с отменой лекарств, то обязательно сообщите об этом лечащему врачу. Очень важно, чтобы Вы точно соблюдали указанные рекомендации, так как только в этом случае будут получены ценные результаты исследования крови.',
                    // tslint:disable-next-line:max-line-length
                    indication: 'Анализ на свертываемость является одним из самых простых и доступных методов исследования. Пациенту нужно отказываться от приема пищи в течение двух часов перед исследованием. Результаты готовы, как правило, через несколько часов. Такое тестирование проводится в рамках комплексного обследования организма, а также при наличии кровотечений невыясненной природы и при диагностике или контроле эффективности лечения патологий костного мозга. Референсные значения зависят от возраста, а в детстве — еще и от пола.',
                    contraindic: '<ul>При некоторых заболеваниях проведение забора тромбоцитарной массы категорически запрещено: \n' +
                      '          <li>туберкулез;</li>\n' +
                      '          <li>вирус иммунодефицита;</li>\n' +
                      '          <li>раковые патологии;</li>\n' +
                      '          <li>гепатиты;</li>\n' +
                      '          <li>нарушения в работе сердца и сосудов;</li>\n' +
                      '          <li>после проведения оперативных вмешательств;</li>\n' +
                      '          <li>беременность;</li>\n' +
                      '          <li>алкоголизм;</li>\n' +
                      '          <li>недавнее введение прививки;</li>\n' +
                      '          <li>язва желудка или двенадцатиперстной кишки;</li>\n' +
                      '          <li>татуировки;</li>\n' +
                      '          <li>обширные стоматологические поражения.</li>\n' +
                      '        </ul>',
                    sale: '',
                    documents: [
                      {
                        id: 1,
                        title: 'Паспорт',
                        // tslint:disable-next-line:max-line-length
                        desc: 'Проснувшись однажды утром после беспокойного сна, Грегор Замза обнаружил, что он у себя в постели превратился в страшное насекомое.',
                        main_photo:
                          {
                            id: 1,
                            url: '../../assets/img/doc-img-1.png',
                          }
                      }
                    ],
                    tags: [
                      {
                        id: 1,
                        name: 'Кровь',
                        desc: 'Описание тега'
                      },
                      {
                        id: 2,
                        name: 'Глюкоза',
                        desc: 'Описание тега'
                      },
                      {
                        id: 3,
                        name: 'Гормоны',
                        desc: 'Описание тега'
                      }
                    ],
                    catalog:
                      {
                        id: 1,
                        name: 'Анализы крови',
                        desc: 'Описание Клинический анализ крови'
                      }
                  }
                ],
              },
              {
                id: 24,
                name: 'Функциональная диагностика',
                main_photo: {
                  id: 1,
                  url: '../../assets/img/direction2-3.png',
                },
                short_description: 'Краткое описание Функциональная диагностика',
                description: 'Полное описание Функциональная диагностика',
                icon: null,
                units: [
                  {
                    id: 2,
                    name: 'Поликлиника №1',
                    address: {
                      id: 1,
                      city: 'Красноярск',
                      street: 'Вавилова',
                      house: '28',
                      building: '',
                      ymapcfg: '../../assets/img/contacts-card-map.png',
                      postcode: '660025',
                      region: 'Красноярский край',
                      mailbox: '6545'
                    },
                    phones: [
                      {
                        id: 7,
                        number: '+7 (391) 274-31-64',
                        description: 'круглосуточно'
                      },
                      {
                        id: 8,
                        number: '+7 (391) 257-64-60',
                        description: 'с 9:00 до 17:00'
                      }
                    ],
                  },
                  {
                    id: 3,
                    name: 'Поликлиника №3',
                    address: {
                      id: 1,
                      city: 'Красноярск',
                      street: 'Коломенская',
                      house: '28',
                      building: '',
                      ymapcfg: '../../assets/img/contacts-card-map.png',
                      postcode: '660025',
                      region: 'Красноярский край',
                      mailbox: '6545'
                    },
                    phones: [
                      {
                        id: 7,
                        number: '+7 (391) 274-31-64',
                        description: 'круглосуточно'
                      },
                      {
                        id: 8,
                        number: '+7 (391) 257-64-60',
                        description: 'с 9:00 до 17:00'
                      }
                    ],
                  },
                ],
                services: [
                  {
                    id: 5,
                    name: 'УЗИ печеночного трансплантанта с дуплексным сканированием артерий и вен',
                    display_name: 'УЗИ печеночного трансплантанта с дуплексным сканированием артерий и вен',
                    price: '1320',
                    // tslint:disable-next-line:max-line-length
                    anons: 'Ультразвуковую диагностику смело можно назвать уникальным методом исследования. Она обладает такими свойствами как неинвазивность, возможность многократного проведения исследования без вреда для здоровья пациента, быстрота получения информации, отсутствие противопоказаний, исключение лучевой нагрузки.',
                    // tslint:disable-next-line:max-line-length
                    body: 'Проснувшись однажды утром после беспокойного сна, Грегор Замза обнаружил, что он у себя в постели превратился в страшное насекомое.',
                    // tslint:disable-next-line:max-line-length
                    memo: 'Вы должны подготовить себя к исследованию следующим образом: воздержаться от физических нагрузок, приема алкоголя и лекарств, изменений в питании в течение 24 часов до взятия крови. Вам не следует принимать пищу после ужина, лечь спать нужно в обычное для Вас время и встать не позднее, чем за час до взятия крови. Утром до сдачи анализа воздержитесь от курения и приема пищи. Взятие крови осуществляется до проведения диагностических или лечебных процедур: инъекций, общего массажа тела, эндоскопий, ЭКГ, рентгеновского обследования и т.д. Если Вы испытываете трудности с отменой лекарств, то обязательно сообщите об этом лечащему врачу. Очень важно, чтобы Вы точно соблюдали указанные рекомендации, так как только в этом случае будут получены ценные результаты исследования крови.',
                    // tslint:disable-next-line:max-line-length
                    indication: 'Анализ на свертываемость является одним из самых простых и доступных методов исследования. Пациенту нужно отказываться от приема пищи в течение двух часов перед исследованием. Результаты готовы, как правило, через несколько часов. Такое тестирование проводится в рамках комплексного обследования организма, а также при наличии кровотечений невыясненной природы и при диагностике или контроле эффективности лечения патологий костного мозга. Референсные значения зависят от возраста, а в детстве — еще и от пола.',
                    contraindic: '<ul>При некоторых заболеваниях проведение забора тромбоцитарной массы категорически запрещено: \n' +
                      '          <li>туберкулез;</li>\n' +
                      '          <li>вирус иммунодефицита;</li>\n' +
                      '          <li>раковые патологии;</li>\n' +
                      '          <li>гепатиты;</li>\n' +
                      '          <li>нарушения в работе сердца и сосудов;</li>\n' +
                      '          <li>после проведения оперативных вмешательств;</li>\n' +
                      '          <li>беременность;</li>\n' +
                      '          <li>алкоголизм;</li>\n' +
                      '          <li>недавнее введение прививки;</li>\n' +
                      '          <li>язва желудка или двенадцатиперстной кишки;</li>\n' +
                      '          <li>татуировки;</li>\n' +
                      '          <li>обширные стоматологические поражения.</li>\n' +
                      '        </ul>',
                    sale: '',
                    documents: [
                      {
                        id: 1,
                        title: 'Паспорт',
                        // tslint:disable-next-line:max-line-length
                        desc: 'Проснувшись однажды утром после беспокойного сна, Грегор Замза обнаружил, что он у себя в постели превратился в страшное насекомое.',
                        main_photo:
                          {
                            id: 1,
                            url: '../../assets/img/doc-img-1.png',
                          }
                      }
                    ],
                    tags: [
                      {
                        id: 1,
                        name: 'Кровь',
                        desc: 'Описание тега'
                      },
                      {
                        id: 2,
                        name: 'Глюкоза',
                        desc: 'Описание тега'
                      },
                      {
                        id: 3,
                        name: 'Витамины',
                        desc: 'Описание тега'
                      }
                    ],
                    catalog:
                      {
                        id: 1,
                        name: 'УЗИ - диагностические исследования',
                        desc: 'Описание Клинический анализ крови'
                      }
                  },
                  {
                    id: 6,
                    name: 'УЗИ печеночного трансплантанта с дуплексным сканированием артерий и вен',
                    display_name: 'УЗИ печеночного трансплантанта с дуплексным сканированием артерий и вен',
                    price: '1320',
                    // tslint:disable-next-line:max-line-length
                    anons: 'Ультразвуковую диагностику смело можно назвать уникальным методом исследования. Она обладает такими свойствами как неинвазивность, возможность многократного проведения исследования без вреда для здоровья пациента, быстрота получения информации, отсутствие противопоказаний, исключение лучевой нагрузки.',
                    // tslint:disable-next-line:max-line-length
                    body: 'Проснувшись однажды утром после беспокойного сна, Грегор Замза обнаружил, что он у себя в постели превратился в страшное насекомое.',
                    // tslint:disable-next-line:max-line-length
                    memo: 'Вы должны подготовить себя к исследованию следующим образом: воздержаться от физических нагрузок, приема алкоголя и лекарств, изменений в питании в течение 24 часов до взятия крови. Вам не следует принимать пищу после ужина, лечь спать нужно в обычное для Вас время и встать не позднее, чем за час до взятия крови. Утром до сдачи анализа воздержитесь от курения и приема пищи. Взятие крови осуществляется до проведения диагностических или лечебных процедур: инъекций, общего массажа тела, эндоскопий, ЭКГ, рентгеновского обследования и т.д. Если Вы испытываете трудности с отменой лекарств, то обязательно сообщите об этом лечащему врачу. Очень важно, чтобы Вы точно соблюдали указанные рекомендации, так как только в этом случае будут получены ценные результаты исследования крови.',
                    // tslint:disable-next-line:max-line-length
                    indication: 'Анализ на свертываемость является одним из самых простых и доступных методов исследования. Пациенту нужно отказываться от приема пищи в течение двух часов перед исследованием. Результаты готовы, как правило, через несколько часов. Такое тестирование проводится в рамках комплексного обследования организма, а также при наличии кровотечений невыясненной природы и при диагностике или контроле эффективности лечения патологий костного мозга. Референсные значения зависят от возраста, а в детстве — еще и от пола.',
                    contraindic: '<ul>При некоторых заболеваниях проведение забора тромбоцитарной массы категорически запрещено: \n' +
                      '          <li>туберкулез;</li>\n' +
                      '          <li>вирус иммунодефицита;</li>\n' +
                      '          <li>раковые патологии;</li>\n' +
                      '          <li>гепатиты;</li>\n' +
                      '          <li>нарушения в работе сердца и сосудов;</li>\n' +
                      '          <li>после проведения оперативных вмешательств;</li>\n' +
                      '          <li>беременность;</li>\n' +
                      '          <li>алкоголизм;</li>\n' +
                      '          <li>недавнее введение прививки;</li>\n' +
                      '          <li>язва желудка или двенадцатиперстной кишки;</li>\n' +
                      '          <li>татуировки;</li>\n' +
                      '          <li>обширные стоматологические поражения.</li>\n' +
                      '        </ul>',
                    sale: '20',
                    tags: [
                      {
                        id: 1,
                        name: 'Кровь',
                        desc: 'Описание тега'
                      },
                      {
                        id: 2,
                        name: 'Глюкоза',
                        desc: 'Описание тега'
                      },
                      {
                        id: 3,
                        name: 'Витамины',
                        desc: 'Описание тега'
                      }
                    ],
                    catalog:
                      {
                        id: 1,
                        name: 'Анализы крови',
                        desc: 'Описание Клинический анализ крови'
                      }
                  },
                  {
                    id: 7,
                    name: 'Анализ крови артерий и вен',
                    display_name: 'Анализ крови артерий и вен',
                    price: '1320',
                    // tslint:disable-next-line:max-line-length
                    anons: 'Ультразвуковую диагностику смело можно назвать уникальным методом исследования. Она обладает такими свойствами как неинвазивность, возможность многократного проведения исследования без вреда для здоровья пациента, быстрота получения информации, отсутствие противопоказаний, исключение лучевой нагрузки.',
                    // tslint:disable-next-line:max-line-length
                    body: 'Проснувшись однажды утром после беспокойного сна, Грегор Замза обнаружил.',
                    // tslint:disable-next-line:max-line-length
                    memo: 'Вы должны подготовить себя к исследованию следующим образом: воздержаться от физических нагрузок, приема алкоголя и лекарств, изменений в питании в течение 24 часов до взятия крови. Вам не следует принимать пищу после ужина, лечь спать нужно в обычное для Вас время и встать не позднее, чем за час до взятия крови. Утром до сдачи анализа воздержитесь от курения и приема пищи. Взятие крови осуществляется до проведения диагностических или лечебных процедур: инъекций, общего массажа тела, эндоскопий, ЭКГ, рентгеновского обследования и т.д. Если Вы испытываете трудности с отменой лекарств, то обязательно сообщите об этом лечащему врачу. Очень важно, чтобы Вы точно соблюдали указанные рекомендации, так как только в этом случае будут получены ценные результаты исследования крови.',
                    // tslint:disable-next-line:max-line-length
                    indication: 'Анализ на свертываемость является одним из самых простых и доступных методов исследования. Пациенту нужно отказываться от приема пищи в течение двух часов перед исследованием. Результаты готовы, как правило, через несколько часов. Такое тестирование проводится в рамках комплексного обследования организма, а также при наличии кровотечений невыясненной природы и при диагностике или контроле эффективности лечения патологий костного мозга. Референсные значения зависят от возраста, а в детстве — еще и от пола.',
                    contraindic: '<ul>При некоторых заболеваниях проведение забора тромбоцитарной массы категорически запрещено: \n' +
                      '          <li>туберкулез;</li>\n' +
                      '          <li>вирус иммунодефицита;</li>\n' +
                      '          <li>раковые патологии;</li>\n' +
                      '          <li>гепатиты;</li>\n' +
                      '          <li>нарушения в работе сердца и сосудов;</li>\n' +
                      '          <li>после проведения оперативных вмешательств;</li>\n' +
                      '          <li>беременность;</li>\n' +
                      '          <li>алкоголизм;</li>\n' +
                      '          <li>недавнее введение прививки;</li>\n' +
                      '          <li>язва желудка или двенадцатиперстной кишки;</li>\n' +
                      '          <li>татуировки;</li>\n' +
                      '          <li>обширные стоматологические поражения.</li>\n' +
                      '        </ul>',
                    sale: '',
                    documents: [
                      {
                        id: 1,
                        title: 'Паспорт',
                        // tslint:disable-next-line:max-line-length
                        desc: 'Проснувшись однажды утром после беспокойного сна, Грегор Замза обнаружил, что он у себя в постели превратился в страшное насекомое.',
                        main_photo:
                          {
                            id: 1,
                            url: '../../assets/img/doc-img-1.png',
                          }
                      }
                    ],
                    tags: [
                      {
                        id: 1,
                        name: 'Кровь',
                        desc: 'Описание тега'
                      },
                      {
                        id: 2,
                        name: 'Глюкоза',
                        desc: 'Описание тега'
                      },
                      {
                        id: 3,
                        name: 'Гормоны',
                        desc: 'Описание тега'
                      }
                    ],
                    catalog:
                      {
                        id: 1,
                        name: 'Анализы крови',
                        desc: 'Описание Клинический анализ крови'
                      }
                  }
                ],
              },
              {
                id: 22,
                name: 'УЗИ',
                main_photo: {
                  id: 1,
                  url: '../../assets/img/direction2-2.png',
                },
                short_description: 'Краткое описание УЗИ',
                description: 'Полное описание УЗИ',
                icon: null,
                units: [
                  {
                    id: 3,
                    name: 'Поликлиника №3',
                    address: {
                      id: 1,
                      city: 'Красноярск',
                      street: 'Коломенская',
                      house: '28',
                      building: '',
                      ymapcfg: '../../assets/img/contacts-card-map.png',
                      postcode: '660025',
                      region: 'Красноярский край',
                      mailbox: '6545'
                    },
                    phones: [
                      {
                        id: 7,
                        number: '+7 (391) 274-31-64',
                        description: 'круглосуточно'
                      },
                      {
                        id: 8,
                        number: '+7 (391) 257-64-60',
                        description: 'с 9:00 до 17:00'
                      }
                    ],
                  },
                ],
                services: [
                  {
                    id: 2,
                    // tslint:disable-next-line:max-line-length
                    name: 'Ультразвуковое исследование органов брюшной полости комплексное: печень, поджелудочная железа, желчный пузырь, селезенка при профилактическом осмотре',
                    display_name: 'УЗИ брюшной полости',
                    price: '820',
                    // tslint:disable-next-line:max-line-length
                    anons: 'Ультразвуковую диагностику смело можно назвать уникальным методом исследования. Она обладает такими свойствами как неинвазивность, возможность многократного проведения исследования без вреда для здоровья пациента, быстрота получения информации, отсутствие противопоказаний, исключение лучевой нагрузки.',
                    // tslint:disable-next-line:max-line-length
                    body: 'Проснувшись однажды утром после беспокойного сна, Грегор Замза обнаружил, что он у себя в постели превратился в страшное насекомое.',
                    // tslint:disable-next-line:max-line-length
                    memo: 'Вы должны подготовить себя к исследованию следующим образом: воздержаться от физических нагрузок, приема алкоголя и лекарств, изменений в питании в течение 24 часов до взятия крови. Вам не следует принимать пищу после ужина, лечь спать нужно в обычное для Вас время и встать не позднее, чем за час до взятия крови. Утром до сдачи анализа воздержитесь от курения и приема пищи. Взятие крови осуществляется до проведения диагностических или лечебных процедур: инъекций, общего массажа тела, эндоскопий, ЭКГ, рентгеновского обследования и т.д. Если Вы испытываете трудности с отменой лекарств, то обязательно сообщите об этом лечащему врачу. Очень важно, чтобы Вы точно соблюдали указанные рекомендации, так как только в этом случае будут получены ценные результаты исследования крови.',
                    // tslint:disable-next-line:max-line-length
                    indication: 'Анализ на свертываемость является одним из самых простых и доступных методов исследования. Пациенту нужно отказываться от приема пищи в течение двух часов перед исследованием. Результаты готовы, как правило, через несколько часов. Такое тестирование проводится в рамках комплексного обследования организма, а также при наличии кровотечений невыясненной природы и при диагностике или контроле эффективности лечения патологий костного мозга. Референсные значения зависят от возраста, а в детстве — еще и от пола.',
                    contraindic: '<ul>При некоторых заболеваниях проведение забора тромбоцитарной массы категорически запрещено: \n' +
                      '          <li>туберкулез;</li>\n' +
                      '          <li>вирус иммунодефицита;</li>\n' +
                      '          <li>раковые патологии;</li>\n' +
                      '          <li>гепатиты;</li>\n' +
                      '          <li>нарушения в работе сердца и сосудов;</li>\n' +
                      '          <li>после проведения оперативных вмешательств;</li>\n' +
                      '          <li>беременность;</li>\n' +
                      '          <li>алкоголизм;</li>\n' +
                      '          <li>недавнее введение прививки;</li>\n' +
                      '          <li>язва желудка или двенадцатиперстной кишки;</li>\n' +
                      '          <li>татуировки;</li>\n' +
                      '          <li>обширные стоматологические поражения.</li>\n' +
                      '        </ul>',
                    sale: '10',
                    doctor: [ // TODO могут ли одну услугу оказывать несколько врачей?
                      {
                        id: 1,
                        lastName: 'Замза',
                        firstName: 'Грегор',
                        middleName: '',
                        specialization: 'Уролог',
                        main_photo: {
                          id: 16,
                          url: '../../assets/img/doctors/doc-2.png'
                        }
                      }
                    ],
                    documents: [
                      {
                        id: 1,
                        title: 'Паспорт',
                        // tslint:disable-next-line:max-line-length
                        desc: 'Проснувшись однажды утром после беспокойного сна, Грегор Замза обнаружил, что он у себя в постели превратился в страшное насекомое.',
                        main_photo:
                          {
                            id: 1,
                            url: '../../assets/img/doc-img-1.png',
                          }
                      }
                    ],
                    tags: [
                      {
                        id: 1,
                        name: 'Кровь',
                        desc: 'Описание тега'
                      },
                      {
                        id: 4,
                        name: 'Железо',
                        desc: 'Описание тега'
                      },
                      {
                        id: 5,
                        name: 'Белки',
                        desc: 'Описание тега'
                      }
                    ],
                    slider: [
                      {
                        id: 1,
                        // tslint:disable-next-line:max-line-length
                        title: 'Ощущения пациента во время проведения ультразвуковой эластографии такие же, как и при обычном УЗИ. Ни боли, ни дискомфорта пациенты не испытывают.',
                        // tslint:disable-next-line:max-line-length
                        desc: 'Ощущения пациента во время проведения ультразвуковой эластографии такие же, как и при обычном УЗИ. Ни боли, ни дискомфорта пациенты не испытывают.',
                        main_photo: {
                          url: '../../assets/img/big-slide-3.png'
                        },
                      },
                      {
                        id: 2,
                        // tslint:disable-next-line:max-line-length
                        title: 'Ощущения пациента во время проведения ',
                        // tslint:disable-next-line:max-line-length
                        desc: 'Ощущения пациента во время проведения ультразвуковой эластографии такие же, как и при обычном УЗИ. Ни боли, ни дискомфорта пациенты не испытывают.',
                        main_photo: {
                          url: '../../assets/img/big-slide-4.png'
                        }
                      }
                    ],
                    catalog:
                      {
                        id: 1,
                        name: 'Клинический анализ крови',
                        desc: 'Описание Клинический анализ крови'
                      }
                  },
                  {
                    id: 3,
                    name: 'УЗИ печеночного трансплантанта с дуплексным сканированием артерий и вен',
                    display_name: 'УЗИ печеночного трансплантанта с дуплексным сканированием артерий и вен',
                    price: '1320',
                    // tslint:disable-next-line:max-line-length
                    anons: 'Ультразвуковую диагностику смело можно назвать уникальным методом исследования. Она обладает такими свойствами как неинвазивность, возможность многократного проведения исследования без вреда для здоровья пациента, быстрота получения информации, отсутствие противопоказаний, исключение лучевой нагрузки.',
                    // tslint:disable-next-line:max-line-length
                    body: 'Проснувшись однажды утром после беспокойного сна, Грегор Замза обнаружил, что он у себя в постели превратился в страшное насекомое.',
                    // tslint:disable-next-line:max-line-length
                    memo: 'Вы должны подготовить себя к исследованию следующим образом: воздержаться от физических нагрузок, приема алкоголя и лекарств, изменений в питании в течение 24 часов до взятия крови. Вам не следует принимать пищу после ужина, лечь спать нужно в обычное для Вас время и встать не позднее, чем за час до взятия крови. Утром до сдачи анализа воздержитесь от курения и приема пищи. Взятие крови осуществляется до проведения диагностических или лечебных процедур: инъекций, общего массажа тела, эндоскопий, ЭКГ, рентгеновского обследования и т.д. Если Вы испытываете трудности с отменой лекарств, то обязательно сообщите об этом лечащему врачу. Очень важно, чтобы Вы точно соблюдали указанные рекомендации, так как только в этом случае будут получены ценные результаты исследования крови.',
                    // tslint:disable-next-line:max-line-length
                    indication: 'Анализ на свертываемость является одним из самых простых и доступных методов исследования. Пациенту нужно отказываться от приема пищи в течение двух часов перед исследованием. Результаты готовы, как правило, через несколько часов. Такое тестирование проводится в рамках комплексного обследования организма, а также при наличии кровотечений невыясненной природы и при диагностике или контроле эффективности лечения патологий костного мозга. Референсные значения зависят от возраста, а в детстве — еще и от пола.',
                    contraindic: '<ul>При некоторых заболеваниях проведение забора тромбоцитарной массы категорически запрещено: \n' +
                      '          <li>туберкулез;</li>\n' +
                      '          <li>вирус иммунодефицита;</li>\n' +
                      '          <li>раковые патологии;</li>\n' +
                      '          <li>гепатиты;</li>\n' +
                      '          <li>нарушения в работе сердца и сосудов;</li>\n' +
                      '          <li>после проведения оперативных вмешательств;</li>\n' +
                      '          <li>беременность;</li>\n' +
                      '          <li>алкоголизм;</li>\n' +
                      '          <li>недавнее введение прививки;</li>\n' +
                      '          <li>язва желудка или двенадцатиперстной кишки;</li>\n' +
                      '          <li>татуировки;</li>\n' +
                      '          <li>обширные стоматологические поражения.</li>\n' +
                      '        </ul>',
                    sale: '20',
                    doctor: [ // TODO могут ли одну услугу оказывать несколько врачей?
                      {
                        id: 1,
                        lastName: 'Коновалов',
                        firstName: 'Геннадий',
                        middleName: 'Александрович',
                        specialization: 'Главный ревматолог',
                        main_photo: {
                          id: 1,
                          url: '../../assets/img/doctors/doc-3.png'
                        }
                      },
                      {
                        id: 2,
                        lastName: 'Морозова',
                        firstName: 'Екатерина',
                        middleName: 'Владимировна',
                        specialization: 'ЛОР',
                        main_photo: {
                          id: 11,
                          url: '../../assets/img/doctors/doc-5.png'
                        }
                      }
                    ],
                    documents: [
                      {
                        id: 1,
                        title: 'Паспорт',
                        // tslint:disable-next-line:max-line-length
                        desc: 'Проснувшись однажды утром после беспокойного сна, Грегор Замза обнаружил, что он у себя в постели превратился в страшное насекомое.',
                        main_photo:
                          {
                            id: 1,
                            url: '../../assets/img/doc-img-1.png',
                          }
                      },
                      {
                        id: 2,
                        title: 'Медицинский полис',
                        // tslint:disable-next-line:max-line-length
                        desc: 'Проснувшись однажды утром после беспокойного сна, Грегор Замза обнаружил, что он у себя в постели превратился в страшное насекомое.',
                        main_photo:
                          {
                            id: 1,
                            url: '../../assets/img/doc-img-2.png',
                          }
                      },
                      {
                        id: 3,
                        title: 'Направление доктора',
                        // tslint:disable-next-line:max-line-length
                        desc: 'Проснувшись однажды утром после беспокойного сна, Грегор Замза обнаружил, что он у себя в постели превратился в страшное насекомое.',
                        main_photo:
                          {
                            id: 1,
                            url: '../../assets/img/doc-img-3.png',
                          }
                      }
                    ],
                    tags: [
                      {
                        id: 1,
                        name: 'Кровь',
                        desc: 'Описание тега'
                      },
                      {
                        id: 2,
                        name: 'Глюкоза',
                        desc: 'Описание тега'
                      },
                      {
                        id: 3,
                        name: 'Витамины',
                        desc: 'Описание тега'
                      }
                    ],
                    catalog:
                      {
                        id: 1,
                        name: 'Клинический анализ крови',
                        desc: 'Описание Клинический анализ крови'
                      }
                  },
                  {
                    id: 6,
                    name: 'УЗИ печеночного трансплантанта с дуплексным сканированием артерий и вен',
                    display_name: 'УЗИ печеночного трансплантанта с дуплексным сканированием артерий и вен',
                    price: '1320',
                    // tslint:disable-next-line:max-line-length
                    anons: 'Ультразвуковую диагностику смело можно назвать уникальным методом исследования. Она обладает такими свойствами как неинвазивность, возможность многократного проведения исследования без вреда для здоровья пациента, быстрота получения информации, отсутствие противопоказаний, исключение лучевой нагрузки.',
                    // tslint:disable-next-line:max-line-length
                    body: 'Проснувшись однажды утром после беспокойного сна, Грегор Замза обнаружил, что он у себя в постели превратился в страшное насекомое.',
                    // tslint:disable-next-line:max-line-length
                    memo: 'Вы должны подготовить себя к исследованию следующим образом: воздержаться от физических нагрузок, приема алкоголя и лекарств, изменений в питании в течение 24 часов до взятия крови. Вам не следует принимать пищу после ужина, лечь спать нужно в обычное для Вас время и встать не позднее, чем за час до взятия крови. Утром до сдачи анализа воздержитесь от курения и приема пищи. Взятие крови осуществляется до проведения диагностических или лечебных процедур: инъекций, общего массажа тела, эндоскопий, ЭКГ, рентгеновского обследования и т.д. Если Вы испытываете трудности с отменой лекарств, то обязательно сообщите об этом лечащему врачу. Очень важно, чтобы Вы точно соблюдали указанные рекомендации, так как только в этом случае будут получены ценные результаты исследования крови.',
                    // tslint:disable-next-line:max-line-length
                    indication: 'Анализ на свертываемость является одним из самых простых и доступных методов исследования. Пациенту нужно отказываться от приема пищи в течение двух часов перед исследованием. Результаты готовы, как правило, через несколько часов. Такое тестирование проводится в рамках комплексного обследования организма, а также при наличии кровотечений невыясненной природы и при диагностике или контроле эффективности лечения патологий костного мозга. Референсные значения зависят от возраста, а в детстве — еще и от пола.',
                    contraindic: '<ul>При некоторых заболеваниях проведение забора тромбоцитарной массы категорически запрещено: \n' +
                      '          <li>туберкулез;</li>\n' +
                      '          <li>вирус иммунодефицита;</li>\n' +
                      '          <li>раковые патологии;</li>\n' +
                      '          <li>гепатиты;</li>\n' +
                      '          <li>нарушения в работе сердца и сосудов;</li>\n' +
                      '          <li>после проведения оперативных вмешательств;</li>\n' +
                      '          <li>беременность;</li>\n' +
                      '          <li>алкоголизм;</li>\n' +
                      '          <li>недавнее введение прививки;</li>\n' +
                      '          <li>язва желудка или двенадцатиперстной кишки;</li>\n' +
                      '          <li>татуировки;</li>\n' +
                      '          <li>обширные стоматологические поражения.</li>\n' +
                      '        </ul>',
                    sale: '20',
                    tags: [
                      {
                        id: 1,
                        name: 'Кровь',
                        desc: 'Описание тега'
                      },
                      {
                        id: 2,
                        name: 'Глюкоза',
                        desc: 'Описание тега'
                      },
                      {
                        id: 3,
                        name: 'Витамины',
                        desc: 'Описание тега'
                      }
                    ],
                    catalog:
                      {
                        id: 1,
                        name: 'Анализы крови',
                        desc: 'Описание Клинический анализ крови'
                      }
                  },
                  {
                    id: 7,
                    name: 'Анализ крови артерий и вен',
                    display_name: 'Анализ крови артерий и вен',
                    price: '1320',
                    // tslint:disable-next-line:max-line-length
                    anons: 'Ультразвуковую диагностику смело можно назвать уникальным методом исследования. Она обладает такими свойствами как неинвазивность, возможность многократного проведения исследования без вреда для здоровья пациента, быстрота получения информации, отсутствие противопоказаний, исключение лучевой нагрузки.',
                    // tslint:disable-next-line:max-line-length
                    body: 'Проснувшись однажды утром после беспокойного сна, Грегор Замза обнаружил.',
                    // tslint:disable-next-line:max-line-length
                    memo: 'Вы должны подготовить себя к исследованию следующим образом: воздержаться от физических нагрузок, приема алкоголя и лекарств, изменений в питании в течение 24 часов до взятия крови. Вам не следует принимать пищу после ужина, лечь спать нужно в обычное для Вас время и встать не позднее, чем за час до взятия крови. Утром до сдачи анализа воздержитесь от курения и приема пищи. Взятие крови осуществляется до проведения диагностических или лечебных процедур: инъекций, общего массажа тела, эндоскопий, ЭКГ, рентгеновского обследования и т.д. Если Вы испытываете трудности с отменой лекарств, то обязательно сообщите об этом лечащему врачу. Очень важно, чтобы Вы точно соблюдали указанные рекомендации, так как только в этом случае будут получены ценные результаты исследования крови.',
                    // tslint:disable-next-line:max-line-length
                    indication: 'Анализ на свертываемость является одним из самых простых и доступных методов исследования. Пациенту нужно отказываться от приема пищи в течение двух часов перед исследованием. Результаты готовы, как правило, через несколько часов. Такое тестирование проводится в рамках комплексного обследования организма, а также при наличии кровотечений невыясненной природы и при диагностике или контроле эффективности лечения патологий костного мозга. Референсные значения зависят от возраста, а в детстве — еще и от пола.',
                    contraindic: '<ul>При некоторых заболеваниях проведение забора тромбоцитарной массы категорически запрещено: \n' +
                      '          <li>туберкулез;</li>\n' +
                      '          <li>вирус иммунодефицита;</li>\n' +
                      '          <li>раковые патологии;</li>\n' +
                      '          <li>гепатиты;</li>\n' +
                      '          <li>нарушения в работе сердца и сосудов;</li>\n' +
                      '          <li>после проведения оперативных вмешательств;</li>\n' +
                      '          <li>беременность;</li>\n' +
                      '          <li>алкоголизм;</li>\n' +
                      '          <li>недавнее введение прививки;</li>\n' +
                      '          <li>язва желудка или двенадцатиперстной кишки;</li>\n' +
                      '          <li>татуировки;</li>\n' +
                      '          <li>обширные стоматологические поражения.</li>\n' +
                      '        </ul>',
                    sale: '',
                    documents: [
                      {
                        id: 1,
                        title: 'Паспорт',
                        // tslint:disable-next-line:max-line-length
                        desc: 'Проснувшись однажды утром после беспокойного сна, Грегор Замза обнаружил, что он у себя в постели превратился в страшное насекомое.',
                        main_photo:
                          {
                            id: 1,
                            url: '../../assets/img/doc-img-1.png',
                          }
                      }
                    ],
                    tags: [
                      {
                        id: 1,
                        name: 'Кровь',
                        desc: 'Описание тега'
                      },
                      {
                        id: 2,
                        name: 'Глюкоза',
                        desc: 'Описание тега'
                      },
                      {
                        id: 3,
                        name: 'Гормоны',
                        desc: 'Описание тега'
                      }
                    ],
                    catalog:
                      {
                        id: 1,
                        name: 'Анализы крови',
                        desc: 'Описание Клинический анализ крови'
                      }
                  }
                ],
              },
              {
                id: 23,
                name: 'Эндоскопия',
                main_photo: {
                  id: 1,
                  url: '../../assets/img/direction2-1.png',
                },
                short_description: 'Краткое описание Эндоскопия',
                description: 'Полное описание Эндоскопия',
                icon: null,
                units: [
                  {
                    id: 2,
                    name: 'Поликлиника №1',
                    address: {
                      id: 1,
                      city: 'Красноярск',
                      street: 'Вавилова',
                      house: '28',
                      building: '',
                      ymapcfg: '../../assets/img/contacts-card-map.png',
                      postcode: '660025',
                      region: 'Красноярский край',
                      mailbox: '6545'
                    },
                    phones: [
                      {
                        id: 7,
                        number: '+7 (391) 274-31-64',
                        description: 'круглосуточно'
                      },
                      {
                        id: 8,
                        number: '+7 (391) 257-64-60',
                        description: 'с 9:00 до 17:00'
                      }
                    ],
                  },
                ],
                services: [
                  {
                    id: 5,
                    name: 'УЗИ печеночного трансплантанта с дуплексным сканированием артерий и вен',
                    display_name: 'УЗИ печеночного трансплантанта с дуплексным сканированием артерий и вен',
                    price: '1320',
                    // tslint:disable-next-line:max-line-length
                    anons: 'Ультразвуковую диагностику смело можно назвать уникальным методом исследования. Она обладает такими свойствами как неинвазивность, возможность многократного проведения исследования без вреда для здоровья пациента, быстрота получения информации, отсутствие противопоказаний, исключение лучевой нагрузки.',
                    // tslint:disable-next-line:max-line-length
                    body: 'Проснувшись однажды утром после беспокойного сна, Грегор Замза обнаружил, что он у себя в постели превратился в страшное насекомое.',
                    // tslint:disable-next-line:max-line-length
                    memo: 'Вы должны подготовить себя к исследованию следующим образом: воздержаться от физических нагрузок, приема алкоголя и лекарств, изменений в питании в течение 24 часов до взятия крови. Вам не следует принимать пищу после ужина, лечь спать нужно в обычное для Вас время и встать не позднее, чем за час до взятия крови. Утром до сдачи анализа воздержитесь от курения и приема пищи. Взятие крови осуществляется до проведения диагностических или лечебных процедур: инъекций, общего массажа тела, эндоскопий, ЭКГ, рентгеновского обследования и т.д. Если Вы испытываете трудности с отменой лекарств, то обязательно сообщите об этом лечащему врачу. Очень важно, чтобы Вы точно соблюдали указанные рекомендации, так как только в этом случае будут получены ценные результаты исследования крови.',
                    // tslint:disable-next-line:max-line-length
                    indication: 'Анализ на свертываемость является одним из самых простых и доступных методов исследования. Пациенту нужно отказываться от приема пищи в течение двух часов перед исследованием. Результаты готовы, как правило, через несколько часов. Такое тестирование проводится в рамках комплексного обследования организма, а также при наличии кровотечений невыясненной природы и при диагностике или контроле эффективности лечения патологий костного мозга. Референсные значения зависят от возраста, а в детстве — еще и от пола.',
                    contraindic: '<ul>При некоторых заболеваниях проведение забора тромбоцитарной массы категорически запрещено: \n' +
                      '          <li>туберкулез;</li>\n' +
                      '          <li>вирус иммунодефицита;</li>\n' +
                      '          <li>раковые патологии;</li>\n' +
                      '          <li>гепатиты;</li>\n' +
                      '          <li>нарушения в работе сердца и сосудов;</li>\n' +
                      '          <li>после проведения оперативных вмешательств;</li>\n' +
                      '          <li>беременность;</li>\n' +
                      '          <li>алкоголизм;</li>\n' +
                      '          <li>недавнее введение прививки;</li>\n' +
                      '          <li>язва желудка или двенадцатиперстной кишки;</li>\n' +
                      '          <li>татуировки;</li>\n' +
                      '          <li>обширные стоматологические поражения.</li>\n' +
                      '        </ul>',
                    sale: '',
                    documents: [
                      {
                        id: 1,
                        title: 'Паспорт',
                        // tslint:disable-next-line:max-line-length
                        desc: 'Проснувшись однажды утром после беспокойного сна, Грегор Замза обнаружил, что он у себя в постели превратился в страшное насекомое.',
                        main_photo:
                          {
                            id: 1,
                            url: '../../assets/img/doc-img-1.png',
                          }
                      }
                    ],
                    tags: [
                      {
                        id: 1,
                        name: 'Кровь',
                        desc: 'Описание тега'
                      },
                      {
                        id: 2,
                        name: 'Глюкоза',
                        desc: 'Описание тега'
                      },
                      {
                        id: 3,
                        name: 'Витамины',
                        desc: 'Описание тега'
                      }
                    ],
                    catalog:
                      {
                        id: 1,
                        name: 'УЗИ - диагностические исследования',
                        desc: 'Описание Клинический анализ крови'
                      }
                  },
                  {
                    id: 6,
                    name: 'УЗИ печеночного трансплантанта с дуплексным сканированием артерий и вен',
                    display_name: 'УЗИ печеночного трансплантанта с дуплексным сканированием артерий и вен',
                    price: '1320',
                    // tslint:disable-next-line:max-line-length
                    anons: 'Ультразвуковую диагностику смело можно назвать уникальным методом исследования. Она обладает такими свойствами как неинвазивность, возможность многократного проведения исследования без вреда для здоровья пациента, быстрота получения информации, отсутствие противопоказаний, исключение лучевой нагрузки.',
                    // tslint:disable-next-line:max-line-length
                    body: 'Проснувшись однажды утром после беспокойного сна, Грегор Замза обнаружил, что он у себя в постели превратился в страшное насекомое.',
                    // tslint:disable-next-line:max-line-length
                    memo: 'Вы должны подготовить себя к исследованию следующим образом: воздержаться от физических нагрузок, приема алкоголя и лекарств, изменений в питании в течение 24 часов до взятия крови. Вам не следует принимать пищу после ужина, лечь спать нужно в обычное для Вас время и встать не позднее, чем за час до взятия крови. Утром до сдачи анализа воздержитесь от курения и приема пищи. Взятие крови осуществляется до проведения диагностических или лечебных процедур: инъекций, общего массажа тела, эндоскопий, ЭКГ, рентгеновского обследования и т.д. Если Вы испытываете трудности с отменой лекарств, то обязательно сообщите об этом лечащему врачу. Очень важно, чтобы Вы точно соблюдали указанные рекомендации, так как только в этом случае будут получены ценные результаты исследования крови.',
                    // tslint:disable-next-line:max-line-length
                    indication: 'Анализ на свертываемость является одним из самых простых и доступных методов исследования. Пациенту нужно отказываться от приема пищи в течение двух часов перед исследованием. Результаты готовы, как правило, через несколько часов. Такое тестирование проводится в рамках комплексного обследования организма, а также при наличии кровотечений невыясненной природы и при диагностике или контроле эффективности лечения патологий костного мозга. Референсные значения зависят от возраста, а в детстве — еще и от пола.',
                    contraindic: '<ul>При некоторых заболеваниях проведение забора тромбоцитарной массы категорически запрещено: \n' +
                      '          <li>туберкулез;</li>\n' +
                      '          <li>вирус иммунодефицита;</li>\n' +
                      '          <li>раковые патологии;</li>\n' +
                      '          <li>гепатиты;</li>\n' +
                      '          <li>нарушения в работе сердца и сосудов;</li>\n' +
                      '          <li>после проведения оперативных вмешательств;</li>\n' +
                      '          <li>беременность;</li>\n' +
                      '          <li>алкоголизм;</li>\n' +
                      '          <li>недавнее введение прививки;</li>\n' +
                      '          <li>язва желудка или двенадцатиперстной кишки;</li>\n' +
                      '          <li>татуировки;</li>\n' +
                      '          <li>обширные стоматологические поражения.</li>\n' +
                      '        </ul>',
                    sale: '20',
                    tags: [
                      {
                        id: 1,
                        name: 'Кровь',
                        desc: 'Описание тега'
                      },
                      {
                        id: 2,
                        name: 'Глюкоза',
                        desc: 'Описание тега'
                      },
                      {
                        id: 3,
                        name: 'Витамины',
                        desc: 'Описание тега'
                      }
                    ],
                    catalog:
                      {
                        id: 1,
                        name: 'Анализы крови',
                        desc: 'Описание Клинический анализ крови'
                      }
                  },
                  {
                    id: 7,
                    name: 'Анализ крови артерий и вен',
                    display_name: 'Анализ крови артерий и вен',
                    price: '1320',
                    // tslint:disable-next-line:max-line-length
                    anons: 'Ультразвуковую диагностику смело можно назвать уникальным методом исследования. Она обладает такими свойствами как неинвазивность, возможность многократного проведения исследования без вреда для здоровья пациента, быстрота получения информации, отсутствие противопоказаний, исключение лучевой нагрузки.',
                    // tslint:disable-next-line:max-line-length
                    body: 'Проснувшись однажды утром после беспокойного сна, Грегор Замза обнаружил.',
                    // tslint:disable-next-line:max-line-length
                    memo: 'Вы должны подготовить себя к исследованию следующим образом: воздержаться от физических нагрузок, приема алкоголя и лекарств, изменений в питании в течение 24 часов до взятия крови. Вам не следует принимать пищу после ужина, лечь спать нужно в обычное для Вас время и встать не позднее, чем за час до взятия крови. Утром до сдачи анализа воздержитесь от курения и приема пищи. Взятие крови осуществляется до проведения диагностических или лечебных процедур: инъекций, общего массажа тела, эндоскопий, ЭКГ, рентгеновского обследования и т.д. Если Вы испытываете трудности с отменой лекарств, то обязательно сообщите об этом лечащему врачу. Очень важно, чтобы Вы точно соблюдали указанные рекомендации, так как только в этом случае будут получены ценные результаты исследования крови.',
                    // tslint:disable-next-line:max-line-length
                    indication: 'Анализ на свертываемость является одним из самых простых и доступных методов исследования. Пациенту нужно отказываться от приема пищи в течение двух часов перед исследованием. Результаты готовы, как правило, через несколько часов. Такое тестирование проводится в рамках комплексного обследования организма, а также при наличии кровотечений невыясненной природы и при диагностике или контроле эффективности лечения патологий костного мозга. Референсные значения зависят от возраста, а в детстве — еще и от пола.',
                    contraindic: '<ul>При некоторых заболеваниях проведение забора тромбоцитарной массы категорически запрещено: \n' +
                      '          <li>туберкулез;</li>\n' +
                      '          <li>вирус иммунодефицита;</li>\n' +
                      '          <li>раковые патологии;</li>\n' +
                      '          <li>гепатиты;</li>\n' +
                      '          <li>нарушения в работе сердца и сосудов;</li>\n' +
                      '          <li>после проведения оперативных вмешательств;</li>\n' +
                      '          <li>беременность;</li>\n' +
                      '          <li>алкоголизм;</li>\n' +
                      '          <li>недавнее введение прививки;</li>\n' +
                      '          <li>язва желудка или двенадцатиперстной кишки;</li>\n' +
                      '          <li>татуировки;</li>\n' +
                      '          <li>обширные стоматологические поражения.</li>\n' +
                      '        </ul>',
                    sale: '',
                    documents: [
                      {
                        id: 1,
                        title: 'Паспорт',
                        // tslint:disable-next-line:max-line-length
                        desc: 'Проснувшись однажды утром после беспокойного сна, Грегор Замза обнаружил, что он у себя в постели превратился в страшное насекомое.',
                        main_photo:
                          {
                            id: 1,
                            url: '../../assets/img/doc-img-1.png',
                          }
                      }
                    ],
                    tags: [
                      {
                        id: 1,
                        name: 'Кровь',
                        desc: 'Описание тега'
                      },
                      {
                        id: 2,
                        name: 'Глюкоза',
                        desc: 'Описание тега'
                      },
                      {
                        id: 3,
                        name: 'Гормоны',
                        desc: 'Описание тега'
                      }
                    ],
                    catalog:
                      {
                        id: 1,
                        name: 'Анализы крови',
                        desc: 'Описание Клинический анализ крови'
                      }
                  }
                ],
              }
            ],
            directions: [
              {
                id: 60,
                name: 'root',
                short_description: null,
                description: null,
                icon: null
              }
            ],
          },
        ],
        services: [
          {
            id: 1,
            name: 'УЗИ печеночного трансплантанта с дуплексным сканированием артерий и вен',
            display_name: 'УЗИ печеночного трансплантанта с дуплексным сканированием артерий и вен',
            price: '1320',
            // tslint:disable-next-line:max-line-length
            anons: 'Ультразвуковую диагностику смело можно назвать уникальным методом исследования. Она обладает такими свойствами как неинвазивность, возможность многократного проведения исследования без вреда для здоровья пациента, быстрота получения информации, отсутствие противопоказаний, исключение лучевой нагрузки.',
            // tslint:disable-next-line:max-line-length
            body: 'Проснувшись однажды утром после беспокойного сна, Грегор Замза обнаружил, что он у себя в постели превратился в страшное насекомое.',
            // tslint:disable-next-line:max-line-length
            memo: 'Вы должны подготовить себя к исследованию следующим образом: воздержаться от физических нагрузок, приема алкоголя и лекарств, изменений в питании в течение 24 часов до взятия крови. Вам не следует принимать пищу после ужина, лечь спать нужно в обычное для Вас время и встать не позднее, чем за час до взятия крови. Утром до сдачи анализа воздержитесь от курения и приема пищи. Взятие крови осуществляется до проведения диагностических или лечебных процедур: инъекций, общего массажа тела, эндоскопий, ЭКГ, рентгеновского обследования и т.д. Если Вы испытываете трудности с отменой лекарств, то обязательно сообщите об этом лечащему врачу. Очень важно, чтобы Вы точно соблюдали указанные рекомендации, так как только в этом случае будут получены ценные результаты исследования крови.',
            // tslint:disable-next-line:max-line-length
            indication: 'Анализ на свертываемость является одним из самых простых и доступных методов исследования. Пациенту нужно отказываться от приема пищи в течение двух часов перед исследованием. Результаты готовы, как правило, через несколько часов. Такое тестирование проводится в рамках комплексного обследования организма, а также при наличии кровотечений невыясненной природы и при диагностике или контроле эффективности лечения патологий костного мозга. Референсные значения зависят от возраста, а в детстве — еще и от пола.',
            contraindic: '<ul>При некоторых заболеваниях проведение забора тромбоцитарной массы категорически запрещено: \n' +
              '          <li>туберкулез;</li>\n' +
              '          <li>вирус иммунодефицита;</li>\n' +
              '          <li>раковые патологии;</li>\n' +
              '          <li>гепатиты;</li>\n' +
              '          <li>нарушения в работе сердца и сосудов;</li>\n' +
              '          <li>после проведения оперативных вмешательств;</li>\n' +
              '          <li>беременность;</li>\n' +
              '          <li>алкоголизм;</li>\n' +
              '          <li>недавнее введение прививки;</li>\n' +
              '          <li>язва желудка или двенадцатиперстной кишки;</li>\n' +
              '          <li>татуировки;</li>\n' +
              '          <li>обширные стоматологические поражения.</li>\n' +
              '        </ul>',
            sale: '',
            doctor: [ // TODO могут ли одну услугу оказывать несколько врачей?
              {
                id: 2,
                lastName: 'Морозова',
                firstName: 'Екатерина',
                middleName: 'Владимировна',
                specialization: 'ЛОР',
                main_photo: {
                  id: 11,
                  url: '../../assets/img/doctors/doc-5.png'
                }
              }
            ],
            documents: [
              {
                id: 1,
                title: 'Паспорт',
                // tslint:disable-next-line:max-line-length
                desc: 'Проснувшись однажды утром после беспокойного сна, Грегор Замза обнаружил, что он у себя в постели превратился в страшное насекомое.',
                main_photo:
                  {
                    id: 1,
                    url: '../../assets/img/doc-img-1.png',
                  }
              },
              {
                id: 2,
                title: 'Медицинский полис',
                // tslint:disable-next-line:max-line-length
                desc: 'Проснувшись однажды утром после беспокойного сна, Грегор Замза обнаружил, что он у себя в постели превратился в страшное насекомое.',
                main_photo:
                  {
                    id: 1,
                    url: '../../assets/img/doc-img-2.png',
                  }
              },
              {
                id: 3,
                title: 'Направление доктора',
                // tslint:disable-next-line:max-line-length
                desc: 'Проснувшись однажды утром после беспокойного сна, Грегор Замза обнаружил, что он у себя в постели превратился в страшное насекомое.',
                main_photo:
                  {
                    id: 1,
                    url: '../../assets/img/doc-img-3.png',
                  }
              }
            ],
            tags: [
              {
                id: 1,
                name: 'Кровь',
                desc: 'Описание тега'
              },
              {
                id: 2,
                name: 'Глюкоза',
                desc: 'Описание тега'
              },
              {
                id: 3,
                name: 'Витамины',
                desc: 'Описание тега'
              }
            ],
            slider: [
              {
                id: 1,
                // tslint:disable-next-line:max-line-length
                title: 'Ощущения пациента во время проведения ультразвуковой эластографии такие же, как и при обычном УЗИ. Ни боли, ни дискомфорта пациенты не испытывают.',
                // tslint:disable-next-line:max-line-length
                desc: 'Ощущения пациента во время проведения ультразвуковой эластографии такие же, как и при обычном УЗИ. Ни боли, ни дискомфорта пациенты не испытывают.',
                main_photo: {
                  url: '../../assets/img/big-slide-3.png'
                },
              },
              {
                id: 2,
                // tslint:disable-next-line:max-line-length
                title: 'Ощущения пациента во время проведения ',
                // tslint:disable-next-line:max-line-length
                desc: 'Ощущения пациента во время проведения ультразвуковой эластографии такие же, как и при обычном УЗИ. Ни боли, ни дискомфорта пациенты не испытывают.',
                main_photo: {
                  url: '../../assets/img/big-slide-4.png'
                }
              },
              {
                id: 3,
                // tslint:disable-next-line:max-line-length
                title: 'Ощущения пациента во время проведения ультразвуковой эластографии такие же, как и при обычном УЗИ. Ни боли, ни дискомфорта пациенты не испытывают.',
                // tslint:disable-next-line:max-line-length
                desc: 'Ощущения пациента во время проведения ультразвуковой эластографии такие же, как и при обычном УЗИ. Ни боли, ни дискомфорта пациенты не испытывают.',
                main_photo: {
                  url: '../../assets/img/big-slide-3.png'
                },
              },
              {
                id: 4,
                // tslint:disable-next-line:max-line-length
                title: 'Ощущения пациента во время проведения ',
                // tslint:disable-next-line:max-line-length
                desc: 'Ощущения пациента во время проведения ультразвуковой эластографии такие же, как и при обычном УЗИ. Ни боли, ни дискомфорта пациенты не испытывают.',
                main_photo: {
                  url: '../../assets/img/big-slide-4.png'
                }
              }
            ],
            catalog:
              {
                id: 1,
                name: 'Клинический анализ крови',
                desc: 'Описание Клинический анализ крови'
              }
          },
          {
            id: 2,
            name: 'Вторая услуга и она со скидкой',
            display_name: 'Вторая услуга и она со скидкой. УЗИ печеночного трансплантанта с дуплексным сканированием артерий и вен',
            price: '2502',
            // tslint:disable-next-line:max-line-length
            anons: 'Ультразвуковую диагностику смело можно назвать уникальным методом исследования. Она обладает такими свойствами как неинвазивность, возможность многократного проведения исследования без вреда для здоровья пациента, быстрота получения информации, отсутствие противопоказаний, исключение лучевой нагрузки.',
            // tslint:disable-next-line:max-line-length
            body: 'Проснувшись однажды утром после беспокойного сна, Грегор Замза обнаружил, что он у себя в постели превратился в страшное насекомое.',
            // tslint:disable-next-line:max-line-length
            memo: 'Вы должны подготовить себя к исследованию следующим образом: воздержаться от физических нагрузок, приема алкоголя и лекарств, изменений в питании в течение 24 часов до взятия крови. Вам не следует принимать пищу после ужина, лечь спать нужно в обычное для Вас время и встать не позднее, чем за час до взятия крови. Утром до сдачи анализа воздержитесь от курения и приема пищи. Взятие крови осуществляется до проведения диагностических или лечебных процедур: инъекций, общего массажа тела, эндоскопий, ЭКГ, рентгеновского обследования и т.д. Если Вы испытываете трудности с отменой лекарств, то обязательно сообщите об этом лечащему врачу. Очень важно, чтобы Вы точно соблюдали указанные рекомендации, так как только в этом случае будут получены ценные результаты исследования крови.',
            // tslint:disable-next-line:max-line-length
            indication: 'Анализ на свертываемость является одним из самых простых и доступных методов исследования. Пациенту нужно отказываться от приема пищи в течение двух часов перед исследованием. Результаты готовы, как правило, через несколько часов. Такое тестирование проводится в рамках комплексного обследования организма, а также при наличии кровотечений невыясненной природы и при диагностике или контроле эффективности лечения патологий костного мозга. Референсные значения зависят от возраста, а в детстве — еще и от пола.',
            contraindic: '<ul>При некоторых заболеваниях проведение забора тромбоцитарной массы категорически запрещено: \n' +
              '          <li>туберкулез;</li>\n' +
              '          <li>вирус иммунодефицита;</li>\n' +
              '          <li>раковые патологии;</li>\n' +
              '          <li>гепатиты;</li>\n' +
              '          <li>нарушения в работе сердца и сосудов;</li>\n' +
              '          <li>после проведения оперативных вмешательств;</li>\n' +
              '          <li>беременность;</li>\n' +
              '          <li>алкоголизм;</li>\n' +
              '          <li>недавнее введение прививки;</li>\n' +
              '          <li>язва желудка или двенадцатиперстной кишки;</li>\n' +
              '          <li>татуировки;</li>\n' +
              '          <li>обширные стоматологические поражения.</li>\n' +
              '        </ul>',
            sale: '13',
            doctor: [ // TODO могут ли одну услугу оказывать несколько врачей?
              {
                id: 2,
                lastName: 'Морозова',
                firstName: 'Екатерина',
                middleName: 'Владимировна',
                specialization: 'ЛОР',
                main_photo: {
                  id: 11,
                  url: '../../assets/img/doctors/doc-5.png'
                }
              }
            ],
            documents: [
              {
                id: 1,
                title: 'Паспорт',
                // tslint:disable-next-line:max-line-length
                desc: 'Проснувшись однажды утром после беспокойного сна, Грегор Замза обнаружил, что он у себя в постели превратился в страшное насекомое.',
                main_photo:
                  {
                    id: 1,
                    url: '../../assets/img/doc-img-1.png',
                  }
              },
              {
                id: 2,
                title: 'Медицинский полис',
                // tslint:disable-next-line:max-line-length
                desc: 'Проснувшись однажды утром после беспокойного сна, Грегор Замза обнаружил, что он у себя в постели превратился в страшное насекомое.',
                main_photo:
                  {
                    id: 1,
                    url: '../../assets/img/doc-img-2.png',
                  }
              },
              {
                id: 3,
                title: 'Направление доктора',
                // tslint:disable-next-line:max-line-length
                desc: 'Проснувшись однажды утром после беспокойного сна, Грегор Замза обнаружил, что он у себя в постели превратился в страшное насекомое.',
                main_photo:
                  {
                    id: 1,
                    url: '../../assets/img/doc-img-3.png',
                  }
              }
            ],
            tags: [
              {
                id: 1,
                name: 'Кровь',
                desc: 'Описание тега'
              },
              {
                id: 2,
                name: 'Глюкоза',
                desc: 'Описание тега'
              },
              {
                id: 3,
                name: 'Витамины',
                desc: 'Описание тега'
              }
            ],
            slider: [
              {
                id: 1,
                // tslint:disable-next-line:max-line-length
                title: 'Ощущения пациента во время проведения ультразвуковой эластографии такие же, как и при обычном УЗИ. Ни боли, ни дискомфорта пациенты не испытывают.',
                // tslint:disable-next-line:max-line-length
                desc: 'Ощущения пациента во время проведения ультразвуковой эластографии такие же, как и при обычном УЗИ. Ни боли, ни дискомфорта пациенты не испытывают.',
                main_photo: {
                  url: '../../assets/img/big-slide-3.png'
                },
              },
              {
                id: 2,
                // tslint:disable-next-line:max-line-length
                title: 'Ощущения пациента во время проведения ',
                // tslint:disable-next-line:max-line-length
                desc: 'Ощущения пациента во время проведения ультразвуковой эластографии такие же, как и при обычном УЗИ. Ни боли, ни дискомфорта пациенты не испытывают.',
                main_photo: {
                  url: '../../assets/img/big-slide-4.png'
                }
              },
              {
                id: 3,
                // tslint:disable-next-line:max-line-length
                title: 'Ощущения пациента во время проведения ультразвуковой эластографии такие же, как и при обычном УЗИ. Ни боли, ни дискомфорта пациенты не испытывают.',
                // tslint:disable-next-line:max-line-length
                desc: 'Ощущения пациента во время проведения ультразвуковой эластографии такие же, как и при обычном УЗИ. Ни боли, ни дискомфорта пациенты не испытывают.',
                main_photo: {
                  url: '../../assets/img/big-slide-3.png'
                },
              },
              {
                id: 4,
                // tslint:disable-next-line:max-line-length
                title: 'Ощущения пациента во время проведения ',
                // tslint:disable-next-line:max-line-length
                desc: 'Ощущения пациента во время проведения ультразвуковой эластографии такие же, как и при обычном УЗИ. Ни боли, ни дискомфорта пациенты не испытывают.',
                main_photo: {
                  url: '../../assets/img/big-slide-4.png'
                }
              }
            ],
            catalog:
              {
                id: 1,
                name: 'Клинический анализ крови',
                desc: 'Описание Клинический анализ крови'
              }
          },

        ],
        tags: {
          id: 3,
          name: 'Сердце',
        },
      },
    ],
  };
}
