import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';

Injectable();

// инструкция по которой делал пагинацию http://michaelbromley.github.io/ngx-pagination/#/
// для пагинации

/**
 * Набор свойств для применения как значения по умолчанию.
 * Используется в случаях отсутствия значений у полей моделей
 * @date 10.04.2023 - 11:46:51
 *
 * @export
 * @class Globals
 * @typedef {Globals}
 */

export class Globals {
  // title сайта
  public globalTitle = 'Официальный сайт ФСНКЦ ФМБА России';
  // переменные для блока "Поделиться"
  public includeSocial: string[] = ['vk', 'telegram', 'viber', 'email', 'print', 'copy'];
  public showSocial = 5;
  public autoSetMeta = false;

  // для постраничной загрузки
  public start = 0;
  public pageSize = 16;


  public PerPage = 6; // количество элементов на страницу
  public page = 1 ; // начальная страница
  public collectionItems: any[] = [];

  public iconSetting = '../assets/img/gear.svg';
  public iconMessage = '../assets/img/messenger.svg';
  public brainImage = '../assets/img/brain2.jpg';
  public noNews = '../assets/img/news.svg';
  public noPharm = '../assets/img/pharmacy.svg';
  public noUnit = '../assets/img/hospital.svg';
  public noPeople = '../assets/img/no-people.svg';
  public noImage = '../assets/img/noimage.svg';
  public noUpload = '../assets/img/upload.svg';
  public noUploadLand = '../assets/img/upload-landscape.svg';
  public addItemImg = '../assets/img/add-item.svg';
  public gerbRussia = '../assets/img/russiagerb.svg';
  public copyIcon = '../assets/img/copy-buffer.svg';
  public universityIcon = '../assets/img/university.svg';
  public dateDefault = '2079-01-01';
  public fio = 'Фамилия Имя Отчество';
  public anonse = '<p><span class="demo-color">Заменить демонстрационный текст.</span> Проснувшись однажды утром после беспокойного сна, Грегор Замза обнаружил, что он у себя в постели превратился в страшное насекомое.</p>';
  public bodyText = '<p><span class="demo-color">Заменить демонстрационный текст.</span> Проснувшись однажды утром после беспокойного сна, Грегор Замза обнаружил, что он у себя в постели превратился в страшное насекомое.</p><p>Лежа на панцирнотвердой спине, он видел, стоило ему приподнять голову, свой коричневый, выпуклый, разделенный дугообразными чешуйками живот, на верхушке которого еле держалось готовое вот-вот окончательно сползти одеяло.</p><p>Его многочисленные, убого тонкие по сравнению с остальным телом ножки беспомощно копошились у него перед глазами. «Что со мной случилось? » – подумал он. Это не было сном.</p><p>Его комната, настоящая, разве что слишком маленькая, но обычная комната, мирно покоилась в своих четырех хорошо знакомых стенах.</p><p>Над столом, где были разложены распакованные образцы сукон – Замза был коммивояжером, – висел портрет, который он недавно вырезал из иллюстрированного журнала и вставил в красивую золоченую рамку.</p>';
  public mission = '<p><span class="demo-color">Заменить демонстрационный текст.</span> Здесь суть миссии.</p>';
}
