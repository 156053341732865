import {Component, Input, OnInit} from '@angular/core';
import {AdminService} from "../../../services/admin.service";
import {DataService} from "../../../services/data.service";
import {AdmitemslistmenuComponent} from "../../admin/admitemslistmenu/admitemslistmenu.component";
import {CommonModule} from "@angular/common";
import {NgbPopoverModule} from "@ng-bootstrap/ng-bootstrap";
import {RouterModule} from "@angular/router";

@Component({
  standalone: true,
  selector: 'app-item-navsidebar',
  templateUrl: './item-navsidebar.component.html',
  styleUrls: ['./item-navsidebar.component.css'],
  imports: [
    AdmitemslistmenuComponent,
    CommonModule,
    NgbPopoverModule,
    RouterModule
  ]
})

export class ItemNavsidebarComponent implements OnInit {
  @Input() model: any;
  @Input() state: string;

  modelname: string;
  mode: string;
  public borderStyle: any;

  constructor(public admin: AdminService, public dataService: DataService) {
    this.modelname = ''; // Название текущей модели этого элемента
    this.mode = '';
    this.borderStyle = {};
  }


  ngOnInit() {
    if (this.state) {
      const tmp = this.state.split(':');
      this.mode = tmp[5] || '';
      this.modelname = this.admin.schema[tmp[0]].links[tmp[2]].modelname || alert('Ошибка 1325! Обратитесь к разработчику.');
      this.model = (this.mode === 'admin') ? this.admin.fillAdminModel(this.model, this.modelname) : this.model;
      console.log('= this.model item-navsidebar =', this.model);
    }
  }

  bordermark = (p, state: string | boolean) => {
    this.borderStyle = {};
    if (state) {
      this.borderStyle['' + state] = 'border: solid 1px #7d7e92;';
      this.admin.popoverOpen(p, '' + state);
    }
  }
}

