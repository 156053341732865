import {Component, Input} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AdmitemslistmenuComponent} from '../../admin/admitemslistmenu/admitemslistmenu.component';
import {NgbPopoverModule} from '@ng-bootstrap/ng-bootstrap';

@Component({
  standalone: true,
  selector: 'app-footer-data-maincontacts',
  templateUrl: './footer-data-maincontacts.component.html',
  styleUrls: ['../footer.component.css'],
  imports: [
    CommonModule,
    AdmitemslistmenuComponent,
    NgbPopoverModule,
  ]
})

export class FooterDataMaincontactsComponent{
  @Input() set struct(s: any) {
    if(s){
      this.model = Object.assign(this.model, s);
    }
  }

  model = {
    id: 0,
    phonedms: {},
    phonefax: {},
    phoneoms: {},
    phonestatic: {},
    phonetohome: {},
    shortName: '',
    address: {
      street: '',
      house: '',
      city: '',
      mailbox: '',
      postcode: '',
      region: '',
    },
    email: [],
    cities: [],
    languages: [],
    title: '',
    orgform: '',
    socialnets: []
  };
}
