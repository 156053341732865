<div class="container mt-md-5 mb-md-5 mt-4 print-none">
  <div class="footer-phone mb-4">
    <a href="tel: {{maincontact.phoneoms.number}}">{{maincontact.phoneoms.number}}</a>
  </div>
  <div class="row justify-content-center">
    <div class="col-auto">
<!--      <app-formfeedback></app-formfeedback>-->
      <input type="submit" class="btn btn-outline-secondary" value="Оставить отзыв" (click)="fbopen()">
    </div>
    <div class="col-auto">
      <input type="submit" class="btn btn-secondary" value="Записаться на прием" (click)="apointment()">
<!--      <a class="btn btn-secondary" [routerLink]="['/apointment']">Записаться на прием</a>-->
    </div>
    <div class="col-auto">
<!--      <a class="btn btn-primary d-none d-md-inline-block" [routerLink]="['/faq']">Вопросы и ответы</a>-->
<!--      <a class="btn btn-primary" href='https://lk.skc-fmba.ru' target="_blank">-->
      <a class="btn btn-primary" [href]="model.lkurl" target="_blank">
        Личный кабинет
      </a>
    </div>
  </div>
</div>
