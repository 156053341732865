<div class="row adm-toppanel">
    <div [ngClass]="{'btn-adm-top-panel_selected': (selectedAdmArea =='exit')}" id="adm-bttnPan_exit" class="btn-group btn-adm-top-panel" role="group">
        <button id="adm-bttn_exit" type="button" class="btn btn-sm" (click)="toPublicMode()">
            Выйти
        </button>
    </div>
    <div [ngClass]="{'btn-adm-top-panel_selected': (selectedAdmArea =='upload')}" id="adm-bttnPan_upload" class="btn-group btn-adm-top-panel" role="group">
        <button id="adm-bttn_upload" type="button" class="btn btn-sm" (click)="imageUpload()">
            Upload
        </button>
    </div>
    <div [ngClass]="{'btn-adm-top-panel_selected': (selectedAdmArea =='integration')}" id="adm-bttnPan_integration" class="btn-group btn-adm-top-panel" role="group">
        <button id="adm-bttn_integration" type="button" class="btn btn-sm" (click)="integrity()">
            Интеграция
        </button>
    </div>
    <div [ngClass]="{'btn-adm-top-panel_selected': (selectedAdmArea =='content')}" id="adm-bttnPan_content" class="btn-group btn-adm-top-panel" role="group">
        <button id="adm-bttn_content" type="button" class="btn btn-sm" (click)="content()">
            Контент
        </button>
    </div>
</div>
  