<div class="big-slide_left_gradient"></div>
<div class="big-slide_right_gradient"></div>
<div *ngIf="model" class="big-slider" >
  <ng-container *ngFor="let item of model.slideritems">
    <!--      слайдер с Новостями -->
<!--    <div class="big-slide _news" *ngIf="item.articles && item.articles != null && item.articles.news == true">-->
    <div class="big-slide _news" *ngIf="item.type.typename === 'allnews'">
      <a class="big-slide__title" [routerLink]="['/news', item.data.id]">{{item.data.slidertitle | slice: 0:93}}
        <span class="ellipsis" *ngIf="(item.data.slidertitle || '').length >= 94">...</span>
      </a>
      <div class="big-slide__text">
        {{item.data.publish_start | date: 'd MMMM y г.' : '0' : 'ru_RU'}}
      </div>
      <div class="big-slide__img">
<!--        TODO разобраться с этим бредом ниже -->
        <img class="big-slide_news_img"
             src="{{(item.data.sliderimage && item.data.sliderimage.trim() !== '')
             ?(((item.data.sliderimage[0] === '/') ? dataService.srvurl : '') + item.data.sliderimage)
             :((!item.data.mainimage || item.data.mainimage.trim() === '')
                ? ('../../' + globals.noImage)
                : (((item.data.mainimage[0] === '/') ? dataService.srvurl : '') + item.data.mainimage))}}"
             alt="">
      </div>
<!--      <div class="big-slide__img-blur">-->
<!--        <img class="big-slide_news_img"-->
<!--             src="{{(!item.data.mainimage || item.data.mainimage.trim() === '')-->
<!--                      ? ('../../' + globals.noImage)-->
<!--                      : (((item.data.mainimage[0] === '/') ? dataService.srvurl : '') + item.data.mainimage)}}"-->
<!--             alt="">-->
<!--      </div>-->
    </div>
    <!--      слайдер с Галереей -->
    <div class="big-slide _news" *ngIf="item.type.typename === 'galeries'">
      <a class="big-slide__title" [routerLink]="['/galleries', item.data.id]">{{item.data.title}}</a>
<!--      <div class="big-slide__text">-->
<!--        {{item.data.created_at | date: 'd MMMM y' : '0' : 'ru_RU'}}-->
<!--      </div>-->
      <div class="big-slide__img">
        <img class="big-slide_news_img"
             src="{{(!item.data.mainimage || item.data.mainimage.trim() === '')
                      ? ('../../' + globals.noImage)
                      : (((item.data.mainimage[0] === '/') ? dataService.srvurl : '') + item.data.mainimage)}}"
             alt=""/>
      </div>
<!--      <div class="big-slide__img-blur">-->
<!--        <img class="big-slide_news_img"-->
<!--             src="{{(!item.data.mainimage || item.data.mainimage.trim() === '')-->
<!--                      ? ('../../' + globals.noImage)-->
<!--                      : (((item.data.mainimage[0] === '/') ? dataService.srvurl : '') + item.data.mainimage)}}"-->
<!--             alt=""/>-->
<!--      </div>-->
    </div>
    <!--    слайдер с доктором -->
    <div class="big-slide _doctor" *ngIf="item.type.typename === 'doctors'">
      <div class="big-slide__doctor">
        <img class="big-slide_doctor_img"
             src="{{(item.data.mainimage && item.data.mainimage[0] == '/')? (dataService.srvurl + item.data.mainimage): (item.data.mainimage && item.data.mainimage != '')? (item.data.mainimage): ('../../' + globals.noPeople)}}"
             alt="">
      </div>
      <!--        TODO вставить ссылку на форму записи к Врачу -->
      <button class="btn btn-outline-primary"
              [routerLink]="['/apointment']"
              [queryParams]="{'doctors': item.data.id}">Записаться к врачу</button>
      <a class="big-slide__title" [routerLink]="['/doctors', item.data.id]">
        {{item.data.title}}
      </a>
      <div class="big-slide__text textTailor">
        <span *ngIf="item.data.Degree && item.data.Degree.length">
          {{item.data.Degree}},
        </span>
        <span *ngIf="item.data.specializations">
          {{item.data.specializations.join(', ')}}
        </span>
<!--        TODO сформировать специализации -->
<!--        {{(item.data.specialization && item.data.specialization != '')? (item.data.specialization): ('')}}-->
<!--        <span *ngIf="item.data.Degree!='' && item.data.Degree"><br>{{item.data.Degree}}</span>-->
      </div>
    </div>
    <!--    слайдер со Статьей -->
    <div class="big-slide _text" *ngIf="item.type.typename === 'articles'">
      <a class="big-slide__title" [routerLink]="['/articles', item.data.id]">{{item.data.title}}</a>
      <div class="big-slide__text textTailor" [innerHTML]="item.data.anonce"></div>
    </div>


    <!--      слайдер со Страницей -->
    <div class="big-slide {{(item.data.sliderimage && item.data.sliderimage.trim().length)? '_news' : '_text'}}" *ngIf="item.type.typename === 'pages'">
      <a class="big-slide__title" [routerLink]="['/pages', item.data.id]">{{item.data.slidertitle || ''}}</a>
      <div *ngIf="!item.data.sliderimage || !item.data.sliderimage.trim().length" class="big-slide__text textTailor" [innerHTML]="item.data.body"></div>
      <div *ngIf="item.data.sliderimage && item.data.sliderimage.trim().length" class="big-slide__img">
        <img class="big-slide_news_img"
             src="{{((item.data.sliderimage[0] === '/') ? dataService.srvurl : '') + item.data.sliderimage}}"
             alt=""/>
      </div>
<!--      <div *ngIf="item.data.sliderimage" class="big-slide__img-blur">-->
<!--        <img class="big-slide_news_img"-->
<!--             src="{{(!item.data.sliderimage || item.data.sliderimage.trim() === '')-->
<!--                      ? ('../../' + globals.noImage)-->
<!--                      : (((item.data.sliderimage[0] === '/') ? dataService.srvurl : '') + item.data.sliderimage)}}"-->
<!--             alt=""/>-->
<!--      </div>-->
    </div>
    <!--      слайдер с Центров (centers) -->
    <div class="big-slide _news" *ngIf="item.type.typename === 'centers'">
      <a class="big-slide__title" [routerLink]="['/centers', item.data.id]">{{item.data.title}}</a>
      <div class="big-slide__img">
        <img class="big-slide_news_img"
             src="{{(!item.data.sliderimage || item.data.sliderimage.trim() === '')
                      ? ('../../' + globals.noImage)
                      : (((item.data.sliderimage[0] === '/') ? dataService.srvurl : '') + item.data.sliderimage)}}"
             alt=""/>
      </div>
<!--      <div class="big-slide__img-blur">-->
<!--        <img class="big-slide_news_img"-->
<!--             src="{{(!item.data.sliderimage || item.data.sliderimage.trim() === '')-->
<!--                      ? ('../../' + globals.noImage)-->
<!--                      : (((item.data.sliderimage[0] === '/') ? dataService.srvurl : '') + item.data.sliderimage)}}"-->
<!--             alt=""/>-->
<!--      </div>-->
    </div>
    <!--      слайдер с Поликлиникой (clinics) -->
    <div class="big-slide _news" *ngIf="item.type.typename === 'clinics'">
      <a class="big-slide__title" [routerLink]="['/clinic', item.data.id]">{{item.data.title}}</a>
      <div class="big-slide__img">
        <img class="big-slide_news_img"
             src="{{(!item.data.sliderimage || item.data.sliderimage.trim() === '')
                      ? ('../../' + globals.noImage)
                      : (((item.data.sliderimage[0] === '/') ? dataService.srvurl : '') + item.data.sliderimage)}}"
             alt=""/>
      </div>
<!--      <div class="big-slide__img-blur">-->
<!--        <img class="big-slide_news_img"-->
<!--             src="{{(!item.data.sliderimage || item.data.sliderimage.trim() === '')-->
<!--                      ? ('../../' + globals.noImage)-->
<!--                      : (((item.data.sliderimage[0] === '/') ? dataService.srvurl : '') + item.data.sliderimage)}}"-->
<!--             alt=""/>-->
<!--      </div>-->
    </div>
    <!--      слайдер с Веб-ресурсом -->
    <div class="big-slide _news" *ngIf="item.type.typename === 'externals'">
      <a class="big-slide__title" target="_blank" href="{{((item.data.url[0] === '/') ? dataService.siteurl : '') + item.data.url}}">{{item.data.title}}</a>
      <div class="big-slide__img">
        <img class="big-slide_news_img"
             src="{{(!item.data.sliderimage || item.data.sliderimage.trim() === '')
                      ? ('../../' + globals.noImage)
                      : (((item.data.sliderimage[0] === '/') ? dataService.srvurl : '') + item.data.sliderimage)}}"
             alt=""/>
      </div>
<!--      <div class="big-slide__img-blur">-->
<!--        <img class="big-slide_news_img"-->
<!--             src="{{(!item.data.sliderimage || item.data.sliderimage.trim() === '')-->
<!--                      ? ('../../' + globals.noImage)-->
<!--                      : (((item.data.sliderimage[0] === '/') ? dataService.srvurl : '') + item.data.sliderimage)}}"-->
<!--             alt=""/>-->
<!--      </div>-->
    </div>
    <!--      слайдер из галерееи -->
    <div class="big-slide _news" *ngIf="item.type === 'image'">
<!--      <a class="big-slide__title" [routerLink]="['/galleries', item.data.id]">{{item.data.title}}</a>-->
      <!--      <div class="big-slide__text">-->
      <!--        {{item.data.created_at | date: 'd MMMM y' : '0' : 'ru_RU'}}-->
      <!--      </div>-->
      <div class="big-gslide__img">
        <a href="{{(!item.url || item.url.trim() === '')
                      ? ('../../' + globals.noImage)
                      : (((item.url[0] === '/') ? dataService.srvurl : '') + item.url.trim())}}">
        <img class="big-slide_news_img"
             src="{{(!item.url || item.url.trim() === '')
                      ? ('../../' + globals.noImage)
                      : (((item.url[0] === '/') ? dataService.srvurl : '') + item.url.trim())}}"
             alt="{{item.title}}" title="{{item.title}}"/></a>
      </div>
      <!--      <div class="big-slide__img-blur">-->
      <!--        <img class="big-slide_news_img"-->
      <!--             src="{{(!item.data.mainimage || item.data.mainimage.trim() === '')-->
      <!--                      ? ('../../' + globals.noImage)-->
      <!--                      : (((item.data.mainimage[0] === '/') ? dataService.srvurl : '') + item.data.mainimage)}}"-->
      <!--             alt=""/>-->
      <!--      </div>-->
    </div>
  </ng-container>
</div>
<div class="slick-slider-nav big-slider-nav"></div>
