import {Component, EventEmitter, NgModule, OnInit, Output} from '@angular/core';
import {DataService} from '../../../services/data.service';
import {CommonModule} from '@angular/common';

@Component({
  standalone: true,
  selector: 'app-fileupload',
  templateUrl: './adm-fileupload.component.html',
  styleUrls: ['./adm-fileupload.component.css'],
  imports: [
    CommonModule
  ]
})
export class AdmFileuploadComponent implements OnInit {
  @Output() uploadedData = new EventEmitter<string | File>();
  fileToUpload: File = null;
  url;

  constructor(private dataService: DataService) { }

  ngOnInit(): void {
  }

  handleFileInput(files: FileList) {
    this.fileToUpload = files.item(0);
    console.log(files);
    console.log(this.fileToUpload);
    // this.upload();
    this.uploadedData.emit(this.fileToUpload);
  }

  upload = () => {
    this.dataService.uploadFile(this.fileToUpload)
      .then((data: any) => {
        console.log('data.url', data.url);
        this.url = this.dataService.srvurl + data.url;
        navigator.clipboard.writeText(this.url);
      })
      .catch(err => console.error(err));
  }
}
// @NgModule({
//   imports: [
//     CommonModule,
//   ],
//   declarations: [
//     AdmFileuploadComponent
//   ],
//   exports: [
//     AdmFileuploadComponent
//   ]
// })
// export class AdmFileuploadComponentModule {}
