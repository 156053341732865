import { JQ } from './models/jq';
import { DataService } from './services/data.service';
import {Component} from '@angular/core';
import { Subscription } from 'rxjs';
import { NavigationEnd, Router} from '@angular/router';
import { Title, Meta } from '@angular/platform-browser';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { environment } from '../environments/environment';
import { SelectCityModalComponent } from './components/modals/select-city-modal/select-city-modal.component'

import qs from 'qs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent {
  private subs: Subscription;
  admStyle: string = 'old'; //флаг переключателя между старой/новой админками
  hfVisible; // флаг отображения шапки и подвала
  route;
  title;
  activated;
  mode: string;
  jivo: boolean;
  // main = false;
  admPanel =  false;

  modalAsk = false;

  ymID = {
    "86937": 22525015,
    "87827": 22525021
  };

  city = 1;
  _header: any = {};
  _footer: any = {};
  _navsidebar: any = {};
  hidden: any;
  name: any;
  items: any = {};

  constructor(
    private jq: JQ,
    private router: Router,
    private dataService: DataService,
    private titleService: Title,
    private meta: Meta,
    private modalService: NgbModal,
    private spinner: NgxSpinnerService,
  ) {
    this.subs = new Subscription();
    jq.textTailor();
    jq.ie11();
    
    console.log(document.scripts)
    
    this.subs.add(router.events.subscribe(async (event) => {
      // console.log('= Update Route =');
        // console.log('= Route EVENT =', event);
        if (event instanceof NavigationEnd) {

          console.log('SET HFVISIBLE true by NavigationEnd', event);

          this.dataService.hfVision = true;

          this.dataService.jivo = true;

          if (event && event.url) {
            const p = qs.parse(event.url.split('?')[1]);
            console.log('urlquery', p);
            if (p && p.setcity) {
              localStorage['city'] = 'true';
            }
          }

          if (this.dataService.struct){
            this.dataService.seo = this.dataService.struct.title;
          }

          if (event.url === '/' ){
            if (this.dataService.struct){
              this.dataService.setRoute(this.dataService.struct.startpage, {});
            }else{
              // Этот вариант отработает лишь при первом запуске приложения с корневой страницы
              this.dataService.struct = environment.development ? 'newsite.skc-fmba.ru' : location.hostname;
            }
          }

          if (!this.mobile) {
            if (event.url.includes('/adm')) {
              this.admPanel = true;
              // document.getElementById('blockContent').classList.remove('container');
              // document.getElementById('blockContent').classList.add('adm');
              // document.getElementById('blockSidebar').classList.remove('block-sidebar');
              console.log('= this.admPanel =', this.admPanel);
            } else {
              this.admPanel = false;
              // document.getElementById('blockContent').classList.add('container');
              // document.getElementById('blockContent').classList.remove('adm');
              // document.getElementById('blockSidebar').classList.add('block-sidebar');
              console.log('= this.admPanel =', this.admPanel);
            }
          }


          console.log('location', location);

          this.route = event.url.split('?')[0];

// TODO сформировать контекст корректно. Данный вариант ломается на более глубоком уровне роутинга, например units/doctors/23
//           this.dataService.context = {
//             modelname: event.snapshot.url[0],
//             id: +event.snapshot.url[1]
//           };
          const ct = this.route.split('/');

          this.dataService.context = {
            modelname: ct[ct.length - 2],
            id: +ct[ct.length - 1]
          };

          // TODO добиться работы статуса видимости исключительно на подписках.
          // проверяем режим mode в параметрах и устанавливаем его напрямую через сервис
          // this.hfVisible = !~this.route.indexOf('adm/');
          // this.hfVisible = !(~this.route.indexOf('login') || ~this.route.indexOf('adm/'));
          // this.dataService.sub().hfVision = this.hfVisible;
          if (!this.dataService.hostname) {
            this.dataService.struct = environment.development ? 'newsite.skc-fmba.ru' : location.hostname;
          }

          const params = qs.parse(event.url.split('?')[1]);

          if (params && params.mode && params.mode !== 'public' && this.route !== '/login') {
            this.dataService.setRoute('login', {returnUrl: (this.route || ''), mode: params.mode});
          } 
// TODO Раскомментировать ниже , если нужен будет запрос локации Красноярск/Зеленогорск
          else if (!this.modalAsk && (!localStorage['city'] || localStorage['city'] !== 'true') && location.hostname == 'kb42.skc-fmba.ru'){
            this.modalAsk = true;
            const modalRef = this.modalService.open(SelectCityModalComponent, 
              {
                backdrop: 'static',
                keyboard: false,
                size: 's',
              });

            modalRef.result.then(async (result) => {
                console.log('Result', result, this.dataService.struct && this.dataService.struct.id);

                if (+result === this.dataService.struct.id){
                  localStorage['city'] = 'true';
                }else{
                  console.log(this.dataService.struct);
                  const way: any = await this.dataService.getData('structures', +result, {});
                  console.log('way', way)
                  localStorage['city'] = 'false';
                  if(way && way.id){
                    window.open(`https://${way.url}?setcity=${way.id}`, '_self');
                  }
                }
              this.modalAsk = false;
            }, disResult => {
              console.log('disResult', disResult);
              this.modalAsk = false;
            });
          }
        }
      })
    );
    this.dataService.sub().spinner.subscribe(s => (s ? this.spinner.show() : this.spinner.hide()));
    this.dataService.sub().struct.subscribe(async (x: any) => {
      console.log('set ! struct in app', x);

      let tmpheader = x.header;

      tmpheader.mode = this.dataService.mode;
      tmpheader.locale = this.dataService.struct.language.locale;
      tmpheader.homepage = '/' + ((this.dataService.struct && this.dataService.struct.startpage) || '');
      tmpheader.specurl = this.dataService.struct.specurl;
      tmpheader.lkurl = this.dataService.struct.lkurl;
      tmpheader.maincontact = x.main_contact;
      this.city = x.id || 1;

      if (x.navheader && x.navheader.id){
        tmpheader.navheader = await dataService.getData('menus', x.navheader.id, {});
        tmpheader.navheader2 = await dataService.getData('menus', x.navsidebar.id, {});
      }

      this._header = tmpheader;

      let tmpfooter = x.footer;
      tmpfooter.maincontact = x.main_contact;
      this._footer = tmpfooter;

      if (x.navsidebar && x.navsidebar.id){
        this._navsidebar = await dataService.getData('menus', x.navsidebar.id, {});
      }

      // TODO
      //  1. Извлечь navheader из структуры и пробросить в app-header
      //  2. Извлечь header из структуры и пробросить в app-header
      //  3. Извлечь navsidebar из структуры и пробросить в app-sidebar
      //  4. Извлечь maincontact из структуры и пробросить в app-footer
      //  5. Все релоады внутри этих компонентов осуществлять только для режима изменений.

      if (location.pathname === '/') {
        this.dataService.setRoute(((x && x.startpage) || ''), {});
      }

      // this.meta.addTag({ name: 'description', content: (x && x.description) || ''});
      // this.jq.jivo(this.dataService.struct.jivocode);
      // this.jq.my(this.ymID['' + this.dataService.struct.jivocode]);
    });

    // TODO Задача установки Title обусловлена не только реагированием на параметры seo в тех или иных моделях, но и необходимостью установки стандартного (глобального) Title для тех стучаев, когда он не указан принудительно. Вот тут нужно подумать, как это сделать грамотно.
    // this.dataService.sub().title.subscribe((t: any) => this.titleService.setTitle(t || this.dataService.struct.title));
    // this.dataService.sub().metaKeywords.subscribe((t:any) => this.meta.updateTag({name: 'keywords', content: t || '' }));
    // this.dataService.sub().metaDescription.subscribe((t:any) => this.meta.updateTag({name: 'description', content: t || '' }));

    this.dataService.sub().seo.subscribe((s:any) => {
      console.log('SEO updated!', s);
      // this.titleService.setTitle(s.title || ''); // ВОзможно глобальный Title нужно вот здесь устанавливать альтернативным и исключить прямое присвоение в прочих местах, но обепечив присвоение seo={} там, где оно отсутствует.
      const _title = (s.title && s.title !== '')? (s.title + ' | ' + this.dataService.struct.title): this.dataService.struct.title;
      this.titleService.setTitle(_title);
      this.meta.updateTag({name: 'keywords', content: s.keywords || '' });
      this.meta.updateTag({name: 'description', content: s.description || '' });
    });

    this.dataService.sub().hfVision.subscribe((hfv) => this.hfVisible = hfv);
    // this.dataService.sub().jivo.subscribe((j) => {
    //   console.log('set jivo', j);
    //   this.jivo = j;
    // });
    this.dataService.sub().mode.subscribe(m => {
      this.mode = m;
      this._header = Object.assign(this._header, {mode: this.mode});
    });

    this.dataService.mobile = this.jq.isMobile();
  }

  // ngAfterContentInit() {
  //   setTimeout(() => {
  //     this.heightBlockContent = document.getElementById('blockContentBlock').offsetHeight;
  //     console.log('%c == ngAfterContentChecked blockContent ==', 'background: red; color: white', this.heightBlockContent);
  //   }, 5000);
  //   setTimeout(() => {
  //     document.getElementById('blockSidebar').style.height = this.heightBlockContent + 'px';
  //   }, 8000);
  // }

  get mobile(){
    return this.dataService.mobile;
  }

  get header(){ return this._header; }
  get footer(){ return this._footer; }
  get navsidebar(){return this._navsidebar; }

  chngAdmStyle = (): void => {
    this.admStyle = this.admStyle == 'old' ? 'new' : 'old';
  }
  
  onKeydown(e){
    const rt = '' + location.pathname;
    if ((e.key === 'a' || e.key === 'ф' || e.key === 'A' || e.key === 'Ф' ) && e.altKey || (e.key === 'a' && e.metaKey)){
      this.dataService.setRoute(rt, {mode: 'admin'});
    } else if ((e.key === 'q' || e.key === 'й' || e.key === 'Q' || e.key === 'Й') && e.altKey){
      this.dataService.mode = 'public';
      this.dataService.setRoute(rt, {});
    } else if ((e.key === 'l' || e.key === 'д' || e.key === 'L' || e.key === 'Д') && e.altKey){
      this.dataService.setRoute(rt, {mode: 'user'});
    }
  }
}
