<!-- BEGIN уведомление о старой версии сайта -->

<!--<div *ngIf="mode != 'admin'" class="switch-site">-->
<!--  <div class="container" style="margin: auto">-->
<!--    <div style="{{(rated)? ('width: 50%;'): ('')}} display: inline-block">-->
<!--      <p style="color: #ffffff; padding: 3px 0px; margin: 2px 0px;">-->
<!--        {{(rated)? (''): ('Перейти на старую версию сайта?')}}-->
<!--          <input class="btn btn-secondary" style="{{(rated)? ('background-color: #0ABAB5; border-color: #00a8a2; font-weight: 500;'): ('')}} padding: 0px 7px; font-size: 13px; min-height: initial;" type="button" value="{{(rated)? ('Старая версия сайта'): ('ДА')}}" (click)="openModal('custom-modal-1')">-->
<!--      </p>-->
<!--    </div>-->
<!--    <div *ngIf="rated" style="width: 49%; display: inline-block;">-->
<!--      <p style=" text-align: right; color: #ffffff; padding: 3px 0px; margin: 2px 0px;">-->
<!--        <input class="btn btn-secondary" style=" padding: 0px 5px; font-size: 14px; min-height: initial;" type="button" value="Оценить сайт" (click)="starsModal('custom-modal-1')">-->
<!--      </p>-->
<!--    </div>-->
<!--  </div>-->
<!--</div>-->
<!--<jw-modal id="custom-modal-1">-->
<!--  <h2 *ngIf="!rated" class="title-2">Прежде, чем перейти</h2>-->
<!--  <h3>Оцените работу сайта!</h3>-->
<!--  <form id="modal_form_about_site" name="modal_form_about_site" class="form-rating">-->
<!--    <ngb-rating [(rate)]="currentRate" max="5" class="rating-stars"></ngb-rating><br>-->
<!--    <textarea [(ngModel)]="message" placeholder="Оставьте комментарий" name="comment" class="form-control mt-2 mb-4"></textarea>-->
<!--  </form>-->
<!--  <button class="btn btn-outline-secondary mb-4 px-4" (click)="closeModal('custom-modal-1', currentRate, message);">-->
<!--    {{(!rated)? ('На старую версию'): ('Отправить оценку')}}-->
<!--  </button>-->
<!--</jw-modal>-->

<!--<jw-modal class="modal-start" id="custom-modal-2">-->
<!--  <h2 class="title-2">Вместе делаем сайт!</h2>-->
<!--  <p>-->
<!--    Обращаем внимание, новый сайт находится на этапе наполнения и актуализации информации. Принимаем все Ваши замечания и пожелания по любым изменениям!-->
<!--  </p>-->
<!--  <p>-->
<!--    Мы активно работаем над тем, чтобы сайт стал для Вас удобным и полезным!-->
<!--  </p>-->
<!--  <p>-->
<!--    Старая версия сайта по-прежнему функционирует и содержит самую свежую информацию о ФСНКЦ ФМБА России.-->
<!--  </p>-->
<!--  <div class="mess-command">-->
<!--    <div class="mess mr-2">-->
<!--      Благодарим за понимание!<br>-->
<!--      С уважением, Ваша команда заботы-->
<!--    </div>-->
<!--    <button class="btn btn-outline-secondary mt-3 mb-3 px-4" (click)="closeModal('custom-modal-2', currentRate, message);">Хорошо!</button>-->
<!--  </div>-->

<!--</jw-modal>-->
<jw-modal class="modal-start" id="alert">
  <h2 class="title-2">Вниманию пациентов поликлиник ФСНКЦ ФМБА России!</h2>
  <p>
    Сегодня, 11 февраля, по техническим причинам, возникшим у оператора связи БИЛАЙН, с 8:00 запись пациентов,
    прикреплённых к поликлиникам ФСНКЦ ФМБА России, а также вызов врача на дом осуществляются по телефону <a href="tel:+7 (391) 274-31-51">+7 (391) 274-31-51</a>.
  </p>
  <p>
    Для записи на платный прием необходимо воспользоваться JIVO-мессенджером, расположенном в левом углу сайта.
  </p>
  <p>
    В настоящее время ведутся работы по устранению неполадок.Приносим свои извинения за доставленные неудобства.
  </p>
  <div class="mess-command">
    <div class="mess mr-2">
      Благодарим за понимание!
    </div>
    <button class="btn btn-outline-secondary mt-3 mb-3 px-4" (click)="closeModal('alert');">Понимаю!</button>
  </div>
</jw-modal>

<!-- END  -->

<header *ngIf="model.locale === 'ru'" class="header">
  <app-new-year *ngIf="mode !== 'admin' && newYearPublic"></app-new-year>
<!--  <app-march-8th *ngIf="mode !== 'admin' && march8th"></app-march-8th>-->

  <div class="container header-top">
    <div class="header-city">
      <ng-container *ngFor="let item of model.cities">
        <span *ngIf="item.url" style="color:gray; cursor:pointer;" (click)="followTo(item)"><i><u>{{item.title}}</u></i></span>

        <!-- <a *ngIf="item.url" style="{{item.style}}" href="{{item.url}}?setcity={{item.id}}">{{item.title}}</a> -->
        <span *ngIf="!item.url">{{item.title}}</span>
      </ng-container>
    </div>

    <div class="header-logo">
      <a [routerLink]="model.homepage" title="Перейти на главную страницу">
        <img src="{{'../../assets/img/logo-' + model.locale + '-' + model.id + '.svg'}}" alt=""/>
      </a>
    </div>

    <div class="block-phone-header">
      <div *ngFor="let item of model.maincontact.phones" class="header-phone">
        <a class="header-phone__link" href="tel:{{item.number}}">{{item.number}}</a>
        <div class="header-phone__text">{{item.description}}</div>
      </div>
    </div>

    <div *ngIf="model.maincontact.socialnets && model.maincontact.socialnets.length > 0" class="header-social">
      <ng-container *ngFor="let item of model.maincontact.socialnets">
        <a *ngIf="item.socialnet" class="link-icon" rel="noopener" target="_blank" [href]="item.socialnet.urlprefix ? (item.socialnet.urlprefix + ((item.socialnet.urlprefix[item.socialnet.urlprefix.length - 1] === '/')? (''): ('/')) + item.url) : ''">
          <svg>
            <use attr.xlink:href="#{{item.socialnet.svgclass}}"></use>
          </svg>
        </a>
      </ng-container>
    </div>
    <div class="header-special">
      <!--      TODO добавить ссылки на личный кабинет и дургие в шапке сайта -->
      <ng-container *ngFor="let item of model.languages">
        <a *ngIf="item.url" class="link-red" [href]="item && item.url">{{item.title}}</a>
      </ng-container>

      <!--      не удалять!-->

            <a class="link-icon--red icon-user" rel="noopener" [href]="model.lkurl" target="_blank">
              <div class="icon-user-title">
                Личный кабинет
              </div>
              <svg>
                <use xlink:href="#user"></use>
              </svg>

            </a>
            <a class="link-icon-red" rel="noopener" [href]="model.specurl">
              <svg>
                <use xlink:href="#glasses"></use>
              </svg>
            </a>
    </div>

    <div class="header-add">
      <div class="header-order">
        <!-- TODO указать ссылку на форму записи -->
        <a class="btn btn-primary btn-block" [routerLink]="['/apointment']">Записаться на прием</a>
      </div>
      <!--      <div class="header-search-btn js&#45;&#45;open-mainmenu">-->
      <!--        <svg>-->
      <!--          <use xlink:href="#search"></use>-->
      <!--        </svg>-->
      <!--      </div>-->
      <div class="header-menu-btn js--open-mainmenu">
        <svg>
          <use xlink:href="#menu"></use>
        </svg>
      </div>
    </div>
  </div>
  <div class="container header-bottom">
    <app-nav-header class="header-nav" [_model]="model"></app-nav-header>
    <!-- <app-sidebar-header></app-sidebar-header> -->
  </div>
  <div class="container header-bottom">
    <!--    Не удалять!  -->
    <!--    <div class="header-menu-btn js&#45;&#45;open-mainmenu">-->
    <!--      <svg>-->
    <!--        <use xlink:href="#menu"></use>-->
    <!--      </svg>-->
    <!--    </div>-->

    <div class="header-search offset-6">
      <input class="form-control" type="text" placeholder="Поиск по сайту..." [(ngModel)]="filter" (keyup.enter)="search()"/>
      <button class="_search" (click)="search()" (submit)="search()">
        <svg>
          <use xlink:href="#search"></use>
        </svg>
      </button>
      <button class="_clear js--clear-search">
        <svg>
          <use xlink:href="#close"></use>
        </svg>
      </button>
    </div>

    <div class="header-order">
      <!-- TODO указать ссылку на форму записи -->
      <a class="btn btn-secondary btn-block" [routerLink]="['/apointment']">Записаться на прием</a>
    </div>
    <div class="header-order">
      <a class="btn btn-primary" [href]="model.lkurl" target="_blank">
        Личный кабинет
      </a>
    </div>
  </div>
</header>

<header *ngIf="model.locale === 'en'" class="header">
  <div class="container header-top">
<!--    <div class="header-city">-->
<!--      <ng-container *ngFor="let item of model.cities">-->
<!--        <a *ngIf="item.url" style="{{item.style}}" href="{{item.url}}">{{item.title}}</a>-->
<!--        <span *ngIf="!item.url">{{item.title}}</span>-->
<!--      </ng-container>-->
<!--    </div>-->

    <div class="header-logo">
      <a [routerLink]="model.homepage" title="Перейти на главную страницу">
        <img src="{{'../../assets/img/logo-' + model.locale + '-' + model.id + '.svg'}}" alt=""/>
      </a>
    </div>

<!--    <div *ngIf="maincontact.phonetohome.number!==''" class="header-phone">-->
<!--      <a class="header-phone__link" href="tel:{{maincontact.phonetohome.number}}">{{maincontact.phonetohome.number}}</a>-->
<!--      <div class="header-phone__text">{{maincontact.phonetohome.description}}</div>-->
<!--    </div>-->
<!--    <div-->
<!--      *ngIf="maincontact.phonedms.number!==null"-->
<!--      class="header-phone"><a class="header-phone__link" href="tel:{{maincontact.phonedms.number}}">{{maincontact.phonedms.number}}-->
<!--      &lt;!&ndash;      <span>– </span><span>Записаться</span>&ndash;&gt;-->
<!--    </a>-->
<!--      <div class="header-phone__text">{{maincontact.phonedms.description}}</div>-->
<!--    </div>-->
<!--    <div-->
<!--      *ngIf="maincontact.phoneoms.number!==null"-->
<!--      class="header-phone">-->
<!--      <a class="header-phone__link" href="tel:{{maincontact.phoneoms.number}}">{{maincontact.phoneoms.number}}-->
<!--        &lt;!&ndash;      <span>– </span><span>Записаться</span>&ndash;&gt;-->
<!--      </a>-->
<!--      <div class="header-phone__text">{{maincontact.phoneoms.description}}</div>-->
<!--    </div>-->

<!--    <div *ngIf="maincontact.socialnets && maincontact.socialnets.length > 0" class="header-social">-->
<!--      <ng-container *ngFor="let item of maincontact.socialnets">-->
<!--        <a *ngIf="item.socialnet" class="link-icon" rel="noopener" target="_blank" [href]="item.socialnet.urlprefix ? (item.socialnet.urlprefix + ((item.socialnet.urlprefix[item.socialnet.urlprefix.length - 1] === '/')? (''): ('/')) + item.url) : ''">-->
<!--          <svg>-->
<!--            <use attr.xlink:href="#{{item.socialnet.svgclass}}"></use>-->
<!--          </svg>-->
<!--        </a>-->
<!--      </ng-container>-->
<!--    </div>-->

<!--    <div class="header-special">-->
<!--      &lt;!&ndash;      TODO добавить ссылки на личный кабинет и дургие в шапке сайта &ndash;&gt;-->
<!--      <ng-container *ngFor="let item of model.languages">-->
<!--        <a *ngIf="item.url" class="link-red" href="{{item.url}}">{{item.title}}</a>-->
<!--      </ng-container>-->

<!--      &lt;!&ndash;      не удалять!&ndash;&gt;-->
<!--      &lt;!&ndash;      <a class="link-red" href="" >Eng</a>&ndash;&gt;-->
<!--      &lt;!&ndash;      <a class="link-icon-red" rel="noopener" href="https://pl.skc-fmba.ru/accounts/login/" target="_blank">&ndash;&gt;-->
<!--      &lt;!&ndash;        <svg>&ndash;&gt;-->
<!--      &lt;!&ndash;          <use xlink:href="#user"></use>&ndash;&gt;-->
<!--      &lt;!&ndash;        </svg>&ndash;&gt;-->
<!--      &lt;!&ndash;      </a>&ndash;&gt;-->
<!--      &lt;!&ndash;      <a class="link-icon-red" rel="noopener" href="">&ndash;&gt;-->
<!--      &lt;!&ndash;        <svg>&ndash;&gt;-->
<!--      &lt;!&ndash;          <use xlink:href="#glasses"></use>&ndash;&gt;-->
<!--      &lt;!&ndash;        </svg>&ndash;&gt;-->
<!--      &lt;!&ndash;      </a>&ndash;&gt;-->
<!--    </div>-->

<!--    <div class="header-add">-->
<!--      <div class="header-order">-->
<!--        &lt;!&ndash; TODO указать ссылку на форму записи &ndash;&gt;-->
<!--        <a class="btn btn-primary btn-block" [routerLink]="['/apointment']">Записаться на прием</a>-->
<!--      </div>-->
<!--      &lt;!&ndash;      <div class="header-search-btn js&#45;&#45;open-mainmenu">&ndash;&gt;-->
<!--      &lt;!&ndash;        <svg>&ndash;&gt;-->
<!--      &lt;!&ndash;          <use xlink:href="#search"></use>&ndash;&gt;-->
<!--      &lt;!&ndash;        </svg>&ndash;&gt;-->
<!--      &lt;!&ndash;      </div>&ndash;&gt;-->
<!--      <div class="header-menu-btn js&#45;&#45;open-mainmenu">-->
<!--        <svg>-->
<!--          <use xlink:href="#menu"></use>-->
<!--        </svg>-->
<!--      </div>-->
<!--    </div>-->
<!--  </div>-->
<!--  <div class="container header-bottom">-->
<!--    <app-nav-header class="header-nav" [_model]="model.navheader"></app-nav-header>-->
<!--  </div>-->
<!--  <div class="container header-bottom">-->
<!--    &lt;!&ndash;    Не удалять!  &ndash;&gt;-->
<!--    &lt;!&ndash;    <div class="header-menu-btn js&#45;&#45;open-mainmenu">&ndash;&gt;-->
<!--    &lt;!&ndash;      <svg>&ndash;&gt;-->
<!--    &lt;!&ndash;        <use xlink:href="#menu"></use>&ndash;&gt;-->
<!--    &lt;!&ndash;      </svg>&ndash;&gt;-->
<!--    &lt;!&ndash;    </div>&ndash;&gt;-->

<!--    <div class="header-search offset-6">-->
<!--      <input class="form-control" type="text" placeholder="Поиск по сайту..." [(ngModel)]="filter" (keyup.enter)="search()"/>-->
<!--      <button class="_search" (click)="search()" (submit)="search()">-->
<!--        <svg>-->
<!--          <use xlink:href="#search"></use>-->
<!--        </svg>-->
<!--      </button>-->
<!--      <button class="_clear js&#45;&#45;clear-search">-->
<!--        <svg>-->
<!--          <use xlink:href="#close"></use>-->
<!--        </svg>-->
<!--      </button>-->
<!--    </div>-->

<!--    <div class="header-order">-->
<!--      &lt;!&ndash; TODO указать ссылку на форму записи &ndash;&gt;-->
<!--      <a class="btn btn-primary btn-block" [routerLink]="['/apointment']">Записаться на прием</a>-->
<!--    </div>-->
  </div>
</header>

<div *ngIf="model.locale === 'ru'" class="header-fix">
  <div class="container">
<!--    Не удалять!   -->
<!--    <div class="header-menu-btn js&#45;&#45;open-mainmenu">-->
<!--      <svg>-->
<!--        <use xlink:href="#menu"></use>-->
<!--      </svg>-->
<!--    </div>-->
    <div class="header-logo">
      <a [routerLink]="'/'">
        <img src="{{'../../assets/img/logo-' + model.locale + '-' + model.id + '.svg'}}" alt=""/>
      </a>
    </div>

    <div class="block-phone-header">
      <div *ngFor="let item of model.maincontact.phones" class="header-phone">
        <a class="header-phone__link" href="tel:{{item.number}}">{{item.number}}</a>
        <div class="header-phone__text">{{item.description}}</div>
      </div>
    </div>
    <div>
    <div class="header-city">
      <ng-container *ngFor="let item of model.cities">
            <span *ngIf="item.url" style="color:gray; cursor:pointer;" (click)="followTo(item)"><i><u>{{item.title}}</u></i></span>

        <!-- <a *ngIf="item.url" style="{{item.style}}" href="{{item.url}}?setcity={{item.id}}">{{item.title}}</a> -->
        <span *ngIf="!item.url">{{item.title}}</span>
      </ng-container>
    </div>
    <div class="header-special">
      <ng-container *ngFor="let item of model.languages">
        <a *ngIf="item.url" class="link-red" href="{{item.url}}">{{item.title}}</a>
      </ng-container>
      <!--      не удалять!-->
      <a class="link-icon-red" rel="noopener" [href]="model.lkurl" target="_blank">
        <svg>
          <use xlink:href="#user"></use>
        </svg>
      </a>
      <a class="link-icon-red" rel="noopener" [href]="model.specurl">
        <svg>
          <use xlink:href="#glasses"></use>
        </svg>
      </a>
    </div>
    <div class="header-order">
      <!-- TODO указать ссылку на форму записи на прием -->
      <a class="btn btn-primary btn-block" [routerLink]="['/apointment']">Записаться на прием</a>
    </div>
  </div>
  </div>
</div>

<div *ngIf="model.locale === 'ru'" >
  <div *ngIf="!mobile" class="mainmenu">
  <div class="container">
    <div class="mainmenu-top">
      <div class="mainmenu-search">
        <input class="form-control" type="text" placeholder="Поиск по сайту..." [(ngModel)]="filter"  (keyup.enter)="search()" />
        <button class="_search" (click)="search()" (submit)="search()">
          <svg>
            <use xlink:href="#search"></use>
          </svg>
        </button>
        <button class="_clear js--clear-search">
          <svg>
            <use xlink:href="#close"></use>
          </svg>
        </button>
      </div>
      <div class="mainmenu-order">
        <!-- TODO указаться ссылку на форму записи -->
        <a class="btn btn-primary btn-block" [routerLink]="['/apointment']">Записаться на прием</a>
      </div>
      <div class="mainmenu-close-btn js--open-mainmenu">
        <svg>
          <use xlink:href="#close"></use>
        </svg>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-3 col-md-12 mainmenu-col">
        <div class="row">
          <div class="col-md-4 col-lg-12 mainmenu-col">
            <a [routerLink]="['/services']"><div class="mobilemenu-close big">Услуги и цены</div></a>
            <a [routerLink]="['/doctors']"><div class="mobilemenu-close big">Врачи</div></a>
            <a [routerLink]="['/articles']"><div class="mobilemenu-close big">Статьи</div></a>
            <a [routerLink]="['/news']"><div class="mobilemenu-close big">Новости</div></a>
            <a [routerLink]="['/feedbacks']"><div class="mobilemenu-close big">Отзывы</div></a>
          </div>
          <div class="col-md-4 col-lg-12 mainmenu-col">
<!--            <a [routerLink]="['/promotions']"><div class="mobilemenu-close big">Акции и<br>программы</div></a>-->
            <a [routerLink]="['/pharmacies']"><div class="mobilemenu-close big">Аптека</div></a>
            <a [routerLink]="['/centers']"><div class="mobilemenu-close big">Центры и отделения</div></a>
            <a [routerLink]="['/clinic']"><div class="mobilemenu-close big">Поликлиники</div></a>
            <a [routerLink]="['/about-center/tab/address']"><div class="mobilemenu-close big">Контакты</div></a>
          </div>
          <div class="col-md-4 col-lg-12 mainmenu-col">
            <div class="mt-lg-5">
              <div *ngFor="let item of model.maincontact.phones" class="mainmenu-phone">
                <a class="mainmenu-phone__link" href="tel:{{item.number}}">{{item.number}}</a>
                <div class="mainmenu-phone__text">{{item.description}}</div>
              </div>
            </div>

            <button class="btn btn-outline-secondary mt-lg-5 d-none d-lg-inline-block" [routerLink]="['/apointment']">Записаться на прием</button>
            <div class="mainmenu-social mt-lg-5 mt-md-4">

              <ng-container *ngFor="let item of model.maincontact.socialnets">
                <a *ngIf="item.socialnet" class="link-icon" rel="noopener" target="_blank" [href]="item.socialnet.urlprefix ? (item.socialnet.urlprefix + ((item.socialnet.urlprefix[item.socialnet.urlprefix.length - 1] === '/')? (''): ('/')) + item.url) : ''">
                  <svg>
                    <use attr.xlink:href="#{{item.socialnet.svgclass}}"></use>
                  </svg>
                </a>
              </ng-container>
            </div>
          </div>
        </div>
      </div>
      <!-- меню - версия для планшета -->
<!--      <h2>Планшет - меню</h2>-->
      <div *ngIf="model.navheader && model.navheader.items" class="col-lg-3 col-md-4 mainmenu-col">
<!--        <ng-container *ngFor="let item of model.navheader">-->
        <ng-container *ngFor="let item of model.navheader.items">
        <!-- родительский пункт меню -->
          <a class="mobilemenu-close" [routerLink]="model.homepage">
            <div class="big mt-4">{{item.title}}</div>
          </a>
          <!-- дочерние пункты -->
          <ng-container *ngFor="let jitem of item.items">
            <a class="mobilemenu-close" *ngIf="jitem.url && (jitem.url.indexOf('://') > 0)" [href]="[jitem.url]" target="_blank" rel="noreferrer">
              {{jitem.title}}
            </a>
            <a class="mobilemenu-close" *ngIf="jitem.url && (jitem.url.indexOf('://') === -1)" [routerLink]="['/' + jitem.url]" [routerLinkActive]="['active']" style="{{(jitem.style && jitem.style != '')? (jitem.style): ('')}}">
              {{jitem.title}}
            </a>
          </ng-container>
        </ng-container>
      </div>
    </div>
  </div>
</div>
  <div  class="mobilemenu">
    <div class="container">
      <div class="mobilemenu-logo">
        <img src="{{'../../assets/img/logo-' + model.locale + '-' + model.id + '.svg'}}" alt=""/>
      </div>
      <div class="mobilemenu-close-btn js--open-mainmenu">
        <svg>
          <use xlink:href="#close"></use>
        </svg>
      </div>
      <div class="mobilemenu-order">
        <a class="btn btn-primary btn-block" [routerLink]="['/apointment']">Записаться на прием</a>
      </div>
      <div class="mobilemenu-search">
        <input class="form-control" type="text" placeholder="Поиск по сайту..." [(ngModel)]="filter"  (keyup.enter)="search()"/>
        <button class="_search" (click)="search()" (submit)="search()">
          <svg>
            <use xlink:href="#search"></use>
          </svg>
        </button>
        <button class="_clear js--clear-search">
          <svg>
            <use xlink:href="#close"></use>
          </svg>
        </button>
      </div>

      <div class="row">
        <div class="mobilemenu-city col-7">
          <ng-container *ngFor="let item of model.cities">
            <span *ngIf="item.url" style="color:gray; cursor:pointer;" (click)="followTo(item)"><i><u>{{item.title}}</u></i></span>
            <!-- <a *ngIf="item.url" style="{{item.style}}" href="{{item.url}}?setcity={{item.id}}">{{item.title}}</a> -->
            <span *ngIf="!item.url">{{item.title}}</span>
          </ng-container>
        </div>
        <div class="col-2 offset-1">
          <a class="link-icon-red" rel="noopener" [href]="model.lkurl" target="_blank">
            <svg height="18px">
              <use xlink:href="#user"></use>
            </svg>
          </a>
        </div>
        <div class="col-2">
          <a class="link-icon-red" rel="noopener" [href]="model.specurl">
            <svg height="18px">
              <use xlink:href="#glasses"></use>
            </svg>
          </a>
        </div>
      </div>
      <nav class="mobilemenu-nav">
<!--        <h2>Мобильное - меню</h2>-->
        <ul *ngIf="model.navheader && model.navheader.items">
          <li *ngFor="let item of model.navheader.items">

            <a *ngIf="item.items.length > 0" class="js--mobilemenu-open" href="">{{item.title}}
              <svg>
                <use xlink:href="#menu-arrow"></use>
              </svg>
            </a>

            <a class="mobilemenu-close" *ngIf="item.items.length <= 0 && item.url && (item.url.indexOf('://') > 0)" [href]="[item.url]" target="_blank" rel="noreferrer">
              {{item.title}}
            </a>


            <a class="mobilemenu-close" *ngIf="item.items.length <= 0 && item.url && (item.url.indexOf('://') === -1)" [routerLink]="['/' + item.url]" [routerLinkActive]="['active']" style="{{(item.style && item.style != '')? (item.style): ('')}}">
              {{item.title}}
            </a>

            <div class="mobilemenu-nav__inner">
              <div class="container">
                <div class="mobilemenu-nav__inner-back" style="position: relative">Назад
                  <div class="mobilemenu-close-btn js--open-mainmenu">
                    <svg>
                      <use xlink:href="#close"></use>
                    </svg>
                  </div>
                </div>

                <ul class="mobilemenu-close">
                  <!-- дочерние пункты -->
                  <ng-container *ngIf="item.items.length > 0">
                    <li *ngFor="let jitem of item.items">
                      <a  *ngIf="jitem.url && (jitem.url.indexOf('://') > 0)" [href]="[jitem.url]" target="_blank" rel="noreferrer">
                        {{jitem.title}}
                      </a>
                      <a *ngIf="jitem.url && (jitem.url.indexOf('://') === -1)" [routerLink]="['/' + jitem.url]" [routerLinkActive]="['active']" style="{{(jitem.style && jitem.style != '')? (jitem.style): ('')}}">
                        {{jitem.title}}
                      </a>
                    </li>
                  </ng-container>

                </ul>
              </div>
            </div>
          </li>
        </ul>
        <ul *ngIf="model.navheader2 && model.navheader2.items">
          <li *ngFor="let item of model.navheader2.items">
        
            <a *ngIf="item.items.length > 0" class="js--mobilemenu-open" href="">{{item.title}}
              <svg>
                <use xlink:href="#menu-arrow"></use>
              </svg>
            </a>
        
            <a class="mobilemenu-close" *ngIf="item.items.length <= 0 && item.url && (item.url.indexOf('://') > 0)"
              [href]="[item.url]" target="_blank" rel="noreferrer">
              {{item.title}}
            </a>
        
        
            <a class="mobilemenu-close" *ngIf="item.items.length <= 0 && item.url && (item.url.indexOf('://') === -1)"
              [routerLink]="['/' + item.url]" [routerLinkActive]="['active']"
              style="{{(item.style && item.style != '')? (item.style): ('')}}">
              {{item.title}}
            </a>
        
            <div class="mobilemenu-nav__inner">
              <div class="container">
                <div class="mobilemenu-nav__inner-back" style="position: relative">Назад
                  <div class="mobilemenu-close-btn js--open-mainmenu">
                    <svg>
                      <use xlink:href="#close"></use>
                    </svg>
                  </div>
                </div>
        
                <ul class="mobilemenu-close">
                  <!-- дочерние пункты -->
                  <ng-container *ngIf="item.items.length > 0">
                    <li *ngFor="let jitem of item.items">
                      <a *ngIf="jitem.url && (jitem.url.indexOf('://') > 0)" [href]="[jitem.url]" target="_blank"
                        rel="noreferrer">
                        {{jitem.title}}
                      </a>
                      <a *ngIf="jitem.url && (jitem.url.indexOf('://') === -1)" [routerLink]="['/' + jitem.url]"
                        [routerLinkActive]="['active']" style="{{(jitem.style && jitem.style != '')? (jitem.style): ('')}}">
                        {{jitem.title}}
                      </a>
                    </li>
                  </ng-container>
        
                </ul>
              </div>
            </div>
          </li>
        </ul>
      </nav>

      <div class="mt-lg-5">
        <div *ngFor="let item of model.maincontact.phones" class="mobilemenu-phone">
          <a class="mobilemenu-phone__link" href="tel:{{item.number}}">{{item.number}}</a>
          <div class="mobilemenu-phone__text">{{item.description}}</div>
        </div>
      </div>

      <div class="mobilemenu-social">
        <ng-container *ngFor="let item of ((model.maincontact && model.maincontact.socialnets) || [])">
          <a *ngIf="item.socialnet" class="link-icon" rel="noopener" target="_blank" [href]="item.socialnet.urlprefix ? (item.socialnet.urlprefix + ((item.socialnet.urlprefix[item.socialnet.urlprefix.length - 1] === '/')? (''): ('/')) + item.url) : ''">
            <svg>
              <use attr.xlink:href="#{{item.socialnet.svgclass}}"></use>
            </svg>
          </a>
        </ng-container>
      </div>
    </div>
  </div>
</div>
