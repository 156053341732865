import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DataService } from '../../../services/data.service';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { debounceTime, delay, switchMap, tap } from 'rxjs/operators';
import { NgbButtonLabel } from '@ng-bootstrap/ng-bootstrap';
import { DecimalPipe } from '@angular/common';

interface SearchResult {
  items: any[];
  total: number;
}

interface State {
  searchTerm: string;
}

const matches = (item: any, fieldName: string, term: string) => (typeof item[fieldName] === 'string' &&
  item[fieldName].toLowerCase().includes(term.toLowerCase()));

@Component({
  selector: 'app-adm-modal-link',
  templateUrl: './adm-modal-link.component.html',
  providers: [ DecimalPipe, NgbButtonLabel ]
})
export class AdmModalLinkComponent implements OnInit {
  private parent: any;
  private _selectedItems = [];
  private _loading$ = new BehaviorSubject<boolean>(true);
  private _search$ = new Subject<void>();
  private _items$ = new BehaviorSubject<any[]>([]);
  private _siteItems$ = [];
  private _total$ = new BehaviorSubject<number>(0);
  private _searchTerm: string;
  private _state: State = {
    searchTerm: ''
  };

  public selected = {};

  schema: any;
  modelname: string;
  id: number;
  field: string;

  model: any;
  links: {field: string; modelname: string; title: string; single: boolean}[];
  selectedLink: any;

  private _search(): Observable<SearchResult> {
    const {searchTerm} = this._state;
    const items = this.model.data.filter(item => (matches(item, 'name', searchTerm) ||
      matches(item, 'title', searchTerm)));

    const total = items.length;
    return of({items, total});
  }

  get selectedItems() { return this._selectedItems; }
  get items$() { return this._items$.asObservable(); }
  get siteItems$() { return this._siteItems$; }
  get total$() { return this._total$.asObservable(); }
  get loading$() { return this._loading$.asObservable(); }

  get searchTerm() { return this._searchTerm; }
  set searchTerm(searchTerm: string) { this._set({searchTerm}); }

  private _set(patch: Partial<State>) {
    Object.assign(this._state, patch);
    this._search$.next();
  }

  reload = (link) => {
    console.log('link', link);
    console.log('this.parent', this.parent);

    let linked = [];

    if (this.parent[link.field]){
      // Если links
      if (Array.isArray(this.parent[link.field])){
        linked = this.parent[link.field].map(item => item.id);
      }
      // Если link
      else{
        linked = [this.parent[link.field].id];
      }
    }

    console.log('linked', linked);

    this.dataService.getData(link.modelname, false, {
      language: this.dataService.struct.language.id,
      ...(link.modelname !== 'allnews' && {city: this.dataService.struct.city.id})
    })
      .then((data: any[]) => {

        // Отсеиваем уже привязанные строки
        this.model.data = data
          .filter(item => !linked.includes(item.id))
          .map(item => ({
            id: item.id, name: item.name || 'Не указано', title: item.title || 'Не указано'
          }))
          .sort((a, b) => a.title.localeCompare(b.title));
        this._search$.next();
      })
      .catch(e => {
        console.error(e);
      });
  }

  constructor(
    private dataService: DataService,
    public modal: NgbActiveModal,
  ) {
    this.model = {data: []};
    this.links = [];
    this.selectedLink = {};
    this._searchTerm = '';
    this.parent = {};

    this._search$.pipe(
      tap(() => this._loading$.next(true)),
      debounceTime(200),
      switchMap(() => this._search()),
      delay(200),
      tap(() => this._loading$.next(false))
    ).subscribe((result: any) => {
      this._items$.next(result.items);
      this._total$.next(result.total);
    });

    this._search$.next();
  }

  ngOnInit(): void {
    if (!this.modelname) {return alert('Неверная схема данных. Свяжитесь с разработчиком!'); }
    this.dataService.getData(this.modelname, this.id, {})
      .then(data => {
        this.parent = data;
        this.links = [];
        // формируем список возможных для линка объектов с учетом типа связывания (единичный/множественный)
        if (this.schema.links) {
          if (this.field && this.schema.links[this.field]){
            this.selectedLink = {
              field: this.field,
              modelname: this.schema.links[this.field].modelname,
              title: this.schema.links[this.field].title,
              single: this.schema.links[this.field].single
            };

            this.reload(this.selectedLink);
          }
          else{
            Object.keys(this.schema.links).forEach(key => {
              this.links.push({
                field: key,
                modelname: this.schema.links[key].modelname,
                title: this.schema.links[key].title,
                single: this.schema.links[key].single
              });
            });
          }
        }
        else{
          alert('Нет доступных для подключения данных, обратитесь к разработчику!');
        }
      })
      .catch(err => console.error(err));
  }

  unselectAll = () => {
    this.selected = {};
    this._selectedItems = [];
  }

  onItemChange = () => {
    this._selectedItems = Object.keys(this.selected).filter(key => this.selected[key]);
  }

  setLinkModel = (link) => {
    this.selectedLink = link;
    this.reload(this.selectedLink);
  }
}
