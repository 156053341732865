<ng-template #admbuttons let-st="state">
  <app-admitemslistmenu [state]="st"></app-admitemslistmenu>
</ng-template>
<div style="flex-direction: row; flex-wrap: wrap; display: flex;">
<app-nav-header-item *ngFor="let item of (((mode==='admin') && model.items) || [])"
                     [ngbPopover]="admbuttons" #p="ngbPopover" placement="left"
                     (mouseenter)="bordermark(p, item.state)"
                     (mouseleave)="bordermark(p, false)"
                     triggers="manual" style="{{borderStyle[item.state]||''}}"
                     [state]="item.state"
                     [model]="item">
</app-nav-header-item>
</div>
<div style="flex-direction: row; flex-wrap: wrap; display: flex;">
<app-nav-header-item *ngFor="let item of (((mode==='admin') && model2.items) || [])"
                     [ngbPopover]="admbuttons" #p="ngbPopover" placement="left"
                     (mouseenter)="bordermark(p, item.state)"
                     (mouseleave)="bordermark(p, false)"
                     triggers="manual" style="{{borderStyle[item.state]||''}}"
                     [state]="item.state"
                     [model]="item">
</app-nav-header-item>
</div>
<div style="flex-direction: row; flex-wrap: wrap; display: flex;">
<app-nav-header-item *ngFor="let item of (((mode!=='admin') && model.items) || [])"
                     [state]="modelname+':'+model.id+':items:'+item.id"
                     [model]="item">
</app-nav-header-item>
</div>
<div style="flex-direction: row; flex-wrap: wrap; display: flex;">
<app-nav-header-item *ngFor="let item of (((mode!=='admin') && model2.items) || [])"
                     [state]="modelname+':'+model2.id+':items:'+item.id"
                     [model]="item"
                     class="navheader2" 
                     >
</app-nav-header-item>
</div>