import {Component, Input, OnDestroy} from '@angular/core';
import { DataService } from '../../services/data.service';
import {ActivatedRoute, RouterModule} from '@angular/router';
import {CommonModule} from '@angular/common';
import {EventsService} from '../../services/events.service';
import {AdminService} from '../../services/admin.service';
import {AdmitemslistmenuComponent} from '../admin/admitemslistmenu/admitemslistmenu.component';
import {NgbPopoverModule} from '@ng-bootstrap/ng-bootstrap';
import {FooterDataMaincontactsComponent} from './footer-data-maincontacts/footer-data-maincontacts.component';
import {Subscription} from 'rxjs';
import { NavFooterItemComponent } from './nav-footer-item/nav-footer-item.component';

class Footer {
  id: number;
  navigation: any;
  order: any;
  cities: any[];
  languages: any[];
  pages: any[];
}

@Component({
  standalone: true,
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css'],
  imports: [
    RouterModule,
    CommonModule,
    AdmitemslistmenuComponent,
    NgbPopoverModule,
    FooterDataMaincontactsComponent,
    NavFooterItemComponent
  ]
})
export class FooterComponent implements OnDestroy{
  @Input() set footer(f){
    if (f.id){
      this.reload(f.id)
    }
  }

  subs: Subscription;
  mode: string;
  model: any = {
    id: 0,
    order: {},
    cities: [],
    languages: [],
    navigation: {},
    pages: [],
    maincontact: {}
  };

  public borderStyle: any;

  year = Date.now();

  // TODO сделать функцию типовой при загрузке данных, инициируемой событием (т.е. почти всегда)
  private reload = async (id) => {
    // delete this.model;
    this.mode = this.dataService.mode;
    const data: any = await this.dataService.getData('footers', id, false);
    
    data.maincontact = this.dataService.struct && this.dataService.struct.main_contact;

    this.model = new Object((this.dataService.mode === 'admin') ? this.admin.fillAdminModel(data, 'footers') : data);
    console.log('FooterComponent model', this.model);
  }

  constructor(
    private activateRoute: ActivatedRoute,
    private events: EventsService,
    public dataService: DataService,
    public admin: AdminService
  ) {
    this.borderStyle = {};
    this.subs = new Subscription();

    this.subs.add(this.dataService.sub().mode.subscribe(m => this.reload(this.model.id)));
    this.subs.add(this.events.event$.subscribe(e => ((e.name === 'reload') && e.data === `footers:${this.model.id}` && this.reload(this.model.id))));
  }

  bordermark = (p, state: string | boolean) => {
    this.borderStyle = {};
    if (state) {
      this.borderStyle['' + state] = 'border: solid 1px #7d7e92;';
      this.admin.popoverOpen(p, '' + state);
    }
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }
}

