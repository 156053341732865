<ng-template #admbuttons let-st="state">
  <app-admitemslistmenu [state]="st"></app-admitemslistmenu>
</ng-template>

<footer class="footer">
  <hr class="d-none d-md-block"/>
    <app-footer-data-maincontacts [struct]="model && model.maincontact"></app-footer-data-maincontacts>
  <hr/>
  <div class="d-none d-md-block">
    <div class="container">
      <div class="row footer-nav">
       <app-nav-footer-item triggers="manual" [fnav]="model && model.navigation"></app-nav-footer-item>

        <!-- <a class="nav-footer-item" [routerLink]="['/pages/', item.id]" *ngFor="let item of (((mode!='admin') && model.pages) || [])">
          {{item.title}}
        </a>
        <a class="nav-footer-item" [routerLink]="['/pages/', item.id]" *ngFor="let item of (((mode=='admin') && model.pages) || [])"
                             [ngbPopover]="admbuttons" #p="ngbPopover" placement="top"
                             (mouseenter)="bordermark(p, item.state)"
                             (mouseleave)="bordermark(p, false)"
                             triggers="manual" style="{{(item.id < 0)? 'color: #F22C8B;' : ''}} {{borderStyle[item.state]||''}}">
          {{(item.id < 0)? ('* добавить материал *') : item.title}}
        </a> -->

<!--        <div class="col-lg-2 col-md-4">-->
          <a class="nav-footer-item" [routerLink]="['/about-center']">Сведения о медицинской организации</a>
          <a class="nav-footer-item" [routerLink]="['/feedbacks']">Отзывы пациентов</a>
          <a class="nav-footer-item" [routerLink]="['/about-center/tab/address']">Контактная информация</a>
          <a class="nav-footer-item" [routerLink]="['/doctors']">Медицинские работники</a>
<!--        </div>-->
      </div>
    </div>
<!--    <hr/>-->
<!--    <div class="container">-->
<!--      <div class="row footer-nav2">-->
<!--        <div class="col-lg-3 col-md-4">-->
<!--          <a href="">-->
<!--            <div class="big">Центры и отделения</div>-->
<!--          </a>-->
<!--          <a href="">Руководство</a>-->
<!--          <a href="">Медицинское руководство</a>-->
<!--          <a href="">Контакты</a>-->
<!--          <a href="">Вышестоящие и контролирующие органы</a><a href="">Страховые компании</a><a href="">Наши партнеры</a><a href="">Аренда помещений</a><a href="">Юридическим лицам</a><a href="">Порядки и стандарты оказания медицинской помощи</a><a href="">Целевое обучение</a><a href="">Вакансии</a><a href="">Прочая информация</a></div>-->
<!--        <div class="col-lg-3 col-md-4"><a href="">-->
<!--          <div class="big">Стационар</div></a><a href="">Руководство</a><a href="">Медицинское руководство</a><a href="">Контакты</a><a href="">Вышестоящие и контролирующие органы</a><a href="">Страховые компании</a><a href="">Наши партнеры</a><a href="">Аренда помещений</a><a href="">Юридическим лицам</a><a href="">Порядки и стандарты оказания медицинской помощи</a><a href="">Целевое обучение</a><a href="">Вакансии</a><a href="">Прочая информация</a></div>-->
<!--        <div class="col-lg-3 col-md-8 order-md-1 mt-4 mt-lg-0">-->
<!--          <div class="row">-->
<!--            <div class="col-md-6 col-lg-12"><a href="">-->
<!--              <div class="big">Поликлиники</div></a><a href="">Поликлиника 1</a><a href="">Поликлиника 2</a><a href="">Поликлиника 3</a><a href="">Поликлиника 4</a><a href="">Поликлиника 5</a></div>-->
<!--            <div class="col-md-6 col-lg-12"><a class="mt-lg-4" href="">-->
<!--              <div class="big">Аптека</div></a><a href="">-->
<!--              <div class="big">Программы и акции</div></a><a href="">-->
<!--              <div class="big">Образование</div></a><a href="">-->
<!--              <div class="big">Наука</div></a></div>-->
<!--          </div>-->
<!--        </div>-->
<!--        <div class="col-lg-3 col-md-4"><a href="">-->
<!--          <div class="big">О нас</div></a><a href="">Руководство</a><a href="">Медицинское руководство</a><a href="">Контакты</a><a href="">Вышестоящие и контролирующие органы</a><a href="">Страховые компании</a><a href="">Наши партнеры</a><a href="">Аренда помещений</a><a href="">Юридическим лицам</a><a href="">Порядки и стандарты оказания медицинской помощи</a><a href="">Целевое обучение</a><a href="">Вакансии</a><a href="">Прочая информация</a></div>-->
<!--      </div>-->
<!--    </div>-->
    <hr/>
  </div>
<!--  <div class="container">-->
  <div class="footer-rights">2003 - {{year | date: 'yyyy'}} © {{model && model.maincontact && model.maincontact.orgform}}<br>{{model && model.maincontact && model.maincontact.shortName}}<br><a href="/media/uploads/policyofinformationsecurity.pdf">Политика информационной безопасности персональных данных</a>
    </div>
<!--  </div>-->
</footer>
