<!-- <noscript><div><img src="https://mc.yandex.ru/watch/{{ymID}}" style="position:absolute; left:-9999px;" alt="" /></div></noscript> -->
<div id="contentOverflowHidden" class="content-overflow-hidden" (keydown)="onKeydown($event)">
  <div class="svg-placeholder"
       style="border:0;clip:rect(0 0 0 0);height:1px;margin:-1px;overflow:hidden;padding:0;position:absolute;width:1px;"
       [inlineSVG]="'./assets/img/fonts.svg'"></div>
  <div *ngIf="mode==='admin'" class="adm-top-area">
    <div class="chng-adm-style-bttn">
      <button (click)="chngAdmStyle()">Изм. стиль</button>
    </div>    
    <app-admin-panel *ngIf="admStyle==='old'"></app-admin-panel>
    <app-admin-panel-n *ngIf="admStyle==='new'"></app-admin-panel-n>
  </div>
  <!-- <app-admin-panel *ngIf="mode==='admin'"></app-admin-panel> -->
  <app-header *ngIf="hfVisible" id="appHeader" [header]="header"></app-header>
  <!--  <div class="mp-first-screen-sm-revers">-->
  <!--    <app-slider-full-width *ngIf="main"></app-slider-full-width>-->
  <!--  </div>-->

  <div id="blockContent" class="body-content container" [style]="(mode==='admin')? 'width: 90vw; max-width: 90vw; margin: 0 20px;': ''">
    <!-- <div
      *ngIf="!admPanel && !mobile"
      id="blockSidebar"
      class="block-sidebar"
      [ngClass]="(!mobile)?('block-none'):('')">
      <app-sidebar *ngIf="hfVisible" class="app-sidebar" [navsidebar]="navsidebar"></app-sidebar>
    </div> -->

    <!-- <div id="blockContentBlock" class="block-content{{city === 1 ? '-krsk' : ''}}" [style]="(mobile)? 'width: 100%': ''"> -->
    <div id="blockContentBlock" class="block-content" [style]="(mobile)? 'width: 100%': ''">
      <router-outlet></router-outlet>

      <div class="del-link-subscribe-sticky" *ngIf="hfVisible && route && (route.indexOf('apointment')<0) && (route.indexOf('employees')<0)">
        <!--  <div class="del-link-subscribe-sticky" *ngIf = "">-->
        <app-block-link-subscribe [header]="header"></app-block-link-subscribe>
      </div>

    </div>
  </div>

  <app-block-partners *ngIf="hfVisible" id="appBlockPartners"></app-block-partners>

  <app-footer *ngIf="hfVisible" id="appFooter" [footer]="footer"></app-footer>
</div>
<ngx-spinner type="ball-clip-rotate"></ngx-spinner>
<!--<div id="jivo" *ngIf="jivo"></div>-->
