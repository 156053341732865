import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  standalone: true,
  templateUrl: './select-city-modal.component.html',
})
export class SelectCityModalComponent{
  titleText: string;
  baseText: string | null;
  subText: string | null;
  submitButtonText: string;
  cancelButtonText: string;

  constructor(public modal: NgbActiveModal) {
    this.submitButtonText = 'ОК';
    this.cancelButtonText = 'Отмена';
    this.titleText = 'Внимание!';
  }
}
