<div class="row">
  <div class="jumbotron col-6 offset-3">
    <div class="container">
      <div class="row">
        <div class="col-sm-6 offset-sm-3">
          <h2>Вход в систему</h2>
          <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
            <div class="form-group">
              <label for="username">Имя пользователя</label>
              <input type="text" formControlName="username" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.username.errors }" />
              <div *ngIf="submitted && f.username.errors" class="invalid-feedback">
                <div *ngIf="f.username.errors.required">Требуется имя пользователя</div>
              </div>
            </div>
            <div class="form-group">
              <label for="password">Пароль</label>
              <input type="password" formControlName="password" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.password.errors }" />
              <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                <div *ngIf="f.password.errors.required">Требуется пароль</div>
              </div>
            </div>
            <div class="form-group">
              <button [disabled]="loading" class="btn btn-primary">
                <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                Войти
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
