<ng-template #admbuttons let-st="state">
  <app-admitemslistmenu [state]="st"></app-admitemslistmenu>
</ng-template>

<app-item-navsidebar
  *ngIf="model.items && model.items.length === 0 && mode === 'admin'"
  [ngbPopover]="admbuttons" #p="ngbPopover" placement="left"
  (mouseenter)="bordermark(p, model.items[0].state)"
  (mouseleave)="bordermark(p, false)"
  triggers="manual" style="{{borderStyle[model.items[0].state]||''}}"
  [state]="model.items[0].state"
  [model]="model.items[0]">
  Добавить первый элемент
</app-item-navsidebar>

<app-item-navsidebar *ngFor="let item of (((mode==='admin') && model.items) || [])"
                     [ngbPopover]="admbuttons" #p="ngbPopover" placement="left"
                     (mouseenter)="bordermark(p, item.state)"
                     (mouseleave)="bordermark(p, false)"
                     triggers="manual" style="{{borderStyle[item.state]||''}}"
                     [state]="item.state"
                     [model]="item"
                     class="sidebar-item-nav">
</app-item-navsidebar>
<app-item-navsidebar *ngFor="let item of (((mode!=='admin') && model.items) || [])"
                     [state]="modelname+':'+model.id+':items:'+item.id"
                     [model]="item"
                      class="sidebar-item-nav">
</app-item-navsidebar>
