<div class="header-new-year">
  <!-- <audio #myAudio autoplay loop> -->
  <audio #myAudio loop>
    <source src="../../../assets/sound/m2.mp3" type="audio/mpeg">
 </audio>
 <div class="btn-sound-play" (click)="statusPlay = !statusPlay; (!statusPlay ? myAudio.pause() : myAudio.play());" >
  <img *ngIf="!statusPlay" src="../../../assets/img/play.svg" alt="" title="С наступающим Новым Годом!">
  <img *ngIf="statusPlay" src="../../../assets/img/pause.svg" alt="" title="С наступающим Новым Годом!">
</div>
</div>
<div class="block-snow">
  <div *ngFor="let i of snow" class='snow'></div>
</div>
