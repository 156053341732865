import {Component, Input, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {AdmitemslistmenuComponent} from '../../admin/admitemslistmenu/admitemslistmenu.component';
import {RouterModule} from '@angular/router';
import {CommonModule} from '@angular/common';
import {NgbPopoverModule} from '@ng-bootstrap/ng-bootstrap';
import {ItemNavsidebarComponent} from '../item-navsidebar/item-navsidebar.component';
import {EventsService} from '../../../services/events.service';
import {DataService} from '../../../services/data.service';
import {AdminService} from '../../../services/admin.service';
import {Subscription} from 'rxjs';

@Component({
  standalone: true,
  selector: 'app-navsidebar',
  templateUrl: './navsidebar.component.html',
  styleUrls: ['./navsidebar.component.css'],
  encapsulation: ViewEncapsulation.None,
  imports: [
    AdmitemslistmenuComponent,
    RouterModule,
    CommonModule,
    NgbPopoverModule,
    ItemNavsidebarComponent,
  ]
})
export class NavSidebarComponent implements OnInit, OnDestroy {
  @Input() set navsidebar(ns) {
    console.log('NavSidebarComponent input model', ns);
    if (ns.id){
      this.model = new Object(ns);
    }
    console.log('NavSidebarComponent _navsidebar after input', this.model);
  }

  private subs: Subscription;
  message: string;
  model: any;
  modelname: string;
  borderStyle: any;
  mode: string;
  locale: string;

  constructor(
    private events: EventsService,
    private dataService: DataService,
    public admin: AdminService,
  ) {
    this.subs = new Subscription();

    this.modelname = 'menus';
    this.borderStyle = {};

    this.model = {
      id: 0,
      navsidebar: []
    };
  }

  // TODO сделать функцию типовой при загрузке данных, инициируемой событием (т.е. почти всегда)
  private reload = async (id) => {
    this.mode = this.dataService.mode;
    this.locale = this.dataService.struct.language.locale;
    console.log('this.locale', this.locale);
    if (this.dataService.struct) {
      // TODO Выяснить, какого хрена вообще проскакивает это обновление с id=0
      const data = (!!id) && (await this.dataService.getData(this.modelname, id, false));
      this.model = (data && this.mode === 'admin') ? this.admin.fillAdminModel(data, this.modelname) : data;
      console.log('= model navsidebar.component =', this.model);
    }
  }

  ngOnInit() {
    this.subs.add(this.dataService.sub().mode.subscribe(m => this.reload(this.model.id)));
    this.subs.add(
      this.events.event$.subscribe(e => {
        if ((e.name === 'reload')) {
          if (e.data === (this.modelname + ':' + this.model.id)) {
            console.log('id2h', this.model.id);
            this.reload(this.model.id);
          } else if (e.data.split(':')[0] === 'navigations') { // TODO HARDCODE
            console.log('id3h', this.model.id);
            this.reload(this.model.id);
          }
        }
      })
    );
  }

  get mobile() {
    return this.dataService.mobile;
  }

  bordermark = (p, state: string | boolean) => {
    this.borderStyle = {};
    if (state) {
      this.borderStyle['' + state] = 'border-bottom: solid 1px #7d7e92;';
      this.admin.popoverOpen(p, '' + state);
    }
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }
}

