import { Component, OnInit } from '@angular/core';
import { DataService } from '../../../services/data.service';
// import { base64ToFile } from 'ngx-image-cropper';

@Component({
  selector: 'app-adm-imgupload',
  templateUrl: './adm-imgupload.component.html'
})
export class AdmImguploadComponent implements OnInit {
  data;
  url;
  constructor(public dataService: DataService) { }

  ngOnInit(): void {
    this.url = '';
  }

  onSubDataUpdate = (e) => {
    console.log('onSubDataUpdate event', e);
    this.data = e;
  }

  upload = () => {
    this.dataService.uploadFile(this.data)
      .then((data: any) => {
        console.log('data.url', data.url);
        this.url = this.dataService.srvurl + data.url;
        navigator.clipboard.writeText(this.url);
      })
      .catch(err => console.error(err));
  }
}
