<ng-template #admbuttons let-st="state">
  <app-admitemslistmenu [state]="st"></app-admitemslistmenu>
</ng-template>

<!--<a class="nav-footer-item" [routerLink]="['/pages/', item.id]" *ngFor="let item of (((mode!=='admin') && model.items) || [])">-->
<!--  {{item.title}}-->
<!--</a>-->

<ng-container *ngFor="let item of ((mode!=='admin' && model.items) || [])">
  <a class="nav-footer-item" *ngIf="item.url && (item.url.indexOf('://') > 0)" [href]="[item.url]" target="_blank" rel="noreferrer">
    {{item.title}}
  </a>
  <a class="nav-footer-item" *ngIf="item.url && (item.url.indexOf('://') === -1)" [routerLink]="['/' + item.url]" [routerLinkActive]="['active']">
    {{item.title}}
  </a>
</ng-container>

<span class="nav-footer-item" *ngFor="let item of ((mode==='admin' && model.items) || [])"
   [ngbPopover]="admbuttons" #p="ngbPopover" placement="top"
   (mouseenter)="bordermark(p, item.state)"
   (mouseleave)="bordermark(p, false)"
   triggers="manual" style="{{(item.id < 0)? 'color: #F22C8B;' : ''}} {{borderStyle[item.state]||''}}">
  {{(item.id < 0)? ('* добавить пункт *') : item.title}}
</span>


<!-- <div class="header-nav__item">

 <div class="header-nav__item-title">

   <ng-container *ngIf="mode != 'admin'">
     <a *ngIf="model.url && (model.url.indexOf('://') > 0)" [href]="[model.url]" target="_blank" rel="noreferrer">
       {{model.title}}
     </a>
     <a *ngIf="model.url && (model.url.indexOf('://') === -1)" [routerLink]="['/' + model.url]" [routerLinkActive]="['active']">
       {{model.title}}
     </a>
     <span *ngIf="(!model.url || model.url == '')">{{model.title}}</span>
   </ng-container>

   <ng-container *ngIf="mode == 'admin'">
     <a *ngIf="model.url && model.url != ''" [routerLink]="[model.url]">{{model.title}}</a>
     <span *ngIf="(!model.url || model.url == '')">{{model.title}}</span>
   </ng-container>

   <svg *ngIf="mode == 'admin' || (model.items && model.items.length > 0)">
     <use xlink:href="#menu-arrow"></use>
   </svg>
 </div>


 <div class="header-nav__item-list" *ngIf="(model.items && model.items.length > 0) || mode === 'admin'">
   <span *ngFor="let item of ((mode=='admin' && model.items) || [])"
      [ngbPopover]="admbuttons" #p="ngbPopover" placement="left"
      (click)="admin.actionDefault(item.state)"
      (mouseenter)="bordermark(p, item.state)"
      (mouseleave)="bordermark(p, false)"
      triggers="manual" style="{{(item.style && item.style != '')? (item.style): ('')}} {{borderStyle[item.state]||''}}"
      [routerLink]="['/' + item.url]" [routerLinkActive]="['active']">
     {{(model.items && model.items.length > 0 && model.items[0].id >= 0)? (item.title): ('* добавить пункт меню *')}}
   </span>

   <ng-container *ngFor="let item of ((mode!='admin' && model.items) || [])">
     <a *ngIf="item.url && (item.url.indexOf('://') > 0)" [href]="[item.url]" target="_blank" rel="noreferrer">
       {{item.title}}
     </a>
     <a *ngIf="item.url && (item.url.indexOf('://') === -1)" [routerLink]="['/' + item.url]" [routerLinkActive]="['active']" style="{{(item.style && item.style != '') ? (item.style): ('')}}">
       {{item.title}}
     </a>
   </ng-container>

 </div>
</div> -->