import {Component, Input, OnInit} from '@angular/core';
import {NavSidebarComponent} from './navsidebar/navsidebar.component';

@Component({
  standalone: true,
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css'],
  imports: [
    NavSidebarComponent
  ],
})
export class SidebarComponent implements OnInit {
  // heightBlockSidebar: number;
  @Input() set navsidebar(ns) {
    console.log('SidebarComponent input header', ns);
    if (ns.id){
      this._navsidebar = new Object(ns);
    }
    console.log('SidebarComponent _navsidebar after input', this._navsidebar);
  }

  _navsidebar: any = {};

  constructor() {
  }

  ngOnInit() {
  }
}
