import { Injectable } from '@angular/core';
import { EventsService } from './events.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DataService } from './data.service';
import { AdmModalConfirmComponent } from '../components/admin/adm-modal-confirm/adm-modal-confirm.component';
import { AdmModalEditComponent } from '../components/admin/adm-modal-edit/adm-modal-edit.component';
import { AdmModalLinkComponent } from '../components/admin/adm-modal-link/adm-modal-link.component';
import { FormFeedbackComponent } from '../components/templates-common/form-feedback-v2/form-feedback.component';
import {AdmModalUploadComponent} from '../components/admin/adm-modal-upload/adm-modal-upload.component';
import {type} from 'jquery';

@Injectable({providedIn: 'root'})
export class AdminService {
// доступные действия с элементами
  private action$ = {
    order   : 0b1, // 2 (для дочернего) выставление очередности (реализуется через поле order в родителе случае связной модели)
    add     : 0b10, // 4 добавление нового элемента
    edit    : 0b100, // 8 редактирование
    delete  : 0b1000, // 16 удаление элемента
    lastdel : 0b10000, // 32 (указывается для дочернего, но использует родитель) удаление или отвязка последнего элемента
    linksub : 0b100000, // 64 привязать новый элемент
    unlink  : 0b1000000, // 128 отвязать элемент
    up      : 0b10000000, // 256 переместить вверх
    down    : 0b100000000, // 512 переместить вниз
    sort    : 0b1000000000, // 1024 сортировка
    upload  : 0b10000000000, // 2048 загрузка
    link    : 0b1000000000000, // добавление ссылки да элемент
  };
  private schema$ = {
    menus: {
      title: 'Меню',
      links: {
        items: {
          title: 'Главное меню',
          actions:
            this.actions.add |
            this.actions.edit |
            this.actions.order |
            this.actions.delete,
          modelname: 'navigations'
        }
      }
    },
    galeries: {
      title: 'Галерея',
      fields: [
        {
          name: 'name',
          actions: this.actions.edit,
          label: 'Введите служебное имя',
          type: 'string',
          default: 'Новая галерея',
          required: true
        },
        {
          name: 'title',
          actions: this.actions.edit,
          label: 'Введите название галереи',
          type: 'string',
          default: '',
          required: false
        },
        {
          name: 'public',
          actions: this.actions.edit,
          label: 'Опубликовать',
          type: 'checkbox',
          default: '',
          required: false
        },

      ],
      imedia: {
        mainimage: {
          actions:
            this.actions.upload |
            this.actions.delete,
          label: 'Обложка галереи',
          type: 'image',
          htmlclass: 'video_cover_img',
          required: false
        }
      },
      components: {
        items: {
          title: 'Элементы галереи',
          actions:
            this.actions.add |
            this.actions.edit |
            this.actions.order |
            this.actions.delete,
          fields: [
            {
              name: 'title',
              actions: this.actions.edit,
              label: 'Введите название',
              type: 'string',
              default: '',
              required: false
            },
            {
              name: 'url',
              actions: this.actions.edit,
              label: 'Укажите ссылку на медиа-объект (ссылки на внутренние ресурсы сайта должны быть относительными, например "/uploads/image34.png")',
              type: 'string',
              default: '',
              required: false
            },
            {
              name: 'public',
              actions: this.actions.edit,
              label: 'Опубликовать',
              type: 'checkbox',
              default: '',
              required: false
            },
          ]
        },
      },
    },
    sliders: {
      title: 'Слайдер',
      fields: [
        {
          name: 'name',
          actions: this.actions.edit,
          label: 'Введите название',
          type: 'string',
          default: '',
          required: false
        },
      ],
      components: {
        slideritem: {
          title: 'Элемент слайдера',
          actions:
            this.actions.link |
            this.actions.unlink |
            this.actions.order
        }
      },
      // TODO Костыль!!!! На самом деле у данной модели нет линков. Подключенные поля являются элементами компонентов и формируются уже программно.
      //  Эта конструкция нужна исключительно для работы adm-modal-slideritemlink в рамках стандартных инструментов движка (частично).
      //  На основании links формируется перечень доступных для подключения типов дочерних элементов.
      //  Решение: либо пересмотреть структуру данных и добиться стандартной работы со струткурой, либо прописать этот конфиг исключительно в окружении слайдера
      links: {
        galeries: {
          title: 'Галерея',
          modelname: 'galeries'
        },
        articles: {
          title: 'Статья',
          modelname: 'articles'
        },
        allnews: {
          title: 'Новость',
          modelname: 'allnews'
        },
        // doctors: {
        //   title: 'Врач',
        //   modelname: 'doctors'
        // },
        centers: {
          title: 'Центры/отделения',
          modelname: 'centers'
        },
        clinics: {
          title: 'Поликлиники',
          modelname: 'clinics'
        },
        pages: {
          title: 'Страница',
          modelname: 'pages'
        },
        externals: {
          title: 'Веб-ресурс',
          modelname: 'externals'
        }
      }
    },
    'page-units': {
      title: 'Центры и отделения',
      fields: [
        {
          name: 'basetext',
          actions: this.actions.edit,
          d_action: 'edit',
          label: 'Описание',
          type: 'text',
          default: '',
          required: false
        }
      ],
      links: {
        units: {
          actions:
          // this.actions.add |
          // this.actions.link |
          // this.actions.unlink |
            this.actions.edit |
            // this.actions.order |
            this.actions.linksub,
          // this.actions.delete,
          title: 'Центр и отделение',
          modelname: 'units',
          single: false
        }
      }
    },
    units: {
      title: 'Медицинская организация',
      fields: [
        {
          name: 'public',
          actions: this.actions.edit,
          label: 'Видим для посетителей сайта',
          type: 'checkbox',
          default: false
        },
        {
          name: 'canapoint',
          actions: this.actions.edit,
          label: 'Доступна для записи',
          type: 'checkbox',
          default: true
        },
        {
          name: 'unittype',
          actions: this.actions.edit,
          label: 'Тип подразделения',
          type: 'select',
          model: 'unittypes',
          default: '2',
          required: true
        },
        {
          name: 'name',
          actions: this.actions.edit,
          label: 'Служебное имя',
          type: 'string',
          default: 'Новая организация',
          required: true
        },
        {
          name: 'title',
          actions: this.actions.edit,
          label: 'Видимое название',
          type: 'string',
          default: 'Новая организация',
          required: true
        },
        {
          name: 'anons',
          actions: this.actions.edit,
          label: 'Введите анонс',
          type: 'text',
          default: '',
          required: false
        },
        {
          name: 'bodyText',
          actions: this.actions.edit,
          label: 'Основной тест',
          type: 'text',
          default: 'Основной текст раздела организации',
          required: true
        },
        {
          name: 'memoinfo',
          actions: this.actions.edit,
          label: 'Заполните памятку',
          type: 'text',
          default: 'Текст памятки',
          required: false
        },
        {
          name: 'worktime',
          actions: this.actions.edit,
          label: 'Режим работы',
          type: 'text',
          default: 'Заполните режим работы',
          required: false
        },
      ],
      imedia: {
        icon: {
          actions:
            this.actions.upload |
            this.actions.delete,
          label: 'Иконка организации',
          type: 'image',
          htmlclass: 'benefit_icon_img',
          required: false
        },
        bannerimage: {
          label: 'Баннер организации',
          actions:
            this.actions.upload |
            this.actions.edit |
            this.actions.delete,
          type: 'image',
          htmlclass: 'direction_img_img',
          required: false
        },
        sliderimage: {
          label: 'Изображение для слайдера',
          actions:
            this.actions.upload |
            this.actions.delete,
          type: 'image',
          htmlclass: 'big_slide',
          required: false
        },
        // Предположительно это фон
        // "mainimage": {
        //   "actions":
        //     // this.actions.upload |
        //     // this.actions.delete,
        //   "label": 'Фон',
        //   "type": 'image',
        //   "htmlclass": '',
        //   "required": false
        // }
      },
      links: {
        buildings: {
          title: 'Здание',
          actions:
            this.actions.order |
            this.actions.link |
            this.actions.unlink,
          modelname: 'buildings'
        },
        benefits: {
          title: 'Преимущество',
          actions:
            this.actions.add |
            this.actions.edit |
            this.actions.order |
            this.actions.link |
            this.actions.unlink,
          modelname: 'benefits'
        },
        feedbacks: {
          title: 'Отзыв',
          actions: this.actions.edit |
            this.actions.order |
            this.actions.link |
            this.actions.unlink,
          modelname: 'feedbacks'
        },
        articles: {
          title: 'Статья',
          actions:
            this.actions.add |
            this.actions.edit |
            this.actions.delete |
            this.actions.link |
            this.actions.unlink,
          modelname: 'articles'
        },
        qmsunits: {
          title: 'Подразделение',
          actions:
            this.actions.link |
            this.actions.unlink,
          modelname: 'qmsunits'
        },
        slider: {
          actions:
            this.actions.link |
            this.actions.unlink,
          title: 'Слайдер',
          modelname: 'sliders',
          single: true
        },
        director: {
          actions:
            this.actions.link |
            this.actions.unlink,
          title: 'Руководитель',
          modelname: 'employees',
          single: true
        },
        mediagalery: {
          title: 'Галерея',
          actions:
            this.actions.link |
            this.actions.unlink,
          modelname: 'galeries',
          single: true
        },
      },
      components: {
        seo: {
          title: 'Мета теги SEO',
          d_action: 'edit',
          actions:
            this.actions.edit,
          fields: [
            {
              name: 'title',
              actions: this.actions.edit,
              label: 'Введите заголовок',
              type: 'string',
              default: '',
              required: false
            },
            {
              name: 'keywords',
              actions: this.actions.edit,
              label: 'Введите ключевые фразы через запятую',
              type: 'string',
              default: '',
              required: false
            },
            {
              name: 'description',
              actions: this.actions.edit,
              label: 'Введите описание',
              type: 'string',
              default: '',
              required: false
            },
          ],
          single: true
        },
        phones: {
          title: 'Телефоны',
          actions:
            this.actions.add |
            this.actions.edit |
            this.actions.order |
            this.actions.delete,
          fields: [
            {
              name: 'description',
              actions: this.actions.edit,
              label: 'Введите описание телефона',
              type: 'string',
              default: '',
              required: false
            },
            {
              name: 'number',
              actions: this.actions.edit,
              label: 'Введите номер',
              type: 'string',
              default: '',
              required: false
            },
          ]
        },
        gen: ['buildings', 'benefits']
      },
    },
    'page-clinics': {
      title: 'Поликлиники',
      links: {
        clinics: {
          actions:
          // this.actions.add |
            this.actions.edit |
            // this.actions.order |
            this.actions.linksub, // |
          // this.actions.delete,
          title: 'Поликлиника',
          modelname: 'units'
        }
      }
    },
    benefits: {
      title: 'Преимущество',
      fields: [
        {
          name: 'name',
          actions: this.actions.edit,
          label: 'Введите служебное имя',
          type: 'string',
          default: 'Новое преимущество',
          required: false
        },
        {
          name: 'title',
          actions: this.actions.edit,
          label: 'Введите название преимущества',
          type: 'string',
          default: 'Название преимущества',
          required: true
        }
      ],
      imedia: {
        img: {
          actions:
            this.actions.upload |
            this.actions.delete,
          label: 'Иконка преимущества',
          type: 'image',
          htmlclass: 'benefit_icon_img',
          required: false
        }
      }
    },
    buildings: {
      title: 'Здание',
      fields: [
        {
          name: 'name',
          actions: this.actions.edit,
          label: 'Введите отображаемый адрес',
          type: 'string',
          default: 'Новое здание',
          required: false
        }
      ],
      components: {
        address: {
          single: true,
          title: 'Адрес здания',
          actions: this.actions.edit,
          fields: [
            {
              name: 'region',
              label: 'Регион',
              type: 'string',
              default: '',
              required: false
            },
            {
              name: 'city',
              label: 'Город',
              type: 'string',
              default: '',
              required: true
            },
            {
              name: 'street',
              label: 'Улица',
              type: 'string',
              default: '',
              required: true
            },
            {
              name: 'house',
              label: 'дом',
              type: 'string',
              default: '',
              required: true
            },
            {
              name: 'building',
              label: 'Строение',
              type: 'string',
              default: '',
              required: false
            }
          ],
        }
      },
    },
    navigations: {
      title: 'Пункт меню',
      fields: [
        {
          name: 'name',
          actions: this.actions.edit,
          label: 'Служебное имя',
          type: 'string',
          default: 'Новый пункт меню',
          required: true
        },
        {
          name: 'title',
          actions: this.actions.edit,
          label: 'Видимое название',
          type: 'string',
          default: 'Новый пункт меню',
          required: true
        },
        {
          name: 'url',
          actions: this.actions.edit,
          label: 'Ссылка (ссылки на внутренние ресурсы сайта должны быть относительными, например "/pages/294")',
          type: 'string',
          default: '',
          required: false
        }
      ],
      components: {
        items: {
          title: 'Подпункт меню',
          actions: this.actions.add |
            this.actions.edit |
            this.actions.order |
            this.actions.delete,
          fields: [
            {
              name: 'title',
              label: 'Видимое название',
              type: 'string',
              default: 'Новый подпункт',
              required: true
            },
            {
              name: 'url',
              label: 'Ссылка на контент (ссылки на внутренние ресурсы сайта должны быть относительными, например "/centers/8")',
              type: 'string',
              default: '',
              required: true
            }
          ],
        }
      },
      gen: ['items']
    },
    directions: {
      title: 'Направление',
      fields: [
        {
          name: 'public',
          actions: this.actions.edit,
          label: 'Опубликовано на главной странице',
          type: 'checkbox',
          default: false
        },
        {
          name: 'canapoint',
          actions: this.actions.edit,
          label: 'Доступна для записи',
          type: 'checkbox',
          default: true
        },
        {
          name: 'name',
          actions: this.actions.edit,
          label: 'Введите служебное имя',
          type: 'string',
          default: 'Новое направление',
          required: true
        },
        {
          name: 'title',
          actions: this.actions.edit,
          label: 'Введите название',
          type: 'string',
          default: '',
          required: true
        },
        {
          name: 'anons',
          actions: this.actions.edit,
          label: 'Введите анонс',
          type: 'text',
          default: '',
          required: false
        },
        {
          name: 'description',
          actions: this.actions.edit,
          d_action: 'edit',
          label: 'Введите описание',
          type: 'text',
          default: '',
          required: false
        }
      ],
      imedia: {
        smallimage: {
          label: 'Миниатюра поднаправления',
          actions:
            this.actions.upload |
            this.actions.delete,
          type: 'image',
          htmlclass: 'direction_img',
          required: false
        },
        mainimage: {
          label: 'Основной баннер направления',
          actions:
            this.actions.upload |
            this.actions.delete,
          type: 'image',
          htmlclass: 'direction_img_img',
          required: false
        },
        icon: {
          label: 'Иконка',
          actions:
            this.actions.upload |
            this.actions.delete,
          type: 'svg',
          default: '',
          // "htmlclass": 'block_directions_main_img',
          required: false
        }
      },
      links: {
        subs: {
          title: 'Подчиненное направление',
          actions:
            this.actions.add |
            this.actions.edit |
            this.actions.lastdel |
            this.actions.sort |
            this.actions.delete |
            this.actions.unlink,
          sort: 'title:asc',
          modelname: 'directions'
        },
        units: {
          title: 'Центры и поликлиники',
          actions:
          // this.actions.add |
            this.actions.edit |
            this.actions.sort |
            this.actions.link |
            this.actions.unlink,
          sort: 'title:asc',
          modelname: 'units'
        },
        doctors: {
          title: 'Врачи',
          actions:
            this.actions.link |
            this.actions.unlink,
          sort: 'lastName:asc',
          modelname: 'doctors'
        },
        services: {
          title: 'Услуги',
          actions:
            this.actions.link |
            this.actions.unlink,
          sort: 'title:asc',
          modelname: 'services'
        },
      },
      components: {
        seo: {
          title: 'Мета теги SEO',
          d_action: 'edit',
          actions:
            this.actions.edit,
          fields: [
            {
              name: 'title',
              actions: this.actions.edit,
              label: 'Введите заголовок',
              type: 'string',
              default: '',
              required: false
            },
            {
              name: 'keywords',
              actions: this.actions.edit,
              label: 'Введите ключевые фразы через запятую',
              type: 'string',
              default: '',
              required: false
            },
            {
              name: 'description',
              actions: this.actions.edit,
              label: 'Введите описание',
              type: 'string',
              default: '',
              required: false
            },
          ],
          single: true
        },
      }
    },
    headers: {
      title: 'Шапка сайта (заголовок)',
      links: {
        // navsidebar: {
        //   title: 'Главное меню',
        //   actions:
        //     this.actions.add |
        //     this.actions.edit |
        //     this.actions.order |
        //     this.actions.delete,
        //   modelname: 'navigations'
        // },
        // ,
        // maincontact: {
        //   title: 'Основные контактные данные',
        //   actions: this.actions.edit,
        //   modelname: 'main-contacts',
        //   single: true
        // }
      }
    },
    'page-directions': {
      title: 'Направления',
      links: {
        directions: {
          actions:
            this.actions.add |
            this.actions.linksub |
            this.actions.edit
            // | this.actions.order
            | this.actions.delete
          // | this.actions.unlink
          ,
          title: 'Основное направление',
          modelname: 'directions'
        }
      }
    },
    'page-mains': {
      title: 'Направления',
      links: {
        directions: {
          actions:
            this.actions.link |
            this.actions.unlink,
          title: 'Основное направление',
          modelname: 'directions'
        },
        doctors: {
          actions:
            this.actions.link |
            this.actions.unlink,
          title: 'Доктор',
          modelname: 'doctors'
        },
        slider: {
          actions:
            this.actions.link |
            this.actions.unlink,
          title: 'Слайдер',
          modelname: 'sliders',
          single: true
        }
      },
    },
    pages: {
      title: 'Страница материала',
      fields: [
        {
          name: 'landing',
          actions: this.actions.edit,
          label: 'Лендинг',
          type: 'checkbox',
          default: false,
        },
        {
          name: 'name',
          actions: this.actions.edit,
          label: 'Служебное имя',
          type: 'string',
          default: 'Новая страница',
          required: true
        },
        {
          name: 'title',
          actions: this.actions.edit,
          label: 'Видимое название',
          type: 'string',
          default: '',
          required: true
        },
        {
          name: 'slidertitle',
          actions: this.actions.edit,
          label: 'Надпись на слайдере',
          type: 'string',
          default: '',
          required: false
        },
        // {
        //   name: 'slidercolor',
        //   actions: this.actions.edit,
        //   label: 'Код цвета надписи слайдера',
        //   type: 'string',
        //   default: '',
        //   required: false
        // },
        {
          name: 'anons',
          actions: this.actions.edit,
          label: 'Анонс',
          type: 'text',
          default: '',
          required: false
        },
        {
          name: 'body',
          actions: this.actions.edit,
          label: 'Содержимое',
          type: 'text',
          default: '',
          required: false
        }
      ],
      imedia: {
        banner: {
          actions: this.actions.upload | this.actions.delete,
          label: 'Баннер материала',
          type: 'image',
          default: '/upload/123.png',
          // "htmlclass": 'direction_img_img',
          htmlclass: 'page_banner',
          required: false
        },
        sliderimage: {
          actions: this.actions.upload | this.actions.delete,
          label: 'Изображение для слайдера',
          type: 'image',
          default: '/upload/123.png',
          // "htmlclass": 'direction_img_img',
          htmlclass: 'big_slide',
          required: false
        }

      },
      components: {
        seo: {
          title: 'Мета теги SEO',
          d_action: 'edit',
          actions:
            this.actions.edit,
          fields: [
            {
              name: 'title',
              actions: this.actions.edit,
              label: 'Введите заголовок',
              type: 'string',
              default: '',
              required: false
            },
            {
              name: 'keywords',
              actions: this.actions.edit,
              label: 'Введите ключевые фразы через запятую',
              type: 'string',
              default: '',
              required: false
            },
            {
              name: 'description',
              actions: this.actions.edit,
              label: 'Введите описание',
              type: 'string',
              default: '',
              required: false
            },
          ],
          single: true
        },
        blocks: {
          title: 'Блок страницы',
          actions:
            this.actions.add |
            this.actions.edit |
            this.actions.order |
            this.actions.delete,
          d_action: 'edit',
          fields: [
            {
              name: 'blocktype',
              actions: this.actions.edit,
              label: 'Укажите тип блока',
              type: 'select',
              model: 'blocktypes',
              default: '1',
              required: true
            },
            {
              name: 'title',
              actions: this.actions.edit,
              label: 'Введите заголовок блока',
              type: 'string',
              default: 'Новый блок',
              required: false
            },
            {
              name: 'text',
              actions: this.actions.edit,
              label: 'Введите текст блока',
              type: 'text',
              default: '',
              required: false
            },
            {
              name: 'slider',
              actions: this.actions.edit,
              label: 'Введите ID слайдера (имеет значение только для типа "Слайдер")',
              type: 'slider',
              default: '',
              required: false
            },
            {
              name: 'url',
              actions: this.actions.edit,
              label: 'Введите адрес ссылки для перехода при нажатии на заголовок',
              type: 'string',
              default: '',
              required: false
            },
            // {
            //   name: 'image',
            //   actions: this.actions.edit,
            //   label: 'Медиа-объект',
            //   type: 'media',
            //   default: '',
            //   required: false
            // },
          ],
          imedia: {
            image: {
              label: 'Медиа-объект (загрузите или укажите ниже в поле ссылку)',
              type: 'media',
              actions:
                this.actions.delete |
                this.actions.edit |
                this.actions.upload,
              htmlclass: 'simple_img',
              required: false
            },
          },
        },
      }
    },
    footers: {
      links: {
        navigation: {
          title: 'Меню подвала',
          actions:
            this.actions.add |
            this.actions.edit |
            this.actions.order |
            this.actions.delete,
          modelname: 'navigations',
          single: true
        },
        pages: {
          title: 'Пункт меню подвала',
          actions:
            // this.actions.add |
            this.actions.link |
            this.actions.unlink |
            this.actions.order |
            // this.actions.delete |
            this.actions.edit,
          modelname: 'pages'
        }
      },
    },
    articles: {
      title: 'Статья',
      fields: [
        {
          name: 'title',
          actions: this.actions.edit,
          label: 'Введите заголовок статьи',
          type: 'string',
          default: '',
          required: true
        },
        {
          name: 'anonce',
          actions: this.actions.edit,
          label: 'Введите анонс',
          type: 'text',
          default: '',
          required: false
        },
        {
          name: 'basetext',
          actions: this.actions.edit,
          label: 'Введите содержимое',
          type: 'text',
          default: '',
          required: false
        },
        {
          name: 'publish_start',
          actions: this.actions.edit,
          label: 'Введите дату публикации',
          type: 'date',
          default: '2079-01-01',
          required: true
        },
        {
          name: 'public',
          actions: this.actions.edit,
          label: 'Доступно к публикации',
          type: 'checkbox',
          default: false
        },
      ],
      imedia: {
        smallimage: {
          label: 'Превью изображения',
          actions:
            this.actions.upload |
            this.actions.delete,
          type: 'image',
          htmlclass: 'slider_articles_img',
          required: false
        },
        mainimage: {
          label: 'Изображение баннера',
          actions:
            this.actions.upload |
            this.actions.delete,
          type: 'image',
          htmlclass: 'new_details_img',
          required: false
        }
      },
      components: {
        seo: {
          title: 'Мета теги SEO',
          d_action: 'edit',
          actions:
            this.actions.edit,
          fields: [
            {
              name: 'title',
              actions: this.actions.edit,
              label: 'Введите заголовок',
              type: 'string',
              default: '',
              required: false
            },
            {
              name: 'keywords',
              actions: this.actions.edit,
              label: 'Введите ключевые фразы через запятую',
              type: 'string',
              default: '',
              required: false
            },
            {
              name: 'description',
              actions: this.actions.edit,
              label: 'Введите описание',
              type: 'string',
              default: '',
              required: false
            },
          ],
          single: true
        },
      },
    },
    'page-articles': {
      title: 'Статьи',
      links: {
        articles: {
          actions:
          // this.actions.add |
            this.actions.link |
            this.actions.unlink |
            this.actions.delete |
            this.actions.edit,
          title: 'Статья',
          modelname: 'articles'
        }
      }
    },
    jottings: {
      title: 'Памятка',
      fields: [
        {
          name: 'title',
          actions: this.actions.edit,
          label: 'Введите заголовок',
          type: 'string',
          default: '',
          required: true
        },
        {
          name: 'text',
          actions: this.actions.edit,
          label: 'Введите содержимое',
          type: 'text',
          default: '',
          required: false
        },
        {
          name: 'jotting_group',
          actions: this.actions.edit,
          label: 'Укажите группу',
          type: 'select',
          model: 'jotting-groups',
          default: '',
          required: false
        },
        {
          name: 'public',
          actions: this.actions.edit,
          label: 'Доступно к публикации',
          type: 'checkbox',
          default: false
        },
      ],
      imedia: {
        url: {
          label: 'Файл памятки',
          actions:
            this.actions.delete |
            this.actions.upload,
          type: 'file',
          htmlclass: 'file',
          required: false
        },
      },
      components: {
        seo: {
          title: 'Мета теги SEO',
          d_action: 'edit',
          actions:
            this.actions.edit,
          fields: [
            {
              name: 'title',
              actions: this.actions.edit,
              label: 'Введите заголовок',
              type: 'string',
              default: '',
              required: false
            },
            {
              name: 'keywords',
              actions: this.actions.edit,
              label: 'Введите ключевые фразы через запятую',
              type: 'string',
              default: '',
              required: false
            },
            {
              name: 'description',
              actions: this.actions.edit,
              label: 'Введите описание',
              type: 'string',
              default: '',
              required: false
            },
          ],
          single: true
        },
      },
    },
    allnews: {
      title: 'Новость',
      fields: [
        {
          name: 'title',
          actions: this.actions.edit,
          label: 'Введите заголовок новости',
          type: 'string',
          default: '',
          required: true
        },
        {
          name: 'slidertitle',
          actions: this.actions.edit,
          label: 'Введите заголовок для слайдера',
          type: 'string',
          default: '',
          required: false
        },
        {
          name: 'anonce',
          actions: this.actions.edit,
          label: 'Введите анонс новости',
          type: 'text',
          default: '',
          required: false
        },
        {
          name: 'basetext',
          actions: this.actions.edit,
          label: 'Введите содержимое новости',
          type: 'text',
          default: '',
          required: false
        },
        {
          name: 'publish_start',
          actions: this.actions.edit,
          label: 'Введите дату начала публикации',
          type: 'date',
          default: '2079-01-01',
          required: true
        },
        {
          name: 'publish_end',
          actions: this.actions.edit,
          label: 'Введите дату окончания публикации',
          type: 'date',
          default: '2079-01-01',
          required: true
        },
        {
          name: 'public',
          actions: this.actions.edit,
          label: 'Доступно к публикации',
          type: 'checkbox',
          default: false
        },
        {
          name: 'common',
          actions: this.actions.edit,
          label: 'Общая новость',
          type: 'checkbox',
          default: false
        },
      ],
      imedia: {
        smallimage: {
          label: 'Превью изображения',
          actions:
            this.actions.upload |
            this.actions.delete,
          type: 'image',
          htmlclass: 'slider_articles_img',
          required: false
        },
        mainimage: {
          label: 'Изображение баннера',
          actions:
            this.actions.upload |
            this.actions.delete,
          type: 'image',
          htmlclass: 'new_details_img',
          required: false
        },
        sliderimage: {
          label: 'Изображение для слайдера',
          actions:
            this.actions.upload |
            this.actions.delete,
          type: 'image',
          htmlclass: 'big_slide',
          required: false
        }
      },
      components: {
        seo: {
          title: 'Мета теги SEO',
          d_action: 'edit',
          actions:
            this.actions.edit,
          fields: [
            {
              name: 'title',
              actions: this.actions.edit,
              label: 'Введите заголовок',
              type: 'string',
              default: '',
              required: false
            },
            {
              name: 'keywords',
              actions: this.actions.edit,
              label: 'Введите ключевые фразы через запятую',
              type: 'string',
              default: '',
              required: false
            },
            {
              name: 'description',
              actions: this.actions.edit,
              label: 'Введите описание',
              type: 'string',
              default: '',
              required: false
            },
          ],
          single: true
        },
        gallery: {
          title: 'Галерея новости',
          actions:
            this.actions.order |
            this.actions.add |
            this.actions.edit |
            this.actions.delete,
          fields: [
            {
              name: 'title',
              actions: this.actions.edit,
              label: 'Введите название',
              type: 'string',
              default: '',
              required: false
            },
          ],
          imedia: {
            url: {
              label: 'Медиа',
              actions:
                this.actions.delete |
                this.actions.edit |
                this.actions.upload,
              type: 'media',
              // htmlclass: 'new_details_img',
              htmlclass: 'simple_img',
              required: false
            },
          },
        },
      },
    },
    'page-news': { // не задействована
      title: 'Новости',
      links: {
        allnews: {
          actions:
            this.actions.add |
            this.actions.edit |
            this.actions.delete,
          title: 'Новость',
          modelname: 'allnews'
        }
      }
    },
    fnews: {
      title: 'Федеральная новость',
      fields: [
        {
          name: 'title',
          actions: this.actions.edit,
          label: 'Введите заголовок',
          type: 'string',
          default: '',
          required: true
        },
        {
          name: 'slidertitle',
          actions: this.actions.edit,
          label: 'Введите заголовок для слайдера',
          type: 'string',
          default: '',
          required: false
        },
        {
          name: 'anonce',
          actions: this.actions.edit,
          label: 'Введите анонс новости',
          type: 'text',
          default: '',
          required: false
        },
        {
          name: 'basetext',
          actions: this.actions.edit,
          label: 'Введите содержимое новости',
          type: 'text',
          default: '',
          required: false
        },
        {
          name: 'publish_start',
          actions: this.actions.edit,
          label: 'Введите дату начала публикации',
          type: 'date',
          default: '2079-01-01',
          required: true
        },
        {
          name: 'publish_end',
          actions: this.actions.edit,
          label: 'Введите дату окончания публикации',
          type: 'date',
          default: '2079-01-01',
          required: true
        },
        {
          name: 'public',
          actions: this.actions.edit,
          label: 'Доступно к публикации',
          type: 'checkbox',
          default: false
        },
      ],
      imedia: {
        smallimage: {
          label: 'Превью изображения',
          actions:
              this.actions.upload |
              this.actions.delete,
          type: 'image',
          htmlclass: 'slider_articles_img',
          required: false
        },
        mainimage: {
          label: 'Изображение баннера',
          actions:
              this.actions.upload |
              this.actions.delete,
          type: 'image',
          htmlclass: 'new_details_img',
          required: false
        },
        sliderimage: {
          label: 'Изображение для слайдера',
          actions:
              this.actions.upload |
              this.actions.delete,
          type: 'image',
          htmlclass: 'big_slide',
          required: false
        }
      },
      components: {
        seo: {
          title: 'Мета теги SEO',
          d_action: 'edit',
          actions:
            this.actions.edit,
          fields: [
            {
              name: 'title',
              actions: this.actions.edit,
              label: 'Введите заголовок',
              type: 'string',
              default: '',
              required: false
            },
            {
              name: 'keywords',
              actions: this.actions.edit,
              label: 'Введите ключевые фразы через запятую',
              type: 'string',
              default: '',
              required: false
            },
            {
              name: 'description',
              actions: this.actions.edit,
              label: 'Введите описание',
              type: 'string',
              default: '',
              required: false
            },
          ],
          single: true
        },
        gallery: {
          title: 'Галерея новости',
          actions:
              this.actions.order |
              this.actions.add |
              this.actions.edit |
              this.actions.delete,
          fields: [
            {
              name: 'title',
              actions: this.actions.edit,
              label: 'Введите название',
              type: 'string',
              default: '',
              required: false
            },
          ],
          imedia: {
            url: {
              label: 'Медиа',
              actions:
                  this.actions.delete |
                  this.actions.edit |
                  this.actions.upload,
              type: 'media',
              // htmlclass: 'new_details_img',
              htmlclass: 'simple_img',
              required: false
            },
          },
        },
      },
    },
    doctors: {
      title: 'Врач|Доктор',
      imedia: {
        mainimage: {
          label: 'Фото врача',
          actions:
            this.actions.delete |
            this.actions.upload,
          type: 'image',
          htmlclass: 'doctor_card_img_img',
          required: false
        }
      },
      fields: [
        {
          name: 'public',
          actions: this.actions.edit,
          label: 'Виден на главной странице',
          type: 'checkbox',
          default: true
        },
        {
          name: 'hidden',
          actions: this.actions.edit,
          label: 'Скрытый',
          type: 'checkbox',
          default: false
        },
        {
          name: 'lastName',
          actions: this.actions.edit,
          label: 'Введите фамилию',
          type: 'string',
          default: '',
          required: false
        },
        {
          name: 'firstName',
          actions: this.actions.edit,
          label: 'Введите имя',
          type: 'string',
          default: '',
          required: false
        },
        {
          name: 'middleName',
          actions: this.actions.edit,
          label: 'Введите отчество',
          type: 'string',
          default: '',
          required: false
        },
        {
          name: 'description',
          actions: this.actions.edit,
          label: 'Введите описание',
          type: 'text',
          default: '',
          required: false
        },
        {
          name: 'specialization',
          actions: this.actions.edit,
          label: 'Введите должность',
          type: 'string',
          default: '',
          required: false
        },
        {
          name: 'Degree',
          actions: this.actions.edit,
          label: 'Введите ученую степень',
          type: 'string',
          default: '',
          required: false
        },
      ],
      components: {
        seo: {
          title: 'Мета теги SEO',
          d_action: 'edit',
          actions:
            this.actions.edit,
          fields: [
            {
              name: 'title',
              actions: this.actions.edit,
              label: 'Введите заголовок',
              type: 'string',
              default: '',
              required: false
            },
            {
              name: 'keywords',
              actions: this.actions.edit,
              label: 'Введите ключевые фразы через запятую',
              type: 'string',
              default: '',
              required: false
            },
            {
              name: 'description',
              actions: this.actions.edit,
              label: 'Введите описание',
              type: 'string',
              default: '',
              required: false
            },
          ],
          single: true
        },
        education: {
          title: 'Образование',
          actions:
            this.actions.add |
            this.actions.edit |
            this.actions.delete,
          fields: [
            {
              name: 'edutype',
              actions: this.actions.edit,
              label: 'Укажите тип образования',
              type: 'select',
              model: 'educationtypes',
              default: '1',
              required: true
            },
            {
              name: 'educationtype',
              actions: this.actions.edit,
              label: 'Напишите вид образования (Высшее, Среднее профессиональное, Повышение квалификации)',
              type: 'string',
              default: '',
              required: false
            },
            {
              name: 'org',
              actions: this.actions.edit,
              label: 'Введите наименование организации',
              type: 'string',
              default: '',
              required: true
            },
            {
              name: 'spec',
              actions: this.actions.edit,
              label: 'Введите специальность',
              type: 'string',
              default: '',
              required: false
            },
            {
              name: 'date',
              actions: this.actions.edit,
              label: 'Укажите дату документа',
              type: 'date',
              default: null,
              required: true
            },
            {
              name: 'expiration',
              actions: this.actions.edit,
              label: 'Срок истечения',
              type: 'date',
              default: null,
              required: false
            },

            // {
            //   name: 'doctype',
            //   actions: this.actions.edit,
            //   label: 'Укажите тип документа (диплом, аккредитация и прочее)',
            //   type: 'string',
            //   default: '',
            //   required: true
            // },
            // {
            //   name: 'serial',
            //   actions: this.actions.edit,
            //   label: 'Введите серию',
            //   type: 'string',
            //   default: '',
            //   required: false
            // },
            // {
            //   name: 'number',
            //   actions: this.actions.edit,
            //   label: 'Введите номер',
            //   type: 'string',
            //   default: '',
            //   required: false
            // },
          ],
        },
        socialnets: {
          title: 'Социальные сети',
          actions:
            this.actions.add |
            this.actions.edit |
            this.actions.delete,
          fields: [
            {
              name: 'title',
              actions: this.actions.edit,
              label: 'Введите название социальной сети',
              type: 'string',
              default: '',
              required: false
            },
            {
              name: 'url',
              actions: this.actions.edit,
              label: 'Введите ссылку на профиль',
              type: 'string',
              default: '',
              required: false
            }
          ]
        },
        jobexpirience: {
          title: 'Опыт работы',
          actions:
            this.actions.add |
            this.actions.edit |
            this.actions.delete,
          fields: [
            {
              name: 'title',
              actions: this.actions.edit,
              label: 'Введите опыт работы',
              type: 'string',
              default: '',
              required: false
            }
          ]
        },
        // internships: {
        //   title: 'Стажировка',
        //   actions:
        //     this.actions.add |
        //     this.actions.edit |
        //     this.actions.delete,
        //   fields: [
        //     {
        //       name: 'title',
        //       actions: this.actions.edit,
        //       label: 'Введите стажировку',
        //       type: 'string',
        //       default: '',
        //       required: false
        //     }
        //   ]
        // },
        practical_interests: {
          title: 'Практические интересы',
          actions:
            this.actions.add |
            this.actions.edit |
            this.actions.delete,
          fields: [
            {
              name: 'title',
              actions: this.actions.edit,
              label: 'Введите практические интересы',
              type: 'string',
              default: '',
              required: false
            }
          ]
        },
      },
      links: {
        employee: {
          title: 'Сотрудник',
          actions:
            this.actions.link |
            this.actions.unlink,
          modelname: 'employees',
          single: true
        },
        articles: {
          title: 'Статьи',
          actions:
            this.actions.link |
            this.actions.unlink,
          modelname: 'articles',
          single: false
        },
        feedbacks: {
          title: 'Отзывы',
          actions:
            this.actions.link |
            this.actions.unlink,
          modelname: 'feedbacks',
          single: false
        },
        qmsdoctors: {
          title: 'Медицинские работники qMS',
          actions:
            this.actions.link |
            this.actions.unlink,
          modelname: 'qmsdoctors',
          single: false
        },
      }
    },
    employees: {
      title: 'Сотрудники учреждения',
      fields: [
        {
          name: 'public',
          actions: this.actions.edit,
          label: 'Публикуется',
          type: 'checkbox',
          default: false
        },
        {
          name: 'title',
          actions: 0,
          label: 'Видимое название',
          type: 'string',
          default: '',
          required: false
        },
        {
          name: 'lastName',
          actions: this.actions.edit,
          label: 'Введите фамилию',
          type: 'string',
          default: '',
          required: false
        },
        {
          name: 'firstName',
          actions: this.actions.edit,
          label: 'Введите имя',
          type: 'string',
          default: '',
          required: false
        },
        {
          name: 'middleName',
          actions: this.actions.edit,
          label: 'Введите отчество',
          type: 'string',
          default: '',
          required: false
        },
        {
          name: 'position',
          actions: this.actions.edit,
          label: 'Введите должность',
          type: 'string',
          default: '',
          required: false
        },
        {
          name: 'description',
          actions: this.actions.edit,
          label: 'Введите описание',
          type: 'text',
          default: '',
          required: false
        },
        {
          name: 'specialization',
          actions: this.actions.edit,
          label: 'Введите специализацию',
          type: 'string',
          default: '',
          required: false
        },
      ],
      imedia: {
        image: {
          label: 'Фото сотрудника',
          actions:
            this.actions.delete |
            this.actions.upload,
          type: 'image',
          htmlclass: 'doctor_card_img_img',
          required: false
        }
      },
      components: {
        seo: {
          title: 'Мета теги SEO',
          d_action: 'edit',
          actions:
            this.actions.edit,
          fields: [
            {
              name: 'title',
              actions: this.actions.edit,
              label: 'Введите заголовок',
              type: 'string',
              default: '',
              required: false
            },
            {
              name: 'keywords',
              actions: this.actions.edit,
              label: 'Введите ключевые фразы через запятую',
              type: 'string',
              default: '',
              required: false
            },
            {
              name: 'description',
              actions: this.actions.edit,
              label: 'Введите описание',
              type: 'string',
              default: '',
              required: false
            },
          ],
          single: true
        },
        education: {
          title: 'Образование',
          actions:
            this.actions.add |
            this.actions.edit |
            this.actions.delete,
          fields: [
            {
              name: 'doctype',
              actions: this.actions.edit,
              label: 'Укажите тип документа (диплом, аккредитация и прочее)',
              type: 'string',
              default: '',
              required: true
            },
            {
              name: 'serial',
              actions: this.actions.edit,
              label: 'Введите серию',
              type: 'string',
              default: '',
              required: false
            },
            {
              name: 'number',
              actions: this.actions.edit,
              label: 'Введите номер',
              type: 'string',
              default: '',
              required: false
            },
            {
              name: 'date',
              actions: this.actions.edit,
              label: 'Укажите дату документа',
              type: 'date',
              default: null,
              required: true
            },
          ]
        },
        socialnets: {
          title: 'Социальные сети',
          actions:
            this.actions.add |
            this.actions.edit |
            this.actions.delete,
          fields: [
            {
              name: 'title',
              actions: this.actions.edit,
              label: 'Введите название профиля',
              type: 'string',
              default: '',
              required: false
            },
            {
              name: 'url',
              actions: this.actions.edit,
              label: 'Введите ссылку на профиль',
              type: 'string',
              default: '',
              required: false
            }
          ]
        },
        phones: {
          title: 'Телефоны',
          actions:
            this.actions.add |
            this.actions.edit |
            this.actions.delete,
          fields: [
            {
              name: 'number',
              actions: this.actions.edit,
              label: 'Введите номер телефона',
              type: 'string',
              default: '',
              required: false
            },
            {
              name: 'description',
              actions: this.actions.edit,
              label: 'Введите описание',
              type: 'string',
              default: '',
              required: false
            }
          ]
        },
        email: {
          title: 'Электронная почта',
          actions:
            this.actions.add |
            this.actions.delete |
            this.actions.edit,
          fields: [
            {
              name: 'email',
              actions: this.actions.edit,
              label: 'Введите адрес электронной почты',
              type: 'string',
              default: '',
              required: false
            },
            {
              name: 'description',
              actions: this.actions.edit,
              label: 'Введите описание',
              type: 'string',
              default: '',
              required: false
            }
          ],
          single: true
        },
      },
      // "component": {
      //   "email": {
      //     "title": 'Электронная почта',
      //     "actions":
      //       this.actions.add |
      //       this.actions.delete |
      //       this.actions.edit,
      //     "fields": [
      //       {
      //         "name": 'email',
      //         "actions": this.actions.edit,
      //         "label": 'Введите адрес электронной почты',
      //         "type": 'string',
      //         "default": '',
      //         "required": false
      //       },
      //       {
      //         "name": 'description',
      //         "actions": this.actions.edit,
      //         "label": 'Введите описание',
      //         "type": 'string',
      //         "default": '',
      //         "required": false
      //       }
      //     ]
      //   },
      // }
    },
    cabinets: {
      title: 'Кабинет',
      imedia: {
        mainimage: {
          label: 'Фото',
          actions:
            this.actions.delete |
            this.actions.upload,
          type: 'image',
          htmlclass: 'doctor_card_img_img',
          required: false
        }
      },
      fields: [
        {
          name: 'title',
          actions: this.actions.edit,
          label: 'Название кабинета',
          type: 'string',
          default: '',
          required: true
        },
        {
          name: 'description',
          actions: this.actions.edit,
          label: 'Описание',
          type: 'text',
          default: '',
          required: false
        },
        {
          name: 'public',
          actions: this.actions.edit,
          label: 'Опубликован',
          type: 'checkbox',
          default: '',
          required: false
        },
      ],
    },
    services: {
      title: 'Услуга',
      fields: [
        {
          name: 'public',
          actions: this.actions.edit,
          label: 'Опубликована',
          type: 'checkbox',
          default: '',
          required: false
        },
        {
          name: 'canapoint',
          actions: this.actions.edit,
          label: 'Доступна для записи',
          type: 'checkbox',
          default: true
        },
        {
          name: 'name',
          actions: this.actions.edit,
          label: 'Название услуги',
          type: 'string',
          default: 'Новая услуга',
          required: false
        },
        {
          name: 'title',
          actions: this.actions.edit,
          label: 'Читаемое наименование',
          type: 'string',
          default: '',
          required: false
        },
        {
          name: 'anons',
          actions: this.actions.edit,
          label: 'Введите анонс описания услуги',
          type: 'text',
          default: '',
          required: false
        },
        {
          name: 'bodyText',
          actions: this.actions.edit,
          label: 'Введите описание услуги',
          type: 'text',
          default: '',
          required: false
        },
        // {
        //   name: 'jotting',
        //   actions: this.actions.edit,
        //   label: 'Заполните памятку',
        //   type: 'text',
        //   default: '',
        //   required: false
        // },
        {
          name: 'indications',
          actions: this.actions.edit,
          label: 'Введите показания',
          type: 'text',
          default: '',
          required: false
        },
        {
          name: 'contraindications',
          actions: this.actions.edit,
          label: 'Введите противопоказания',
          type: 'text',
          default: '',
          required: false
        },
      ],
      links: {
        qmsservice: {
          title: 'qmsservice',
          actions: 0,
          modelname: 'qmsservices',
          single: true,
          sort: 'namePrice:asc'
        },
        pamyatka: {
          title: 'pamyatka',
          actions: 0,
          modelname: 'jottings',
          single: true,
          sort: 'title:asc'
        },
      },
      components: {
        seo: {
          title: 'Мета теги SEO',
          d_action: 'edit',
          actions:
            this.actions.edit,
          fields: [
            {
              name: 'title',
              actions: this.actions.edit,
              label: 'Введите заголовок',
              type: 'string',
              default: '',
              required: false
            },
            {
              name: 'keywords',
              actions: this.actions.edit,
              label: 'Введите ключевые фразы через запятую',
              type: 'string',
              default: '',
              required: false
            },
            {
              name: 'description',
              actions: this.actions.edit,
              label: 'Введите описание',
              type: 'string',
              default: '',
              required: false
            },
          ],
          single: true
        },
      },
    },
    'main-contacts': { // имя должно совпадать с именем сущьности в StrApi
      title: 'Основные контакты',
      fields: [
        {
          name: 'bodyText',
          actions: this.actions.edit,
          label: 'Введите описание центра',
          type: 'text',
          default: '',
          required: false
        },
        {
          name: 'mission',
          actions: this.actions.edit,
          label: 'Введите данные о миссии и ценностях',
          type: 'text',
          default: '',
          required: false
        },
        {
          name: 'orgform',
          actions: this.actions.edit,
          label: 'Введите организационную форму',
          type: 'string',
          default: '',
          required: false
        },
        {
          name: 'title',
          actions: this.actions.edit,
          label: 'Введите полное наименование учреждения',
          type: 'string',
          default: '',
          required: false
        },
        {
          name: 'shortName',
          actions: this.actions.edit,
          label: 'Введите короткое название учреждения',
          type: 'string',
          default: '',
          required: false
        },
        {
          name: 'OGRN',
          actions: this.actions.edit,
          label: 'Введите ОГРН',
          type: 'string',
          default: '',
          required: false
        },
        {
          name: 'INN',
          actions: this.actions.edit,
          label: 'Введите ИНН',
          type: 'string',
          default: '',
          required: false
        },
        {
          name: 'reginfo',
          actions: this.actions.edit,
          label: 'Введите информацию о регистрации учреждения (дата и место)',
          type: 'string',
          default: '',
          required: false
        },
      ],
      links: {
        employees: {
          title: 'Сотрудник',
          actions:
            (
              this.actions.add
              | this.actions.delete
              | this.actions.link
              | this.actions.unlink
              | this.actions.sort
              // | this.actions.order
            ),
          modelname: 'employees',
          single: false,
          sort: 'lastName:asc'
        },
        leaders: {
          title: 'Руководитель',
          actions:
            this.actions.add |
            this.actions.delete |
            this.actions.link |
            this.actions.unlink |
            this.actions.order,
          modelname: 'employees',
          single: false
        },
      },
      components: {
        email: {
          title: 'Электронная почта',
          actions:
            this.actions.add |
            this.actions.edit |
            this.actions.delete,
          fields: [
            {
              name: 'email',
              actions: this.actions.edit,
              label: 'Введите адрес email',
              type: 'string',
              default: '',
              required: true
            },
            {
              name: 'description',
              actions: this.actions.edit,
              label: 'Введите описание',
              type: 'string',
              default: '',
              required: true
            },
          ]
        },
        socialnets: {
          title: 'Социальные сети',
          actions:
            this.actions.add |
            this.actions.edit |
            this.actions.delete,
          fields: [
            {
              name: 'title',
              actions: this.actions.edit,
              label: 'Введите название соц.сети',
              type: 'string',
              default: '',
              required: true
            },
            {
              name: 'url',
              actions: this.actions.edit,
              label: 'Укажите ссылку на профиль',
              type: 'string',
              default: '',
              required: true
            },
          ],
          links: {
            socialnet: {
              title: 'Тип социальной сети',
              actions:
                this.actions.link |
                this.actions.unlink,
              single: true
            }
          }
        },
        phones: {
          title: 'Телефоны',
          actions:
            this.actions.order |
            this.actions.add |
            this.actions.edit |
            this.actions.delete,
          fields: [
            {
              name: 'number',
              actions: this.actions.edit,
              label: 'Введите номер телефона',
              type: 'string',
              default: '',
              required: true
            },
            {
              name: 'description',
              actions: this.actions.edit,
              label: 'Напишите описание для номера',
              type: 'string',
              default: '',
              required: false
            },
          ],
        },
        phonefax: {
          single: true,
          title: 'Номер факса',
          actions: this.actions.edit,
          fields: [
            {
              name: 'number',
              actions: this.actions.edit,
              label: 'Введите номер телефона',
              type: 'string',
              default: '',
              required: false
            },
            {
              name: 'description',
              actions: this.actions.edit,
              label: 'Введите описание',
              type: 'string',
              default: '',
              required: false
            },
          ],
        },
        address: {
          single: true,
          title: 'Адрес',
          actions: this.actions.edit,
          fields: [
            {
              name: 'postcode',
              actions: this.actions.edit,
              label: 'Введите индекс',
              type: 'string',
              default: '',
              required: false
            },
            {
              name: 'region',
              actions: this.actions.edit,
              label: 'Введите название региона/края/области',
              type: 'string',
              default: '',
              required: false
            },
            {
              name: 'city',
              actions: this.actions.edit,
              label: 'Введите название города',
              type: 'string',
              default: '',
              required: false
            },
            {
              name: 'street',
              actions: this.actions.edit,
              label: 'Введите название улицы',
              type: 'string',
              default: '',
              required: true
            },
            {
              name: 'house',
              actions: this.actions.edit,
              label: 'Введите номер дома',
              type: 'string',
              default: '',
              required: true
            },
            {
              name: 'building',
              actions: this.actions.edit,
              label: 'Введите номер строения',
              type: 'string',
              default: '',
              required: false
            },
            {
              name: 'mailbox',
              actions: this.actions.edit,
              label: 'Введите номер а/я',
              type: 'string',
              default: '',
              required: false
            },
          ],
        },
        phoneoms: {
          single: true,
          title: 'Номер телефона ОМС',
          actions: this.actions.edit,
          fields: [
            {
              name: 'number',
              actions: this.actions.edit,
              label: 'Введите номер телефона',
              type: 'string',
              default: '',
              required: false
            },
            {
              name: 'description',
              actions: this.actions.edit,
              label: 'Введите описание',
              type: 'string',
              default: '',
              required: false
            },
          ],
        },
        phonedms: {
          single: true,
          title: 'Номер телефона ДМС',
          actions: this.actions.edit,
          fields: [
            {
              name: 'number',
              actions: this.actions.edit,
              label: 'Введите номер телефона',
              type: 'string',
              default: '',
              required: false
            },
            {
              name: 'description',
              actions: this.actions.edit,
              label: 'Введите описание',
              type: 'string',
              default: '',
              required: false
            },
          ],
        },
        phonestatic: {
          single: true,
          title: 'Номер телефона стационара',
          actions: this.actions.edit,
          fields: [
            {
              name: 'number',
              actions: this.actions.edit,
              label: 'Введите номер телефона',
              type: 'string',
              default: '',
              required: false
            },
            {
              name: 'description',
              actions: this.actions.edit,
              label: 'Введите описание',
              type: 'string',
              default: '',
              required: false
            },
          ],
        },
      },
    },
    'page-pharmacies': {
      title: 'Аптеки',
      links: {
        pharmacies: {
          actions:
            this.actions.add |
            this.actions.edit |
            this.actions.order |
            this.actions.linksub |
            this.actions.link |
            this.actions.unlink |
            this.actions.delete,
          title: 'Аптека',
          modelname: 'pharmacies'
        }
      }
    },
    pharmacies: {
      title: 'Аптека',
      fields: [
        {
          name: 'name',
          actions: this.actions.edit,
          label: 'Введите служебное имя',
          type: 'string',
          default: 'Новая аптека',
          required: false
        },
        {
          name: 'title',
          actions: this.actions.edit,
          label: 'Введите название аптеки',
          type: 'string',
          default: '',
          required: true
        },
        {
          name: 'anons',
          actions: this.actions.edit,
          label: 'Анонс',
          type: 'text',
          default: '',
          required: false
        },
        {
          name: 'body',
          actions: this.actions.edit,
          label: 'Введите полное описание',
          type: 'text',
          default: '',
          required: false
        },
        {
          name: 'worktime',
          actions: this.actions.edit,
          label: 'Введите дни и время работы аптеки',
          type: 'string',
          default: '',
          required: false
        }
      ],
      imedia: {
        icon: {
          actions:
            this.actions.upload |
            this.actions.delete,
          label: 'Иконка аптеки',
          type: 'image',
          htmlclass: 'benefit_icon_img',
          required: false
        },
        bannerimage: {
          actions:
            this.actions.upload |
            this.actions.delete,
          label: 'Баннер',
          type: 'image',
          htmlclass: 'direction_img_img',
          required: false
        }
      },
      links: {
        employees: {
          title: 'Сотрудники аптеки',
          actions:
            this.actions.add |
            this.actions.delete |
            this.actions.link |
            this.actions.unlink,
          modelname: 'employees',
          single: false
        },
        feedbacks: {
          title: 'Отзывы аптеки',
          actions:
            this.actions.link |
            this.actions.unlink,
          modelname: 'feedbacks',
          single: false
        },
        buildings: {
          title: 'Здание',
          actions:
            this.actions.order |
            this.actions.unlink |
            this.actions.link,
          modelname: 'buildings',
          single: false
        },
        slider: {
          actions:
            this.actions.link |
            this.actions.unlink,
          title: 'Слайдер',
          modelname: 'sliders',
          single: true
        },
      },
      components: {
        seo: {
          title: 'Мета теги SEO',
          d_action: 'edit',
          actions:
            this.actions.edit,
          fields: [
            {
              name: 'title',
              actions: this.actions.edit,
              label: 'Введите заголовок',
              type: 'string',
              default: '',
              required: false
            },
            {
              name: 'keywords',
              actions: this.actions.edit,
              label: 'Введите ключевые фразы через запятую',
              type: 'string',
              default: '',
              required: false
            },
            {
              name: 'description',
              actions: this.actions.edit,
              label: 'Введите описание',
              type: 'string',
              default: '',
              required: false
            },
          ],
          single: true
        },
        phones: {
          title: 'Телефоны',
          actions:
            this.actions.add |
            this.actions.edit |
            this.actions.order |
            this.actions.delete,
          fields: [
            {
              name: 'description',
              actions: this.actions.edit,
              label: 'Введите описание телефона',
              type: 'string',
              default: '',
              required: false
            },
            {
              name: 'number',
              actions: this.actions.edit,
              label: 'Введите номер',
              type: 'string',
              default: '',
              required: false
            },
          ]
        },
        gen: ['buildings', 'benefits']
      },
    },
    feedbacks: {
      title: 'Отзывы',
      fields: [
        // {
        //   name: 'author',
        //   actions: 0,
        //   label: 'Автор отзыва',
        //   type: 'string',
        //   default: '',
        //   required: false
        // },
        {
          name: 'author',
          actions: this.actions.edit,
          label: 'Автор отзыва',
          type: 'string',
          default: '',
          required: true
        },
        {
          name: 'text',
          actions: 0,
          label: 'Текст отзыва',
          type: 'text',
          default: '',
          required: false
        },
        {
          name: 'moderate',
          actions: this.actions.edit,
          label: 'Модерированный текст',
          type: 'text',
          default: '',
          required: true
        },
        {
          name: 'made',
          actions: 0,
          label: 'Дата отзыва',
          type: 'date',
          default: '',
          required: false
        },
        {
          name: 'public',
          actions: this.actions.edit,
          label: 'Опубликовано',
          type: 'checkbox',
          default: false
        },
        {
          name: 'type',
          actions: 0,
          label: 'Вид отзыва (хороший / плохой / предложение)',
          type: 'string',
          default: false
        },
        {
          name: 'email',
          actions: 0,
          label: 'Электронная почта',
          type: 'string',
          default: false
        },
        {
          name: 'phone',
          actions: 0,
          label: 'Телефон',
          type: 'string',
          default: false
        },
      ],
      links: {
        doctors: {
          title: 'Врачи',
          actions:
            this.actions.link |
            this.actions.unlink,
          modelname: 'doctors',
          single: false
        },
        units: {
          title: 'Подразделения',
          actions:
            this.actions.link |
            this.actions.unlink,
          modelname: 'units',
          single: false
        },
      },
      components: {
        // seo: {
        //   title: 'Мета теги SEO',
        //   d_action: 'edit',
        //   actions:
        //     this.actions.edit,
        //   fields: [
        //     {
        //       name: 'title',
        //       actions: this.actions.edit,
        //       label: 'Введите заголовок',
        //       type: 'string',
        //       default: '',
        //       required: false
        //     },
        //     {
        //       name: 'keywords',
        //       actions: this.actions.edit,
        //       label: 'Введите ключевые фразы через запятую',
        //       type: 'string',
        //       default: '',
        //       required: false
        //     },
        //     {
        //       name: 'description',
        //       actions: this.actions.edit,
        //       label: 'Введите описание',
        //       type: 'string',
        //       default: '',
        //       required: false
        //     },
        //   ],
        //   single: true
        // },
      },
    },
    externals: {
      title: 'Веб-ресурс',
      imedia: {
        sliderimage: {
          label: 'Изображение для слайдера',
          actions:
            this.actions.delete |
            this.actions.upload,
          type: 'image',
          htmlclass: 'big_slide',
          required: false
        }
      },
      fields: [
        {
          name: 'name',
          actions: this.actions.edit,
          label: 'Введите служебное имя',
          type: 'string',
          default: '',
          required: true
        },
        {
          name: 'title',
          actions: this.actions.edit,
          label: 'Введите имя',
          type: 'string',
          default: '',
          required: true
        },
        {
          name: 'url',
          actions: this.actions.edit,
          label: 'Введите адрес ссылки',
          type: 'string',
          default: '',
          required: true
        }
      ],
    },
    faqs: {
      title: 'Вопрос-ответ',
      fields: [
        {
          name: 'name',
          actions: this.actions.edit,
          label: 'Введите имя',
          type: 'string',
          default: '',
          required: true
        },
        {
          name: 'email',
          actions: this.actions.edit,
          label: 'Введите email',
          type: 'string',
          default: '',
          required: false
        },
        {
          name: 'phone',
          actions: this.actions.edit,
          label: 'Введите телефон',
          type: 'string',
          default: '',
          required: false
        },
        {
          name: 'question',
          actions: this.actions.edit,
          label: 'Введите вопрос',
          type: 'text',
          default: '',
          required: true
        },
        {
          name: 'answer',
          actions: this.actions.edit,
          label: 'Введите ответ',
          type: 'text',
          default: '',
          required: false
        },
        {
          name: 'public',
          actions: this.actions.edit,
          label: 'Доступно к публикации',
          type: 'checkbox',
          default: false
        },
      ],
      links: {
        doctor: {
          title: 'Доктор',
          actions:
            this.actions.link |
            this.actions.unlink,
          modelname: 'doctors',
          single: true
        },
      }
    },
    'page-faqs': {
      title: 'Вопрос-ответы',
      links: {
        faqs: {
          actions:
          this.actions.order,
          title: 'Вопрос-ответ',
          modelname: 'faqs'
        },
      }
    },
    institutes: {
      title: 'Институт',
      imedia: {
        logo: {
          label: 'Логотип института',
          actions:
            this.actions.delete |
            this.actions.upload,
          type: 'image',
          htmlclass: 'doctor_card_img_img',
          required: false
        }
      },
      fields: [
        {
          name: 'title',
          actions: this.actions.edit,
          label: 'Введите название института',
          type: 'string',
          default: '',
          required: true
        },
      ],
    },
    researchers: {
      title: 'Исследователь',
      imedia: {
        mainimage: {
          label: 'Фото научного сотрудника',
          actions:
            this.actions.delete |
            this.actions.upload,
          type: 'image',
          htmlclass: 'doctor_card_img_img',
          required: false
        },
        testfile: {
          label: 'Тестовый файл',
          actions:
            this.actions.delete |
            this.actions.upload,
          type: 'file',
          htmlclass: 'file',
          required: false
        }
      },
      fields: [
        {
          name: 'lastName',
          actions: this.actions.edit,
          label: 'Введите фамилию',
          type: 'string',
          default: '',
          required: false
        },
        {
          name: 'firstName',
          actions: this.actions.edit,
          label: 'Введите имя',
          type: 'string',
          default: '',
          required: false
        },
        {
          name: 'middleName',
          actions: this.actions.edit,
          label: 'Введите отчество',
          type: 'string',
          default: '',
          required: false
        },
        {
          name: 'degree',
          actions: this.actions.edit,
          label: 'Введите ученую степень',
          type: 'string',
          default: '',
          required: false
        },
        {
          name: 'position',
          actions: this.actions.edit,
          label: 'Введите должность',
          type: 'string',
          default: '',
          required: false
        },
        {
          name: 'insturl',
          actions: this.actions.edit,
          label: 'Ссылка на профиль',
          type: 'string',
          default: '',
          required: false
        },
      ],
      links: {
        employee: {
          single: true,
          required: true,
          title: 'Cотрудник',
          label: 'Привязанный сотрудник',
          actions:
            this.actions.link,
          modelname: 'employees',
          fields: [
            {
              name: 'lastName',
              actions: this.actions.edit,
              label: 'Введите фамилию',
              type: 'string',
              default: '',
              required: false
            },
            {
              name: 'firstName',
              actions: this.actions.edit,
              label: 'Введите имя',
              type: 'string',
              default: '',
              required: false
            },
            {
              name: 'middleName',
              actions: this.actions.edit,
              label: 'Введите отчество',
              type: 'string',
              default: '',
            },
          ],
        },
        institute: {
          single: true,
          // "title": "Институт",
          actions:
            this.actions.link |
            this.actions.add |
            this.actions.edit |
            this.actions.delete |
            this.actions.unlink,
          modelname: 'institutes',
        },
        allnews: {
          single: false,
          title: 'Новости',
          actions:
            this.actions.add |
            this.actions.delete |
            this.actions.link |
            this.actions.unlink,
          modelname: 'allnews',
        },
        articles: {
          // single: false,
          title: 'Статьи',
          actions:
            this.actions.add |
            this.actions.delete |
            this.actions.link |
            this.actions.unlink,
          view: {
            fields: [
              'title',
              'publish_start'
            ],
            sort: 'publish_start:desc'
          },
          modelname: 'articles',
        },
        researches: {
          fields: [
            {
              name: 'title',
              actions: this.actions.edit,
              label: 'Введите название исследования',
              type: 'string',
              default: '',
              required: true
            },
            {
              name: 'public',
              actions: this.actions.edit,
              label: 'Доступно к публикации',
              type: 'checkbox',
              default: false
            },
            {
              name: 'goszakaz',
              actions: this.actions.edit,
              label: 'Выполняется по госзаказу',
              type: 'checkbox',
              default: false
            },
          ],
          single: false,
          title: 'Научная работа',
          actions:
            // this.actions.add |
            // this.actions.delete |
            this.actions.link |
            this.actions.unlink,
          modelname: 'research',
        },
        coresearches: {
          single: false,
          title: 'Работы в соавторстве',
          actions:
            this.actions.link |
            this.actions.unlink,
          modelname: 'research',
        },
      }
    },
    research: {
      title: 'Исследования',
      fields: [
        {
          name: 'title',
          actions: this.actions.edit,
          label: 'Введите название исследования',
          type: 'string',
          default: '',
          required: true
        },
        {
          name: 'description',
          actions: this.actions.edit,
          label: 'Введите описание исследования',
          type: 'text',
          default: '',
          required: false
        },
        {
          name: 'public',
          actions: this.actions.edit,
          label: 'Доступно к публикации',
          type: 'checkbox',
          default: false
        },
        {
          name: 'goszakaz',
          actions: this.actions.edit,
          label: 'Выполняется по госзаказу',
          type: 'checkbox',
          default: false
        },
      ],
      links: {
        researcher: {
          single: true,
          title: 'Руководитель исследования',
          actions:
            this.actions.link |
            this.actions.unlink |
            this.actions.linksub,
          modelname: 'researchers',
        },
        coresearchers: {
          single: false,
          title: 'Соавтор исследования',
          actions:
            this.actions.link |
            this.actions.unlink,
          modelname: 'researchers',
        },
      }
    },
    'page-researches': {
      title: 'Исследования',
      fields: [
        {
          name: 'title',
          actions: this.actions.edit,
          label: 'Введите название исследования',
          type: 'string',
          default: '',
          required: true
        },
        {
          name: 'description',
          actions: this.actions.edit,
          label: 'Введите описание исследования',
          type: 'text',
          default: '',
          required: false
        },
        {
          name: 'goszakaz',
          actions: this.actions.edit,
          label: 'Выполняется по госзаказу',
          type: 'checkbox',
          default: false
        },
      ],
      links: {
        researches: {
          single: false,
          title: 'Исследование',
          actions:
            this.actions.add |
            this.actions.delete |
            this.actions.link |
            this.actions.unlink |
            this.actions.linksub |
            this.actions.order,
          modelname: 'research'
        },
      }
    },
    'research-units': {
      title: 'Научное направление',
      fields: [
        {
          name: 'name',
          actions: this.actions.edit,
          label: 'Служебное имя',
          type: 'string',
          default: '',
          required: true
        },
        {
          name: 'title',
          actions: this.actions.edit,
          label: 'Заголовок',
          type: 'string',
          default: '',
          required: true
        },
        {
          name: 'description',
          actions: this.actions.edit,
          d_action: 'edit',
          label: 'Описание',
          type: 'text',
          default: '',
          required: false
        },
        {
          name: 'struct',
          actions: this.actions.edit,
          d_action: 'edit',
          label: 'Структура',
          type: 'text',
          default: '',
          required: false
        },
        {
          name: 'docs',
          actions: this.actions.edit,
          d_action: 'edit',
          label: 'Документы',
          type: 'text',
          default: '',
          required: false
        },
        {
          name: 'parthners',
          d_action: 'edit',
          actions: this.actions.edit,
          label: 'Партнеры',
          type: 'text',
          default: '',
          required: false
        },
      ],
      links: {
        slider: {
          actions:
            this.actions.link |
            this.actions.unlink,
          title: 'Слайдер',
          modelname: 'sliders',
          single: true
        },
        documents: {
          actions:
            this.actions.link |
            this.actions.unlink,
          title: 'Галерея документов',
          modelname: 'galeries',
          single: true
        },

        // "translations": {
        //   "title": 'Перевод',
        //   "actions":
        //     this.actions.order |
        //     this.actions.link |
        //     this.actions.unlink,
        //   "modelname": "research-units"
        // },
        // "baselangver": {
        //   "title": 'Основной язык',
        //   "actions":
        //     this.actions.order |
        //     this.actions.link |
        //     this.actions.unlink,
        //   "modelname": "research-units"
        // },
      },
    },
  };

  private popover: any;

  constructor(
    private modalService: NgbModal,
    private events: EventsService,
    private dataService: DataService,
  ) {
    events.event$.subscribe(e => this.eventFactory(e));
  }

  /**
   * Протокол взаимодействия (e.data)
   * Части протокола разделены двоеточием:
   * 0  Имя модели основного/родительского элемента;
   * 1  ID основного/родительского элемента;
   * 2  Название поля целевого дочернего элемента;
   * 3  ID целевого дочернего элемента в случае составного (links, components);
   * 4  Маска набора доступных действий для целевого дочернего элемента (AdminService.actions);
   * 5  Режим работы (mode)
   */

  private eventFactory = (e) => {
    console.log('Event on AdminService Run!', e.data);
    const tmp = e.data.split(':');
    const baseSchema = this.schema$[tmp[0]];
    switch (e.name) {
      case 'add': {
        console.log('Add params!', e.data);

        if (baseSchema.links && baseSchema.links[tmp[2]]) {
          const modelname = baseSchema.links[tmp[2]].modelname;
          const schema: {title: string; fields: any[]; links: any; components: any; imedia: any[]; component: any; } = this.schema$[modelname];
          const postobj: any = {};

          schema.fields.forEach( item => {
            if (item.name === 'title'){
              postobj[item.name] = 'Hовый элемент "' + schema.title + '"';
            }else if (item.name === 'public'){
              postobj[item.name] = item.default || false;
            }
            else{
              postobj[item.name] = item.default || '';
            }
          });

          if (schema.component){
            for (const cmpkey of Object.keys(schema.component)){
              if (schema.component[cmpkey].fields){
                if (!postobj[cmpkey]){
                  postobj[cmpkey] = {};
                }
                for (const field of schema.component[cmpkey].fields){
                  postobj[cmpkey][field.name] = field.default || null;
                }
              }
            }
          }

          // (schema.gen && schema.components && Object.keys(schema.components).forEach(item => {
          //   if (schema.gen.includes(item)){
          //     if (!postobj[item])
          //     {
          //       postobj[item] = [];
          //     }
          //     const t = {};
          //     schema.components[item].fields.forEach(field => {
          //       if (field.default) {
          //         t[field.name] = field.default;
          //       }
          //     });
          //     postobj[item].push(t);
          //   }
          // }));

          postobj.city = this.dataService.struct.city.id;
          postobj.language = this.dataService.struct.language.id;

          this.dataService.addData(modelname, postobj)
            .then((postres: any) => {
              this.dataService.getData(tmp[0], +tmp[1], {})
                .then((getres: any) => {
                  const order = getres.order || {};
                  const subs = getres[tmp[2]].map(item => item.id);
                  subs.push(postres.id);

                  order[tmp[2]] = subs; // TODO нужно оценить, зависит ли это от переданного параметра actions
                  const putobj = {order};

                  putobj[tmp[2]] = subs;
                  this.dataService.putData(tmp[0], putobj, +tmp[1])
                    .then(putres => {
                      this.events.send({name: 'reload', data: (tmp[0] + ':' + tmp[1])});
                    })
                    .catch(err => console.error(err));
                })
                .catch(err => console.error(err));
            })
            .catch(err => console.error(err));
        }

        else if (baseSchema.components && baseSchema.components[tmp[2]]){
          // Если не было указано имени модели, значит имеем дело со списком компонентов
          const schema: {title: string; fields: any[]; links: any; components: any; imedia: any[]; component: any; } = this.schema$[tmp[0]].components[tmp[2]];
          this.dataService.getData(tmp[0], +tmp[1], {})
            .then(getres => {
              const obj = {};
              const putobj = {};

              schema.fields.forEach(field => {
                if (field.default) {
                  obj[field.name] = field.default;
                }
              });

              getres[tmp[2]] = getres[tmp[2]].map(item => {
                delete item.id;
                return item;
              });
              getres[tmp[2]].push(obj);
              putobj[tmp[2]] = getres[tmp[2]];

              this.dataService.putData(tmp[0], putobj, + tmp[1])
                .then(putres => this.events.send({name: 'reload', data: (tmp[0] + ':' + tmp[1])}))
                .catch(err => console.error(err));
            })
            .catch(err => console.error(err));
        }
        else{
          alert('Ошибка схемы. Добавление элемента невозможно! Обратитесь к разработчику.');
        }
        break;
      }
      case 'linksub': {
        const modelname = baseSchema.links[tmp[2]].modelname;
        const schema: {title: string; fields: any[]; links: any; components: any; imedia: any[]; component: any; } = this.schema$[modelname];

        const modalRef = this.modalService.open(AdmModalLinkComponent, { size: 'xl', scrollable: true });
        modalRef.componentInstance.schema = schema;
        modalRef.componentInstance.modelname = modelname;
        modalRef.componentInstance.id = +tmp[3];

        modalRef.result.then(result => {
          if (result) {
            this.subItemLink(modelname, +tmp[3], result[0].field, result[1])
              .then(res => {
                this.events.send({name: 'reload', data: (tmp[0] + ':' + tmp[1])});
              })
              .catch(err => console.error(err));
          }
        }, disResult => {
          // console.log('disResult', disResult);
        });
        break;
      }
      case 'link': {
        const modelname = tmp[0];
        const schema: {title: string; fields: any[]; links: any; components: any; imedia: any[]; component: any; } = this.schema$[modelname];

        const modalRef = this.modalService.open(AdmModalLinkComponent, { size: 'xl', scrollable: true });
        modalRef.componentInstance.schema = schema;
        modalRef.componentInstance.modelname = modelname;
        modalRef.componentInstance.id = +tmp[1];
        modalRef.componentInstance.field = tmp[2];

        modalRef.result.then(result => {
          if (result) {
            this.subItemLink(modelname, +tmp[1], result[0].field, result[1])
              .then(res => {
                this.events.send({name: 'reload', data: (tmp[0] + ':' + tmp[1])});
              })
              .catch(err => console.error(err));
          }
        }, disResult => {
          // console.log('disResult', disResult);
        });
        break;
      }
      case 'edit': {
        const modelname = (baseSchema.links && baseSchema.links[tmp[2]] && baseSchema.links[tmp[2]].modelname);
        const schema: {title: string; fields: any[]; links: any; components: any; imedia: any[]; } = this.schema$[modelname || tmp[0]];
        const modalRef = this.modalService.open(AdmModalEditComponent, { size: 'xl' });
        modalRef.componentInstance.data = e.data;
        modalRef.componentInstance.schema = schema;
        modalRef.componentInstance.modelname = modelname;
        modalRef.result.then(result => {
          if (result) {
            console.log('Данные для изменений', result);

            if (modelname) {
              //TODO внести обработку поля, содержащего тип select. Сохранять только ID
              this.dataService.putData(modelname, result, +tmp[3]).then(res => {
                this.events.send({name: 'reload', data: (tmp[0] + ':' + tmp[1])});
              });
            }else{
              // TODO пересмотреть подход!!! это не сработает для обычного поля, которое не вложенное подмножество
              this.dataService.getData(tmp[0], +tmp[1], {})
                .then(async (getres) => {
                  const putobj = {};
                  const components = schema.components && schema.components[tmp[2]];
                  console.log('edit result', result);
                  console.log('components', components);
                  if (components){
                    if (!components.single){
                      console.log('= start components.single =');
                      putobj[tmp[2]] = [];
                      for (const item of getres[tmp[2]]){
                        if (+item.id === +tmp[3]){
                          for (const key of Object.keys(result)){
                            let url = '';
                            const t = components.fields && components.fields.filter(f => f.name === key)[0];
                            const type = t && t.type;

                            if (components.imedia && components.imedia[key]){
                              console.log('WTF????', result[key]);
                              if (typeof result[key] === 'string'){
                                url = result[key];
                              } else {
                                console.log('not string!');
                                url = (await (this.dataService.uploadFile(result[key])
                                  .then((data: any) => data && data.url)
                                  .catch(err => console.error(err))));
                              }
                              console.log('url', url);
                              item[key] = url;
                              console.log('item[key]', key, item[key]);
                            }
                            else{
                              console.log('item[key]', key, item[key]);
                              item[key] = result[key];
                            }
                            console.log('result[key]', result, result[key]);
                          }
                        }
                        delete item.id;
                        putobj[tmp[2]].push(item);
                      }
                      // putobj[tmp[2]] = getres[tmp[2]].map(async (item) => {
                      //   if (+item.id === +tmp[3]){
                      //     for (const key of Object.keys(result)){
                      //       let url = '';
                      //       if (components.imedia && components.imedia[key]){
                      //         url = (await (this.dataService.uploadFile(result[key])
                      //           .then((data: any) => data && data.url)
                      //           .catch(err => console.error(err))));
                      //         console.log('url', url);
                      //         item[key] = url;
                      //         console.log('item[key]', key, item[key]);
                      //       }else{
                      //         console.log('item[key]', key, item[key]);
                      //         item[key] = result[key];
                      //       }
                      //       console.log('result[key]', result, result[key]);
                      //     }
                      //   }
                      //   delete item.id;
                      //   return item;
                      // });
                    }else{
                      putobj[tmp[2]] = result;
                      delete putobj[tmp[2]].id;
                    }
                  }
                  else{
                    putobj[tmp[2]] = result[tmp[2]];
                  }

                  this.dataService.putData(tmp[0], putobj, +tmp[1])
                    .then(putres => {
                      this.events.send({name: 'reload', data: (tmp[0] + ':' + tmp[1])});
                    })
                    .catch(err => console.error(err));
                })
                .catch(err => console.error(err));
            }
          }
        }, disResult => {
          // console.log('disResult', disResult);
        });
        break;
      }
      case 'delete': {
        const modalRef = this.modalService.open(AdmModalConfirmComponent);
        modalRef.componentInstance.submitButtonText = 'Удалить';
        modalRef.componentInstance.baseText = 'Вы уверены, что хотите удалить элемент?';
        modalRef.componentInstance.subText = 'Прим.: После удаления ничего невозможно вернуть';
        modalRef.result.then(result => {
          if (result) {
            const subModelName = baseSchema.links && baseSchema.links[tmp[2]] && baseSchema.links[tmp[2]].modelname;
            this.subItemDelete(tmp[0], tmp[1], tmp[2], tmp[3], subModelName);
          }
        }, disResult => {
          // console.log('disResult', disResult);
        });
        break;
      }
      case 'unlink': {
        const modalRef = this.modalService.open(AdmModalConfirmComponent);
        modalRef.componentInstance.submitButtonText = 'Отвязать';
        modalRef.componentInstance.baseText = 'Вы уверены, что хотите отвязать элемент?';
        modalRef.result.then(result => {
          if (result) {
            // console.log('Params for subItemUnlink', tmp);
            this.subItemUnlink(tmp[0], tmp[1], tmp[2], tmp[3]);
          }
        }, disResult => {
          // console.log('disResult', disResult);
        });
        break;
      }
      case 'up': {
        const modelname = baseSchema.links && baseSchema.links[tmp[2]] && baseSchema.links[tmp[2]].modelname;
        if (modelname) {
          console.log('set adminServiceUp spinner true');
          this.dataService.spinner = true;
          this.dataService.getData(tmp[0], +tmp[1], {})
            .then((data: any) => {
              const i = data.order[tmp[2]].findIndex((element, index, array) => +element === +tmp[3]);
              const t = data.order[tmp[2]][i];
              data.order[tmp[2]][i] = data.order[tmp[2]][i - 1];
              data.order[tmp[2]][i - 1] = t;
              this.dataService.putData(tmp[0], {order: data.order}, +tmp[1])
                .then(res => {
                  this.dataService.spinner = false;
                  this.events.send({name: 'reload', data: (tmp[0] + ':' + tmp[1])});
                })
                .catch(err => console.error(err));
            })
            .catch(err => console.error(err));
        }else{
          // Если модель не опеределена, то необходимо в родителе менять содержимое поля
          this.dataService.spinner = true;
          this.dataService.getData(tmp[0], tmp[1], {})
            .then((data: any) => {
              const i = data[tmp[2]].findIndex((element, index, array) => +element.id === +tmp[3]);
              const t = data[tmp[2]][i];
              data[tmp[2]][i] = data[tmp[2]][i - 1];
              data[tmp[2]][i - 1] = t;
              const putobj = {};
              putobj[tmp[2]] = data[tmp[2]];
              this.dataService.putData(tmp[0], putobj, tmp[1])
                .then(res => {
                  this.dataService.spinner = false;
                  this.events.send({name: 'reload', data: (tmp[0] + ':' + tmp[1])});
                })
                .catch(err => console.error(err));
            })
            .catch(err => console.error(err));
        }
        break;
      }
      case 'down': {
        const modelname = baseSchema.links && baseSchema.links[tmp[2]] && baseSchema.links[tmp[2]].modelname;
        if (modelname) {
          this.dataService.getData(tmp[0], +tmp[1], {})
            .then((data: any) => {
              if (!data.order[tmp[2]] || !data.order[tmp[2]].length){
                data.order[tmp[2]] = data[tmp[2]].map(item => item.id);
              }
              const i = data.order[tmp[2]].findIndex((element, index, array) => +element === +tmp[3]);
              const t = data.order[tmp[2]][i];
              data.order[tmp[2]][i] = data.order[tmp[2]][i + 1];
              data.order[tmp[2]][i + 1] = t;
              this.dataService.putData(tmp[0], {order: data.order}, +tmp[1])
                .then(res => {
                  this.events.send({name: 'reload', data: (tmp[0] + ':' + tmp[1])});
                })
                .catch(err => console.error(err));
            })
            .catch(err => console.error(err));
        }else{
          // Если модель не опеределена, то необходимо в родителе менять содержимое поля
          this.dataService.getData(tmp[0], +tmp[1], {})
            .then((data: any) => {
              const i = data[tmp[2]].findIndex((element, index, array) => +element.id === +tmp[3]);
              const t = data[tmp[2]][i];
              data[tmp[2]][i] = data[tmp[2]][i + 1];
              data[tmp[2]][i + 1] = t;
              const putobj = {};
              putobj[tmp[2]] = data[tmp[2]];
              this.dataService.putData(tmp[0], putobj, +tmp[1])
                .then(res => {
                  this.events.send({name: 'reload', data: (tmp[0] + ':' + tmp[1])});
                })
                .catch(err => console.error(err));
            })
            .catch(err => console.error(err));
        }
        break;
      }
      case 'sort': {
        const modelname = baseSchema.links && baseSchema.links[tmp[2]] && baseSchema.links[tmp[2]].modelname;
        console.log('modelname for sorting', modelname);
        if (modelname) {
          this.dataService.getData(tmp[0], +tmp[1], {})
            .then((data: any) => {
              data.order[tmp[2]] = [baseSchema.links[tmp[2]].sort || 'title:asc'];
              console.log('data.order', data.order);
              this.dataService.putData(tmp[0], {order: data.order}, +tmp[1])
                .then(res => {
                  this.events.send({name: 'reload', data: (tmp[0] + ':' + tmp[1])});
                })
                .catch(err => console.error(err));
            })
            .catch(err => console.error(err));
        }else{
          // Если модель не опеределена, то необходимо в родителе менять содержимое поля
          this.dataService.getData(tmp[0], +tmp[1], {})
            .then((data: any) => {
              const putobj = {};
              putobj[tmp[2]] = data[tmp[2]].sort((a, b) => a.title.toLowerCase().localeCompare(b.title.toLowerCase()));
              this.dataService.putData(tmp[0], putobj, +tmp[1])
                .then(res => {
                  this.events.send({name: 'reload', data: (tmp[0] + ':' + tmp[1])});
                })
                .catch(err => console.error(err));
            })
            .catch(err => console.error(err));
        }
        break;
      }
      case 'upload': {
        // TODO Загружаемые данные должны прилететь в форме. Дальше нужно определить их тип (файл или блоб)
        //  и загрузить на сервер с последующей привязкой ссылки к объекту
        const modelname = baseSchema.links && baseSchema.links[tmp[2]] && baseSchema.links[tmp[2]].modelname;
        const schema: {title: string; fields: any[]; links: any; components: any; imedia: any[]; } = this.schema$[modelname || tmp[0]];
        const modalRef = this.modalService.open(AdmModalUploadComponent, { size: 'xl' });
        modalRef.componentInstance.data = e.data;
        modalRef.componentInstance.schema = schema;
        modalRef.componentInstance.modelname = modelname;
        modalRef.result.then(result => {
          console.log('schema', schema);
          console.log('e.data', e.data);
          if (result) {
            if (schema.imedia && schema.imedia[tmp[2]]){
              if (schema.imedia[tmp[2]].type === 'image' || schema.imedia[tmp[2]].type === 'file' || schema.imedia[tmp[2]].type === 'media'){
                this.upload(result[tmp[2]], e.data);
              }
              // else if (schema.imedia[tmp[2]].type === 'svg'){
              //   this.setSelected(result, e.data);
              // }
              else{
                alert('Внимание! Тип медиафайла не определен в схеме. Сообщите разработчикам.');
              }
            }
          }
        }, disResult => {
          // console.log('disResult', disResult);
        });
        break;
      }
      case 'feedback': {
        // TODO запросить токен и пробросить в форму
        this.dataService.formReady('feedbacks')
          .then((res: any) => {
            console.log('Get token for feedback', res);
            if (res.data && res.data.token){
              const modalRef = this.modalService.open(FormFeedbackComponent);
              modalRef.componentInstance.data[tmp[0]] = tmp[1];
              modalRef.componentInstance.data.token = res.data.token;
              modalRef.result.then(result => {
                if (result) {
                  // TODO отправит отзыв
                  this.dataService.sendFeedback(result)
                    .then(sfbres => {
                      console.log('Отзыв отправлен успешно!', sfbres);
                    })
                    .catch(err => {
                      console.log('Ошибка отправки отзыва!');
                    });
                }
              }, disResult => {
                // console.log('disResult', disResult);
              });
            }else{
              alert('По техническим причинам отправка отзыва невозможна. Попробуйте позже. Приносим свои извинения!');
            }
          })
          .catch(err => {
            alert('По техническим причинам отправка отзыва невозможна. Попробуйте позже. Приносим свои извинения!');
          });
        break;
      }
      default: {
        console.log('Other event on AdminService Run!', e.name , e.data);
        break;
      }
    }
  }

  /**
   * Удаление элемента из списка внутри модели.
   * Именно удаление, а не отвязка.
   * Функция удаляет (после переработки будет отправлять в предыдущею версию) сам элемент.
   * Если дочерний элемент является объектом связанной модели, то удаляется объект,
   * а информация о наличии его в списке, например в поле order, должна удаляться автоматически
   * при прочих операциях редактирования (так или иначе отсутствующий в списке элемент из order должен игнорироваться).
   * Если же элемент является частью вложенного списка, то список пересобирается и записывается в родительский элемент
   * (в будущем, в новую его версию)
   * @param parentModelName - имя модели родителя, но по сути название функции
   * @param parentId - id объекта родителя
   * @param subFieldName - имя поля родителя, содержащее подмножество
   * @param subId - id объекта, по сути подлежащего удалению
   * @param subModelName - имя модели удаляемого объекта, но по сути название функции.
   *        Его наличие определяет является ли объект элементом связного списка или же вложенного.
   */

  private subItemDelete = (parentModelName, parentId, subFieldName, subId, subModelName) => {
    this.dataService.getData(parentModelName, parentId, {})
      .then((data: any) => {
        if (subModelName) {
          this.dataService.delData(subModelName, subId).then(res => {
            if (data.order && data.order[subFieldName]){
              const putobj: any = {};
              data.order[subFieldName] = data.order[subFieldName].filter(item => +item !== +subId);
              putobj.order = data.order;
              this.dataService.putData(parentModelName, putobj, parentId).then(putres => {
                this.events.send({name: 'reload', data: (parentModelName + ':' + parentId)});
              });
            }else{
              this.events.send({name: 'reload', data: (parentModelName + ':' + parentId)});
            }
          });
        }else{
          let subitems;
          const putobj = {};
          if (this.schema$[parentModelName].imedia && this.schema$[parentModelName].imedia[subFieldName]){
            subitems = '';
          }
          else if (this.schema$[parentModelName].component && this.schema$[parentModelName].component[subFieldName]){
            subitems = {};
          }
          else{
            subitems = data[subFieldName].filter(item => +item.id !== +subId);
          }
          putobj[subFieldName] = subitems;
          this.dataService.putData(parentModelName, putobj, parentId).then(res => {
            this.events.send({name: 'reload', data: (parentModelName + ':' + parentId)});
          })
            .catch(err => console.error(err));
        }
      })
      .catch(err => console.error(err));
  }
  /**
   * Отвязка элемента из списка внутри модели.
   * Функция 'отвязывает' элемент, т.е. элемент остается неизменным, но информация о родительской связи теряется.
   * Если дочерний элемент является объектом связанной модели, то удаляется информация о наличии его в списке
   * (пересобираем в соответствии с order или без него). Объект дочернего списка не трогаем.
   * Если же элемент является частью вложенного списка, то нужно обеспечить интерфейсную недоступность отвязки,
   * т.е. видимость кнопки отвязки должна зависить от типа взаимодействия объектов.
   *
   * @param parentModelName - имя модели родителя, но по сути название функции
   * @param parentId - id объекта родителя
   * @param subFieldName - имя поля родителя, содержащее подмножество
   * @param subId - id объекта, по сути подлежащего удалению
   *        Его наличие определяет является ли объект элементом связного списка или же вложенного.
   */
  subItemUnlink = (parentModelName, parentId, subFieldName, sub) => {
    return new Promise((resolve, reject) => this.dataService.getData(parentModelName, parentId, {})
      .then((data: any) => {
        const putobj: any = {};

        console.log('subFieldName', subFieldName);
        console.log('parentModelName', parentModelName);
        console.log('single', this.schema$[parentModelName].links[subFieldName].single);
        console.log('sub', sub);

        if (!this.schema$[parentModelName].links[subFieldName].single) {

          sub = (Array.isArray(sub)) ? sub.map(s => +s) : [+sub];

          putobj[subFieldName] = data[subFieldName]
            .filter(item => !sub.includes(+item.id))
            .map(item => +item.id);

          if (data.order && data.order[subFieldName] && Array.isArray(data.order[subFieldName]) && (data.order[subFieldName].length && +data.order[subFieldName][0])){
            data.order[subFieldName] = data.order[subFieldName].filter(item => !sub.includes(+item));
            putobj.order = data.order;
          }else{
            this.events.send({name: 'reload', data: (parentModelName + ':' + parentId)});
          }
        }
        else{
          putobj[subFieldName] = null;
        }

        this.dataService.putData(parentModelName, putobj, parentId).then(putres => {
          resolve(putres);
          this.events.send({name: 'reload', data: (parentModelName + ':' + parentId)});
        });
      })
      .catch(err => console.error(err)));
  }

  /**
   * Привязка
   * @param parentModelName
   * @param parentId
   * @param subFieldName
   * @param selectedItems
   */
  subItemLink = (parentModelName, parentId, subFieldName, selectedItems) => {
    return new Promise((resolve, reject) => this.dataService.getData(parentModelName, +parentId, {})
      .then((getres: any) => {
        let subs: any;
        const order = getres.order || {};

        console.log('parentModelName', parentModelName);
        console.log('parentId', parentId);
        console.log('subFieldName', subFieldName);
        console.log('selectedItems', selectedItems);

        if (!this.schema$[parentModelName].links[subFieldName].single) {
          subs = getres[subFieldName].map(item => item.id);
          selectedItems.forEach(item => {subs.push(+item); });
        }
        else{
          subs = +selectedItems[0];
        }

        // TODO Крайне спорная хрень с сортировкой.
        //  Получается, что бы ни было установлено, подключение дочернего линка приводит к изменениям в соответствии с перечнем копонентов.
        //  Order должен быть проанализирован на предмет наличия служебных слов и переработан соответствующим образом.
        //  Если есть закрепленный элемент, он должен быть закреплен.
        //  Если установлена просто сортировка, то должна остаться просто сортировка
        //  Короче, так order можно делать лишь в случае, если и до этого он быт просто перечнем id (пусть и пустым) или не был определен никак

        if (Array.isArray(order[subFieldName]) && (!order[subFieldName].length || +order[subFieldName][0])){
          order[subFieldName] = subs;
        }
        const putobj = {order};

        putobj[subFieldName] = subs;
        this.dataService.putData(parentModelName, putobj, +parentId)
          .then(putres => {
            resolve(putres);
          })
          .catch(err => reject(err));
      })
      .catch(err => reject(err)));
  }

  /**
   * Геттер для битовых масок доступных действий над объектом
   */
  public get actions(){
    return this.action$;
  }
  public get schema(){
    return this.schema$;
  }

// TODO функции popoverOpen/Close предполагают сохранение состояния для всех компонентов, использущих сервис,
//  что исключает его динамическое использование. Подумать. Либо админку в отдельный модуль, а сервис уже к нему,
//  либо всё же это состояние перевести в DataService, хотя по логике туда не стоит пихать ничего,
//  что касается администрирования...
  public popoverOpen(p, state: string){
    if (this.popover && this.popover.isOpen()){
      this.popover.close();
    }
    this.popover = p;
    this.popover.open({state});
    return true;
  }

  public popoverClose(p){
    if (this.popover && this.popover.isOpen()){
      this.popover.close();
    }
  }

  public fillAdminModel(data: any, modelname: string){
    const links = this.schema$[modelname].links;

    (links && Object.keys(links).forEach(link => {
      let actions: number;

      // Если link одиночный
      if (links[link].single){
        console.log('Single link data', data[link]);
        if (!data[link]) {
          data[link] = {};
        }
        actions = links[link].actions;
        data[link].state = modelname + ':' + data.id + ':' + link + ':' + data[link].id + ':' + actions + ':admin';
      }
      else{
        // добавлено
        if (!(data[link] && data[link].length)) {
          data[link] = [{id: -1}];
        }

        data[link] = data[link].map((item, i, arr) => {
          actions = links[link].actions;
          if (data[link][0].id < 0) {
            actions = actions & (~this.action$.linksub) & (~this.action$.unlink) & (~this.action$.edit) & (~this.action$.delete); // добавлено
          }
          if (actions & this.action$.order){
            actions = (i > 0)
              ? (actions | this.action$.up)
              : (actions & (~this.action$.up));
            actions = (i < (arr.length - 1))
              ? (actions | this.action$.down)
              : (actions & (~this.action$.down));
          }
          item.state = modelname + ':' + data.id + ':' + link + ':' + item.id + ':' + actions + ':admin';
          return item;
        });
      }
    }));

    const components = this.schema$[modelname].components;
    (components && Object.keys(components).forEach ( cmp => {
      let actions: number;
      if (!components[cmp].single){
        if (!(data[cmp] && data[cmp].length)) {
          data[cmp] = [{id: -1}];
        }
        data[cmp] = data[cmp].map((item, i, arr) => {
          actions = components[cmp].actions;
          if (data[cmp][0].id < 0) {
            actions = actions & (~this.action$.delete) & (~this.action$.edit);
          }

          if (actions & this.action$.order){
            actions = (i > 0)
              ? (actions | this.action$.up)
              : (actions & (~this.action$.up));
            actions = (i < (arr.length - 1))
              ? (actions | this.action$.down)
              : (actions & (~this.action$.down));
          }
          item.state = modelname + ':' + data.id + ':' + cmp + ':' + item.id + ':' + actions + ':admin';
          return item;
        });
      }else{
        if (!(data[cmp])) {
          data[cmp] = {id: -1};
        }
        actions = components[cmp].actions;
        data[cmp].state = modelname + ':' + data.id + ':' + cmp + ':' + data[cmp].id + ':' + actions + ':admin';
      }
    }));

    // обработка component
    // const component = this.schema$[modelname].component;
    // (component && Object.keys(component).forEach(item => {
    //   let actions: number;
    //
    //   if (!(data[item])) {
    //     data[item] = {id: -1};
    //   }
    //   actions = component[item].actions;
    //   data[item].state = modelname + ':' + data.id + ':' + item + ':' + data[item].id + ':' + actions + ':admin';
    // }));

/*
    (components && Object.keys(components).forEach ( cmp => {
      let actions: number;

      if (!(data[cmp] && data[cmp].length)) {
        data[cmp] = [{id: -1}];
      }

      data[cmp] = data[cmp].map((item, i, arr) => {
        actions = components[cmp].actions;
        if (data[cmp][0].id < 0) {
          actions = actions & (~this.action$.delete) & (~this.action$.edit);
        }

        if (actions & this.action$.order){
          actions = (i > 0)
            ? (actions | this.action$.up)
            : (actions & (~this.action$.up));
          actions = (i < (arr.length - 1))
            ? (actions | this.action$.down)
            : (actions & (~this.action$.down));
        }
        item.state = modelname + ':' + data.id + ':' + cmp + ':' + item.id + ':' + actions + ':admin';
        return item;
      });
    }));

    // обработка component
    const component = this.schema$[modelname].component;
    (component && Object.keys(component).forEach(item => {
      let actions: number;

      if (!(data[item])) {
        data[item] = {id: -1};
      }
      actions = component[item].actions;
      data[item].state = modelname + ':' + data.id + ':' + item + ':' + data[item].id + ':' + actions + ':admin';
    }));
*/

    // TODO очень надо решить с полями вопрос
    // const fields = this.schema$[modelname].fields;
    // (fields && fields.forEach((field,i,array) => {
    //   let actions: number;
    //   data[field] = data[fields].map((item, i, arr) => {
    //     actions = this.schema$[modelname].components[fields].actions;
    //     if((data[fields].length===1) &&
    //       (actions&this.action$.delete) &&
    //       !(actions&this.action$.lastdel)){
    //       actions = actions&(~this.action$.delete);
    //     }
    //     if(actions&this.action$.order){
    //       actions = (i>0)
    //         ? (actions | this.action$.up)
    //         : (actions & (~this.action$.up))
    //       actions = (i<(arr.length - 1))
    //         ? (actions | this.action$.down)
    //         : (actions & (~this.action$.down))
    //     }
    //     item.state = modelname + ':' + data.id + ':' + link + ':' + item.id + ':' + actions + ':admin';
    //     return item;
    //   })
    // }));

    return data;
  }

  public toCB = (data) => {
    navigator.clipboard.writeText(data);
  }

  public toNewTab = (data) => {
    window.open(data);
  }

  public upload(file, state){
    this.dataService.uploadFile(file)
      .then((data: any) => {
            const tmp = state.split(':');
            const putData = {};
            putData[tmp[2]] = data.url;
            this.dataService.putData(tmp[0], putData, +tmp[1])
              .then(res => {
                this.events.send({name: 'reload', data: (tmp[0] + ':' + tmp[1])});
              })
              .catch(err => console.error(err));
          })
      .catch(err => console.error(err));
  }

  private setSelected(putData, state) {
    const tmp = state.split(':');
    this.dataService.putData(tmp[0], putData, +tmp[1])
      .then(res => {
        this.events.send({name: 'reload', data: (tmp[0] + ':' + tmp[1])});
      })
      .catch(err => console.error(err));
  }

  public actionDefault(state) {
    console.log('default action state', state);
    const tmp = state && state.split(':');
    const schema = this.schema[tmp[0]];
    let action: string | false;

    if (schema.imedia && schema.imedia[tmp[2]]){
      const imedia = schema.imedia[tmp[2]];
      action = (imedia && (imedia.d_action || (imedia.actions & this.actions.upload && 'upload'))) || false;
    }
    else{
      console.log('problem item', tmp[2]);
      const field = schema.fields[schema.fields.findIndex((item, index, array) => item.name === tmp[2])];
      action = (field && (field.d_action || (field.actions & this.actions.edit && 'edit'))) || false;
    }

    if (action){
      this.events.send({name: action, data: state});
    }
  }
}

export interface ProgressStatus {
  status: ProgressStatusEnum;
  percentage?: number;
}

export enum ProgressStatusEnum {
  START, COMPLETE, IN_PROGRESS, ERROR
}
