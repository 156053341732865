import {Component, NgModule, OnInit, ViewEncapsulation} from '@angular/core';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {DataService} from '../../services/data.service';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';

@Component({
  standalone: true,
  selector: 'app-start',
  templateUrl: './start.component.html',
  styleUrls: ['./start.component.css'],
  encapsulation: ViewEncapsulation.None,
  imports: [
    NgbModule,
    CommonModule,
    RouterModule
  ]
})
export class StartComponent implements OnInit {
  mobile;
  active;

  constructor(private dataService: DataService) {

  }

  ngOnInit(): void {
    this.mobile = this.dataService.mobile;
    this.dataService.hfVision = false;
    this.dataService.jivo = false;
    this.active = 'oc';
  }

  setActive(e){
    this.active = e;
  }

  toObr(){
    location.href = 'https://learn.skc-fmba.ru';
  }

  toEye(){
    location.href = this.dataService.struct.specurl;
  }
}

