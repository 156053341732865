import { Input, Component } from '@angular/core';
import { AdminService } from '../../../services/admin.service';
import {AdmitemslistmenuComponent} from '../../admin/admitemslistmenu/admitemslistmenu.component';
import {RouterModule} from '@angular/router';
import {NgbPopoverModule} from '@ng-bootstrap/ng-bootstrap';
import {CommonModule} from '@angular/common';
import {DataService} from '../../../services/data.service';
import { Subscription } from 'rxjs';
import { EventsService } from 'src/app/services/events.service';

@Component({
  standalone: true,
  selector: 'app-nav-footer-item',
  templateUrl: './nav-footer-item.component.html',
  styleUrls: ['./nav-footer-item.component.css'],
  imports: [
    AdmitemslistmenuComponent,
    CommonModule,
    NgbPopoverModule,
    RouterModule
  ]
})
export class NavFooterItemComponent {
  @Input() set fnav(fn){
    console.log('Input NavFooterItemComponent', fn);
    if (fn && fn.id) {
      this.reload(fn.id)
    }
  };

  model: any = {};
  mode: string;
  public borderStyle: any;
  subs: Subscription;

  private reload = async (id) => {
    this.mode = this.dataService.mode;
    const data = await this.dataService.getData('navigations', id, false);
    this.model = (this.dataService.mode === 'admin') ? this.admin.fillAdminModel(data, 'navigations') : data;
    console.log('FooterItemComponent model', this.model);
  }

  constructor(public admin: AdminService, private events: EventsService, public dataService: DataService) {
    this.mode = '';
    this.borderStyle = {};
    this.subs = new Subscription();
    this.subs.add(this.dataService.sub().mode.subscribe(m => this.reload(this.model.id)));
    this.subs.add(this.events.event$.subscribe(e => ((e.name === 'reload') && e.data === `navigations:${this.model.id}` && this.reload(this.model.id))));
  }

  bordermark = (p, state: string | boolean) => {
    this.borderStyle = {};
    if (state) {
      this.borderStyle['' + state] = 'border: solid 1px #7d7e92;';
      this.admin.popoverOpen(p, '' + state);
    }
  }
}

