<div class="modal-header">
  <h4 class="modal-title" id="modal-title">Ввод данных элемента {{title?'"'+title+'"':''}} </h4>
</div>
<div class="modal-body">
  <form [formGroup]="editForm" (ngSubmit)="onSubmit()">
    <div *ngFor="let item of formFields">
      <label for="{{item.name}}">{{item.label}} <sup>{{(item.required?'*':'')}}</sup></label>

      <input *ngIf="!isMedia[item.name] && ((item.type === 'string') || (item.type === 'media') || (item.type === 'image') || (item.type === 'file') || (item.type === 'slider'))" type="text"
             [ngClass]="{ 'is-invalid': submitted && f[item.name].errors }"
             formControlName="{{item.name}}"
             class="form-control" placeholder="{{item.label}}"/>

      <app-svg-check *ngIf="item.type == 'svg'" (uploadedData)="onSubDataUpdate($event, item.name)"></app-svg-check>
      <app-upload *ngIf="(item.type === 'image') || (item.type === 'media') || (item.type === 'file')"
                  [src]="dataService.srvurl + formData[item.name][0] || ''"
                  [cls]="item.htmlclass || ''"
                  (uploadedData)="onSubDataUpdate($event, item.name)"></app-upload>
<!--      <app-fileupload *ngIf="item.type == 'file'"-->
<!--                      (uploadedData)="onSubDataUpdate($event, item.name)"></app-fileupload>-->

<!--      <p *ngIf="showProgress"> progress <strong>{{percentage}}%</strong></p>-->
<!--      <input *ngIf="item.type == 'image'" type="file"-->
<!--             [ngClass]="{ 'is-invalid': submitted && f[item.name].errors }"-->
<!--             formControlName="{{item.name}}"-->
<!--             class="form-control" [(ngModel)]="fileData" (ngModelChange)="onFileChange()"/>-->
      <input *ngIf="item.type == 'date'" type="date"
             [ngClass]="{ 'is-invalid': submitted && f[item.name].errors }"
             formControlName="{{item.name}}"
             class="form-control" placeholder="{{item.label}}"/>
      <input *ngIf="item.type == 'checkbox'" type="checkbox"
             [ngClass]="{ 'is-invalid': submitted && f[item.name].errors }"
             formControlName="{{item.name}}"
             class="form-control" placeholder="{{item.label}}"/>
      <input *ngIf="item.type == 'boolean'" type="checkbox" name="{{item.name}}"
             [ngClass]="{ 'is-invalid': submitted && f[item.name].errors }"
             formControlName="{{item.name}}"
             class="form-control"/>

      <select *ngIf="item.type === 'select'"
              [ngClass]="{ 'is-invalid': submitted && f[item.name].errors }"
              formControlName="{{item.name}}"
              class="form-control">
        <option *ngFor="let c of selectvariants[item.name]" [ngValue]="c.id">{{c.title}}</option>
      </select>

<!--      <textarea *ngIf="item.type == 'text'" rows="5"-->
<!--                [ngClass]="{ 'is-invalid': submitted && f[item.name].errors }"-->
<!--                formControlName="{{item.name}}" class="form-control"></textarea>-->
<!--      <ckeditor *ngIf="item.type == 'text'" tagName="textarea" formControlName="{{item.name}}"></ckeditor>-->
      <ckeditor *ngIf="item.type == 'text'" [editor]="Editor" [config]="Config" tagName="textarea" formControlName="{{item.name}}"></ckeditor>
      <div *ngIf="submitted && f[item.name].errors" class="invalid-feedback">
        <div *ngIf="f[item.name].errors.required">Требуется {{item.label.toLowerCase()}}</div>
      </div>
      <br>
    </div>
    <hr>
    <div class="form-group">
      <button [disabled]="loading" class="btn btn-danger col-4" (click)="modal.dismiss()">
        <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
        Отмена
      </button>
      <button [disabled]="loading" class="btn btn-success col-4 offset-4" type="submit">
        <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
        Сохранить
      </button>
<!--      <div *ngIf="!loading" class="row">-->
<!--&lt;!&ndash;        <button class="btn btn-outline-secondary" (click)="modal.dismiss()">Отмена</button>&ndash;&gt;-->
<!--      </div>-->
    </div>
  </form>
</div>
