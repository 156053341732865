import { Component, Output, EventEmitter, Input, ViewChild, ElementRef, NgModule, OnInit } from '@angular/core';
import { ProgressStatus} from '../../services/admin.service';
import { ImageCropperModule } from 'ngx-image-cropper';
import { ImageCroppedEvent, base64ToFile } from 'ngx-image-cropper';
import { CommonModule } from '@angular/common';
import {ReactiveFormsModule} from '@angular/forms';

@Component({
  standalone: true,
  selector: 'app-upload',
  templateUrl: 'upload.component.html',
  imports: [
    ImageCropperModule,
    CommonModule,
    ReactiveFormsModule
  ],
})

export class UploadComponent implements OnInit {
  @Input() public cls: string;
  @Input() public src;

  @Output() public uploadStatus: EventEmitter<ProgressStatus>;
  @Output() uploadedData = new EventEmitter<string | File>();
  @ViewChild('inputFile') inputFile: ElementRef;

  filename: string;
  imageChangedEvent: any;
  croppedImage: any;
  imgSRC;
  conf: any = {
    video_cover_img: {
      width: 1100,
      height: 618
    },
    page_banner: {
      width: 450
    },
    big_slide: {
      width: 550,
      height: 370
    },
    direction_img_img: {
      // height: 374,
      width: 1198
    },
    direction_img: {
      width: 200,
      height: 200
    },
    block_directions_main_img: {
      width: 48,
      height: 48
    },
    benefit_icon_img: {
      width: 70,
      height: 70
    },
    doctor_card_img_img: {
      width: 165,
      height: 165
    },
    simple_img: {
      width: 600,
    },
    new_details_img: {
      width: 1200,
      height: 362
    },
    slider_articles_img: {
      width: 280,
      height: 280,
    },
    promo_img_img: {
      width: 1120,
      height: 370
    }
  };

  constructor() {
    this.uploadStatus = new EventEmitter<ProgressStatus>();
  }

  ngOnInit(): void {
    this.imageChangedEvent = '';
    this.croppedImage = false;
    this.imgSRC = this.src || '';
  }

  fileChangeEvent(event: any): void {
    console.log('fileChangeEvent', event.target.files);
    const file = event.target.files.item(0);
    const isImage = (/\.(gif|jpe?g|tiff|png)$/i).test(file.name);
    this.filename = file.name;

    this.imageChangedEvent = '';
    this.uploadedData.emit('');

    if (isImage){
      this.imageChangedEvent = event;
    }else{
      this.uploadedData.emit(file);
    }
  }

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
    this.imgSRC = this.croppedImage;

    this.uploadedData.emit(new File([base64ToFile(this.imgSRC)], this.filename));
  }

  imageLoaded() {
    // show cropper
    console.log('imageLoaded');
  }

  cropperReady() {
    // cropper ready
    console.log('cropperReady');
  }

  loadImageFailed() {
    // show message
    console.log('loadImageFailed');
  }

  over(){
    this.imgSRC = this.src || '';
  }

  leave(){
    this.imgSRC = this.croppedImage || this.src || '';
  }
}
// @NgModule({
//   declarations: [
//     UploadComponent,
//   ],
//   imports: [
//     ImageCropperModule,
//     CommonModule,
//     ReactiveFormsModule
//   ],
//   exports: [
//     UploadComponent
//   ]
// })
// export class UploadComponentModule {}
