<div class="row">
  <div class="col">
    <div class="row mt-3">
      <div class="col" style="text-align: center;">
        <img class="center-media__img" src="https://fmba.gov.ru/local/templates/main_new/img/logo.png">
      </div>
    </div>
    <div class="row">
      <a [routerLink]="['/pages/354']" class="col-6 offset-3 btn btn-outline-danger">COVID-19</a>
    </div>
    <div class="row mt-3">
      <a href="tel:+7 (391) 277-33-55" class="col-6 offset-3 btn btn-outline-primary">Запись ОМС</a>
    </div>
    <div class="row mt-3">
      <a href="tel:+7 (391) 257-31-60" class="col-6 offset-3 btn btn-outline-primary">Запись ДМС и платно</a>
    </div>
    <div class="row mt-3">
      <a href="tel:+7 (391) 257-31-61" class="col-6 offset-3 btn btn-outline-primary">Вызов врача на дом</a>
    </div>
    <div class="row mt-3">
      <a [routerLink]="['/pages/322']" class="col-6 offset-3 btn btn-outline-danger">Внимание, клещ!</a>
    </div>
  </div>
</div>
