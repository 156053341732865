import {Component, OnInit} from '@angular/core';
import {CommonModule} from '@angular/common';

@Component({
  standalone: true,
  selector: 'app-new-year',
  templateUrl: './new-year.component.html',
  styleUrls: ['new-year.component.css'],
  imports: [
    CommonModule
  ],

})
export class NewYearComponent implements OnInit {
  snow = new Array(200);
  statusPlay: boolean;
  constructor() { }
  
  public statusSlay(statusPlay: boolean) {
    this.statusPlay = statusPlay;
  }
  
  ngOnInit() {
    this.statusPlay = false;
  }
}
