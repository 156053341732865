import {Component, NgModule, OnInit, ViewEncapsulation} from '@angular/core';
import {DataService} from '../../services/data.service';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';

@Component({
  standalone: true,
  selector: 'app-tap',
  templateUrl: './tap.component.html',
  styleUrls: ['./tap.component.css'],
  encapsulation: ViewEncapsulation.None,
  imports: [
    NgbModule,
    CommonModule,
    RouterModule
  ],
})
export class TapComponent implements OnInit {
  mobile;
  active;
  constructor(private dataService: DataService) { }

  ngOnInit(): void {
    this.mobile = this.dataService.mobile;
    this.dataService.hfVision = false;
    this.dataService.jivo = false;
    this.active = 'skc';
  }

  setActive(e){
    this.active = e;
  }
}

