import { Component, OnInit} from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  templateUrl: './adm-modal-confirm.component.html',
})
export class AdmModalConfirmComponent implements OnInit {
  titleText: string;
  baseText: string | null;
  subText: string | null;
  submitButtonText: string;
  cancelButtonText: string;

  constructor(public modal: NgbActiveModal) {
    this.submitButtonText = 'ОК';
    this.cancelButtonText = 'Отмена';
    this.titleText = 'Внимание!';
  }
  ngOnInit(): void {}
}
