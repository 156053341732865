<div class="row" style="border-bottom: #44bbb7; border-bottom-style: solid;margin: 5px;">
  <div class="btn-group mb-2" role="group">
    <button type="button" class="btn btn-sm" (click)="toPublicMode()">Выйти
<!--      <fa-icon [icon]="faLongArrowAltDown" ngbTooltip="Передвинуть" placement="top"></fa-icon>-->
    </button>
  </div>
  <div class="btn-group mb-2" role="group">
    <button type="button" class="btn btn-sm" (click)="imageUpload()">Upload</button>
  </div>
  <div class="btn-group mb-2" role="group">
    <button type="button" class="btn btn-sm" (click)="integrity()">Интеграция</button>
  </div>
  <div class="btn-group mb-2" role="group">
    <button type="button" class="btn btn-sm" (click)="content()">Контент</button>
  </div>
  <!-- <div class="btn-group mb-2" role="group">
    <button type="button" class="btn btn-sm" (click)="uni()">Тест</button>
  </div> -->
</div>
